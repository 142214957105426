import useMediaQuery from "@material-ui/core/useMediaQuery";
import usePrompt from "hooks/usePrompt";
import { useState } from "react";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NotificationProvider from "../../../../components/NotificationProvider";
import FilterService, { fieldTypes } from "../../../../services/filterService";
import { setFilters, useDeleteLotMutation, useGetLotsQuery, useUpdateLotMutation } from "../../lotSlice";
import { useGetSubMarketsQuery } from "../LotSubMarketsPage/lotSubMarketsSlice";
import LotsList from "./LotsList/LotsList";
import LotsMapWrapper from "./LotsMap/LotsMapWrapper";
import { lotsPageStyle } from "./lotsPageStyle";

const LotsPage = () => {
    const classes = lotsPageStyle();
    const { triggerPrompt } = usePrompt();
    const isNarrowScreen = useMediaQuery("(max-width: 1450px)");

    const { filters } = useSelector(state => state.subMarketsState);

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let [addOrUpdateLot, { isLoading: updateLotLoading }] = useUpdateLotMutation();
    let [deleteLot, { isLoading: deleteLotLoading }] = useDeleteLotMutation();

    let { data: subMarkets, error: subMarketsError, isLoading: isLoadingSubMarkets } = useGetSubMarketsQuery();
    subMarkets = subMarkets || [];

    const [screenView, setScreenView] = useState('map');
    const [lotToEdit, setLotToEdit] = useState();
    const [lotViewImages, setLotViewImages] = useState();
    const [searchedVal, setSearchedVal] = useState('');
    const [deletePopup, setDeletePopup] = useState(false);
    // let [googleApi, setGoogleApi] = useState();

    let filterConfig = [
        { type: fieldTypes.VALUE_LIST, size: 2, title: 'Sub Market', key: 'subMarket', values: subMarkets },
        { type: fieldTypes.LIST, size: 2, title: 'PMF Lot', key: 'pmfLotFilter', values: [{ id: '1', name: 'Yes' }, { id: '0', name: 'No' }] },
    ];

    lots = lots || [];
    lots = lots.filter(l => !l.deletedDate);

    const subMarketsByLot = {}
    subMarkets?.map(s => {
        s?.lotIds?.map(l => {
            subMarketsByLot[l] = [...(subMarketsByLot[l] ?? []), s];
        })
    })

    const getSubMarketByLot = (lotId) => {
        return subMarketsByLot[lotId]?.map(l => l?.id + '');
    }

    lots = lots?.map(l => ({
        ...l,
        subMarket: getSubMarketByLot(l.id) || [],
        pmfLotFilter: l.pmfLot ? '1' : '0'
    }));

    const sortedLots = FilterService.filter(filters, searchedVal, lots, filterConfig);

    const searchLot = (searchedVal) => {
        setSearchedVal(searchedVal.trim());
    }

    const onNewLot = () => {
        setLotToEdit({});
    }

    const handleViewChange = (e, newView) => {
        setScreenView(newView);
    }

    const onEditLot = (lot) => {
        setLotToEdit({ ...lot });
    }

    const onViewImages = (lot) => {
        setLotViewImages(lot);
    }

    const onDelete = async (lot) => {
        if (lot?.numberOfAssets > 0) {
            setDeletePopup(true)
        }
        else {
            triggerPrompt({
                title: "Delete Lot",
                content: "Are you sure you want to delete the lot?",
                onConfirm: async () => {
                    const res = await deleteLot(lot)
                    if (!res?.error) {
                        NotificationProvider.success("Lot was deleted successfully");
                    } else {
                        NotificationProvider.error("Failed to delete lot");
                    }
                },
            });
        }
    }

    const onCloseImages = () => {
        setLotViewImages();
    }

    const onSaveLot = async (lot) => {
        //let maps = googleApi?.maps;
        // if (!googleApi) {
        //     const loadedApi = await googleMapService.loadGoogleMapApi();
        //     setGoogleApi(loadedApi);
        //     maps = loadedApi?.maps;
        // }
        // try {
        //     const geoCode = await googleMapService.geoCode(maps, googleMapService.convertAddressPartsToAddressString(lot))
        //     lot.longitude = geoCode.lng + "";
        //     lot.latitude = geoCode.lat + "";
        // } catch (err) {

        // }
        const res = await addOrUpdateLot(lot);
        if (!res?.error) {
            NotificationProvider.success("Lot was saved successfully");
            setLotToEdit();
        } else {
            NotificationProvider.error("Failed to save lot");
        }
    }

    const onCancelEditLot = () => {
        setLotToEdit();
    }

    return (
        <>
            <LoadingSpinner loading={isLoadingLots || updateLotLoading} />
            {screenView == 'map' ?
                <LotsMapWrapper
                    lots={sortedLots}
                    screenView={screenView}
                    handleViewChange={handleViewChange}
                    onNewLot={onNewLot}
                    filters={filters}
                    setFilters={setFilters}
                    searchLot={searchLot}
                    subMarketsByLot={subMarketsByLot}
                    onEditLot={onEditLot}
                    onViewImages={onViewImages}
                    onDelete={onDelete}
                    lotToEdit={lotToEdit}
                    onCloseImages={onCloseImages}
                    onSaveLot={onSaveLot}
                    onCancelEditLot={onCancelEditLot}
                    lotViewImages={lotViewImages}
                    updateLotLoading={updateLotLoading}
                    deletePopup={deletePopup}
                    setDeletePopup={setDeletePopup}
                    subMarkets={subMarkets}
                    isNarrowScreen={isNarrowScreen}
                /> :
                <LotsList
                    allLots={lots}
                    lots={sortedLots}
                    deletePopup={deletePopup}
                    setDeletePopup={setDeletePopup}
                    updateLotLoading={updateLotLoading}
                    lotViewImages={lotViewImages}
                    setLotViewImages={setLotViewImages}
                    lotToEdit={lotToEdit}
                    subMarketsByLot={subMarketsByLot}
                    searchLot={searchLot}
                    filterConfig={filterConfig}
                    filters={filters}
                    setFilters={setFilters}
                    searchedVal={searchedVal}
                    onNewLot={onNewLot}
                    screenView={screenView}
                    handleViewChange={handleViewChange}
                    onEditLot={onEditLot}
                    onViewImages={onViewImages}
                    onDelete={onDelete}
                    onCloseImages={onCloseImages}
                    onSaveLot={onSaveLot}
                    onCancelEditLot={onCancelEditLot}
                    isNarrowScreen={isNarrowScreen}
                />
            }
        </>
    )
}

export default LotsPage;

