import {createSlice} from "@reduxjs/toolkit";

export const issuesTrackerFiltersSlice = createSlice({
    name: 'issuesTrackerFilters',
    initialState: {
        searchVal: '',
        filters: {
            clientId: {value: null},
            lotId: {value: null},
            statusId: {value: null},
        },
    },
    reducers: {
        setSearchVal: (state, action) => {
            state.searchVal = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        }
    },
});

export const { setSearchVal, setFilters } = issuesTrackerFiltersSlice.actions;
export default issuesTrackerFiltersSlice.reducer;