import { makeStyles } from "@material-ui/core";

export const addPhotoStyle = makeStyles((theme) => ({
    container: {
        width: "100%",
        maxHeight: 704,
        margin: 0,
    },
    addPhotoContainer: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        //padding: 45,
        cursor: 'pointer',
        width: '100%',
        height: 140,
        '&:hover': {
            opacity: 0.8
        }
    },
    showTextContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    showText: {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    photo: {
        width: '100%',
        height: '100%',
        maxHeight: 140,
        objectFit: "cover"
    },
    inactiveText: {
        color: theme.palette.secondary.light,
        cursor: 'default',
    }
}));