import React, { useState, useEffect, useRef } from 'react';
import { ConnectedRouter } from 'connected-react-router'
import { makeStyles } from '@material-ui/core/styles';
import './App.scss';
import CssBaseline from "@material-ui/core/CssBaseline";
import Routes from "./Routes";
import { history } from "./app/store/store";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import mobileBreakpoint from "./utils/mobileBreakpoint";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import Header from "./features/Header/Header";
import { realTimeNotification } from "./app/realTimeNotification";
import { Prompt } from './components/Prompt/Prompt';
import { PromptContextProvider } from './components/Prompt/PromptContext';
import SideNav from './menu/SideNav';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    appContainer: {
        marginTop: "5rem",
        marginLeft: "16rem",
        marginRight: "1rem"
    },
    appContainerMobile: {
        marginLeft: 100
    }
}));

function App() {
    const classes = useStyles();
    const isMobile = useMediaQuery(mobileBreakpoint);
    const [connection, setConnection] = useState(null);
    const [isSideNavOpen, setIsSideNavOpen] = useState(true);
    
    useEffect(() => {
        const newConnection = realTimeNotification.GetHubConnectionBuilder();
        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
                    console.log('Connected!');
                    connection.on('ReceiveMessage', receiveObject => {
                        realTimeNotification.ProcessMessage(receiveObject);
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);

    const toggleSideNav = () => {
        setIsSideNavOpen(!isSideNavOpen);
    };

    
    return (
        <PromptContextProvider>
            <ConnectedRouter history={history}>
                <Prompt />
                <CssBaseline />
                <Header onToggleSideNav={toggleSideNav} isNavOpen={isSideNavOpen}  />
                <SideNav isNavOpen={isSideNavOpen} />
                <div className={`${classes.appContainer} ${!isSideNavOpen ? classes.appContainerMobile : ""}`}>
                    <Routes />
                </div>
                <ToastContainer />
            </ConnectedRouter>
        </PromptContextProvider>
    );
}

export default App;
