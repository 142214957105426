import { Autocomplete, TextField, createFilterOptions } from "@mui/material"
import LoadingSpinner from "components/LoadingSpinner";
import NotificationProvider from "components/NotificationProvider";
import { useAddServiceProviderMutation, useGetServiceProvidersQuery } from "features/serviceProvider/serviceProviderSlice"
import { PricingListStyle } from "./style";

export const VendorField = ({ value, onChange, required, label }) => {
    const classes = PricingListStyle()

    const filter = createFilterOptions();
    let { data: serviceProviders, error: serviceProvidersError, isLoading: isLoadingServiceProviders } = useGetServiceProvidersQuery();
    const [addServiceProvider, { isLoading: isAdding }] = useAddServiceProviderMutation();

    let vendor = serviceProviders.find(s => s.id === value);

    const onValueChange = async (newValue) => {
        if ((newValue && newValue.inputValue) || typeof newValue === 'string') {
            // Create a new value from the user input
            let result = await addServiceProvider({ name: newValue.inputValue });
            if (result && !result.error) {
                NotificationProvider.success("Vendor added successfully");
            } else {
                NotificationProvider.error("Something happened, try again or contact support");
            }
            onChange(result?.data);
        } else {
            onChange(newValue?.id);
        }
    }

    return <>
        <LoadingSpinner loading={isAdding || isLoadingServiceProviders} />
        <Autocomplete
            value={vendor}
            onChange={(event, newValue) => {
                onValueChange(newValue)
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.name);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        name: `Add "${inputValue}"`,
                    });
                }

                return filtered;
            }}
            autoHighlight
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="vendor"
            options={serviceProviders}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.name;
            }}
            renderOption={(props, option) => <li {...props}> <div className={classes.options}>{option.name}</div></li>}
            freeSolo
            renderInput={(params) => (
                    <TextField
                        value={vendor}
                        variant='outlined'
                        label={label}
                        InputLabelProps={{ className: classes.label }}
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            className: classes.input,
                            notchedOutline: classes.notchedOutline,
                        }}
                    />
            )}
            className={classes.input}
        />
    </>

}