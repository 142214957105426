import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Tab, Tabs } from "@material-ui/core";
import configStyles from "../configStyles";
import CheckInConfigStyle from "./CheckInConfigStyle";
import CheckInGeneralData from "./CheckInGeneralData";
import PropTypes from 'prop-types';
import React, { useState } from "react";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import NotificationProvider from "components/NotificationProvider";
import { useUpdateCheckInMutation } from "./CheckInConfigSlice";
import ClientVehicleTypes from "features/clients/contracts/EditContractPopup/EditWorkFlowPopup/components/ClientVehicleTypes/ClientVehicleTypes";
import { useSelector } from "react-redux";
import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function tabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = CheckInConfigStyle;
const useConfigStyles = configStyles;

const CheckInConfig = ({ contract, open, clientConfig, handleClose }) => {
    const configClasses = useConfigStyles();
    const classes = useStyles();

    const [checkinSla, setCheckinSla] = useState([]);
    const [defaultStatusId, setDefaultStatusId] = useState(null);
    const [updateCheckIn] = useUpdateCheckInMutation();
    const [isSaving, setIsSaving] = useState(false);
    const { selectedVehicleTypes } = useSelector(state => state.mobileWorkflowState);

    const onClose = () => {
        setCheckinSla(null);
        handleClose();
    }

    const handleSave = async () => {
        setIsSaving(true);

        let objectToSave = {
            clientId: clientConfig?.id,
            statusId: defaultStatusId,
            checkInSla: checkinSla?.filter(row => row.changed).map(row => ({ ...row, clientId: clientConfig?.id })) ?? [],
            selectedVehicleTypes: selectedVehicleTypes
        };
        let res = await updateCheckIn(objectToSave);

        setIsSaving(false);
        handleClose();

        if (!res?.error) {
            NotificationProvider.success('Saved successfully');
            onClose();
        } else {
            NotificationProvider.error('Failed to save');
        }
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabs = [
        {
            label: 'General Data',
            component: <CheckInGeneralData open={open} clientConfig={clientConfig} handleClose={handleClose} checkinSla={checkinSla}
                setCheckinSla={setCheckinSla} defaultStatusId={defaultStatusId} setDefaultStatusId={setDefaultStatusId} />
        },
        {
            label: 'Asset Types',
            component: <ClientVehicleTypes clientId={clientConfig?.id} />
        }
    ]

    return (
        <>
            {
                <Dialog
                    open={open}
                    onClose={onClose}
                    fullWidth
                    maxWidth='xl'
                >
                    <DialogTitle>
                        <Grid container justifyContent='space-between'>
                            <div>{`${contract?.label} SLA`}</div>
                            <div className={classes.btnWrapper}>
                                <Authorize profile={permissionProfiles.CLIENT_CONFIG.CHECKIN_SLA_ACTIONS}>
                                    <Button
                                        onClick={handleSave}
                                        disabled={isSaving}
                                        className={classes.button}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        {isSaving ? "Saving..." : "Save"}
                                    </Button>
                                </Authorize>
                            </div>
                            {contract?.headerComponent}
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <TabsWrapper tabs={tabs} />
                    </DialogContent>
                </Dialog>

            }
        </>
    )
}
export default CheckInConfig;
