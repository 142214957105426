import * as AllMaterialIcons from '@material-ui/icons';

const statuses = {
    '1': {
        icon: <AllMaterialIcons.HistoryRounded />,
        label: 'Pending Carrier Assignment'
    },
    '2': {
        icon: <AllMaterialIcons.RotateLeftRounded />,
        label: 'Pending Carrier Acceptance'
    },
    '3': {
        icon: <AllMaterialIcons.PlayForWorkRounded />,
        label: 'Load Accepted'
    },
    '4': {
        icon: <AllMaterialIcons.TrendingUpRounded />,
        label: 'In-Transit',
        exportLabel: 'Transit Began'
    },
    '5': {
        icon: <AllMaterialIcons.LocalShippingRounded />,
        label: 'Delivered'
    },
    '6': {
        icon: <AllMaterialIcons.DoneAllRounded />,
        label: 'Complete (Billing)',
        exportLabel: 'Completed'
    },
    '7': {
        icon: <AllMaterialIcons.Block />,
        label: 'Cancelled',
        exportLabel: 'Load Cancelled'
    },
    '8': {
        icon: <AllMaterialIcons.NewReleasesOutlined />,
        label: 'Load Created'
    },
    '9': {
        icon: <AllMaterialIcons.BeenhereOutlined />,
        label: 'Amerit Confirmed'
    },
    '10': {
        icon: <AllMaterialIcons.Error />,
        label: 'Exception'
    },
    '11': {
        icon: <AllMaterialIcons.Block />,
        label: 'Cancelled in Parq'
    },
};

export default statuses;