import { Loader } from '@googlemaps/js-api-loader';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CustomInput from '../../../../../components/CustomInput';
import { googleMapService } from '../../../../../components/MapWrapper/googleMapService';
import { useGetLotsQuery } from '../../../../lots/lotSlice';
import LocationDistanceStyle from './LocationDistanceStyle';

const useStyles = LocationDistanceStyle;

const LocationDistance = ({ onChange, newLoad, originNewLot, destinationNewLot, showMandatoryError }) => {

    const classes = useStyles();

    let { data: lots, error: lotError, isLoading: isLoadingLots, isSuccess: isLotsSuccess } = useGetLotsQuery();

    const [originLocationValid, setOriginLocationValid] = useState(false);
    const [destinationLocationValid, setDestinationLocationValid] = useState(false);
    let [autoCalcMiles, setAutoCalcMiles] = useState(true);

    let [googleApi, setGoogleApi] = useState();

    useEffect(() => {
        const fetchData = async () => {
            if (!googleApi) {
                const mapApi = await googleMapService.loadGoogleMapApi();
                setGoogleApi(mapApi);
            }
        }
        fetchData();
    }, [])


    useEffect(() => {
        if (googleApi) {
            let flag1 = false;
            let flag2 = false;
            if ((originNewLot && newLoad?.pickupCity && newLoad?.pickupState && newLoad?.pickupZip) || (!originNewLot && newLoad?.pickupLocationId)) {
                setOriginLocationValid(true)
                flag1 = true;
            }
            else {
                setOriginLocationValid(false)
            }
            if ((destinationNewLot && newLoad?.deliveryCity && newLoad?.deliveryState && newLoad?.deliveryZip) || (!destinationNewLot && newLoad?.deliveryLocationId)) {
                setDestinationLocationValid(true)
                flag2 = true;
            }
            else {
                setDestinationLocationValid(false)
            }
            if (flag1 && flag2 && autoCalcMiles) {
                getDistance()
            }
            else {
                onChange("miles", 0)
            }
        }

    }, [googleApi, newLoad?.pickupLocationId, newLoad?.pickupCity, newLoad?.pickupState, newLoad?.pickupZip, newLoad?.deliveryLocationId, newLoad?.deliveryCity, newLoad?.deliveryState, newLoad?.deliveryZip, originNewLot, destinationNewLot]);



    const onCheckMiles = () => {
        setAutoCalcMiles(!autoCalcMiles);
        if (!autoCalcMiles) {
            getDistance();
        }
        else {
            onChange("miles", 0);
        }
    }

    const getDistance = () => {
        let startLocation = !originNewLot ? lots.find(l => l.id == newLoad.pickupLocationId) : { city: newLoad.pickupCity, state: newLoad.pickupState, zipCode: newLoad.pickupZip }
        let endLocation = !destinationNewLot ? lots.find(l => l.id == newLoad.deliveryLocationId) : { city: newLoad.deliveryCity, state: newLoad.deliveryState, zipCode: newLoad.deliveryZip }
        googleMapService.calculateDistance(googleApi?.maps, startLocation, endLocation, (distance) => handleDistance(distance))
    }

    const handleDistance = (distance) => {
        let dist = Math.round(distance * 0.0621371192) / 100
        if (dist !== newLoad?.miles) {
            onChange("miles", dist);
        }
    }


    return (
        <Grid container>
            <Grid item xs={4}>
                <div className={classes.input}>
                    <CustomInput
                        id="miles"
                        label="Miles"
                        value={newLoad?.miles ?? ''}
                        onChange={val => onChange("miles", +val)}
                        elementType="input"
                        numberOnly
                        required
                        touched
                        valid={!(showMandatoryError && !newLoad?.miles)}
                        shrink
                        disabled={originLocationValid && destinationLocationValid && autoCalcMiles}
                    />
                </div>
            </Grid>
            {originLocationValid && destinationLocationValid &&
                <Grid item xs={4}>
                    <div className={classes.input}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={autoCalcMiles}
                                onChange={onCheckMiles}
                            />}
                            label="Auto Calculate Miles" />
                    </div>
                </Grid>}
        </Grid>
    );
};

export default LocationDistance;
