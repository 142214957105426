import HttpService from './httpService';
import { usStates } from './usStates';

const VehicleService = {
    incoming: async (vin) => {
        return HttpService.post('api/v1/vehicle/incoming', { vin, dealerId: 1, lotId: 0, vehicleType: "vehicle" });
    },
    delivered: async (vin) => {
        return HttpService.post('api/v1/vehicle/delivered', { vin, dealerId: 1, lotId: 0 });
    },
    sold: async (vin) => {
        return HttpService.post('api/v1/vehicle/sold', { vin, dealerId: 1 });
    },
    transferred: async (vin) => {
        return HttpService.post('api/v1/vehicle/transferred', { vin, dealerId: 1 });
    },
    pickedUp: async (vin) => {
        return HttpService.post('api/v1/vehicle/pickedup', { vin, dealerId: 1 });
    },
    create: async (data) => {
        return HttpService.post('api/v1/vehicle', data);
    },
    getStatus: async () => {
        return HttpService.get('api/v1/vehicle/status');
    },
    getTypes: async () => {
        return HttpService.get('api/v1/vehicle/types');
    },
    uploadFile: async (fromData) => {
        return HttpService.post(
            'api/v1/vehicle/uploadcsvfile',
            fromData
        );
    },
    saveAssetByFile: async (data) => {
        return HttpService.post(
            'api/v1/vehicle/addAssetByFileUpload',data
        );
    },
    getUsStates: () => {
        return usStates;
    }

};

export default VehicleService;