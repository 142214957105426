import { Grid, Paper } from "@material-ui/core";
import React, { useState } from "react";
import { TaskSetup } from "../../taskConsts";
import { TaskItemStyles } from "./TaskItemStyles";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { NavLink } from "react-router-dom";


const TaskItem = ({ task, style, onClose }) => {
    const classes = TaskItemStyles();
    const taskSetup = TaskSetup[task?.taskType];

    return (
        <Paper className={classes.wrapper} style={style}>
            <Grid container alignItems="center">
                <Grid item xs={2} className={classes.iconContainer}>
                    {taskSetup.icon}
                </Grid>
                <Grid container xs={9} spacing={1}>
                    <Grid item xs={10}>
                        <b>{taskSetup?.label}</b>
                    </Grid>
                    <Grid item xs={10}>
                        {taskSetup?.component(task)}
                    </Grid>
                    {/* For debug <div>
                        Pickup: {task?.pickupPriority},
                        Domicile: {task?.domicilePriority},
                        Readiness: {task?.readinessPriority},
                        Load: {task?.loadPriority},
                        isLoad: {task?.isLoadPriority}
                    </div> */}
                </Grid>
                <Grid container xs={1} alignContent='flex-end'>
                    {
                        taskSetup?.link(task) != "" &&
                        <NavLink exact
                            className={classes.link}
                            to={taskSetup?.link(task)}
                        >
                            <ArrowForwardIcon title='View Asset' />
                        </NavLink>
                    }
                    
                </Grid>
            </Grid>
        </Paper >
    )
}
export default TaskItem;