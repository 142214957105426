import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from "../../app/baseQuery";
import { apiTags } from '../../app/store/apiTags';
import { createSlice } from '@reduxjs/toolkit';

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: () => `admin/users/getall`,
            providesTags: [apiTags.USERS]
        }),
        getUserLotRelations: builder.query({
            query: () => `userlotrelation/user`,
            providesTags: [apiTags.USER_LOT],
        }),
        getUserLotRelationsForUser: builder.query({
            query: (userId) => `userlotrelation/userId/${userId}`,
            providesTags: [apiTags.USER_LOT],
        }),
        getUserLotRelationsByLot: builder.query({
            query: (lotId) => `userlotrelation/lot/${lotId}`,
            providesTags: [apiTags.USER_LOT],
        }),
        getAllUserLotRelations: builder.query({
            query: () => `userlotrelation/userLots`,
            providesTags: [apiTags.USER_LOT],
        }),
        getAllRelations: builder.query({
            query: () => `userlotrelation/allrelations`,
            providesTags: [apiTags.USER_LOT]
        }),
        saveUserLotRelations: builder.mutation({
            query: relations => ({
                url: `userlotrelation`,
                method: "POST",
                body: relations,
            }),
            invalidatesTags: [apiTags.USER_LOT, apiTags.USERS]
        }),
        getUserPermissionClaims: builder.query({
            query: (userId) => `admin/users/permissions/${userId}`,
            providesTags: [apiTags.USER_PERMISSIONS]
        }),
        saveUserPermissionClaims: builder.mutation({
            query: (userClaims, userId) => ({
                url: `admin/users/permissions/${userId}`, 
                method: 'POST',
                body: userClaims
            }),
            invalidatesTags: [apiTags.USER_PERMISSIONS]
        }),
        createUser: builder.mutation({
            query: user => ({
                url: "admin/users/create",
                method: 'POST',
                body: user
            }),
            invalidatesTags: [apiTags.USERS]
        }),
        getUserRole: builder.query({
            query: (userId) => `admin/users/${userId}/roles`,
            providesTags: [apiTags.USER_ROLE]
        }),
        getClaimsByUserId: builder.query({
            query: (userId) => `admin/users/${userId}/claims`,
            providesTags: [apiTags.USER_CLAIMS]
        }),
        updateUserRestrictionClaims: builder.mutation({
            query: (userClaims, userId) => ({
                url: `admin/users/restrictions/${userId}`, 
                method: 'POST',
                body: userClaims
            }),
            invalidatesTags: [apiTags.USER_CLAIMS]
        }),
        updateUserInformation: builder.mutation({
            query: (userClaims, userRole, userId) => ({
                url: `admin/users/userInformation/${userId}`,
                method: 'POST',  
                body: userClaims, userRole              
            }),
            invalidatesTags: [apiTags.USER_CLAIMS, apiTags.USER_ROLE]
        }),
        userChangePassword: builder.mutation({
            query: user => ({
                url: `admin/users/changepassword`,
                method: 'POST',
                body: user
            })
        })
    }),
});

export const {
    useGetUsersQuery,
    useGetUserLotRelationsQuery,
    useGetUserLotRelationsForUserQuery,
    useGetUserLotRelationsByLotQuery,
    useGetAllUserLotRelationsQuery,
    useGetAllRelationsQuery,
    useSaveUserLotRelationsMutation,
    useGetUserPermissionClaimsQuery,
    useSaveUserPermissionClaimsMutation,
    useCreateUserMutation,
    useGetUserRoleQuery,
    useGetClaimsByUserIdQuery,
    useUpdateUserRestrictionClaimsMutation,
    useUpdateUserInformationMutation,
    useUserChangePasswordMutation
} = usersApi;

export const usersSlice = createSlice({
    name: 'usersSlice',
    initialState: {
        filters: {
            regionalManager: { value: '' }
        }
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
    },
});

export const { setFilters } = usersSlice.actions;