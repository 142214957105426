import { Grid } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MapIcon from '@material-ui/icons/Map';
import { useEffect, useState } from "react";
import AddPhoto from "../../../../components/AddPhoto";
import Authorize from "../../../../components/Authorize";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import NotificationProvider from "../../../../components/NotificationProvider";
import FileService from "../../../../services/fileService";
import { useDeleteAttachmentMutation, useGetAttachmentsQuery, useUploadAttachmentMutation } from "../../lotSlice";
import { lotAttachmentsDialogStyle } from "./LotDialogStyle";

const LotAttachmentsDialog = ({ lot, onClose }) => {

    const mapAttachmentType = 2;
    let { data: attachments, error: attachmentsError, isFetching: isLoadingAttachments } = useGetAttachmentsQuery(lot?.id, { skip: !lot?.id });
    let [uploadAttachment, { isLoading: uploading }] = useUploadAttachmentMutation();
    let [deleteAttachment, { isLoading: deleting }] = useDeleteAttachmentMutation();

    const [newAttachments, setNewAttachments] = useState([]);
    const [existingAttachments, setExistingAttachments] = useState([]);
    const [siteMap, setSiteMap] = useState();
    const [uploadingAttachments, setUploadingAttachments] = useState(false);

    const classes = lotAttachmentsDialogStyle();

    const handleDelete = async (img) => {
        let result = await deleteAttachment(img.id);
        if (result.error) {
            NotificationProvider.error("Failed to delete the attachment");
        } else {
            NotificationProvider.success("Successfully deleted the attachment");
        }
    };

    const addAttachment = image => {
        setNewAttachments([...newAttachments, image]);
    };

    const addMap = image => {
        setSiteMap({ ...image, attachmentType: mapAttachmentType });
    };

    const uploadAttachments = async () => {
        let result;
        setUploadingAttachments(true);
        let images = [];
        const attementsToAdd = [...newAttachments];
        if (!siteMap?.id) {
            attementsToAdd.push({ ...siteMap, imageTypeId: mapAttachmentType })
        }
        for (let attachment of attementsToAdd) {
            let base64Data = await FileService.getBase64Data(attachment.file);
            let image = {
                lotId: lot.id,
                fileName: attachment.file.name,
                fileData: base64Data,
                attachmentType: attachment.imageTypeId
            }
            images.push(image);
        }
        result = await uploadAttachment(images);
        if (result && result.error) {
            NotificationProvider.error(`Failed to upload attachments`);
        } else {
            NotificationProvider.success(`Successfully uploaded attachments`);
        }
        setNewAttachments([]);
        setUploadingAttachments(false);
        return result;
    };

    useEffect(() => {
        return () => {
            setNewAttachments([]);
            setExistingAttachments([]);
            setSiteMap();
        }
    }, []);

    useEffect(() => {
        if (lot?.id && !isLoadingAttachments) {
            const map = attachments?.find(a => a.attachmentType == mapAttachmentType);
            setExistingAttachments(attachments?.filter(a => a.attachmentType != mapAttachmentType));
            setSiteMap(map);
        } else {
            setNewAttachments([]);
            setExistingAttachments([]);
            setSiteMap();
        }

    }, [attachments, lot?.id]);

    return (
        <Dialog open={!!lot?.id} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth='md' fullWidth>
            <DialogTitle id="form-dialog-title">{'Lot Attachments'}</DialogTitle>
            <DialogContent>
                <LoadingSpinner loading={isLoadingAttachments} />
                {lot?.pmfLot && <Grid container>
                    <Authorize profile={permissionProfiles.LOT.LOT_EDIT_CREATE}>
                        <Grid container alignItems="center"><MapIcon />Site Map</Grid>
                        <AddPhoto
                            limit={1}
                            newImages={siteMap?.id ? [] : siteMap ? [siteMap] : []}
                            existingImages={siteMap?.id ? [siteMap] : []}
                            addImage={addMap}
                            label='Add Site Map'
                            onDelete={handleDelete}
                            accept={'image/svg+xml'}
                        />
                    </Authorize>
                </Grid>}
                <Grid container>
                    <Authorize profile={permissionProfiles.LOT.LOT_EDIT_CREATE}>
                        <Grid container alignItems="center"><AttachFileIcon />Attachments</Grid>
                        <AddPhoto
                            newImages={newAttachments ?? []}
                            existingImages={existingAttachments ?? []}
                            addImage={addAttachment}
                            label='Add Attachment'
                            onDelete={handleDelete}
                        />
                    </Authorize>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>

                {(!!newAttachments?.length || (siteMap && !siteMap?.id)) && <Authorize profile={permissionProfiles.LOT.LOT_EDIT_CREATE}>
                    <Button onClick={uploadAttachments} color="primary" variant="contained" disabled={uploading}>
                        {uploadingAttachments ? 'Saving...' : 'Save'}
                    </Button>
                </Authorize>}
            </DialogActions>
        </Dialog>
    );
};


export default LotAttachmentsDialog;
