import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { useState } from "react";
import CustomInput from "../../../../../components/CustomInput";
import NotificationProvider from "../../../../../components/NotificationProvider";
import { useGetLotsQuery } from "../../../../lots/lotSlice";
import { useBulkUpdateMutation, useGetVehicleStatusQuery } from "../../../vehicleSlice";
import { auditBulkStyles } from "./AssetBulkUpdateStyle";
import { getLotsWithPmfText } from "../../../../../features/lots/lotUtil";
import usePrompt from "hooks/usePrompt";

const AssetBulkUpdate = ({ assetIds }) => {
    const classes = auditBulkStyles();
    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery(null);
    let { data: vehicleStatuses, error: vehicleStatusError, isLoading: isLoadingVehicleStatus, isSuccess: isVehicleStatusSuccess } = useGetVehicleStatusQuery();
    let [bulkUpdate, { data: bulkUpdateResult, error: bulkError, isLoading: bulkLoading }] = useBulkUpdateMutation();

    const fields = [
        { name: "Lot", key: "lotId" },
        { name: "Status", key: "statusId" },
    ];

    const [selectedLot, setSelectedLot] = useState();
    const [open, setOpen] = useState();
    const [selectedStatus, setSelectedStatus] = useState();
    const { triggerPrompt } = usePrompt();

    const onClose = () => {
        setOpen(false);
    }

    const onSave = async () => {
        triggerPrompt({
            title: 'Bulk Update Assets',
            content: `You are about to update the status or lot of ${assetIds?.length ?? 0} assets, are you sure?`,
            onConfirm: async () => {
                const res = await bulkUpdate(
                    {
                        lotId: selectedLot,
                        statusId: selectedStatus,
                        vehicleIds: assetIds
                    }
                );
                if (res?.error) {
                    NotificationProvider.error('Failed to bulk update the assets');
                } else {
                    NotificationProvider.success('Successfully updated the assets');
                    onClose();
                }
            },
        });

    }

    return (
        <>
            <Button variant="contained" color='primary' disabled={!assetIds?.length} onClick={() => setOpen(true)}>Bulk Update</Button>
            <Dialog open={open} onClose={() => onClose()} aria-labelledby='dialog-title'>
                <DialogTitle>
                    Bulk Update
                </DialogTitle>
                <DialogContent>
                    <Grid container direction="column" spacing={2}>
                        <Grid item className={classes.groupWrapper}  >
                            The changes will apply to {assetIds?.length} assets
                        </Grid>
                        <Grid item className={classes.groupWrapper}  >
                            <Grid container spacing={2} direction="column" >
                                <Grid item>
                                    <CustomInput
                                        label="Lot"
                                        value={selectedLot}
                                        elementType="dropdown"
                                        onChange={val => setSelectedLot(val)}
                                        values={getLotsWithPmfText(lots) ?? []}
                                        showEmpty
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.groupWrapper}  >
                            <Grid container spacing={2} direction="column" >
                                <Grid item>
                                    <CustomInput
                                        label="Status"
                                        value={selectedStatus}
                                        elementType="dropdown"
                                        onChange={val => setSelectedStatus(val)}
                                        values={vehicleStatuses ?? []}
                                        showEmpty
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color='primary'
                        disabled={bulkLoading}
                        onClick={onSave}>
                        {bulkLoading ? 'Saving...' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default AssetBulkUpdate;