import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from '@material-ui/core';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import CustomInput from 'components/CustomInput';
import NotificationProvider from 'components/NotificationProvider';
import PhoneInput from 'components/PhoneInput';
import { IconAccessCode, IconSend } from 'icons';
import { useState } from 'react';
import Validator from 'services/validator';
import { AccessCodeTypes } from '../accessConsts';
import { useSendAccessCodeMutation } from '../accessSlice';
import { GenerateAccessCodeStyle } from './style';
import AccessCodeLoader from './AccessCodeLoader/AccessCodeLoader';

const SendAccessCode = ({ accessGroup, lotId = undefined }) => {
    const classes = GenerateAccessCodeStyle()

    const [sendAccessCode, { isLoading }] = useSendAccessCodeMutation();

    const [dialogOpen, setDialogOpen] = useState(false);

    const onSend = async () => {
        const res = await sendAccessCode({
            accessGroupId: accessGroup?.id,
            lotId,
        })
        if (res && !res.error) {
            NotificationProvider.success("Successfully sent the access code");
        } else {
            NotificationProvider.error("Failed to send the access code.");
        }
    }

    return (
        <>
            <Authorize profile={permissionProfiles.ACCESS.MANAGE_ACCESS}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<IconSend className={classes.icon} />}
                    onClick={() => setDialogOpen(true)}
                    disabled={!accessGroup?.pocEmail && !accessGroup?.pocPhone}>
                    Resend
                </Button>
            </Authorize>
            <Dialog
                open={dialogOpen}
                onClose={() => { }}
                fullWidth
                maxWidth='sm'>
                <DialogTitle>Send Access Code</DialogTitle>
                <DialogContent>
                    <Grid container direction='column' spacing={2} >
                        <Grid item >
                            <div className={classes.paramTitle}>The access code will be sent using the following contact Information</div>
                            <div><b>Name:</b> {accessGroup?.pocName}</div>
                            <div><b>Phone Number:</b> {accessGroup?.pocPhone}</div>
                            <div><b>Email: </b>{accessGroup?.pocEmail}</div>
                        </Grid>
                    </Grid>
                    {/* <AccessCodeLoader show={true} /> */}
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => setDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button color="primary"
                        variant="contained"
                        onClick={onSend}
                        disabled={isLoading}
                        startIcon={<IconSend className={classes.icon} />}
                    >
                        {isLoading ? 'Sending...' : 'Send'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default SendAccessCode;