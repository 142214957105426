import { Button, Checkbox, Grid, IconButton } from '@material-ui/core';
import { orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from 'react-router-dom';
import DataListHeader from '../../../components/DataListHeader';
import DataTable from '../../../components/DataTable';
import ExportButton from '../../../components/ExportButton';
import LoadingSpinner from '../../../components/LoadingSpinner';
import PageComponent from '../../../components/Page/PageComponent';
import useSortOptions from '../../../hooks/useSortOptions';
import FilterService from '../../../services/filterService';
import FormatService from '../../../services/formatService';
import { useGetLotsQuery } from '../../lots/lotSlice';
import { statuses } from './auditConsts';
import { setAuditFilters, setSearchVal } from './auditFiltersSlice';
import AssetBulkUpdate from './components/AssetBulkUpdate';
import AssetResolveDuplicate from './components/AssetResolveDuplicate';
import { vehicleLotAuditStyle } from './VehicleLotAuditStyle';
import { useGetAuditsByUserQuery, useRunAuditMutation } from './lotAuditSlice';
import { ArrowBack } from '@material-ui/icons';

function VehicleLotAudit() {
    const classes = vehicleLotAuditStyle()
    const history = useHistory();

    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    lots = lots || [];
    let { data: audits, error: auditsError, isLoading: isAuditsingLots } = useGetAuditsByUserQuery(null);
    audits = audits?.data || [];

    let [runAudit, { data: auditResult, error: runError, isLoading: runLoading }] = useRunAuditMutation();

    let { id } = useParams();

    const formatAsset = (asset, index) => {
        return (
            {
                ...asset,
                lotName: lots?.find(l => l.id == asset.lotId)?.name,
                statusName: statuses[asset.status]?.label,
                dateInFormatted: FormatService.formatDate(asset.dateIn),
                dateOutFormatted: FormatService.formatDate(asset.dateOut),
                vehicles: asset.vehicles?.map(a => formatAsset(a)),
                index,
                info: asset//this is need since the DataTable needs to access a single named attribute of the object for each column.
            }
        )
    }
    const processedData = auditResult?.map((a, index) => formatAsset(a, index))

    // const auditResultMap = processedData?.reduce(function (map, obj) {
    //     map[obj.vehicleId] = obj;
    //     return map;
    // }, {});

    useEffect(async () => {
        let audit = audits?.find(a => a.id == id);
        let body = {
            lotId: audit.lotId,
            url: audit.fileUrl,
            email: audit.createdBy
        }
        const res = await runAudit(body);
    }, [])

    useEffect(() => {
        let lotId = lots?.find(l => l.id == audits?.find(a => a.id == id)?.lotId)?.id
        setSelectedLot(lotId);
    }, [lots])


    useEffect(() => {
        setSelected([]);
    }, [auditResult])

    const [selectedLot, setSelectedLot] = useState(lots?.[0]?.id);
    const [selected, setSelected] = useState([]);

    const onSelectRow = (id) => {
        let newValue = [...selected];
        if (selected.includes(id)) {
            newValue?.splice(selected.indexOf(id), 1);
        } else {
            newValue.push(id);
        }
        setSelected(newValue)
    }

    const mapExportData = v => ({
        VIN: v.vin,
        AssetId: v.assetId,
        Status: statuses[v.status]?.label,
        ['Asset status']: v.assetStatus,
        Lot: lots?.find(l => l.id == v.lotId)?.name,
        ['Last In']: v.dateInFormatted,
        ['Last Out']: v.dateOutFormatted
    });

    let columns = [
        { name: "", key: "vehicleId", width: 50, selectedByDefault: true, component: (id) => <Checkbox checked={selected.includes(id)} onChange={(e) => { onSelectRow(id) }} /> },
        { name: "VIN", sortKey: 'vin', key: "info", width: 200, selectedByDefault: true, component: (info) => <Link disabled={!info?.vehicleId} to={`/assets/detail/${info?.vehicleId}`}>{info?.vin}</Link> },
        { name: "Asset ID", key: "assetId", selectedByDefault: true },
        { name: "Status", key: "status", width: 300, selectedByDefault: true, component: (status) => <div className={classes.iconContainer}><div>{statuses[status]?.icon}</div>{statuses[status]?.label}</div> },
        { name: "Asset Status", key: "assetStatus", selectedByDefault: true },
        { name: "Lot", key: "lotId", selectedByDefault: true, component: (lotId) => <>{lotId && lots.find(l => l.id == lotId)?.name}</> },
        { name: "Last In", key: "dateIn", selectedByDefault: true, component: (dateIn) => <>{FormatService.formatDate(dateIn)}</> },
        { name: "Last Out", key: "dateOut", selectedByDefault: true, component: (dateOut) => <>{FormatService.formatDate(dateOut)}</> }
    ];

    const rowActions = (index) => {
        const row = processedData?.[index];
        if (row?.status == 5) {
            return [
                {
                    label: 'Resolve',
                    component: <AssetResolveDuplicate asset={row} />
                },
            ]
        }
        return [];
    };

    const auditResultFilterConfig = [
        { type: 'text', size: 2.5, title: 'Status', key: 'status', values: Object.keys(statuses).map(key => ({ id: key, name: statuses[key]?.label })) },
    ];


    const dispatch = useDispatch();
    const { filters, searchVal } = useSelector(state => state.auditFilters);

    const onDropFilter = (filterValue, prop) => {
        dispatch(setAuditFilters({ ...filters, ...{ [prop]: { value: filterValue } } }));
    };

    const onSearch = value => {
        dispatch(setSearchVal(value.trim()));
    }

    const goBack = () => {
        history.push('/assets/audit');
    };

    const [sortOptions, sortData] = useSortOptions();

    let filteredResults = FilterService.filter(filters, searchVal, processedData ?? [], auditResultFilterConfig);

    const isLoading = isLoadingLots;

    return (
        <>{isLoading ?
            <LoadingSpinner loading={isLoading} /> :
            <PageComponent header={
                <DataListHeader
                    title={(
                        <>
                            <IconButton onClick={() => goBack()} >
                                <ArrowBack />
                            </IconButton>
                            <span>{`Audit Lot ${selectedLot && auditResult?.length ? lots?.find(l => l.id == selectedLot)?.name : ''}`}</span>
                        </>
                    )}
                    data={filteredResults}
                    dropDownsConfig={auditResult?.length ? auditResultFilterConfig : undefined}
                    filters={filters}
                    searchVal={auditResult?.length ? searchVal : undefined}
                    onDropFilter={onDropFilter}
                    onSearch={auditResult?.length ? onSearch : undefined}
                    actionButtons={(
                        <Grid container spacing={1}>
                            <Grid item>
                                <AssetBulkUpdate assetIds={selected} />
                            </Grid>
                            <Grid item>
                                <ExportButton fileLabel="Export" data={processedData} mapExportData={mapExportData} />
                            </Grid>
                        </Grid>
                    )}
                />
            }>
                {auditResult?.length ? <DataTable

                    columns={columns}
                    rows={orderBy(
                        filteredResults,
                        sortOptions.columnToSort,
                        sortOptions.sortDirection
                    )}
                    rowIdentifier='index'
                    onSort={sortData}
                    sortDirection={sortOptions.sortDirection}
                    columnToSort={sortOptions.columnToSort}
                    actions={rowActions}
                    maxActionCount={1}
                /> :
                    <LoadingSpinner loading={runLoading} />}

            </PageComponent>
        }</>
    );
}

export default VehicleLotAudit;

