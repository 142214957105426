import { createTheme } from '@material-ui/core/styles';

const neutral = "#dde2e5";
const darkText = "#46535d";
const errorColor = "#ec2001";
const lightBlue = "#d4ecff";
const mediumBlue = "#0090fe";
const darkBlue = "#0061a9";
const feedbackSuccessDark = "#90c534";
const feedbackMedium = "#7fc7ff";
const feedbackLight = "#54b5ff";

const theme = createTheme({
    typography: {
        "fontFamily": `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500,
        color: "#46535d"
    },
    palette: {
        common: {
            black: "rgba(0, 0, 0, 1)",
            white: "#fff",
            darkText
        },
        background: {
            paper: "#fff",
            default: "#F3F5F6"
        },
        primary: {
            light: lightBlue,
            main: mediumBlue,
            dark: darkBlue,
            contrastText: "#fff"
        },
        feedback: {
            light: feedbackLight,
            main: feedbackMedium
        },
        secondary: {
            light: "#6f818f",
            main: "#323b42",
            dark: "#323b42",
            contrastText: "#fff"
        },
        neutral: {
            light: "rgba(0, 0, 0, 0.05)",
            medium: "#aab5bd",
            main: neutral
        },
        success: {
            main: feedbackSuccessDark,
            dark: feedbackSuccessDark,
        },
        warning: {
            main: "#f7be2d",
            dark: "#f7be2d",
        },
        error: {
            light: "#fe583e",
            main: errorColor,
            dark: "#f75050",
            contrastText: "#fff"
        },
        text: {
            primary: darkText,
            secondary: "rgb(71, 66, 66)",
            //disabled: "rgba(255, 255, 255, 0.38)",
            hint: "rgba(255, 255, 255, 0.87)"
        }
    },
    overrides: {
        MuiSvgIcon: {
            root: {
                fill: mediumBlue
            },
            colorError: {
                fill: errorColor
            }
        },
        MuiInput: {
            underline: {
                "&:before": {
                    borderBottomColor: "#FFFFFF"
                }
            },
        },
        MuiToolbar: {
            gutters: {
                paddingLeft: "74px"
            }
        },
        MuiButton: {
            root: {
                color: darkText,
                textTransform: "none"
            },
            contained: {
                boxShadow: "none",
                "&:hover": {
                    boxShadow: "none",
                }
            },
            outlined: {
                borderColor: mediumBlue,
                color: mediumBlue
            },
            label: {
                "& a": {
                    color: "white",
                    textDecoration: "none"
                }
            }
        },
        MuiFormLabel: {
            asterisk: {
                color: '#db3131',
                '&$error': {
                    color: '#db3131'
                },
            }
        },
        MuiSwitch: {
            colorPrimary: {
                '&.Mui-checked.Mui-disabled': {
                    color: mediumBlue,
                    opacity: '0.7',
                }
            },
        },
        MuiTooltip: {
            tooltip: {
                fontSize: "1em",
                whiteSpace: 'normal',
            }
        },
        MuiFormControlLabel: {
            label: {
                font: 'Montserrat',
                fontFamily: 'Montserrat'
            }
        },
        MuiTabs: {
            indicator: {
                backgroundColor: mediumBlue
            }
        },
        MuiTab: {
            root: {
                borderBottom: 1,
                fontSize: 14,
                fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif`,
                fontWeight: 600,
                textTransform: 'unset',
                "&:hover": {
                    backgroundColor: '#F3F5F6',
                },
                "&.Mui-selected": {
                    color: mediumBlue,
                }
            },
            selected: {
                color: `${mediumBlue} !important`,
            }
        },

    }
});

export { theme };
