import { Button, Grid, Paper, Switch } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import ReorderOutlinedIcon from '@material-ui/icons/ReorderOutlined';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Authorize from "../../../../components/Authorize";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import CustomInput from "../../../../components/CustomInput";
import DataSearchBar from "../../../../components/DataSearchBar";
import { IconDollar, IconParkingLot, IconPercent, IconSlip } from "../../../../icons";
import ContractHeader from "../../contracts/ContractHeader/ContractHeader";
import configStyles from "../configStyles";
import { ActiveLotsList } from "./components/ActiveLotsList";
import { resetBulkUpdate, setBulkEdit, useMonthlyBillingReportMutation, useRemoveLotFromClientMutation, useRemoveSubMarketFromClientMutation } from "./lotConfigSlice";
import LotsConfigStyle from "./LotsConfigStyle";

const useStyles = LotsConfigStyle;
const useConfigStyles = configStyles;

export const clientLotConfigModel = {
    pricePerSpot: 0,
    overage: 0,
    numberOfSpots: 0,
    clientAlias: ''
}

const LotsConfig = ({
    clientToEdit,
    onConfigChange
}) => {
    const configClasses = useConfigStyles();
    const classes = useStyles();
    const [monthlyBillingReport, { isLoading: removingLot }] = useMonthlyBillingReportMutation()
    const configName = 'lotConfig';
    const lotConfigParams = clientToEdit?.config?.[configName];

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetBulkUpdate());
        }

    }, []);

    const priceField = (fieldName, label, icon) => (
        field(
            fieldName,
            label,
            <CustomInput
                value={lotConfigParams?.[fieldName]}
                onChange={(val) => onConfigChange(configName, fieldName, parseInt(val))}
                icon={icon ? icon : <IconPercent />}
                elementType="input"
                numberOnly
            />, undefined, permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING
        )
    )

    const field = (fieldName, label, component, icon, profile) => (
        <Authorize profile={profile}>
            <Grid item className={configClasses.input}>
                <div className={configClasses.label}>{label} </div>
                <div className={configClasses.inputField}>
                    {component ??
                        <Authorize
                            profile={profile}
                            readonlyComponent={<div><b>{lotConfigParams?.[fieldName]}</b></div>}
                        >
                            <CustomInput
                                value={lotConfigParams?.[fieldName]}
                                onChange={(val) => onConfigChange(configName, fieldName, parseInt(val))}
                                icon={icon}
                                elementType="input"
                                numberOnly
                            />
                        </Authorize>}
                </div>
            </Grid>
        </Authorize>
    )

    const MonthlyBillingReportPrinting = async () => {
        const result = await monthlyBillingReport(clientToEdit.id);
        // if (result?.error) {
        //     NotificationProvider.error("Failed downloading Monthly Billing Reports");
        // } else {
        //     NotificationProvider.success("Successfully downloaded Monthly Billing Reports");
        // }
    }

    return (
        <>
            <Grid className={configClasses.monthlyBillingReport}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => MonthlyBillingReportPrinting()}>
                    Monthly Billing Report
                </Button>
            </Grid>
            <Paper className={configClasses.card}>
                <ContractHeader
                    label={'Pricing'}
                />
                <Grid container direction='column' spacing={1} xs={8}>
                    {field('baseNumberOfSpots', 'Base number of spots', undefined, <IconSlip />, permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_FIELDS)}
                    {priceField('lotMonthlyRetainer', 'Monthly retainer', <IconDollar />)}
                    {priceField('lotTechFee', 'Tech fee')}
                    {priceField('lotDiscount', 'Discount')}
                    {field('lotCrossMarketOverage', 'Cross market overage', undefined, <IconParkingLot />, permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING)}
                    {field('lotAutomateBilling', 'Automate billing', <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING}>
                        <Switch
                            color="primary"
                            checked={lotConfigParams?.lotAutomateBilling}
                            onChange={(e) => onConfigChange(configName, 'lotAutomateBilling', e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        /></Authorize>, undefined, permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING)}
                    {field('correctToRetainer', 'Correct to retainer', <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING}>
                        <Switch
                            color="primary"
                            checked={lotConfigParams?.correctToRetainer}
                            onChange={(e) => onConfigChange(configName, 'correctToRetainer', e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        /></Authorize>, undefined, permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING)}

                    {field('otherClientOccupied', 'Show occupied lots by other clients', <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING}>
                        <Switch
                            color="primary"
                            checked={lotConfigParams?.otherClientOccupied != false}
                            onChange={(e) => onConfigChange(configName, 'otherClientOccupied', e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        /></Authorize>, undefined, permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING)}
                </Grid>
            </Paper>

            <Lots clientToEdit={clientToEdit} />
        </>
    )
}
export default LotsConfig;

function Lots({ clientToEdit }) {
    let [removeClientLot, { isLoading: removingLot }] = useRemoveLotFromClientMutation()
    let [removeClientSubMarket, { isLoading: removingSubMarket }] = useRemoveSubMarketFromClientMutation()

    const [search, setSearch] = useState();
    const [selectedClientLots, setSelectedClientLots] = useState(clientToEdit?.clientLots);
    const [selectedClientSubMarkets, setSelectedClientSubMarkets] = useState(clientToEdit?.clientSubmarkets);

    const configClasses = useConfigStyles();

    const remove = async (clientLot) => {
        let res = true;

        if (clientLot?.id) {
            if (clientLot?.isSubMarket) {
                res = await removeClientSubMarket(clientLot);
            } else {
                res = await removeClientLot(clientLot);
            }
        } else {
            setSelectedClientLots([...selectedClientLots.filter(l => (clientLot?.isNew && !l?.isNew) || (l.id != clientLot?.id))])
        }
        return res;
    }

    useEffect(() => {
        setSelectedClientLots(clientToEdit?.clientLots);
        setSelectedClientSubMarkets(clientToEdit?.clientSubmarkets);
    }, [clientToEdit?.clientLots, clientToEdit?.clientSubmarkets]);

    return (<Paper className={configClasses.card}>
        <ContractHeader
            label={'Lots'}
        />
        <Grid container spacing={1} alignItems='center' justifyContent="space-between">
            <Grid item xs={7}>
                <DataSearchBar onSearch={(val) => setSearch(val)} searchVal={search} />
            </Grid>
            <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_EDIT}>
                <Grid spacing={1} item xs={5} container justifyContent="flex-end">
                    <Actions
                        hasNew={selectedClientLots?.find(a => a.isNew)}
                        onAdd={() => {
                            setSelectedClientLots([
                                ...selectedClientLots,
                                {
                                    clientId: clientToEdit.id,
                                    ...clientLotConfigModel,
                                    isNew: true
                                }])
                        }}
                    />
                </Grid>
            </Authorize>
        </Grid>
        <div>
            <ActiveLotsList
                search={search}
                activeClientSubmarkets={selectedClientSubMarkets}
                activeClientLots={selectedClientLots}
                onRemove={remove}
            />
        </div>
    </Paper>)
}


function Actions({ onAdd, hasNew }) {

    const { bulkEdit } = useSelector(state => state.lotConfigState);

    const classes = useStyles();

    const dispatch = useDispatch();

    return (
        <>
            <Button
                id="basic-button"
                onClick={() => {
                    dispatch(setBulkEdit(!bulkEdit));
                }}
                startIcon={<ReorderOutlinedIcon />}
                disabled={hasNew}
            >
                {'Bulk Edit'}
            </Button>
            <Button
                id="basic-button"
                onClick={() => onAdd()}
                startIcon={<AddIcon />}
                disabled={hasNew}
            >
                {'Add'}
            </Button>

        </>
    );
};