import { useState } from 'react';
import Editor, { BtnBold, BtnItalic, EditorProvider, Toolbar } from 'react-simple-wysiwyg';
import { editorStyle } from './style';

const RichTextEditor = ({ content, onChange }) => {
    const [html, setHtml] = useState(content);

    const classes = editorStyle();
    function onValueChange(e) {
        setHtml(e?.target?.value);
        onChange && onChange(e?.target?.value)
    }

    return (
        <div className={classes.editorWrapper}>
            <EditorProvider>
                <Editor
                    //containerProps={{ style: { border: '3px black solid' } }}
                    value={html}
                    onChange={onValueChange}
                >
                    <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                    </Toolbar>
                </Editor>
            </EditorProvider>
        </div>
    );
}
export default RichTextEditor;