import { makeStyles } from "@material-ui/core";

export const lotItemStyle = makeStyles((theme) => ({
    card: {
        margin: "0 1rem 1rem 0",
        padding: '1.5rem 2rem 0 0',
        width: '100%'
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginTop: '0.5rem'
    },
    icon: {
        padding: '0.5rem',
        margin: '0 0.12rem 0 0.12rem',
        backgroundColor: '#eeeeee'
    },
    leftIcon: {
        marginTop: 5,
        marginRight: 10
    },
    title: {
        margin: 0,
        padding: 0
    },
    marketIconWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    iconWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    marketIcon: {
        marginRight: "0.5rem",
    },
    subTitle: {
        margin: 0,
        padding: 0,
        color: theme.palette.text.secondary,
        opacity: 0.6,
        fontWeight: 'lighter'
    },
    marketsList: {
        padding: "1em",
        color: theme.palette.text.secondary,
    },
    actionContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 20
    },
    errorIcon: {
        fill: '#0061a9',
        stroke: "#ffff",
        strokeWidth: 0.25
    }
}));
