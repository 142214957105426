import { Tab, Tabs } from "@material-ui/core";
import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";

export const VehicleTabs = ({ selected = 0, setSelected }) => {

    const tabs = [
        {
            label: 'Asset Details',
        },
        {
            label: 'Access Groups',
        }
    ]

    return (
        <TabsWrapper tabs={tabs} selectedTab={selected} setSelectedTab={setSelected} />
    )
}