import CustomInput from "components/CustomInput"
import LotsDropdown from "components/SharedDropdowns/LotsDropDown"
import { VendorField } from "./VendorField"
import { IconDollar } from "icons"
import { Checkbox, FormControlLabel } from "@material-ui/core"

export const activityPriceFields = {
    ACTIVITY: 1,
    VENDOR: 2,
    LOT: 3,
    VEHICLE_TYPE: 4,
    PRICE: 5,
    USE_INVENTORY: 6,
}

export const activityPriceConfig = {
    [activityPriceFields.ACTIVITY]: {
        name: 'Activity',
        component: (value, values, onChange, required, label) => <CustomInput
            id="ddlActivityId"
            label={label}
            elementType="dropdown"
            values={values}
            value={value}
            required={required}
            showEmpty={true}
            onChange={val => onChange(val)}
        />
    },
    [activityPriceFields.VENDOR]: {
        name: 'Vendor',
        component: (value, values, onChange, required, label) => <VendorField
            value={value}
            onChange={val => onChange(val)}
            label={label}
            required={required}
        />
    },
    [activityPriceFields.LOT]: {
        name: 'Lot',
        component: (value, values, onChange, required, label) => <LotsDropdown
            title={label}
            identifier='lot'
            values={values}
            onSelect={val => onChange(val)}
            value={value}
            showEmpty={true}
            required={required}
            pmfLot={true}
        />
    },
    [activityPriceFields.VEHICLE_TYPE]: {
        name: 'Vehicle Type',
        component: (value, values, onChange, required, label) => <CustomInput
            id="ddlActivityVehicleTypeId"
            label={label}
            elementType="dropdown"
            values={values}
            value={value}
            required={required}
            showEmpty={true}
            onChange={val => onChange(val)}
        />
    },
    [activityPriceFields.PRICE]: {
        name: 'Price',
        component: (value, values, onChange, required, label) => <CustomInput
            id="ddlActivityPrice"
            label={label}
            elementType="input"
            value={value}
            required={required}
            onChange={val => onChange(val)}
            numberOnly
            icon={<IconDollar />}
        />
    },
    [activityPriceFields.USE_INVENTORY]: {
        name: 'Use Inventory',
        component: (checked, onChange, label) => <FormControlLabel
            control = {<Checkbox
                        checked={checked}
                        onChange={onChange}
                    />}
            label = {label} 
            labelPlacement="start"
        />
    }
}