import { Button, Grid, IconButton, Paper } from "@material-ui/core";
import LoadingSpinner from "components/LoadingSpinner";
import { useGetMechanicalRuleTypesQuery, useGetMechanicalsConfigQuery } from "../../mechanicalsSlice";
import { lotMechanicalConfigStyles } from "./style";
import { ArrowBack } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import Rule from './Rule'
import { useState } from "react";
import { useEffect } from "react";
import LotParams from "./LotParams";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import ContractHeader from "features/clients/contracts/ContractHeader/ContractHeader";
import { useGetAllActivityItemsQuery } from "features/activity/activitySlice";
import DropdownFilter from "components/DropdownFilter";
import { setFilter } from "features/lots/lotSlice";
import FilterService from "services/filterService";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";

const LotMechanicalConfig = ({ clientId, lotConfig, onClose }) => {
    const classes = lotMechanicalConfigStyles();

    let { data: mechanicalsRules, error: mechanicalsRulesError, isLoading: mechanicalsRulesLoading } = useGetMechanicalsConfigQuery({ clientId, lotId: lotConfig.lotId });
    let { data: mechanicalsRulesTypes, error: mechanicalsRulesTypesError, isLoading: mechanicalsRulesTypesLoading } = useGetMechanicalRuleTypesQuery();
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();

    const [mechanicalsRulesToEdit, setMechanicalsRulesToEdit] = useState([])
    const [filters, setFilters] = useState({})

    useEffect(() => {
        setMechanicalsRulesToEdit(mechanicalsRules?.data?.rules);
    }, [mechanicalsRules]);

    const onAdd = () => {
        setMechanicalsRulesToEdit([
            {
                clientId: clientId,
                lotId: lotConfig?.lotId,
                isNew: true
            },
            ...mechanicalsRulesToEdit
        ])
    }
    const onCancel = () => {
        setMechanicalsRulesToEdit([...mechanicalsRulesToEdit.filter(l => !l?.isNew)])
    }

    const onFilter = (filterName, filterValue) => {
        setFilters({ ...filters, [filterName]: { value: filterValue } })
    }

    const filteredData = FilterService.filter(filters, undefined, mechanicalsRulesToEdit, [{ type: 'text', size: 2, key: 'activityId', values: activityItems }]);
    const filteredActivityItems = activityItems?.filter(a => mechanicalsRulesTypes?.find(m => m.activityId === a.id))

    return <Grid container direction='column' spacing={2} className={classes.lotContainer}>
        <LoadingSpinner loading={mechanicalsRulesLoading} />
        <Grid item>
            <Paper className={classes.lotName}>
                <IconButton onClick={onClose} >
                    <ArrowBack />
                </IconButton>
                {lotConfig?.name}
            </Paper>
        </Grid>
        <Grid item>
            {mechanicalsRules?.data?.config && <LotParams lotConfig={mechanicalsRules?.data?.config} clientId={clientId} lotId={lotConfig.lotId} />}
        </Grid>

        <Grid item container justifyContent="space-between">
            <ContractHeader
                label={`Mechanical Rules (${mechanicalsRulesToEdit?.length ?? 0})`}
            />
            <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.SAVE_CONFIG}>
                <Button
                    id="basic-button"
                    color="primary"
                    variant="outlined"
                    onClick={() => onAdd()}
                    startIcon={<AddIcon />}
                    disabled={mechanicalsRulesToEdit?.find(a => a.isNew)}
                >
                    {'Add Rule'}
                </Button>
            </Authorize>
        </Grid>
        {!!mechanicalsRulesToEdit?.length && <Grid item>
            <DropdownFilter
                title='Filter by activity...'
                onSelect={(val) => onFilter('activityId', val)}
                values={filteredActivityItems}
                value={filters?.activityId?.value}
                showEmpty
            />
        </Grid>}
        <Grid item>
            {
                filteredData?.map(rule => <Rule key={rule.id} rule={rule} onCancel={onCancel} />)//shouldn't be .data check why
            }
            {!(!!filteredData?.length) && <EmptyPlaceholder text="No Rules Defined" />}
        </Grid>

    </Grid>
}
export default LotMechanicalConfig;
