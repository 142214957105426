import { Grid, Paper } from "@material-ui/core";
import DataSearchBar from "components/DataSearchBar";
import DataTable from "components/DataTable";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import ExportButton from "components/ExportButton";
import useSortOptions from "hooks/useSortOptions";
import { orderBy } from "lodash";
import { useState } from "react";
import FilterService from "services/filterService";
import RankingDateFilters from "./RankingDateFilters/RankingDateFilters";
import { LotRankingListStyle } from "./style";

const LotRankingList = ({ setStartDate, setEndDate, lotRanking }) => {

    const classes = LotRankingListStyle();

    const [search, setSearch] = useState();

    const [sortOptions, sortData] = useSortOptions();

    const siteColumns = [
        { name: "Site", key: "name", width: 200 },
        { name: "Size", key: "siteSizeName" },
        { name: "Pay Hours", key: "totalHours" },
        { name: "Hours>TH", key: "totalHoursOverThreshold" },
        { name: "Rank", key: "rank" },
        { name: "Points", key: "hoursPoints" },
        { name: "Inspections Missed", key: "inspectionsMissed" },
        { name: "Points", key: "inspectionPoints" },
        { name: "Audit Points", key: "auditPoints" },
        { name: "Wash Under SLA", key: "washSlaPercentage", component: (washSlaPercentage) => <>{`${Math.round(washSlaPercentage)}%`}</> },
        { name: "Points", key: "washPoints" },
        { name: "1:1s", key: "oneOnOneCount" },
        { name: "Points", key: "oneOnOnePoints" },
        { name: "Total Points", key: "totalPoints" },
    ];

    const roundPoint = (point) => {
        return Math.floor(point * 100) / 100;
    }

    let lots = FilterService.filter([], search, lotRanking?.lotScores ?? [], []);
    lots = lots?.map(lot => ({
        ...lot,
        hoursPoints: roundPoint(lot.hoursPoints),
        totalPoints: roundPoint(lot.totalPoints),
        washPoints: roundPoint(lot.washPoints),
        auditPoints: roundPoint(lot.auditPoints),
        oneOnOnePoints: roundPoint(lot.oneOnOnePoints),
    }))

    const mapExportData = row => {
        const detailsInfo = {};
        siteColumns?.map(col => {
            detailsInfo[col.name] = row?.[col.key]
        });
        return detailsInfo;
    };

    return (

        <Paper className={classes.wrapper} elevation={1}>
            <RankingDateFilters setStartDate={setStartDate} setEndDate={setEndDate}/>
            <Grid justifyContent="space-between" container className={classes.searchWrapper}>
                <DataSearchBar
                    onSearch={(val) => setSearch(val)} searchVal={search}
                />
                <ExportButton label='Export' fileLabel="Export" data={lots} mapExportData={mapExportData} />
            </Grid>
            {!!lots?.length ?
                <DataTable
                    columns={siteColumns}
                    rowIdentifier='id'
                    rows={orderBy(
                        lots ?? [],
                        sortOptions.columnToSort ?? 'rank',
                        sortOptions.sortDirection
                    )}
                    onSort={sortData}
                    sortDirection={sortOptions.sortDirection}
                    columnToSort={sortOptions.columnToSort}
                /> : <EmptyPlaceholder text="No Data" />}
        </Paper>
    )
}

export default LotRankingList;