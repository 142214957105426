import React from 'react'
import SearchBar from "material-ui-search-bar";
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        boxShadow: 'none',
        border: 0,
        height: '100%'
    },
    input: {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.default
    }
}));

const DataSearchBar = (({ onSearch, searchVal }) => {
    const classes = useStyles();

    const cancelSearch = () => {
        onSearch("");
    };

    return (
        <SearchBar

            className={classes.root}
            value={searchVal}
            onChange={(val) => onSearch(val)}
            onCancelSearch={() => cancelSearch()}
            inputProps={{ className: classes.input, dataTestId: 'search-bar' }}
        />
    )
})

export default DataSearchBar
