import { Button, Grid, ListItem, ListItemIcon, Paper } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import EmptyPlaceholder from 'components/EmptyPlaceholder/EmptyPlaceholder';
import NotificationProvider from 'components/NotificationProvider';
import usePrompt from 'hooks/usePrompt';
import { useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList } from "react-window";
import AccessCodeList from '../AccessCodeList/AccessCodeList';
import AccessDialog from '../AccessDialog';
import GenerateAccessCode from '../GenerateAccessCode/GenerateAccessCode';
import SendAccessCode from '../GenerateAccessCode/SendAccessCode';
import { AccessGroupSetup } from '../accessConsts';
import { useDeleteAccessMutation, useSendAccessCodeMutation } from '../accessSlice';
import { AccessGroupListStyle } from './AccessGroupListStyle';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const AccessGroupList = ({ accessGroups, groupType }) => {
    const classes = AccessGroupListStyle()
    const { triggerPrompt } = usePrompt();

    let [deleteAccess, { isLoading: deleteAccessLoading }] = useDeleteAccessMutation();
    let [sendAccessCode, { isLoading: sending }] = useSendAccessCodeMutation();


    const [accessToEdit, setAccessToEdit] = useState()
    const [openDialog, setOpenDialog] = useState(false)

    const editAccess = (access) => {
        setAccessToEdit(access);
        setOpenDialog(true);
    }

    const handleClose = () => {
        setAccessToEdit(null);
        setOpenDialog(false);
    }

    const onDelete = async (access) => {
        triggerPrompt({
            title: "Delete Access Group",
            content: "Are you sure you want to delete this access group and associated access codes?",
            onConfirm: async () => {
                let result = await deleteAccess({ id: access.id });
                if (result && !result.error) {
                    NotificationProvider.success("Access deleted successfully");
                } else {
                    NotificationProvider.error("Failed to delete the access");
                }
            },
        });

    }

    const Row = ({ index, style }) => {
        const access = accessGroups[index];
        return <ListItem style={style} key={access.id} className={classes.listItem} >
            {AccessGroupSetup[groupType]?.cardComponent(access)}
            <ListItemIcon>
                <Grid container direction='column' spacing={1}>
                    <Grid item>
                        <SendAccessCode accessGroup={access} />
                    </Grid>
                    {/* <Grid item>
                        <Authorize profile={permissionProfiles.ACCESS.MANAGE_ACCESS}>
                            <Button
                                variant="outlined" //color="primary" 
                                onClick={() => editAccess(access)}
                                startIcon={<EditIcon className={classes.icon} />}>
                                Edit
                            </Button>
                        </Authorize>
                    </Grid> */}
                    <Grid item>
                        <GenerateAccessCode accessGroup={access} />
                    </Grid>
                    <Grid item>
                        <AccessCodeList accessGroup={access} />
                    </Grid>
                    <Grid item>
                        <Authorize profile={permissionProfiles.ACCESS.MANAGE_ACCESS}>
                            <Button
                                disabled={deleteAccessLoading}
                                variant="outlined" //color="primary" 
                                onClick={() => onDelete(access)}
                                startIcon={<DeleteOutlineIcon className={classes.icon} />}>
                                Delete
                            </Button>
                        </Authorize>
                    </Grid>
                </Grid>
            </ListItemIcon>
        </ListItem>
    };


    return (
        <>
            {!(!!accessGroups?.length) && <EmptyPlaceholder className={classes.listWrapper} />}
            {!!accessGroups?.length &&
                <>
                    <Paper className={classes.listWrapper}>
                        <AutoSizer className={classes.list}>
                            {({ height, width }) => (
                                <FixedSizeList
                                    className="List"
                                    height={height - 30}
                                    itemCount={accessGroups?.length}
                                    itemSize={400}
                                    width={width}
                                >
                                    {Row}
                                </FixedSizeList>
                            )}
                        </AutoSizer>
                    </Paper>
                    <AccessDialog access={accessToEdit} handleClose={handleClose} open={openDialog} isEdit={true} />
                </>}
        </>
    );
}

export default AccessGroupList;