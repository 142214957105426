import MapWrapper from "components/MapWrapper/MapWrapper";
import LotsMapMarker from 'features/maps/markers/LotsMapMarker/LotsMapMarker';

const LotsMap = ({ lots, handleSelectLot, isNarrowScreen }) => {

    const defaultProps = {
        center: {
            lat: 39.148171,
            lng: -101.763726
        },
        zoom: 4
    };

    var markers = lots?.map(lot => {
        return {
            ...lot,
            address: lot.addressLine1 + ', ' + lot.city + ', ' + lot.state,
            icon: <LotsMapMarker
                color={lot.pmfLot ? "#0090FE" : "#E48900"}
                text={lot.name}
            />,
            onClick: handleSelectLot,
            id: lot?.id
        }
    })

    return (
        <MapWrapper
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            markers={markers}
            includeSearch
            isNarrowScreen={isNarrowScreen}
        />
    )
}

export default LotsMap;