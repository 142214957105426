import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Paper, ListItem, IconButton, Tabs, Tab, Box } from '@material-ui/core';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import LoadingSpinner from 'components/LoadingSpinner';
import { useEffect, useState } from 'react';
import CustomInput from '../../../components/CustomInput';
import { useStyles } from "./AddActivityTypeStyles";
import {
    useAddActivityTypeMutation,
    useGetActivityFieldsTypesQuery,
    useGetAllActivityTypesQuery,
    useUpdateActivityTypeMutation,
    useGetAllActivityTypeInventoryConfigsQuery,
    useGetAllStepsToCompleteConfigQuery
} from 'features/activity/activitySlice';
import NotificationProvider from 'components/NotificationProvider';
import { Alert } from '@mui/material';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useGetAllInventoryQuery } from "../../inventory/inventorySlice";
import "./styles.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
    DragIndicatorOutlined
} from "@material-ui/icons";
import { Guid } from 'js-guid';
import { useGetLotsQuery } from "../../../features/lots/lotSlice";
import DropdownFilter from "../../../components/DropdownFilter";
import { TabsWrapper } from 'components/TabsWrapper/TabsWrapper';

function AddActivityType({ open, handleClose, activityTypeToEdit, isEditing, setIsEditing }) {
    const classes = useStyles();
    const [addActivityType, { isLoading }] = useAddActivityTypeMutation();
    const [editActivityType] = useUpdateActivityTypeMutation();
    const [activityToAdd, setActivityToAdd] = useState(activityTypeToEdit);
    const [showTypeAlert, setShowTypeAlert] = useState(false);
    const [showParamsAlert, setShowParamsAlert] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [itemList, setItemList] = useState([]);
    const [newStepDescription, setNewStepDescription] = useState();
    const [inventorySettings, setInventorySettings] = useState([]);
    const [nonDuplicateInventory, setNonDuplicateInvetory] = useState(true);
    const [globalLotId, setGlobalLotId] = useState();
    const [newDescriptionError, setNewDescriptionError] = useState(false);

    let { data: activityTypes, error: activityTypesError, isLoading: isLoadingActivityTypes } = useGetAllActivityTypesQuery();
    let { data: fieldsTypes, error: fieldsTypesError, isLoading: isLoadingFieldsTypes } = useGetActivityFieldsTypesQuery();

    let { data: getAllActivityTypeInventoryConfigs } = useGetAllActivityTypeInventoryConfigsQuery();
    getAllActivityTypeInventoryConfigs = getAllActivityTypeInventoryConfigs || [];

    let { data: inventories } = useGetAllInventoryQuery();
    inventories = inventories || [];

    const [inventoriesFilteredByLot, setInventoriesFilteredByLot] = useState([]);

    let { data: getAllStepsToCompleteConfig } = useGetAllStepsToCompleteConfigQuery();
    getAllStepsToCompleteConfig = getAllStepsToCompleteConfig || [];

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    lots = lots || [];

    getAllStepsToCompleteConfig = getAllStepsToCompleteConfig.map(a => ({
        ...a,
        id: a.id.toString()
    }));

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    activityTypes = activityTypes || [];
    fieldsTypes = fieldsTypes || [];

    useEffect(() => {

        let inventoryConfigs = getAllActivityTypeInventoryConfigs.filter(t => t.activityItemId == activityTypeToEdit?.id);

        if (inventoryConfigs.length !== 0) {
            setGlobalLotId(inventoryConfigs[0].lotId);
            let inventoryByLot = inventories.filter(t => t.lotId == inventoryConfigs[0].lotId);
            setInventoriesFilteredByLot(inventoryByLot);
        }
        let stepsToCompleteConfig = getAllStepsToCompleteConfig.filter(t => t.activityItemId == activityTypeToEdit?.id);

        let inventorySettingsList = { ...activityTypeToEdit };
        inventorySettingsList.relationEntities = inventoryConfigs;
        inventorySettingsList.stepsToComplete = stepsToCompleteConfig;

        setInventorySettings(inventoryConfigs);
        setItemList(stepsToCompleteConfig);
        setActivityToAdd(inventorySettingsList);
        setNewDescriptionError(false);
    }, [activityTypeToEdit]);

    // useEffect(() => {
    //     // let orderList = itemList.sort(function(a, b) { 
    //     //     return a.sequenceNumber - b.sequenceNumber;
    //     // })

    //     setItemList(itemList);
    // }, [itemList]);

    const onChange = (key, val) => {
        setActivityToAdd({ ...activityToAdd, [key]: val });
    };

    const onFilterInventoryByLot = (key, val) => {
        setGlobalLotId(parseInt(val));
        let inventoriesFilterByLot = inventories.filter(i => i.lotId === parseInt(val));
        setInventoriesFilteredByLot(inventoriesFilterByLot);

        let inventoryConfigs = getAllActivityTypeInventoryConfigs.filter(t => t.lotId == parseInt(val));
        setInventorySettings(inventoryConfigs);
    };

    const onClose = () => {
        setActivityToAdd({})
        setIsEditing(false)
        setShowTypeAlert(false)
        setShowParamsAlert(false)
        handleClose()
    }

    const onClickFinish = async () => {
        let result;

        activityToAdd.stepsToComplete = activityToAdd?.stepsToComplete.map((item, index) => ({
            ...item,
            id: item.isNew ? 0 : parseInt(item.id),
            sequenceNumber: item.sequenceNumber
        }));

        !isEditing ? result = await addActivityType(activityToAdd) :
            result = await editActivityType(activityToAdd)

        setIsEditing(false);

        if (result && !result.error) {
            NotificationProvider.success(isEditing ? 'Activity edited successfully' : "Activity added successfully");
            onClose();
        } else {
            NotificationProvider.error("Something happened, try again or contact support");
            onClose();
        }
    }

    const onSelectField = (param, value) => {
        if (value) {
            let paramTypes = activityToAdd?.paramTypes || [];
            paramTypes.push(param);
            setActivityToAdd({ ...activityToAdd, ['paramTypes']: paramTypes });
        }
        else {
            setShowParamsAlert(true)
            let paramTypes = activityToAdd?.paramTypes;
            paramTypes = param.paramSubType ? paramTypes.filter(p => p.paramSubType != param.paramSubType) :
                paramTypes.filter(p => p.paramType != param.paramType && p.paramSubType == null)
            setActivityToAdd({ ...activityToAdd, ['paramTypes']: paramTypes });
        }
    }

    const isValid = activityToAdd?.activityTypeId && activityToAdd?.name;
    const isSaving = isLoading;

    const getActivityTypeName = (id) => {
        let atype = activityTypes.find(t => t.id == id);
        return atype.name;
    }

    const onChangeActivityType = (val) => {
        onChange("activityTypeId", parseInt(val))
        setShowTypeAlert(true)
    }

    const onChangeInventory = (item, val) => {
        let inventoryExists = inventorySettings.some(x => x.inventoryId === parseInt(val));

        let inventorySettingsList = [...inventorySettings];
        let newItem = { ...item };
        newItem.inventoryId = parseInt(val);
        if (inventoryExists) {
            newItem.isDuplicated = true;
            setNonDuplicateInvetory(false);
        }
        else {
            newItem.isDuplicated = false;
            setNonDuplicateInvetory(true);
        }
        inventorySettingsList[inventorySettings.findIndex(i => i == item)] = { ...newItem };
        setInventorySettings(inventorySettingsList);

        setActivityToAdd({ ...activityToAdd, ['relationEntities']: inventorySettingsList });
    }

    const onDelete = (item) => {
        let inventorySettingsList = [...inventorySettings];
        inventorySettingsList = inventorySettingsList.filter(i => i != item);
        setInventorySettings(inventorySettingsList);

        let nonDuplicatedInventories = inventorySettingsList.some(x => x.isDuplicated === true);
        if (nonDuplicatedInventories) {
            setNonDuplicateInvetory(false);
        }
        else {
            setNonDuplicateInvetory(true);
        }

        setActivityToAdd({ ...activityToAdd, ['relationEntities']: inventorySettingsList });
    }

    const onAddNewInventorySetting = () => {
        let inventorySettingsList = [...inventorySettings];
        inventorySettingsList.push({ 'inventoryId': null, 'unitNumber': null, 'isDuplicated': false, 'lotId': parseInt(globalLotId) });
        setInventorySettings(inventorySettingsList);

        let relationInventoryEntities = activityToAdd?.relationEntities || [];
        relationInventoryEntities.push(inventorySettings);
        setActivityToAdd({ ...activityToAdd, ['relationEntities']: relationInventoryEntities });
    }

    const onChangeUnitNumber = (item, val) => {
        let inventorySettingsList = [...inventorySettings];
        let newItem = { ...item };
        newItem.unitNumber = parseInt(val);
        inventorySettingsList[inventorySettings.findIndex(i => i == item)] = { ...newItem };
        setInventorySettings(inventorySettingsList);

        setActivityToAdd({ ...activityToAdd, ['relationEntities']: inventorySettingsList });
    }

    const onDragEnd = ({ destination, source }) => {
        if (!destination) return;

        let reoderList = [...itemList];

        const result = Array.from(reoderList);
        const [removed] = result.splice(source.index, 1);
        result.splice(destination.index, 0, removed);

        reoderList = result.map((item, index) => ({
            ...item,
            sequenceNumber: index
        }));

        setItemList(reoderList);
        setActivityToAdd({ ...activityToAdd, ['stepsToComplete']: reoderList });
    };

    const reorder = (itemList, startIndex, endIndex) => {
        const result = Array.from(itemList);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        let resultSequence = result.map((item, index) => ({
            ...item,
            sequenceNumber: index
        }));

        return resultSequence;
    };

    const onSelectFieldFromStepList = (item, val) => {
        let stepList = [...itemList];
        let newItem = { ...item };
        newItem.isCompleted = true;
        stepList[itemList.findIndex(i => i == item)] = { ...newItem };
        setItemList(stepList);

        setActivityToAdd({ ...activityToAdd, ['stepsToComplete']: stepList });
    };

    const onSelectFieldFromCompletedList = (item, val) => {
        let stepList = [...itemList];
        let newItem = { ...item };
        newItem.isCompleted = false;
        stepList[itemList.findIndex(i => i == item)] = { ...newItem };
        setItemList(stepList);

        setActivityToAdd({ ...activityToAdd, ['stepsToComplete']: stepList });
    };

    const onDeleteStepList = (item, val) => {
        let stepList = [...itemList];
        stepList = stepList.filter(i => i != item);

        let reoderList = stepList.map((item, index) => ({
            ...item,
            sequenceNumber: index
        }));

        setItemList(reoderList);

        setActivityToAdd({ ...activityToAdd, ['stepsToComplete']: reoderList });
    };

    const onAddStep = () => {
        if (typeof newStepDescription === "undefined" || newStepDescription === "") {
            setNewDescriptionError(true);
            return;
        }

        setNewDescriptionError(false);
        let stepList = [...itemList];

        stepList.push({
            'id': Guid.newGuid().StringGuid,
            'description': newStepDescription,
            isCompleted: false,
            sequenceNumber: 0,
            isNew: true
        });

        let reoderList = stepList.map((item, index) => ({
            ...item,
            sequenceNumber: index
        }));

        setItemList(reoderList);
        //setNewStepDescription("");

        let stepEntities = activityToAdd?.stepsToComplete || [];
        //stepEntities.push(stepList);
        setActivityToAdd({ ...activityToAdd, ['stepsToComplete']: reoderList });
    };

    const onChangeDescription = (index, val) => {
        if (typeof val === "undefined" || val === "") {
            setNewDescriptionError(true);
            setNewStepDescription("");
            return;
        }

        setNewDescriptionError(false);
        setNewStepDescription(val);
    }

    const Item = ({ index, item, dragItemStyle = {}, children }) => (
        <Draggable index={index} draggableId={item.id}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={{
                        ...provided.draggableProps.style,
                        ...(snapshot.isDragging ? dragItemStyle : {}),
                    }}
                >
                    {children(item, provided.dragHandleProps)}
                </div>
            )}
        </Draggable>
    );

    const List = ({ list, onDragEnd, dragListStyle = {}, ...props }) => (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                            ...(snapshot.isDraggingOver ? dragListStyle : {}),
                        }}
                    >
                        {list.sort(function (a, b) {
                            return a.sequenceNumber - b.sequenceNumber;
                        }).map((item, index) => (
                            <>
                                {item.isCompleted === false &&
                                    <Item
                                        key={item.id}
                                        index={index}
                                        item={item}
                                        {...props}
                                    />
                                }
                            </>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );

    const tabs = [
        {
            label: 'Activity type',
            component: <><DialogTitle >
                <div className={classes.dialogTitle} style={{ display: "flex", justifyContent: "center" }}>{!isEditing ? 'Create Activity Type' : 'Edit Activity Type'}</div>
            </DialogTitle>
                <DialogContent>
                    {!(isLoadingActivityTypes || isLoadingFieldsTypes) &&
                        <Grid container spacing={1} direction='column'>
                            <Grid item>
                                <CustomInput
                                    id="ddlActivityTypeId"
                                    label="Activity Type"
                                    elementType="dropdown"
                                    values={activityTypes}
                                    value={activityToAdd?.activityTypeId}
                                    required={true}
                                    showEmpty={true}
                                    onChange={val => onChangeActivityType(val)}
                                />
                            </Grid>
                            <Grid item>
                                <CustomInput
                                    id="ddlActivityName"
                                    label="Activity Name"
                                    elementType="input"
                                    classStyles={classes.dropdownStyle}
                                    value={activityToAdd?.name}
                                    required={true}
                                    onChange={val => onChange("name", val)}
                                />
                            </Grid>
                            {isEditing && showTypeAlert &&
                                <Grid item>
                                    <Alert severity='warning' className={classes.alert}>
                                        {'You are about to change the type of activity ' + getActivityTypeName(activityTypeToEdit.activityTypeId) +
                                            ', this change will also apply to all existing assets. Are you sure you want to proceed?'}
                                    </Alert>
                                </Grid>}
                            <Grid item className={classes.logicTitle}>
                                <div>Mechanical SLA Logic:</div>
                            </Grid>
                            {isEditing && showParamsAlert &&
                                <Grid item>
                                    <Alert severity='warning' className={classes.alert}>
                                        {'Please note that changing the mechanical rule config will affect the existing rules'}
                                    </Alert>
                                </Grid>
                            }
                            <Grid item container spacing={1} direction='column'>
                                {fieldsTypes?.map((item, index) =>
                                    <>
                                        <FormControlLabel
                                            control={<Checkbox
                                                defaultChecked={activityToAdd?.paramTypes?.find(a => a?.paramType === item?.paramType && a?.paramSubType === item?.paramSubType)}
                                                onChange={(e, value) => onSelectField(item, value)}
                                            />}
                                            label={item?.name}
                                            className={classes.paramTypes}
                                        />
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    }
                </DialogContent></>
        },
        {
            label: 'Set up steps',
            component: <div className="App1">
                <Grid container direction='column' xs={12}>
                    <Grid item className={classes.logicTitle} xs={12}>
                        <div>Steps List:</div>
                    </Grid>
                    <List
                        list={itemList}
                        onDragEnd={onDragEnd}
                    >
                        {(item, dragHandleProps) => (
                            <>
                                <Grid className='lineStepList'>
                                    <Grid className='subLineStepList'>
                                        <span {...dragHandleProps}>
                                            <DragIndicatorOutlined className='drag' />
                                        </span>
                                        {/* <FormControlLabel
                                        control={<Checkbox 
                                            onChange={(e, value) => onSelectFieldFromStepList(item, value)} 
                                        />}
                                        label={item.description}
                                    /> */}
                                        {item.description}
                                    </Grid>
                                    <Grid className='deleteIcon'>
                                        <span onClick={(e, value) => onDeleteStepList(item, value)} ><DeleteIcon /></span>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </List>
                </Grid>
                <Grid container xs={12} className='addNewStep'>
                    <Grid item xs={1} className={classes.dropDownLine}>
                        <IconButton edge="end" aria-label="delete"
                            onClick={() => onAddStep()}
                        >
                            <AddIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={11} className={classes.dropDownLine}>
                        <CustomInput
                            label=''
                            identifier='description'
                            //value={newStepDescription}
                            onChange={val => onChangeDescription("description", val)}
                        />
                        {newDescriptionError ? <span style={{ color: "red" }}>Description should not be empty</span> : ""}
                    </Grid>
                </Grid>
                {/* <Grid container spacing={1} direction='column' xs={12}>
                <Grid item className={classes.logicTitle} xs={12}>
                    <div>Steps Completed:</div>
                </Grid>
                {itemList.map((item, i) => (
                    <>
                    {item.isCompleted &&  <Grid className='line'>
                            <FormControlLabel
                                control={<Checkbox 
                                    defaultChecked={true} 
                                    onChange={(e, value) => onSelectFieldFromCompletedList(item, value)} 
                                />}
                                label={<span className='completedListDescription'>{item.description} </span>}
                            />
                                <span className='deleteIcon' onClick={(e, value) => onDeleteStepList(item, value)} ><DeleteIcon/></span>
                            </Grid>
                        }
                    </>
                ))}
            </Grid> */}
            </div>
        },
        {
            label: 'Inventory',
            component: <DialogContent>
                <div className={classes.input}>
                    <DropdownFilter
                        title='Lot'
                        identifier='lotId'
                        showEmpty
                        values={lots || []}
                        onSelect={(event) => onFilterInventoryByLot("lotId", event)}
                        value={parseInt(globalLotId)}
                        required={true}
                    />
                </div>
                {
                    inventorySettings?.map((item, index) => {
                        return <div key={item.id}>
                            <ListItem>
                                <div className={item.isDuplicated ? classes.duplicateActivityTypeInventory : ""}>
                                    <Grid container spacing={2} >
                                        <Grid item xs={5}>
                                            <CustomInput
                                                id="inventoryId"
                                                label="Inventory"
                                                elementType="dropdown"
                                                values={inventoriesFilteredByLot || []}
                                                value={item.inventoryId}
                                                required={true}
                                                showEmpty={true}
                                                onChange={val => onChangeInventory(item, val)}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <CustomInput
                                                id="unitNumber"
                                                label="Unit Number"
                                                elementType="input"
                                                value={item.unitNumber}
                                                required={true}
                                                onChange={val => onChangeUnitNumber(item, val)}
                                            />
                                        </Grid>
                                        <IconButton edge="end" aria-label="delete"
                                            onClick={() => onDelete(item)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                    <span style={{ color: "red" }}>{item.isDuplicated ? "Inventory already exist" : ""}</span>
                                </div>
                            </ListItem>
                        </div>
                    })
                }

                <DialogActions style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                        className={classes.addButton}
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={onAddNewInventorySetting}>
                        Add Inventory
                    </Button>
                </DialogActions>
            </DialogContent>
        }
    ]
    return (
        <>

            <Dialog open={open} onClose={onClose} >
                <Paper className={classes.paper}>
                    <TabsWrapper tabs={tabs} />
                    <DialogActions>
                        <div className={classes.btnContainer}>
                            <Button className={classes.button} variant="outlined"
                                onClick={onClose}>Cancel</Button>
                            <Button
                                onClick={onClickFinish}
                                disabled={!isValid || isSaving || !nonDuplicateInventory}
                                className={classes.button}
                                variant="contained" >
                                {isSaving ? "Saving..." : "Finish"}
                            </Button>
                        </div>
                    </DialogActions>
                </Paper>
            </Dialog>

            <LoadingSpinner loading={false} />
        </>
    );
}

export default AddActivityType;