import { Grid, Paper } from "@material-ui/core";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import moment from 'moment';
import { useSelector } from "react-redux";
import Authorize from "../../../../components/Authorize";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { useGetAllActivityItemsQuery, useGetAllActivityTypesQuery } from '../../../activity/activitySlice';
import { useGetMechanicalActivitiesQuery } from '../../../clients/contracts/MechanicalWorkConfig/mechanicalsSlice';
import ActivityTypesCard from "./ActivityTypesCard/ActivityTypesCard";
import CompletedWorkCard from "./CompletedWorkCard/CompletedWorkCard";
import { mechanicalDashboardStyles } from "./MechanicalDashboardStyles";
import { StatusEnumDescription } from "./MechanicalEnums";
import PendingWorkCard from "./PendingWorkCard/PendingWorkCard";
import RequestedWorkCard from "./RequestedWorkCard/RequestedWorkCard";
import SlaChartCard from "./SlaChartCard/SlaChartCard";
import PendingInvoiceCard from "./PendingInvoiceCard/PendingInvoiceCard";

const MechanicalDashboard = () => {

    const { filters } = useSelector(state => state.dashboardState);
    const classes = mechanicalDashboardStyles();

    let { data: mechanicalActivities, error: mechanicalActivitiesError, isLoading: isLoadingMechanicalActivities } = useGetMechanicalActivitiesQuery();
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let { data: activityTypes, error: activityTypesError, isFetching: isLoadingActivityTypes } = useGetAllActivityTypesQuery();
    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, error: clientsError, isLoading: isLoadingClients } = useGetClientsQuery();

    const filteredStartDate = moment(filters.date?.startDate).startOf('day');
    const filteredEndDate = moment(filters.date?.endDate).startOf('day');

    const filteredActivities = mechanicalActivities?.filter(v =>
        (!filters.lotId?.length || filters.lotId.includes(v.lotId))
        && (!filters.clientId || filters.clientId == v.clientId)
        && (!filters.date?.value || (filteredStartDate <= moment(v.activityDate).startOf('day') && filteredEndDate >= moment(v.activityDate).startOf('day')))
    );

    const enumList = StatusEnumDescription;

    const mapExportData = row => {
        return ({
            'Id': row.id,
            'Activity': activityItems?.find(a => a.id == row.activityItemId)?.name,
            'Activty Status': enumList.find(e => e.id == row.activityStatus)?.name,
            'Lot': lots?.find(l => l.id == row.lotId)?.name,
            'AssetID': row.vehicleId,
            'Within SLA': row.withinSla ? 'Yes' : 'No',
            'Client': clients?.find(c => c.id == row.clientId)?.name,
            'Price': row.price,
            'Revenue': row.revenue,
            'Activity Date': new Date(row.activityDate)?.toDateString()
        })
    };

    const isLoadingDashboardData = isLoadingMechanicalActivities || isLoadingActivityItems || isLoadingLots || isLoadingClients || isLoadingActivityTypes;

    return (
        <>
            {
                isLoadingDashboardData ? <LoadingSpinner loading={isLoadingDashboardData} /> :
                    <>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={3} >
                                <Paper elevation={1} className={classes.card}>
                                    <PendingWorkCard mechanicalActivities={filteredActivities} mapExportData={mapExportData} />
                                </Paper>
                            </Grid>
                            <Grid item xs={3} >
                                <Paper elevation={1} className={classes.card}>
                                    <Grid className={classes.wrapper} container direction="column">
                                        <RequestedWorkCard mechanicalActivities={filteredActivities} mapExportData={mapExportData} />
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={3} >
                                <Paper elevation={1} className={classes.card}>
                                    <Grid className={classes.wrapper} container direction="column">
                                        <PendingInvoiceCard mechanicalActivities={filteredActivities} mapExportData={mapExportData} />
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={3} >
                                <Paper elevation={1} className={classes.card}>
                                    <Grid className={classes.wrapper} container direction="column">
                                        <CompletedWorkCard mechanicalActivities={filteredActivities} mapExportData={mapExportData} />
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper className={classes.card} elevation={1}>
                                    <ActivityTypesCard mechanicalActivities={filteredActivities} activityItems={activityItems} />
                                </Paper>
                            </Grid>
                            <Authorize profile={permissionProfiles.DASHBOARD.WASH_SLA}>
                                <Grid item xs={6}>
                                    <Paper className={classes.card} elevation={1}>
                                        <SlaChartCard mechanicalActivities={filteredActivities} clients={clients} activityTypes={activityTypes}/>
                                    </Paper>
                                </Grid>
                            </Authorize>
                        </Grid>
                    </>
            }
        </>
    );
};

export default MechanicalDashboard;
