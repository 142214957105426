import { createSlice } from "@reduxjs/toolkit";
import { sum } from 'lodash';
import { baseApi } from "../../../app/baseApi";
import { apiTags } from "../../../app/store/apiTags";

const basePath = 'activityinvoice';

export const activityInvoiceApi = baseApi.injectEndpoints({
    reducerPath: 'loadInvoiceApi',
    endpoints: (builder) => ({
        getActivityInvoices: builder.query({
            query: (body) => ({
                url: `${basePath}?${Object.keys(body)?.filter(k => body[k]).map(k => `${k}=${body[k]}`)?.join('&')}`,

            }),
            providesTags: [apiTags.ACTIVITY_INVOICES],
            transformResponse: (response) => {
                return response.map(invoice => ({
                    ...invoice,
                }));
            }
        }),
        updateActivityInvoiceDetails: builder.mutation({
            query: (body) => ({
                url: `${basePath}/details`,
                method: "PUT",
                body
            }),
            invalidatesTags: [apiTags.ACTIVITY_INVOICES],
        }),
        updateActivityInvoiceTotals: builder.mutation({
            query: (body) => ({
                url: `${basePath}/totals`,
                method: "PUT",
                body
            }),
            invalidatesTags: [apiTags.ACTIVITY_INVOICES],
        }),
        getActivityReports: builder.mutation({
            query: reports => ({
                url: `${basePath}/Report`,
                method: "POST",
                body: reports,
                responseHandler: async (response) => {
                    response.blob().then(blob => {
                        // Creating new object of PDF file
                        const fileURL = window.URL.createObjectURL(blob);
                        // Setting various property values
                        let alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.download = 'Activity Reports.zip';
                        console.log(alink);
                        alink.click();
                    })
                    //window.location.assign(window.URL.createObjectURL(await response.blob())) 
                },
                cache: "no-cache"
            })
        }),

    })

});


export const {
    useGetActivityInvoicesQuery,
    useUpdateActivityInvoiceDetailsMutation,
    useUpdateActivityInvoiceTotalsMutation,
    useGetActivityReportsMutation,
} = activityInvoiceApi;

export const activityInvoiceStateSlice = createSlice({
    name: 'activityInvoiceStateSlice',
    initialState: {
        searchVal: '',
        filters: {
            'clientId': '',
            'month': '',
            'year': ''
        }
    },
    reducers: {
        setSearchVal: (state, action) => {
            state.searchVal = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setFilter: (state, action) => {
            state.filters = { ...state.filters, [action.payload.key]: action.payload.value }
        },
    },
});

export const { setSearchVal, setFilters, setFilter } = activityInvoiceStateSlice.actions;
