import { createUserManager } from 'redux-oidc';

const authority = process.env.REACT_APP_AUTH_URL || "https://dev-parqai-auth.azurewebsites.net";
const clientid = process.env.REACT_APP_AUTH_CLIENT_ID || "parqai-web-local";

// const authority = process.env.REACT_APP_AUTH_URL || "https://localhost:5001/";
// const clientid = process.env.REACT_APP_AUTH_CLIENT_ID || "parqai-web";

const userManagerConfig = {
    client_id: clientid,
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/`,
    response_type: 'code',
    scope: 'openid profile email role clientId pmf.api.read pmf.api.write lotId permission',
    authority,
    // silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
    // automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;