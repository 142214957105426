import ClientFields from "./AccessDialog/Steps/InfoFields/ClientFields"
import TransporterFields from "./AccessDialog/Steps/InfoFields/TransporterFields"
import VendorFields from "./AccessDialog/Steps/InfoFields/VendorFields"
import GroupTypeStep from "./AccessDialog/Steps/GroupTypeStep"
import AccessInfoStep from "./AccessDialog/Steps/AccessInfoStep"
import DefiningAccessStep from "./AccessDialog/Steps/DefiningAccessStep"
import AddLotsStep from "./AccessDialog/Steps/AddLotsStep"
import ClientAccessCard from "./AccessGroupList/Cards/ClientAccessCard"
import EmployeeAccessCard from "./AccessGroupList/Cards/EmployeeAccessCard"
import TransporterAccessCard from "./AccessGroupList/Cards/TransporterAccessCard"
import VendorAccessCard from "./AccessGroupList/Cards/VendorAccessCard"
import ConfirmAccessStep from "./AccessDialog/Steps/ConfirmAccessStep"
import CarrierAccessCard from "./AccessGroupList/Cards/CarrierAccessCard"
import CarrierFields from "./AccessDialog/Steps/InfoFields/CarrierFields"
import TransporterOverview from "./AccessDialog/Steps/Overview/TransporterOverview"
import CarrierOverview from "./AccessDialog/Steps/Overview/CarrierOverview"
import ClientOverview from "./AccessDialog/Steps/Overview/ClientOverview"
import VendorOverview from "./AccessDialog/Steps/Overview/VendorOverview"
import AccessValidator from "./AccessDialog/accessValidator"

export const AccessGroupTypes = {
    CLIENT: 1,
    CARRIER: 2,
    TRANSPORTER: 3,
    VENDOR: 4,
    EMPLOYEE: 5,
    SALES_AND_OWNERSHIP: 6,
}

export const AccessGroupSetup = {
    [AccessGroupTypes.CLIENT]: {
        id: AccessGroupTypes.CLIENT,
        name: 'Client',
        cardComponent: (access) => <ClientAccessCard access={access} />,
        showForClient:true
    },
    [AccessGroupTypes.CARRIER]: {
        id: AccessGroupTypes.CARRIER,
        name: 'Sub Client',
        cardComponent: (access) => <CarrierAccessCard access={access} />,
        showForClient:true
    },
    [AccessGroupTypes.TRANSPORTER]: {
        id: AccessGroupTypes.TRANSPORTER,
        name: 'Transporter',
        cardComponent: (access) => <TransporterAccessCard access={access} />,
        showForClient:true
    },
    [AccessGroupTypes.VENDOR]: {
        id: AccessGroupTypes.VENDOR,
        name: 'Vendor',
        cardComponent: (access) => <VendorAccessCard access={access} />,
        showForClient:true
    },
    [AccessGroupTypes.EMPLOYEE]: {
        id: AccessGroupTypes.EMPLOYEE,
        name: 'Employee',
        cardComponent: (access) => <EmployeeAccessCard access={access} />,
    },
    [AccessGroupTypes.SALES_AND_OWNERSHIP]: {
        id: AccessGroupTypes.SALES_AND_OWNERSHIP,
        name: 'Sales and Ownership',
        cardComponent: (access) => <EmployeeAccessCard access={access} />,
    },
}

export const AccessGroupTypesList = Object.values(AccessGroupSetup)

export const AccessDialogSteps = {
    1: {
        key: 1,
        title: () => 'Create Access',
        component: () => <GroupTypeStep />,
        isValid: (access) => AccessValidator.validateGroupTypeStep(access)
    },
    2: {
        key: 2,
        title: (type) => `Create ${AccessGroupSetup[type]?.name} Access`,
        component: () => <AccessInfoStep />,
        isValid: (access) => AccessValidator.validateAccessInfoStep(access)

    },
    3: {
        key: 3,
        title: () => 'Defining Access',
        component: () => <DefiningAccessStep />,
        isValid: () => true
    },
    4: {
        key: 4,
        title: () => 'Select the Sites',
        component: () => <AddLotsStep />,
        isValid: () => true
    },
    5: {
        key: 3,
        title: () => 'Confirm Access',
        component: () => <ConfirmAccessStep />,
        isValid: () => true
    },
}

export const AccessGroupInfoFields = {
    [AccessGroupTypes.CLIENT]: {
        key: AccessGroupTypes.CLIENT,
        fields: () => <ClientFields />,
        overview: () => <ClientOverview />
    },
    [AccessGroupTypes.CARRIER]: {
        key: AccessGroupTypes.CARRIER,
        fields: () => <CarrierFields />,
        overview: () => <CarrierOverview />
    },
    [AccessGroupTypes.TRANSPORTER]: {
        key: AccessGroupTypes.TRANSPORTER,
        fields: () => <TransporterFields />,
        overview: () => <TransporterOverview />
    },
    [AccessGroupTypes.VENDOR]: {
        key: AccessGroupTypes.VENDOR,
        fields: () => <VendorFields />,
        overview: () => <VendorOverview />
    },
    [AccessGroupTypes.EMPLOYEE]: {
        key: AccessGroupTypes.EMPLOYEE,
        fields: () => <></>,
        overview: () => <></>
    },
    [AccessGroupTypes.SALES_AND_OWNERSHIP]: {
        key: AccessGroupTypes.SALES_AND_OWNERSHIP,
        fields: () => <></>,
        overview: () => <></>
    },
}

export const AccessCodeTypes = {
    ONE_TIME: 1,
    RECURRING: 2
}
