import { modes } from "../modes";
import { roles } from "../roles";

export const tasksPermissionProfile = {
    TASK_VIEW_CLIENT: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.ACCOUNT_MANAGER]
    },
    TASK_LIST: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.ACCOUNT_MANAGER]
    },
}