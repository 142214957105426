import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './app/store/store';
import {Provider} from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import { ThemeProvider } from '@material-ui/core/styles';
import * as serviceWorker from './serviceWorker';
import {theme} from './styles/theme';
import userManager from './app/userManager';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <OidcProvider userManager={userManager} store={store}>
                <ThemeProvider theme={theme}>
                    <App/>
                </ThemeProvider>
            </OidcProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
