import { Button, Grid, IconButton, ListItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useEffect } from 'react';
import CustomInput from '../../../../../components/CustomInput';
import { getLotsWithPmfText } from "../../../../../features/lots/lotUtil";
import { useGetLotsQuery } from '../../../../lots/lotSlice';
import { relationTypeEnum } from '../../../UsersEnums';
import { useGetUserLotRelationsForUserQuery, useSaveUserLotRelationsMutation } from '../../../usersSlice';
import usersPageStyle from '../../UsersPageStyle';
import { useState } from 'react';
import NotificationProvider from 'components/NotificationProvider';
import { selectIsOwner, selectUserId } from 'features/user/userSlice';
import { useSelector } from 'react-redux';
import AssociatedLotsDropDown from 'components/SharedDropdowns/LotsDropDown/AssociatedLotsDropDown';

const useStyles = usersPageStyle;

const EditUserLotRelations = ({ userId, setRelations, relations, onCancel }) => {
    const classes = useStyles();

    const loggedInUserId = useSelector(selectUserId);
   
    let { data: userLotRelations, error: relationsError, isLoading: isLoadingRelations } = useGetUserLotRelationsForUserQuery(userId, { skip: !userId });
    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: loggedInLotRelations, error: loggedInRelationsError, isLoading: isLoadingLoggedInRelations } = useGetUserLotRelationsForUserQuery(loggedInUserId, { skip: !loggedInUserId });
    userLotRelations = userLotRelations || [];
    lots = lots || [];
    loggedInLotRelations = loggedInLotRelations || [];

    let [saveRelations] = useSaveUserLotRelationsMutation();
    const [saving, setSaving] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);

    const isOwner = useSelector(selectIsOwner);

    useEffect(() => {
        userLotRelations = (!loggedInLotRelations.length && isOwner) ? userLotRelations : userLotRelations.filter(r => loggedInLotRelations.map(l => l.id).includes(r.id));
        setRelations(userLotRelations?.length ? userLotRelations : [{ 'userId': userId, 'relationType': null, 'lotId': null }]);
    }, [isLoadingRelations, userId]);

    const onAddNewRelation = () => {
        setHasChanged(true);
        let relationsList = [...relations];
        relationsList.push({ 'userId': userId, 'relationType': null, 'lotId': null });
        setRelations(relationsList);
    }

    const onChangeLot = (item, val) => {
        setHasChanged(true);
        let relationsList = [...relations];
        let newItem = { ...item };
        newItem.lotId = parseInt(val);
        relationsList[relations.findIndex(i => i === item)] = { ...newItem };
        setRelations(relationsList);
    }

    const onChangeRelationType = (item, val) => {
        setHasChanged(true);
        let relationsList = [...relations];
        let newItem = { ...item };
        newItem.relationType = parseInt(val);
        relationsList[relations.findIndex(i => i === item)] = { ...newItem };
        setRelations(relationsList);
    }

    const onDelete = (item) => {
        setHasChanged(true);
        let relationsList = [...relations];
        relationsList = relationsList.filter(i => i != item);
        setRelations(relationsList);
    }

    const handleSave = async () => {
        setSaving(true);

        let relationsToSave = {
            userId: userId,
            relationEntities: relations.filter(r => r.relationType && r.lotId)
        }

        let res = await saveRelations(relationsToSave);

        setSaving(false);

        if (!res?.error) {
            NotificationProvider.success('Saved successfully');
        } else {
            NotificationProvider.error('Failed to save');
        }

        setHasChanged(false);
    }

    const isLoading = () => isLoadingLots || isLoadingRelations;
    
    let isRM = loggedInLotRelations?.find(u => u.relationType === 3); //lot association with rm
    let isSuper = loggedInLotRelations?.find(u => u.relationType === 2);

    const getRelationTypeValues = () => {
        if (isSuper && !isRM) {
            return relationTypeEnum.filter(r => r.id !== 3)  
        } else return relationTypeEnum
    }

    return (
        <div>
            <div className={classes.label}> Lot Associations</div>
            {!isLoading() &&
                <div >
                    {
                        relations?.map((item, index) => {
                            return <div key={item.id}>
                                <ListItem>
                                    <Grid container spacing={2} >
                                        <Grid item xs={5}>
                                            <AssociatedLotsDropDown
                                                title={"Lot"}
                                                identifier={index}
                                                onSelect={val => onChangeLot(item, val)}
                                                showEmpty
                                                value={item.lotId}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <CustomInput
                                                key={index}
                                                label="Relation Type"
                                                elementType="dropdown"
                                                values={getRelationTypeValues()}
                                                value={item.relationType}
                                                onChange={val => onChangeRelationType(item, val)}
                                                showEmpty={true}
                                            />
                                        </Grid>
                                        <IconButton edge="end" aria-label="delete"
                                            onClick={() => onDelete(item)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </ListItem>
                            </div>
                        })
                    }
                </div>
            }

            <Grid container className={classes.buttonWrapper}>
                <Grid item >
                    <Button
                        className={classes.addButton}
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={onAddNewRelation}
                    >
                        Add lot association
                    </Button>
                </Grid>
                { hasChanged ?
                    <Grid item>
                        <Button
                            className={classes.saveButton}
                            onClick={handleSave}
                            color="primary"
                            variant="contained"
                            disabled={saving}
                        >
                            {saving ? 'Saving...' : 'Save'}
                        </Button>
                    </Grid> : null
                }
            </Grid>
        </div>
    );
};


export default EditUserLotRelations;
