import { Paper} from "@material-ui/core";
import { Grid } from "@mui/material";
import { IconDollar } from "icons";
import { activityInvoiceSummaryStyles } from "./style";
import { useState } from "react";


const ActivityInvoiceSummary = ({ invoices }) => {
    const classes = activityInvoiceSummaryStyles();

    const getTotalSum = (field) => {
        let sum = invoices.reduce((accumulator, object) => {
            return accumulator + object?.[field];
          }, 0);
        return sum
    }

    return (
        <>
            <Paper className={classes.wrapper}>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid item xs={0.5} className={classes.totalText}>Totals</Grid>
                    <Grid item xs={11} >
                        <Grid container alignItems={'center'} justifyContent='flex-end'>
                            {summaryColumns.map(count =>
                                <Grid item xs={3} container key={count.key} spacing={1} alignItems='center'>
                                    <Grid item ><div className={classes.icon}>{count.icon}</div></Grid>
                                    <Grid item>
                                        <div className={classes.amount}>{count.value(getTotalSum([count?.key]) ?? 0)}</div>
                                        <div className={classes.label}>{count.label}</div>
                                    </Grid>
                                </Grid>)}
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

export default ActivityInvoiceSummary;

export const summaryColumns = [
    {
        key: 'totalInternalCost',
        label: 'Total Internal Cost',
        icon: <IconDollar />,
        value: (v) => `$${v}`
    },
    {
        key: 'totalCharge',
        label: 'Total Charge',
        icon: <IconDollar />,
        value: (v) => `$${v}`
    },
    {
        key: 'totalRevenue',
        label: 'Total Revenue',
        icon: <IconDollar />,
        value: (v) => `$${v}`
    },
]