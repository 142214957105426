export const roles = {
    OWNER: 'owner',
    ADMIN: 'admin',
    CLIENT: 'client',
    ACCOUNT_MANAGER: 'account_manager',
    MACHANIC: 'mechanic'
}

export const roleInfo = {
    [roles.OWNER]: {
        label: "Owner"
    },
    [roles.ADMIN]: {
        label: "Admin"
    },
    [roles.CLIENT]: {
        label: "Client"
    },
    [roles.ACCOUNT_MANAGER]: {
        label: "Account Manager"
    },
    [roles.MACHANIC]: {
        label: "Mechanic"
    }
}