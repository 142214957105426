import { LinearProgress, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    labelsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: theme.palette.text.secondary,
        fontWeight: 'lighter'
    }
}));

const ProgressBar = ({ initLabel, endLabel, progress }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <LinearProgress variant={progress ? "determinate" : ""} value={progress} />
            <div className={classes.labelsContainer}>
                <p>{initLabel}</p>
                <p>{endLabel}</p>
            </div>
        </div>
    )
}

export default ProgressBar
