

//import { Button, ButtonGroup, Checkbox, FormControlLabel, FormGroup, Grid, makeStyles, Paper } from '@material-ui/core'
import DropdownFilter from '../../../../components/DropdownFilter';
import DatePicker from '../../../../components/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import moment from "moment";
import { Button, DialogContent, Dialog, DialogTitle, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { loadStatusUpdateStyle } from './LoadStatusUpdateStyle';
import statuses from '../../loadStatusesWithIcons';
import { useAddStatusMutation, useUpdateStatusMutation } from '../../loadsSlice';
import NotificationProvider from '../../../../components/NotificationProvider';
import CustomInput from '../../../../components/CustomInput';
import FormatService from '../../../../services/formatService';

function LoadStatusUpdate({ loadId, statusToEdit, forEdit = false, onClose }) {
    const classes = loadStatusUpdateStyle()

    let [addStatus, { isLoading: isLoadingAddStatus, isSuccess: addStatusSuccess }] = useAddStatusMutation();
    let [updateStatus, { isLoading: isLoadingUpdateStatus, isSuccess: updateStatusSuccess }] = useUpdateStatusMutation();

    const [open, setOpen] = useState(false);
    const [statusDate, setStatusDate] = React.useState(new Date());
    const [status, setStatus] = React.useState();
    const [comment, setComment] = React.useState();
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (statusToEdit?.id) {
            setOpen(!!statusToEdit);
            setStatusDate(FormatService.formatDateTime(statusToEdit?.statusDate));
            setStatus(statusToEdit?.status);
            setComment(statusToEdit?.comment)
        } else {
            setOpen(false);
        }
    }, [statusToEdit]);

    let statusConfig = Object.entries(statuses).map(([key, value]) => ({ name: value.label, id: key })) ?? [];
    const supportedStatues = ["1", "3", "4", "5", "6", "10", "11"];
    let filteredStatuses = statusConfig.filter(status => supportedStatues.includes(status.id));

    const onSelectStatus = (e) => {
        setStatus(e)
        if (e) {
            setShowError(false)
        }
        console.log(status)
    }

    const onSelectTime = (e) => {
        setStatusDate(e)
        console.log(statusDate)
    }

    const onUpdateStatus = async () => {
        if (!status) {
            setShowError(true)
        }
        else {
            setShowError(false)
            let utcStatusDate = new Date(statusDate);
            if (statusToEdit?.id) {
                let result = await updateStatus({
                    id: statusToEdit.id,
                    loadId: loadId,
                    status: status,
                    statusDate: new Date(utcStatusDate.toUTCString()),
                    comment: comment
                });
                if (result?.error) {
                    NotificationProvider.error(`Failed to update status`)
                } else {
                    NotificationProvider.success(`Successfully update status`);
                }
            } else {
                let result = await addStatus({
                    loadId: loadId,
                    status: status,
                    statusDate: new Date(utcStatusDate.toUTCString()),
                    comment: comment
                });
                if (result?.error) {
                    NotificationProvider.error(`Failed to update status`)
                } else {
                    NotificationProvider.success(`Successfully update status`);
                }
            }
            onClose && onClose();
            setOpen(false);
        }

    }
    const filterConfig = [
        {
            size: 12,
            title: 'Status',
            key: 'status',
            values: statusConfig,
            component: statusToEdit ? <b>{statusConfig?.find(s => s.id == status)?.name}</b> : <DropdownFilter
                title='Status'
                values={statusToEdit ? statusConfig : filteredStatuses}
                onSelect={onSelectStatus}
                value={status}
                showEmpty={true} />
        },
        {
            type: 'dateTime',
            size: 12,
            title: 'Date',
            key: 'date',
            component: <div className={classes.label}>
                <LocalizationProvider
                    dateAdapter={AdapterDateFns} >
                    <Stack spacing={3}>
                        <DateTimePicker
                            label='Date'
                            value={statusDate}
                            onChange={onSelectTime}
                            renderInput={(params) =>
                                <TextField {...params}
                                    sx={{ svg: { color: '#0090FE' } }}
                                />}
                        />
                    </Stack>
                </LocalizationProvider></div>
        },
        {
            type: 'text',
            size: 12,
            title: 'Comment',
            key: 'comment',
            component: <>
                {status == 10 && !comment && <div className={classes.commentReminder}>{'Please add a comment explaining what the exception is'}</div>}
                <CustomInput
                    id="comments"
                    label="Comments"
                    value={comment}
                    elementType="textarea"
                    onChange={val => setComment(val)}
                /></>
        },
    ];
    return (
        <div>
            {!forEdit && <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
            >
                Update status
            </Button>}
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                    onClose && onClose();
                }
                }
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Update Status</DialogTitle>
                <DialogContent>
                    <>
                        {showError &&
                            <div className={classes.error}>Please choose a status</div>
                        }
                        {filterConfig &&
                            filterConfig.map((ddConfig, index) => (
                                <>
                                    <Grid className={classes.box} key={index} item xs={ddConfig.size}>
                                        {ddConfig.component}
                                    </Grid>
                                </>
                            ))
                        }
                    </>
                    <Button
                        className={classes.dispatchButton}
                        variant="contained"
                        color="primary"
                        disabled={isLoadingAddStatus || isLoadingUpdateStatus}
                        onClick={onUpdateStatus}
                    >
                        {isLoadingUpdateStatus ? 'Saving' : 'Update'}
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default LoadStatusUpdate;