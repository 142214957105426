import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import CustomInput from "components/CustomInput";
import NotificationProvider from "components/NotificationProvider";
import { useApprovePendingInvoiceMutation } from "features/activity/activitySlice";
import { useEffect, useState } from "react";
import { approveActibityStyle } from "./style";


const ApprovePendingInvoiceActivity = ({
    activityLogs,
    bulk,
    setSelected
}) => {

    const classes = approveActibityStyle();
    const [approveActivity, { isLoading }] = useApprovePendingInvoiceMutation();
    const [cost, setCost] = useState(0);
    const [revenue, setRevenue] = useState(0);
    const [showError, setShowError] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (activityLogs?.length === 1) {
            setCost(activityLogs?.[0].price);
            setRevenue(activityLogs?.[0].revenue);
        } else {
            setCost(0);
            setRevenue(0);
        }
    }, [activityLogs]);

    const onSave = async () => {
        if (!bulk && (!cost || !revenue)) {
            setShowError(true);
            return;
        }
        setShowError(false);
        const res = await approveActivity({
            activityLogIds: activityLogs.map(a => a.id),
            price: !bulk ? cost : null,
            revenue: !bulk ? revenue : null
        });

        if (res?.data?.error) {
            NotificationProvider.error(`An error occured while approving the activity, please try again.`);
            return;
        } else {
            NotificationProvider.success(!bulk ? 'Successfully approved the activity and updated the price' : 'Successfully approved the activities');
            if (!!bulk)
                setSelected([]);
            else
                setOpen(false);
        }
    };


    return (
        <>
            {<Authorize profile={permissionProfiles.ACTIVITY.PENDING_INVOICE_ADD_PRICE}>
                <Button variant={bulk ? "contained" : undefined} disabled={!activityLogs?.length || isLoading} onClick={bulk ? () => onSave() : () => setOpen(true)} color='primary'>
                    {bulk ? `Approve Selected (${activityLogs?.length})` : 'Approve'}
                </Button>
            </Authorize>}
            <Dialog open={open && !bulk} onClose={() => setOpen(false)} >
                <DialogTitle id={'dialog-title'} >Approve Activity</DialogTitle>
                <DialogContent>
                    <Grid container direction='column' spacing={1}>
                        <Grid item>
                            <CustomInput
                                id="cost"
                                label="Internal Cost"
                                value={cost}
                                elementType="input"
                                required={true}
                                onChange={setCost}
                                valid={cost || !showError}
                                numberOnly
                                touched
                            />
                        </Grid>
                        <Grid item>
                            <CustomInput
                                id="cost"
                                label="Charge"
                                value={revenue}
                                elementType="input"
                                required={true}
                                onChange={setRevenue}
                                valid={revenue || !showError}
                                numberOnly
                                touched
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpen(false)} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={onSave} color='primary' variant="contained" disabled={isLoading}>
                        {isLoading ? 'Saving...' : 'Approve activity'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ApprovePendingInvoiceActivity;