import { Grid } from "@material-ui/core";
import ExportButton from "components/ExportButton";
import { IconDownload } from 'icons';
import ActivityListDialog from '../ActivityList/ActivityListDialog';
import { mechanicalDashboardStyles } from "../MechanicalDashboardStyles";
import { MechActivityStatusEnum } from "../MechanicalEnums";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";

const PendingInvoiceCard = ({ mechanicalActivities, mapExportData }) => {

    const classes = mechanicalDashboardStyles();

    const filteredActivities = mechanicalActivities?.filter(a => a.activityStatus == MechActivityStatusEnum.PENDING_INVOICE);

    return (
        <>
            <Grid className={classes.cardWrapper} container direction="column">
                <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.VIEW_ACTIVITY_DATA}>
                    <div className={classes.exportBtn}>
                        <ActivityListDialog status={MechActivityStatusEnum.PENDING_INVOICE} />
                        <ExportButton btnStyle='text' label="" icon={<IconDownload />} fileLabel="Export" data={filteredActivities} mapExportData={mapExportData} />
                    </div>
                </Authorize>
                <Grid item className={classes.count}>{filteredActivities?.length}</Grid>
                <Grid item><h3 className={classes.text}>Pending Invoice</h3></Grid>
            </Grid>
        </>
    )
}

export default PendingInvoiceCard;