import AssignmentIcon from '@material-ui/icons/Assignment';
import ChecklistIcon from '@material-ui/icons/Check';
import LotsConfig from "./clientConfigs/lots";
import TransportsConfig from "./clientConfigs/transports";
import WashConfig from "./clientConfigs/washes";
import BeenhereOutlinedIcon from '@material-ui/icons/BeenhereOutlined';
import BuildIcon from '@material-ui/icons/Build';
import MechanicalWorkConfig from './contracts/MechanicalWorkConfig/MechanicalWorkConfig';
import ConditionalReportConfig from './contracts/ConditionReportConfig/index';
import { IconTruck } from 'icons';

export const clientConfigTypes = {
    Transportation: 1,
    Wash: 2,
    Lot: 3,
    Readiness: 4,
    Mechanicals: 5,
    ConditionalReport: 6,
};

export const clientConfigSetup = (clientToEdit, onConfigChange) => {
    let list = [
        {
            label: 'Storage',
            configType: clientConfigTypes.Lot,
            configName: 'lotsConfig',
            component: <LotsConfig clientToEdit={clientToEdit} onConfigChange={onConfigChange} />,
            showOnCreate: false,
            icon: <img src="/icons/icon-parking.svg" />,
            isOn: () => true
        },
        {
            label: 'Transports',
            configType: clientConfigTypes.Transportation,
            configName: 'transportConfig',
            component: <TransportsConfig clientToEdit={clientToEdit} onConfigChange={onConfigChange} />,
            showOnCreate: true,
            icon: <IconTruck />,
            isOn: (clientToEdit) => {
                return clientToEdit?.config?.transportConfig?.useTransport
            }
        },
        {
            label: 'Wash',
            configType: clientConfigTypes.Wash,
            configName: 'washConfig',
            component: <WashConfig clientToEdit={clientToEdit} onConfigChange={onConfigChange} />,
            showOnCreate: true,
            isOn: (clientToEdit) => clientToEdit?.config?.washConfig?.useWashProgram
        },
        {
            label: 'Check-in',
            configName: 'checkin',
            icon: <ChecklistIcon />,
            showOnCreate: false,
            isOn: () => true
        },
        {
            label: 'Readiness',
            configType: clientConfigTypes.Readiness,
            configName: 'readiness',
            icon: <BeenhereOutlinedIcon />,
            showOnCreate: false,
            isOn: (clientToEdit) => !!clientToEdit?.readinessOptions?.length
        },
        {
            label: 'Mechanical Work',
            configType: clientConfigTypes.Mechanicals,
            component: <MechanicalWorkConfig
                // contract={contract}
                // open={open}
                // clientConfig={clientConfig}
                // name={contract.configName}
                // handleClose={handleClose}
                clientToEdit={clientToEdit}
                onConfigChange={onConfigChange}
            />,
            configName: 'mechanicals',
            icon: <BuildIcon />,
            showOnCreate: false,
            isOn: (clientToEdit) => clientToEdit?.config?.mechanicalsConfig?.useMechanicals
        },
        {
            label: 'Conditional Report',
            configType: clientConfigTypes.ConditionalReport,
            component: <ConditionalReportConfig
                clientToEdit={clientToEdit}
                onConfigChange={onConfigChange}
            />,
            configName: 'conditionalReport',
            icon: <AssignmentIcon />,
            showOnCreate: false,
            isOn: (clientToEdit) => clientToEdit?.config?.mechanicalsConfig?.useMechanicals
        }
    ];
    if (window.location.href.includes('demo.parq.ai') || window.location.href.includes('localhost')) {
        list.push({
            label: 'New Check-in',
            configName: 'newCheckin',
            icon: <ChecklistIcon />,
            showOnCreate: false,
            isOn: () => true
        },)
    }
    return list;
};
