import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Grid, Paper } from "@material-ui/core";
import formStyles from "../../features/vehicles/formStyles";
import CustomInput from "../../components/CustomInput";
import { useRestockInventoryMutation } from "./inventorySlice";
const useStyles = formStyles;

const RestockInventory = ({handleClose, inventoryId}) => {
    const classes = useStyles();
    let [restockInventoryToSave, setRestockInventory] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [restockInventory] = useRestockInventoryMutation();

    const onChange = async (key, val) => {
        setRestockInventory({ ...restockInventoryToSave, [key]: val});
    };

    const onSubmit = async () => {
        setIsSaving(true);
        restockInventoryToSave.quantity = parseInt(restockInventoryToSave.quantity);
        restockInventoryToSave.inventoryId = parseInt(inventoryId);
        restockInventoryToSave.notes = "Restocking Inventory";
        
        let result = await restockInventory(restockInventoryToSave);

        if (result && !result.error) {
            console.log("Inventory restocked successfully");
            handleClose();
        } else {
            console.log("Something happened, try again or contact support");
        }

        handleClose();
        setIsSaving(false);
    };

    return (
        <>
        <Paper className={classes.formContainer}>
            <form >
                <div className={classes.input}>
                    <CustomInput
                        id="quantity"
                        label="Quantity"
                        value={restockInventoryToSave?.quantity}
                        elementType="input"
                        onChange={val => onChange("quantity", val)}
                        required={true}
                    />
                </div>
                {/* <div className={classes.input}>
                    <CustomInput
                        id="notes"
                        label="Notes"
                        value={restockInventoryToSave?.notes}
                        elementType="textarea"
                        onChange={val => onChange("notes", val)}
                    />
                </div> */}
            </form>
            <div className={classes.btnContainer}>
                <div>
                    <Button className={classes.button} variant="outlined" onClick={() => handleClose()}>Cancel</Button>
                    <Button
                        onClick={onSubmit}
                        disabled={isSaving}
                        className={classes.button}
                        variant="contained"
                    >
                        Submit
                    </Button>
                </div>
            </div>
            </Paper>
        </>
    );
};

export default RestockInventory;