import { Button, Grid, Paper } from "@material-ui/core";
import DataListHeader from "components/DataListHeader";
import LoadingSpinner from "components/LoadingSpinner";
import PageComponent from "components/Page/PageComponent";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { selectUserClientId } from "features/user/userSlice";
import { relationTypeEnumById } from "features/users/UsersEnums";
import { useGetAllRelationsQuery } from "features/users/usersSlice";
import { groupBy, orderBy } from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterService, { fieldTypes } from "services/filterService";
import BasicModal from "../../../../../../components/BasicModal";
import InspectionForm from "../KpiInspection/InspectionReports/ReportDialog/InspectionForm";
import InspectionReports from "./InspectionReports/InspectionReports";
import { KpiInspectionStyles } from "./KpiInspectionStyle";
import { setFilters, useGetAllInspectionsQuery } from "./inspectionSlice";

const KpiInspection = ({ }) => {

    const classes = KpiInspectionStyles();

    let { data: allRelations, error: allRelationsError, isLoading: isLoadingAllRelations } = useGetAllRelationsQuery();
    allRelations = allRelations || [];

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    lots = lots || [];

    const pmfLots = lots?.filter(l => l.pmfLot)?.map(l => l.id);

    let { data: inspections, error: inspectionError, isLoading: isLoadingInspections } = useGetAllInspectionsQuery();
    inspections = inspections || [];

    let regionalManagers = [... new Set(allRelations?.filter(relation => relation.relationType === relationTypeEnumById.REGIONAL_MANAGER))];
    const groupedToUserId = groupBy(regionalManagers, 'userId');
    const dropdownValues = Object.keys(groupedToUserId).map((key) => ({ id: key, name: groupedToUserId[key]?.[0].userName }));

    inspections = inspections.map(ins => ({
        ...ins,
        needsAttentionFilter: ins.needsAttention ? '1' : '0',
        regionalManager: regionalManagers?.filter(r => r.lotId === ins.lotId)?.map(r => r.userId)
    }));

    let filterConfig = [
        { type: fieldTypes.LOT, size: 4, title: 'Lot', key: 'lotId', values: [...(pmfLots ?? [])], multiple: true, pmfLot: true },
        { type: fieldTypes.TEXT, size: 3, title: 'Inspections that Need Attention', key: 'needsAttentionFilter', values: [{ id: '1', name: 'Needs Attention' }, { id: '0', name: 'Valid Inspections' }] },
        { type: fieldTypes.VALUE_LIST, size: 3, title: 'Regional Manager', key: 'regionalManager', values: dropdownValues },
    ];

    const onDropFilter = (filterValue, prop) => {
        dispatch(setFilters({ ...filters, ...{ [prop]: { value: filterValue } } }));
    };

    const { filters } = useSelector(state => state.inspectionState);
    const filteredInspections = orderBy(FilterService.filter(filters, null, inspections, filterConfig), ['inspectionDate'], ['desc']);
    const dispatch = useDispatch();

    const userClientId = useSelector(selectUserClientId);

    let inspection = {
        userId: 0,
        inspectionDate: new Date(),
        numberOfTrucks: null,
        isBatchromCleaned: "true",
        bathroom: "",
        isGateSecured: "true",
        gateSecurity: "",
        isOfficeCleaned: "true",
        office: "",
        isYardCleaned: "true",
        yard: "",
        isThereAnyTrash: "true",
        trashRemoval: "",
        isParkAIWorking: "true",
        parqAI: "",
        otherIssuesReport: "",
        hoursVerification: "true",
        //lotId: parseInt(lotId),
        inspectionDocuments: [],
        needsAttentionFilter: null,
        regionalManager: ""
    }

    const isLoadingDashboardData = () => isLoadingLots || isLoadingAllRelations;
    const [openSelectFieldsPopup, setOpenSelectFieldsPopup] = useState(false);
    const onSelectExistingClick = () => {
        setOpenSelectFieldsPopup(true)
    }

    return (
        <>
            {
                isLoadingDashboardData() ? <LoadingSpinner loading={isLoadingDashboardData()} /> :
                    <>
                        <Grid>
                            <PageComponent className={classes.card} header={
                                <DataListHeader
                                    title={(
                                        <>
                                            <img src="/icons/icon-inspect.svg" className={classes.icon} />
                                            <h3 className={classes.subTitle}>Site Inspection</h3>
                                        </>
                                    )}
                                    data={filteredInspections}
                                    dropDownsConfig={filterConfig}
                                    filters={filters}
                                    onDropFilter={onDropFilter}
                                    actionButtons={(
                                        <Grid item xs={2} alignContent="flex-end" >
                                            <Button
                                                className={classes.button}
                                                variant="contained"
                                                color="primary"
                                                onClick={onSelectExistingClick}>Create report</Button>
                                        </Grid>
                                    )}
                                />} content={
                                    <Paper className={classes.paper}>
                                        <InspectionReports inspections={inspections} filteredInspections={filteredInspections} />
                                        <BasicModal
                                            open={openSelectFieldsPopup}
                                            header={<div className={classes.inspectionTitle}>Site Inspection</div>}
                                            wide={true}
                                            handleClose={() => setOpenSelectFieldsPopup(false)}
                                            component={<InspectionForm
                                                lotId={parseInt(inspection?.lotId)}
                                                inspection={inspection}
                                                handleClose={() => setOpenSelectFieldsPopup(null)} />}
                                        />
                                    </Paper>
                                }>
                            </PageComponent>
                        </Grid>
                    </>
            }
        </>
    );
};

export default KpiInspection;
