import { Grid, Paper } from "@material-ui/core";
import React from "react";
import { Styles } from "./taskItemStyles";
import {NavLink} from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import TaskStatusLabel from "./taskStatusLabel";



const TaskItem = ({ task, style, activityItems }) => {
    const classes = Styles();
    let assent = task?.descriptor ?? task?.assetId;
    let linkWork= `/workoder/${task.id}`;
    return (
        <Paper className={classes.wrapper} style={style}>
            <Grid container alignItems="center">
                <Grid container xs={9} spacing={1}>
                    <Grid item xs={10}>
                        <b style={{color:"#0090FE"}}>{(task?.descriptor == undefined ? "Assent ID: " :"VIN: " ) + assent}</b>
                    </Grid>
                    <Grid item xs={10}>
                          <label>
                              {activityItems?.find(a => a.id == task.activityItemId)?.name}
                          </label>  
                    </Grid>
                    <Grid item xs={10}>
                        <TaskStatusLabel status={task.activityStatus} />
                    </Grid>
                    <Grid container xs={1} alignContent='flex-end'>
                        {
                            <NavLink exact className={classes.link} to={linkWork}>
                                <ArrowForwardIcon title='View Asset' />
                            </NavLink>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Paper >
    )
}
export default TaskItem;