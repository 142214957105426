import { Grid, Typography } from "@material-ui/core";
import ExportButton from "components/ExportButton";
import { IconDownload } from 'icons';
import { groupBy } from "lodash";
import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import EmptyPlaceholder from "../../../../../components/EmptyPlaceholder/EmptyPlaceholder";
import { theme } from "../../../../../styles/theme";
import { mechanicalDashboardStyles } from '../MechanicalDashboardStyles';


const ActivityTypesCard = ({ mechanicalActivities, activityItems }) => {
    const classes = mechanicalDashboardStyles();

    const dataGrouped = groupBy(mechanicalActivities, 'activityItemId');

    const dataMapped = Object.keys(dataGrouped).map(key => {
        return ({
            activityItem: key,
            avg: Math.ceil(dataGrouped[key]?.reduce((sum, item) => sum + item.daysCompletedIn, 0) / dataGrouped[key]?.length) || 0
        })
    });

    const finalData = dataMapped.filter(item => item.avg !== 0);

    const mapExportData = row => {
        return ({
            'Activity Type': activityItems?.find(a => a.id == row.activityItem)?.name,
            'Average days to complete': row.avg
        })
    };

    return (
        <div>
            <Grid item>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid className={classes.titleContainer}>
                        <img src="/icons/icon-inspect.svg" className={classes.icon} />
                        <Typography className={classes.subTitle}>Activity Types</Typography>
                    </Grid>
                    <Grid item justifyContent="flex-end">
                        <ExportButton btnStyle='text' label="" icon={<IconDownload />} fileLabel="Export" data={finalData} mapExportData={mapExportData} />
                    </Grid>
                </Grid>
            </Grid>
            {!!finalData?.length ?
                <div style={{ width: "100%", height: finalData?.length * 40 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            layout="vertical"
                            data={finalData}
                            margin={{ top: 5, right: 30, left: 100, bottom: 5 }}
                        >
                            <XAxis
                                type="number"
                                hide
                                dataKey="avg"
                                axisLine={false}
                                tickLine={false}
                            />

                            <YAxis
                                type="category"
                                axisLine={false}
                                dataKey="activityItem"
                                tickLine={false}
                                tick={{ color: theme.palette.primary.main, fontSize: '1em', fontWeight: '500' }}
                                tickFormatter={activityId => {
                                    return activityItems?.find(a => a.id == activityId)?.name;
                                }}
                            />
                            <Tooltip
                                content={(val) => <GraphTooltip val={val} activityItems={activityItems} />}
                            />

                            <Bar
                                dataKey="avg"
                                fill={'#efc761'}
                            >
                                <LabelList dataKey="avg" position="outside" style={{ fill: theme.palette.primary.contrastText }} />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                :
                <EmptyPlaceholder text='No Activities' />}
        </div>
    )
}

export default ActivityTypesCard;

const GraphTooltip = ({ val, activityItems }) => {
    const classes = mechanicalDashboardStyles();
    return (
        <>
            {val.payload?.[0]?.value > 0 &&
                <div className={classes.tooltip}>
                    <div>{val.payload?.[0]?.value} {val.payload?.[0]?.value == 1 ? 'day' : 'days'} is the average time to complete {activityItems?.find(a => a.id == val.label)?.name.toLowerCase()} activities</div>
                </div>
            }
        </>
    )
}