//https://developers.google.com/maps/documentation
import { Loader } from '@googlemaps/js-api-loader';

const geoCode = (maps, address) => {
    let geocoder = new maps.Geocoder();
    return new Promise(function (resolve, reject) {
        geocoder.geocode({ 'address': address }, function (results, status) {
            if (status == 'OK') {
                const latLang = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
                resolve(latLang);
            }
            else {
                reject(status);
            }
        });
    });
}

//This can be used to calculate the route to the closest, not final code, but works if needed can be re-introduced
// const calculateClosest = (maps, startLatLang, endLocations, callbackFunction) => {
//     let geocoder = new maps.Geocoder();

//     function findLatLang(address) {
//         return new Promise(function (resolve, reject) {
//             geocoder.geocode({ 'address': address }, function (results, status) {
//                 if (status === 'OK') {
//                     resolve({ latitude: results?.[0]?.geometry?.location.lat(), longitude: results?.[0]?.geometry?.location.lng() });
//                 } else {
//                     reject(new Error('Couldnt\'t find the location ' + address));
//                 }
//             })
//         })
//     }

//     let locationData = [];
//     let start = new maps.LatLng(startLatLang?.latitude, startLatLang?.longitude);
//     let end = endLocations?.map(endLocation => endLocation?.latitude ? new maps.LatLng(endLocation?.latitude, endLocation?.longitude) : `${endLocation.city} ${endLocation.state} ${endLocation.zipCode} `);
//     for (let i = 0; i < end.length; i++) {
//         locationData.push(findLatLang(end[i]))
//     }

//     Promise.all(locationData)
//         .then(function (returnVals) {
//             console.log(returnVals);
//             var closestMarker = -1;
//             var closestDistance = Number.MAX_VALUE;
//             for (let i = 0; i < returnVals.length; i++) {
//                 var distance = maps.geometry.spherical.computeDistanceBetween(new maps.LatLng(returnVals[i]?.latitude, returnVals[i]?.longitude), start);
//                 if (distance < closestDistance) {
//                     closestMarker = returnVals[i];
//                     closestDistance = distance;
//                 }
//             }
//             callbackFunction(closestMarker, closestDistance);
//         })
// }

const calculateRoute = (directionsRenderer, directionsService, map, maps, startLocation, endLocation, callback) => {
    let start = startLocation?.address ?? (startLocation?.latitude ? new maps.LatLng(startLocation?.latitude, startLocation?.longitude) : `${startLocation.city} ${startLocation.state} ${startLocation.zipCode} `)
    let end = endLocation?.address ?? (endLocation?.latitude ? new maps.LatLng(endLocation?.latitude, endLocation?.longitude) : `${endLocation.city} ${endLocation.state} ${endLocation.zipCode} `)
    directionsRenderer.setMap(map);
    let request = {
        origin: start,
        destination: end,
        travelMode: 'DRIVING'
    };
    directionsService.route(request, function (result, status) {
        if (status == 'OK') {
            callback(null, result);
        }
        else {
            callback(status, null);
        }
    });
}

const calculateDistance = (maps, startLocation, endLocation, callbackFunction) => {
    const service = new maps.DistanceMatrixService();
    let start = startLocation?.latitude ? new maps.LatLng(startLocation?.latitude, startLocation?.longitude) : `${startLocation.city} ${startLocation.state} ${startLocation.zipCode} `
    let end = endLocation?.latitude ? new maps.LatLng(endLocation?.latitude, endLocation?.longitude) : `${endLocation.city} ${endLocation.state} ${endLocation.zipCode} `
    let request = {
        origins: [start],
        destinations: [end],
        travelMode: 'DRIVING',
        unitSystem: maps.UnitSystem.IMPERIAL,
    };

    service.getDistanceMatrix(request, function (response, status) {
        if (status == 'OK') {
            let distance;
            for (let i = 0; i < response.rows.length; i++) {
                let results = response.rows[i].elements;
                for (let j = 0; j < results.length; j++) {
                    let element = results[j];
                    distance = element?.distance?.value;
                }
            }
            callbackFunction(distance);
        }
    })
};

const convertAddressPartsToAddressString = ({ addressLine1, city, state, zipCode }) => {
    return `${addressLine1}, ${city}, ${state}, ${zipCode}`;
}

const convertDistanceToMiles = (distance) => {
    return Math.round(distance * 0.0621371192) / 100;
}

const googleMapsParams = {
    apiKey: 'AIzaSyC_crLykO9dT6xsEgF6qEoWCi0mbZjimwg',
    mapId: '892b6fc6a91c2e80',
    version: "beta",
    libraries: ['places', 'marker', 'geometry']
}
const loadGoogleMapApi = () => {
    const loader = new Loader({
        apiKey: googleMapsParams.apiKey,
        version: googleMapsParams.version,
        libraries: googleMapsParams.libraries
    });
    return new Promise(function (resolve, reject) {
        loader.load()
            .then((google) => {
                resolve(google);
            })
            .catch(e => {
                reject(e);
            });
    });

}

export const googleMapService = {
    googleMapsParams,
    calculateRoute,
    calculateDistance,
    //calculateClosest,
    convertAddressPartsToAddressString,
    convertDistanceToMiles,
    geoCode,
    loadGoogleMapApi
}
