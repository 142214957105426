import { makeStyles } from "@material-ui/core"

export const invoiceDetailsStyle = makeStyles((theme) => ({
    fieldWrapper: {
        whiteSpace: 'nowrap',
        "&:hover": {
            "& $icons": {
                opacity: 1,
            }
        }
    },
    field: {
        position: 'relative',
    },
    icons: {
        display: 'flex',
        position: 'absolute',
        right: '0',
        opacity: 0.1,
        top: '-2px'
    },
    groupWrapper: {
        whiteSpace: 'nowrap'
    },
    label: {
        fontWeight: 'bold',
    },
    groupLabel: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        margin: '0.5em 0'
    },
    headers: {
        color: theme.palette.primary.main,
    },
    totals: {
        fontSize: '1.2em'
    },
    totalSummary: {
        color: theme.palette.primary.main,
    },
    services: {
        margin: '1em 0'
    },
    service: {
        // whiteSpace: 'normal'
    }
}));