import { baseApi } from '../../../app/baseApi';
import { baseQuery } from "../../../app/baseQuery";
import { apiTags } from '../../../app/store/apiTags';

const apiPrefix = 'issuesTracker';
export const issuesTrackerApi = baseApi.injectEndpoints({
    reducerPath: 'issuesTrackerApi',
    tagTypes: [apiTags.ISSUES_TRACKER],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAllIssuesTracker: builder.query({
            query: ({ includeIssueTrackerHistory, cacheKey }) => `${apiPrefix}?includeIssueTrackerHistory=${includeIssueTrackerHistory}&c=${cacheKey}`,
            providesTags: [apiTags.ISSUES_TRACKER],
        }),
        getStatusIssueTracker: builder.query({
            query: () => `${apiPrefix}/status`,
            providesTags: [apiTags.ISSUES_TRACKER],
        }),
        addIssueTracker: builder.mutation({
            query: (issueTracker) => ({
                url: `${apiPrefix}`,
                method: "POST",
                body: issueTracker
            }),
            invalidatesTags: [apiTags.ISSUES_TRACKER],
        }),
        updateIssueTracker: builder.mutation({
            query: (issueTracker) => ({
                url: `${apiPrefix}`,
                method: "PUT",
                body: issueTracker
            }),
            invalidatesTags: [apiTags.ISSUES_TRACKER]
        }),
        bulkUpdateStatus: builder.mutation({
            query: issueTracker => ({
                url: `${apiPrefix}/bulk`,
                method: "POST",
                body: issueTracker
            }),
            invalidatesTags: [apiTags.ISSUES_TRACKER]
        })
    }),
});

export const {
    useGetAllIssuesTrackerQuery,
    useGetStatusIssueTrackerQuery,
    useAddIssueTrackerMutation,
    useUpdateIssueTrackerMutation,
    useBulkUpdateStatusMutation 
} = issuesTrackerApi;