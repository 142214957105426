import {createSlice} from "@reduxjs/toolkit";

export const loadFiltersSlice = createSlice({
    name: 'loadFilters',
    initialState: {
        searchVal: '',
        filters: {
            clientId: {value: null},
            latestStatus: {value: null},
            pickupLocationId: {value: null},
            deliveryLocationId: {value: null},
            pickupDates: {value: null},
            deliveryDates: {value: null},
            lmStatus: {value: null},
            //vinsForSearch: {value: null},
        },
        sortValues:{
            createdDate:{value: null},
            pickupStartDate:{value: null},
            latestStatusDate:{value: null},
        }
    },
    reducers: {
        setSearchVal: (state, action) => {
            state.searchVal = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setSortValue: (state, action) => {
            state.sortValues = action.payload;
        }
    },
});

export const { setSearchVal, setFilters, setSortValue } = loadFiltersSlice.actions;
export default loadFiltersSlice.reducer;
