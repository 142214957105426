import { useHistory } from "react-router-dom";
import DropdownFilter from "components/DropdownFilter"
import { InvoiceTypesList, InvoiceTypeRoute } from "../../invoiceConsts.ts";

const InvoiceScreenSelector = ({ selected }) => {
    const history = useHistory();
    const onChange = (selected) => {
        history.push(`${InvoiceTypeRoute[selected]}`)
    }
    return (
        <DropdownFilter
            values={InvoiceTypesList}
            value={selected}
            onSelect={onChange}
        />
    )
}

export default InvoiceScreenSelector;