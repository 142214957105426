import { createApi } from '@reduxjs/toolkit/query/react';
import { apiTags } from '../../app/store/apiTags';
import { baseQuery } from "../../app/baseQuery";

export const clientApi = createApi({
    reducerPath: 'clientApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getClients: builder.query({
            query: () => `client`,
            providesTags: [apiTags.CLIENT],
        }),
        getClientWithClientAccounts: builder.query({
            query: () => `client/accounts`,
            providesTags: [apiTags.CLIENT],
        }),
        getClientAccounts: builder.query({
            query: () => `account`,
            providesTags: [apiTags.CLIENT],
        }),
    }),
});

export const { useGetClientsQuery, useGetClientWithClientAccountsQuery, useGetClientAccountsQuery } = clientApi;
