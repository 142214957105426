import { makeStyles } from "@material-ui/core"

export const NotesStyle = makeStyles((theme) => ({
    noteWrapper: {
        //borderBottom: '1px solid #efefef',
        background: '#f3f3f3',
        borderRadius: '5px',
        padding: '0.5em 0.75em',
        '&:hover': {
            '&  $noteActions': {
                opacity: 1
            }
        },
    },
    title: {
        fontSize: '1.5em',
        cursor: 'pointer',
        fontWeight: 500
    },
    commentIcon: {
        marginRight: '0.2em'
    },
    noCollapseNoteList: {
        minHeight: '100px'
    },
    notesTooltip: {
        opacity: 0.2,
        transition: 'opacity 0.2s ease',
        '&:hover': {
            opacity: 1
        },
    },
    notesTooltipOn: {
        opacity: 0.7,
    },
    noteActions: {
        transition: 'opacity 0.2s ease',
        opacity: 0,
    },
    noteListWrapper: {
        padding: '1em 0'
    }
}));