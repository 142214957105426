import { Checkbox, Paper } from "@material-ui/core";
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import IconButton from '@mui/material/IconButton';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import DataListHeader from "components/DataListHeader";
import DataTable from "components/DataTable";
import { useGetAllActivityItemsQuery } from "features/activity/activitySlice";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { selectIsClient } from "features/user/userSlice";
import ApprovePendingApprovalActivity from "features/vehicles/Activities/ApproveActivity";
import ApprovePendingInvoiceActivity from "features/vehicles/Activities/ApproveActivity/ApprovePendingInvoiceActivity";
import useSortOptions from "hooks/useSortOptions";
import { orderBy } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import FilterService, { fieldTypes } from "services/filterService";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import { useGetMechanicalActivitiesQuery } from '../../../../clients/contracts/MechanicalWorkConfig/mechanicalsSlice';
import { mechanicalDashboardStyles } from "../MechanicalDashboardStyles";
import { MechActivityStatusEnum, StatusEnumDescription } from "../MechanicalEnums";
import { ActivityPriceError } from "./ActivityPriceError";

const ActivityListTable = ({ status = MechActivityStatusEnum.REQUESTED_BY_CLIENT, dialog = false }) => {
    const classes = mechanicalDashboardStyles()

    const isClient = useSelector(selectIsClient);
    let { data: mechanicalActivities, error: mechanicalActivitiesError, isLoading: isLoadingMechanicalActivities } = useGetMechanicalActivitiesQuery();
    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null, { skip: isClient });
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();

    const [sortOptions, sortData] = useSortOptions();
    const [selected, setSelected] = useState([]);
    const [search, setSearch] = useState('');
    const [filters, setFilters] = useState({
        activityStatus: { value: status }
    });


    useEffect(() => {
        const searchedVin = new URLSearchParams(window.location.search).get('vin');
        if (searchedVin) {
            setSearch(searchedVin)
        }
    }, [window.location.search])

    const history = useHistory();

    const statusEnumList = StatusEnumDescription;

    const filterConfig = [
        ...(isClient ? [] : [{ type: fieldTypes.TEXT, size: 2, title: 'Client', key: 'clientId', values: clients }]),
        { type: fieldTypes.LOT, size: 2, title: 'Lot', key: 'lotId', values: lots },
        { type: fieldTypes.DATERANGE, size: 2, title: 'Time Frame', key: 'activityDate', values: [] },
        { type: fieldTypes.TEXT, size: 2, title: 'Activity', key: 'activityItemId', values: activityItems },
        { type: fieldTypes.TEXT, size: 2, title: 'Status', key: 'activityStatus', values: statusEnumList },
    ];

    const getStatusName = (activity) => {
        let name = statusEnumList.find(e => e.id == activity.activityStatus)?.name;
        if (activity.activityStatus === MechActivityStatusEnum.PENDING_APPROVAL && activity.abovePriceCap) {
            name = `${name} (above price cap)`
        }
        return name;
    }

    const mappedData = mechanicalActivities?.map(activity => {
        const formattedDate = moment(activity.activityDate).format("YYYY-MM-DD");
        return {
            ...activity,
            activityItem: activityItems?.find(item => item.id == activity.activityItemId)?.name,
            activityItemId: activityItems?.find(item => item.id == activity.activityItemId)?.id,
            activityStatusName: getStatusName(activity),
            vehicle: { id: activity.vehicleId, descriptor: activity?.descriptor, assetId: activity?.assetId },
            vehicleIdentifer: `${activity.descriptor}$$${activity.assetId}`,
            client: clients?.find(client => client.id == activity.clientId)?.name,
            lot: lots?.find(lot => lot.id == activity.lotId)?.name,
            withinSla: activity.withinSla ? 'Yes' : 'No',
            activityDate: formattedDate,
            activityObj: activity
        }
    });

    const onSelectRow = (id) => {
        let newValue = [...selected];
        if (selected.includes(id)) {
            newValue?.splice(selected.indexOf(id), 1);
        } else {
            newValue.push(id);
        }
        setSelected(newValue)
    }

    const activityLookup = mappedData?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    let activityColumns = [
        {
            name: "", key: "id", width: 50, component: (id) => {
                const activity = activityLookup[id];
                if (activity.activityStatus !== MechActivityStatusEnum.PENDING_INVOICE) return <></>;
                return <Checkbox checked={selected.includes(id)} onChange={(e) => { onSelectRow(id) }} />;
            }
        },
        { name: "Activity", key: "activityItem" },
        { name: "VIN", key: "vehicle", width: 200, component: (vehicle) => <Link to={`/assets/detail/${vehicle?.id}`} className={classes.link}>{vehicle?.descriptor}</Link> },
        { name: "Asset Id", key: "vehicle", width: 200, component: (vehicle) => <Link to={`/assets/detail/${vehicle?.id}`} className={classes.link}>{vehicle?.assetId}</Link> },
        { name: "Status", key: "activityStatusName" },
        ...(isClient ? [] : [{ name: "Client", key: "client" }]),
        { name: "Lot", key: "lot" },
        { name: "Date", key: "activityDate" },
        { name: "Price", key: "price" },
        { name: "Revenue", key: "revenue" },
        { name: "Within SLA", key: "withinSla" },
        { name: "Alert", key: "activityObj", component: (activityObj) => <ActivityPriceError activity={activityObj} />, width: 50 },
    ];


    let filteredData = FilterService.filter(filters, search, mappedData, filterConfig);

    const onDropFilter = (filterValue, prop) => {
        setFilters({ ...filters, [prop]: { value: filterValue } });
    };

    const openInNewWindow = () => {
        history.push(`/mechanical/${status}`);
    }

    const isLoading = isLoadingLots || isLoadingClients || isLoadingActivityItems || isLoadingMechanicalActivities;

    const rowActions = (rowId) => {
        const row = mappedData?.find(activity => activity.id === rowId);
        const actions = [];
        if (row?.activityStatus == MechActivityStatusEnum.PENDING_APPROVAL) {
            actions.push(
                {
                    label: '',
                    component: <ApprovePendingApprovalActivity activityLog={row} />
                }
            );
        }
        if (row?.activityStatus == MechActivityStatusEnum.PENDING_INVOICE) {
            actions.push(
                {
                    label: '',
                    component: <ApprovePendingInvoiceActivity activityLogs={[row]} />
                }
            );
        }
        return actions;
    }

    return (
        <>
            <Paper className={classes.paper}>
                {
                    isLoading ? <LoadingSpinner loading={isLoading} /> :
                        <>
                            <Authorize profile={permissionProfiles.DASHBOARD.MECHANICAL_VIEW}>
                                <DataListHeader
                                    title={
                                        <div className={classes.titleWrapper}>
                                            <>{`Activities (${filteredData?.length})`}</>
                                            {dialog &&
                                                <IconButton onClick={openInNewWindow}>
                                                    <OpenInNewRoundedIcon />
                                                </IconButton>
                                            }
                                        </div>}
                                    data={mechanicalActivities}
                                    dropDownsConfig={filterConfig}
                                    filters={filters}
                                    onDropFilter={onDropFilter}
                                    onSearch={setSearch}
                                    searchVal={search}
                                    actionButtons={<>
                                        <ApprovePendingInvoiceActivity bulk activityLogs={selected.map(selectedId => activityLookup[selectedId])} setSelected={setSelected}/>
                                    </>}
                                />

                                <div className={`${dialog ? classes.dialogTable : classes.table}`} >
                                    <DataTable
                                        columns={activityColumns}
                                        rows={orderBy(
                                            filteredData,
                                            sortOptions.columnToSort,
                                            sortOptions.sortDirection
                                        )}
                                        rowIdentifier='id'
                                        onSort={sortData}
                                        sortDirection={sortOptions.sortDirection}
                                        columnToSort={sortOptions.columnToSort}
                                        noItemsMessage='There are no activities matching this criteria'
                                        actions={isClient ? undefined : rowActions}
                                        maxActionCount={isClient ? 0 : 1}
                                    />
                                </div>
                            </Authorize>
                        </>
                }
            </Paper >
        </>
    )
}

export default ActivityListTable;
