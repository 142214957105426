export const activityStatuses = {
    ItemRequestedByClient: 1,
    ItemRequestedByVendor: 2,
    ItemInProgress: 3,
    ItemCompleted: 4,
    PendingApproval: 5,
    PendingInvoice: 6,
    Cancelled: 7
}

export const activityStatusOrder = {
    [activityStatuses.PendingApproval]: 1,
    [activityStatuses.ItemRequestedByClient]: 2,
    [activityStatuses.ItemRequestedByVendor]: 2,
    [activityStatuses.ItemInProgress]: 3,
    [activityStatuses.PendingInvoice]: 4,
    [activityStatuses.ItemCompleted]: 5,
}
