export const apiTags = {
    VEHICLE: 'Vehicle',
    VEHICLES: 'Vehicles',
    VEHICLES_TYPES: 'VehiclesTypes',
    MARKETS: 'Markets',
    LOTS: 'Lots',
    LOT_ATTACHMENTS: 'LotAttachments',
    LOT_MAP: 'LotMap',
    LOT_MAP_URL: 'LotMapUrl',
    LOT_SUMMARY: 'LotSummary',
    LOT_SCORES: 'LotScores',
    CLIENT_CONFIG: 'ClientConfig',
    CLIENT_CHECKIN: 'ClientCheckIn',
    READINESS_CONFIG: 'ReadinessConfig',
    READINESS: 'Readiness',
    CLIENT_LOT_CONFIG: 'ClientLotConfig',
    CLIENT: 'Client',
    LOAD_INVOICES: 'LoadInvoices',
    STORAGE_INVOICES: 'StorageInvoices',
    STEP_DEFINITION: 'StepDefinition',
    STEP_DEFINITION_DETAILS: 'StepDefinitionDetails',
    CLIENT_VALUES: 'ClientValues',
    INSPECTION: 'Inspection',
    USER_LOT: 'UserLotRelations',
    LOADS: 'Loads',
    LOT_AUDIT: 'LotAudit',
    ONE_ON_ONE: 'OneOnOne',
    ISSUES_TRACKER: 'IssuesTracker',
    MECHANICALS_CONFIG: 'MechanicalsConfig',
    ACTIVITIES: 'activities',
    MECHANICAL_ACTIVITIES: 'mechanical_activities',
    ACTIVITY_LOG: 'activityLog',
    CONDITION_REPORT: "ConditionReport",
    ACTIVITY_INVOICES: 'activityInvoices',
    NOTES: 'notes',
    MECHANICAL_RULE_TYPES: 'mechanicalRuleTypes',
    ACTIVITY_TYPES: 'activityTypes',
    ACTIVITY_TYPE_PRICE: 'activityTypePrice',
    SERVICE_PROVIDER: 'serviceProvider',
    USER_CLAIMS: 'userClaims',
    INVENTORY: 'Inventory',
    UNIT_MEASURE: 'UnitMeasure',
    ACCESS: 'Access',
    CONFIGURATION: 'configuration',
    ACTIVITY_TYPE_INVENTORY: 'ActivityTypeInventory',
    ACTIVITY_TYPE_STEP: 'ActivityTypeStep',
    USERS: 'Users',
    USER_ROLE: 'UserRole',
    USER_PERMISSIONS: 'UserPermissions',
    MECHANICAL_TASK: 'MechanicalTask',
    MECHANICAL_WORK: 'MechanicalWork',
}