import { modes } from "../modes";
import { permissions } from "../permissions/permissions";
import { roles } from "../roles";

export const activityPermissionProfile = {
    ACTIVITY_UPLOAD: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER,roles.MACHANIC],
    },
    PENDING_APPROVAL_ADD_PRICE: {
        [modes.EDIT]: [roles.OWNER],
    },
    PENDING_INVOICE_ADD_PRICE: {
        [modes.EDIT_PERMISSION]: [permissions.ACTIVITIES.MANAGE_PENDING_INVOICE],
    },
    ABOVE_CAP_APPROVAL: {
        [modes.EDIT_PERMISSION]: [permissions.ACTIVITIES.APPROVE_ABOVE_PRICE_CAP],
    },
    ABOVE_CAP_VIEW: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER],
    }
}