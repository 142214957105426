import { Button, Dialog, DialogActions, ListItem, Paper } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList } from "react-window";
import Authorize from '../../../../components/Authorize';
import { permissionProfiles } from '../../../../components/Authorize/permissionProfiles';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder/EmptyPlaceholder';
import CancelLoad from '../CancelLoad';
import LoadCard from '../LoadCard';
import LoadDetails from '../LoadDetails';
import LoadDispatch from '../LoadDispatch';
import LoadStatusUpdate from '../LoadStatusUpdate/LoadStatusUpdate';
import PostLoad from '../PostLoad';
import { loadsListStyle } from './LoadsListStyle';
function LoadsList({ loads, readinessForLoads, contactsForLoad }) {
    const classes = loadsListStyle()
    const [selectedLoad, setselectedLoad] = useState();
    
    const history = useHistory();

    useEffect(() => {
        if (selectedLoad) {
            setselectedLoad(loads.find(l => l.id == selectedLoad.id))
        }
    }, [loads])

    const Row = ({ index, style }) => {
        const load = loads[index];
        return <ListItem style={style} key={load.id} className={classes.listItem} selected={selectedLoad?.id == load.id} >
            <LoadCard key={load.id} load={load} readiness={readinessForLoads[load?.id]} contacts={contactsForLoad[load?.id]} onViewClick={() => setselectedLoad(load)} />
        </ListItem>
    };

    return (
        <>
            {!(!!loads?.length) && <EmptyPlaceholder className={classes.listWrapper} />}
            {!!loads?.length &&
                <Paper className={classes.listWrapper}>

                    <AutoSizer>
                        {({ height, width }) => (
                            <FixedSizeList
                                className="List"
                                height={height}
                                itemCount={loads?.length}
                                itemSize={220}
                                width={width}
                            >
                                {Row}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </Paper>}
            <Dialog
                onClose={() => setselectedLoad()}
                open={!!selectedLoad}
                maxWidth={'lg'}
                fullWidth={true}
            >
                {
                    !!selectedLoad ? <LoadDetails load={selectedLoad} readiness={readinessForLoads[selectedLoad?.id]} /> : <></>
                }
                <div className={classes.footer}>
                    <Authorize profile={permissionProfiles.LOAD.LOAD_ACTIONS}>
                        <DialogActions>
                            {selectedLoad?.lmId && <Button
                                variant="contained"
                                color="primary"
                                onClick={() => history.push(`/invoices/loads/new/?loadId=${selectedLoad?.id}`)}
                            >
                                Create Invoice
                            </Button>}
                            {!selectedLoad?.lmId && <PostLoad load={selectedLoad} />}
                            {selectedLoad?.lmId && <LoadDispatch loadId={selectedLoad?.id} />}
                            <LoadStatusUpdate loadId={selectedLoad?.id} />
                            <CancelLoad loadId={selectedLoad?.id} />
                        </DialogActions>
                    </Authorize>
                </div>
            </Dialog>
        </>
    );
}

export default LoadsList;