import { modes } from "../modes";
import { roles } from "../roles";

export const clientConfigPermissionProfile = {
    CLIENT_CONFIG_MANAGE: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.ADMIN],
    },
    CLIENT_CONFIG_PRICING: {
        [modes.EDIT]: [roles.OWNER],
        [modes.READONLY]: [roles.ADMIN, roles.ACCOUNT_MANAGER]
    },
    CLIENT_CONFIG_BILLING_REPORT: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER],
    },
    CLIENT_CONFIG_FIELD_EDIT_INTERNAL: {
        [modes.EDIT]: [roles.OWNER],
        [modes.READONLY]: [roles.ADMIN, roles.ACCOUNT_MANAGER]
    },
    CLIENT_CONFIG_CHANGE: {
        [modes.EDIT]: [roles.OWNER],
    },
    CLIENT_CONFIG_FIELD_EDIT: {
        [modes.EDIT]: [roles.OWNER],
        [modes.READONLY]: [roles.CLIENT, roles.ADMIN, roles.ACCOUNT_MANAGER]
    },
    CLIENT_CONFIG_VIEW: {
        [modes.READONLY]: [roles.CLIENT]
    },
    CLIENT_CONFIG_EDIT_MOVEMENT_TYPES: {
        [modes.EDIT]: [roles.OWNER]
    },
    CLIENT_CONFIG_CREATE_LOTS: {
        [modes.EDIT]: [roles.OWNER],
        [modes.READONLY]: [roles.ADMIN, , roles.ACCOUNT_MANAGER, roles.CLIENT]
    },
    CLIENT_CONFIG_CREATE_LOTS_OFF: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER],
    },
    ASSET_STATUS_DELIVERED_LOAD: {
        [modes.EDIT]: [roles.OWNER],
        [modes.READONLY]: [roles.ADMIN, roles.ACCOUNT_MANAGER, roles.CLIENT]
    },
    CONTRACT_OFF: {
        [modes.EDIT]: [roles.OWNER],
        [modes.READONLY]: [roles.ADMIN, roles.ACCOUNT_MANAGER]
    },
    CONTRACT_ON: {
        [modes.EDIT]: [roles.OWNER],
        [modes.READONLY]: [roles.ADMIN, roles.CLIENT, roles.ACCOUNT_MANAGER]
    },
    CONTRACT_OFF_CLIENT: {
        [modes.EDIT]: [roles.CLIENT],
    },
    CLIENT_CONFIG_LOT_ALIAS: {
        [modes.EDIT]: [roles.CLIENT]
    },
    CLIENT_CONFIG_LOT_NAME: {
        [modes.EDIT]: [roles.OWNER],
        [modes.READONLY]: [roles.ADMIN, roles.ACCOUNT_MANAGER]
    },
    CLIENT_CONFIG_LOT_FIELDS: {
        [modes.EDIT]: [roles.OWNER],
        [modes.READONLY]: [roles.ADMIN, roles.CLIENT, roles.ACCOUNT_MANAGER]
    },
    CLIENT_CONFIG_LOT_EDIT: {
        [modes.EDIT]: [roles.OWNER],
    },
    CLIENT_CONFIG_LOT_PRICING: {
        [modes.EDIT]: [roles.OWNER],
    },
    WORKFLOW_SLA_EDIT: {
        [modes.EDIT]: [roles.OWNER],
        [modes.READONLY]: [roles.CLIENT, roles.ACCOUNT_MANAGER, roles.ADMIN]
    },
    READINESS_CONFIG_EDIT: {
        [modes.EDIT]: [roles.OWNER],
        [modes.READONLY]: [roles.CLIENT, roles.ACCOUNT_MANAGER, roles.ADMIN]
    },
    READINESS_CONFIG_ACTIONS: {
        [modes.EDIT]: [roles.OWNER],
    },
    WORKFLOW_SLA_ACTIONS: {
        [modes.EDIT]: [roles.OWNER]
    },
    CHECKIN_SLA_ACTIONS: {
        [modes.EDIT]: [roles.OWNER],
        [modes.READONLY]: [roles.ACCOUNT_MANAGER, roles.ADMIN, roles.CLIENT]
    },
    BUSINESS_UNITS_EDIT: {
        [modes.EDIT]: [roles.OWNER]
    },
    BUSINESS_UNITS_VIEW: {
        [modes.READONLY]: [roles.CLIENT, roles.ACCOUNT_MANAGER, roles.ADMIN]
    },
    BULK_UPDATE_PRICES: {
        [modes.EDIT]: [roles.OWNER]
    }
}