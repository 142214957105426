import { Button, Grid, Paper } from "@material-ui/core";
import DataListHeader from "components/DataListHeader";
import DataTable from "components/DataTable";
import { useCanDeleteMutation, useGetAllActivityItemsQuery, useGetAllActivityTypesQuery } from "features/activity/activitySlice";
import useSortOptions from "hooks/useSortOptions";
import { orderBy } from "lodash";
import { useState } from "react";
import FilterService from "services/filterService";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "./activityTypeFiltersSlice";
import { activityTypeStyle } from "./style";
import LoadingSpinner from "components/LoadingSpinner";
import PageComponent from "components/Page/PageComponent";
import AddActivityType from "../AddActivityType/AddActivityType";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import usePrompt from "hooks/usePrompt";
import { IconAdd } from "icons";
import { useGetMechanicalRuleTypesQuery } from "features/clients/contracts/MechanicalWorkConfig/mechanicalsSlice";
import DeleteActivityType from "../DeleteActivityType/DeleteActivityType"

const ActivityTypesList = ({ }) => {
    const classes = activityTypeStyle()
    const { triggerPrompt } = usePrompt();

    const [openDialog, setOpenDialog] = useState(false);
    const handleClose = () => setOpenDialog(false);
    const [activityTypeToEdit, setActivityTypeToEdit] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const handleDeleteClose = () => { setOpenDeleteDialog(false); setCanDeleteResponse() };
    const [canDeleteResponse, setCanDeleteResponse] = useState(null);
    
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let { data: activityTypes, error: activityTypesError, isFetching: isLoadingActivityTypes } = useGetAllActivityTypesQuery();
    let { data: mechanicalRuleTypes, error: mechanicalRuleTypesError, isFetching: isLoadingMechanicalRuleTypes } = useGetMechanicalRuleTypesQuery();
    let [ canDeleteApi, {data: canDelete, error: canDeleteError, isLoading: isLoadingCanDelete, refetch } ] = useCanDeleteMutation(activityTypeToEdit?.id);

    activityItems = activityItems?.map(activity => {
        return {
            ...activity,
            activityType: activityTypes?.find(item => item.id == activity.activityTypeId)?.name,
            paramTypes: mechanicalRuleTypes?.filter(a => a.activityId === activity?.id)
        }
    });

    const [sortOptions, sortData] = useSortOptions();
    const { filters } = useSelector(state => state.activityTypeFilters);
    const dispatch = useDispatch();

    const filterConfig = [
        { type: 'text', size: 2, title: 'Activity Type', key: 'activityTypeId', values: activityTypes },
    ];

    let activityTypeColumns = [
        { name: "Type", key: "activityType" },
        { name: "Activity", key: "name" }
    ];

    const onDropFilter = (value, prop) => {
        dispatch(setFilters({ ...filters, [prop]: { value } }));
    };

    let filteredData = FilterService.filter(filters, null, activityItems, filterConfig);

    const isLoading = isLoadingActivityItems || isLoadingActivityTypes;
    
    const onAdd = () => {
        setActivityTypeToEdit(null);
        setOpenDialog(true);
    }

    const rowActions = (rowId) => ([
        {
            icon: <EditIcon />,
            onClick: () => onEdit(rowId)
        },
        {
            icon: <DeleteIcon />,
            onClick: () => onDelete(rowId)
        }
    ]);

    const onEdit = (id) => {
       setActivityTypeToEdit(activityItems?.find(a => a.id == id));
       setOpenDialog(true)
       setIsEditing(true);
    };

    const onDelete = async (id) => {
        setOpenDeleteDialog(true);
        setActivityTypeToEdit(activityItems?.find(a => a.id == id));
        const result = await canDeleteApi(id);
        setCanDeleteResponse(!result.data.hasRequests);
    };

    return (
        <>
            {
                isLoading ? <LoadingSpinner loading={isLoading} /> :
                    <>
                        <PageComponent className={classes.card} header={
                            <DataListHeader
                                title={
                                    <div className={classes.titleWrapper}>
                                        Activity Types
                                    </div>}
                                data={activityItems}
                                dropDownsConfig={filterConfig}
                                filters={filters}
                                onDropFilter={onDropFilter}
                                actionButtons={(
                                    <>
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <Button variant="contained" color="primary"
                                                    onClick={onAdd} startIcon={<IconAdd />}>
                                                    Add Activity Type
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            />}>

                            <DataTable
                                columns={activityTypeColumns}
                                rows={orderBy(
                                    filteredData,
                                    sortOptions.columnToSort,
                                    sortOptions.sortDirection
                                )}
                                rowIdentifier='id'
                                onSort={sortData}
                                sortDirection={sortOptions.sortDirection}
                                columnToSort={sortOptions.columnToSort}
                                actions={rowActions}
                                noItemsMessage='There are no activities matching this criteria'
                            />

                            <AddActivityType open={openDialog} handleClose={handleClose} activityTypeToEdit={activityTypeToEdit} isEditing={isEditing} setIsEditing={setIsEditing} />
                            <DeleteActivityType open={openDeleteDialog} handleClose={handleDeleteClose} activityTypeToDelete={activityTypeToEdit} isLoading={isLoadingCanDelete} 
                                    canDeleteResponse={canDeleteResponse} setCanDeleteResponse={setCanDeleteResponse} />        
                        </PageComponent>
                    </>
            }
        </>
    )
}

export default ActivityTypesList;
