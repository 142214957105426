import React from 'react';
import { useSelector } from 'react-redux';
import {
    selectIsAccountManager,
    selectIsAdmin,
    selectIsAdminOnly,
    selectIsClient,
    selectIsMechanic,
    selectIsOwner,
    selectUserPermissions
} from '../../features/user/userSlice';
import { modes } from './modes';
import { roles } from './roles';

/**
 * The Authorize component is used for role or permission level authorization on specific components
 * @param {*} profile the profile defined in the permission profile
 * @param {*} readonlyComponent optional: 
 * by default if the profile has a readonly mode defined, the nested component will have the disabled prop injected to it. 
 * Use the readonlyComponent prop to provide a component to use for the readonly mode
 * @param {*} unauthorizedComponent optional: 
 * A component to dispaly when the user is not authorized to view the current component, by default an empty component will be rendered
 * @returns 
 */

const Authorize = ({ children, profile, readonlyComponent, unauthorizedComponent }) => {
    const isOwner = useSelector(selectIsOwner);
    const isAdmin = useSelector(selectIsAdminOnly);
    const isClient = useSelector(selectIsClient);
    const permissions = useSelector(selectUserPermissions);
    const isAccountManager = useSelector(selectIsAccountManager);
    const isMechanic = useSelector(selectIsMechanic);

    const roleChecker = {
        [roles.OWNER]: isOwner,
        [roles.ADMIN]: isAdmin,
        [roles.CLIENT]: isClient,
        [roles.ACCOUNT_MANAGER]: isAccountManager,
        [roles.MACHANIC]: isMechanic,
    }

    const hasPermissions = (permission) => {
        return permissions.includes(permission);
    }

    const readonly = profile?.[modes.READONLY]?.some(role => roleChecker[role]) || profile?.[modes.READONLY_PERMISSION]?.some(perm => hasPermissions(perm.key));
    const edit = !profile || profile?.[modes.EDIT]?.some(role => roleChecker[role]) || profile?.[modes.EDIT_PERMISSION]?.some(perm => hasPermissions(perm.key))

    if (!readonly && !edit) return unauthorizedComponent ?? <></>

    if (readonly)
        return readonlyComponent ?? React.Children.map(children, child =>
            child ? React.cloneElement(child, { disabled: true }) : undefined
        );

    return (
        children
    );
}

export default Authorize;