import { Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';

const NoteView = ({ note }) => {

    const [html, setHTML] = useState({ __html: "" });

    useEffect(() => {
        setHTML({ __html: note.content })
    }, [note]);

    return (
        <Grid container alignItems='center'>
            <div dangerouslySetInnerHTML={html} />
        </Grid>
    );
}
export default NoteView;