import CheckCircleIcon from '@material-ui/icons/CheckCircle';
export const statuses = {
    1: {
        label: "Match",
        icon: <CheckCircleIcon style={{
            fill: '#79c179',
            height: '0.8em'
        }} />
    },
    2: {
        label: "Not on lot in Parq"
    },
    3: {
        label: "On lot in Parq, missing in file"
    },
    4: {
        label: "Does not exist in Parq"
    },
    5: {
        label: "Duplicate"
    }
}

export const auditFileStatuses = {
    1: 'Pending',
    2: 'Processing',
    3: 'Done'
}
