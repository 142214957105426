import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from '@material-ui/core';
import { Alert } from '@mui/material';
import { getErrorListFromResponse } from 'app/errorHandling';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import CustomInput from 'components/CustomInput';
import NotificationProvider from 'components/NotificationProvider';
import PhoneInput from 'components/PhoneInput';
import { IconAccessCode } from 'icons';
import { useEffect, useState } from 'react';
import FormatService from 'services/formatService';
import Validator from 'services/validator';
import { AccessCodeTypes } from '../accessConsts';
import { useGenerateAccessCodeMutation } from '../accessSlice';
import AccessCodeLoader from './AccessCodeLoader/AccessCodeLoader';
import AccessCodeParameters from './AccessCodeParameters';
import { GenerateAccessCodeStyle } from './style';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import usePrompt from 'hooks/usePrompt';

const GenerateAccessCode = ({ accessGroup, lotId = undefined, vehicleId = undefined, buttonLabel = 'Reset Code', btnStyle = 'outlined' }) => {
    const classes = GenerateAccessCodeStyle()
    const { triggerPrompt } = usePrompt();

    const [generateAccessCode, { isLoading }] = useGenerateAccessCodeMutation();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [errors, setErrors] = useState([]);

    const [accessCodeParams, setAccessCodeParams] = useState({ 'accessCodeType': AccessCodeTypes.RECURRING });

    const onChangeValue = (key, val) => {
        setAccessCodeParams({ ...accessCodeParams, [key]: val });
    }

    const validate = () => {
        const errorList = [];
        if (!accessCodeParams?.startDate || !accessCodeParams?.endDate) {
            errorList.push('Please select a start and end date');
        }
        if (!accessCodeParams.pocEmail) {
            errorList.push('POC email is required');
        }
        if (!accessCodeParams.pocPhone) {
            errorList.push('POC phone is required');
        }
        setErrors(errorList);
        return errorList.length == 0;
    }

    const onSave = async () => {
        const valid = validate();
        if (!valid) return;
        let utcStartDate = FormatService.fromLocalDateToUtcNoConversion(accessCodeParams.startDate);
        let utcEndDate = FormatService.fromLocalDateToUtcNoConversion(accessCodeParams.endDate)
        const res = await generateAccessCode({
            accessGroupId: accessGroup?.id,
            lotId,
            vehicleId,
            ...accessCodeParams,
            endDate: utcEndDate,
            startDate: utcStartDate
        })
        if (res && !res.error) {
            triggerPrompt({
                title: "Generate Access Codes",
                content: <div>
                    <p><b>{res?.data?.errorMessages?.length ? 'Failed to generate some access codes for the following reasons:' : 'Successfully generated new access codes'}</b></p>
                    <p>
                        {res.data?.errorMessages?.map(msg => <div>{msg}</div>)}
                    </p>
                </div>,
                onCancel: async () => {
                    setDialogOpen(false);
                },
            });
            setDialogOpen(false);
        } else {
            NotificationProvider.error("Failed to generate an access code.");
            const errors = getErrorListFromResponse(res);
            errors?.map(err => NotificationProvider.error(err));
        }
    }

    useEffect(() => {
        setAccessCodeParams({
            'pocName': accessGroup?.pocName,
            'pocEmail': accessGroup?.pocEmail,
            'pocPhone': accessGroup?.pocPhone,
        })
        setErrors([]);
    }, [accessGroup]);

    return (
        <>
            <Authorize profile={permissionProfiles.ACCESS.MANAGE_ACCESS}>
                <Button variant={btnStyle}
                    startIcon={<RotateLeftIcon className={classes.icon} />}
                    onClick={() => setDialogOpen(true)}>
                    {buttonLabel}
                </Button>
            </Authorize>
            <Dialog
                open={dialogOpen}
                onClose={() => { }}
                fullWidth
                maxWidth='md'>
                <DialogTitle>Generate Access Code</DialogTitle>
                <DialogContent>
                    <Grid container direction='column' spacing={2} >
                        <Grid item >
                            <Alert severity='warning' className={classes.alert}>
                                By generating a new access code all existing access codes will be deleted.
                            </Alert>
                        </Grid>
                        {errors?.map((error, index) =>
                            <Grid key={index} item >
                                <Alert severity='error' className={classes.alert}>
                                    {error}
                                </Alert>
                            </Grid>)}
                        <Grid item >
                            <Paper className={classes.paramContainer}>
                                <div className={classes.paramTitle}>POC Information</div>
                                <Grid container direction='row' spacing={2} className={classes.pocInfo}>
                                    <Grid item xs={4}>
                                        <CustomInput
                                            label='POC Name'
                                            elementType='input'
                                            value={accessCodeParams?.pocName}
                                            onChange={(val) => onChangeValue("pocName", val)}
                                            disabled={!!lotId || !!vehicleId}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CustomInput
                                            label='POC email'
                                            elementType='input'
                                            value={accessCodeParams?.pocEmail}
                                            valid={Validator.validateEmail(accessCodeParams?.pocEmail)}
                                            touched={accessCodeParams?.pocEmail}
                                            onChange={(val) => onChangeValue("pocEmail", val)}
                                            disabled={!!lotId || !!vehicleId}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <PhoneInput
                                            label='POC Phone Number'
                                            value={accessCodeParams?.pocPhone}
                                            valid={accessCodeParams?.pocPhone && Validator.validatePhone(accessCodeParams?.pocPhone)}
                                            onChange={(val) => onChangeValue("pocPhone", val)}
                                            disabled={!!lotId || !!vehicleId}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item>
                            <AccessCodeParameters
                                accessToEdit={accessCodeParams}
                                setAccessToEdit={setAccessCodeParams}
                            />
                        </Grid>
                    </Grid>
                    <AccessCodeLoader show={isLoading} />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => setDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button color="primary"
                        variant="contained"
                        onClick={onSave}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Saving...' : 'Save and Reset'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default GenerateAccessCode;