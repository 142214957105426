import GoogleMap from 'google-map-react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { mapWrapperStyle } from "./MapWrapperStyle";
import SearchBox from './Search/SearchBox';
import { googleMapService } from "./googleMapService";

function MapWrapper({
    defaultCenter = {
        lat: 39.148171,
        lng: -101.763726
    },
    defaultZoom,
    markers,
    route,
    includeSearch,
    limitMarkersByDistanceFromCenter,
    isNarrowScreen
}) {
    const classes = mapWrapperStyle();

    const [map, setmap] = useState();
    const [maps, setmaps] = useState();
    const [directionsService, setDirectionService] = useState();
    const [directionsRenderer, setDirectionRenderer] = useState();
    const [markersArray, setMarkersArray] = useState([]);

    const clearOverlays = () => {
        var markList = [];
        for (var i = 0; i < markersArray.length; i++) {
            var mark = markersArray[i];
            mark.setMap(null);
            markList.push(mark);
        }
        setMarkersArray(markList);
        setMarkersArray([]);
    }

    const createMarkerWithEvent = (maps, location, map, icon, content, onClick, id) => {
        if (maps) {
            const infoWindow = new maps.InfoWindow();
            const markerDiv = document.createElement('div')
            ReactDOM.render(icon, markerDiv);

            const marker = new maps.marker.AdvancedMarkerElement({
                map: map,
                position: new maps.LatLng(location.lat, location.lng),
                content: markerDiv
            });

            if (onClick) {
                marker.addListener("click", () => {
                    onClick(id);
                });
            };

            if (content) {
                marker.addListener("click", () => {
                    if (marker.open) {
                        infoWindow.close();
                        marker.open = false;
                    }
                    else {
                        infoWindow.setContent(content);
                        infoWindow.open(map, marker);
                        marker.open = true;
                    }
                });
            }

            return marker;
        }
    };

    const drawOnMap = async (dr, ds, map, maps) => {
        //Clear existing markers on map
        clearOverlays();


        if (route) {
            googleMapService.calculateRoute(dr, ds, map, maps, route?.startLocation, route?.endLocation, function (error, result) {
                if (!error) {
                    dr.setDirections(result);
                }
            });
        }

        if (markers) {

            const centerMarker = markers?.find(marker => marker.center);
            let centerGoogleMapPos = null

            function _generateMarker(marker, pos) {
                const createdMarker = createMarkerWithEvent(maps, pos, map, marker.icon, marker.content, marker.onClick, marker.id);
                setMarkersArray(prevMarkers => [...prevMarkers, createdMarker]);
            }

            function _renderMarker(marker, pos) {
                if (!limitMarkersByDistanceFromCenter || marker.center) {
                    _generateMarker(marker, pos);
                }
                else if (limitMarkersByDistanceFromCenter && centerMarker && centerGoogleMapPos) {
                    const googleMapPos = new maps.LatLng(pos.lat, pos.lng);
                    var distance = googleMapService.convertDistanceToMiles(maps.geometry.spherical.computeDistanceBetween(googleMapPos, centerGoogleMapPos));
                    if (distance < limitMarkersByDistanceFromCenter) {
                        _generateMarker(marker, pos);
                    }
                }
            }

            if (centerMarker) {
                try {
                    let lat = centerMarker.latitude;
                    let lng = centerMarker.longitude;
                    if (!lat || !lng) {
                        const geoCode = await googleMapService.geoCode(maps, centerMarker.address);
                        lat = geoCode.lat;
                        lng = geoCode.lng;
                    }
                    //const pos = { lat: geoCode.geometry.location.lat(), lng: geoCode.geometry.location.lng() };
                    centerGoogleMapPos = new maps.LatLng(lat, lng);
                    _renderMarker(centerMarker, { lat, lng });
                    map.setCenter(centerGoogleMapPos)
                } catch (err) {
                    console.log(err);
                }
            }

            const markersWithLatLng = markers?.filter(marker => marker.latitude && marker.longitude);
            markersWithLatLng.forEach(marker => {
                const pos = { lat: marker.latitude, lng: marker.longitude };
                _renderMarker(marker, pos);
            });

            const markersWithAddress = markers?.filter(marker => marker.address && !marker.latitude && !marker.longitude && !marker.center);
            for (const marker of markersWithAddress) {
                try {
                    const result = await googleMapService.geoCode(maps, marker.address);
                    //const pos = { lat: result.geometry.location.lat(), lng: result.geometry.location.lng() };
                    _renderMarker(marker, result);
                } catch (err) {
                    console.log(err);
                }
            }
        }
    }

    useEffect(() => {
        if (map && maps && directionsRenderer && directionsService) {
            drawOnMap(directionsRenderer, directionsService, map, maps);
        }
    }, [markers, route]);

    const apiIsLoaded = (map, maps) => {
        let ds = new maps.DirectionsService();
        let dr = new maps.DirectionsRenderer({ suppressMarkers: true });
        setDirectionRenderer(dr);
        setDirectionService(ds);
        setmap(map);
        setmaps(maps);
        drawOnMap(dr, ds, map, maps);
    };

    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.searchBox}>
                    {includeSearch && maps && <SearchBox
                        map={map}
                        maps={maps}
                        defaultCenter={defaultCenter}
                        defaultZoom={defaultZoom}
                        createMarkerWithEvent={createMarkerWithEvent}
                        markers={markers}
                        isNarrowScreen={isNarrowScreen}
                    />}
                </div>

                <GoogleMap
                    mapContainerClassName={classes.wrapper}
                    bootstrapURLKeys={{ key: googleMapService.googleMapsParams.apiKey, version: googleMapService.googleMapsParams.version, libraries: googleMapService.googleMapsParams.libraries }}
                    defaultCenter={defaultCenter ?? { lat: Number(0), lng: Number(0) }}
                    defaultZoom={defaultZoom ?? 0}
                    options={{
                        mapId: googleMapService.googleMapsParams.mapId,
                        fullscreenControlOptions: { position: maps?.ControlPosition?.BOTTOM_RIGHT },
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
                >
                </GoogleMap>
            </div>
        </>
    );
}

export default MapWrapper;