import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import LoadingSpinner from 'components/LoadingSpinner';
import { useState } from 'react';
import { useStyles } from "./AddActivityPriceStyles";
import { useGetAllActivityItemsQuery } from 'features/activity/activitySlice';
import NotificationProvider from 'components/NotificationProvider';
import { useGetLotsQuery } from 'features/lots/lotSlice';
import { useGetVehicleTypesQuery } from 'features/vehicles/vehicleSlice';
import { useGetServiceProvidersQuery } from 'features/serviceProvider/serviceProviderSlice';
import { useAddActivityPriceMutation, useGetAllActivityPricingQuery } from 'features/pricing/pricingSlice';
import { activityPriceConfig, activityPriceFields } from '../pricingConsts';

function AddActivityPrice({ open, handleClose, setOpenModel }) {
    const classes = useStyles();

    let { data: activityPricing, error: activityPricingError, isFetching: isLoadingActivityPricing } = useGetAllActivityPricingQuery();
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes } = useGetVehicleTypesQuery();
    let { data: serviceProviders, error: serviceProvidersError, isLoading: isLoadingServiceProviders } = useGetServiceProvidersQuery();

    const [addActivityPrice, { isLoading: isAdding }] = useAddActivityPriceMutation();

    const [activityPriceToAdd, setActivityPriceToAdd] = useState();

    activityItems = activityItems || [];
    lots = lots || [];
    vehicleTypes = vehicleTypes || [];
    serviceProviders = serviceProviders || [];
    activityPricing = activityPricing || [];


    const onChange = (key, val) => {
        setActivityPriceToAdd({ ...activityPriceToAdd, [key]: val });
    };

    const onClose = () => {
        setActivityPriceToAdd({})
        handleClose()
    }

    const onClickFinish = async () => {
        let existing = activityPricing.find(a =>
            a?.activityItemId === activityPriceToAdd?.activityItemId &&
            a?.vendorId === activityPriceToAdd?.vendorId &&
            a?.lotId === activityPriceToAdd?.lotId &&
            a?.vehicleTypeId === activityPriceToAdd?.vehicleTypeId)
        if (existing) {
            setOpenModel(true)
        }
        else {
            let result = await addActivityPrice(activityPriceToAdd);
            if (result && !result.error) {
                NotificationProvider.success("Activity added successfully");
                onClose();
            } else {
                NotificationProvider.error("Something happened, try again or contact support");
            }
        }
    }

    const isValid = activityPriceToAdd?.activityItemId && activityPriceToAdd?.vendorId && activityPriceToAdd?.lotId && activityPriceToAdd?.vehicleTypeId && activityPriceToAdd?.price;
    const isSaving = isAdding;
    const isLoading = isLoadingActivityItems || isLoadingLots || isLoadingVehicleTypes || isLoadingServiceProviders || isLoadingActivityPricing;


    return (
        <>
            <Authorize profile={permissionProfiles.PRICING.MANAGE_PPRICING}>
                <Dialog open={open} onClose={onClose} >
                    <DialogTitle >
                        <div className={classes.dialogTitle}> Add Activity Price </div>
                    </DialogTitle>

                    <DialogContent>
                        {!(isLoading) &&
                            <Grid container spacing={1} direction='column'>
                                <Grid item className={classes.field}>
                                    {activityPriceConfig[activityPriceFields.ACTIVITY]?.component(activityPriceToAdd?.activityItemId, activityItems, (val) => onChange("activityItemId", parseInt(val)), true, "Activity")}
                                </Grid>
                                <Grid item className={classes.field}>
                                    {activityPriceConfig[activityPriceFields.VENDOR]?.component(activityPriceToAdd?.vendorId, serviceProviders, (val) => onChange("vendorId", parseInt(val)), true, "Vendor")}
                                </Grid>
                                <Grid item className={classes.field}>
                                    {activityPriceConfig[activityPriceFields.LOT]?.component(activityPriceToAdd?.lotId, lots, (val) => onChange("lotId", parseInt(val)), true, "Lot")}
                                </Grid>
                                <Grid item className={classes.field}>
                                    {activityPriceConfig[activityPriceFields.VEHICLE_TYPE]?.component(activityPriceToAdd?.vehicleTypeId, vehicleTypes, (val) => onChange("vehicleTypeId", parseInt(val)), true, "Vehicle Type")}
                                </Grid>
                                <Grid item className={classes.field}>
                                    {activityPriceConfig[activityPriceFields.PRICE]?.component(activityPriceToAdd?.price, null, (val) => onChange("price", parseInt(val)), true, "Price")}
                                </Grid>
                                <Grid item className={classes.checkbox}>
                                    {activityPriceConfig[activityPriceFields.USE_INVENTORY]?.component(activityPriceToAdd?.useInventory, (val) => onChange("useInventory", val.target.checked), "Uses Inventory?")}
                                </Grid>
                            </Grid>
                        }
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.btnContainer}>
                            <Button className={classes.button} variant="outlined"
                                onClick={onClose}>Cancel</Button>
                            <Button
                                onClick={onClickFinish}
                                disabled={!isValid || isSaving}
                                className={classes.button}
                                variant="contained" >
                                {isSaving ? "Saving..." : "Finish"}
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>

                <LoadingSpinner loading={isLoading || isSaving} />
            </Authorize>
        </>
    );
}

export default AddActivityPrice;