import React, { useEffect, useState } from 'react';
import { Button, Grid, IconButton } from "@material-ui/core";
import ServiceContactsStyle from './ServiceContactsStyle';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CustomInput from "../../../../../components/CustomInput";
import Validator from "../../../../../services/validator";

const useStyles = ServiceContactsStyle;

export default function ServiceContacts({ serviceContacts, setServiceContacts, lotToEdit, setLotToEdit }) {
  const classes = useStyles();

  useEffect(() => {
    setServiceContacts(serviceContacts.map(s => s= {...s, valid: Validator.validateEmail(s.contactEmail) ? true : false}))
}, []);
  

  const addEmail = () => {
    const newObj = [...serviceContacts, { id: null, lotId: lotToEdit.id, contactEmail: null, contactType: 2, deletedDate: null, valid: true }];
    setServiceContacts(newObj);
  }

  const onChangeEmail = (index, val) => {
    let emailsList = [...serviceContacts];
    emailsList[index] = { ...emailsList[index], contactEmail: val, valid: Validator.validateEmail(val) ? true : false };
    setServiceContacts(emailsList);
    setLotToEdit({ ...lotToEdit, services: emailsList });
  }

  const onDelete = (index) => {
    let emailsList = [...serviceContacts];
    emailsList[index] = { ...emailsList[index], deletedDate: new Date(), valid: true }
    setServiceContacts(emailsList);
    setLotToEdit({ ...lotToEdit, services: emailsList });
  }

  return (
    <div >
      <div className={classes.groupTitle}>
        Service Emails
      </div>
      {
        serviceContacts.map((item, index) => {
          if (item.deletedDate === null) {
            return (
              <Grid container xs={12}>
                <Grid item xs={11} className={classes.dropDownLine}>
                  <CustomInput
                    label='Additional Contact'
                    identifier='email'
                    value={item?.contactEmail}
                    onChange={val => onChangeEmail(index, val)}
                    valid={item?.valid}
                  />
                  {
                    item?.valid === false &&
                    <p className={classes.invalid}>Invalid Email</p>
                  }
                </Grid>
                {/* <Grid item xs={5} className={classes.dropDownLine}>
                  <CustomInput
                    label='Name'
                    identifier='name'
                    value={item?.name}
                    onChange={val => onChangeEmail(index, val)}
                  />
                </Grid> */}
                <Grid item xs={1} className={classes.dropDownLine}>
                  <IconButton edge="end" aria-label="delete"
                    onClick={() => onDelete(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )
          }
        })
      }
      <div >
        <Button variant="outlined"
          color="primary"
          onClick={addEmail}
          className={classes.buttonSchedule}
          startIcon={<AddIcon />}
        >
          Add Service Email
        </Button>
      </div>
    </div>
  )
}
