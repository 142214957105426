import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import {NavLink} from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import mobileBreakpoint from "../../utils/mobileBreakpoint";
import {Box, Button, IconButton, Link, withStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none',
    },
    button: {
        padding: "0px 15px",
        height: 25,
        '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
            "& svg": {
                fill: theme.palette.primary.main,
            }
        }
    }
}));

export default function ({id}) {
    const classes = useStyles();
    const isMobile = useMediaQuery(mobileBreakpoint);

    return (
        <NavLink className={classes.link} exact
            to={`/assets/detail/${id}`}>
                <Button button className={classes.button} variant='outlined'>
                {
                    !isMobile ?
                        <ListItemText className={classes.text} primary="Details"/>
                        : null
                }
                </Button>
        </NavLink>
    );
}
