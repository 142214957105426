import { Box, Tab, Tabs } from "@material-ui/core";
import { useState } from "react";
import { tabStyles } from "./style";

function tabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}

export const TabsWrapper = ({ tabs, selectedTab, setSelectedTab }) => {

    const classes = tabStyles();
    const [value, setValue] = useState(selectedTab ?? 0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSelectedTab && setSelectedTab(newValue)
    };


    return (
        <>
            <Box className={classes.tabsWrapper}>
                <Tabs value={value} onChange={handleChange} centered>
                    {tabs?.map((tab, index) => <Tab {...tabProps(index)} key={`v_tab_${index}`} label={tab.label} value={tab.index} />)}
                </Tabs>
            </Box>
            <Box>
                {tabs?.map((tab, index) =>
                    <>
                        {tab.component && <TabPanel value={value} index={index}>
                            {tab.component}
                        </TabPanel>}
                    </>)}
            </Box>
        </>
    )
}

