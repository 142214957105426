import { permissions } from "../permissions";

export const permissionDisplay = [
    permissions.VEHICLE.IMPORT,
    permissions.VEHICLE.CREATE,
    permissions.LOTS.SIZING.EDIT,
    permissions.LOADS.INVOICE,
    permissions.LOTS.DELETE,
    permissions.Condition_Report.FREE_ACCESS,
    permissions.Condition_Report.COLLECTIBLE,
    permissions.ACTIVITIES.ADD_PRICE,
    permissions.ACTIVITIES.APPROVE_ABOVE_PRICE_CAP,
    permissions.ACTIVITIES.MANAGE_PENDING_INVOICE,
    permissions.PRICING.MANAGE_PPRICING,
    permissions.PERMISSION.MANAGE_PERMISSIONS,
    permissions.USER.CREATE,
    permissions.LOTS.ACCESS.OPEN_GATE,
]