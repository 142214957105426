import CustomInput from "components/CustomInput";
import { selectIsOwner, selectUserId } from "features/user/userSlice";
import { useGetUserLotRelationsForUserQuery, useGetUserRoleQuery, useUpdateUserInformationMutation } from "features/users/usersSlice";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UsersPageStyle from "../../UsersPageStyle";
import { useGetAllRolesQuery } from "components/Authorize/rolesSlice";
import { Button, Grid, Paper } from "@material-ui/core";
import ResetPasswordDialog from "../resetPassword/resetPasswordDialog";
import NotificationProvider from "components/NotificationProvider";

const useStyles = UsersPageStyle;

const EditUserInformation = ({ user, userClaims }) => {
    const classes = useStyles();

    const userId = useSelector(selectUserId);

    let { data: userLotRelations, error: relationsError, isLoading: isLoadingRelations } = useGetUserLotRelationsForUserQuery(userId, { skip: !userId });
    let { data: roles, error: roleError, isLoading: isLoadingRoles } = useGetAllRolesQuery()
    let { data: userRole, error: userRoleError, isLoading: isLoadingUserRole } = useGetUserRoleQuery(user?.id, { skip: !user?.id });

    userLotRelations = userLotRelations || [];
    roles = roles?.data || [];
    userRole = userRole || [];

    const [updateInformation] = useUpdateUserInformationMutation()
    const [hasChanged, setHasChanged] = useState(false);
    const [saving, setSaving] = useState(false);
    const [roleId, setRoleId] = useState();
    const [fullName, setFullName] = useState("");
    const [openResetDialog, setOpenResetDialog] = useState(false);

    useEffect(() => {
        let name = userClaims?.find(c => c.claimType === 'name')?.claimValue ?? '';
        setFullName(name ?? "");
    }, [userClaims])

    useEffect(() => {
        if (!isLoadingUserRole) {
            setRoleId(userRole?.data?.[0]?.roleId ?? "");
        }
    }, [user?.id, isLoadingUserRole])

    const handleUserInfoSave = async () => {
        setSaving(true);

        const objToSend = {
            userId: user?.id,
            roleId: roleId,
            fullName: fullName
        }

        let result = await updateInformation(objToSend);

        setSaving(false);

        if (!result?.error) {
            NotificationProvider.success('Saved successfully');
        } else {
            NotificationProvider.error('Failed to save');
        }

        setHasChanged(false);
    }

    const isOwner = useSelector(selectIsOwner);

    let isRM = userLotRelations?.find(u => u.relationType === 3); //lot association with rm
    let isSuper = userLotRelations?.find(u => u.relationType === 2);

    const getRoleValues = () => {
        if (isRM && isOwner) {
            return roles?.filter(r => r?.name === "account_manager" || r?.name === "admin");
        }
        if (isSuper) {
            return roles?.filter(r => r?.name === "admin");
        }
        if (isOwner && !isRM) {
            return roles;
        }
    }

    const onClickReset = () => {
        setOpenResetDialog(true);
    }

    return (
        <Paper className={classes.paper}>
            <div className={classes.label}>User Information</div>
            <Grid container className={classes.wrapperSave}>
                <Grid item>
                    <Button
                        className={classes.resetButton}
                        onClick={onClickReset}
                        color="primary"
                        variant="contained"
                    >
                        Reset Password
                    </Button>
                </Grid>
            </Grid>
            <Grid item className={classes.grids}>
                <CustomInput
                    id="userName"
                    label="Username"
                    elementType="input"
                    classStyles={classes.dropdown}
                    value={user?.userName}
                    disabled
                />
            </Grid>
            <Grid item className={classes.grids}>
                <CustomInput
                    id="name"
                    label="Full Name"
                    elementType="input"
                    classStyles={classes.dropdown}
                    onChange={(val) => { setFullName(val); setHasChanged(true); }}
                    value={fullName}
                />
            </Grid>
            <Grid item className={classes.grids}>
                <CustomInput
                    id="email"
                    label="Email"
                    elementType="input"
                    classStyles={classes.dropdown}
                    disabled
                    value={user?.email}
                />
            </Grid>
            <Grid item className={classes.grids}>
                <CustomInput
                    id="role"
                    label="Role"
                    elementType="dropdown"
                    values={[{ id: '', name: "" }, ...(getRoleValues() ?? [])]}
                    onChange={val => { setRoleId(val); setHasChanged(true); }}
                    touched
                    value={roleId ?? ""}
                />
            </Grid>
            <Grid container className={classes.wrapperSave}>
                {hasChanged ?
                    <Grid item>
                        <Button
                            className={classes.resetButton}
                            onClick={handleUserInfoSave}
                            color="primary"
                            variant="contained"
                            disabled={saving}
                        >
                            {saving ? 'Saving...' : 'Save'}
                        </Button>
                    </Grid> : null
                }
            </Grid>
            <ResetPasswordDialog open={openResetDialog} setOpenDialog={setOpenResetDialog} userId={user?.id}></ResetPasswordDialog>
        </Paper>
    )
}

export default EditUserInformation;