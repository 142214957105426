import { makeStyles } from "@material-ui/core";

export const CreatableDropdownStyle = makeStyles((theme) => ({
    editField: {
        padding: '0 1rem 0 0',
        paddingLeft: "1rem",
        marginLeft: '-14px',
        width: '100%',
        '& .MuiOutlinedInput-input': {
            paddingTop: '10px',
            paddingBottom: '10px',
            fontSize: '0.875rem',
        }
    },
    field: {
        paddingLeft: "1rem",

    },
    input: {
        fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important`,
        color: theme.palette.common.darkText,
        backgroundColor: theme.palette.background.default,
        borderRadius: '4px',
        '&.MuiOutlinedInput-adornedEnd': {
            backgroundColor: theme.palette.background.default,
        },
        '&.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root': {
            padding: '0 !important',
        },
        '&.MuiAutocomplete-inputRoot': {
            padding: '0 !important',
        },
        '&.Mui-disabled': {
            opacity: 0.5,
        },
        '& fieldset': {
            border: 'none',
        }
    },
    label: {
        color: theme.palette.text.secondary,
        '&.Mui-disabled': {
            color: theme.palette.text.secondary,
            opacity: 0.7,
        }
    },
    options: {
        fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important`,
    }
}));