import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from "../../app/baseQuery";

export const rolesApi = createApi({
    reducerPath: 'rolesApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAllRoles: builder.query({
            query: () => `admin/roles/getall`,
        }),
    }) 
});

export const {
    useGetAllRolesQuery
} = rolesApi