import {
    Button,
    Grid,
    Paper
} from '@material-ui/core';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { IconMap, IconPhoto } from 'icons';
import { useSelector } from "react-redux";
import BasicModal from '../../../components/BasicModal';
import {
    useGetAllIssuesTrackerQuery,
} from "../../../features/reporting/IssuesTracker/issuesTrackerSlice";
import mobileBreakpoint from "../../../utils/mobileBreakpoint";
import { useGetClientsQuery } from "../../clients/clientSlice";
import { useGetLoadsQuery } from '../../loads/loadsSlice';
import { useGetLotsQuery } from '../../lots/lotSlice';
import { selectIsAdmin } from "../../user/userSlice";
import ParentEditAssetModal from '../ParentEditAssetModal';
import {
    useGetVehicleDetailsQuery,
    useGetVehicleStatusQuery,
    useGetVehicleTypesQuery,
} from '../vehicleSlice';
import { VehicleAccess } from './VehicleAccess/VehicleAccess';
import VehicleActivityLogNew from './VehicleActivityLog/VehicleActivityLogNew';
import { vehicleDetailsPageStyle } from "./VehicleDetailsPageStyle";
import VehicleDetailsSummary from './VehicleDetailsSummary';
import { VehicleDocuments } from './VehicleDocuments/VehicleDocuments';
import { VehicleMap } from '../../maps/VehicleMap/VehicleMap';
import { VehicleNotes } from './VehicleNotes';
import { VehicleTabs } from './VehicleTabs/VehicleTabs';


const VehicleDetailsPage = () => {
    const classes = vehicleDetailsPageStyle();
    const isMobile = useMediaQuery(mobileBreakpoint);
    const isAdmin = useSelector(selectIsAdmin);
    const [assetToEdit, setAssetToEdit] = useState(null);
    const [openPhotos, setOpenPhots] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);


    let { id } = useParams();
    let {
        data: vehicleDetails,
        error,
        isLoading: isLoadingVehicle,
    } = useGetVehicleDetailsQuery(id);
    let {
        data: lots,
        error: lotError,
        isLoading: isLoadingLots,
    } = useGetLotsQuery();
    let {
        data: vehicleTypes,
        error: vehicleTypesError,
        isLoading: isLoadingVehicleTypes
    } = useGetVehicleTypesQuery();
    let {
        data: statuses,
        error: statusError,
        isLoading: isLoadingStatuses
    } = useGetVehicleStatusQuery();
    let {
        data: clients,
        error: clientError,
        isLoading: isLoadingClients,
    } = useGetClientsQuery(null, { skip: !isAdmin });

    let { data: loads, error: loadListError, isLoading: isLoadingLoad } = useGetLoadsQuery({});


    clients = clients || [];
    statuses = statuses || [];
    vehicleTypes = vehicleTypes || [];

    if (isLoadingVehicle || isLoadingLots || isLoadingClients) {
        return <CircularProgress className={classes.spinner} />
    }

    if (!vehicleDetails) {
        return (
            <div className={classes.noAssetDiv}>
                <h2>This asset does not exist</h2>
            </div>
        )
    }

    const findLot = (lot_id) => {
        return lots?.find(l => l.id === lot_id) || {};
    }

    let client = clients?.find(c => c.clientAccounts.find(ca => ca.id === vehicleDetails?.customerId));
    let lot = findLot(vehicleDetails?.lotId);

    lot = lot || {};

    let conditionReportUrl = vehicleDetails?.conditionReportUrl;

    if (vehicleDetails?.conditionReportId) {
        conditionReportUrl = `/assets/condition/${id}`;
    }

    const saveActivity = (vehicleDetailsId, date) => {
        // refetchActivityLog();
        setAssetToEdit(null);
        setOpenPhots(false);
    };

    const onEdit = (openPhotos) => {
        setOpenPhots(openPhotos);
        setAssetToEdit(vehicleDetails);
    };

    const latestLoad = loads?.find(l => l.assets.find(a => a.id === vehicleDetails?.id));

    return (
        <>
            <Paper className={`${classes.tabsWrapper}`} >
                <VehicleTabs selected={selectedTab} setSelected={setSelectedTab} />
            </Paper>
            <VehicleDetailsSummary vehicleDetails={vehicleDetails} minimalSummary={selectedTab == 1} onEdit={onEdit} />
            {selectedTab == 1 ? <VehicleAccess vehicleId={id} clientId={client?.id} /> : <>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Paper className={classes.card}>
                            <VehicleCardHeader title='Map' icon={<IconMap />} />
                            <div className={classes.sideBySide}>
                                <VehicleMap vehicleDetails={vehicleDetails} />
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item md={6}>
                        <Paper className={classes.card}>
                            <VehicleCardHeader title='Photos & Documents' icon={<IconPhoto />} actions={<Button className={classes.editButton}
                                color="primary"
                                variant="outlined"
                                onClick={() => onEdit(true)}
                            >
                                Edit
                            </Button>} />
                            <div className={classes.sideBySide}>
                                <VehicleDocuments vehicleDetails={vehicleDetails} latestLoad={latestLoad} onEdit={onEdit} />
                            </div>
                        </Paper>
                    </Grid>
                </Grid>

                <VehicleNotes vehicleDetails={vehicleDetails} />

                <VehicleActivityLogNew />

                <BasicModal
                    open={assetToEdit}
                    header={`Editing ${assetToEdit?.descriptor}`}
                    wide={true}
                    handleClose={() => setAssetToEdit(null)}
                    component={<ParentEditAssetModal openPhotos={openPhotos} asset={assetToEdit} handleClose={() => setAssetToEdit(null)} updateAsset={saveActivity} />}
                />

            </>}
        </>
    );
};

export default VehicleDetailsPage;

export const VehicleCardHeader = ({ title, icon, actions }) => {
    const classes = vehicleDetailsPageStyle();
    return (
        <Grid className={classes.titleWrapper} container alignItems='center' justifyContent='space-between'>
            <Grid item>
                <Grid container alignItems='center'>
                    {icon}
                    <h2 className={classes.title}>{title}</h2>
                </Grid>
            </Grid>
            <Grid item>
                {actions}
            </Grid>
        </Grid>
    )
}