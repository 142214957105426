import { makeStyles } from "@material-ui/core"

export const importInspectionActivityStyle = makeStyles((theme) => ({
    clientWrapper: {
        marginBottom: '2em'
    },
    spinner: {
        height: "inherit",
        position: 'absolute',
        top: '50%',
        left: 'calc(50% - 20px)',
        zIndex: '100'
    },
    loadingwrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));