import { makeStyles } from "@material-ui/core"

export const loadButtonStyle = makeStyles((theme) => ({
    openDetails: {
        color: 'blue',
        padding: '1rem 1rem',
        cursor: 'pointer', 
        '&:hover': {
            opacity: 0.6
        }
    },
    typography: {
        padding: theme.spacing(2),
      },
}));