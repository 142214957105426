import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Paper, ListItem, IconButton } from '@material-ui/core';
import { useEffect, useState } from 'react';
import {
    useGetAllStepsByActivityTypeIdQuery,
    useUpdateActivityTypeStepMutation
} from 'features/activity/ActivityTypeStepSlice';
import { useStyles } from "../../../pricing/AddActivityType/AddActivityTypeStyles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
    DragIndicatorOutlined
} from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Guid } from 'js-guid';
import CustomInput from '../../../../components/CustomInput';
import NotificationProvider from 'components/NotificationProvider';

const StepsToComplete = ({activity, isReadyOnly = false}) => {
    const classes = useStyles();
    const [itemList, setItemList] = useState([]);
    const [newStepDescription, setNewStepDescription] = useState("");
    const [newDescriptionError, setNewDescriptionError] = useState(false);
    const [isAdding, setIsAdding] = useState(false);

    let [updateSteps] = useUpdateActivityTypeStepMutation();
    let { data: steps, refetch: refetchSteps } = useGetAllStepsByActivityTypeIdQuery({
        entityId: activity?.id,
        entityType: 1
    });

    useEffect(() => {
        if(steps)
        {
            console.log("steps", steps);
            steps = steps.map(a => ({
                ...a,
                id: a.id.toString()
            }));

            setItemList(steps);
        }
    }, [steps]);

    const onSelectFieldFromCompletedList = (item, val) => {
        let stepList = [...itemList];
        let newItem = { ...item };
        newItem.isCompleted = false;
        stepList[itemList.findIndex(i => i == item)] = { ...newItem };
        setItemList(stepList);
    };

    const onChangeDescription = (index, val) => {
        if(typeof val === "undefined" || val === "")
        {
            setNewDescriptionError(true);
            setNewStepDescription("");
            return;
        }

        setNewDescriptionError(false);
        setNewStepDescription(val);
    }

    const onAddStep = () => {
        if(typeof newStepDescription === "undefined" || newStepDescription === "")
        {
            setNewDescriptionError(true);
            return;
        }

        setNewDescriptionError(false);
        let stepList = [...itemList];

        stepList.push({ 'id': Guid.newGuid().StringGuid,
            'description': newStepDescription,
            isCompleted: false,
            sequenceNumber: 0,
            isNew: true,
            entityId: activity?.id,
            entityType: 1,
            "CreatedBy": "",
            "CreatedDate": null,
            "DeletedDate": null,
            "DeletedBy": "",});

        let stepListSequence = stepList.map((item, index) => ({
            ...item,
            sequenceNumber: index
        }));

        setItemList(stepListSequence);
        setNewStepDescription("");
    };

    const onRefresh = async() => {
        await refetchSteps();
    }

    const onSaveAction = async() => {
        setIsAdding(true);
        let arrayCopy = [...itemList];
        arrayCopy.forEach(element => {
            if (element.isNew) {
                element.id = 0
            }
        });
        var request = {
            ActivityTypeStepsEntities: arrayCopy,
            EntityId: activity?.id,
            EntityType: 1
        };
        var res = await updateSteps(request);
        if (res.error) {
            NotificationProvider.error("Failed to managing steps");
        } else {
            NotificationProvider.success("Successfully");
        }
        onRefresh();
        setIsAdding(false);
    }

    const onDeleteStepList = (item, val) => {
        if (isReadyOnly) return;
        let stepList = [...itemList];
        stepList = stepList.filter(i => i != item);

        let reoderList = stepList.map((item, index) => ({
            ...item,
            sequenceNumber: index
        }));

        setItemList(reoderList);
    };

    const onSelectFieldFromStepList = (item, val) => {
        let stepList = [...itemList];
        let newItem = { ...item };
        newItem.isCompleted = true;
        stepList[itemList.findIndex(i => i == item)] = { ...newItem };
        setItemList(stepList);
    };

    const onDragEnd = ({ destination, source }) => {
        if (!destination) return;

        let reoderList = [...itemList];

        const result = Array.from(reoderList);
        const [removed] = result.splice(source.index, 1);
        result.splice(destination.index, 0, removed);

        reoderList = result.map((item, index) => ({
            ...item,
            sequenceNumber: index
        }));

        setItemList(reoderList);
    };

    const reorder = (itemList, startIndex, endIndex) => {
        const result = Array.from(itemList);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        let resultSequence = result.map((item, index) => ({
            ...item,
            sequenceNumber: index
        }));

        return resultSequence;
    };

    const Item = ({ index, item, dragItemStyle = {}, children }) => (
        <Draggable index={index} draggableId={item.id} isDragDisabled={isReadyOnly}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={{
                        ...provided.draggableProps.style,
                        ...(snapshot.isDragging ? dragItemStyle : {}),
                    }}
                >
                    {children(item, provided.dragHandleProps)}
                </div>
            )}
        </Draggable>
    );

    const List = ({ list, onDragEnd, dragListStyle = {}, ...props }) => (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                            ...(snapshot.isDraggingOver ? dragListStyle : {}),
                        }}
                    >
                        {list.sort(function(a,b) {
                            return a.sequenceNumber - b.sequenceNumber;
                        }).map((item, index) => (
                            <>
                                {item.isCompleted === false &&
                                    <Item
                                        key={item.id}
                                        index={index}
                                        item={item}
                                        {...props}
                                    />
                                }
                            </>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );

    return (
        <>
            <Grid container spacing={3}>
                <div className="App1">
                    <Grid container direction='column' xs={12}>
                        <List
                            list={itemList}
                            onDragEnd={onDragEnd}
                        >
                            {(item, dragHandleProps) => (
                                <>
                                    <Grid className='lineStepList'>
                                        <Grid className='subLineStepList'>
                                            <span {...dragHandleProps}>
                                                <DragIndicatorOutlined className='drag'/>
                                            </span>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    onChange={(e, value) => onSelectFieldFromStepList(item, value)}
                                                    disabled={isReadyOnly}
                                                />}
                                            />
                                            {item.description}
                                        </Grid>
                                        <Grid className='deleteIcon'>
                                            <span onClick={(e, value) => onDeleteStepList(item, value)} ><DeleteIcon/></span>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </List>
                    </Grid>
                    <Grid container xs={12} className='addNewStep'>
                        <Grid item xs={1} className={classes.dropDownLine}>
                            <IconButton edge="end" aria-label="delete" disabled={isReadyOnly}
                                        onClick={() => onAddStep()}
                            >
                                <AddIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={11} className={classes.dropDownLine}>
                            <CustomInput
                                label=''
                                identifier='description'
                                onChange={val => onChangeDescription("description", val)}
                                disabled={isReadyOnly}
                            />
                            {newDescriptionError ? <span style={{ color: "red" }}>Description should not be empty</span> : ""}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} direction='column' xs={12}>
                        {itemList.map((item, i) => (
                            <>
                                {item.isCompleted &&  <Grid className='line'>
                                    <FormControlLabel
                                        control={<Checkbox
                                            defaultChecked={true}
                                            onChange={(e, value) => onSelectFieldFromCompletedList(item, value)}
                                            disabled={isReadyOnly}
                                        />}
                                        label={<span className='completedListDescription'>{item.description} </span>}
                                    />
                                    <span className='deleteIcon' onClick={(e, value) => onDeleteStepList(item, value)}><DeleteIcon/></span>
                                </Grid>
                                }
                            </>
                        ))}
                    </Grid>
                </div>
                <Grid container spacing={3} style={{display: "flex", justifyContent: "space-around", padding: 20}}>
                    <Button onClick={onSaveAction} color='primary' variant="contained" disabled={isReadyOnly || isAdding}>
                        {isAdding ? 'Saving...' : 'Save Changes'}
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default StepsToComplete
