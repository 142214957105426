import {TaskSetup} from "../../taskConsts";
import React from "react";

const TaskStatusLabel = ({ status }) => {
    const taskStatus = TaskSetup[status];

    if (!taskStatus) {
        return null;
    }

    const { label, color,backgroundColor } = taskStatus;

    const labelStyles = {
        backgroundColor: backgroundColor,
        borderRadius: 10,
        padding: "4px 8px",
        color: color,
        marginLeft: '8px' 
    };

    return (
        <>
            <label style={labelStyles}>
                {label}
            </label>
        </>
    );
};

export default TaskStatusLabel;