import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Button, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: 10,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        height: 25,
        borderRadius: 2,
        fontWeight: 400,
        '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
        }
    },
    card: {
        marginBottom: "1rem",
        padding: "1rem",
    },
    label: {
        fontWeight: "500",
        color: theme.palette.primary.main,
        marginBottom: 4
    },
    noItemsDiv: {
        backgroundColor: theme.palette.common.white,
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    }
}));

const MobileDataTable = ({
    rows,
    columns,
    rowIdentifier,
    onShow,
    noItemsMessage
}) => {

    const classes = useStyles();

    const rowCards = rows.map((row, rowKey) => (
        <Paper elevation={1} className={classes.card} key={rowKey}>
            <Grid container direction="column">
                {
                    columns.filter(c => !c.hideOnMobile).map((column, columnKey) => (
                        <Grid container justifyContent="space-between" key={columnKey}>
                            <Grid item className={classes.label}>{column.name}</Grid>
                            <Grid item>{column.component ? column.component(row[column.key]) : column.values ? column.values?.find(v => v.id == row[column.key])?.name : row[column.key]}</Grid>
                        </Grid>
                    ))
                }
                {
                    onShow &&
                    <Grid container justifyContent="flex-end">
                        <Button className={classes.button} variant="outlined" onClick={() => onShow(row[rowIdentifier])}>Details</Button>
                    </Grid>
                }
            </Grid>
        </Paper>
    ));

    return (
        <>
            {
                rows.length > 0 ?
                    rowCards
                    : <div className={classes.noItemsDiv}>
                        <h2>{noItemsMessage}</h2>
                    </div>
            }
        </>
    )
}

export default MobileDataTable;
