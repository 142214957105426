import {
    Collapse,
    Grid,
    Paper
} from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import IconButton from "@material-ui/core/IconButton";
import BuildCircleIcon from "@material-ui/icons/Build";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import ConfirmationPopup from 'components/ConfirmationPopup';
import { EntityAuditTooltip } from 'components/EntityAudit/EntityAuditTooltip';
import ExportButton from "components/ExportButton";
import LoadButton from 'components/LoadButton/LoadButton';
import NotificationProvider from 'components/NotificationProvider';
import AddActivity from "features/activity/AddActivity/AddActivity";
import { useDeleteActivityMutation } from 'features/activity/activitySlice';
import { useGetClientsQuery } from 'features/clients/clientSlice';
import { ActivityPriceError } from 'features/landing/components/MechanicalDashboard/ActivityList/ActivityPriceError';
import { useGetDeletedLotsQuery, useGetLotsQuery } from 'features/lots/lotSlice';
import NotesTooltip from 'features/notes/NotesTooltip';
import { NoteEntityTypes } from 'features/notes/notesConsts';
import { useGetVehicleActivityNotesQuery } from 'features/notes/notesSlice';
import { selectIsAdmin } from 'features/user/userSlice';
import {
    useDeleteVehicleDetailMutation,
    useGetActivityLogQuery,
    useGetVehicleDetailsQuery,
    useGetVehicleStatusQuery
} from 'features/vehicles/vehicleSlice';
import { groupBy, orderBy } from 'lodash';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import FormatService from "services/formatService";
import EditActivityLog from '../EditActivityLog';
import EditVehicleDetails from "../EditVehicleDetails";
import { vehicleDetailsPageStyle } from "../VehicleDetailsPageStyle";
import { vehicleActivityLogStyle } from './style';
import LoadingSpinner from 'components/LoadingSpinner';

const VehicleActivityLogNew = () => {
    const classes = vehicleDetailsPageStyle();
    const activityLogClasses = vehicleActivityLogStyle();
    const history = useHistory();

    const isAdmin = useSelector(selectIsAdmin);
    const [vehicleDetailsToEdit, setVehicleDetailsToEdit] = useState(null);
    const [activityLogToEdit, setActivityLogToEdit] = useState(null);
    const [vehicleDetailToDelete, setVehicleDetailToDelete] = useState(null);
    const [activityToDelete, setActivityToDelete] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);


    let { id } = useParams();
    let { data: vehicleDetails, error, isLoading: isLoadingVehicle } = useGetVehicleDetailsQuery(id);
    let {
        data: activityLogData,
        error: activityLogError,
        isLoading: isLoadingActivityLog,
        refetch: refetchActivityLog
    } = useGetActivityLogQuery(id);
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: deletedLots, error: deletedLotsotError, isLoading: isLoadingDeletedLots, } = useGetDeletedLotsQuery();
    let { data: statuses, error: statusError, isLoading: isLoadingStatuses } = useGetVehicleStatusQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null, { skip: !isAdmin });
    let {
        data: allvehicleActivityNotes,
        error: notesError,
        isFetching: isLoadingNotes
    } = useGetVehicleActivityNotesQuery({ vehicleId: vehicleDetails?.id }, { skip: !vehicleDetails?.id });
    let [deleteVehicleDetail] = useDeleteVehicleDetailMutation();
    let [deleteActivityLog] = useDeleteActivityMutation();

    activityLogData = activityLogData || [];

    activityLogData = activityLogData.map(a => ({
        ...a,
        action: statuses[a.action] ? statuses[a.action]?.label : a.action,
        formattedDate: FormatService.formatDateTimeNoConversion(a.date)
    }))

    const notActivities = activityLogData.filter(a => !a.activityLogId);
    const activities = orderBy(activityLogData.filter(a => a.activityLogId), ['activityDate'], ['desc']);

    const activityGroups = [];

    const groupedActivities = groupBy(activities, 'groupId');

    Object.keys(groupedActivities).map(groupId => {
        const lastActivity = groupedActivities[groupId]?.[0];
        activityGroups.push({
            ...lastActivity,
            subActivities: groupedActivities[groupId]?.filter(a => a.activityLogId !== lastActivity.activityLogId)
        });
    });

    let activityLog = orderBy([...notActivities, ...activityGroups], ['date'], ['desc']);

    const findLot = (lot_id) => {
        return lots?.find(l => l.id === lot_id) || {};
    }

    const lotLookup = lots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const findLotName = (lot_id) => {
        let lot = lotLookup?.[lot_id]
        if (lot) {
            return lot?.name
        } else {
            lot = deletedLots?.find(l => l?.id === lot_id)
            return lot ? `${lot?.name} (Deleted)` : ""
        }
    }

    const mapExportData = v => {
        let exportData = {
            VIN: vehicleDetails?.descriptor,
            "Asset ID": vehicleDetails?.assetId,
            Date: v.date,
            Action: v.action,
            Lot: findLot(v.lotId)?.name,
            "Requested Date": v.requestedDate,
            "Days Completed In": v.daysCompletedIn ? v.daysCompletedIn : "",
            "Within SLA": getWithinSla(v),
            "Asset Readiness": v.readinessStatus
        };

        if (isAdmin) {
            exportData.Cost = v.cost;
            exportData.Revenue = v.revenue;
        }

        return exportData;
    };

    function editActivityLog(activity) {
        if (activity.activityLogId) {
            setActivityLogToEdit(activity);
        }
        if (activity.vehicleDetailsId) {
            setVehicleDetailsToEdit(activity);

        }
    }

    function showMechanicalWork(activity) {
        console.log(activity);
        let linkWork = `/workoder/${activity.id}`;
        history.push(linkWork);
    }

    function deleteActivity(activity) {
        setOpenPopup(true);
        if (activity.vehicleDetailsId) {
            setVehicleDetailToDelete(activity);
            return;
        }

        if (activity.activityLogId) {
            setActivityToDelete(activity);
        }
    }

    const saveActivity = (vehicleDetailsId, date) => {
        setVehicleDetailsToEdit(null);
        setActivityLogToEdit(null);
        refetchActivityLog();
    };

    const getWithinSla = activity => {
        if (activity.withinSla === null) {
            return "";
        }

        return activity.withinSla ? "Yes" : "No";
    };

    const onDeleteActivity = async () => {
        setOpenPopup(true);
        setIsDeleting(true);

        const result = vehicleDetailToDelete ? await deleteVehicleDetail(vehicleDetailToDelete.vehicleDetailsId) : await deleteActivityLog(activityToDelete.activityLogId);
        if (result.error) {
            NotificationProvider.error("Failed to delete the activity");
        } else {
            NotificationProvider.success("Successfully deleted the activity");
        }

        setIsDeleting(false);
        setOpenPopup(false);
        setVehicleDetailToDelete(null);
        setActivityToDelete(null);
    }

    let client = clients?.find(c => c.clientAccounts.find(ca => ca.id === vehicleDetails?.customerId));

    const getNotesForActivity = (activity) => {
        const type = activity?.activityLogId ? NoteEntityTypes.ACTIVITY : activity?.vehicleDetailsId ? NoteEntityTypes.VEHICLE_DETAIL : null;
        const id = activity?.activityLogId ?? activity?.vehicleDetailsId;
        if (!type) return [];
        return allvehicleActivityNotes?.filter(note => note.entityType == type && note.entityId == id);
    }

    const getIsActivity = (activity) => {
        return !!activity?.activityLogId;
    }
    const getIsVechileDetail = (activity) => {
        return !!activity?.vehicleDetailsId;
    }

    const supportsNotes = (activity) => {
        return getIsActivity(activity) || getIsVechileDetail(activity);
    }

    const activityColumns = [
        {
            key: 'date',
            label: 'Date',
            component: (activity) => (
                <Grid container alignItems='center'>
                    <Grid xs={9} item>{activity.formattedDate}</Grid>
                    <Authorize profile={permissionProfiles.ASSETS.VEHICLE_ACTIVITY_VIEW_AUDIT}>
                        <Grid xs={1} item><EntityAuditTooltip
                            entity={{ ...activity, createdDate: activity.createdDate ?? activity.formattedDate }} /></Grid>
                    </Authorize>
                </Grid>
            ),
            size: 2
        },
        {
            key: 'action',
            label: 'Action',
            component: (activity) => (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                    {activity.action}{activity.cancelledDate ? ' (cancelled)' : ''}{activity.loadId ?
                        <LoadButton latestLoadID={activity.loadId} /> : ''}
                </div>
            ),
            size: 2
        },
        {
            key: 'pendingApproval',
            label: '',
            component: (activity) => (
                <>{getIsActivity(activity) && <ActivityPriceError activity={activity} />}</>
            )
        },
        {
            key: 'lot',
            label: 'Lot',
            component: (activity) => (
                <>{findLotName(activity.lotId)}</>
            ),
            size: 1
        },
        {
            key: 'requestedCompletionDate',
            label: 'Requested Completion Date',
            component: (activity) => (
                <>{FormatService.formatDate(activity.requestedCompletionDate)}</>
            ),
            size: 1
        },
        {
            key: 'withinSla',
            label: 'Within SLA',
            component: (activity) => (
                <>{getWithinSla(activity)}</>
            ),
            size: 1

        },
        {
            key: 'daysCompletedIn',
            label: 'Days Completed In',
            profile: permissionProfiles.ASSETS.VIEW_ACTIVITY_DAYS_COMPLETED_IN,
            component: (activity) => (
                <>{activity.daysCompletedIn == null || activity.daysCompletedIn == undefined ? "-" : activity.daysCompletedIn}</>
            ),
            size: 1

        },
        {
            key: 'internalCost',
            label: 'Internal Cost',
            profile: permissionProfiles.ASSETS.VIEW_ACTIVITY_PRICING,
            component: (activity) => (
                <>{FormatService.formatCurrency(activity.cost)}</>
            ),
            size: 1
        },
        {
            key: 'charge',
            label: 'Charge',
            profile: permissionProfiles.ASSETS.VIEW_ACTIVITY_PRICING,
            component: (activity) => (
                <>{FormatService.formatCurrency(activity.revenue)}</>
            ),
            size: 1
        },
        {
            key: 'notes',
            label: 'Notes',
            component: (activity) => (
                <>{supportsNotes(activity) && <NotesTooltip notes={getNotesForActivity(activity)}
                    entityType={activity?.activityLogId ? NoteEntityTypes.ACTIVITY : activity?.vehicleDetailsId ? NoteEntityTypes.VEHICLE_DETAIL : null}
                    entityId={activity?.activityLogId ?? activity?.vehicleDetailsId} />}</>
            ),
        },
        {
            key: 'edit',
            label: '',
            profile: permissionProfiles.ASSETS.EDIT_ACTIVITY,
            component: (activity) => (
                <>{
                    !activity.loadId ?
                        <IconButton className={activityLogClasses.actionButton} variant="outlined"
                            onClick={() => editActivityLog(activity)}><EditIcon /></IconButton>
                        : null
                }</>
            )
        },
        {
            key: 'mechanical',
            label: '',
            profile: permissionProfiles.ASSETS.EDIT_MECHANICAL,
            component: (activity) => (
                <>{
                    activity.id ?
                        <IconButton className={activityLogClasses.actionButton}
                            variant="outlined"
                            onClick={() => showMechanicalWork(activity)}>
                            <BuildCircleIcon />
                        </IconButton>
                        : null

                }</>
            )
        },
        {
            key: 'delete',
            label: '',
            profile: permissionProfiles.ASSETS.DELETE_ACTIVITY,
            component: (activity) => (
                <>{
                    !activity.loadId ?
                        <Tooltip
                            title={activity.systemGenerated ? 'This activity was generated by the system and should not be deleted' : ''}>
                            <div>
                                <IconButton className={activityLogClasses.actionButton} variant="outlined"
                                    onClick={() => deleteActivity(activity)}
                                    disabled={activity.systemGenerated}><DeleteIcon /></IconButton>
                            </div>
                        </Tooltip> : null
                }</>
            )
        },

    ]

    return (
        <>
            <Paper style={{ position: 'relative' }} className={classes.card} elevation={1} id="activityLog">
                <LoadingSpinner loading={isLoadingActivityLog} />
                <Grid container direction="row" alignItems="center" spacing={2} className={classes.titleRow}>
                    <Grid container alignItems="center">
                        <img src="/icons/icon-log.svg" className={classes.icon} alt="Activity Log" />
                        <h2 className={classes.title}>Activity Log</h2>
                        <div container className={classes.exportButtonWrapper}>
                            <Authorize profile={permissionProfiles.ASSETS.ADD_ACTIVITY}>
                                <AddActivity vehicleId={id} lotId={vehicleDetails?.lotId} clientId={client?.id}
                                    onClose={refetchActivityLog} vehicleTypeId={vehicleDetails?.vehicleTypeId} />
                            </Authorize>
                            <ExportButton fileLabel="ActivityLog" data={activityLog} mapExportData={mapExportData}
                                vin={vehicleDetails?.descriptor} />
                        </div>
                    </Grid>
                </Grid>
                <Grid container className={classes.activityLogContainer}>
                    <Grid container direction='column'>
                        <Grid container item alignItems="center" className={activityLogClasses.headerRow}>
                            {activityColumns.map(column =>
                                <Authorize profile={column.profile}>
                                    <Grid className={activityLogClasses.activityLogHeader} key={`header_${column.key}`} item
                                        xs={column.size}>
                                        {column.label}
                                    </Grid>
                                </Authorize>
                            )}
                        </Grid>

                        {activityLog.map((activity, i) => (
                            <ActivityItem activity={activity} index={i} activityColumns={activityColumns} />
                        ))}
                    </Grid>
                </Grid>
            </Paper>

            {
                !!vehicleDetailsToEdit &&
                <EditVehicleDetails vehicleDetails={vehicleDetailsToEdit} onCancel={() => setVehicleDetailsToEdit(null)}
                    onConfirm={saveActivity} />
            }
            {
                !!activityLogToEdit &&
                <EditActivityLog vehicleId={id} clientId={client?.id} activityLog={activityLogToEdit}
                    onCancel={() => setActivityLogToEdit(null)} onConfirm={saveActivity}
                    vehicleTypeId={vehicleDetails?.vehicleTypeId} />
            }
            <ConfirmationPopup
                title={'Delete Activity'}
                onCancel={() => setOpenPopup(false)}
                onConfirm={() => onDeleteActivity()}
                open={openPopup}
                loading={isDeleting}
            >
                <div>{`Are you sure you want to delete this activity?`}</div>
            </ConfirmationPopup>

        </>
    );
};

export default VehicleActivityLogNew;

const ActivityItem = ({ index, activity, activityColumns }) => {

    const classes = vehicleActivityLogStyle();
    const [expanded, setExpanded] = useState(false);

    const hasSubItems = !!activity?.subActivities?.length;
    return (
        <Grid container alignItems='center' item
            className={`${classes.activityItem} ${hasSubItems && expanded && classes.expandedGroup}`}>
            <Grid container alignItems='center' className={`${hasSubItems && expanded && classes.expandedRow}`}>
                <ActivityRow
                    activity={activity}
                    index={index}
                    activityColumns={activityColumns}
                    expandIcon={hasSubItems &&
                        <IconButton size='small' className={`${classes.expandIcon} ${expanded && classes.expanded}`}
                            onClick={() => setExpanded(!expanded)}><ChevronRightIcon /></IconButton>}
                />
            </Grid>
            {!!activity?.subActivities?.length &&
                <Collapse in={expanded} timeout="auto" unmountOnExit className={classes.collapseWrapper}>
                    <Grid container alignItems='center' direction='column' spacing={1}>
                        {activity?.subActivities?.map((subActivity, subIndex) => <ActivityRow activity={subActivity}
                            index={subIndex}
                            activityColumns={activityColumns} />)}
                    </Grid>
                </Collapse>}
        </Grid>
    )
}

const ActivityRow = ({ index, activity, activityColumns, expandIcon }) => {
    const classes = vehicleActivityLogStyle();

    return (
        <Grid container alignItems='center'>
            {expandIcon}
            {activityColumns.map(column =>
                <Authorize profile={column.profile}>
                    <Grid className={classes.activityCell} key={`${index}_${column.key}`} item xs={column.size}>
                        {column.component(activity)}
                    </Grid>
                </Authorize>
            )}
        </Grid>
    )
}