import { Button, Grid, Popover } from '@material-ui/core';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import EditIcon from "@material-ui/icons/EditSharp";
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import AssetReadinessStatuses from 'features/vehicles/components/AssetReadinessStatuses';
import { useGetFuelTypesQuery } from 'features/vehicles/vehicleSlice';
import moment from "moment";
import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import Authorize from '../../../../components/Authorize';
import { permissionProfiles } from '../../../../components/Authorize/permissionProfiles';
import BasicModal from '../../../../components/BasicModal';
import ConfirmationPopup from "../../../../components/ConfirmationPopup";
import DatePicker from '../../../../components/DatePicker';
import NotificationProvider from '../../../../components/NotificationProvider';
import FormatService from '../../../../services/formatService';
import AssetReadiness from '../../../vehicles/components/AssetReadiness';
import { moveTypes } from '../../enums';
import { useUpdateActualDateMutation } from "../../loadsSlice";
import statuses from '../../loadStatusesWithIcons';
import CreateLoadForm from '../CreateLoadForm';
import CreateLoadFormOld from '../CreateLoadForm/CreateLoadForm_old';
import LoadStatus from '../LoadStatus';
import { loadCardStyle } from './LoadCardStyle';

const displayAddressWrapper = (data) => {
    return <div>
        <div>{data?.addressLine1}</div>
        <div>{data?.city}, {data?.state} {data?.zipCode}</div>
    </div>
}

function LoadCard({ load, onViewClick, readiness, contacts }) {

    const [openUpdateDatePopup, setOpenUpdateDatePopup] = useState(false);
    const [isShown, setIsShown] = useState(false);
    const [isDeliveryDate, setIsDeliveryDate] = useState(false);
    let [updateActualDate, { isLoading, isError, isSuccess }] = useUpdateActualDateMutation();
    let { data: fuelTypes, error: fuelTypesError, isLoading: isLoadingFuelTypes, isSuccess: isSuccessFuelTypes } = useGetFuelTypesQuery();

    const classes = loadCardStyle()

    const [loadToEdit, setLoadToEdit] = useState(null);

    const getDate = (date, format = "MMM DD, yyyy, hh:mm") => {
        return date ? moment(date).format(format) : "";
    }

    const onEdit = (event, isDelivery) => {
        //event.stopPropagation();
        setOpenUpdateDatePopup(true);
        setIsDeliveryDate(isDelivery);
        return false;
    }

    const latestStatus = load?.latestStatusObject ?? {};
    const loadStatus = {
        label: statuses[latestStatus.status]?.label || latestStatus.status,
        icon: statuses[latestStatus.status]?.icon,
        timestamp: latestStatus.timestamp
    }
    const carrier = load?.carrier?.transporterName;
    const updateschedule = async (value) => {

        let result = isDeliveryDate ? await updateActualDate({ loadId: load.id, confirmedDeliveryDate: value.toString(), confirmedPickupDate: load.confirmedPickupDate }) : await updateActualDate({ loadId: load.id, ConfirmedPickupDate: value.toString(), ConfirmedDeliveryDate: load.confirmedDeliveryDate });
        if (result?.error) {
            NotificationProvider.error(`Failed to update schedule`)
            setOpenUpdateDatePopup(false)
        } else {
            NotificationProvider.success(`Successfully updated schedule`);
            setOpenUpdateDatePopup(false);
        }
    }

    const [openEditLoad, setOpenEditLoad] = useState(false);

    const editLoad = () => {
        setLoadToEdit(load)
        setOpenEditLoad(true)
    }


    return (
        <div className={classes.loadBox}>
            <Grid container direction='row' >
                <Grid item xs={3} className={classes.cardItem} >
                    <ArrowUpwardSharpIcon className={classes.upwardArrow}></ArrowUpwardSharpIcon>
                    <Grid className={classes.locationWrapper} >
                        <Grid item className={classes.displayAddressWrapper} >
                            {load?.pickupLocation?.nodeName} {load?.pickupLocation?.name}
                            {!!contacts?.length &&
                                <LoadContacts contacts={contacts} />
                            }
                        </Grid>
                        <Grid item className={classes.displayAddressWrapper} >
                            {displayAddressWrapper(load?.pickupLocation)}
                        </Grid>
                        <Grid item>
                            <div className={classes.externalID}>
                                <label>Earliest Pickup Date: </label> {`${getDate(load?.pickupStartDate, "MMM DD, yyyy")}`}
                            </div>
                        </Grid>


                        <Grid item>
                            <div className={classes.dateWrapper} onMouseEnter={() => setIsShown(true)}
                                onMouseLeave={() => setIsShown(false)}>
                                <div><b className={classes.confirmedDateTextWrapper}>Estimated Pickup Date:</b></div>
                                <div className={classes.dateValue}>{load?.confirmedPickupDate ? `${getDate(load?.confirmedPickupDate, "MMM DD, yyyy")}` : 'Not set'}</div>
                                {
                                    isShown ?
                                        <Authorize profile={permissionProfiles.LOAD.LOAD_EDIT_DATES}><button
                                            title={'add pickup date'}
                                            edge='end'
                                            aria-label='add date'
                                            onClick={(event) => onEdit(event, false)}
                                            className={classes.editDatesButton}>
                                            <EditIcon className={classes.pencilIcon} fontSize='small' />
                                        </button>
                                        </Authorize> : null
                                }
                            </div>
                        </Grid>
                        {
                            load?.actualPickupDate && <Grid item>
                                <div className={classes.locationDateWrapper}>
                                    <b className={classes.confirmedDateTextWrapper}>Actual Pickup Date: </b>
                                    {FormatService.formatDateTime(load?.actualPickupDate)}
                                </div>
                            </Grid>
                        }
                    </Grid>
                </Grid>

                <Grid item xs={3} className={classes.cardItem}>
                    <ArrowDownwardSharpIcon className={classes.downwardArrow}></ArrowDownwardSharpIcon>
                    <Grid className={classes.locationWrapper} >
                        <Grid item className={classes.displayAddressWrapper} >
                            {load?.deliveryLocation?.nodeName} {load?.deliveryLocation?.name}
                        </Grid>
                        <Grid item className={classes.displayAddressWrapper}>
                            {displayAddressWrapper(load?.deliveryLocation)}
                        </Grid>
                        <Grid item>
                            <div className={classes.externalID}>
                                <label>Latest Delivery Date: </label> {`${getDate(load?.deliveryStartDate, "MMM DD, yyyy")}`}
                            </div>
                        </Grid>

                        <Grid item>
                            <div className={classes.dateWrapper} onMouseEnter={() => setIsShown(true)}
                                onMouseLeave={() => setIsShown(false)}>
                                <div><b className={classes.confirmedDateTextWrapper}>Estimated Delivery Date: </b></div>
                                <div className={classes.dateValue}>{load?.confirmedDeliveryDate ? `${getDate(load?.confirmedDeliveryDate, "MMM DD, yyyy")}` : 'Not set'}
                                    {
                                        isShown ?
                                            <Authorize profile={permissionProfiles.LOAD.LOAD_EDIT_DATES}><button
                                                title={'add delivery date'}
                                                edge='end'
                                                aria-label='add date'
                                                onClick={(event) => onEdit(event, true)}
                                                className={classes.editDatesButton}>
                                                <EditIcon className={classes.pencilIcon} fontSize='inherit' />
                                            </button> </Authorize> : null
                                    }
                                </div>
                            </div>
                        </Grid>
                        {
                            load?.actualDeliveryDate && <Grid item>
                                <div className={classes.locationDateWrapper}>
                                    <b className={classes.confirmedDateTextWrapper}>Actual Delivery Date: </b>
                                    {FormatService.formatDateTime(load?.actualDeliveryDate)}
                                </div>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={3} direction='column' className={classes.cardItem}>
                    <Grid item className={classes.statusCardItem}>
                        <div>{loadStatus.icon}</div>
                        <Grid className={classes.locationWrapper} >
                            <Grid item className={classes.status}>
                                <LoadStatus load={load} status={load?.latestStatusObject} />
                            </Grid>
                            <Grid item>
                                <div className={classes.statusDate}>{FormatService.formatDateTime(load?.latestStatusDate)}</div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid>
                        <div className={classes.externalID}><label>Request Date: </label>{FormatService.formatDateTime(load?.createdDate)}</div>
                    </Grid>
                    {load?.businessUnitName && <Grid item>
                        <div className={classes.externalID}>
                            <label>Business Unit: </label> {load?.businessUnitName}
                        </div>
                    </Grid>}
                    {load?.neededByWeek && <Grid item>
                        <div className={classes.externalID}>
                            <label>Needed By Week: </label> {load?.neededByWeek}
                        </div>
                    </Grid>}
                    {load?.handoffWeek && <Grid item>
                        <div className={classes.externalID}>
                            <label>Handoff Week: </label> {load?.handoffWeek}
                        </div>
                    </Grid>}
                    {load?.miles && <Grid item>
                        <div className={classes.externalID}>
                            <label>Miles: </label> {load?.miles}
                        </div>
                    </Grid>}
                </Grid>
                <Grid item xs={3} direction='column' className={classes.lastCol}>

                    <Grid item>

                        <div className={classes.vin}>{load?.assets?.map(asset =>
                            <>
                                <div style={{ display: 'flex' }}>VIN:&nbsp;<NavLink exact
                                    to={`/assets/detail/${asset.id}`}>
                                    {asset.vin}
                                </NavLink>
                                    &nbsp;
                                    {readiness &&
                                        <AssetReadinessStatuses readinessStatus={readiness} />
                                    }
                                </div>
                                {!!asset.fuelType && <div className={classes.externalID}><label>Fuel Type:</label> {fuelTypes?.find(f => f.id == asset.fuelType)?.name}</div>}
                            </>
                        )}

                        </div>
                    </Grid>
                    {carrier && <Grid item>
                        <div className={classes.externalID}><label>Carrier: </label>{carrier}</div>
                    </Grid>}
                    {load?.moveType && <Grid item>
                        <div className={classes.externalID}><label>Move Type:</label> {moveTypes.find(t => t.id == load.moveType)?.name}</div>
                    </Grid>}
                    {load?.movementType?.name && <Grid item>
                        <div className={classes.externalID}><label>Move Type:</label> {load.movementType?.name}</div>
                    </Grid>}
                    <Grid item>
                        <div className={classes.externalID}><label>Asset ID:</label> {load?.externalId}</div>
                    </Grid>
                    <Grid item>
                        <div className={classes.externalID}><label>VRID:</label> {load?.id}</div>
                    </Grid>
                    <Authorize profile={permissionProfiles.LOAD.LOAD_LM_INFO}>
                        <Grid item>
                            <div className={classes.externalID}><label>LoadMate ID:</label> {load?.lmId}</div>
                        </Grid>
                    </Authorize>
                    <Authorize profile={permissionProfiles.LOAD.LOAD_LM_INFO}>
                        <Grid item>
                            <div className={classes.externalID}><label>Shipper Load ID:</label> {load?.shipperLoadId}</div>
                        </Grid>
                    </Authorize>
                </Grid>

                <Grid className={classes.btns}>
                    {/* {!load?.lmId && */}
                    <Authorize profile={permissionProfiles.LOAD.LOAD_LM_INFO}>
                        <Button
                            variant="contained"
                            title={'Edit Load'}
                            onClick={editLoad}
                            className={classes.editButton}
                        >
                            Edit
                        </Button>
                    </Authorize>
                    {/* } */}
                    {onViewClick && <Button
                        variant="contained"
                        title={'View Load'}
                        onClick={() => onViewClick()}
                        className={classes.editButton}
                    >
                        View
                    </Button>}
                </Grid>
            </Grid>
            <UpdateDatePopup
                load={load}
                open={openUpdateDatePopup}
                onCancel={() => setOpenUpdateDatePopup(false)}
                onConfirm={updateschedule}
                isDeliveryDate={isDeliveryDate}
                isLoading={isLoading}
            />
            <BasicModal
                open={openEditLoad}
                header={`Editing Load`}
                wide={true}
                handleClose={() => setOpenEditLoad(false)}
                component={loadToEdit?.moveType ?
                    <CreateLoadFormOld
                        handleClose={() => setOpenEditLoad(false)}
                        loadToEdit={loadToEdit}
                    /> :
                    <CreateLoadForm
                        handleClose={() => setOpenEditLoad(false)}
                        loadToEdit={loadToEdit}
                    />
                }
            />

        </div >
    );
}

export default LoadCard


function LoadContacts({ contacts }) {

    const [anchorEl, setAnchorEl] = useState(false);
    const classes = loadCardStyle();

    useEffect(() => {

    }, [])

    return (
        <>
            <Button onClick={(e) => setAnchorEl(e.currentTarget)}>
                <RecentActorsIcon />
            </Button>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className={classes.contacts}>
                    {contacts.map(c => <div>{c.name} &nbsp; {c.email}</div>)}
                </div>
            </Popover>
        </ >
    );
}

function UpdateDatePopup({ load, open, isDeliveryDate, onConfirm, onCancel, isLoading }) {

    const [selectedDate, setSelectedDate] = useState(isDeliveryDate ? load.confirmedDeliveryDate : load.confirmedPickupDate);

    useEffect(() => {
        if (open) {
            setSelectedDate();
        } else {
            setSelectedDate(isDeliveryDate ? load.confirmedDeliveryDate : load.confirmedPickupDate);
        }
    }, [open])

    return (
        <ConfirmationPopup
            title={isDeliveryDate ? "Update Delivery Date" : "Update Pickup Date"}
            onCancel={(event) => { event.stopPropagation(); onCancel() }}
            onConfirm={(e) => {
                e.stopPropagation();
                onConfirm(selectedDate);
            }}
            open={open}
            loading={isLoading}
        >
            <div onClick={(event) => event.stopPropagation()}><DatePicker
                identifier={load.id}
                value={selectedDate}
                onSelect={setSelectedDate}
            />
            </div>
        </ConfirmationPopup>
    );
}