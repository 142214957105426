import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Image } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import { useState } from "react";
import CustomInput from "../../../../../src/components/CustomInput";
import Authorize from "../../../../components/Authorize";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import BasicModal from '../../../../components/BasicModal';
import ConfirmationPopup from "../../../../components/ConfirmationPopup";
import mobileBreakpoint from "../../../../utils/mobileBreakpoint";
import Carousel from "./Carrousel";
import { fadeInImageStyle } from "./FadeInImageStyle";


const FadeInImage = ({ 
                    img, alt, readonly = false, showFileName = false,
                    isDocument = false, onDelete, onEdit, imageTypes = null,
                    changeImageTypeId = null, existingImages = null,
                    showCarousel = false, imagesForCarousel = null}) => {
    const classes = fadeInImageStyle();
    const isMobile = useMediaQuery(mobileBreakpoint);
    const [loaded, setOnLoaded] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [imageTypeId, setImageTypeId] = useState(img?.imageTypeId || 0);
    let [showingImages, setShowingImages] = useState(false);
    let [imagesToShow, setImagesToShow] = useState([]);
    let [defaultIndexImage, setDefaultIndexImage] = useState(0);
    const [imageDateSelected, setImageDateSelected] = useState(null);


    const onLoad = () => setOnLoaded(true);

    const openModalConfirmDelete = () => {
        setIsEditing(false);
        setOpenPopup(true);
    }

    const openModalConfirmEdit = () => {
        setIsEditing(true);
        setOpenPopup(true);
    }

    const onSelectImageType = (index, val) => {
        let imageTypeEntity = imageTypes.find(x => x.id == val);
        let imageEntity = existingImages.find(x => x.id == index);
        let image =
        {
            id: imageEntity?.id,
            url: imageEntity?.url,
            imageTypeId: val,
            fileName: imageTypeEntity?.label
        };
        changeImageTypeId(image);
        setImageTypeId(val);
    }

    const onCancelEvent = () => {
        setOpenPopup(false);
    }
    
    const closeShowingPhotos = () => {
        setShowingImages(false);
    }

    const opeShowingPhotos = (imageDate) => {
        
        imagesForCarousel[imageDate].forEach((element, index) => { 
            if(element.url == img?.url){
                setDefaultIndexImage(index);
            }
        });
        setImageDateSelected(imageDate);
        setImagesToShow(imagesForCarousel[imageDate]);
        setShowingImages(true);
    }

    const isDoc = img?.type === 'pdf' || (img?.url ? img?.url?.endsWith("pdf") : img?.fileUrl?.endsWith("pdf"));
    return (
        <div className={`${classes.imageContainer} ${isMobile && classes.imageContainerMobile}`}>
            <div className={classes.placeholder}>
                {!isDoc && <Image color="secondary" className={classes.placeholderIcon} />}
                {isDoc &&
                    <PictureAsPdfOutlinedIcon color="secondary" className={classes.placeholderIcon} />
                }
            </div>
            {
                !showCarousel ? 
                <a href={img?.url ?? img?.fileUrl} target="_blank">
                    <img
                        className={`${classes.image} ${loaded ? classes.imageVisible : ""}`}
                        src={img?.thumbnail || img?.fileUrl || img?.url}
                        alt={alt}
                        onLoad={onLoad}
                    />
                    {(showFileName || (img?.fileName && img?.url.endsWith("pdf"))) &&
                        <div className={classes.fileName}>{img?.fileName}</div>
                    }
                </a>:
                <div onClick={() => {opeShowingPhotos(img?.createdDate)}}>
                    <img
                        className={`${classes.image} ${loaded ? classes.imageVisible : ""}`}
                        src={img?.thumbnail || img?.fileUrl || img?.url}
                        alt={alt}
                        onLoad={onLoad}
                    />
                    {(showFileName || (img?.fileName && img?.url.endsWith("pdf"))) &&
                        <div className={classes.fileName}>{img?.fileName}</div>
                    }
                </div>
                
            }
            
            {(onDelete && img?.id && !isDoc && !readonly) &&
                <div className={classes.buttomsContainer}>
                    <Authorize profile={permissionProfiles.ASSETS.ASSET_EDIT}>
                        <IconButton
                            title={'Delete image'}
                            edge='end'
                            aria-label='delete'
                            className={{...classes.imageDeleteIcon}}
                            onClick={openModalConfirmDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </Authorize>
                    <Authorize profile={permissionProfiles.ASSETS.ASSET_EDIT}>
                        <IconButton
                            title={'Edit image'}
                            edge='end'
                            aria-label='edit'
                            className={classes.imageDeleteIcon }
                            onClick={openModalConfirmEdit}>
                            <EditIcon />
                        </IconButton>
                    </Authorize>
                </div>
            }
            <ConfirmationPopup
                title={!isEditing ? 'Delete Image' : "Edit Image"}
                onCancel={() => onCancelEvent()}
                onConfirm={async () => {
                    setProcessing(true);
                    if(!isEditing){
                        await onDelete(img, isDocument);
                    }
                    else if (isEditing){
                        onEdit && (await onEdit(img, isDocument));
                    }
                    setProcessing(false);
                    setOpenPopup(false);
                }}
                open={openPopup}
                loading={processing}
            >
                {
                    !isEditing ?
                    <div>
                        <div>{'Are you sure you want to delete this image?'}</div>
                        <div className={classes.imagePreviewWrapper}>
                            <img
                                className={`${classes.image} ${loaded ? classes.imageVisible : ""}`}
                                src={img?.url ?? img?.fileUrl}
                                alt={alt}
                                onLoad={onLoad}
                            />
                        </div>
                    </div>
                    :
                    <div>
                        <div>{"Are you sure you want to change the type for this image?"}</div>
                        <div className={classes.imagePreviewWrapperEditting}>
                            <img
                                className={`${classes.imageEditing} ${loaded ? classes.imageVisible : ""}`}
                                src={img?.url ?? img?.fileUrl}
                                alt={alt}
                                onLoad={onLoad}
                            />
                            {
                                (imageTypes && !img.isExternal) && 
                                <div style={{marginTop: 10}}>
                                    <CustomInput
                                        id={imageTypeId}
                                        label="Image type"
                                        value={imageTypeId}
                                        elementType="dropdown"
                                        onChange={val => onSelectImageType(img.id, +val)} 
                                        values={imageTypes}
                                        showEmpty={true}
                                    />
                                </div>
                            }
                            
                        </div>
                    </div>
                }
                
            </ConfirmationPopup>
            <BasicModal
                open={showingImages}
                header={`Photos ${imageDateSelected}`}
                wide={true}
                handleClose={closeShowingPhotos}
                component={<Carousel 
                            images={imagesToShow}
                            onDelete={onDelete}
                            onEdit={onEdit}
                            imageTypes={imageTypes}
                            changeImageTypeId={changeImageTypeId}
                            existingImages={existingImages}
                            defaultValue={defaultIndexImage}
                            />}
            />
        </div>
    );
};

export default FadeInImage;
