import { Button, Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { orderBy } from "lodash";
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from 'react-router-dom';
import Authorize from "../../../components/Authorize";
import { permissionProfiles } from "../../../components/Authorize/permissionProfiles";
import DataListHeader from "../../../components/DataListHeader";
import DataTable from "../../../components/DataTable";
import ExportButton from "../../../components/ExportButton";
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import PageComponent from '../../../components/Page/PageComponent';
import useSortOptions from "../../../hooks/useSortOptions";
import FilterService from "../../../services/filterService";
import { setSearchVal } from '../usersFiltersSlice';
import { useGetUsersQuery } from '../usersSlice';
import EditUserDialog from './editUser/EditUserDialog';
import usersPageStyle from './UsersPageStyle';
import { IconAdd } from 'icons';
import CreateUserDialog from './CreateUserDialog';

const useStyles = usersPageStyle;

const UsersPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    let { data: users, error: usersError, isLoading: isLoadingUsers } = useGetUsersQuery();
    users = users?.data || [];

    const [clickedUser, setClickedUser] = useState(null);
    const [sortOptions, sortData] = useSortOptions();
    const { searchVal } = useSelector(state => state.usersFilters);
    const [openDialog, setOpenDialog] = useState(false);

    let usersColumns = [
        { name: "User Name", key: "userName" },
        { name: "email", key: "email" }
    ];

    const onSearch = (searchedVal) => {
        dispatch(setSearchVal(searchedVal.trim()));
    }

    const onEdit = id => {
        const user = users?.find(u => u.id == id);
        setClickedUser(user);
    };

    const rowActions = (rowId) => ([
        {
            component: <EditIcon onClick={() => onEdit(rowId)} />,
        }
    ])

    const onCancel = () => {
        setClickedUser(null);
    }

    const mapExportData = v => ({
        Email: v.email,
        UserName: v.userName,
    });

    const onClickCreate = () => {
        setOpenDialog(true);
        setClickedUser(null);
    }

    const isLoading = () => isLoadingUsers;

    let filteredUsers = FilterService.filter([], searchVal, users);

    const history = useHistory();

    return (
        <Authorize profile={permissionProfiles.USER.VIEW_USERS}>
            <PageComponent
                header={<DataListHeader
                    title={'Manage Users'}
                    data={users}
                    onSearch={onSearch}
                    actionButtons={(
                        <Grid container spacing={1}>
                            <Grid item><Button variant='contained' color='primary' startIcon={<IconAdd />} onClick={onClickCreate}>Create User</Button></Grid>
                            <Grid item><Authorize profile={permissionProfiles.USER.VIEW_USERS}><Button variant='contained' color='primary' onClick={() => history.push(`permissions`)} >Permissions</Button></Authorize></Grid>
                            <Grid item><ExportButton fileLabel="users" data={users} mapExportData={mapExportData} /></Grid>
                        </Grid>
                    )}
                />}
            >
                <>
                    {
                        isLoading() ? <LoadingSpinner loading={isLoading()} /> :
                            <DataTable
                                columns={usersColumns}
                                rows={orderBy(
                                    filteredUsers,
                                    sortOptions.columnToSort,
                                    sortOptions.sortDirection
                                )}
                                rowIdentifier='id'
                                onSort={sortData}
                                sortDirection={sortOptions.sortDirection}
                                columnToSort={sortOptions.columnToSort}
                                noItemsMessage='There are no users to display'
                                actions={rowActions}
                            />
                    }
                </>
                <EditUserDialog user={clickedUser} onCancel={onCancel} />
                <CreateUserDialog open={openDialog} setOpenDialog={setOpenDialog} user={clickedUser}></CreateUserDialog>
            </PageComponent>
        </Authorize>
    );
};

export default UsersPage;
