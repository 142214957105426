import { Badge, Drawer, Grid, IconButton } from "@material-ui/core";
import FilterListIcon from '@material-ui/icons/FilterList';
import { useGetMechanicalActivitiesQuery } from "features/clients/contracts/MechanicalWorkConfig/mechanicalsSlice";
import { MechActivityStatusEnum } from "features/landing/components/MechanicalDashboard/MechanicalEnums";
import { useGetUserLotRelationsQuery } from "features/users/usersSlice";
import { useGetAuditsByUserQuery } from "features/vehicles/VehicleLotAudit/lotAuditSlice";
import { IconClipboard } from "icons";
import { orderBy } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import Authorize from "../../../../components/Authorize";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import DataSearchBar from "../../../../components/DataSearchBar";
import DropdownFilter from "../../../../components/DropdownFilter";
import EmptyPlaceholder from "../../../../components/EmptyPlaceholder/EmptyPlaceholder";
import ExportButton from "../../../../components/ExportButton";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import LotsDropdown from "../../../../components/SharedDropdowns/LotsDropDown/LotsDropDown";
import FilterService, { fieldTypes } from "../../../../services/filterService";
import FormatService from "../../../../services/formatService";
import { useGetClientsQuery } from "../../../clients/clientSlice";
import { useGetAllInspectionTaskQuery } from "../../../landing/components/KpiDashboard/navigations/KpiInspection/inspectionSlice";
import { loadStatuses } from "../../../loads/enums";
import { useGetLoadsQuery } from "../../../loads/loadsSlice";
import { useGetLotsQuery } from "../../../lots/lotSlice";
import { useGetReadinessQuery } from "../../../vehicles/readiness/readinessSlice";
import { TaskSetup, TaskTypes } from "../../taskConsts";
import { setFilters, setShowDomiciledOnly, setTaskPanelOpen, useGetTasksQuery } from "../../taskSlice";
import TaskItem from "../TaskItem";
import { TasksPanelStyles } from "./TasksPanelStyles";

const TasksPanel = () => {
    const classes = TasksPanelStyles();
    let { data: tasks, error: tasksError, isLoading: isLoadingTasks } = useGetTasksQuery();
    let { data: lots, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, isLoading: isLoadingClients } = useGetClientsQuery();
    let { data: vehiclesReadiness, error: vehiclesReadinessError, isLoading: isLoadingVehiclesReadiness } = useGetReadinessQuery({});
    let { data: loads, error: loadListError, isFetching: isLoadingLoads } = useGetLoadsQuery({ lmStatus: null });

    let { data: inspectionTasks, error: inspectionTaskListError, isFetching: isLoadingInspectionTasks } = useGetAllInspectionTaskQuery({});
    let { data: audits, error: auditsError, isLoading: isAuditsingLots } = useGetAuditsByUserQuery(null);
    let { data: mechanicalActivities, error: mechanicalActivitiesError, isLoading: isLoadingMechanicalActivities } = useGetMechanicalActivitiesQuery();

    audits = audits?.data || [];

    let { data: userLotRelations, error: relationsError, isLoading: isLoadingRelations } = useGetUserLotRelationsQuery();
    userLotRelations = userLotRelations?.filter(r => r.relationType === 3)

    const regionalManagerLots = lots?.filter(l => userLotRelations?.map(r => r.lotId)?.includes(l.id))

    const isLoading = isLoadingTasks || isLoadingVehiclesReadiness || isLoadingInspectionTasks;

    const [searchVal, setSearchVal] = useState();

    const dispatch = useDispatch();
    const { taskPanelOpen, showDomiciledOnly, filters } = useSelector(state => state.taskState);


    const filterConfig = [
        {
            type: fieldTypes.TEXT,
            size: 3,
            key: 'clientId',
            values: [{ id: '', name: 'All clients' }, ...(clients ?? [])],
            profile: permissionProfiles.TASKS.TASK_VIEW_CLIENT
        },
        {
            type: fieldTypes.LOT,
            size: 3,
            label: !!filters?.lotId?.value?.length ? undefined : 'All lots',
            key: 'lotId',
            values: [...(lots ?? [])],
            multiple: true
        },
        {
            type: fieldTypes.TEXT,
            size: 3,
            key: 'available',
            values: [{ id: '', name: "All availability" }, { id: '1', name: "Available" }, { id: '2', name: "Unavailable" }],
        },
        {
            type: fieldTypes.TEXT,
            size: 2,
            key: 'taskType',
            values: [
                { id: '', name: "All tasks" },
                ...(Object.values(TaskTypes).map(taskType => ({ id: taskType, name: TaskSetup[taskType]?.label })))
            ]
            //{ id: '1', name: "Washes" }, { id: '2', name: "Transports" }, { id: '3', name: "Inspection" }, { id: '4', name: "Audits" }, { id: '4', name: "Audits" }],
        },
    ];

    const readinessByVehicleId = vehiclesReadiness?.reduce(function (map, obj) {
        map[obj.vehicleId] = obj;
        return map;
    }, {})

    const clientLookup = clients?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {})

    const lotLookup = lots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {})

    const loadLookup = loads?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const priority = {
        LOAD_7_DAYS_NOT_READY: 1,
        MECHANICALS: 2,
        LOAD_7_DAYS_READY: 3,
        LOAD_DELIVERED_7_DAYS: 4,
        OVERDUE_WASH_WITH_LOAD: 5,
        OVERDUE_WASH_NO_LOAD: 6,
        LOAD_MORE_THAN_7_DAYS_NOT_READY: 7,
        DUE_WASH_WITH_LOAD: 8,
        DUE_WASH_NO_LOAD: 9,
        LOAD_READY_NOT_AT_RISK: 10,
        DUE_WASH_NOT_AVAILABLE: 11
    }

    const washTasks = tasks?.data?.pendingTasks?.map(task => {
        const readiness = readinessByVehicleId?.[task?.entityId];
        //if(!readiness?.available) return null;
        if (showDomiciledOnly && (!readiness?.domicileLot || readiness?.domicileLot != task?.lotId)) return null;
        const taskWithData = {
            ...task,
            entity: readiness,
            searchTerm: `${readiness?.descriptor}$$${readiness?.assetId}`,
            lot: lotLookup?.[task.lotId],
            client: clientLookup?.[readiness?.clientId],
            clientId: readiness?.clientId,
            readinessStatus: readiness?.washStatus,
            latestCompletedWash: readiness?.latestCompletedWash ? moment(readiness?.latestCompletedWash) : undefined,
            available: readiness?.available ? 1 : 2,
            taskType: TaskTypes.WASH
        }
        if (taskWithData?.entity) {
            // if (taskWithData?.entity && taskWithData?.entity?.lotId == taskWithData?.entity?.domicileLot) {
            //     taskWithData.domicilePriority = 1;
            // } else {
            //     taskWithData.domicilePriority = 2;
            // }

            // if (taskWithData?.readinessStatus == 3) {
            //     taskWithData.readinessPriority = 1;
            // } else if (taskWithData?.readinessStatus == 2) {
            //     taskWithData.readinessPriority = 2;
            // }

            // if (taskWithData?.entity?.latestLoadId) {
            //     taskWithData.washloadPriority = 1;
            // } else {
            //     taskWithData.washloadPriority = 2;
            // }

            const currentLoad = loadLookup?.[taskWithData?.entity?.latestLoadId];

            if (taskWithData?.readinessStatus == 3 && (currentLoad?.pickupStartDate >= new Date())) {
                taskWithData.generalPriority = priority.OVERDUE_WASH_WITH_LOAD;
            } else if (taskWithData?.readinessStatus == 3 && !(currentLoad?.pickupStartDate >= new Date())) {
                taskWithData.generalPriority = priority.OVERDUE_WASH_NO_LOAD;
            }

            if (taskWithData?.readinessStatus == 2 && (currentLoad?.pickupStartDate >= new Date())) {
                taskWithData.generalPriority = priority.DUE_WASH_WITH_LOAD;
            }
            else if (taskWithData?.readinessStatus == 2 && !(currentLoad?.pickupStartDate >= new Date())) {
                taskWithData.generalPriority = priority.DUE_WASH_NO_LOAD;
            }

            if (taskWithData?.available == 2 && taskWithData?.readinessStatus == 2) {
                taskWithData.generalPriority = priority.DUE_WASH_NOT_AVAILABLE;
            }
        }
        return taskWithData

    }).filter(v => v);

    const getDifferenceInDays = (startDate, endDate) => {
        const difference = endDate?.getTime() - startDate?.getTime();
        const differenceInDays = Math.ceil(difference / (1000 * 3600 * 24));

        return differenceInDays;
    }

    const loadsList = loads?.filter(load => load?.latestStatus == loadStatuses.ACCEPTED)

    const loadTasks = loadsList?.map(load => {
        const readiness = readinessByVehicleId?.[load?.assets[0].id];
        const taskWithData = {
            ...load,
            entity: readiness,
            pickupDate: load?.calcPickupDate,
            readinessStatus: readiness?.washStatus,
            taskType: TaskTypes.LOADS,
            isLoadPriority: 1,
            available: readiness?.available ? 1 : 2,
            lotId: load?.pickupLocationId,
            clientId: readiness?.clientId,
            lot: lotLookup?.[load?.pickupLocationId],
            client: clientLookup?.[readiness?.clientId],
        }

        // if (taskWithData?.isAtRisk == 1) //pickup today
        // {
        //     taskWithData.pickupPriority = 1
        // }
        // if (taskWithData?.readinessStatus == 2 || taskWithData?.readinessStatus == 3) { //if pending pickup and Not Ready
        //     taskWithData.readinessPriority = 1; //before yellow washes
        //     taskWithData.domicilePriority = 1; //that are on domicile lots
        // }


        const days = getDifferenceInDays(new Date(), new Date(taskWithData?.pickupDate))

        if ((days >= 0 && days <= 7) && (taskWithData.readiness == 2 || taskWithData.readiness == 3)) { //pickup is within 7 days and asset is not ready
            taskWithData.generalPriority = priority.LOAD_7_DAYS_NOT_READY;
        }

        if ((days >= 0 && days <= 7) && (taskWithData.readiness == 1)) { //pickup is within 7 days and asset is ready
            taskWithData.generalPriority = priority.LOAD_7_DAYS_READY;
        }

        const deliveredDays = getDifferenceInDays(new Date(), new Date(taskWithData?.deliveryStartDate))

        if (deliveredDays >= 0 && deliveredDays <= 7) {
            taskWithData.generalPriority = priority.LOAD_DELIVERED_7_DAYS;
        }

        if (days > 7 && (taskWithData.readiness == 2 || (taskWithData.readiness == 3))) {
            taskWithData.generalPriority = priority.LOAD_MORE_THAN_7_DAYS_NOT_READY;
        }

        if (taskWithData?.readinessStatus == 1 && taskWithData?.isAtRisk != 1) {
            taskWithData.generalPriority = priority.LOAD_READY_NOT_AT_RISK;
        }

        return taskWithData
    });

    const inspectionLoadedTasks = inspectionTasks?.map(inspection => {
        const taskWithData = {
            ...inspection,
            taskType: TaskTypes.Inspection,
            lot: lotLookup?.[inspection?.lotId],
            lotId: inspection?.lotId,
            inspectionPriority: 1,
        }
        return taskWithData
    });

    const auditsList = audits?.filter(audit => audit?.status === 1 && regionalManagerLots?.map(r => r.id)?.includes(audit.lotId));

    const auditsTasks = auditsList?.map(audit => {
        const taskWithData = {
            ...audit,
            taskType: TaskTypes.Audits,
            lot: lotLookup?.[audit?.lotId],
            lotId: audit?.lotId,
            auditPriority: 1,
        }
        return taskWithData
    });

    const mechanicalTasks = mechanicalActivities?.filter(m => m.activityStatus == MechActivityStatusEnum.REQUESTED_BY_CLIENT)?.map(activity => {
        const readiness = readinessByVehicleId?.[activity?.vehicleId];
        const taskWithData = {
            ...activity,
            searchTerm: `${readiness?.descriptor}$$${readiness?.assetId}`,
            vehicle: readiness,
            taskType: TaskTypes.Mechanicals,
            lot: lotLookup?.[activity?.lotId],
            lotId: activity?.lotId,
            //mechanicalPriority: 1
            generalPriority: priority.MECHANICALS
        }
        return taskWithData
    });

    const mapExportData = task => ({
        ['Task Type']: TaskSetup[task.taskType]?.label,
        ['Client']: task?.client?.name,
        ['VIN']: task?.entity?.descriptor,
        ['Asset ID']: task?.entity?.assetId,
        ['LOT']: task?.lot?.name,
        ['Request Date']: task?.createdDate ? FormatService.formatDate(task?.createdDate) : 'N/A',
        ['Requested Completion Date']: task?.requestedCompletionDate ? FormatService.formatDate(task?.requestedCompletionDate) : 'N/A',
        ['Pickup Location']: task?.pickupLocation?.name ? task?.pickupLocation?.name : 'N/A',
        ['Pickup Date']: task?.calcPickupDate ? FormatService.formatDate(task?.calcPickupDate) : 'N/A'
    });

    const allTasks = [...(washTasks ?? []), ...(loadTasks ?? []), ...(inspectionLoadedTasks ?? []), ...(auditsTasks ?? []), ... (mechanicalTasks ?? [])];

    // const sortedTasks = orderBy(FilterService.filter(filters, searchVal, allTasks, filterConfig)
    //     , ['inspectionPriority', 'auditPriority', 'generalPriority','pickupPriority', 'domicilePriority', 'readinessPriority', 'washloadPriority', 'latestCompletedWash', 'isLoadPriority'],
    //     ['asc', 'asc', 'asc', 'asc', 'asc', 'asc', 'desc', 'asc']);

    const sortedTasks = orderBy(FilterService.filter(filters, searchVal, allTasks, filterConfig)
        , ['inspectionPriority', 'auditPriority', 'generalPriority'],
        ['asc', 'asc', 'asc']);

    const Row = ({ index, style }) => (
        <div style={style}>
            <TaskItem task={sortedTasks?.[index]} key={index} />
        </div>
    );

    const openPanel = value => {
        dispatch(setTaskPanelOpen(value));
    }

    return (
        <>
            <Authorize profile={permissionProfiles.TASKS.TASK_LIST}>
                <IconButton title='Tasks' onClick={() => openPanel(true)} >
                    <Badge badgeContent={allTasks?.length} color="primary">
                        <IconClipboard />
                        {/* <AssignmentIcon className={classes.icon} /> */}
                    </Badge>
                </IconButton>
            </Authorize>
            <Drawer
                anchor={'right'}
                open={taskPanelOpen}
                onClose={() => {
                    dispatch(setShowDomiciledOnly(false));
                    dispatch(setFilters({ lotId: { value: [] }, clientId: { value: '' } }));
                    openPanel(false)
                }}
                BackdropProps={{ invisible: true }}
            >
                <Grid direction="column" container className={classes.panel}>
                    <Grid container className={classes.header}>
                        <Grid xs={10} item> Tasks ({sortedTasks?.length})</Grid>
                        <Grid xs={2} item alignContent="flex-end">  <ExportButton btnStyle='' label={''} fileLabel="tasks" data={sortedTasks} mapExportData={mapExportData} /></Grid>
                    </Grid>
                    <Grid container alignItems="center">
                        <Grid container direction='row' alignItems='center' className={classes.filters}>
                            <Grid item className={classes.iconContainer}><FilterListIcon /></Grid>
                            {filterConfig?.map(filter =>
                                <Authorize profile={filter.profile} key={filter.key}>
                                    <Grid xs={filter.size} item className={classes.filterWrapper}>
                                        <Grid container direction='row' alignItems='center' >
                                            <Grid xs={12} item >
                                                {filter?.type == fieldTypes.LOT ?
                                                    <LotsDropdown
                                                        clientId={filters['clientId']?.value}
                                                        title={filter.label}
                                                        values={filter.values}
                                                        value={filters?.[filter.key]?.value ?? (filter.multiple && [])}
                                                        onSelect={(val) => dispatch(setFilters({ ...filters, [filter.key]: { value: val } }))}
                                                        multiple
                                                    />
                                                    : <DropdownFilter
                                                        title={filter.label}
                                                        values={filter.values}
                                                        value={filters?.[filter.key]?.value ?? (filter.multiple && [])}
                                                        onSelect={(val) => dispatch(setFilters({ ...filters, [filter.key]: { value: val } }))}
                                                        multiple={filter.multiple}
                                                    />}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Authorize>
                            )}
                        </Grid>
                    </Grid>
                    <Grid alignItems='center' className={classes.search}>
                        <DataSearchBar onSearch={(val) => setSearchVal(val)} searchVal={searchVal} />
                    </Grid>

                    {isLoading ?
                        <LoadingSpinner loading={isLoading} />
                        : sortedTasks?.length ? <div style={{ flex: 1 }}>
                            <AutoSizer>
                                {({ height, width }) => (
                                    <List
                                        className="List"
                                        height={height}
                                        itemCount={sortedTasks?.length}
                                        itemSize={180}
                                        width={width}
                                    >
                                        {Row}
                                    </List>
                                )}
                            </AutoSizer>

                        </div> :
                            <EmptyPlaceholder text="No Tasks" />}
                </Grid>
            </Drawer>
        </>
    )
}
export default TasksPanel;