import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import CustomInput from 'components/CustomInput';
import DropdownFilter from 'components/DropdownFilter';
import NotificationProvider from 'components/NotificationProvider';
import usePrompt from 'hooks/usePrompt';
import { useState } from 'react';
import { useGenerateOneTimeAccessCodeMutation, useGetLotsWithAccessQuery } from '../accessSlice';
import PhoneInput from 'components/PhoneInput';
import Validator from 'services/validator';
import AccessCodeList from '../AccessCodeList/AccessCodeList';
import AccessCodeView from '../AccessCodeList/AccessCodeView';
import { useGetLotsQuery } from 'features/lots/lotSlice';
import { IconAccessCode } from 'icons';
import { set } from 'lodash';
import AccessCodeLoader from '../GenerateAccessCode/AccessCodeLoader/AccessCodeLoader';

const GenerateOneTimeAccess = ({ }) => {

    let [generateCode, { isLoading }] = useGenerateOneTimeAccessCodeMutation();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsWithAccessQuery();
    let { data: allLots, error: allLotError, isLoading: isLoadingAllLots } = useGetLotsQuery();

    const { triggerPrompt } = usePrompt();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [lotId, setLotId] = useState();
    const [pocEmail, setPocEmail] = useState();
    const [pocPhone, setPocPhone] = useState();
    const [pocName, setPocName] = useState();
    const [generatedCode, setGeneratedCode] = useState();

    const onClose = () => {
        setGeneratedCode();
        setLotId();
        setPocEmail();
        setPocName();
        setPocPhone();
        setDialogOpen(false);
    }

    const valid = () => {
        return lotId &&
            pocPhone &&
            pocEmail &&
            pocName &&
            Validator.validateEmail(pocEmail) &&
            Validator.validatePhone(pocPhone)
    }

    const generate = async () => {
        let result = await generateCode({ lotId, pocEmail, pocPhone, pocName });
        if (result && !result.error) {
            NotificationProvider.success("Successfully generated one time access");
            setGeneratedCode({ ...result?.data });
        } else {
            NotificationProvider.error("Failed to generate one time access");
        }

    }
    return (
        <>
            <Authorize profile={permissionProfiles.ACCESS.MANAGE_ACCESS}>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<IconAccessCode width={20} />}
                    onClick={() => setDialogOpen(true)}
                >
                    Generate One Time Access Code
                </Button>
            </Authorize>
            <Dialog
                open={dialogOpen}
                onClose={onClose}
                fullWidth
                maxWidth='sm'>
                <DialogTitle>Generate One Time Access Code</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} direction='column'>
                        <Grid item>
                            <DropdownFilter
                                values={lots}
                                onSelect={(val) => setLotId(val)}
                                title='Select Lot'
                                showEmpty
                                required
                            />
                        </Grid>
                        <Grid item>
                            <CustomInput
                                label='Name'
                                elementType='input'
                                value={pocName}
                                touched={pocName}
                                onChange={(val) => setPocName(val)}
                                required
                            />
                        </Grid>
                        <Grid item>
                            <CustomInput
                                label='Email'
                                elementType='input'
                                value={pocEmail}
                                valid={Validator.validateEmail(pocEmail)}
                                touched={pocEmail}
                                onChange={(val) => setPocEmail(val)}
                                required
                            />
                        </Grid>
                        <Grid item>
                            <PhoneInput
                                label='Phone Number'
                                value={pocPhone}
                                valid={pocPhone && Validator.validatePhone(pocPhone)}
                                onChange={(val) => setPocPhone(val)}
                                required
                            />
                        </Grid>
                        {generatedCode && <Grid item>
                            <h3>Generated Access Code</h3>
                            <AccessCodeView accessCode={{ ...(allLots?.find(l => l.id == lotId) ?? {}), ...generatedCode, lotName: lots?.find(l => l.id == lotId)?.name, }} />
                        </Grid>}
                    </Grid>
                    <AccessCodeLoader show={isLoading} />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => onClose(false)}>
                        Cancel
                    </Button>
                    <Button color="primary"
                        variant="contained"
                        onClick={generate}
                        disabled={isLoading || !valid()}
                    >
                        {isLoading ? 'Generating...' : 'Generate'}
                    </Button>
                </DialogActions>
            </Dialog >
        </>
    );
}

export default GenerateOneTimeAccess;