import { IconButton } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import DataListHeader from "components/DataListHeader";
import DataTable from "components/DataTable";
import LoadingSpinner from "components/LoadingSpinner";
import PageComponent from "components/Page/PageComponent";
import usePrompt from "hooks/usePrompt";
import useSortOptions from "hooks/useSortOptions";
import { IconDownload } from 'icons';
import { groupBy, orderBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FilterService, { fieldTypes } from "services/filterService";
import FormatService from "services/formatService";
import { useGetLotsQuery } from "../../../../lots/lotSlice";
import { auditFileStatuses } from "../../auditConsts";
import { useDeleteAuditMutation, useEditAuditMutation, useGetAuditsByUserQuery } from "../../lotAuditSlice";
import AuditForm from "../AuditForm/AuditForm";
import AuditStatusDropdown from "../AuditStatusDropdown";
import { setAuditListFilters } from "./auditListFiltersSlice";
import { AuditListStyles } from "./AuditListStyle";
import { useGetAllRelationsQuery } from "features/users/usersSlice";
import { relationTypeEnumById } from "features/users/UsersEnums";
import { selectIsOwner } from "features/user/userSlice";

const AuditList = () => {
    const classes = AuditListStyles();

    const history = useHistory();

    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    lots = lots || [];

    let { data: allRelations, error: allRelationsError, isLoading: isLoadingAllRelations } = useGetAllRelationsQuery();
    allRelations = allRelations || [];

    let regionalManagers =  [... new Set(allRelations?.filter(relation => relation.relationType === relationTypeEnumById.REGIONAL_MANAGER))];
    const groupedToUserId = groupBy(regionalManagers, 'userId');
    const dropdownValues = Object.keys(groupedToUserId).map((key) => ({ id: key, name: groupedToUserId[key]?.[0].userName }));

    let { data: audits, error: auditsError, isLoading: isAuditsingLots } = useGetAuditsByUserQuery(null);
    audits = audits?.data || [];

    let [deleteAudit, { isLoading: deleting }] = useDeleteAuditMutation();

    let auditsData = audits?.map((a => {
        let audit = {
            ...a,
            lot: lots?.find(lot => lot.id == a.lotId)?.name,
            createdDate: FormatService.formatDate(a.createdDate),
            auditUrl: a.fileUrl,
            statusLabel: auditFileStatuses[a.status],
            regionalManager: regionalManagers?.filter(r => r.lotId === a.lotId)?.map(r => r.userId)
        }
        return audit;
    })).sort((a, b) => (new Date(a.createdDate) - new Date(b.createdDate)))

    const [sortOptions, sortData] = useSortOptions();

    let auditsColumns = [
        { name: "Lot", key: "lot" },
        { name: "Created Date", key: "createdDate" },
        { name: "Created By", key: "createdBy" },
        { name: "Status", key: "id", component: (id) => <AuditStatusDropdown audit={auditsData?.find(audit => audit.id == id)} /> },
    ];

    const run = async (id) => {
        history.push(`/assets/audit/${id}`);
    };

    const changeStatus = async (id,) => {

    };

    const downloadAuditFile = (id) => {
        let url = auditsData?.find(a => a.id == id)?.auditUrl;
        window.open(url, "_blank")
    }

    const { triggerPrompt } = usePrompt();

    const rowActions = (rowId) => ([
        {
            icon: <IconDownload />,
            onClick: () => downloadAuditFile(rowId),
        },

        {
            component: <Authorize profile={permissionProfiles.AUDIT.AUDIT_RUN}>
                <IconButton onClick={() => run(rowId)}>
                    <PlayCircleFilledWhiteOutlinedIcon />
                </IconButton>
            </Authorize>,
        },
        {
            component: <Authorize profile={permissionProfiles.AUDIT.AUDIT_DELETE}>
                <IconButton onClick={() => {
                    triggerPrompt({
                        title: "Delete Audit",
                        content: "Are you sure you want to delete the audit?",
                        loading: deleting,
                        onConfirm: async () => {
                            await deleteAudit(rowId);
                        },
                    });
                }}>
                    <DeleteIcon />
                </IconButton>
            </Authorize>,

        }
    ]);

    const isOwner = useSelector(selectIsOwner);
    const dispatch = useDispatch();
    const { filters } = useSelector(state => state.auditListFilters);

    const filterConfig = [
        { type: fieldTypes.ASSOCIATED_LOT, size: 2.5, title: 'Lots', key: 'lotId', values: lots },
        { type: fieldTypes.DATERANGE, size: 3, title: 'Date', key: 'createdDate', values: [] },
    ];

    if(isOwner) {
        filterConfig.push({ type: fieldTypes.VALUE_LIST, size: 3, title: 'Regional Manager', key: 'regionalManager', values: dropdownValues })
    }

    const onDropFilter = (filterValue, prop) => {
        dispatch(setAuditListFilters({ ...filters, ...{ [prop]: { value: filterValue } } }));
    };

    const isLoading = () => isLoadingLots;

    let filteredData = FilterService.filter(filters, null, auditsData, filterConfig);

    return (
        <>
            <PageComponent header={
                <DataListHeader
                    title={(
                        <>
                            <span>Audits</span>
                        </>
                    )}
                    data={filteredData}
                    dropDownsConfig={filterConfig}
                    filters={filters}
                    onDropFilter={onDropFilter}
                    actionButtons={(
                        <Authorize profile={permissionProfiles.AUDIT.AUDIT_UPLOAD}>
                            <AuditForm />
                        </Authorize>
                    )}
                />}>
                <>
                    {
                        isLoading() ? <LoadingSpinner loading={isLoading()} /> :
                            <DataTable
                                className={classes.table}
                                columns={auditsColumns}
                                rows={orderBy(
                                    filteredData,
                                    sortOptions.columnToSort || 'createdDate',
                                    'desc'
                                )}
                                rowIdentifier='id'
                                onSort={sortData}
                                sortDirection={sortOptions.sortDirection}
                                columnToSort={sortOptions.columnToSort}
                                noItemsMessage='There are no audits to display'
                                actions={rowActions}
                                maxActionCount={3}
                            />
                    }
                </>
            </PageComponent>
        </>
    )
}
export default AuditList;