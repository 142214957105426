import { Grid } from '@material-ui/core';
import { CreatableDropdown } from 'components/CreatableDropdown/CreatableDropdown';
import CustomInput from 'components/CustomInput';
import LoadingSpinner from 'components/LoadingSpinner';
import NotificationProvider from 'components/NotificationProvider';
import { setAccessToEdit } from 'features/access/accessStateSlice';
import { useCreateTransporterMutation, useGetTransportersQuery } from 'features/transporter/transporterSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const TransporterFields = ({ }) => {
    
    const { accessToEdit } = useSelector(state => state.accessState);
    const dispatch = useDispatch();

    const onChangeValue = (key, val) => {
        dispatch(setAccessToEdit({...accessToEdit, [key]: val}))
    }
    
    let { data: transporters, error: transportersError, isLoading } = useGetTransportersQuery();
    const [addTransporter, { isLoading: isAdding }] = useCreateTransporterMutation();

    const transportersLookup = transporters?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    useEffect(() => {
        let usdot = transportersLookup?.[accessToEdit?.transporterId]?.usdot
        if(accessToEdit?.transporterId && usdot) {
            setDisableUsdot(true);
        }
        dispatch(setAccessToEdit({...accessToEdit, ["usdot"]: usdot}))
    }, [])

    const [disableUsdot, setDisableUsdot] = useState(false);

    
    
    const onAddTransporter = async (val) => {
        setDisableUsdot(false);
        let result = await addTransporter(val);
        if (result && !result.error) {
            NotificationProvider.success("Transporter added successfully");
        } else {
            NotificationProvider.error("Failed to save the transporter");
        }
        // dispatch(setAccessToEdit({ ...accessToEdit, ["transporterName"]: val?.name, ["usdot"]: null, ["transporterId"]: null }));
        return result;
    }

    const onSelectTransporter = (val) => {
        //When creating a new transporter:
        // if (val == 0) {
        //     setDisableUsdot(false);
        //     onChangeValue("transporterId", val)
        // }
        //When selecting an existing transporter:
        // else {
            let transporterUsdot = transporters?.find(t => t.id === val)?.usdot;
            if(transporterUsdot) {
                setDisableUsdot(true);
            } else {
                setDisableUsdot(false);
            }
            dispatch(setAccessToEdit({ ...accessToEdit, 
                ["transporterId"]: val, 
                ["usdot"]: transporterUsdot, 
                // ["transporterName"]: null 
            }));
        // }
    }

    // useEffect(() => {
    //     if (accessToEdit?.transporterId) {
    //         let transporterUsdot = transporters?.find(t => t.id === accessToEdit?.transporterId)?.usdot;
    //         onChangeValue("usdot", transporterUsdot)
    //     }
    // }, [accessToEdit?.transporterId])


    return (
        <>
            <LoadingSpinner loading={isAdding} />
            <Grid container direction='row' spacing={2}>
                <Grid item xs={6}>
                    <CreatableDropdown
                        options={transporters}
                        value={accessToEdit?.transporterId}
                        required
                        addValue={onAddTransporter}
                        label='Transporter'
                        onChange={(val) => onSelectTransporter(val)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomInput
                        label='USDOT'
                        elementType='input'
                        value={accessToEdit?.usdot}
                        onChange={(val) => onChangeValue("usdot", val)}
                        disabled={disableUsdot}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default TransporterFields;