import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from "../../app/baseQuery";

export const vendorApi = createApi({
    reducerPath: 'vendorApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getVendors: builder.query({
            query: () => `vendor`,
        }),
    }),
});

export const { useGetVendorsQuery } = vendorApi;
