import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    button: {
        margin: "0 0.5rem",
        padding: '0.5rem',
        height: 54,
        width: 250,
        fontSize: 15
    },
    title: {
        fontSize: 24,
        fontWeight: 550,
        paddingBottom: "1em"
    },
    header: {
        paddingBottom: "1.5em"
    },
    pageContent: {
        padding: "3em"
    },
    contractsPage: {
        minHeight: "700px",
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: '100'
    },

}))