import { makeStyles } from "@material-ui/core"

const darkGreen = "#78a05c";
const darkRed = "#D44040";
const lightGreen = "#f3f9ec";
const lightRed = "#fff5f5";

export const assetResolveDuplicateStyle = makeStyles((theme) => ({
    assetWrapper: {
        padding: '1em',
        cursor: 'pointer',
        border: '2px solid transparent',
        transition: 'border-color 0.5s ease'
    },
    keepBorder: {
        border: `2px solid ${darkGreen}`
    },
    deleteBorder: {
        border: `2px solid ${darkRed}`
    },
    label: {
        fontWeight: 'bold'
    },
    checkIcon: {
        fill: darkGreen
    },
    closeIcon: {
        fill: darkRed
    },
    disabledIcon: {
        fill: theme.palette.grey[300]
    },    
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '1rem'
    },
    keepButton: {
        margin: '0.5rem',
        '&:hover': {
            background: lightGreen
        },
        color: darkGreen,
        borderColor: darkGreen
    },
    deleteButton: {
        margin: '0.5rem',
        '&:hover': {
            background: lightRed
        },
        color: darkRed,
        borderColor: darkRed
    },
    mergeAlert: {
        alignItems: 'center',
        "& .MuiAlert-icon": {
            fontSize: 25
        }
    }
}));