
function SvgIconDownload(props, width, height) {
    return (
        <svg width="20" height="20" fill="#0090fe" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.833 17.5c0 .46-.373.833-.833.833H5a.833.833 0 1 1 0-1.666h10c.46 0 .833.373.833.833zM10 15l-4.412-4.412a.831.831 0 0 1 1.175-1.177l2.404 2.397V2.5a.833.833 0 1 1 1.666 0v9.308l2.404-2.397a.831.831 0 0 1 1.175 1.177L10 15z" />
        </svg>
    );
}

export default SvgIconDownload;
