import { baseApi } from 'app/baseApi';
import { baseQuery } from 'app/baseQuery';
import { apiTags } from 'app/store/apiTags';

const basePath = 'mechanicals';

export const mechanicalsApi = baseApi.injectEndpoints({
    reducerPath: 'mechanicalsApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getMechanicalsConfig: builder.query({
            query: ({ clientId, lotId }) => `${basePath}?clientId=${clientId}&lotId=${lotId}`,
            providesTags: [apiTags.MECHANICALS_CONFIG]
        }),
        updateMechanicalRule: builder.mutation({
            query: body => ({
                url: `${basePath}/updateRule`,
                method: "POST",
                body
            }),
            invalidatesTags: [apiTags.MECHANICALS_CONFIG],
        }),
        getMechanicalRuleTypes: builder.query({
            query: () => `${basePath}/ruletypes`,
            providesTags: [apiTags.MECHANICAL_RULE_TYPES]
        }),
        getMechanicalRuleTypesByActivity: builder.query({
            query: (activityId) => `${basePath}/ruletypesByActivity/${activityId}`,
        }),
        getMechanicalActivities: builder.query({
            query: () => `${basePath}/activities`,
            providesTags: [apiTags.MECHANICAL_ACTIVITIES]
        }),
        addMechanicalRule: builder.mutation({
            query: ({ clientId, lotId, activityId, vehicleType, calcOnCheckin, ruleOperand, daysToComplete, preferredVendorId, ruleParams }) => ({
                url: `${basePath}`,
                method: "POST",
                body: {
                    clientId,
                    lotId,
                    activityId,
                    vehicleType,
                    calcOnCheckin,
                    ruleOperand,
                    daysToComplete,
                    preferredVendorId,
                    ruleParams
                }
            }),
            invalidatesTags: [apiTags.MECHANICALS_CONFIG]
        }),
        updateMechanicalsConfig: builder.mutation({
            query: ({ clientId, lotId, ...params }) => ({
                url: `${basePath}/config`,
                method: "PUT",
                body: {
                    clientId,
                    lotId,
                    ...params
                }
            }),
            invalidatesTags: [apiTags.MECHANICALS_CONFIG]
        }),
        deleteMechanicalsConfig: builder.mutation({
            query: ({ id }) => ({
                url: `${basePath}`,
                method: "DELETE",
                body: {
                    id
                }
            }),
            invalidatesTags: [apiTags.MECHANICALS_CONFIG]
        }),
    }),
});

export const {
    useGetMechanicalsConfigQuery,
    useUpdateMechanicalRuleMutation,
    useGetMechanicalRuleTypesQuery,
    useGetMechanicalRuleTypesByActivityQuery,
    useGetMechanicalActivitiesQuery,
    useUpdateMechanicalsConfigMutation,
    useAddMechanicalRuleMutation,
    useDeleteMechanicalsConfigMutation
} = mechanicalsApi;
