import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, ListItem, Paper, Switch } from '@material-ui/core';
import { groupBy, orderBy } from "lodash";
import moment from "moment";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Authorize from '../../../../../components/Authorize';
import { permissionProfiles } from '../../../../../components/Authorize/permissionProfiles';
import NotificationProvider from '../../../../../components/NotificationProvider';
import { StepDefinitionTypeById } from '../../enums';
import EditWorkFlowPopupStyle from './EditWorkFlowPopupStyle';
import { screenTypeAdditionalComponent } from './screenTypes';
import {
    useGetDefaultStepDefinitionQuery, 
    useGetStepDefinitionDetailsGroupQuery, 
    useGetStepDefinitionsByClientQuery, 
    useGetStepDefinitionsDetailsByIdQuery, 
    useUpdateStepDefinitionMutation,
} from './workFlowSlice';
import { useGetVehicleStatusQuery } from '../../../../../../src/features/vehicles/vehicleSlice';
import { useGetDefaultStatusByClientIdQuery, useUpdateDefaultStatusMutation } from '../../../../../../src/features/clients/clientConfigSlice';
import CustomInput from "../../../../../components/CustomInput";

const useStyles = EditWorkFlowPopupStyle;

const EditWorkFlowPopup = ({ contract, open, clientConfig, handleClose }) => {

    const classes = useStyles();
    const [stepDefinitionType, setStepDefinitionType] = useState(StepDefinitionTypeById.CHECKIN);
    const [updateDetails] = useUpdateStepDefinitionMutation();
    const [updateDefaultStatus] = useUpdateDefaultStatusMutation();
    const [isSaving, setIsSaving] = useState(false);
    const [steps, setSteps] = useState([]);
    let { data: statuses, error: statusError, isLoading: isLoadingStatuses } = useGetVehicleStatusQuery();
    statuses = statuses || [];
    const [defaultStatusId, setDefaultStatusId] = useState(null);

    //default steps
    let { data: defaultStepDefinitions, error: sdefaultStepDefinitionsError, isLoading: isLoadingDefaultStepDefinitions } = useGetDefaultStepDefinitionQuery();
    defaultStepDefinitions = defaultStepDefinitions || [];
    const defaultStepDefinition = defaultStepDefinitions?.data?.find(s => s.stepDefinitionTypeId == 1);

    let { data: defaultStepDefinitionDetails, error: defaultStepDefinitionDetailsError, isLoading: isLoadingDefaultStepDefinitionDetails } = useGetStepDefinitionsDetailsByIdQuery(defaultStepDefinition?.id, { skip: !defaultStepDefinition?.id });
    let defaultSteps = defaultStepDefinitionDetails?.data || [];

    let { data: defaultStepDefinitionDetailsGroups, error: defaultStepDefinitionDetailsGroupsError, isLoading: isLoadingdefaultStepDefinitionDetailsGroups } = useGetStepDefinitionDetailsGroupQuery();
    let stepsGroups = defaultStepDefinitionDetailsGroups?.data || [];

    //users steps
    let { data: stepDefinitions, error: stepDefinitionsError, isLoading: isLoadingStepDefinitions } = useGetStepDefinitionsByClientQuery(clientConfig?.id);
    let { data: statusId, error: statusIdError, isLoading: isStatusIdLoading, refetch } = useGetDefaultStatusByClientIdQuery( {clientId : clientConfig?.id} );


    stepDefinitions = stepDefinitions || [];
    const stepDefinition = stepDefinitions?.data?.filter(s => s.stepDefinitionTypeId == stepDefinitionType)[0];

    let { data: stepDefinitionDetails, error: stepDefinitionDetailsError, isLoading: isLoadingStepDefinitionDetails } = useGetStepDefinitionsDetailsByIdQuery(stepDefinition?.id, { skip: !stepDefinition?.id });
    let clientsSteps = stepDefinitionDetails?.data || [];

    let isLoading = isStatusIdLoading || isLoadingDefaultStepDefinitions || isLoadingDefaultStepDefinitionDetails || isLoadingdefaultStepDefinitionDetailsGroups || isLoadingStepDefinitions || isLoadingStepDefinitionDetails;

    useEffect(() => {
        if (!isLoading && defaultSteps?.length && stepsGroups?.length) {
            let clientsDefaultSteps = [];
            [...defaultSteps].forEach(step => {
                let defaulStep = { ...step };
                defaulStep.description = defaulStep.description ?? "";
                let clientStep = clientsSteps?.find(s => s.screenTypeId == defaulStep.screenTypeId);
                if (clientStep) {
                    defaulStep.id = clientStep?.id;
                    defaulStep.isActive = clientStep?.isActive;
                    defaulStep.required = clientStep?.required;
                    defaulStep.lastDateSync = clientStep?.lastDateSync;
                }
                else {
                    defaulStep.id = 0;
                    defaulStep.isActive = defaulStep.required ? 1 : 0;
                    defaulStep.stepDefinitionId = stepDefinition?.id;
                    defaulStep.lastDateSync = moment().format();
                }
                clientsDefaultSteps.push(defaulStep);
            });

            const stepsGrouped = groupBy([...clientsDefaultSteps]?.filter(s => s.groupId), s => s.groupId);
            const groupsWithSteps = [...stepsGroups]?.filter(g => stepsGrouped[g.id])?.map(g => ({
                ...g,
                steps: stepsGrouped[g.id],
                stepNumber: stepsGrouped[g.id]?.[0]?.stepNumber
            }));
            const stepsWithoutGroups = clientsDefaultSteps?.filter(s => !s.groupId);
            const orderedSteps = orderBy([...stepsWithoutGroups, ...groupsWithSteps], 'stepNumber');

            setSteps(orderedSteps);
        }
    }, [open])

    useEffect(() => {
        setDefaultStatusId(statusId);
    }, [statusId])

    const isStepActive = (step) => {
        if (step.isActive) {
            return step.isActive;
        }
        else {
            let activeSteps = step.steps?.find(s => s.isActive);
            return activeSteps ? true : false;
        }
    }

    const onClose = () => {
        setDefaultStatusId(statusId);
        handleClose();
    }

    const allStepsActive = (step) => {
        if (step.steps) {
            let activeSteps = step.steps?.find(s => s.isActive);
            let notActiveSteps = step.steps?.find(s => !s.isActive);
            return activeSteps && notActiveSteps ? false : true;
        }
        return true;
    }

    const isStepRequired = (step) => {
        if (step.required) {
            return step.required;
        }
        else {
            let requiredSteps = step.steps?.find(s => s.required);
            return requiredSteps ? true : false;
        }
    }

    const getStepsName = (steps) => {
        if (steps) {
            return steps.map(s => s.name)?.join(', ');
        }
    }

    const onChangeDefaultStatus = (p_statusId) => {
        setDefaultStatusId(p_statusId);
    }

    const onChangeStep = (e, index) => {
        let mySteps = steps?.map(s => ({ ...s }));
        let myStep = mySteps[index];

        if (myStep.isActive != undefined)
            myStep.isActive = e.target.checked ? 1 : 0;
        else {
            myStep.steps = [...myStep.steps]?.map((s) => {
                s.isActive = e.target.checked ? 1 : 0;
                return s;
            })
        }

        mySteps[index] = { ...myStep };
        setSteps(mySteps);
    }

    const getFlattenSteps = () => {
        let flatten = [...steps?.filter(s => s.steps).flatMap(s => s.steps), ...steps?.filter(s => !s.steps)];
        let orderedFlatten = orderBy(flatten, ['isActive', 'stepNumber'], ['desc', 'asc']);
        return orderedFlatten;
    }

    const { selectedVehicleTypes } = useSelector(state => state.mobileWorkflowState);

    const handleSave = async () => {
        setIsSaving(true);

        let stepDefinitionToSave = {
            id: stepDefinition?.id,
            clientId: clientConfig?.id,
            name: stepDefinition?.name,
            stepDefinitionTypeId: 1,
            stepDefinitionDetailsList: getFlattenSteps(),
            selectedVehicleTypes
        }
        let res = await updateDetails(stepDefinitionToSave);

        let clientStatusEntity = {
            clientId: clientConfig?.id,
            statusId: defaultStatusId
        }

        let responseUpdateStatus = await updateDefaultStatus(clientStatusEntity); 

        setIsSaving(false);
        handleClose();
        if (!res?.error) {
            refetch();
            NotificationProvider.success('Saved successfully');
        } else {
            NotificationProvider.error('Failed to save');
        }
    };


    return (
        <>
            {isLoading
                ? <CircularProgress className={classes.spinner} />
                : <Dialog
                    open={open}
                    onClose={onClose}
                    fullWidth
                    maxWidth='md'
                >
                    <DialogTitle>
                        <Grid container justifyContent='space-between'>
                            <div>{`${contract?.label} SLA`}</div>
                            {contract?.headerComponent}
                        </Grid>
                    </DialogTitle>

                    <DialogContent>
                        {isSaving && <CircularProgress className={classes.spinner} />}
                        <div className={classes.slaTitle}>{'Check In'}</div>
                        <div className={classes.dropdownStatuses}>
                            <Authorize profile={permissionProfiles.CLIENT_CONFIG.WORKFLOW_SLA_EDIT}>
                                <CustomInput
                                    id="status"
                                    label="Default Checking Status"
                                    value={defaultStatusId}
                                    elementType="dropdown"
                                    onChange={val => onChangeDefaultStatus(+val)}
                                    values={statuses}
                                    showEmpty={true}
                                />
                            </Authorize>
                        </div>
                        
                        {steps?.map((step, index) =>
                            <Paper className={`${classes.listItem} classes.container`}>
                                <Grid container direction="row" spacing={2} className={classes.lableWrapper}>

                                    <Grid key={index} item>
                                        <ListItem key={index} >
                                            <FormControlLabel
                                                control={
                                                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.WORKFLOW_SLA_EDIT}>
                                                        <Switch
                                                            color="primary"
                                                            checked={isStepActive(step)}
                                                            onChange={(e) => onChangeStep(e, index)}
                                                            disabled={isStepRequired(step)}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    </Authorize>
                                                }
                                                label={step.name}
                                            />
                                        </ListItem>

                                        <ListItem key={index} className={classes.description}>
                                            {step.description}
                                        </ListItem>

                                        <ListItem key={index} className={classes.groupNames}>
                                            {getStepsName(step.steps)}
                                        </ListItem>

                                    </Grid>

                                    <Grid item >
                                        {!allStepsActive(step) && <p className={classes.requiredLable}>Not all steps are active</p>}
                                        {isStepRequired(step) && <p className={classes.requiredLable}>Required</p>}
                                    </Grid>

                                </Grid>
                                {isStepActive(step) && screenTypeAdditionalComponent(clientConfig?.id)[step.screenTypeId]}
                            </Paper>
                        )}
                    </DialogContent>

                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.WORKFLOW_SLA_ACTIONS}>
                        <DialogActions>
                            <div className={`${classes.btnContainer}`}>
                                <div className={classes.btnWrapper}>
                                    <Button
                                        onClick={onClose}
                                        className={classes.button}
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                <div className={classes.btnWrapper}>
                                    <Button
                                        onClick={handleSave}
                                        disabled={isSaving}
                                        className={classes.button}
                                        variant="contained"
                                        color="primary"
                                    >
                                        {isSaving ? "Saving..." : "Save"}
                                    </Button>
                                </div>
                            </div>
                        </DialogActions>
                    </Authorize>

                </Dialog>
            }
        </>
    );
};

export default EditWorkFlowPopup;


