import { baseApi } from '../../app/baseApi';
import { baseQuery } from "../../app/baseQuery";
import { apiTags } from '../../app/store/apiTags';

const apiPrefix = 'activityTypeInventory';
export const activityTypeInventoryApi = baseApi.injectEndpoints({
    reducerPath: 'activityTypeInventoryApi',
    tagTypes: [apiTags.ACTIVITY_TYPE_INVENTORY],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAllInventoryByActivityTypeId: builder.query({
            query: (params) => `${apiPrefix}/getByActivityId?${Object.keys(params).filter(key => params[key]).map(key => `${key}=${params[key]}`).join('&')}`,
            providesTags: [apiTags.ACTIVITY_TYPE_INVENTORY],
        }),
        updateActivityTypeInventory: builder.mutation({
            query: inventories => ({
                url: `${apiPrefix}/saveInventoryUsedByActivity`,
                method: "POST",
                body: inventories
            }),
            invalidatesTags: [apiTags.ACTIVITY_TYPE_INVENTORY]
        }),
    }),
});

export const {
    useGetAllInventoryByActivityTypeIdQuery, useUpdateActivityTypeInventoryMutation
} = activityTypeInventoryApi;