
import { Button, DialogContent, Dialog, DialogTitle, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useCancelLoadMutation } from '../../loadsSlice';
import NotificationProvider from '../../../../components/NotificationProvider';
import { cancelLoadStyle } from './CancelLoadStyle';
import ConfirmationPopup from '../../../../components/ConfirmationPopup';
import CustomInput from '../../../../components/CustomInput';

function CancelLoad({ loadId }) {
    const classes = cancelLoadStyle()

    let [cancelLoad, { isLoading, isError, isSuccess }] = useCancelLoadMutation();

    const [open, setOpen] = useState(false);
    const [statusDate, setStatusDate] = React.useState(new Date());
    const [comment, setComment] = React.useState('');
    const [status, setStatus] = React.useState();
    const [showError, setShowError] = useState(false);

    const onClick = async () => {
        setOpen(true);
    }

    const onConfirm = async () => {
        const result = await cancelLoad({ loadId: loadId, comment });
        if (result?.error) {
            NotificationProvider.error(result?.error?.data?.[0] ?? 'Failed to cancel load')
        } else {
            NotificationProvider.success(result?.data?.[0] ?? `Successfully cancelled load`);
        }
        setOpen(false);
    }

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                onClick={onClick}
            >
                Cancel Load
            </Button>
            <ConfirmationPopup
                loading={isLoading}
                title={'Cancel Load'}

                open={open}
                onConfirm={onConfirm}
                onCancel={() => setOpen(false)}
            >
                Are you sure you want to cancel the load?
                <p>
                    <div className={classes.commentReminder}>{'Please specify a cancellation reason'}</div>
                    <CustomInput
                        id="comments"
                        label=""
                        value={comment}
                        elementType="textarea"
                        onChange={val => setComment(val)}
                    />
                </p>
            </ConfirmationPopup>
        </div>
    );
}

export default CancelLoad;