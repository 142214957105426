import React, { useState, useEffect } from 'react';
import CustomInput from '../CustomInput';
import { Button, Grid, Paper, FormControlLabel, Checkbox, List, Tooltip, ButtonGroup, Select } from "@material-ui/core";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import DateTimeScheduleStyle from './DateTimeScheduleStyle';
import moment from 'moment';
import DropdownFilter from "../../components/DropdownFilter"

const useStyles = DateTimeScheduleStyle;

export default function DateScheculeComponent (route) {
  const {data, daysOfWeekList, handleCallback, indexChild, removeHandleCallback, name, isTimeMessageAvailable} = route;
  const [jsonData, setJsonData] = useState(data);
  const classes = useStyles();

  const openDateChange = (event) => {
    let changeOpenTime = {...jsonData};
    changeOpenTime.openTime = getTimeFormated(event.selection);
    setJsonData(changeOpenTime);
    handleCallback(changeOpenTime, event.indexChild, event.name);
  };

  const closeDateChange = (event) => {
    let changeOpenTime = {...jsonData};
    changeOpenTime.closeTime = getTimeFormated(event.selection);
    setJsonData(changeOpenTime);
    handleCallback(changeOpenTime, event.indexChild, event.name);
  };

  const dropdownChange = (val, indexChild, name) => {
    let changeOpenTime = {...jsonData};
    changeOpenTime.dayOfWeekId = parseInt(val);
    setJsonData(changeOpenTime);
    handleCallback(changeOpenTime, indexChild, name);
  };

  const getTimeFormated = (time) => {
    let fullTime = moment(time, "H:m:ss");
    return fullTime.format("HH:mm");
  }

  const removeSchedule = (event) => {
    removeHandleCallback(event.indexChild, event.name);
  };

  return (
      <div>
          <div className={classes.divDesign}>
            <Grid xs={5} className={classes.dropDownLine}>
              <DropdownFilter
                title='Days'
                identifier='days'
                values={daysOfWeekList}
                onSelect={(event) => dropdownChange(event, indexChild, name)}
                value={jsonData?.dayOfWeekId || 1}
                showEmpty={true} />
            </Grid>
            <Grid xs={5}>
              <input className={classes.inputOpenTime} name='openDate' type='time' value={jsonData.openTime} onChange={(event) => {
                                          openDateChange({ selection: event.target.value, indexChild: indexChild, name: name });
                                      }}></input>
            </Grid>
            <Grid xs={5}>
              <input className={classes.inputOpenTime} name='closeDate' type='time' value={jsonData.closeTime} onChange={(event) => {
                                          closeDateChange({ selection: event.target.value, indexChild: indexChild, name: name });
                                      }}></input>
            </Grid>
            <ButtonGroup >
                <Button onClick={(event) => { removeSchedule({event, indexChild: indexChild, name: name})}}>
                    X
                </Button>
            </ButtonGroup>
        </div>
      </div>
  )
}
