import { apiTags } from 'app/store/apiTags';
import { baseQuery } from 'app/baseQuery';
import { baseApi } from 'app/baseApi';

const apiPrefix = 'activityTypePrice';
export const activityTypePriceApi = baseApi.injectEndpoints({
    reducerPath: 'activityTypePriceApi',
    tagTypes: [apiTags.ACTIVITY_TYPE_PRICE],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAllActivityPricing: builder.query({
            query: () => `${apiPrefix}`,
            providesTags: [apiTags.ACTIVITY_TYPE_PRICE],
        }),
        deletePrice: builder.mutation({
            query: ({ id }) => ({
                url: `${apiPrefix}`,
                method: "DELETE",
                body: { id }
            }),
            invalidatesTags: [apiTags.ACTIVITY_TYPE_PRICE]
        }),
        addActivityPrice: builder.mutation({
            query: (activityPrice) => ({
                url: `${apiPrefix}`,
                method: "POST",
                body: activityPrice
            }),
            invalidatesTags: [apiTags.ACTIVITY_TYPE_PRICE],
        }),
        updateActivityPrice: builder.mutation({
            query: (activityPrice) => ({
                url: `${apiPrefix}`,
                method: "PUT",
                body: activityPrice
            }),
            invalidatesTags: [apiTags.ACTIVITY_TYPE_PRICE]
        }),
        getActivityPricingByParameters: builder.query({
            query: (params) => {
                const queryString = Object.keys(params).map(param => params[param] ? `${param}=${params[param]}` : undefined).filter(k => k).join('&');
                return `${apiPrefix}/parameters?${queryString}`
            },
        }),
        getChargeForClient: builder.mutation({
            query: ({clientId, activityType, lotId, price, inventoryUsed}) => ({
                url: `${apiPrefix}/charge`,
                method: "POST",
                body: {clientId, activityType, lotId, price, inventoryUsed},
            })
        })
    }),
});

export const {
    useGetAllActivityPricingQuery,
    useDeletePriceMutation,
    useAddActivityPriceMutation,
    useUpdateActivityPriceMutation,   
    useGetActivityPricingByParametersQuery,
    useGetChargeForClientMutation,
} = activityTypePriceApi;