import { Button, Grid, IconButton, ListItem } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import UsersPageStyle from "../../UsersPageStyle";
import CustomInput from "components/CustomInput";
import { useState } from "react";
import { useGetUserPermissionClaimsQuery, useSaveUserPermissionClaimsMutation } from "features/users/usersSlice";
import { useEffect } from "react";
import { permissionDisplay } from "components/Authorize/permissions/PermissionList/permissionDisplay";
import DeleteIcon from '@material-ui/icons/Delete';
import NotificationProvider from "components/NotificationProvider";

const useStyles = UsersPageStyle;

const EditUserPermissions = ({ userId, onCancel }) => {
    const classes = useStyles();

    let { data: userPermissions, error: userPermissionsError, isLoading: isLoadingUserPermissions } = useGetUserPermissionClaimsQuery(userId, { skip: !userId })
    userPermissions = userPermissions || [];

    let permissionClaims = userPermissions?.data;
    
    let [updatePermissions] = useSaveUserPermissionClaimsMutation();

    const [saving, setSaving] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [permissions, setPermissions] = useState(permissionClaims);

    useEffect(() => {
        setPermissions(permissionClaims?.length ? permissionClaims : [{'id': null,  'claimType': 'permission', 'claimValue': null}]);
    }, [isLoadingUserPermissions, userId]);

    const getValues = (item) => {
        let dropdownValues = permissionDisplay?.filter(perm => !permissions?.find(p => p.claimValue == perm.key) || (perm.key == item.claimValue)).map(p => ({id: p.key, name: p.name, description: p.description ? p.description : null}));
        return dropdownValues 
    }

    const onChangePermission = (item, val) => {
        setHasChanged(true); 
        let permissionList = [...permissions];
        let newItem = {...item};
        newItem.claimType = "permission";
        newItem.claimValue = permissionDisplay?.find(p => p.key === val).key;
        permissionList[permissions.findIndex(i => i == item)] = { ...newItem };
        setPermissions(permissionList);
    }
    
    const onAddNewPermission = () => {
        setHasChanged(true);
        let permissionList = [...permissions];
        permissionList.push({'userId': userId, 'claimType': null, 'claimValue': null});
        setPermissions(permissionList);
    }

    const onDelete = (item) => {
        setHasChanged(true);
        let permissionList = [...permissions];
        permissionList = permissionList.filter(p => p != item);
        setPermissions(permissionList);
    }

    const handleSave = async () => {
        setSaving(true);

        let permissionsToSave = {
            userId: userId,
            permissionClaimEntities: permissions
        }

        let result = await updatePermissions(permissionsToSave);

        setSaving(false);

        if (!result?.error) {
            NotificationProvider.success('Saved successfully');
        } else {
            NotificationProvider.error('Failed to save');
        }

        setHasChanged(false);
    }

    const renderOption = (value) => {
        return <div className={classes.dropdown}>
            <div className={classes.name}>{value?.name}</div>
            <div className={classes.description}>{value?.description}</div> 
        </div>
    }

    return (
        <div>
            <div className={classes.label}>User Permissions</div>
            <div>
                { permissions?.map((item, index) => {
                        return <div key={item.id}>
                            <ListItem>
                                <Grid container spacing={12}>
                                    <Grid item xs = {12} className={classes.dropdown}>
                                        <CustomInput
                                            key={index}
                                            label="Permission"
                                            elementType="dropdown"
                                            values={getValues(item)}
                                            value={item.claimValue}
                                            onChange={val => onChangePermission(item, val)}
                                            showEmpty={true}
                                            renderOption={renderOption}
                                        />
                                    </Grid>
                                </Grid>
                                <IconButton edge="end" aria-label="delete"
                                    onClick={() => onDelete(item)}
                                >
                                    <DeleteIcon />
                                </IconButton> 
                            </ListItem>
                        </div>
                    }) }
            </div>
            <Grid container className={classes.buttonWrapper}>
                <Grid item >
                    <Button
                        className={classes.addButton}
                        variant="outlined"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={onAddNewPermission}
                    >
                        Add user permission
                    </Button>
                </Grid>
                { hasChanged ?
                    <Grid item>
                        <Button
                            className={classes.saveButton}
                            onClick={handleSave}
                            color="primary"
                            variant="contained"
                            disabled={saving}
                        >
                            {saving ? 'Saving...' : 'Save'}
                        </Button>
                    </Grid> : null
                }
            </Grid>
        </div>
    );
}
export default EditUserPermissions;