import { Button, Grid, Paper, Tooltip } from "@material-ui/core";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomInput from '../../../../components/CustomInput';
import DatePicker from '../../../../components/DatePicker';
import NotificationProvider from '../../../../components/NotificationProvider';
import Validator from '../../../../services/validator';
import { useGetClientsWithConfigsQuery } from '../../../clients/clientConfigSlice';
import { useGetLotsQuery } from '../../../lots/lotSlice';
import { selectIsAccountManager, selectIsAdmin, selectIsOwner } from '../../../user/userSlice';
import { useGetFuelTypesQuery, useGetVehicleStatusQuery, useGetVehicleTypesQuery } from '../../../vehicles/vehicleSlice';
import { moveTypeMessages, moveTypes, moveTypesById } from '../../enums';
import { useAddLoadMutation, useUpdateLoadMutation } from '../../loadsSlice';
import formStyles from "./formStyles";
import LocationDistance from './LocationDistance';
import LocationField from './LocationField';

const useStyles = formStyles;

const CreateLoadForm = ({ handleClose, loadToEdit }) => {

    let { data: vehicleStatuses, error: vehicleStatusError, isLoading: isLoadingVehicleStatus, isSuccess: isVehicleStatusSuccess } = useGetVehicleStatusQuery();
    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes, isSuccess: isVehicleTypesSuccess } = useGetVehicleTypesQuery();
    let { data: lots, error: lotError, isLoading: isLoadingLots, isSuccess: isLotsSuccess } = useGetLotsQuery();
    let { data: clientsWithConfigs, error: clientsWithConfigsError, isFetching: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery(null);
    let { data: fuelTypes, error: fuelTypesError, isLoading: isLoadingFuelTypes, isSuccess: isSuccessFuelTypes } = useGetFuelTypesQuery();

    vehicleTypes = vehicleTypes || [];
    vehicleTypes = vehicleTypes.filter(v => [7, 8, 16].includes(v.id));
    vehicleStatuses = vehicleStatuses || [];
    clientsWithConfigs = clientsWithConfigs || [];

    let [addLoad] = useAddLoadMutation();
    let [updateLoad] = useUpdateLoadMutation();

    const isAdmin = useSelector(selectIsAdmin);
    const isOwner = useSelector(selectIsOwner);
    const isAccountManager = useSelector(selectIsAccountManager);

    const classes = useStyles();
    const defaultDateMessage = "Please select move type";
    let [newLoad, setNewLoad] = useState({ ...loadToEdit?.assets[0], ...loadToEdit });
    let [isSaving, setIsSaving] = useState(false);
    const [originNewLot, setOriginNewLot] = useState(false);
    const [destinationNewLot, setDestinationNewLot] = useState(false);
    const [errors, setErrors] = useState({})
    const [pickupMinDate, setPickupMinDate] = useState(moment());
    const [deliveryMinDate, setDeliveryMinDate] = useState(moment());
    const [deliveryMaxDate, setDeliveryMaxDate] = useState();
    const [pickupDateDisabled, setPickupDateDisabled] = useState(true);
    const [deliveryDateDisabled, setDeliveryDateDisabled] = useState(true);
    const [showMandatoryError, setShowMandatoryError] = useState(false);
    const [puDateMessage, setPuDateMessage] = useState();
    const [dDateMessage, setDDateMessage] = useState("Please select move type");

    const isLoading = () => isLoadingVehicleStatus || isLoadingVehicleTypes || isLoadingClientsWithConfigs;

    const destinationFormulaCalc = () => Math.ceil(newLoad?.miles / 600);


    const reset = async () => {
        setNewLoad();
        setOriginNewLot(false);
        setDestinationNewLot(false);
        setErrors({});
        setPickupMinDate(moment());
        setDeliveryMinDate(moment());
        setDeliveryMaxDate();
        setPickupDateDisabled(true);
        setDeliveryDateDisabled(true);
        setPuDateMessage(defaultDateMessage);
        setDDateMessage(defaultDateMessage);
        setShowMandatoryError(false);
    }

    const onSubmit = async () => {
        const validate = isValid();
        if (!validate) {
            setShowMandatoryError(true);
            return;
        }
        setIsSaving(true);
        setErrors({});
        if (!loadToEdit) {
            let result = await addLoad(newLoad)
            if (result?.error) {
                NotificationProvider.error(result?.error?.data?.messages[0] ?? `Failed to create the load`)
            } else {
                NotificationProvider.success(`Successfully created the load`);
                reset();
                document.getElementById("createLoadForm").reset();
            }
            setIsSaving(false);
        }
        else {
            let result = await updateLoad(newLoad)
            if (result?.error) {
                NotificationProvider.error(`Failed to update the load`)
            } else {
                NotificationProvider.success(`Successfully updated the load`);

                setNewLoad(null)
                document.getElementById("createLoadForm").reset();
            }
            setIsSaving(false);
            handleClose();
        }


    };

    const getErrorMessagesForField = {
        'deliveryPocEmail': (val) => {
            if (val && destinationNewLot && !Validator.validateEmail(val)) {
                return "Invalid delivery POC email";
            }
            return false;
        },
        'pickupPocEmail': (val) => {
            if (val && originNewLot && !Validator.validateEmail(val)) {
                return "Invalid pickup POC email";
            }
            return false;
        },
        'deliveryPhone': (val) => {
            if (val && destinationNewLot && !Validator.validatePhone(val)) {
                return "Invalid delivery phone";
            }
            return false;
        },
        'pickupPhone': (val) => {
            if (val && originNewLot && !Validator.validatePhone(val)) {
                return "Invalid pickup phone";
            }
            return false;
        },
        'vin': (val) => {
            if (!Validator.validateVin(val)) {
                return "Invalid VIN";
            }
            return false;
        },
        'year': (val) => {
            if (!(val && val > 1980 && val.length <= 4)) {
                return "Invalid year";
            }
            return false;
        },
        'pickupStartDate': (val) => {
            const lot = lots?.find(l => l.id == newLoad?.pickupLocationId);
            if ((originNewLot || (lot && !lot.pmfLot)) && !Validator.validateWeekDaysOnly(val)) {
                return "The pickup lot does not accept pickup on Saturday or Sunday";
            }
            if (moment(val, "YYYY-MM-DD") < moment().startOf('day')) {
                return "Pickup date can not be in the past";
            }
            return false;
        },
        'deliveryStartDate': (val) => {
            const lot = lots?.find(l => l.id == newLoad?.deliveryLocationId);
            if ((destinationNewLot || (lot && !lot.pmfLot)) && !Validator.validateWeekDaysOnly(val)) {
                return "The delivery lot does not accept delivery on Saturday or Sunday";
            }
            return false;
        },
        /*'miles': (miles) => {
            if (!miles) {
                return "Miles can not be 0"
            }
            return false;
        }*/
    }

    const clearErrorForFields = (keys) => {
        const newErrors = { ...errors };
        keys.map(key => {
            delete newErrors[key];
        })
        setErrors(newErrors);
    };

    const onChange = (key, val) => {
        //console.log('change registered');
        const errorMessage = getErrorMessagesForField[key] && getErrorMessagesForField[key](val)
        if (errorMessage) {
            setErrors({ ...errors, [key]: errorMessage })
        }
        else {
            clearErrorForFields([key]);
        }
        setNewLoad({ ...newLoad, [key]: val });
    };

    const isValid = () => {
        const res = loadToEdit ?
            !!(
                newLoad.pickupStartDate && !errors['pickupStartDate'] &&
                newLoad.deliveryStartDate && !errors['deliveryStartDate']
            )
            : !!(newLoad &&
                (newLoad.clientId || (!isAdmin && !isOwner && !isAccountManager)) &&
                newLoad.vin && !errors['vin'] &&
                newLoad.assetId &&
                newLoad.make &&
                newLoad.model &&
                newLoad.year && !errors['year'] &&
                newLoad.assetTypeId &&
                newLoad.neededByWeek &&
                newLoad.handoffWeek &&
                newLoad.moveType &&
                newLoad.pickupStartDate && !errors['pickupStartDate'] &&
                newLoad.deliveryStartDate && !errors['deliveryStartDate'] &&
                ((newLoad.pickupLocationId && newLoad.pickupLocationId !== 0 && !originNewLot) ||
                    (originNewLot &&
                        newLoad.pickupName &&
                        newLoad.pickupNodeName &&
                        newLoad.pickupAddress &&
                        newLoad.pickupCity &&
                        newLoad.pickupState &&
                        newLoad.pickupZip &&
                        newLoad.pickupPocEmail &&
                        newLoad.pickupPhone)) &&
                ((newLoad.deliveryLocationId && newLoad.deliveryLocationId !== 0 && !destinationNewLot) ||
                    (destinationNewLot &&
                        newLoad.deliveryName &&
                        newLoad.deliveryNodeName &&
                        newLoad.deliveryAddress &&
                        newLoad.deliveryCity &&
                        newLoad.deliveryState &&
                        newLoad.deliveryZip &&
                        newLoad.deliveryPocEmail &&
                        newLoad.deliveryPhone)))
        return res;
    };



    const getWeekendDay = (date, goBack = false) => {
        let dateObj = moment(date)
        let day = dateObj.day();
        let days = 0;
        if (day == 0) {
            if (goBack) days = 2;//Sunday -> Friday
            if (!goBack) days = 1;//Sunday -> Monday
        }
        if (day == 6) {
            if (goBack) days = 1;//Saturday -> Friday
            if (!goBack) days = 2;//Saturday -> Monday
        }
        goBack && dateObj.add(-days, 'days');
        !goBack && dateObj.add(days, 'days');
        return dateObj;
    }

    const moveTypeHandler = {
        [moveTypesById.NETWORK_TRANSFER]: (val) => {
            let pickupDate = moment();
            pickupDate.add(3, 'days');
            if ((originNewLot || !lots?.find(l => l.id == newLoad.pickupLocationId)?.pmfLot)) {
                pickupDate = getWeekendDay(pickupDate);
            }

            setDeliveryMinDate(pickupDate);
            setDeliveryMaxDate(null)

            pickupDate = moment(pickupDate).format("YYYY-MM-DD")

            setPickupDateDisabled(true)
            setDeliveryDateDisabled(true) // disable until locations are selected

            setNewLoad({ ...newLoad, [`moveType`]: +val, [`pickupStartDate`]: pickupDate, [`deliveryStartDate`]: null })

            setPickupMinDate(moment())
            setPuDateMessage(moveTypeMessages[moveTypesById.NETWORK_TRANSFER].pickup());
            if (newLoad?.miles === 0) {
                setDDateMessage("Please select locations");
            } else {
                setDDateMessage(moveTypeMessages[moveTypesById.NETWORK_TRANSFER].delivery(destinationFormulaCalc()));
            }
        },
        [moveTypesById.AMAZON_TRANSFER]: (val) => {
            setDeliveryMaxDate(null)
            setPickupDateDisabled(false)
            setDeliveryDateDisabled(true) // disable until locations are selected

            setNewLoad({ ...newLoad, [`moveType`]: +val, [`pickupStartDate`]: null, [`deliveryStartDate`]: null })

            let puMinDate = moment();
            puMinDate.add(3, 'days');
            setPickupMinDate(puMinDate);

            setPuDateMessage(moveTypeMessages[moveTypesById.AMAZON_TRANSFER].pickup())
            if (newLoad?.miles !== 0 && newLoad?.pickupStartDate) {
                setDDateMessage(moveTypeMessages[moveTypesById.AMAZON_TRANSFER].pickup(destinationFormulaCalc()));
                setDeliveryDateDisabled(false)
            }
            else if (newLoad?.pickupStartDate) {
                setDDateMessage("Please select locations")
            }
            else {
                setDDateMessage("Please select pickup date")
            }
        },
        [moveTypesById.DIRECT_TO_AFP]: (val) => {
            setDeliveryMaxDate(null)
            setPickupMinDate(moment())
            setDeliveryMinDate(moment())
            setPickupDateDisabled(true)
            setDeliveryDateDisabled(false)
            setDDateMessage("select delivery date")

            setNewLoad({ ...newLoad, [`moveType`]: +val, [`pickupStartDate`]: null, [`deliveryStartDate`]: null })
            if (newLoad?.miles !== 0 && !newLoad?.deliveryStartDate) {
                setPuDateMessage("Please select delivery date")
            }
            else if (newLoad?.miles === 0) {
                setPuDateMessage("Please select locations")
            }
            else if (newLoad?.deliveryStartDate) {
                setPuDateMessage("Please select delivery date")
            }
        },
        [moveTypesById.EXPEDITED]: (val) => {
            setNewLoad({ ...newLoad, [`moveType`]: +val, [`pickupStartDate`]: null, [`deliveryStartDate`]: null })
            setDeliveryMaxDate(null);
            setPickupMinDate(moment());
            setDeliveryMinDate(moment());
            setPickupDateDisabled(false);
            setDeliveryDateDisabled(false);
            setPuDateMessage(moveTypeMessages[moveTypesById.EXPEDITED].pickup())
            setDDateMessage(moveTypeMessages[moveTypesById.EXPEDITED].delivery())
        }
    }

    const onMoveTypeChange = (val) => {
        if (moveTypeHandler[val]) {
            clearErrorForFields(['pickupStartDate', 'deliveryStartDate']);
            moveTypeHandler[val](val);
        }
        else {
            setNewLoad({ ...newLoad, [`moveType`]: +val, [`pickupStartDate`]: null, [`deliveryStartDate`]: null })
            setPickupDateDisabled(true)
            setDeliveryDateDisabled(true)
            setPuDateMessage("Please select move type")
            setDDateMessage("Please select move type")
        }
    }

    useEffect(() => {
        if (newLoad?.miles !== 0) {
            if (newLoad?.moveType == moveTypesById.NETWORK_TRANSFER) {
                setPickupDateDisabled(true)
                setDeliveryDateDisabled(false)
                if (newLoad?.pickupStartDate) {
                    const days = destinationFormulaCalc();
                    setDeliveryDateDisabled(false);
                    let dMinDate = moment(newLoad?.pickupStartDate, "YYYY-MM-DD");
                    dMinDate.add(days, "days");
                    setDeliveryMinDate(dMinDate)
                    setDDateMessage(moveTypeMessages[moveTypesById.NETWORK_TRANSFER].delivery(days));
                }
            }
            else if (newLoad?.moveType == moveTypesById.AMAZON_TRANSFER) {
                if (newLoad?.pickupStartDate) {
                    setDeliveryDateDisabled(false);
                    let dMinDate = moment(newLoad?.pickupStartDate, "YYYY-MM-DD");
                    dMinDate.add(destinationFormulaCalc(), "days");
                    setDeliveryMinDate(dMinDate)
                    if (moment(newLoad?.deliveryStartDate) < dMinDate) {
                        setNewLoad({ ...newLoad, [`deliveryStartDate`]: null })
                    }
                    setDDateMessage(moveTypeMessages[moveTypesById.AMAZON_TRANSFER].delivery(destinationFormulaCalc()));
                }
                else {
                    setDDateMessage(`Please select pickup date`)
                }
            }
            else if (newLoad?.moveType === moveTypesById.DIRECT_TO_AFP) {
                if (newLoad?.deliveryStartDate) {
                    let pickupStartDate = moment(newLoad?.deliveryStartDate, "YYYY-MM-DD");
                    const transitTime = destinationFormulaCalc();
                    pickupStartDate.subtract(transitTime, "days");
                    if ((originNewLot || !lots?.find(l => l.id == newLoad.pickupLocationId)?.pmfLot)) {
                        pickupStartDate = getWeekendDay(pickupStartDate, true);
                    }
                    pickupStartDate = moment(pickupStartDate).format("YYYY-MM-DD")
                    onChange('pickupStartDate', pickupStartDate);
                }
                else {
                    setPuDateMessage(`Please select delivery date`)
                }
            }
        }
        else {
            if (newLoad?.moveType === moveTypesById.AMAZON_TRANSFER) {
                if (!newLoad?.pickupStartDate) {
                    setDDateMessage(`Please select pickup date`)
                }
                else {
                    setDDateMessage(`Please select locations`)
                }
            }
        }

        if (newLoad?.moveType == moveTypesById.EXPEDITED) {
            if (newLoad?.pickupStartDate) {
                setDeliveryMinDate(moment(newLoad?.pickupStartDate, 'YYYY-MM-DD'))
            }
        }

    }, [newLoad?.miles, newLoad?.pickupStartDate, newLoad?.deliveryStartDate])

    const hasErrors = !!Object.values(errors)?.length;

    const filteredClients = clientsWithConfigs.filter(client => client.config.transportConfig.useTransport == true)

    let form = (
        <Grid direction='row' container >
            <Grid item xs={11}>
                {clientsWithConfigs?.length > 1 && !loadToEdit &&
                    <div className={classes.input}>
                        <CustomInput
                            id="clientId"
                            label="Client"
                            value={newLoad?.clientId}
                            elementType="dropdown"
                            onChange={val => onChange("clientId", +val)}
                            values={filteredClients}
                            showEmpty
                            required
                            touched
                            valid={!(showMandatoryError && !newLoad?.clientId)}
                            disabled={loadToEdit}
                        />
                    </div>}
                {!loadToEdit && <Grid container>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="vin"
                                label="VIN"
                                value={newLoad?.vin ?? ''}
                                elementType="input"
                                onChange={val => onChange('vin', val)}
                                required
                                valid={!errors['vin'] && !(showMandatoryError && !newLoad?.vin)}
                                touched
                                disabled={loadToEdit}

                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="assetId"
                                label="Asset ID"
                                value={newLoad?.assetId ?? ''}
                                elementType="input"
                                onChange={val => onChange("assetId", val)}
                                required
                                valid={!(showMandatoryError && !newLoad?.assetId)}
                                touched
                                disabled={loadToEdit}

                            />
                        </div>
                    </Grid>

                </Grid>}
                {!loadToEdit && <Grid container>
                    <Grid item xs={4}>
                        <div className={classes.input}>
                            <CustomInput
                                id="make"
                                label="Vehicle Make"
                                value={newLoad?.make ?? ''}
                                elementType="input"
                                onChange={val => onChange("make", val)}
                                required
                                disabled={loadToEdit}
                                valid={!(showMandatoryError && !newLoad?.make)}
                                touched
                            />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={classes.input}>
                            <CustomInput
                                id="model"
                                label="Vehicle Model"
                                value={newLoad?.model ?? ''}
                                elementType="input"
                                onChange={val => onChange("model", val)}
                                required
                                disabled={loadToEdit}
                                valid={!(showMandatoryError && !newLoad?.model)}
                                touched
                            />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={classes.input}>
                            <CustomInput
                                id="year"
                                label="Vehicle Year"
                                value={newLoad?.year ?? ''}
                                elementType="input"
                                onChange={val => onChange("year", val)}
                                required
                                valid={!errors['year'] && !(showMandatoryError && !newLoad?.year)}
                                touched
                                disabled={loadToEdit}
                                numberOnly

                            />
                        </div>
                    </Grid>
                </Grid>}
                {!loadToEdit && <Grid container>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="assetTypeId"
                                label="Vehicle Type"
                                value={newLoad?.assetTypeId}
                                elementType="dropdown"
                                onChange={val => onChange("assetTypeId", +val)}
                                values={vehicleTypes}
                                showEmpty
                                required
                                valid={!(showMandatoryError && !newLoad?.assetTypeId)}
                                touched
                                disabled={loadToEdit}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="vehicleFuelTypeId"
                                label="Vehicle Fuel Type"
                                value={newLoad?.vehicleFuelTypeId}
                                elementType="dropdown"
                                onChange={val => onChange("vehicleFuelTypeId", +val)}
                                values={fuelTypes}
                                showEmpty
                                required
                                valid={!(showMandatoryError && !newLoad?.vehicleFuelTypeId)}
                                touched
                            />
                        </div>
                    </Grid>
                </Grid>}


                <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.input}>
                            <CustomInput
                                id="moveType"
                                label="Move Type"
                                value={newLoad?.moveType}
                                elementType="dropdown"
                                onChange={val => onMoveTypeChange(val)}
                                values={moveTypes}
                                showEmpty
                                required
                                valid={!(showMandatoryError && !newLoad?.moveType)}
                                touched
                            />
                        </div>
                    </Grid>
                </Grid>

                <LocationField
                    name="pickup"
                    title="Origin"
                    onChange={onChange}
                    newLoad={newLoad}
                    setNewLoad={setNewLoad}
                    errors={errors}
                    loadToEdit={loadToEdit}
                    newLot={originNewLot}
                    setNewLot={(val) => {
                        if (!val) {
                            clearErrorForFields(['pickupPocEmail', 'pickupPhone']);
                        }
                        setOriginNewLot(val);
                    }}
                    showMandatoryError={showMandatoryError}
                />
                <LocationField
                    name="delivery"
                    title="Destination"
                    onChange={onChange}
                    newLoad={newLoad}
                    setNewLoad={setNewLoad}
                    errors={errors}
                    loadToEdit={loadToEdit}
                    newLot={destinationNewLot}
                    setNewLot={(val) => {
                        if (!val) {
                            clearErrorForFields(['deliveryPocEmail', 'deliveryPhone']);
                        }
                        setDestinationNewLot(val);
                    }}
                    showMandatoryError={showMandatoryError}
                />

                <LocationDistance onChange={onChange} newLoad={newLoad} originNewLot={originNewLot} destinationNewLot={destinationNewLot} showMandatoryError={showMandatoryError} />

                <Grid container>
                    <Grid item xs={6}>
                        <div className={classes.dateInput}>
                            <DatePicker
                                title="Earliest Pick Up Date"
                                value={(newLoad?.pickupStartDate) ? moment(newLoad?.pickupStartDate).format("YYYY-MM-DD") : null}
                                onSelect={val => onChange("pickupStartDate", val)}
                                minDate={moment(pickupMinDate).format("YYYY-MM-DD")}
                                required
                                disabled={pickupDateDisabled}
                                valid={!errors['pickupStartDate'] && !(showMandatoryError && !newLoad?.pickupStartDate)}
                                touched
                            />
                            <Tooltip title={puDateMessage} arrow className={classes.tooltipIcon}>
                                <HelpOutlineIcon />
                            </Tooltip>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.dateInput}>
                            <DatePicker
                                title="Latest Delivery Date"
                                value={(newLoad?.deliveryStartDate) ? moment(newLoad?.deliveryStartDate).format("YYYY-MM-DD") : null}
                                onSelect={val => onChange("deliveryStartDate", val)}
                                minDate={moment(deliveryMinDate).format("YYYY-MM-DD")}
                                maxDate={deliveryMaxDate ? moment(deliveryMaxDate).format("YYYY-MM-DD") : null}
                                required
                                disabled={deliveryDateDisabled}
                                valid={!errors['deliveryStartDate'] && !(showMandatoryError && !newLoad?.deliveryStartDate)}
                                touched
                            />
                            <Tooltip title={dDateMessage} arrow className={classes.tooltipIcon}>
                                <HelpOutlineIcon />
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>

                {!loadToEdit && <Grid container>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="neededByWeek"
                                label="Need by week"
                                value={newLoad?.neededByWeek}
                                onChange={val => onChange("neededByWeek", +val)}
                                elementType="input"
                                required
                                numberOnly
                                valid={!(showMandatoryError && !newLoad?.neededByWeek)}
                                touched
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="handoffWeek"
                                label="Handoff week"
                                value={newLoad?.handoffWeek}
                                onChange={val => onChange("handoffWeek", +val)}
                                elementType="input"
                                required
                                numberOnly
                                valid={!(showMandatoryError && !newLoad?.handoffWeek)}
                                touched
                            />
                        </div>
                    </Grid>
                </Grid>}

                <div className={classes.input}>
                    <CustomInput
                        id="comments"
                        label="Comments"
                        value={newLoad?.comments}
                        elementType="textarea"
                        onChange={val => onChange("comments", val)}
                    />
                </div>
            </Grid>
            {
                hasErrors && <Grid item xs={2}>
                    <Paper className={classes.errorWrapper}>
                        <h4>Please correct the following errors</h4>
                        {Object.values(errors).map(error => <><Alert icon={false} severity="error">{error}</Alert><br /></>)}
                    </Paper>
                </Grid>
            }
        </Grid >
    );


    return (
        <Paper className={classes.formContainer}>
            {!loadToEdit && <h2 className={classes.title}>Create Load</h2>}
            <form id="createLoadForm" className={`${classes.form} ${isLoading() && classes.disabledForm}`}>
                {form}
            </form>
            <div className={`${classes.btnContainer}`}>
                <div>
                    {loadToEdit && <Button className={classes.button} variant="outlined" onClick={() => handleClose()}>Cancel</Button>}
                    <Tooltip title={Object.values(errors)?.length ? `${Object.values(errors)?.length} error(s) in the form` : ''}>
                        <div>
                            <Button
                                onClick={onSubmit}
                                disabled={isSaving}
                                className={classes.button}
                                variant="contained"

                            >
                                {isSaving ? "Saving..." : "Finish"}
                            </Button>
                        </div>
                    </Tooltip>
                </div>
            </div>
        </Paper>
    );
};

export default CreateLoadForm;
