import { Collapse, Paper } from "@material-ui/core"
import NoteList from "features/notes/NoteList"
import { vehicleDetailsPageStyle } from "./VehicleDetailsPageStyle";
import { NoteEntityTypes } from "features/notes/notesConsts";
import { useState } from "react";

export const VehicleNotes = ({ vehicleDetails }) => {
    const classes = vehicleDetailsPageStyle();

    return (
        <Paper className={classes.card}>
            <NoteList entityId={vehicleDetails?.id} entityType={NoteEntityTypes.VEHICLE} />
        </Paper>
    )
}