import {Button, Grid, Paper} from "@material-ui/core";
import {mechanicalWorkTaskStyle} from "./mechanicalWorkTaskStyle";
import InventoryUsed from "./components/Inventory/InventoryUsed";
import StepsToComplete from "./components/StepsToComplete/StepsToComplete";
import React, {useEffect, useState} from "react";
import TaskStatusLabel from "../mechanicalTasks/components/taskItem/taskStatusLabel";
import {useGetAllActivitiesQuery} from "../activity/activitySlice";
import NotificationProvider from "components/NotificationProvider";
import {useGetByActivityLogIdQuery, useSaveMechanicalWorkMutation} from "./mechanicalWorkSlice";
import {useParams} from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import {NoteEntityTypes} from "../notes/notesConsts";
import NoteList from "../notes/NoteList";
import CustomInput from "components/CustomInput";
import {permissions} from "components/Authorize/permissions/permissions";
import {hasPermission} from "components/Authorize/authorizeUtil";
import {activityStatuses} from "features/activity/activityConsts";
import {selectIsAccountManager, selectIsOwner} from "../user/userSlice";
import {useSelector} from "react-redux";
import {IconPhoto} from "../../icons";
import {VehicleDocuments} from "../vehicles/VehicleDetailsPage/VehicleDocuments/VehicleDocuments";
import BasicModal from "../../components/BasicModal";
import ParentEditAssetModal from "../vehicles/ParentEditAssetModal";
import CardHeader from "./cardHeader";
import { useUploadDocumentMutation} from "../vehicles/vehicleSlice";
import DropZoneInDialog from "../../components/DropZoneInDialog";

const MechanicalWorkTask = () => {
    const params = useParams();
    const isOwner = useSelector(selectIsOwner);
    const isAccountManager = useSelector(selectIsAccountManager);

    const classes = mechanicalWorkTaskStyle();

    const [existingImages, setExistingImages] = useState();
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [newImages, setNewImages] = useState([]);
    const [noteToEdit, setNoteToEdit] = useState("");
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [assetToEdit, setAssetToEdit] = useState(null);
    const [openPhotos, setOpenPhotos] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    
    const [updateActivityLog] = useSaveMechanicalWorkMutation();

    let [uploaddocument, { isLoading: uploadingDoc, isError, isSuccess }] = useUploadDocumentMutation();
    let { data: activityResult, error: vehicleDetailsError, isLoading: isVehicleDetailsLoading, refetch: refecthLog } = useGetByActivityLogIdQuery(params.id);
    let {data: activities} = useGetAllActivitiesQuery();

    const [activityStatusesReadOnly, setActivityStatusesReadOnly] = useState([
        activityStatuses.ItemCompleted,
        activityStatuses.PendingApproval,
        activityStatuses.PendingInvoice,
        activityStatuses.Cancelled
    ]);

    useEffect(() => {
        if (!existingImages) {
            setExistingImages(activityResult?.data?.images);
        }
        if (activityResult?.data?.activityId) {
            if (activityStatusesReadOnly.includes(activityResult?.data?.activityId)) {
                setIsReadOnly(true);
            }
            setSelectedStatus(activityResult?.data?.activityId);
        }

        if (isOwner || isAccountManager)
            setIsReadOnly(false);

    }, [activityResult?.data])
    
    const onEdit = (openPhotos) => {
        setOpenPhotos(openPhotos);
        setAssetToEdit(activityResult?.data.vehicle);
    };
        
    const onSaveAction = async () => {
        setIsSaving(true);
        
        const result = await updateActivityLog({
            id: activityResult?.data?.id,
            statusId: selectedStatus ?? activityResult?.data?.activityId
        });

        if (result.error) {
            NotificationProvider.error("Failed to update the activity");
        } else {
            NotificationProvider.success("Successfully updated the activity");
        }

        setIsSaving(false);
    };

    const uploadDocument = async (file) => {
        const d = new Date();
        let fileBase64 = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            fileBase64 = reader.result.toString().replace(/^data:(.*,)?/, '');
            const res = uploaddocument({
                "id": 0,
                "vehicleId": activityResult?.data?.vehicleId,
                "fileUrl": "",
                "filename": file.name,
                "file_type": file.type,
                "createdAt": d.toISOString(),
                "is_Deleted": 0,
                "filedata": fileBase64
            })
        };
    };

    const saveActivity = (vehicleDetailsId, date) => {
        setAssetToEdit(null);
        setOpenPhotos(false);
    };

    if (isVehicleDetailsLoading) {
        return <LoadingSpinner loading={isVehicleDetailsLoading}/>
    }
    
    return (
        <>
            <Paper className={`${classes.card}`} elevation={1}>

                <CardHeader title='Mechanical Work' icon={<img src="/icons/icon-wheel.svg" className={classes.icon}
                                                               alt="Asset ID"/>} actions={
                    <Grid>
                        <Button onClick={onSaveAction} color='primary' variant="contained" disabled={isSaving || isReadOnly}>
                            {
                                isSaving ? "Saving..." : "Process Changes"
                            }
                        </Button>
                    </Grid>
                }/>


                <div style={{paddingLeft: 5}}>
                    <b className={classes.detailLabel}>{activityResult?.data?.activityName}</b>
                    <br/><br/>
                    <span>
                        {activityResult?.data?.activityDescription}
                    </span>
                </div>
                <hr/>
                <br/>

                <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}
                      className={classes.details}>
                    <Grid item className={classes.detail}>
                        <Grid container>
                            <div className={classes.detailLabel}>Asset ID :</div>
                            <div>{activityResult?.data?.assetId}</div>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.detail}>
                        <Grid container>
                            <div className={classes.detailLabel}>VIN :</div>
                            <div>{activityResult?.data?.vin}</div>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}
                      className={classes.details}>
                    <Grid item className={classes.detail}>
                        <Grid container>
                            <div className={classes.detailLabel}>Client :</div>
                            <div>{activityResult?.data?.clientName}</div>
                        </Grid>
                    </Grid>

                    <Grid item className={classes.detail}>
                        <Grid container>
                            <div className={classes.detailLabel}>Lot :</div>
                            <div>{activityResult?.data?.lotName}</div>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}
                      className={classes.details}>
                    <Grid item className={classes.detail}>
                        <Grid container>
                            <div className={classes.detailLabel}>Status :</div>
                            <CustomInput
                                id="cost"
                                label="Status"
                                value={selectedStatus}
                                elementType="dropdown"
                                showEmpty={true}
                                required={false}
                                onChange={val => setSelectedStatus(val)}
                                values={hasPermission(permissions.ACTIVITIES.MANAGE_PENDING_INVOICE.key) ? activities : activities.filter(a => a.id !== activityStatuses.ItemCompleted)}
                            />

                        </Grid>
                    </Grid>
                </Grid>
            </Paper>

            {/*Steps to complete*/}
            <Paper className={`${classes.card}`} elevation={1}>

                <CardHeader title='Steps to complete'/>

                <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}
                      className={classes.details}>
                    <StepsToComplete activity={{id: activityResult?.data?.id}} isReadyOnly={isReadOnly}/>
                </Grid>

            </Paper>

            {/*Inventory used*/}
            <Paper className={`${classes.card}`} elevation={1}>
                <CardHeader title='Inventory used'/>
                <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}
                      className={classes.details}>
                    <InventoryUsed activity={{id: activityResult?.data?.id, lotId: activityResult?.data?.lotId}} isReadyOnly={isReadOnly}/>
                </Grid>
            </Paper>

            {/*Notes*/}
            <Paper className={`${classes.card}`} elevation={1}>
                <NoteList entityId={activityResult?.data?.id} entityType={NoteEntityTypes.MECHANICAL_WORK}/>
            </Paper>

            {/*Photos & Documents*/}
            <Paper className={`${classes.card}`} elevation={1}>
                <CardHeader icon={<IconPhoto/>} title="Photos & Documents"
                            actions={
                                <Grid item>
                                    <Grid container spacing={1} justifyContent="flex-end" alignItems='center'>
                                        <Grid item>
                                            <Button className={classes.editButton}
                                                    color="primary"
                                                    variant="outlined"
                                                    onClick={() => onEdit(true)}>
                                                Edit
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <DropZoneInDialog
                                                labelDialog={'Upload Document'}
                                                buttonLabel={'Upload Document'}
                                                onSave={uploadDocument}
                                                formContent={
                                                    <div className={classes.clientWrapper}>
                                                    </div>
                                                }
                                                accept={{
                                                    'application/pdf': ['.pdf'],
                                                    'image/jpg': ['.jpg'],
                                                    'image/png': ['.png'],
                                                    'image/jpeg': ['.jpeg']
                                                }}
                                                actionButtonLabel="Upload"
                                            />
                                            <LoadingSpinner loading={uploadingDoc}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                />

                <div className={classes.sideBySide}>
                    <VehicleDocuments vehicleDetails={activityResult?.data} latestLoad={undefined} onEdit={onEdit}/>
                </div>

            </Paper>

            <BasicModal
                open={assetToEdit}
                header={`Editing ${assetToEdit?.vin}`}
                wide={true}
                handleClose={() => setAssetToEdit(null)}
                component={<ParentEditAssetModal openPhotos={openPhotos}
                                                 asset={assetToEdit}
                                                 handleClose={() => setAssetToEdit(null)}
                                                 updateAsset={saveActivity}/>}
            />
        </>
    )
}

export default MechanicalWorkTask;


