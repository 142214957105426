export const MechActivityStatusEnum = {
    REQUESTED_BY_CLIENT: 1,
    COMPLETED: 4,
    PENDING_APPROVAL: 5,
    CANCELLED: 7,
    PENDING_INVOICE: 6
};

export const StatusEnumDescription = [
    { id: 1, name: "Requested" },
    { id: 4, name: "Completed" },
    { id: 5, name: "Pending Approval" },
    { id: 6, name: "Pending Invoice" },
    { id: 7, name: "Cancelled" },

];