import MobileDataTable from "../../components/MobileDataTable";
import { orderBy } from "lodash";
import DataTable from "../../components/DataTable";
import React, { useState, useEffect } from "react";
import { Button, Checkbox, CircularProgress, FormControlLabel, makeStyles, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import mobileBreakpoint from "../../utils/mobileBreakpoint";
import BasicModal from "../../components/BasicModal";
import EditAssetPopup from "./EditAssetPopup";
import ParentEditAssetModal from "./ParentEditAssetModal";
import SelectFieldsPopup from "./selectFieldsPopup";
import DataListHeader from "../../components/DataListHeader";
import { IconAdd } from "../../icons";
import ExportButton from "../../components/ExportButton";
import { useDispatch, useSelector } from "react-redux";
import { setFilters, setSearchVal } from "./vehicleFiltersSlice";
import { useHistory } from "react-router-dom";
import FilterService, { fieldTypes } from "../../services/filterService";
import ConfirmationPopup from "../../components/ConfirmationPopup";
import importInspectionActivity from "./components/ImportInspectionActivity"
import ImportInspectionActivity from "./components/ImportInspectionActivity";
import Authorize from "../../components/Authorize";
import { permissionProfiles } from "../../components/Authorize/permissionProfiles";
import {selectIsAccountManager, selectUserPermissions} from "../user/userSlice";
import PageComponent from "../../components/Page/PageComponent";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailsButton from "./DetailsButton";
import { useGetSubMarketsQuery } from "../lots/components/LotSubMarketsPage/lotSubMarketsSlice";
import ImportAssets from './ImportAssets/ImportAssets';
import {modes} from "../../components/Authorize/modes";

export const useStyles = makeStyles((theme) => ({
    table: {
        flex: 1
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: 'calc(50% + 100px)',
        zIndex: '100'
    },
    icon: {
        fill: theme.palette.common.white,
    },
    actionButton: {
        marginRight: 16,
    },
    actionButtonWrapper: {
        padding: "15px 10px"
    },
    redLabel: {
        color: "red"
    }
}));

const VehicleTable = ({ vehiclesData, columns, lots, vehicleTypes, clients, statuses, isAdmin, isOwner, isLoading,
    assetToEdit, setAssetToEdit, assetToAdd, setAssetToAdd, updateAsset, addAsset, includeHistory, setIncludeHistory, onAdd, onEdit,
    sortOptions, sortVehicles, viewVehicle, mapExportData, selectedColumns, setSelectedColumns, onClickDelete, isDeleting }) => {

    let { data: subMarkets, error: smError, isLoading: isLoadingSm } = useGetSubMarketsQuery();
    const history = useHistory();
    const classes = useStyles();
    const isMobile = useMediaQuery(mobileBreakpoint);
    const dispatch = useDispatch();
    const { searchVal, filters } = useSelector(state => state.vehicleFilters);
    const [openSelectFieldsPopup, setOpenSelectFieldsPopup] = useState(false);
    const [error, setError] = useState(null);
    const [assetToDelete, setAssetToDelete] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);

    const isAccountManager = useSelector(selectIsAccountManager);
    const permissions = useSelector(selectUserPermissions);

    const filterConfig = [
        { type: fieldTypes.VALUE_LIST, size: 2, title: 'Submarket', key: 'subMarketIds', values: subMarkets },
        { type: fieldTypes.LOT, size: 2, title: 'Current Location', key: 'lotId', values: lots },
        { type: fieldTypes.LOT, size: 2, title: 'Domicile Lot', key: 'domicileLot', values: lots },
        { type: fieldTypes.DATERANGE, size: 3, title: 'Last In', key: 'formattedDateIn', values: [] },
        { type: fieldTypes.DATERANGE, size: 2.5, title: 'Last Out', key: 'formattedDateOut', values: [] },
        {
            type: fieldTypes.TEXT,
            size: 2,
            title: 'Vehicle Type',

            key: 'vehicleTypeId',
            values: vehicleTypes
        },
        { type: fieldTypes.TEXT, size: 2, title: 'Status', key: 'vehicleStatusId', values: statuses },

    ];

    if (isAdmin) {
        filterConfig.unshift({
            type: fieldTypes.TEXT,
            size: 2,
            title: 'Client',
            key: 'clientId',
            values: clients.map(c => ({ id: c.id, name: c.name }))
        });
    }

    const updateFilters = (prop, value) => {
        if (prop == 'domicileLot') {
            if (value) {
                setIncludeHistory(true);
            }
        }
        dispatch(setFilters({ ...filters, [prop]: { value } }));
    }

    const searchVehicles = value => {
        dispatch(setSearchVal(value.trim()));
    }

    const onDropFilter = (filterValue, prop) => {
        updateFilters(prop, filterValue);
    };

    const clickFileUpload = () => {
        history.push("/assets/fileUpload");
    };

    let filteredVehicles = FilterService.filter(filters, searchVal, vehiclesData, filterConfig);

    const isChecked = (checked, columnName) => {
        return [...checked].filter(column => column ? column.name == columnName : false).length > 0;
    }

    const onSubmitSelectedFields = (checked) => {
        setSelectedColumns(
            columns.map((column) => (isChecked(checked, column.name) ? column : null))
                .filter(column => column)
        );
        setOpenSelectFieldsPopup(false);
    }

    useEffect(() => {
        let filterField = history?.location?.state?.params?.filters;
        if (filterField) {
            for (let i in filterField) {
                updateFilters(i, filterField[i]);
            }
        }
    }, [])

    const confirmDelete = () => {
        setAssetToDelete(null);
        onClickDelete(assetToDelete);
    };

    const showDeletePopup = assetId => {
        let vehicle = vehiclesData.find(v => v.id === assetId);
        setAssetToDelete(vehicle)
    }

    const rowActions = (rowId) => ([...[
        {
            label: 'Details',
            component: <DetailsButton id={rowId} />
        },

    // ], ...((isOwner || isAccountManager || permissionProfiles.ASSETS.ASSET_EDIT[modes.EDIT_PERMISSION]?.some(perm => permissions.includes(perm.key))) ? [{
    ], ...((isOwner || isAccountManager) ? [{
        icon: <EditIcon />,
        onClick: () => onEdit(rowId)
    }, {
        icon: <DeleteIcon />,
        onClick: () => showDeletePopup(rowId)
    }] : [])]);

    const filteredColumns = columns.filter(c => selectedColumns.find(s => s.key === c.key));

    return (
        <>
            <BasicModal
                open={assetToEdit}
                header={`Editing ${assetToEdit?.descriptor}`}
                wide={true}
                handleClose={() => setAssetToEdit(null)}
                component={<ParentEditAssetModal asset={assetToEdit} handleClose={() => setAssetToEdit(null)}
                    updateAsset={updateAsset} />}
            />
            <BasicModal
                open={assetToAdd}
                header={`Adding Asset`}
                wide={true}
                handleClose={() => setAssetToAdd(null)}
                component={<EditAssetPopup asset={assetToAdd} handleClose={() => setAssetToAdd(null)}
                    updateAsset={addAsset} />}
            />
            <BasicModal
                open={openSelectFieldsPopup}
                header={`Select Fields`}
                handleClose={() => setOpenSelectFieldsPopup(false)}
                component={<SelectFieldsPopup columns={columns.filter(x => x.hideMode == undefined || x.hideMode == false)} selectedColumns={selectedColumns} isChecked={isChecked} onSubmit={onSubmitSelectedFields} handleClose={() => setOpenSelectFieldsPopup(false)} />}
            />
            <ConfirmationPopup
                title={'Delete Asset'}
                onCancel={() => setAssetToDelete(null)}
                onConfirm={confirmDelete}
                open={!!assetToDelete}
                loading={isDeleting}
            >
                <div>{`Are you sure you want to delete Asset: ${assetToDelete?.descriptor}?`}</div>
            </ConfirmationPopup>
            <PageComponent
                header={
                    <DataListHeader
                        title={`Assets (${filteredVehicles.length})`}
                        data={vehiclesData}
                        dropDownsConfig={filterConfig}
                        filters={filters}
                        onDropFilter={onDropFilter}
                        onSearch={searchVehicles}
                        searchVal={searchVal}
                        actionButtons={(
                            <>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<Checkbox checked={includeHistory}
                                                onChange={(e, value) => setIncludeHistory(value)} />}
                                            label="Include History" />
                                    </Grid>
                                    <Authorize profile={permissionProfiles.ASSETS.ASSET_EDIT}>
                                        <Grid item>
                                            <Button variant="contained" color="primary" className={classes.addAssetButton}
                                                startIcon={<IconAdd />} onClick={onAdd}>Add Asset</Button>
                                        </Grid>
                                    </Authorize>
                                    <Authorize profile={permissionProfiles.ASSETS.ASSET_IMPORT}>
                                        <Grid item>
                                            <ImportAssets clients={clients} reloadAssets={updateAsset} />
                                        </Grid>
                                    </Authorize>
                                    <Grid item>
                                        <ExportButton fileLabel="inventory" data={filteredVehicles}
                                            mapExportData={mapExportData} />
                                    </Grid>

                                    {/* <Authorize profile={permissionProfiles.ASSETS.ASSET_IMPORT_INSPECTION}>
                                        <Grid item>
                                            <ImportInspectionActivity />
                                        </Grid>
                                    </Authorize> */}
                                    <Grid item>
                                        <Authorize profile={permissionProfiles.ASSETS.ASSET_AUDIT}>
                                            <Button variant="contained" color="primary" className={classes.addAssetButton}
                                                onClick={() => history.push("/assets/audit")}>Audit</Button>
                                        </Authorize>
                                    </Grid>

                                </Grid>

                            </>
                        )
                        }
                        selectFeildsButton={< div className={classes.actionButtonWrapper} > <Button variant="contained" color="primary" className={classes.actionButton} onClick={() => setOpenSelectFieldsPopup(true)}>Select Fields</Button></div >}
                    />}
            >
                {

                    isLoading
                        ? <CircularProgress className={classes.spinner} />
                        :
                        <div className={classes.table}>
                            {
                                isMobile ?
                                    <MobileDataTable
                                        columns={filteredColumns}
                                        rows={orderBy(
                                            filteredVehicles,
                                            sortOptions.columnToSort,
                                            sortOptions.sortDirection
                                        )}
                                        rowIdentifier='id'
                                        onShow={viewVehicle}
                                        onSort={sortVehicles}
                                        sortDirection={sortOptions.sortDirection}
                                        columnToSort={sortOptions.columnToSort}
                                        noItemsMessage='There are no vehicles matching this criteria'
                                    />
                                    :

                                    <DataTable
                                        columns={filteredColumns}
                                        rows={orderBy(
                                            filteredVehicles,
                                            sortOptions.columnToSort,
                                            sortOptions.sortDirection
                                        )}
                                        rowIdentifier='id'
                                        onSort={sortVehicles}
                                        sortDirection={sortOptions.sortDirection}
                                        columnToSort={sortOptions.columnToSort}
                                        noItemsMessage='There are no vehicles matching this criteria'
                                        actions={rowActions}
                                        maxActionCount={3}
                                    />
                            }
                        </div>
                } </PageComponent>
        </>

    );
};
export default VehicleTable;
