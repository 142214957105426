import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { Route, useLocation } from "react-router-dom";
import { isObjectEmpty } from '../../utils/object-util';
import userManager from "../../app/userManager";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { useSessionstorageState } from "rooks";
import { selectIsLoggingOut } from "../user/userSlice";
import Authorize from 'components/Authorize';
import UnauthorizedRoute from './UnauthorizedRoute/UnauthorizedRoute';

const useStyles = makeStyles((theme) => ({
    table: {
        marginTop: 20
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: "calc(50% - 20px)",
        zIndex: '100'
    }
}));

const PrivateRoute = ({ component: Component, profile, ...rest }) => {
    const classes = useStyles();
    const location = useLocation();
    const user = useSelector(state => state.oidc.user);
    const isLoggingOut = useSelector(selectIsLoggingOut);
    const isLoadingUser = useSelector(state => state.oidc.isLoadingUser);
    const [redirectPath, setRedirectPath, removeRedirectPath] = useSessionstorageState("redirectPath");


    useEffect(() => {
        if ((user === null || isObjectEmpty(user)) && !isLoggingOut) {
            // console.log('red location', location.pathname);
            setRedirectPath(location.pathname + location.search);

            userManager.signinRedirect()
                .then(user => console.log('signIn', user));
        }
    });

    return (
        <Authorize profile={profile} unauthorizedComponent={<UnauthorizedRoute />}>
            <Route
                {...rest}
                render={props => user ? <Component {...props} /> : <CircularProgress className={classes.spinner} />}
            />
        </Authorize>
    );
};

export default PrivateRoute;
