import { Dialog, DialogActions, DialogContent, Divider, Grid, Link, Tooltip } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import moment from "moment";
import React, { useEffect, useState } from "react";
import CustomInput from "../../../../../components/CustomInput";
import DatePicker from "../../../../../components/DatePicker";
import NotificationProvider from "../../../../../components/NotificationProvider";
import FormatService from "../../../../../services/formatService";
import { useGetInvoiceAccountsQuery, useGetInvoiceItemsQuery, useSaveInvoiceMutation } from "../../loadInvoiceSlice";
import InvoiceStatusDropdown from "../InvoiceStatusDropdown";
import { AddService } from "./AddService";
import { EditWrapper } from "./EditWrapper";
import { invoiceDetailsStyle } from "./InvoiceDetailsStyle";
import { Service } from "./Service";

const InvoiceDetails = ({ invoice, onClose }) => {
    let { data: invoiceItems, error, isFetching } = useGetInvoiceItemsQuery({});
    let { data: invoiceAccounts, error: accountsError, isFetching: accountsFetching } = useGetInvoiceAccountsQuery({});    
    const classes = invoiceDetailsStyle();

    const invoiceFields = [
        {
            name: "QB Bill Number",
            key: "invoiceNumber",
            editable: true,
            editComponent: () => <CustomInput
                label='Invoice Number'
                value={invoiceToEdit?.invoiceNumber}
                onChange={(val) => onFieldChange('invoiceNumber', val)}
                elementType="input"
            />
        },
        {
            name: "QB Invoice Number",
            key: "quickbooksInvoiceDocumentNumber",
        },
        {
            name: "Invoice Date",
            key: "issueDate",
            editable: true,
            component: (date) => <>{FormatService.formatDate(date)}</>,
            editComponent: () => <DatePicker
                title="Date"
                value={moment(invoiceToEdit?.issueDate).format("YYYY-MM-DD")}
                onSelect={(val) => onFieldChange('issueDate', val)} />
        },
        {
            name: "Vendor",
            key: "quickBooksVendorId",
            editable: true,
            component: (id) => <>{invoiceToEdit?.vendors?.find(t => t.id === id)?.displayName ?? '-'}</>,
            editComponent: () => <CustomInput
                value={invoiceToEdit?.quickBooksVendorId}
                onChange={(val) => onFieldChange('quickBooksVendorId', val)}
                elementType="dropdown"
                required={true}
                values={invoiceToEdit?.vendors?.map(i => ({...i, name: i.displayName}))}
            />
        },
        {
            name: "Customer",
            key: "quickBooksCustomerId",
            editable: true,
            component: (id) => <> {invoiceToEdit?.customers?.find(t => t.id === id)?.fullyQualifiedName ?? '-'}</>,
            editComponent: () => <CustomInput
                value={invoiceToEdit?.quickBooksCustomerId}
                onChange={(val) => onFieldChange('quickBooksCustomerId', val)}
                elementType="dropdown"
                required={true}
                values={invoiceToEdit?.customers?.map(i => ({...i, name: i.fullyQualifiedName}))}
            />
        },
        {
            name: "Departure",
            key: "pickupLotName"
        },
        {
            name: "Arrival",
            key: "deliveryLotName"
        },
        {
            name: "Distance",
            key: "miles"
        },
    ];

    const allFields = [
        {
            fields: invoiceFields,
        },
    ]

    const [invoiceToEdit, setInvoiceToEdit] = useState();

    useEffect(() => {
        console.log(invoice);
        setInvoiceToEdit({ ...invoice })
    }, [invoice])


    let [saveInvoice, { isLoading: isSaving, isSuccess: saveSuccess }] = useSaveInvoiceMutation();

    const onInvoiceSave = async () => {
        const fields = invoiceFields.filter(f => f.editable)?.map(f => f.key);
        const invoiceToSave = {
            id: invoiceToEdit.id,

        }
        fields.map(f => {
            invoiceToSave[f] = invoiceToEdit[f];
        })
        const result = await saveInvoice(invoiceToSave);
        if (result?.error) {
            NotificationProvider.error(`Failed to update invoice`)
        } else {
            NotificationProvider.success(`Successfully updated invoice`);
        }

    }

    const onFieldChange = async (fieldName, value) => {
        setInvoiceToEdit({
            ...invoiceToEdit,
            [fieldName]: value
        })
    }

    return (
        <Dialog maxWidth='md' fullWidth open={!!invoice} onClose={() => {
            setInvoiceToEdit();
            onClose();
        }} aria-labelledby='dialog-title'>
            <DialogContent>
                <Grid container direction="column" spacing={2} alignItems='flex-end'>
                    <Link componenet={GetAppIcon}
                        href={invoiceToEdit?.invoiceDocumentUrl} target="_blank" color="primary" >
                        <Tooltip title='View PDF'><OpenInNewIcon /></Tooltip>
                    </Link>
                </Grid>
                <Grid container direction="column" spacing={2}>
                    {allFields?.map((group, groupIndex) => (
                        <Grid item className={classes.groupWrapper} key={groupIndex} >
                            <Grid container spacing={2} direction="column" >
                                {group.label && <Grid item className={classes.groupLabel} >{group.label}</Grid>}
                                {group.fields?.map((field, fieldIndex) =>
                                    <Grid item key={fieldIndex}>
                                        <Field invoice={invoiceToEdit} field={field} onSave={onInvoiceSave} />
                                    </Grid>)
                                } </Grid>
                        </Grid>
                    )
                    )}
                    <Grid item className={classes.services} >
                        <Services invoiceToEdit={invoiceToEdit} setInvoiceToEdit={setInvoiceToEdit} />
                    </Grid>
                    <Grid item className={classes.totals}>
                        <Grid container direction='column' spacing='5'>
                            <Grid item>
                                <Grid container alignContent='space-between' className={classes.totalSummary}>
                                    <Grid item xs={5} className={classes.label}>Total:</Grid>
                                    <Grid item xs={6} >${invoiceToEdit?.amount}</Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <InvoiceStatusDropdown invoice={invoiceToEdit} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    )
}
export default InvoiceDetails;

const Field = ({ invoice, field, onSave }) => {
    const classes = invoiceDetailsStyle();

    return (
        <Grid container >
            <Grid item xs={5} className={classes.label}>{field.name}</Grid>
            <Grid item xs={6} >
                <EditWrapper
                    editComponent={!invoice?.readonly && field.editComponent && field.editComponent()}
                    readonlyComponent={(field.component ? field.component(invoice?.[field.key]) : invoice?.[field.key] ?? '-')}
                    onSave={onSave}
                />
            </Grid>

        </Grid>
    )
}

const Services = ({ invoiceToEdit, setInvoiceToEdit }) => {
    const classes = invoiceDetailsStyle();

    return (
        <Grid container direction='column' spacing='2'>
            <Grid  ><Divider /></Grid>
            <Grid item className={classes.groupLabel} >Services</Grid>

            <Grid item xs={11} container className={classes.headers}>
                    <Grid item xs={3}>Description</Grid>
                    <Grid item xs={3}>Product/Service (invoice)</Grid>
                    <Grid item xs={3}>Category (bill)</Grid>
                    <Grid item xs={3} >Amount</Grid>
                    {/* <Grid item xs={2} >Pass Through</Grid> */}
            </Grid>

            {invoiceToEdit?.services?.map((service, serviceIndex) =>
                <Grid item key={serviceIndex}>
                    <Service service={service} invoice={invoiceToEdit} onDelete={(serviceId) => {
                        setInvoiceToEdit({
                            ...invoiceToEdit,
                            services: [
                                ...(invoiceToEdit.services?.filter(s => s.id !== serviceId) ?? []),
                            ]
                        })
                    }} />
                </Grid>)}
            {!invoiceToEdit?.readonly && <Grid item>
                <AddService invoice={invoiceToEdit} onAdd={(newService) => {
                    if (newService) {
                        setInvoiceToEdit({
                            ...invoiceToEdit,
                            services: [
                                ...invoiceToEdit.services,
                                newService
                            ]
                        })
                    }
                }} />
            </Grid>}
            <Grid  ><Divider /></Grid>
        </Grid>
    )
}