import { Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import DataListHeader from "components/DataListHeader";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import ExportButton from "components/ExportButton";
import PageComponent from "components/Page/PageComponent";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import useSortOptions from "hooks/useSortOptions";
import { orderBy } from 'lodash';
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterService from "services/filterService";
import { InvoiceTypes } from '../../../invoiceConsts.ts';
import InvoiceScreenSelector from '../../../shared/InvoiceScreenSelector';
import { setSearchVal, useGetStorageInvoicesQuery } from '../../storageInvoiceSlice.ts';
import StorageInvoice from '../StorageInvoice/StorageInvoice';
import StorageInvoiceHeader from '../StorageInvoiceHeader/StorageInvoiceHeader';
import StorageInvoiceSummary from '../StorageInvoiceSummary/StorageInvoiceSummary';
import BasicModal from "../../../../../../src/components/BasicModal";
import SelectFieldsPopup from "../../../../../../src/features/vehicles/selectFieldsPopup";
import { useGetInvoiceReportsMutation, useUpdateStorageInvoiceMutation } from "../../../../../../src/features/lots/lotSlice";
import { useGetSubMarketsQuery } from "../../../../../../src/features/lots/components/LotSubMarketsPage/lotSubMarketsSlice";
import { storageDetailsColumns } from '../../storageInvoiceConsts.js';

const StorageInvoicePage = ({ loadId }) => {

    const dispatch = useDispatch();
    const { filters, searchVal } = useSelector(state => state.storageInvoiceState);

    let { data: invoices, error, isFetching } = useGetStorageInvoicesQuery({
        clientId: filters?.['clientId'],
        month: filters?.['month'],
        year: filters?.['year']
    }, { skip: !filters?.['clientId'] || !filters?.['month'] });

    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: submarkets, error: smError, isLoading: isLoadingSm } = useGetSubMarketsQuery();
    const [getInvoiceReports, { isLoading: removingLot }] = useGetInvoiceReportsMutation()
    const [updateStorageInvoice] = useUpdateStorageInvoiceMutation()
    let [isDownloadingReports, setIsDownloadingReports] = useState(false);
    let [isRegeneratingData, setIsRegeneratingData] = useState(false);


    // const invoiceData = invoices?.flatMap(invoice => {
    //     return invoice?.storageInvoiceDetails?.map(det => {
    //         const lot = lots?.find(l => l.id === det?.lotId);
    //         return ({
    //             ...det,
    //             lot: lot,
    //             lotInfo: [lot?.name, lot?.addressLine1, lot?.city, lot?.state, lot?.zipCode]?.join(', '),
    //             ...invoice
    //         })
    //     })
    // })

    const lastInvoice = orderBy(invoices ?? [], ['year'], ['desc'])?.[0] ?? {};
    const invoiceData = {
        ...lastInvoice,
        details: lastInvoice?.storageInvoiceDetails?.map(det => {
            let resultInfo;
            let result = lots?.find(l => l.id === det?.lotId);
            
            if(typeof(result) === "undefined"){
                result = submarkets?.find(l => l.id === det?.subMarketId);
                resultInfo = [result?.name];
            }
            else
            {
                resultInfo = [result?.name, result?.addressLine1, result?.city, result?.state, result?.zipCode]?.join(', ')
            }

            return ({
                ...det,
                lot: result,
                lotInfo: resultInfo,
            })
        })
    }

    const [showComplete, setShowComplete] = useState(false);

    //let filteredResults = FilterService.filter({}, searchVal, invoiceData, []);

    const searchVehicles = value => {
        dispatch(setSearchVal(value.trim()));
    }
    let reportColumns = [
        { name: "Invoice Report", key: "1", selectedByDefault: true, width: 280 },
        { name: "Lot/Day Matrix PMF Days", key: "2", selectedByDefault: true, width: 200 },
        { name: "VIN Report PMF Days", key: "3", selectedByDefault: true },
        { name: "Lot/Day Matrix Day Count", key: "4", selectedByDefault: true, width: 200 },
        { name: "VIN Report Day Count", key: "5", selectedByDefault: true, width: 200 }

    ];
    const [selectedColumns, setSelectedColumns] = useState([...reportColumns].filter(c => c.selectedByDefault));

    const [openSelectFieldsPopup, setOpenSelectFieldsPopup] = useState(false);

    const isChecked = (checked, columnName) => {
        return [...checked].filter(column => column ? column.name == columnName : false).length > 0;
    }

    const onSubmitSelectedFields = async (checked) => {
        setSelectedColumns(
            reportColumns.map((column) => (isChecked(checked, column.name) ? column : null))
                .filter(column => column)
        );

        let reportsToSend = [];
        reportColumns.map((column) => (isChecked(checked, column.name) ? column : null)).forEach((id) => {
            if(id !== null)
            {
                reportsToSend.push(parseInt(id.key));
            }
        })

        let reportsToSendObj = {
            ReportTypes: reportsToSend,
            ClientId: parseInt(filters?.['clientId']),
            Month: parseInt(filters?.['month']),
            Year: new Date().getFullYear()
        }

        setOpenSelectFieldsPopup(false);

        setIsDownloadingReports(true);
        await getInvoiceReports(reportsToSendObj);
        setIsDownloadingReports(false);
    }


    const onSubmitRegenerateData = async (checked) => {
        let option = {
            ClientId: parseInt(filters?.['clientId']),
            Month: parseInt(filters?.['month']),
            Year: new Date().getFullYear()
        }
        setIsRegeneratingData(true);
        await updateStorageInvoice(option);
        setIsRegeneratingData(false);
    }

    const onSelectExistingClick = () => {
        setOpenSelectFieldsPopup(true);
        setIsDownloadingReports(true);
    }

    const onCloseModal = () => {
        setOpenSelectFieldsPopup(false);
        setIsDownloadingReports(false);
    }

    const mapExportData = row => {
        const detailsInfo = {};
        storageDetailsColumns?.map(col => {
            detailsInfo[col.key] = row?.[col.key]
        });
        const invoiceData = {
            ['Lot']: row.lotInfo,
            ['Base Days PMF']: row.daysPMF,
            ['Overage Days PMF']: row.overageDaysPMF,
            ['Lot Fee']: row.lotFee,
            ...detailsInfo
        };

        return invoiceData;
    };

    const [sortOptions, sortData] = useSortOptions();

    const needsFiltering = !filters?.['clientId'] || !filters?.['month'];
    return (
        <PageComponent
            loading={isFetching}
            header={
                <DataListHeader
                    titleSize={6}
                    titleComponent={(
                        <InvoiceScreenSelector
                            selected={InvoiceTypes.STORAGE}
                        />
                    )}
                    //data={filteredResults}
                    // onSearch={}
                    actionButtons={<Grid container spacing={1}>
                        <Grid item>
                            <FormControlLabel
                                control={<Checkbox checked={showComplete}
                                    onChange={(e, value) => setShowComplete(value)} />}
                                label="Include History" />
                        </Grid>
                        <Grid item>
                            {/* <ExportButton fileLabel="Export" data={invoiceData?.details} mapExportData={mapExportData} /> */}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onSelectExistingClick}
                                disabled={isDownloadingReports}>
                                Export
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onSubmitRegenerateData}
                                disabled={isRegeneratingData}>
                                Re-generate Storage Invoice
                            </Button>
                            
                        </Grid>
                    </Grid>}
                />}>
            <StorageInvoiceHeader />
            <BasicModal
                open={openSelectFieldsPopup}
                header={`Select Reports`}
                handleClose={() => onCloseModal(false)}
                component={<SelectFieldsPopup columns={reportColumns} 
                                            selectedColumns={selectedColumns} 
                                            isChecked={isChecked} 
                                            onSubmit={onSubmitSelectedFields} 
                                            handleClose={() => onCloseModal(false)} />}
            />
            {needsFiltering ?
                <EmptyPlaceholder text={'Please select client and month'} /> :
                !!invoiceData ?
                    <>

                        <Grid direction='column' spacing={1}>
                            {invoiceData?.details?.map(invoice => <Grid item ><StorageInvoice key={invoice.id} invoice={invoice} /></Grid>)}
                        </Grid>
                        <StorageInvoiceSummary invoice={invoiceData} />
                    </> :
                    <EmptyPlaceholder text={'No Invoices'} />}
        </PageComponent>
    )
}
export default StorageInvoicePage;