import { Button, Checkbox, Grid, List, Paper } from "@material-ui/core";
import { orderBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import NotificationProvider from "../../../../../components/NotificationProvider";
import FilterService from "../../../../../services/filterService";
import { useGetLotsQuery } from "../../../../lots/lotSlice";
import { BulkEditLot } from "./BulkEditLot";
import { Lot } from "./Lot";
import { resetBulkUpdate, resetSelected, setSelectedLots, setSelectedSubmarkets, useUpdateClientLotMutation } from "../lotConfigSlice";
import LotsConfigStyle from "../LotsConfigStyle";
import { useGetSubMarketsQuery } from "../../../../lots/components/LotSubMarketsPage/lotSubMarketsSlice";

const useStyles = LotsConfigStyle

export const ActiveLotsList = ({
    activeClientLots,
    activeClientSubmarkets,
    onConfigChange,
    search,
    onRemove
}) => {
    const classes = useStyles();

    let [updateClientLot, { isLoading: updatingLot }] = useUpdateClientLotMutation()
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: submarkets, error: smError, isLoading: isLoadingSm } = useGetSubMarketsQuery();

    const { bulkEdit, selectedLots, selectedSubmarkets } = useSelector(state => state.lotConfigState);
    const dispatch = useDispatch();


    const saveMultipleLots = async (info) => {
        const { pricePerSpot, overage, numberOfSpots, ...other } = info;

        const clientLots = selectedLots?.map(s => {
            const lot = (activeLots?.find(a => a.id == s));
            return {
                ...lot,
                pricePerSpot: pricePerSpot == null ? lot.pricePerSpot : pricePerSpot,
                overage: overage == null ? lot.overage : overage,
                numberOfSpots: numberOfSpots == null ? lot.numberOfSpots : numberOfSpots
            }
        })

        const clientSubmarkets = selectedSubmarkets?.map(s => {
            const lot = (activeSubMarkets?.find(a => a.id == s));
            return {
                ...lot,
                pricePerSpot: pricePerSpot == null ? lot.pricePerSpot : pricePerSpot,
                overage: overage == null ? lot.overage : overage,
                numberOfSpots: numberOfSpots == null ? lot.numberOfSpots : numberOfSpots
            }
        })
        const res = await updateClientLot({ clientLots, clientSubmarkets });
        if (res.error) {
            NotificationProvider.error('Failed to update lots');
        } else {
            dispatch(resetBulkUpdate());
            NotificationProvider.success('Successfully updated lots');
        }
    }

    const lotMap = lots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {})

    const subMarketMap = submarkets?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {})

    let activeLots = activeClientLots?.filter(l => l?.isNew || lotMap?.[l?.lotId]).map(lot => ({
        ...lot,
        name: lotMap?.[lot?.lotId]?.name,
    })) ?? [];

    let activeSubMarkets = activeClientSubmarkets?.map(submarket => {
        const subMarket = subMarketMap?.[submarket?.subMarketId];
        return ({
            name: subMarket?.name,
            lotIds: subMarket?.lotIds,
            lots: activeLots?.filter(l => subMarket?.lotIds?.includes(l.lotId)),
            isSubMarket: true,
            lotsForSearch: subMarket?.lotIds?.map(l => lotMap?.[l]?.name).join('$$'),
            ...submarket
        })
    }) ?? [];

    const activeLotsInSubMarket = activeSubMarkets?.flatMap(s => s.lotIds).reduce(function (map, obj) {
        map[obj] = obj;
        return map;
    }, {});

    let activeLotsAndSubMarkets = [
        ...activeLots?.filter(l => !activeLotsInSubMarket?.[l.lotId]),
        ...activeSubMarkets];

    activeLotsAndSubMarkets = orderBy(FilterService.filter([], search, activeLotsAndSubMarkets, {}), ['name'], ['asc']);


    const onSelectAll = (selected) => {
        if (selected) {
            dispatch(setSelectedLots(activeLots?.map(l => l.id)));
            dispatch(setSelectedSubmarkets(activeSubMarkets?.map(s => s.id)));

        } else {
            dispatch(setSelectedLots([]));
            dispatch(setSelectedSubmarkets([]));
        }
    }

    return (
        <List>
            {bulkEdit && <>
                <BulkEditLot
                    onBulkSave={saveMultipleLots}
                    onSelectAll={onSelectAll}
                />
            </>}

            {
                activeLots.filter(a => a.isNew)?.map(activeLot =>
                    <Paper className={`${classes.listItemWrapper} ${classes.listItemWrapperNew}`}>
                        <Lot
                            activeLot={activeLot}
                            activeLots={activeLots}
                            activeSubMarkets={activeSubMarkets}
                            onRemove={onRemove} key={'new_lot'}
                        />
                    </Paper>
                )
            }
            {
                activeLotsAndSubMarkets.filter(a => !a.isNew)?.map(activeLot =>
                    <Paper className={`${classes.listItemWrapper} ${activeLot?.isNew && classes.listItemWrapperNew}`}>
                        <Lot
                            activeLot={activeLot}
                            activeLots={activeLots}
                            activeSubMarkets={activeSubMarkets}
                            onRemove={onRemove}
                            key={activeLot?.id} />

                        {!!activeLot?.lots?.length && <Paper className={`${classes.subLots}`}>
                            <div className={classes.subLotsHeader}>Lots in Sub Market</div>
                            {activeLot.lots?.map(lot =>
                                <Lot
                                    activeLot={lot}
                                    activeLots={activeLots}
                                    activeSubMarkets={activeSubMarkets}
                                    onRemove={onRemove}
                                    key={lot?.id}
                                />)}
                        </Paper>}

                    </Paper>
                )
            }
        </List>
    )
}


