import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    addButton: {
        padding: '0rem 0 1rem',
        textAlign: 'right',
        marginRight: '1rem',
    },
    popover: {
        padding: '1em'
    },
    btnWrapper: {
        marginTop: '1em',
    },
    lotActions: {
        opacity: 0,
        transition: 'opacity 0.5s ease'
    },
    listItem: {
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    listItemWrapper: {
        margin: '0.5em 0',
        padding: '1em',
        border: '1px solid transparent',
        "&:hover": {
            "& $lotActions": {
                opacity: 1,
            }
        }
    },
    listItemWrapperEdit: {
    },
    listItemWrapperNew: {
        borderColor: '#90C534',
        position: 'relative',
        "&::before": {
            content: "'NEW'",
            color: 'white',
            background: '#90C534',
            position: "absolute",
            fontSize: '0.8em',
            padding: '1px 5px',
            top: '-0.2em',
            left: "1em",
            borderRadius: '4px',
            transform: "translate(0px, -50%)",
        },
    },
    lotName: {
        padding: '0 0.5em',
        marginBottom: '0.5em',
        background: theme.palette.background.default,
        fontWeight: 'bold',
        minHeight: '3em'
    },
    fieldWrapper: {
        padding: '0.5em'
    },
    inputField: {
        fontWeight: 'bold'
    },
    bulkTitle: {
        fontSize: '1.1em',
        color: theme.palette.primary.main
    },
    bulkCountTitle: {
        padding: '1em',
        margin: '0.5em 0',
        background: theme.palette.primary.light,
        fontWeight: '400'
    },
    listItemWrapperBulk: {
    },
    subLotsHeader: {
        color: theme.palette.primary.main,
        fontSize: '1.1em',
        margin: '0 0 0.5em 0'
    },
    subLots: {
        padding: '1em',
    },
    infinity: {
        fontSize: '1.2em',
        fill: theme.palette.secondary.main,
    },
    addressWrapper: {
        fontSize: '.7rem',
        fontWeight: 300
    }
}));