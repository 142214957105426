import {Grid, IconButton, ListItem, Button, Checkbox} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomInput from 'components/CustomInput';
import { useGetAllInventoryByActivityTypeIdQuery, useUpdateActivityTypeInventoryMutation } from 'features/activity/ActivityTypeInventorySlice';
import { useGetAllInventoryQuery } from "features/inventory/inventorySlice";
import { useEffect, useState } from 'react';
import NotificationProvider from 'components/NotificationProvider';
import { Guid } from 'js-guid';

const InventoryUsed = ({ activity, isReadyOnly = false }) => {

    let [updateInventories] = useUpdateActivityTypeInventoryMutation();
    let { data: inventoriesUsed, isFetching: isLoading, refetch: refetchInventories } = useGetAllInventoryByActivityTypeIdQuery({ entityId: activity?.id, entityType: 1 });
    let { data: inventories} = useGetAllInventoryQuery();
    
    const [inventoriesUsedCopy, setInventoriesUsedCopy] = useState([]);
    const [isAdding, setIsAdding] = useState(false);
    
    let inventoriesFilterByLot = inventories?.filter((inventory) => inventory.lotId === activity.lotId);
    inventoriesFilterByLot = inventoriesFilterByLot || [];
    
    useEffect(() => {
        if (inventoriesUsed) {
            setInventoriesUsedCopy(inventoriesUsed);
        }
    }, [inventoriesUsed]);
    
    const onDelete = (item) => {
        let inventoryArray = [...inventoriesUsedCopy];
        inventoryArray = inventoryArray.filter((inventoryUsed) => inventoryUsed.inventoryId !== item.inventoryId)
        setInventoriesUsedCopy(inventoryArray);
    }

    const onRefresh = async() => {
        await refetchInventories();
    }

    const onChangeInventory = (item, val) => {
        let inventoryExists = inventoriesUsedCopy.some(x => x.inventoryId === parseInt(val));
        let inventorySettingsList = [...inventoriesUsedCopy];
        let newItem = { ...item };
        if (inventoryExists === false) {
            newItem.inventoryId = parseInt(val);
        }
        inventorySettingsList[inventoriesUsedCopy.findIndex(i => i == item)] = { ...newItem };
        setInventoriesUsedCopy(inventorySettingsList);
    }

    const onChangeUnitNumber = (item, val) => {
        if (!val){
            val = 0;
        }
        let inventorySettingsList = [...inventoriesUsedCopy];
        let newItem = { ...item };
        newItem.unitNumber = val;
        inventorySettingsList[inventoriesUsedCopy.findIndex(i => i == item)] = { ...newItem };
        setInventoriesUsedCopy(inventorySettingsList);
    }

    const onAddNewInventoryItem = () => {
        let inventorySettingsList = [...inventoriesUsedCopy];
        inventorySettingsList.push({
            'inventoryId': null,
            'unitNumber': null,
            "isCompleted": false,
            "entityId": parseInt(activity?.id),
            "entityType": 1,
            "id": null,
            "CreatedBy": "",
            "CreatedDate": null,
            "DeletedDate": null,
            "DeletedBy": "",
            "TrackGuid": Guid.newGuid().StringGuid,
        });
        setInventoriesUsedCopy(inventorySettingsList);
    };
    const onSaveAction = async() => {
        setIsAdding(true);
        let request = {
            ActivityTypeInventoryEntities: inventoriesUsedCopy,
            EntityId: activity?.id,
            EntityType: 1
        };
        let res = await updateInventories(request);
        if (res.error) {
            NotificationProvider.error("Failed to save the changes");
        } else {
            NotificationProvider.success("Successfully saved the changes");
        }
        await onRefresh();
        setIsAdding(false);
    }

    const onChangeCheckbox = (inventory, isChecked) => {
        const updatedInventories = inventoriesUsedCopy.map((item) => {
            if (item.inventoryId === inventory.inventoryId) {
                return { ...item, isCompleted: isChecked };
            } else {
                return item;
            }
        });

        setInventoriesUsedCopy(updatedInventories);
    };


    
    return (
        <>
            <Grid container spacing={3} style={{ justifyContent: "center"}}>
                {
                    inventoriesUsedCopy?.length > 0 &&
                    inventoriesUsedCopy?.map((inventory, index) => {
                        return <div key={index}>
                            <ListItem>
                                <Grid container spacing={2} >
                                    <Grid item xs={1}>
                                        <Checkbox
                                            checked={inventory.isCompleted}
                                            onChange={(event) => {
                                                onChangeCheckbox(inventory, event.target.checked);
                                            }}
                                            disabled={isReadyOnly}
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={5}>
                                        <CustomInput
                                            id="inventoryId"
                                            label="Inventory"
                                            elementType="dropdown"
                                            values={inventoriesFilterByLot}
                                            value={inventory.inventoryId}
                                            showEmpty
                                            required
                                            touched
                                            onChange={val => onChangeInventory(inventory, val) }
                                            disabled={isReadyOnly}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <CustomInput
                                            id="unitNumber"
                                            label="Unit Number"
                                            elementType="input"
                                            value={inventory.unitNumber}
                                            required={true}
                                            onChange={val => onChangeUnitNumber(inventory, val)}
                                            disabled={isReadyOnly}
                                        />
                                    </Grid>
                                    <IconButton edge="end" aria-label="delete"
                                                onClick={() => onDelete(inventory)}
                                                disabled={isReadyOnly}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </ListItem>
                        </div>
                    })
                }
            </Grid>
            <Grid container style={{ display: "flex", justifyContent: "space-around", padding: 12 }}>
                <Grid item>
                    <Button onClick={onAddNewInventoryItem} color='primary' variant="contained" disabled={isReadyOnly}>
                        Add New Item
                    </Button>
                </Grid>
                <Grid item>
                    <Button onClick={onSaveAction} color='primary' variant="contained" disabled={isReadyOnly || isAdding}>
                        {isAdding ? 'Saving...' : 'Save Changes'}
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default InventoryUsed
