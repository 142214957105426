import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    inputOpenTime: {
        padding: 18.5,
        color: '#46535d',
        backgroundColor: '#F3F5F6',
        font: 'inherit',
        width: 100,
        border: 0,
        /* height: 1.1876em; */
        margin: 0,
        display: 'block',
        minWidth: 0,
        boxSizing: 'content-box',
        letterSpacing: 'inherit'
    },
    invalidInput: {
        borderBlockColor: theme.palette.error.dark
    },
    inputIcon: {
        cursor: 'pointer'
    },
    label: {
        color: theme.palette.text.secondary,
        '&.Mui-disabled': {
            color: theme.palette.text.secondary,
            opacity: 0.7,
        }
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'green !important'
    },
    divDesign: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    dropDownLine: {
        marginRight: 5
    }
}));