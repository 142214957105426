import { createSlice } from "@reduxjs/toolkit";
import { apiTags } from '../../../../app/store/apiTags';
import { baseApi } from '../../../../app/baseApi';
import { baseQuery } from '../../../../app/baseQuery';

export const lotConfigApi = baseApi.injectEndpoints({
    reducerPath: 'lotConfigApi',
    baseQuery: baseQuery,
    tagTypes: [apiTags.CLIENT_LOT_CONFIG],
    endpoints: (builder) => ({
        removeLotFromClient: builder.mutation({
            query: body => ({
                url: `lot/client`,
                method: "DELETE",
                body
            }),
            invalidatesTags: [apiTags.CLIENT_CONFIG],
        }),
        updateClientLot: builder.mutation({
            query: data => ({
                url: `lot/client`,
                method: "POST",
                body: data
            }),
            invalidatesTags: [apiTags.CLIENT_CONFIG],
        }),
        addClientLot: builder.mutation({
            query: clientLot => ({
                url: `lot/client`,
                method: "PUT",
                body: {
                    clientLot
                }
            }),
            invalidatesTags: [apiTags.CLIENT_CONFIG],
        }),
        updateClientSubMarket: builder.mutation({
            query: clientSubmarkets => ({
                url: `submarket/client`,
                method: "POST",
                body: {
                    clientSubmarkets
                }
            }),
            invalidatesTags: [apiTags.CLIENT_CONFIG],
        }),
        addClientSubMarket: builder.mutation({
            query: clientSubmarkets => ({
                url: `submarket/client`,
                method: "PUT",
                body: {
                    clientSubmarkets
                }
            }),
            invalidatesTags: [apiTags.CLIENT_CONFIG],
        }),
        removeSubMarketFromClient: builder.mutation({
            query: body => ({
                url: `submarket/client`,
                method: "DELETE",
                body
            }),
            invalidatesTags: [apiTags.CLIENT_CONFIG],
        }),
        monthlyBillingReport: builder.mutation({
            query: clientId => ({
                url: `lot/LotBillingReportZip`,
                method: "POST",
                body: {clientId},
                responseHandler: async (response) => 
                { 
                    response.blob().then(blob => {
                        // Creating new object of PDF file
                        const fileURL = window.URL.createObjectURL(blob);
                        // Setting various property values
                        let alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.download = 'Monthly Billing Report.zip';
                        console.log(alink);
                        alink.click();
                    })
                    //window.location.assign(window.URL.createObjectURL(await response.blob())) 
                },
                cache: "no-cache"
            })
        }),
    }),
});

export const {
    useRemoveLotFromClientMutation,
    useAddClientLotMutation,
    useUpdateClientLotMutation,
    useUpdateClientSubMarketMutation,
    useAddClientSubMarketMutation,
    useRemoveSubMarketFromClientMutation,
    useMonthlyBillingReportMutation
} = lotConfigApi;


export const lotConfigSlice = createSlice({
    name: 'lotConfigSlice',
    initialState: {
        bulkEdit: false,
        selectedLots: [],
        selectedSubmarkets: [],
    },
    reducers: {
        setBulkEdit: (state, action) => {
            state.bulkEdit = action.payload;
        },
        addSelectedLot: (state, action) => {
            state.selectedLots = [...state.selectedLots, action.payload];
        },
        setSelectedLots: (state, action) => {
            state.selectedLots = [...action.payload];
        },
        resetSelectedLots: (state, action) => {
            state.selectedLots = [];
        },
        resetSelected: (state, action) => {
            state.selectedLots = [];
            state.selectedSubmarkets = [];
        },
        removeSelectedLot: (state, action) => {
            state.selectedLots = state.selectedLots.filter(l => l != action.payload);
        },
        addSelectedSubmarket: (state, action) => {
            state.selectedSubmarkets = [...state.selectedSubmarkets, action.payload];
        },
        setSelectedSubmarkets: (state, action) => {
            state.selectedSubmarkets = [...action.payload];
        },
        removeSelectedSubMarket: (state, action) => {
            state.selectedSubmarkets = state.selectedSubmarkets.filter(l => l != action.payload);
        },
        resetSelectedSubmarkets: (state, action) => {
            state.selectedSubmarkets = [];
        },
        resetBulkUpdate: (state, action) => {
            state.selectedLots = [];
            state.selectedSubmarkets = [];
            state.bulkEdit = false
        },
    },
});

export const {
    setBulkEdit,
    resetBulkUpdate,
    addSelectedLot,
    removeSelectedLot,
    resetSelectedLots,
    addSelectedSubmarket,
    removeSelectedSubMarket,
    resetSelectedSubmarkets,
    resetSelected,
    setSelectedLots,
    setSelectedSubmarkets
} = lotConfigSlice.actions;