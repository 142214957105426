import { makeStyles } from "@material-ui/core";

export const activityTableStyle = makeStyles((theme) => ({
    errorIcon: {
        fill: 'white',
    },
    errorIconWrapper:{
        background: '#fd8f8f',
    },
    errorTooltip: {
        padding: '2em',
        width: '500px'
    }
}));