import LoadingSpinner from "components/LoadingSpinner";
import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";
import { kpiDashboardStyles } from "./KpiDashboardStyles";
import KpiInspection from "./navigations/KpiInspection/KpiInspection";
import KpiOverview from "./navigations/KpiOverview/KpiOverview";

const KpiDashboardPage = ({ lotId }) => {

    const classes = kpiDashboardStyles();

    const isLoadingDashboardData = () => false;

    const tabs = [
        {
            label: 'Overview',
            component: <KpiOverview />
        },
        {
            label: 'Inspection',
            component: <KpiInspection lotId={lotId} />
        }
    ]
    return (
        <>
            {
                isLoadingDashboardData() ? <LoadingSpinner loading={isLoadingDashboardData()} /> :
                    <TabsWrapper tabs={tabs} />
            }
        </>
    );
};

export default KpiDashboardPage;
