import { InputAdornment, makeStyles, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import DropdownFilter from './DropdownFilter';

const useStyles = makeStyles((theme) => ({
    input: {
        color: theme.palette.common.darkText,
        backgroundColor: theme.palette.background.default,
        borderRadius: '5px',
        '&.MuiOutlinedInput-adornedEnd': {
            backgroundColor: theme.palette.background.default,
        },
        '&.Mui-disabled': {
            opacity: 0.5,
        },
        '& fieldset': {
            border: 'none'
        }
    },
    invalidInput: {
        borderBlockColor: theme.palette.error.dark
    },
    colorInput: {
        backgroundColor: theme.palette.action.focus,
    },
    inputIcon: {
        cursor: 'pointer'
    },
    label: {
        color: theme.palette.text.secondary,
        '&.Mui-disabled': {
            color: theme.palette.text.secondary,
            opacity: 0.7,
        }
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'green !important'
    }
}))

const CustomInput = ({
    id,
    label,
    value,
    onChange,
    elementType,
    values,
    icon,
    iconAction,
    shouldValidate,
    valid = true,
    touched,
    disabled,
    showEmpty,
    required,
    classStyles,
    colorInput = false,
    numberOnly,
    shrink,
    step,
    clientId,
    minValue = null,
    onClick = () => { },
    renderOption
}) => {

    const classes = useStyles();

    const handleChange = (event) => {
        onChange(event.target.value, id);
    };

    const onIconClick = () => {
        iconAction(id);
    };

    let inputElement = null;

    switch (elementType) {
        case ('input'):
            inputElement = <TextField inputProps={{ className: `${classes.input} ${!valid && classes.invalidInput} ${colorInput ? classes.colorInput : ''}`, type: numberOnly ? 'number' : undefined, step: step, min: minValue }}
                fullWidth variant='outlined' InputLabelProps={{ className: classes.label, shrink: shrink }}
                error={!valid && touched} disabled={disabled} value={value}
                id={id} label={label} required={required} onChange={(event) => handleChange(event)}

                InputProps={{
                    notchedOutline: classes.notchedOutline,
                    className: classes.input,
                    endAdornment: (
                        icon &&
                        <InputAdornment position="end" className={classes.input}>
                            {icon}
                        </InputAdornment>
                    ),
                }} />
            break;
        case ('textarea'):
            inputElement = <TextField required={required} inputProps={{ className: classes.input }} InputLabelProps={{ className: classes.label }}
                className={classes.input} fullWidth variant='outlined' id={id} label={label}
                value={value} onChange={(event) => handleChange(event)} multiline rows={4}
                error={!valid && touched}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" className={classes.inputIcon}
                            onClick={() => onIconClick()}>
                            {icon ?
                                icon
                                : null
                            }
                        </InputAdornment>
                    ),
                }} />
            break;
        case ('dropdown'):
            inputElement = <DropdownFilter title={label} identifier={id} value={value} values={values} required={required} classStyles={classStyles}
                onSelect={onChange} valid={valid} touched={touched} disabled={disabled} showEmpty={showEmpty} colorInput={colorInput} renderOption={renderOption} />
            break;
        default:
            inputElement = <TextField className={classes.input} fullWidth variant='outlined' InputLabelProps={{ className: classes.label }}
                id={id} label={label} disabled={disabled} defaultValue={value} required={required} onChange={(event) => handleChange(event)} />
    }

    return (
        <div onClick={() => onClick()}>
            {inputElement}
        </div>

    )
}

export default CustomInput
