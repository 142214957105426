export const storageHeaderColumns = [
    {
        label: 'Available Lots',
        size: 4,
        key: 'lot',
        value: (invoice) => invoice?.lotInfo
    },
    {
        label: 'Base Days PMF',
        size: 2,
        key: 'daysPMF',
    },
    {
        label: 'Overage Days PMF',
        size: 2,
        key: 'overageDaysPMF'
    },
    {
        label: 'Lot Fee',
        size: 2,
        key: 'lotFee',
        value: (invoice) => `$${invoice?.lotFee}`
    }
]


export const storageDetailsColumns = [
    {
        label: 'Days PMF',
        key: 'daysPMF',
        editable: true
    },
    {
        label: 'Days On Lot',
        key: 'daysOnLot',
        editable: true
    },
    {
        label: 'Base Rate',
        key: 'baseRate',
        value: (v) => `$${v}`
    },
    {
        label: 'Charge',
        key: 'baseFee',
        value: (v) => `$${v}`
    },
    {
        label: 'Overage Days PMF',
        key: 'overageDaysPMF',
        size: 2,
        editable: true
    },
    {
        label: 'Overage Days on Lot',
        key: 'overageDaysOnLot',
        size: 2,
        editable: true
    },
    {
        label: 'Overage Rate',
        key: 'overageRate',
        value: (v) => `$${v}`
    },
    {
        label: 'Overage Charge',
        key: 'overageFeeToClient',
        size: 1,
        value: (v) => `$${v}`
    },
    {
        label: 'Total',
        key: 'lotFee',
        size: 1,
        value: (v) => `$${v}`,
        editable: true
    },
]

