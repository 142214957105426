import {useState} from "react";
import ContractHeader from "../ContractHeader/ContractHeader";
import {mechanicalConfigStyles} from "./style";
import {IconDollar, IconParkingLot, IconPercent, IconSlip} from "../../../../icons";
import {permissionProfiles} from "../../../../components/Authorize/permissionProfiles";
import Authorize from "../../../../components/Authorize";
import {Grid, Switch, Paper} from "@material-ui/core";
import CustomInput from "../../../../components/CustomInput";

const ConditionalReportConfig = ({clientToEdit, onConfigChange, configName = 'conditionalReportConfig'}) => {
        
    const classes = mechanicalConfigStyles();

    const [selectedLot, setSelectedLot] = useState();

    const conditionalReportConfigParams = clientToEdit?.config?.conditionalReportConfig;
    const priceField = (fieldName, label, icon) => (
        field(
            fieldName,
            label,
            <CustomInput
                value={conditionalReportConfigParams?.[fieldName]}
                onChange={(val) => onConfigChange(configName, fieldName, parseInt(val))}
                icon={icon ? icon : <IconPercent/>}
                elementType="input"
                numberOnly
            />, undefined, permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_LOT_PRICING
        )
    )

    const field = (fieldName, label, component, icon, profile) => (
        <Authorize profile={profile}>
            <Grid item>
                <div>{label} </div>
                <div>
                    {component ??
                        <Authorize
                            profile={profile}>
                            <CustomInput
                                onC hange={(val) => onConfigChange(configName, fieldName, parseInt(val))}
                                icon={icon}
                                elementType="input"
                                numberOnly
                            />
                        </Authorize>}
                </div>
            </Grid>
        </Authorize>
    )


    return <>
        <Grid container direction='column' spacing={1} xs={8}>
            { field('useConditionalReport', 'Use Conditional Report', <Authorize profile={undefined}>
                    <Switch
                        color="primary"
                        checked={conditionalReportConfigParams?.useConditionalReport != false}
                        onChange={(e) => onConfigChange(configName, 'useConditionalReport', e.target.checked)}
                        inputProps={{'aria-label': 'controlled'}}
                    /></Authorize>, undefined, undefined)}

            {priceField('priceByReport', 'Report Price', <IconDollar/>)}
        </Grid>
    </>
}

export default ConditionalReportConfig;