import {
    useGetAllActivitiesQuery,
    useGetAllActivityItemsQuery,
    useGetAllActivityLogsQuery,
    useGetAllActivityTypesQuery
} from "./activitySlice";
import LoadingSpinner from "../../components/LoadingSpinner";
import { groupBy, orderBy } from "lodash";
import DataTable from "../../components/DataTable";
import React, { useState } from "react";
import { useStyles } from "./ActivityReportPageStyles";
import { useGetLotsQuery } from "../lots/lotSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAdmin } from "../user/userSlice";
import FormatService from "../../services/formatService";
import { useGetClientsQuery } from "../clients/clientSlice";
import { Button, Grid } from "@material-ui/core";
import ExportButton from "../../components/ExportButton";
import DataListHeader from "../../components/DataListHeader";
import { setSearchVal, setFilters } from "./activityReportFiltersSlice";
import FilterService from "../../services/filterService";
import useSortOptions from "../../hooks/useSortOptions";
import PageComponent from "../../components/Page/PageComponent";
import { fieldTypes } from "../../services/filterService";
import ImportActivities from './ImportActivities/ImportActivities';
import Authorize from "../../components/Authorize";
import { permissionProfiles } from "../../components/Authorize/permissionProfiles";
import { useHistory } from 'react-router-dom';
import AddActivityType from "features/pricing/AddActivityType/AddActivityType";
import NotesTooltip from "features/notes/NotesTooltip";
import { NoteEntityTypes } from "features/notes/notesConsts";
import { useGetAllActivityLogNotesQuery } from "features/notes/notesSlice";


const ActivityReportPage = () => {
    const classes = useStyles();

    const isAdmin = useSelector(selectIsAdmin);
    const history = useHistory();

    let { data: activityLogs, error: activityLogsError, isFetching: isLoadingActivityLogs, refetch } = useGetAllActivityLogsQuery();

    let { data: activities, error: activitiesError, isFetching: isLoadingActivities } = useGetAllActivitiesQuery();
    let { data: activityTypes, error: activityTypesError, isFetching: isLoadingActivityTypes } = useGetAllActivityTypesQuery();
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null, { skip: !isAdmin });

    let { data: activityNotes, error: activityNotesError, isFetching: isLoadingNotes, refetch: getNotes } = useGetAllActivityLogNotesQuery();
    const { searchVal, filters } = useSelector(state => state.activityReportFilters);
    const dispatch = useDispatch();

    activityLogs = activityLogs || [];
    activities = activities || [];
    activityTypes = activityTypes || [];
    activityItems = activityItems || [];
    lots = lots || [];
    clients = clients || [];

    activityLogs = activityLogs.map(a => ({
        ...a,
        formattedDate: FormatService.formatDate(a.date),
        formattedDateRequested: FormatService.formatDate(a.dateRequested),
        formattedPrice: FormatService.formatCurrency(a.cost),
        formattedRevenue: FormatService.formatCurrency(a.revenue),
    }));

    const isLoading = () => isLoadingActivityLogs || isLoadingActivities || isLoadingActivityTypes || isLoadingActivityItems || isLoadingLots || isLoadingClients;

    const slaValues = [
        { id: true, name: "Yes" },
        { id: false, name: "No" },
    ];

    let activityColumns = [
        { name: "Date", key: "formattedDate", sortKey: "date", selectedByDefault: true },
        { name: "VIN", key: "descriptor", selectedByDefault: true, width: 180 },
        { name: "Asset ID", key: "assetId", selectedByDefault: true },
        { name: "Client", key: "clientId", values: clients, requireAdmin: true, selectedByDefault: true },
        { name: "Lot", key: "lotId", values: lots, selectedByDefault: true },
        { name: "Type", key: "activityTypeId", values: activityTypes, selectedByDefault: true },
        { name: "Activity", key: "activityItemId", values: activityItems, selectedByDefault: true },
        { name: "Status", key: "activityId", values: activities, selectedByDefault: true },
        { name: "Description", key: "description", selectedByDefault: true },
        { name: "Date Requested", key: "formattedDateRequested", sortKey: "dateRequested" },
        { name: "Within SLA", key: "withinSla", values: slaValues, selectedByDefault: true },
        { name: "Internal Cost", key: "formattedPrice", requireAdmin: true },
        { name: "Charge", key: "formattedRevenue", requireAdmin: true },
        { name: "Notes", key: "id", component: (id) => <NotesTooltip entityId={id} entityType={NoteEntityTypes.ACTIVITY} /> },
    ];

    if (!isAdmin) {
        activityColumns = activityColumns.filter(c => !c.requireAdmin);
    }

    let filterConfig = [
        {
            type: 'text',
            size: 2,
            title: 'Client',
            key: 'clientId',
            values: clients,
            requireAdmin: true
        },
        { type: fieldTypes.LOT, size: 2, title: 'Lot', key: 'lotId', values: lots },
        { type: 'text', size: 2, title: 'Activity Type', key: 'activityTypeId', values: activityTypes },
        { type: 'text', size: 2, title: 'Activity', key: 'activityItemId', values: activityItems },
        { type: 'text', size: 2, title: 'Activity Status', key: 'activityId', values: activities },
        { type: 'dateRange', size: 3, title: 'Date', key: 'date', values: [] },
    ];

    if (!isAdmin) {
        filterConfig = filterConfig.filter(f => !f.requireAdmin);
    }

    const [sortOptions, sortData] = useSortOptions();

    const getName = (data, id) => data.find(l => l.id === id)?.name;

    const searchActivityLogs = value => {
        dispatch(setSearchVal(value.trim()));
    };

    const onDropFilter = (value, prop) => {
        dispatch(setFilters({ ...filters, [prop]: { value } }));
    };

    const filteredActivityLogs = FilterService.filter(filters, searchVal, activityLogs, filterConfig);

    const noteLookup = groupBy(activityNotes, 'entityId');

    const mapExportData = a => {
        const selectedColumnsMappedData = {};
        let allDataMap = {
            Date: a.formattedDate,
            VIN: a.descriptor,
            ["Asset ID"]: a.assetId,
            Client: getName(clients, a.clientId),
            Lot: getName(lots, a.lotId),
            ["Activity Type"]: getName(activityTypes, a.activityTypeId),
            ["Activity"]: getName(activityItems, a.activityItemId),
            ["Status"]: getName(activities, a.activityId),
            Description: a.description,
            ["Date Requested"]: a.formattedDateRequested,
            ["Within SLA"]: getName(slaValues, a.withinSla),
            "Internal Cost": a.cost,
            "Charge": a.revenue,
            "Notes": noteLookup[a.id]?.map(n => n.content?.replace(/<[^>]+>/g, ''))?.join(", ") ?? ''
        }
        for (const field of activityColumns) {
            selectedColumnsMappedData[field.name] = allDataMap[field.name]
        }
        return selectedColumnsMappedData;
    };

    if (isLoading()) {
        return <LoadingSpinner loading={true} />;
    }

    return (
        <PageComponent header={
            <DataListHeader
                title={`Filter Results (${filteredActivityLogs?.length ?? 0})`}
                data={activityLogs}
                dropDownsConfig={filterConfig}
                filters={filters}
                onDropFilter={onDropFilter}
                onSearch={searchActivityLogs}
                searchVal={searchVal}
                actionButtons={(
                    <>
                        <Grid container spacing={1}>
                            <Authorize profile={permissionProfiles.PRICING.MANAGE_PPRICING}>
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={() => { history.push(`pricing`) }}>
                                        Pricing
                                    </Button>
                                </Grid>
                            </Authorize>
                            <Grid item>
                                <ImportActivities clients={clients} refetch={refetch} />
                            </Grid>
                            <Grid item>
                                <ExportButton fileLabel="activities" data={filteredActivityLogs}
                                    mapExportData={mapExportData} />
                            </Grid>
                        </Grid>
                    </>
                )}
            />}>
            <DataTable
                columns={activityColumns}
                rows={orderBy(
                    filteredActivityLogs,
                    sortOptions.columnToSort,
                    sortOptions.sortDirection
                )}
                rowIdentifier='id'
                onSort={sortData}
                sortDirection={sortOptions.sortDirection}
                columnToSort={sortOptions.columnToSort}
                noItemsMessage='There are no activities matching this criteria'
            />
        </PageComponent>
    );
};

export default ActivityReportPage;
