import { Grid, Paper } from "@material-ui/core";
import React, { useState } from "react";
import FormatService from "../../../../../../services/formatService";
import { TaskSetup } from "../../../../taskConsts";
import { TaskItemStyles } from "../../TaskItemStyles";

const LoadsTask = ({ task }) => {
    const taskSetup = TaskSetup[task?.taskType];
    const classes = TaskItemStyles();

    const field = (label, value) => {
        return <Grid container direction="row"><Grid xs={4}><label className={classes.label}>{label}</label></Grid><Grid xs={8}> {value}</Grid></Grid>;
    }

    //change fields show
    return (
        <Grid container direction='column'>
            {field('Pickup Location', task?.pickupLocation?.name)}
            {field('Pickup Date', FormatService.formatDate(task?.calcPickupDate))}
            {task?.assets[0].assetId && field('Asset ID', task?.entity?.assetId)}
            {field('VIN', task?.entity?.descriptor)}
        </Grid>
    )
}
export default LoadsTask;