import { FormControl, makeStyles, TextField } from '@material-ui/core'
import React from 'react'
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        height: '100%',
        width: '100%'
    },
    datePicker: {
        backgroundColor: theme.palette.background.default,
        '& fieldset': {
            border: 'none'
        }
    },
    input: {
        border: 0,
        color: theme.palette.common.darkText,

    },
    label: {
        color: theme.palette.text.secondary,
        '&.Mui-disabled': {
            color: theme.palette.text.secondary,
        }

    }
}));

const DatePicker = ({
    title,
    identifier,
    onSelect,
    minDate,
    maxDate,
    value = null,
    required = false,
    disabled = false,
    valid = true
}) => {
    const classes = useStyles();

    const [dateValue, setDateValue] = React.useState(value)

    useEffect(() => {
        setDateValue(value)
        if (value === '' || value === null) {
            setDateValue("");
        }
    }, [value]);

    const handleChange = (event) => { onSelect(event.target.value, identifier); };

    return (
        <FormControl variant='outlined' className={classes.formControl}>
            <TextField
                className={classes.datePicker}
                variant='outlined'
                id="date"
                label={title}
                type="date"
                value={dateValue}
                InputLabelProps={{
                    shrink: true,
                    className: classes.label
                }}
                inputProps={{
                    min: minDate,
                    max: maxDate,
                    className: classes.input
                }}
                onChange={handleChange}
                required={required}
                disabled={disabled}
                error={!valid}
            />
        </FormControl>
    )
}

export default DatePicker
