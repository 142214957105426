import { List, ListItem, ListItemText, ListItemIcon, Paper, Tooltip, MenuItem, Menu, Popover } from '@material-ui/core';
import React, { useState } from 'react';
import { loadHistoryStyle } from './LoadHistoryStyle';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder/EmptyPlaceholder';
import moment from "moment";
import statuses from '../../loadStatusesWithIcons';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import LoadStatus from '../LoadStatus';
import CommentIcon from '@material-ui/icons/Comment';
import LoadStatusUpdate from '../LoadStatusUpdate/LoadStatusUpdate';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Authorize from '../../../../components/Authorize';
import { permissionProfiles } from '../../../../components/Authorize/permissionProfiles';
import { useDeleteStatusMutation } from '../../loadsSlice';
import ConfirmationPopup from '../../../../components/ConfirmationPopup';
import usePrompt from '../../../../hooks/usePrompt';

function LoadHistory({ load }) {
    const classes = loadHistoryStyle()

    const history = load.statuses;

    const [statusToEdit, setStatusToEdit] = useState();
    let [deleteStatus, { isLoading: isLoadingDeleteStatus, isSuccess: deleteStatusSuccess }] = useDeleteStatusMutation();
    const { triggerPrompt } = usePrompt();

    const actions = [
        {
            key: 'edit',
            label: 'Edit',
            onClick: (status) => setStatusToEdit(status)
        },
        {
            key: 'delete',
            label: 'Delete',
            onClick: (status) => {
                triggerPrompt({
                    title: "Delete Status",
                    content: "Are you sure you want to delete the status?",
                    loading: isLoadingDeleteStatus,
                    onConfirm: async () => {
                        await deleteStatus(status.id);
                    },
                });
            }
        }
    ]
    return (
        <>
            {!(history?.length) ?
                <EmptyPlaceholder text='no history' className={classes.emptyPlaceHolder} /> :
                <Paper className={classes.card} elevation={1}>
                    <div className={classes.listWrapper}>
                        <List>
                            <ListItemText>History</ListItemText>
                            {(_.reverse(_.sortBy(history, h => h.statusDate)))?.map(h =>
                                <StatusItem status={h} load={load} actions={actions} />
                            )}
                        </List>
                    </div>
                </Paper>
            }
            <LoadStatusUpdate
                loadId={load?.id}
                statusToEdit={statusToEdit}
                onClose={() => setStatusToEdit(null)}
                forEdit={true}
            />

        </>
    );
}

export default LoadHistory;


const StatusItem = ({ status, actions, load }) => {
    const classes = loadHistoryStyle();
    const getStatus = (key) => {
        return { label: statuses[key.status]?.label || key.status, icon: statuses[key.status]?.icon };
    };

    const getDate = (date) => {
        const options = {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        };
        return date ? new Date(moment(date).format("MMM DD, yyyy, hh:mm a") + " UTC").toLocaleString({}, options) : "";
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorComment, setAnchorComment] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleCommentOpen = (event) => {
        setAnchorComment(event.currentTarget);
    };

    const handleCommentClose = () => {
        setAnchorComment(null);
    };


    return (
        <>
            <ListItem className={classes.historyItem}>
                <ListItemIcon>
                    {getStatus(status).icon}
                </ListItemIcon>
                <ListItemText
                    primary={
                        <>
                            <LoadStatus load={load} status={status} />
                        </>}
                    secondary={getDate(status.statusDate)} secondaryTypographyProps={{ style: { opacity: '0.6' } }}
                />
                {!!status.comment && <ListItemIcon>
                    <CommentIcon
                        className={classes.commentIcon}
                        onClick={handleCommentOpen}
                    />
                    <Popover
                        open={!!anchorComment}
                        anchorEl={anchorComment}
                        onClose={() => setAnchorComment(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div className={classes.statusTootlip}>
                            {status.comment}
                        </div>
                    </Popover>
                </ListItemIcon>}
                <Authorize profile={permissionProfiles.LOAD.LOAD_ACTIONS}>
                    <ListItemIcon className={classes.historyItemActions} onClick={handleClick} >
                        <MoreVertIcon />
                    </ListItemIcon>
                </Authorize>
            </ListItem>
            <Menu
                id="lot-actions"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lot-menu',
                }}
            >
                {actions.map(action =>
                    <MenuItem key={action.key} onClick={() => {
                        action.onClick(status);
                        handleClose();
                    }} >
                        {action.label}
                    </MenuItem>
                )}
            </Menu>
        </>
    )
}