import { Grid, IconButton, Popover } from "@material-ui/core";
import { useState } from "react";
import EntityAudit from "./EntityAudit";
import { entityAuditStyle } from "./style";
import UpdateIcon from '@material-ui/icons/Update';
import Authorize from "components/Authorize";

export const EntityAuditTooltip = ({ entity }) => {

    const classes = entityAuditStyle();

    const [anchorEl, setAnchorEl] = useState(false);


    return (
        <>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                <UpdateIcon className={classes.icon} />
            </IconButton>
            <Popover

                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Grid className={classes.tooltip} direction='column'>
                    <EntityAudit entity={entity} itemWidth={12} />
                </Grid>
            </Popover>

        </>

    )
}
