import React, { useEffect, useState } from "react";
import { makeStyles, Paper } from '@material-ui/core'
import { useSelector } from "react-redux";
import { useGetLotsQuery } from "../../../lots/lotSlice";
import { userPageStyle } from "./UserPageStyle";
import { selectUserEmail, selectUserRole } from "../../userSlice";
import Authorize from "../../../../components/Authorize";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { roleInfo } from "../../../../components/Authorize/roles";
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { Grid } from "@material-ui/core";
import { List as ListIcon } from "@material-ui/icons";
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import { useGetClientsQuery } from "../../../clients/clientSlice";

const UserPage = () => {
    const userEmail = useSelector(selectUserEmail);
    const userRole = useSelector(selectUserRole);

    const classes = userPageStyle();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery();

    return (
        <div>
            <div className={classes.title}>
                <Grid container direction='row'>
                    <Grid item xs={2}>
                        <Grid item className={classes.pageTitle}>User Details</Grid>
                        <Grid item className={classes.userEmail}>{userEmail}</Grid>
                        <Grid item className={classes.userEmail}>{roleInfo[userRole]?.label}</Grid>
                    </Grid>
                </Grid>
            </div>
            {!isLoadingLots && <Paper className={classes.box}>
                <div className={classes.header}><img src="/icons/icon-parking.svg" alt="Lots" /> Assigned Lots</div>
                <div className={classes.content}>
                    <Authorize profile={permissionProfiles.USER.VIEW_LIMITED_CLIENTS_LOTS}>
                        {lots?.map(l => <div className={classes.line}>{l.name}</div>)}
                    </Authorize>
                    <Authorize profile={permissionProfiles.USER.VIEW_ALL_CLIENTS_LOTS}>
                        <div>Access to all Lots</div>
                    </Authorize>
                </div>
            </Paper>}
            <Authorize profile={permissionProfiles.USER.VIEW_CLIENTS}>
                <Paper className={classes.box}>
                    <div className={classes.header}><img src="/icons/icon-user.svg" alt="Dashboard" />Assigned Clients</div>
                    <div className={classes.content}>
                        <Authorize profile={permissionProfiles.USER.VIEW_LIMITED_CLIENTS_LOTS}>
                            {clients?.map(l => <div>{l.name}</div>)}
                        </Authorize>
                        <Authorize profile={permissionProfiles.USER.VIEW_ALL_CLIENTS_LOTS}>
                            <div>Access to all clients</div>
                        </Authorize>
                    </div>
                </Paper>
            </Authorize>
        </div>
    );
};

export default UserPage;
