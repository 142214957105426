import { makeStyles } from "@material-ui/core";

const darkGreyBackground = "#dde2e5"
const lightGreyBackground = "#f3f5f6";
const lightGreyText = "#b0bac1";
const lightBlue = "#d4ecff";

export default makeStyles(theme => ({
    table: {
        height: '75vh'
    },
    dropdownStatuses: {
        margin: '0 0 2rem 2rem',
        position: 'relative',
        width: "25%"
    },
    button: {
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white
        },
        minWidth: "7rem"
    },
    header: {
        fontWeight: 500,
        padding: '2rem 0 2rem 1rem',
        backgroundColor: lightBlue,
        // width: "12rem",
        width: "2000%",
    },
    text: {
        fontWeight: 500,
        paddingLeft: "1rem",
    },
    label: {
        fontWeight: 500,
        fontFamily: "Montserrat",
        fontSize: "0.875rem"
    },
    checkbox: {
        width: "2000%",
        padding: '2rem 0 2rem 0.3rem',
        backgroundColor: lightBlue,
    },
    notActiveDark: {
        backgroundColor: darkGreyBackground,
        width: "2000%",
        padding: '2rem 0 2rem 0.3rem',
    },
    notActive: {
        backgroundColor: lightGreyBackground,
        width: "2000%",
        padding: '2rem 0 2rem 0',
    },
    notActiveText: {
        backgroundColor: lightGreyBackground,
        width: "6000%",
        padding: '2rem 0 2rem 1rem',
        color: lightGreyText,
    }
}));