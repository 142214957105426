import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from "../../app/baseQuery";

export const driverApi = createApi({
    reducerPath: 'driverApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getDrivers: builder.query({
            query: () => `driver/all`,
            providesTags:['Driver']
        }),
        addDriver: builder.mutation({
            query: driver => ({
                url: "driver",
                method: "POST",
                body: driver
            }),
            invalidatesTags: ["Driver"]
        }),
        deleteDriver: builder.mutation({
            query: driver => ({
                url: "driver",
                method: "DELETE",
                body: driver
            }),
            invalidatesTags: ["Driver"]
        }),
    }),
});

export const { useGetDriversQuery, useAddDriverMutation, useDeleteDriverMutation } = driverApi;
