import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Grid } from "@mui/material";
import React from "react";
import UserMenu from "./UserMenu/UserMenu";
import { useSelector } from "react-redux";
import { IconButton, makeStyles } from "@material-ui/core";
import TasksPanel from "../Tasks/components/TaskPanel/TasksPanel";
import MechanicalTasksPanel from "../mechanicalTasks/components/taskPanel";
import MenuIcon from "@material-ui/icons/Menu";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import Authorize from "components/Authorize";

export const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        fontWeight: '400'
    },
    iconButton: {
        position: "absolute",
        left: 100,
        fill: "white"
    },
    iconButton2: {
        position: "absolute",
        left: 260,
        fill: "white"
    },
}));


const Header = ({ onToggleSideNav, isNavOpen }) => {
    const classes = useStyles();
    let user = useSelector(state => state.oidc.user);
    let isUserLoaded = !!user;
    return (
        <AppBar color="secondary">

            <Toolbar style={{ justifyContent: "flex-end" }}>
                {
                    isUserLoaded &&
                    <Grid container justifyContent="flex-end">
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={onToggleSideNav}
                            className={isNavOpen ? classes.iconButton2 : classes.iconButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <TasksPanel />
                        <Authorize profile={permissionProfiles.MECHANICALS_TASKS.TASK_LIST}>
                            <MechanicalTasksPanel />
                        </Authorize>
                        <UserMenu />

                    </Grid>
                }
            </Toolbar>
        </AppBar>
    );
};

export default Header;
