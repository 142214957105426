import MyLocationRoundedIcon from '@material-ui/icons/MyLocationRounded';
import LoadingSpinner from 'components/LoadingSpinner';
import MapWrapper from 'components/MapWrapper/MapWrapper';
import { googleMapService } from "components/MapWrapper/googleMapService";
import moment from "moment";
import { useEffect, useState } from 'react';
import LotsMapMarker from '../../../maps/markers/LotsMapMarker/LotsMapMarker';
import { useGetLoadLocationsQuery } from '../../loadsSlice';
import { loadMapStyle } from './LoadMapStyle';

function LoadMap({ load, showLocations }) {
    const classes = loadMapStyle()

    let { data: loadLocations, error: loadLocationsError, isLoading: isLoadingLoadLocations } = useGetLoadLocationsQuery(load.id);
    loadLocations = loadLocations || [];
    const [markers, setMarkers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getDate = (date, readable = false) => {
        return readable ? moment(date).format('MMM Do, YYYY hh:mm a') : moment(date).format('MM-DD-YYYY hh:mm a');
    };

    const getInfoWindowContent = (location, startDate, endDate) => {
        return `<b>${location.city ? `${location.city},` : ''}
            ${location.state}
            ${location.street ? `${location.street},` : ''}
            ${location.zipCode}<br></b>
            ${getDate(startDate)} - ${getDate(endDate)}`;
    };

    const getHistoryInfoWindowContent = (date) => {
        return `<b>${getDate(date, true)}</b>`;
    };

    const setAllMarkers = () => {

        const historyMarkers = showLocations ? loadLocations?.map(h => ({
            latitude: h.location?.latitude,
            longitude: h.location?.longitude,
            content: getHistoryInfoWindowContent(h.timestamp),
            icon: <MyLocationRoundedIcon className={classes.historyIcon} />,
        })) : [];

        const startLocationMarker = {
            address: googleMapService.convertAddressPartsToAddressString(load?.pickupLocation),
            content: getInfoWindowContent(load?.pickupLocation, load?.pickupStartDate, load?.pickupEndDate),
            icon: <LotsMapMarker
                color={load?.pickupLocation.pmfLot ? "#0090FE" : "#E48900"}
                text={load?.pickupLocation.name}
            />
        };

        const endLocationMarker = {
            address: googleMapService.convertAddressPartsToAddressString(load?.deliveryLocation),
            content: getInfoWindowContent(load?.deliveryLocation, load?.deliveryStartDate, load?.deliveryEndDate),
            icon: <LotsMapMarker
                color={load?.deliveryLocation.pmfLot ? "#0090FE" : "#E48900"}
                text={load?.deliveryLocation.name}
            />
        };

        const markers = [...historyMarkers, startLocationMarker, endLocationMarker];
        setMarkers(markers);
        setIsLoading(false);
    }

    useEffect(() => {
        if (!isLoadingLoadLocations)
            setAllMarkers();
    }, [showLocations, isLoadingLoadLocations]);

    return (
        <div className={classes.mapWrapper}>
            {isLoading ?
                <LoadingSpinner loading={isLoading} /> :
                <MapWrapper
                    defaultCenter={{ lat: Number(load?.pickupLocation?.latitude), lng: Number(load?.pickupLocation?.longitude) }}
                    markers={markers}
                    route={{ startLocation: load?.pickupLocation, endLocation: load?.deliveryLocation }}
                />}
        </div>
    );
}

export default LoadMap