import React, { useState } from 'react';
import DropZoneInDialog from '../../../../components/DropZoneInDialog';
import { importInspectionActivityStyle } from './ImportInspectionActivityStyle';
import { useImportActivityInspectionMutation } from '../../vehicleSlice';
import NotificationProvider from "../../../../components/NotificationProvider";
import { CircularProgress, Dialog, DialogContent, DialogTitle, Paper } from '@material-ui/core';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';


function ImportInspectionActivity({ }) {
    const classes = importInspectionActivityStyle();
    let [importActivityInspection, { data: importedData, error: importError, isLoading: importLoading }] = useImportActivityInspectionMutation();
    const [showLoadingDialog, setShowLoadingDialog] = useState(false)

    const onSave = async (file) => {
        setShowLoadingDialog(true);
        let fd = new FormData();
        fd.append("File", file);
        const res = await importActivityInspection(fd);
        if (res.error) {
            NotificationProvider.error("Failed to import inspection data");
            setShowLoadingDialog(false);
        } else {
            NotificationProvider.success("Successfully imported inspection data");
            setShowLoadingDialog(false);
        }
    }

    return (
        <>
            <DropZoneInDialog
                labelDialog={'Import Inspection Activity'}
                buttonLabel={'Import Inspection Activity'}
                onSave={onSave}
                accept={{ 'application/xlsx': ['.xlsx'], 'application/xls': ['.xls'], }}
            />
            <Dialog open={showLoadingDialog} className={classes.dialog}>
                <DialogContent>
                    <div className={classes.loadingwrapper}>
                        <CircularProgress className={classes.LoadingSpinner} />
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default ImportInspectionActivity;