import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@material-ui/core';
import { EntityAuditTooltip } from 'components/EntityAudit/EntityAuditTooltip';
import NotificationProvider from 'components/NotificationProvider';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import { useEffect, useState } from 'react';
import { useAddNoteMutation, useEditNoteMutation } from './notesSlice';

const NoteEditor = ({ note, onClose }) => {
    const [changed, setChanged] = useState(false);
    const [saving, setSaving] = useState(false);
    const [noteToEdit, setNoteToEdit] = useState({});
    const [addNote] = useAddNoteMutation();
    const [saveNote] = useEditNoteMutation();

    useEffect(() => {
        setNoteToEdit({ ...note });
    }, [note]);

    const onChange = (field, value) => {
        setNoteToEdit({ ...noteToEdit, [field]: value });
    }

    const onSave = async () => {
        setSaving(true);
        let res;
        if (noteToEdit.id) {
            res = await saveNote(noteToEdit);
        } else {
            res = await addNote(noteToEdit);
        }
        if (res.error) {
            NotificationProvider.error('Failed to save the note');
        } else {
            NotificationProvider.success('Successfully save the note');
            onClose();
        }
        setSaving(false)
    }

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle>
                {note?.id ? 'Edit Note' : 'Add Note'}
                {note?.id && <EntityAuditTooltip entity={note} />}
            </DialogTitle>
            <DialogContent>
                <RichTextEditor onChange={(val) => onChange('content', val)} content={noteToEdit?.content} />
                <div>
                    <FormControlLabel
                        control={<Checkbox checked={noteToEdit?.viewByClient}
                            onChange={(e, value) => onChange('viewByClient', value)} />}
                        label="Can be viewed by client" />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} >
                    Cancel
                </Button>
                <Button disabled={saving} onClick={onSave} variant="contained" color='primary'>
                    {saving ? 'Saving...' : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default NoteEditor;