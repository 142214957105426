import LoadingSpinner from "../../../components/LoadingSpinner";
import { orderBy, first, groupBy } from "lodash";
import DataTable from "../../../components/DataTable";
import React, { useState } from "react";
import { useStyles } from "../../activity/ActivityReportPageStyles";
import { useGetLotsQuery } from "../../lots/lotSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAdmin, selectIsClient } from "../../user/userSlice";
import { useGetClientsQuery } from "../../clients/clientSlice";
import DataListHeader from "../../../components/DataListHeader";
import { setSearchVal, setFilters } from './issuesTrackerFiltersSlice';
import FilterService from "../../../services/filterService";
import useSortOptions from "../../../hooks/useSortOptions";
import PageComponent from "../../../components/Page/PageComponent";
import { useGetAllIssuesTrackerQuery, useGetStatusIssueTrackerQuery, useBulkUpdateStatusMutation } from "./issuesTrackerSlice";
import { Button, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import BasicModal from "../../../components/BasicModal";
import AddIssueTracker from "./AddIssueTracker";
import { fieldTypes } from "../../../services/filterService";
import EditIcon from '@material-ui/icons/Edit';
import { useParams } from 'react-router-dom';
import { permissionProfiles } from '../../../components/Authorize/permissionProfiles';
import Authorize from '../../../components/Authorize/Authorize';
import FormatService from "services/formatService";
import ExportButton from "components/ExportButton";
import { useGetAllRelationsQuery } from "features/users/usersSlice";
import { relationTypeEnumById } from "features/users/UsersEnums";
import NotificationProvider from "../../../components/NotificationProvider";
import usePrompt from "hooks/usePrompt";
import LotsDropdown from "../../../components/SharedDropdowns/LotsDropDown/LotsDropDown";
import DropdownFilter from '../../../components/DropdownFilter';

const IssuesTrackerPage = () => {
    const classes = useStyles();
    const isAdmin = useSelector(selectIsAdmin);
    const isClient = useSelector(selectIsClient);
    const [includeIssueTrackerHistory, setIncludeIssueTrackerHistory] = useState(false);
    let { data: issuesTracker, error: issuesTrackerError, isFetching: isLoadingIssuesTracker } = useGetAllIssuesTrackerQuery({ includeIssueTrackerHistory });
    let { data: statusIssuesTracker, error: statusIssuesTrackerError, isFetching: isLoadingStatusIssuesTracker } = useGetStatusIssueTrackerQuery();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null, { skip: !isAdmin });
    let { data: allRelations, error: allRelationsError, isLoading: isLoadingAllRelations } = useGetAllRelationsQuery();
    let [bulkUpdateStatus] = useBulkUpdateStatusMutation();

    const { searchVal, filters } = useSelector(state => state.issuesTrackerFilters);
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    let { param } = useParams();
    const [selected, setSelected] = useState([]);
    const { triggerPrompt } = usePrompt();
    const [statusIds, setStatusIds] = useState([]);

    issuesTracker = issuesTracker || [];
    lots = lots || [];
    clients = clients || [];
    statusIssuesTracker = statusIssuesTracker || [];
    allRelations = allRelations || [];

    let regionalManagers = [... new Set(allRelations?.filter(relation => relation.relationType === relationTypeEnumById.REGIONAL_MANAGER))];
    const groupedToUserId = groupBy(regionalManagers, 'userId');
    const dropdownValues = Object.keys(groupedToUserId).map((key) => ({ id: key, name: groupedToUserId[key]?.[0].userName }));

    if (param) {
        let paramLot = param.split("-");
        if (paramLot[0] === "lot") {
            lots = lots.filter(x => x.id == paramLot[1]);
            issuesTracker = issuesTracker.filter(x => x.lotId == paramLot[1]);
        }
        else if (paramLot[0] === "asset") {
            issuesTracker = issuesTracker.filter(x => x.assetId == paramLot[1]);
        }
    }

    let issueTracker = {
        description: "",
        lotId: null,
        statusId: 1,
        assetId: "",
        clientId: first(clients)?.id
    }

    issuesTracker = issuesTracker.map(a => ({
        ...a,
        formattedDate: FormatService.formatDateTime(a.createdDate),
        lotName: lots?.find(l => l.id == a.lotId)?.name,
        regionalManager: regionalManagers?.filter(r => r.lotId === a.lotId)?.map(r => r.userId)
    }));
    const [issuesTrackerObj, setIssuesTrackerObj] = useState(issueTracker);

    const isLoading = () => isLoadingLots || isLoadingClients;

    const onSelectRow = (id) => {
        let newValue = [...selected];
        if (selected.includes(id)) {
            newValue?.splice(selected.indexOf(id), 1);
        } else {
            newValue.push(id);
        }
        setSelected(newValue)
    }

    const onSave = async () => {
        triggerPrompt({
            title: 'Bulk Update Status',
            content: `You are about to update the status, are you sure?`,
            onConfirm: async () => {
                const res = await bulkUpdateStatus({
                    issueTrackerIds: selected
                });
        
                if (res?.error) {
                    NotificationProvider.error('Failed to bulk update the status');
                } else {
                    NotificationProvider.success('Successfully updated the status');
                    setSelected([]);
                }
            },
        });
    }

    let issueTrackerColumns = [
        { name: "", key: "id", width: 50, selectedByDefault: true, component: (id) => <Checkbox checked={selected.includes(id)} onChange={(e) => { onSelectRow(id) }} /> },
        { name: "Lot", key: "lotId", values: lots, selectedByDefault: true },
        { name: "Status", key: "statusDescription", selectedByDefault: true },
        { name: "Created Date", key: "formattedDate", sortKey: 'createdDate', selectedByDefault: true },
        { name: "Asset ID", key: "assetId", selectedByDefault: true },
        //{ name: "Issue Type", key: "issueType", selectedByDefault: true },
        { name: "Description", key: "description", selectedByDefault: true }
    ];

    if (!isAdmin) {
        issueTrackerColumns = issueTrackerColumns.filter(c => !c.requireAdmin);
    }

    const handleFilterChange = (key, value) => {
        if (key == 'statusId')
            setStatusIds(value);

        console.log("filters", filters);
        dispatch(setFilters({ ...filters, ['statusId']: {value} }));
    }

    let filterConfig = [
        { type: 'text', size: 2, title: 'Client', key: 'clientId', values: clients, requireAdmin: true },
        { type: fieldTypes.LOT, size: 2, title: 'Lot', key: 'lotId', values: lots },
        {
            type: fieldTypes.COMPONENT,
            size: 2,
            title: 'Status',
            key: 'statusId',
            component: <DropdownFilter
                title={'Status'}
                identifier={'status'}
                values={[...(statusIssuesTracker ?? [])]}
                onSelect={(val) => handleFilterChange('statusId', val)}
                value={statusIds}
                showEmpty={true}
                multiple={true}
                required={false} />
        },
    ];

    if (!isAdmin) {
        filterConfig = filterConfig.filter(f => !f.requireAdmin);
    }

    if (!isClient) {
        filterConfig.push({ type: fieldTypes.VALUE_LIST, size: 3, title: 'Regional Manager', key: 'regionalManager', values: dropdownValues })
    }

    const [sortOptions, sortData] = useSortOptions();

    const searchIssuesTracker = value => {
        dispatch(setSearchVal(value.trim()));
    };

    const onDropFilter = (value, prop) => {
        console.log("filters22", filters);
        dispatch(setFilters({ ...filters, [prop]: { value } }));
    };

    const filteredIssuesTracker = FilterService.filter(filters, searchVal, issuesTracker, filterConfig);

    if (isLoading()) {
        return <LoadingSpinner loading={true} />;
    }
    const handleClose = () => setOpenModal(false);

    const onAddNewIssueTracker = () => {
        setIssuesTrackerObj(issueTracker);
        setOpenModal(true);
    }

    const onEdit = id => {
        setIssuesTrackerObj(issuesTracker.find(x => x.id === id));
        setOpenModal(true);
    };

    const rowActions = (rowId) => ([
        {
            component: <Authorize profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_CREATE}><EditIcon onClick={() => onEdit(rowId)} /></Authorize>,
        }
    ])

    const mapExportData = row => {
        const issues = {};
        issueTrackerColumns?.map(col => {
            issues[col.name] = row?.[col.key]
        });
        issues['Lot'] = row?.['lotName'];
        return issues;
    };

    return (
        <PageComponent header={
            <DataListHeader
                title={'Issue Tracker'}
                data={issuesTracker}
                dropDownsConfig={filterConfig}
                filters={filters}
                onDropFilter={onDropFilter}
                onSearch={searchIssuesTracker}
                searchVal={searchVal}
                actionButtons={
                    <>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Authorize profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_CREATE}>
                                    <FormControlLabel
                                        control={<Checkbox checked={includeIssueTrackerHistory}
                                        onChange={(e, value) => setIncludeIssueTrackerHistory(value)}/>}
                                        label="Include History" />
                                </Authorize>
                            </Grid>
                            <Authorize profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_CREATE}>
                                <Grid item>
                                    <Button variant="contained" color='primary' disabled={!selected?.length} onClick={onSave}>Bulk Resolve</Button>
                                </Grid>
                            </Authorize>
                            <Grid item>
                                <ExportButton label='Export' fileLabel="Export" data={issuesTracker} mapExportData={mapExportData} />
                            </Grid>
                            <Authorize profile={permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_CREATE}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={onAddNewIssueTracker}>
                                        Add Issue
                                    </Button>
                                </Grid>
                            </Authorize>
                        </Grid>
                    </>
                }
            />}>
            <DataTable
                columns={issueTrackerColumns}
                rows={orderBy(
                    filteredIssuesTracker,
                    sortOptions.columnToSort,
                    sortOptions.sortDirection
                )}
                rowIdentifier='id'
                onSort={sortData}
                sortDirection={sortOptions.sortDirection}
                columnToSort={sortOptions.columnToSort}
                noItemsMessage='There are no activities matching this criteria'
                actions={
                    rowActions
                }
            />
            <BasicModal
                open={openModal}
                wide={true}
                header={`Issue Tracker`}
                handleClose={handleClose}
                component={<AddIssueTracker handleClose={handleClose} issueTracker={issuesTrackerObj} />} //clientId={clientIdParameter}
            />
        </PageComponent>
    );
};

export default IssuesTrackerPage;