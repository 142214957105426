import { Grid, Typography } from "@material-ui/core";
import ExportButton from "components/ExportButton";
import { IconDownload } from 'icons';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { mechanicalDashboardStyles } from "../MechanicalDashboardStyles";

const SlaChartCard = ({ mechanicalActivities, clients, activityTypes }) => {

    const classes = mechanicalDashboardStyles();

    const cleaningType = activityTypes.find(a=>a.name == 'Cleaning');
    const filteredMechanicalActivities = mechanicalActivities.filter(a=>a.activityTypeId != cleaningType?.id);
    const withinSlaCount = filteredMechanicalActivities?.filter(a => a.withinSla)?.length;
    const fraction = Math.round(withinSlaCount / filteredMechanicalActivities?.length);
    const withinSlaPercentage = (fraction * 100);

    const SLAData = [
        { name: "Within SLA", value: withinSlaPercentage },
        { name: "Above SLA", value: 100 - withinSlaPercentage },
    ];

    const groupedActivities = filteredMechanicalActivities?.reduce((accumulator, activity) => {
        const { clientId, withinSla } = activity;

        const existingClient = accumulator.find(item => item.clientId === clientId);
        if (existingClient) {
            existingClient[withinSla ? 'withinSla' : 'aboveSla'].push(activity);
        } else {
            const newClient = {
                clientId,
                withinSla: withinSla ? [activity] : [],
                aboveSla: withinSla ? [] : [activity],
            };
            accumulator.push(newClient);
        }

        return accumulator;
    }, []);

    const mapExportData = row => {
        return ({
            'Client': clients?.find(c => c.id == row.clientId)?.name,
            'Within SLA': row.withinSla.length,
            'Above SLA': row.aboveSla.length
        })
    };

    const COLORS = ['#b6ed7b', '#ee9492'];

    const renderColorfulLegendText = (value) => {
        return (
            <span style={{ color: "#596579", fontWeight: 500, padding: "10px" }}>
                {value}
            </span>
        );
    };

    const CustomTooltip = ({ payload }) => {
        return (
            <div>
                <div className={classes.tooltip} >
                    <p>
                        <b>{payload?.[0]?.payload?.name} </b>
                        {payload?.[0]?.payload?.value}%
                    </p>
                </div>
            </div>
        );
    };

    return (
        <Grid container direction="column" className={classes.graphWrapper} >
            <Grid item>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid className={classes.titleContainer}>
                        <img src="/icons/icon-inspect.svg" className={classes.icon} />
                        <Typography className={classes.subTitle}>Activities Within SLA</Typography>
                    </Grid>
                    <Grid item justifyContent="flex-end">
                        <ExportButton btnStyle='text' label="" icon={<IconDownload />} fileLabel="Export" data={groupedActivities} mapExportData={mapExportData} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
                <ResponsiveContainer >
                    <PieChart>
                        <Pie
                            data={SLAData}
                            dataKey="value" nameKey="name"
                            cx={"50%"} cy={"50%"}
                            innerRadius={50} outerRadius={90}
                            paddingAngle={0.5} >
                            {SLAData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Legend
                            height={36}
                            iconType="circle"
                            layout="vertical"
                            verticalAlign="middle"
                            iconSize={10}
                            padding={5}
                            formatter={renderColorfulLegendText}
                            wrapperStyle={{ top: 200, left: 15 }}
                        />
                        <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                </ResponsiveContainer>
            </Grid>
        </Grid>
    )
}

export default SlaChartCard;