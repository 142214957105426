import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import LoadingSpinner from "components/LoadingSpinner";
import { useDeleteActivityTypeMutation } from "features/activity/activitySlice";
import NotificationProvider from "components/NotificationProvider";

const DeleteActivityType = ({ open, handleClose, activityTypeToDelete, isLoading, canDeleteResponse, setCanDeleteResponse }) => {
    
    const [deleteActivityType] = useDeleteActivityTypeMutation();

    const handleDelete = async () => {
        var result = await deleteActivityType(activityTypeToDelete?.id)
    
        setCanDeleteResponse(null);

        if (result && !result.error) {
            NotificationProvider.success('Activity deleted successfully');
            handleClose();
        } else {
            NotificationProvider.error("Something happened, try again or contact support");
            handleClose();
        }
    };

    return (
        <Dialog open={open} close={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form_dialog_title">Delete Activity Type</DialogTitle>
            <DialogContent>
                {
                    isLoading ? <> 
                        <LoadingSpinner loading={isLoading} />
                        <div>{"Checking for open requests"}</div>
                    </> 
                    : canDeleteResponse == false ? 
                        <div>{"This activity type cannot be deleted since there are pending activities of this type"}</div>
                        : <div>{"Are you sure you want to delete this activity type?"}</div> 
                }                              
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                { canDeleteResponse ?
                    <Button onClick={handleDelete} color="primary">
                        Delete
                    </Button> : null
                }
            </DialogActions>
        </Dialog>
    );
}

export default DeleteActivityType;