import React, { useEffect, useState } from 'react';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder/EmptyPlaceholder';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import FadeInImage from '../../../vehicles/components/FadeInImage/FadeInImage';
import { useGetLoadAttachmentsQuery, useGetLoadBOLQuery, useGetLoadLocationsQuery } from '../../loadsSlice';
import { loadAttachmentsStyle } from './LoadAttachmentsStyle';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import PDFJSWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';
import { Grid, useMediaQuery } from '@material-ui/core';
import mobileBreakpoint from "../../../../utils/mobileBreakpoint";

function LoadAttachments({ load, placeholder, showLoading = true, hasTitle }) {
    let { data: loadAttachments, error, isLoading } = useGetLoadAttachmentsQuery(load?.id);

    const classes = loadAttachmentsStyle()
    const [galleryUrl, setGalleryUrl] = useState();
    const [filteredAttachments, setFilteredAttachments] = useState([]);
    const isMobile = useMediaQuery(mobileBreakpoint);

    pdfjsLib.GlobalWorkerOptions.workerSrc = PDFJSWorker;
    const photoGalleryName = 'Pickup Photo Gallery';
    const photoGalleryNameFromServer = 'gallery_url';

    useEffect(() => {
        let url = galleryUrl;
        if (loadAttachments?.length) {
            const att = loadAttachments?.filter(a => a.originalFileName !== photoGalleryNameFromServer) ?? [];
            setFilteredAttachments([...att?.filter(a => a.originalFileName !== photoGalleryName), { originalFileName: photoGalleryName, fileUrl: url }]);
        }
    }, [galleryUrl]);

    // const getGalleryUrl = () => {
    //     const bol = loadAttachments?.find(a => a.originalFileName == photoGalleryNameFromServer);
    //     if (bol) {
    //         let pdfData = atob(bol.fileUrl);
    //         let doc = pdfjsLib?.getDocument({ data: pdfData });
    //         doc?.promise
    //             .then(function (doc) {
    //                 let page = doc.getPage(1);
    //                 page.then(function (page) {
    //                     let textContent = page.getTextContent();
    //                     textContent.then(function (text) {
    //                         let found = false;
    //                         for (const item of text.items) {
    //                             if (found && item.str.includes('https')) {
    //                                 setGalleryUrl(item.str);
    //                                 found = false;
    //                                 return;
    //                             }
    //                             if (item.str == "Vehicle Photos") {
    //                                 //The next item will be the gallery url
    //                                 found = true;
    //                             }
    //                         }
    //                     });
    //                 })
    //             });
    //     }
    // }

    useEffect(() => {
        if (loadAttachments?.length) {
            const att = loadAttachments?.filter(a => a.originalFileName !== photoGalleryNameFromServer) ?? [];
            setFilteredAttachments(att);
            // getGalleryUrl();
        }
    }, [loadAttachments]);

    // if (!galleryUrl) {
    //     getGalleryUrl();
    // }

    const groupedAttachmentsByDate = {};

    filteredAttachments.forEach(attachment => {
        let date = new Date(attachment.createTime).toLocaleString("default", { year: 'numeric', month: 'long', day: 'numeric', });
        if (!groupedAttachmentsByDate[date]) groupedAttachmentsByDate[date] = [];
        groupedAttachmentsByDate[date].push(attachment);
    });

    let attachmentsDates = Object.keys(groupedAttachmentsByDate);
    attachmentsDates = attachmentsDates.sort((a, b) => (new Date(a) - new Date(b)));


    return (
        <div className={classes.attachmentsContainer}>
            <LoadingSpinner loading={showLoading && isLoading} />
            {filteredAttachments?.length ?
                <div>
                    {hasTitle && <div className={classes.title}>{`Load Attachments (${filteredAttachments.length})`}</div>}
                    {
                        attachmentsDates.map((attachmentDate) =>
                            <Grid container direction="column">
                                <Grid item>
                                    <div>Date: {!!attachmentDate ? attachmentDate : 'Unknown'}</div>
                                </Grid>
                                <Grid container>
                                    {
                                        groupedAttachmentsByDate[attachmentDate].map((attachment, i) =>
                                            <Grid item key={i} className={isMobile && classes.imageMobile}>
                                                <FadeInImage
                                                    showFileName={true}
                                                    readonly={true}
                                                    img={{ url: attachment?.fileUrl, fileName: attachment?.originalFileName, thumbnail: attachment?.thumbnail }}
                                                    alt={attachment.originalFileName}
                                                />
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                </div> :
                placeholder ?? <EmptyPlaceholder text='no attachments' />
            }
        </div>
    );
}

export default LoadAttachments