import { Grid, Paper } from '@material-ui/core';
import { useState } from 'react';
import EditContractPopup from '../EditContractPopup';
import ReadinessConfig from '../ReadinessConfig/ReadinessConfig';
import ContractCardStyle from './ContractCardStyle';
import CheckInConfig from 'features/clients/clientConfigs/CheckIn';
import EditWorkFlowPopup from '../EditContractPopup/EditWorkFlowPopup/EditWorkFlowPopup';

const useStyles = ContractCardStyle;

const ContractCard = ({ contract, clientConfig, setClientConfig }) => {

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const componentPerConfig = {
        "checkin":
            <EditWorkFlowPopup
                contract={contract}
                open={open}
                clientConfig={clientConfig}
                name={contract.configName}
                handleClose={handleClose}
            />,
        "readiness": <ReadinessConfig
            contract={contract}
            open={open}
            clientConfig={clientConfig}
            name={contract.configName}
            handleClose={handleClose}
        />,
        "newCheckin":
            <CheckInConfig
                contract={contract}
                open={open}
                clientConfig={clientConfig}
                name={contract.configName}
                handleClose={handleClose}
            />,
        // "mechanicals": <MechanicalWorkConfig
        //     contract={contract}
        //     open={open}
        //     clientConfig={clientConfig}
        //     name={contract.configName}
        //     handleClose={handleClose}
        // />
    }

    const on = contract.isOn && contract.isOn(clientConfig);
    return (
        <>
            <Paper className={`${classes.card}`} onClick={() => setOpen(true)}>
                <Grid container alignItems='center'>
                    <Grid item xs={2} display="flex" className={classes.iconContainer}>
                        {contract.icon ?? <img src="/icons/icon-detailing.svg" alt="Transporter" />}
                    </Grid>
                    <Grid item xs={10}>
                        <div className={classes.title}>{`${contract.label} SLA`}</div>
                        {on && <div className={classes.activeWrapper}>
                            <div className={classes.activeIcon}></div>
                            <div>Active</div>
                        </div>}
                    </Grid>
                </Grid>
            </Paper>
            {
                componentPerConfig[contract.configName] ? <>{componentPerConfig[contract.configName]}</> :
                    <EditContractPopup
                        contract={contract}
                        setOpen={setOpen}
                        open={open}
                        clientConfig={clientConfig}
                        setClientConfig={setClientConfig}
                        name={contract.configName}
                    />
            }
        </>
    );
};

export default ContractCard;


