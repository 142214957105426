import { Dialog } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { IconTruck } from 'icons';
import * as React from 'react';
import { useState } from 'react';
import LoadCard from '../../features/loads/components/LoadCard';
import LoadDetails from '../../features/loads/components/LoadDetails';
import { useGetLoadsQuery } from '../../features/loads/loadsSlice';
import { loadButtonStyle } from './LoadButtonStyle';

export default function LoadButton({ latestLoadID }) {
    const classes = loadButtonStyle();
    let { data: loads, error: loadListError, isLoading: isLoadingLoads } = useGetLoadsQuery({});

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedLoad, setSelectedLoad] = useState();

    loads = loads || [];

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const loadDetails = loads?.find(x => x.id === latestLoadID)

    return (
        <div>
            {loadDetails && <Button aria-describedby={id} onClick={handleClick}>
                <IconTruck />
            </Button>}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2 }} className={classes.typography}>
                    <LoadCard load={loadDetails} />

                    <a className={classes.openDetails} onClick={() => setSelectedLoad(loadDetails)}>Open Details</a>

                    <Dialog
                        onClose={() => setSelectedLoad()}
                        open={!!selectedLoad}
                        maxWidth={'lg'}
                        fullWidth={true}
                    >
                        {
                            !!selectedLoad ? <LoadDetails load={selectedLoad} /> : <></>
                        }

                    </Dialog>

                </Typography>
            </Popover>
        </div>
    );
}
