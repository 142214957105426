import WashTask from "./components/TaskItem/TaskTypes/WashTask"
import React from "react";
import LoadsTask from "./components/TaskItem/TaskTypes/LoadsTask";
import InspectionTask from "./components/TaskItem/TaskTypes/InspectionTask";
import ArticleIcon from '@material-ui/icons/LocalShippingOutlined';
import InspectionReport from "../landing/components/KpiDashboard/navigations/KpiInspection/InspectionReports/ReportDialog/InspectionReport";
import AuditsTask from "./components/TaskItem/TaskTypes/AuditsTask";
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import MechanicalTask from "./components/TaskItem/TaskTypes/MechanicalTask";
import BuildIcon from '@material-ui/icons/Build';
import { IconTruck } from "icons";
import { MechActivityStatusEnum } from "features/landing/components/MechanicalDashboard/MechanicalEnums";

export const TaskTypes = {
    WASH: 1,
    LOADS: 2,
    Inspection: 3,
    Audits: 4,
    Mechanicals: 5,
}

export const TaskEntityTypes = {
    ASSET: 1
}

export const TaskStatuses = {
    OPEN: 1
}


export const TaskSetup = {
    [TaskTypes.WASH]: {
        label: 'Wash',
        component: (task) => <WashTask task={task} />,
        link: (task) => `/assets/detail/${task?.entityId}`,
        icon: <img src="/icons/icon-detailing.svg" />
    },
    [TaskTypes.LOADS]: {
        label: 'Transport',
        component: (load) => <LoadsTask task={load} />,
        link: (task) => `/assets/detail/${task?.assets[0].id}`,
        icon: <IconTruck />
    },
    [TaskTypes.Inspection]: {
        label: 'Inspection',
        component: (load) => <InspectionTask task={load} />,
        link: (task) => ``,
        icon: <img src="/icons/icon-inspect.svg" />,
    },
    [TaskTypes.Audits]: {
        label: 'Audit',
        component: (audit) => <AuditsTask task={audit} />,
        link: () => `/assets/audit`,
        icon: <AssignmentTurnedInOutlinedIcon />,
    },
    [TaskTypes.Mechanicals]: {
        label: 'Mechanicals',
        component: (a) => <MechanicalTask task={a} />,
        link: (task) => `/mechanical/${MechActivityStatusEnum.PENDING_APPROVAL}?vin=${task?.vehicle?.descriptor ?? task?.vehicle?.assetId}`,
        icon: <BuildIcon />,
    }
}