import { makeStyles } from "@material-ui/core";

export const vehicleActivityLogStyle = makeStyles((theme) => ({
    activityItem: {
        borderBottom: '1px solid #e0e0e0',
        padding: '1em 0 1em 2em',
        position: "relative",
        backgroundColor: 'transparent',
        transition: 'background-color 0.2s ease',
    },
    headerRow: {
        borderBottom: '1px solid #e0e0e0',
        fontWeight: '500',
        padding: '1em 0 1em 2em',
    },
    collapseWrapper: {
        width: '100%'
    },
    expandedGroup: {
        boxShadow: '1px 2px 7px #ddd',
        backgroundColor: '#f4f4f4'
    },
    expandIcon: {
        // animation: "$spin 1s 1",
        position: "absolute",
        left: '-0.1em'
    },
    expanded: {
        transform: "rotate(90deg)",
    },
    expandedRow: {
        fontWeight: 600,
        marginBottom: '1em'
    },
    activityLogHeader: {
        color: theme.palette.primary.main,
        padding: '2px'
    },
    activityCell: {
        padding: '2px'
    },
    actionButton: {
        padding: '5px',
    }
}));