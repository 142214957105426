import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { storageInvoiceStyles } from "./style";
import { useState } from "react";
import CustomInput from "components/CustomInput";
import NotificationProvider from "components/NotificationProvider";
import { useUpdateStorageInvoiceDetailsMutation } from "../../../storageInvoiceSlice.ts";
import { storageDetailsColumns } from "features/invoice/storage/storageInvoiceConsts";

const EditStorageInvoiceDetail = ({ invoice, open, setOpen, invoiceToEdit, setInvoiceToEdit }) => {
    const classes = storageInvoiceStyles();

    const [updateStorageInvoice, { isLoading }] = useUpdateStorageInvoiceDetailsMutation();

    const [isSaving, setIsSaving] = useState(false);

    const onChangeValue = (key, val) => {
        setInvoiceToEdit({ ...invoiceToEdit, [key]: val });
    }

    const onClose = () => {
        setOpen(false);
        setInvoiceToEdit(invoice);
    }
    const save = async () => {
        setIsSaving(true)
        let result = await updateStorageInvoice(invoiceToEdit);
        if (result && !result.error) {
            NotificationProvider.success("Invoice updated successfully");
        } else {
            NotificationProvider.error("Failed to update the invoice");
        }
        onClose()
        setIsSaving(false)
    }


    return (
        <>
            <Dialog open={open} onClose={onClose} aria-labelledby='dialog-title'>
                <DialogTitle id={'dialog-title'} >Edit Storage Invoice Details</DialogTitle>
                <DialogContent>
                    {storageDetailsColumns?.map((col) =>
                        col.editable == true &&
                        <div className={classes.input}>
                            <CustomInput
                                id={col.key}
                                label={col.label}
                                value={invoiceToEdit?.[col.key]}
                                numberOnly
                                required={false}
                                onChange={val => onChangeValue(col.key, val)}
                            />
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} disabled={isSaving} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={save} disabled={isSaving} color='primary' variant="contained">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default EditStorageInvoiceDetail;