const { Grid } = require("@material-ui/core");
const { virtualLotStyle } = require("./style");

const Legend = ({

}) => {
    const classes = virtualLotStyle();
    const legend = [
        {
            key: 'leased',
            label: 'Leased'
        },
        {
            key: 'occupied',
            label: 'Occupied'
        },
        {
            key: 'overage',
            label: 'Overage'
        },
        {
            key: 'empty',
            label: 'Available'
        }
    ];
    return (
        <Grid container className={classes.legend} spacing={1} >
            {legend.map(l =>
                <Grid item key={l.key} alignItems='center'>
                    <Grid container alignItems='center' >
                        <Grid item className={`${classes.legendIcon} ${classes[`${l.key}Legend`]}`}></Grid>
                        <Grid item>{l.label}</Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

export default Legend;