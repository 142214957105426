import { modes } from "../modes";
import { roles } from "../roles";

export const mechanicalsPermissionProfile = {
    VIEW_CONFIG: {
        [modes.EDIT]: [roles.OWNER],
        [modes.READONLY]: [roles.CLIENT, roles.ADMIN, roles.ACCOUNT_MANAGER]
    },
    SAVE_CONFIG: {
        [modes.EDIT]: [roles.OWNER],
    },
    VIEW_ACTIVITY_DATA: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.ACCOUNT_MANAGER],
    },
}