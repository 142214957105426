import { makeStyles } from "@material-ui/core";

export const lotsPageStyle = makeStyles((theme) => ({
    viewToggle: {
        backgroundColor: '#f3f5f6',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
}));
