import { Grid, Paper } from '@material-ui/core';
import { useRef, useState } from 'react';
import FadeInImage from '../../features/vehicles/components/FadeInImage/FadeInImage';
import { IconImage } from '../../icons';
import DropdownFilter from '../DropdownFilter';
import { addPhotoStyle } from './AddPhotoStyle';

const AddPhoto = ({
    newImages,
    existingImages,
    addImage,
    changeImageTypeId,
    imageTypes,
    label = 'Add Photo',
    onDelete,
    limit,
    accept = 'image/*'
}) => {
    const classes = addPhotoStyle();
    const [photosToShow, setPhotosToShow] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    //Contains just URLs for displaying IMGs
    const [previews, setPreviews] = useState([]);
    const inputFile = useRef(null);

    const changeShowMoreStatus = () => previews.length > 3 && setShowMore(!showMore);

    const selectPhoto = () => {
        inputFile.current.click();
    };

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFiles(undefined)
            return
        }

        let files = [...e.target.files];
        files.forEach(file => {
            let url = URL.createObjectURL(file);
            addImage({ url, file, imageTypeId: null });
        });
    };

    let allImages = [];
    allImages = allImages.concat(existingImages.map(i => i) || []);
    allImages = allImages.concat(newImages?.map(i => i));

    const onSelectImageType = (selectedVal, identifier) => {
        if (selectedVal >= existingImages.length) {
            newImages[selectedVal - existingImages.length].imageTypeId = identifier;
        }
        else {
            let image =
            {
                id: existingImages[selectedVal].id,
                url: existingImages[selectedVal].url,
                imageTypeId: identifier
            };
            changeImageTypeId(image);
        }
    };

    return (
        <>
            <Grid container spacing={3} className={classes.container}>
                {allImages && allImages.map((image, index) => (
                    <Grid key={index} item xs={3}>
                        <FadeInImage
                            img={image}
                            alt={`Image`}
                            onDelete={onDelete}
                        />
                        {imageTypes && !image.isExternal && <DropdownFilter title={"Image type"} identifier={index}
                            value={image.imageTypeId} showEmpty={true}
                            values={imageTypes} onSelect={val => onSelectImageType(index, +val)} />}

                    </Grid>
                ))}
                {(!limit || limit > (allImages?.length)) && <Grid item xs={3}>
                    <Paper className={classes.addPhotoContainer} onClick={selectPhoto}>
                        <IconImage width={30} height={30} />
                        {label}
                    </Paper>
                    <input 
                        type='file' 
                        ref={inputFile} 
                        multiple={true} 
                        style={{ display: 'none' }} 
                        accept={accept} 
                        onChange={(event)=> { 
                            onSelectFile(event) 
                            event.target.value=null
                        }} />
                </Grid>}
            </Grid>
        </>
    )
}

export default AddPhoto
