import React, { useEffect } from "react";
import { Button, ButtonGroup, Checkbox, FormControlLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from "../../../../components/CustomInput";
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import { Grid, Paper } from "@material-ui/core";
import { lotDialogStyle } from "./LotDialogStyle";
import Authorize from "../../../../components/Authorize";
import PhoneInput from "../../../../components/PhoneInput";
import DateTimeComponent from '../../../../components/DateTimeSchedule/DateScheculeComponent';
import moment from 'moment';
import ServiceContacts from "./ServiceContacts/ServiceContacts";
import { EntityAuditTooltip } from "components/EntityAudit/EntityAuditTooltip";
import Validator from "services/validator";
import DropdownFilter from "components/DropdownFilter";


const LotDialog = ({ lot, onSave, onCancel, saving }) => {
    const classes = lotDialogStyle();

    const [lotToEdit, setLotToEdit] = React.useState();
    const [schedules, setSchedules] = React.useState([]);
    const [serviceContacts, setServiceContacts] = React.useState([]);
    const [isTimeAvailable, setTimeAvailable] = React.useState(true);
    const [isTimeAvailableBetweenDays, setIsTimeAvailableBetweenDays] = React.useState(true);

    let data = {
        dayOfWeekId: 1,
        openTime: "00:00:00",
        closeTime: "01:00:00",
        createdDate: new Date(),
        lotId: 0,
        id: 0,
        deletedDate: null
    };

    let daysOfWeekList = [
        { id: 1, name: "Sunday" },
        { id: 2, name: "Monday" },
        { id: 3, name: "Tuesday" },
        { id: 4, name: "Wednesday" },
        { id: 5, name: "Thrusday" },
        { id: 6, name: "Friday" },
        { id: 7, name: "Saturday" }
    ];

    const handleCallback = (childData, key) => {
        const newSchedule = [...schedules];
        let sCopy = { ...newSchedule[key] };
        let validateResult = validateTime(childData, key);

        if (validateResult) {
            sCopy.dayOfWeekId = parseInt(childData.dayOfWeekId);
            sCopy.openTime = childData.openTime;
            sCopy.closeTime = childData.closeTime;
            newSchedule[key] = sCopy;
        }

        setSchedules(newSchedule);
        setLotToEdit({ ...lotToEdit, Schedule: newSchedule });
    }

    const removeHandleCallback = (key) => {
        const newSchedules = [...schedules];
        let sCopy = { ...newSchedules[key] };
        sCopy.deletedDate = new Date();
        newSchedules[key] = sCopy;
        setTimeAvailable(true);
        setIsTimeAvailableBetweenDays(true);
        setSchedules(newSchedules);
        setLotToEdit({ ...lotToEdit, Schedule: newSchedules });
    }

    const addSchedule = () => {
        const newObj = [...schedules, data];
        setSchedules(newObj);
        setLotToEdit({ ...lotToEdit, Schedule: newObj });
    }

    const handleSave = async () => {
        let lotToSave = lotToEdit
        let services = lotToEdit?.services?.filter(l => l?.deletedDate === null)
        lotToSave = { ...lotToSave, services: services }
        await onSave(lotToSave);
    };

    const onChange = (field, val) => {
        setLotToEdit({ ...lotToEdit, [field]: val })
    };
    const getTimeFormated = (time) => {
        let fullTime = moment(time, "H:m:ss");
        return fullTime.format("HH:mm");
    }

    const validateTime = (currentSchedule, key) => {
        setTimeAvailable(true);
        if (getTimeFormated(currentSchedule.openTime) >= getTimeFormated(currentSchedule.closeTime)) {
            setTimeAvailable(false);
            return false;
        }

        let result = true;
        setIsTimeAvailableBetweenDays(true);
        schedules.map((item, index) => {
            if (index !== key && parseInt(item.dayOfWeekId) == parseInt(currentSchedule.dayOfWeekId) && item.deletedDate === null) {
                if ((getTimeFormated(currentSchedule.openTime) >= getTimeFormated(item.openTime) &&
                    getTimeFormated(currentSchedule.openTime) <= getTimeFormated(item.closeTime)) ||
                    (getTimeFormated(currentSchedule.closeTime) >= getTimeFormated(item.openTime) &&
                        getTimeFormated(currentSchedule.closeTime) <= getTimeFormated(item.closeTime))) {
                    setIsTimeAvailableBetweenDays(false);
                    result = false;
                }
            }
        })

        return result;
    }

    function TimeMessage() {
        return isTimeAvailable ? <span></span> : <span>Please validate hours. Open bigger than close time</span>
    }

    function SecondTimeInOtherDayRangeTimeMessage() {
        return isTimeAvailableBetweenDays ? <span></span> : <span>Please validate hours. Times are overlaping with other rows with the same day.</span>
    }

    useEffect(() => {
        setLotToEdit(lot);
        setSchedules([...lot?.schedule || []]);
        setServiceContacts([...lot?.services || []])
        return () => {

        }
    }, [lot]);

    const textField = (field) => (
        <>

            <CustomInput
                id={field.name}
                label={field.label}
                value={lotToEdit?.[field.name] ?? ''}
                elementType="input"
                onChange={val => onChange(field.name, val)}
                required={field.required}
                numberOnly={field.numberOnly}
            />
            {
                field.valid === false &&
                <p className={classes.invalid}>{field.invalidMessage}</p>
            }

        </>
    );

    const basicFields = [
        {
            name: 'name',
            label: 'Name',
            required: true
        },
        {
            name: 'sku',
            label: 'SKU',
            profile: permissionProfiles.LOT.LOT_EDIT_CREATE,
            required: true
        },
        {
            name: 'pmfAlias',
            label: 'Alias',
            required: true
        },
        {
            name: 'addressLine1',
            label: 'Addres',
            required: true
        },
        {
            name: 'city',
            label: 'City',
            required: true
        },
        {
            name: 'state',
            label: 'State',
            required: true
        },
        {
            name: 'zipCode',
            label: 'Zip Code',
            required: true
        },
        {
            name: 'phoneNumber',
            label: 'Phone Number',
            component: <PhoneInput label='Phone Number' value={lotToEdit?.['phoneNumber']} onChange={(val) => onChange('phoneNumber', val)} />,
        },
        {
            name: 'numberOfSpots',
            label: 'Number of Spots',
            numberOnly: true,
            required: true
        },
        {
            name: 'size',
            label: 'Size in Acres',
            numberOnly: true
        },
        {
            name: 'carSpotsCount',
            label: 'Count of Car Spots',
            numberOnly: true
        },
        {
            name: 'truckSpotsCount',
            label: 'Count of Truck Spots',
            numberOnly: true
        },
        {
            name: 'auditDay',
            label: 'Audit Day',
            component: <DropdownFilter
                title='Audit Day'
                identifier='auditDay'
                values={daysOfWeekList}
                onSelect={(val) => onChange("auditDay", val === "" ? null : val)}
                value={lotToEdit?.auditDay ?? ""}
                showEmpty={true} />
        },
        {
            name: 'pmfLot',
            label: 'PMF Lot',
            component: <FormControlLabel control={<Checkbox
                title="PMF Lot"
                checked={lotToEdit?.pmfLot}
                onChange={(e, val) => onChange("pmfLot", val)}
            />}
                label="PMF Lot">
            </FormControlLabel>
        },
    ];

    const featureFields = [
        {
            name: 'officeCount',
            label: 'Count of Offices',
            numberOnly: true
        },
        {
            name: 'bathroomCount',
            label: 'Count of Bathrooms',
            numberOnly: true
        },
        {
            name: 'amentiesCount',
            label: 'Count of Amenties',
            numberOnly: true
        },
        {
            name: 'mechRoomCount',
            label: 'Count of Mechanical Rooms',
            numberOnly: true
        },
    ]


    const pricingFields = [
        {
            name: 'washPrice',
            label: 'Basic Wash Internal Cost',
            profile: permissionProfiles.LOT.LOT_EDIT_CREATE,
            numberOnly: true
        },
        {
            name: 'detailingPrice',
            label: 'Detailing Internal Cost',
            profile: permissionProfiles.LOT.LOT_EDIT_CREATE,
            numberOnly: true
        },
    ]

    const contactsFields = [
        {
            name: 'pocEmail',
            label: 'POC Email',
            profile: permissionProfiles.LOT.LOT_CONTACTS,
            valid: (Validator.validateEmail(lotToEdit?.pocEmail) || !lotToEdit?.pocEmail) ? true : false,
            invalidMessage: 'Invalid Email'
        },
        {
            name: 'services',
            label: 'Service Emails',
            component: <ServiceContacts serviceContacts={serviceContacts} setServiceContacts={setServiceContacts} lotToEdit={lotToEdit} setLotToEdit={setLotToEdit} />,
            profile: permissionProfiles.LOT.LOT_CONTACTS,
            valid: serviceContacts.every(s => s?.valid)
        },
    ]

    const fieldGroups = [
        {
            label: "Basic Information",
            fields: basicFields
        },
        {
            label: "Features",
            fields: featureFields
        },
        {
            label: "Pricing",
            fields: pricingFields,
            profile: permissionProfiles.LOT.LOT_EDIT_CREATE
        },
        {
            label: "Contacts",
            fields: contactsFields,
            profile: permissionProfiles.LOT.LOT_CONTACTS
        },
    ]

    const isValid = () => basicFields.every(field => !field.required || lotToEdit?.[field.name] || field?.valid) && contactsFields.every(field => field?.valid !== false);


    return (
        <Dialog open={!!lotToEdit} onClose={onCancel} aria-labelledby="form-dialog-title" maxWidth='sm'>
            <DialogTitle id="form-dialog-title">
                {lotToEdit?.id ? 'Lot Information' : 'Create Lot'}
                <EntityAuditTooltip entity={lotToEdit} />
            </DialogTitle>
            <DialogContent>

                {fieldGroups?.map(group =>
                    <Authorize profile={group.profile} key={group.label}>
                        <Paper className={classes.fieldGroup}>
                            <Grid container xs={12}>
                                <Grid xs={12} className={classes.groupTitle}>{group.label}</Grid>
                                {group?.fields?.map(field =>
                                    <Grid key={field.name} item xs={12} >
                                        <div className={classes.inputWrapper}>
                                            <Authorize profile={field.profile ?? permissionProfiles.LOT.LOT_DIALOG} readonlyComponent={<div>{field.label}: {lotToEdit?.[field.name]}</div>}>
                                                {field.component ??
                                                    textField(field)
                                                }
                                            </Authorize>
                                        </div>
                                    </Grid>
                                )}
                            </Grid>
                        </Paper>
                    </Authorize>
                )}
                <Paper className={classes.fieldGroup}>
                    <Grid container xs={12}>
                        {/* <button onClick={addSchedule}>Add Schedule</button> */}
                        <Grid xs={12} className={classes.groupTitle}>
                            Schedule
                            <Grid item xs={6}>
                                <ButtonGroup >
                                    <Button onClick={addSchedule} className={classes.buttonSchedule}>
                                        Add Operating Hours
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                        <TimeMessage />
                        <SecondTimeInOtherDayRangeTimeMessage />
                        <div className={classes.inputWrapper}>
                            {
                                schedules.map((item, index) => {
                                    if (item.deletedDate === null) {
                                        return <DateTimeComponent data={item}
                                            daysOfWeekList={daysOfWeekList}
                                            handleCallback={handleCallback}
                                            key={index}
                                            indexChild={index}
                                            removeHandleCallback={removeHandleCallback}
                                            valueFromAPI={item.id > 0 ? item.dayOfWeekId : 1} />
                                    }
                                })
                            }
                        </div>
                    </Grid>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Authorize profile={permissionProfiles.LOT.LOT_EDIT_CREATE}>
                    <Button
                        onClick={handleSave}
                        color="primary"
                        variant="contained"
                        disabled={saving || !isValid()}>
                        {saving ? 'Saving...' : 'Save'}
                    </Button>
                </Authorize>
            </DialogActions>
        </Dialog>
    );
};


export default LotDialog;
