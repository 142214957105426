import { IconButton } from "@material-ui/core";
import { Dialog } from "@mui/material"
import DataListHeader from "components/DataListHeader";
import DataTable from "components/DataTable";
import { useGetVehicleStatusQuery } from "features/clients/clientConfigSlice";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { useGetVehicleTypesQuery } from "features/vehicles/vehicleSlice";
import VehicleTable from "features/vehicles/VehicleTable";
import useSortOptions from "hooks/useSortOptions";
import { orderBy } from "lodash";
import { useState } from "react";
import FilterService, { fieldTypes } from "services/filterService";
import { dispatchDashboardStyle } from "../style";
import ListIcon from '@material-ui/icons/List';
import DetailsButton from "features/vehicles/DetailsButton";

export const AssetListDialog = ({ open, data, onClose }) => {
    const classes = dispatchDashboardStyle()
    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let { data: statuses, error: statusError, isLoading: isLoadingStatuses } = useGetVehicleStatusQuery();
    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes } = useGetVehicleTypesQuery();

    const [sortOptions, sortData] = useSortOptions();
    const [filters, setFilters] = useState({});
    const [search, setSearch] = useState('');
    const [showList, setShowList] = useState(false);

    const pmfLots = lots?.filter(l => l.pmfLot) ?? [];
    const filterConfig = [
        { type: fieldTypes.LOT, size: 4, title: 'Lot', key: 'lotId', values: pmfLots },
        { type: fieldTypes.TEXT, size: 4, title: 'Client', key: 'clientId', values: clients },
    ];

    let vehicleColumns = [
        { name: "VIN", key: "descriptor", selectedByDefault: true, width: 280 },
        { name: "Asset ID", key: "assetId", selectedByDefault: true, width: 200 },
        { name: "Client", key: "clientId", values: clients, requireAdmin: true, selectedByDefault: true },
        { name: "Location", key: "lotId", values: lots, selectedByDefault: true, width: 200 },
        { name: "Domicile", key: "domicileLot", values: lots, selectedByDefault: true, width: 200 },
    ];

    let filteredVehicles = FilterService.filter(filters, search, data, filterConfig);

    const onDropFilter = (filterValue, prop) => {
        setFilters({ ...filters, [prop]: { value: filterValue } });
    };

    return (
        <>
            <IconButton onClick={() => setShowList(true)}><ListIcon /></IconButton>
            <Dialog maxWidth='xl' fullWidth open={showList} onClose={() => setShowList(false)}>
                <DataListHeader
                    title={`Assets (${filteredVehicles?.length})`}
                    data={data}
                    dropDownsConfig={filterConfig}
                    filters={filters}
                    onDropFilter={onDropFilter}
                    onSearch={setSearch}
                    searchVal={search}
                />
                <div className={classes.table}>
                    <DataTable
                        columns={vehicleColumns}
                        rows={orderBy(
                            filteredVehicles,
                            sortOptions.columnToSort,
                            sortOptions.sortDirection
                        )}
                        rowIdentifier='id'
                        onSort={sortData}
                        sortDirection={sortOptions.sortDirection}
                        columnToSort={sortOptions.columnToSort}
                        actions={(rowId) => ([
                            {
                                label: 'Details',
                                component: <DetailsButton id={rowId} />
                            },

                        ])}
                        maxActionCount={1}
                    />
                </div>
            </Dialog>
        </>
    )
}
