import { Button, Grid, Paper, Tooltip } from "@material-ui/core";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomInput from '../../../../components/CustomInput';
import DatePicker from '../../../../components/DatePicker';
import NotificationProvider from '../../../../components/NotificationProvider';
import Validator from '../../../../services/validator';
import { useGetClientsWithConfigsQuery } from '../../../clients/clientConfigSlice';
import { useGetLotsQuery } from '../../../lots/lotSlice';
import { selectIsAccountManager, selectIsAdmin, selectIsClient, selectIsOwner, selectUserClientId } from '../../../user/userSlice';
import { useGetFuelTypesQuery, useGetVehicleStatusQuery, useGetVehicleTypesQuery } from '../../../vehicles/vehicleSlice';
import { calcTypeMessages, calcTypesById } from '../../enums';
import { useAddLoadMutation, useUpdateLoadMutation } from '../../loadsSlice';
import { useGetBusinessUnitsQuery } from '../../../clients/clientConfigSlice';
import formStyles from "./formStyles";
import LocationDistance from './LocationDistance';
import LocationField from './LocationField';
import VinDecode from "features/vehicles/VinDecode/VinDecodePopup";
import VinMatching from "features/vehicles/components/VinMatching/VinMatching";

const useStyles = formStyles;

const CreateLoadForm = ({ handleClose, loadToEdit }) => {

    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes, isSuccess: isVehicleTypesSuccess } = useGetVehicleTypesQuery();
    let { data: lots, error: lotError, isLoading: isLoadingLots, isSuccess: isLotsSuccess } = useGetLotsQuery();
    let { data: clientsWithConfigs, error: clientsWithConfigsError, isFetching: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery(null);
    let { data: fuelTypes, error: fuelTypesError, isLoading: isLoadingFuelTypes, isSuccess: isSuccessFuelTypes } = useGetFuelTypesQuery();
    let { data: businessUnits, error: businessUnitsError, isLoading: isLoadingbusinessUnits, isSuccess: businessUnitsSuccess } = useGetBusinessUnitsQuery();

    vehicleTypes = vehicleTypes || [];
    vehicleTypes = vehicleTypes.filter(v => [5, 7, 8, 16, 53].includes(v.id));//FIXME!!!
    clientsWithConfigs = clientsWithConfigs || [];

    let [addLoad] = useAddLoadMutation();
    let [updateLoad] = useUpdateLoadMutation();

    const isAdmin = useSelector(selectIsAdmin);
    const isOwner = useSelector(selectIsOwner);
    const isClient = useSelector(selectIsClient);
    const loggedInUserClientId = useSelector(selectUserClientId);
    const isAccountManager = useSelector(selectIsAccountManager);

    const classes = useStyles();
    const defaultDateMessage = "Please select move type";
    let [newLoad, setNewLoad] = useState({ ...loadToEdit?.assets[0], ...loadToEdit });
    let [isSaving, setIsSaving] = useState(false);
    const [originNewLot, setOriginNewLot] = useState(false);
    const [destinationNewLot, setDestinationNewLot] = useState(false);
    const [errors, setErrors] = useState({})
    const [warnings, setWarnings] = useState({})
    const [pickupMinDate, setPickupMinDate] = useState(moment(newLoad?.createdDate) || moment());
    const [deliveryMinDate, setDeliveryMinDate] = useState(moment(newLoad?.createdDate) || moment());
    const [deliveryMaxDate, setDeliveryMaxDate] = useState();
    const [pickupDateDisabled, setPickupDateDisabled] = useState(true);
    const [deliveryDateDisabled, setDeliveryDateDisabled] = useState(true);
    const [showMandatoryError, setShowMandatoryError] = useState(false);
    const [puDateMessage, setPuDateMessage] = useState(defaultDateMessage);
    const [dDateMessage, setDDateMessage] = useState(defaultDateMessage);
    const [clientConfigs, setClientConfigs] = useState();
    const [clientMoveTypes, setClientMoveTypes] = useState();
    const [clientBUs, setClientBUs] = useState([]);
    const [loadMoveType, setLoadMoveType] = useState();
    const [showWarning, setShowWarning] = useState(false);
    const [startTimer, setStartTimer] = useState(false);
    const [decodedData, setDecodedData] = useState({});
    const [colorDecoded, setColorDecoded] = useState(false);
    const [openDecoded, setOpenDecoded] = useState(false);

    const isLoading = () => isLoadingVehicleTypes || isLoadingClientsWithConfigs || isLoadingLots;

    const destinationFormulaCalc = () => Math.ceil(newLoad?.miles / 550);

    const reset = async () => {
        setNewLoad(null);
        setOriginNewLot(false);
        setDestinationNewLot(false);
        setErrors({});
        setWarnings({});
        setPickupMinDate(moment());
        setDeliveryMinDate(moment());
        setDeliveryMaxDate();
        setPickupDateDisabled(true);
        setDeliveryDateDisabled(true);
        setPuDateMessage(defaultDateMessage);
        setDDateMessage(defaultDateMessage);
        setShowMandatoryError(false);
    }

    const onSubmit = async () => {
        const validate = isValid();
        if (!validate) {
            setShowMandatoryError(true);
            return;
        }
        setIsSaving(true);
        setErrors({});
        setWarnings({});
        if (!loadToEdit) {
            let result = await addLoad(newLoad)
            if (result?.error) {
                NotificationProvider.error(result?.error?.data?.messages ? result?.error?.data?.messages[0] : `Failed to create the load`)
            } else {
                NotificationProvider.success(`Successfully created the load`);
                reset();
                document.getElementById("createLoadForm").reset();
            }
            setIsSaving(false);
        }
        else {
            let result = await updateLoad(newLoad)
            if (result?.error) {
                NotificationProvider.error(`Failed to update the load`)
            } else {
                NotificationProvider.success(`Successfully updated the load`);

                setNewLoad(null)
                document.getElementById("createLoadForm").reset();
            }
            setIsSaving(false);
            handleClose();
        }
    };

    const getWarningMessageForField = {
        'pickupStartDate': (val) => {
            const daysToPush = daysToAddByOperation(newLoad?.pickupLocationId, moment(val, "YYYY-MM-DD"), true)
            if (daysToPush != 0) {
                setShowWarning(true);
                setStartTimer(true);
                return `The selected pickup lot is not open on the selected date, the date has been postponed`;
            }
            return false;
        },
        'deliveryStartDate': (val) => {
            const daysToPush = daysToAddByOperation(newLoad?.deliveryLocationId, moment(val, "YYYY-MM-DD"), true)
            if (daysToPush != 0) {
                setShowWarning(true);
                setStartTimer(true);
                return `The selected delivery lot is not open on the selected date, the date has been brought forward`;
            }
            return false;
        }
    }

    const getErrorMessagesForField = {
        'deliveryPocEmail': (val) => {
            if (val && destinationNewLot && !Validator.validateEmail(val)) {
                return "Invalid delivery POC email";
            }
            return false;
        },
        'pickupPocEmail': (val) => {
            if (val && originNewLot && !Validator.validateEmail(val)) {
                return "Invalid pickup POC email";
            }
            return false;
        },
        'deliveryPhone': (val) => {
            if (val && destinationNewLot && !Validator.validatePhone(val)) {
                return "Invalid delivery phone";
            }
            return false;
        },
        'pickupPhone': (val) => {
            if (val && originNewLot && !Validator.validatePhone(val)) {
                return "Invalid pickup phone";
            }
            return false;
        },
        'vin': (val) => {
            if (!Validator.validateVin(val)) {
                return "Invalid VIN";
            }
            return false;
        },
        'year': (val) => {
            if (!(val && val > 1980 && val.length <= 4)) {
                return "Invalid year";
            }
            return false;
        },
        'pickupStartDate': (val) => {
            if (val && ((moment(newLoad?.createdDate) || moment()).add(loadMoveType?.minPickup, "days").format("YYYY-MM-DD") > val)) {
                setNewLoad({ ...newLoad, [`deliveryStartDate`]: null })
                return "Based on the selected move type, the pickup date calculated by the transit time does not meet the requirement of the minimum pickup date. Please choose a later delivery date";
            }
            if (loadMoveType?.maxDelivery > 0 && loadMoveType?.maxDelivery < destinationFormulaCalc()) {
                setNewLoad({ ...newLoad, [`pickupLocationId`]: null, [`deliveryLocationId`]: null })
                return "Based on the selected move type and lots, transit time does not meet the requirement of the maximum delivery date.";
            }
            if (moment(val, "YYYY-MM-DD") < (moment(newLoad?.createdDate) || moment()).startOf('day')) {
                return "Pickup date can not be in the past";
            }
            if (loadMoveType?.maxDelivery > 0 && moment(val).add(loadMoveType?.maxDelivery, "days").format("YYYY-MM-DD") < newLoad?.deliveryStartDate && newLoad?.deliveryStartDate) {
                return "Based on the selected move type and lots, pickup date calculated does not meet the requirement of the maximum delivery date.";
            }
            return false;
        },
        'deliveryStartDate': (val) => {
            if (loadMoveType?.maxDelivery > 0 && moment(val).subtract(loadMoveType?.maxDelivery, "days").format("YYYY-MM-DD") > newLoad?.pickupStartDate && newLoad?.pickupStartDate) {
                return "Based on the selected move type and lots, delivery date calculated does not meet the requirement of the maximum delivery date.";
            }
        }
    }

    const clearErrorForFields = (keys) => {
        const newErrors = { ...errors };
        keys.map(key => {
            delete newErrors[key];
        })
        setErrors(newErrors);
    };

    const onChange = (key, val) => {
        const warningMessage = getWarningMessageForField[key] && getWarningMessageForField[key](val)
        if (warningMessage) {
            setWarnings({ ...warnings, [key]: warningMessage });
        }
        const errorMessage = getErrorMessagesForField[key] && getErrorMessagesForField[key](val)
        if (errorMessage) {
            setErrors({ ...errors, [key]: errorMessage })
        }
        else {
            clearErrorForFields([key]);
        }
        setNewLoad({ ...newLoad, [key]: val });
    };

    const onChangeLocation = () => {
        onMoveTypeChange(newLoad?.configMoveType);
    };

    const onChangePickupDate = (val) => {
        if (newLoad?.deliveryStartDate) {
            onChange("pickupStartDate", val)
            setNewLoad({ ...newLoad, ["deliveryStartDate"]: null, ["pickupStartDate"]: val });
            clearErrorForFields(["pickupStartDate"]);
        }
        else {
            onChange("pickupStartDate", val)
        }
    };

    const isValid = () => {
        const res = loadToEdit ?
            !!(
                newLoad.pickupStartDate && !errors['pickupStartDate'] &&
                newLoad.deliveryStartDate && !errors['deliveryStartDate']
            )
            : !!(newLoad &&
                (newLoad.clientId || (isClient)) &&
                newLoad.vin && !errors['vin'] &&
                newLoad.assetId &&
                newLoad.make &&
                newLoad.model &&
                newLoad.year && !errors['year'] &&
                newLoad.assetTypeId &&
                (clientConfigs?.config?.transportConfig?.needByWeek ? newLoad.neededByWeek : true) &&
                (clientConfigs?.config?.transportConfig?.handoffWeek ? newLoad.handoffWeek : true) &&
                newLoad.configMoveType &&
                newLoad.pickupStartDate && !errors['pickupStartDate'] &&
                newLoad.deliveryStartDate && !errors['deliveryStartDate'] &&
                (!clientBUs?.length || newLoad.businessUnit) &&
                ((newLoad.pickupLocationId && newLoad.pickupLocationId !== 0 && !originNewLot) ||
                    (originNewLot &&
                        newLoad.pickupName &&
                        newLoad.pickupNodeName &&
                        newLoad.pickupAddress &&
                        newLoad.pickupCity &&
                        newLoad.pickupState &&
                        newLoad.pickupZip &&
                        newLoad.pickupPocEmail &&
                        newLoad.pickupPhone)) &&
                ((newLoad.deliveryLocationId && newLoad.deliveryLocationId !== 0 && !destinationNewLot) ||
                    (destinationNewLot &&
                        newLoad.deliveryName &&
                        newLoad.deliveryNodeName &&
                        newLoad.deliveryAddress &&
                        newLoad.deliveryCity &&
                        newLoad.deliveryState &&
                        newLoad.deliveryZip &&
                        newLoad.deliveryPocEmail &&
                        newLoad.deliveryPhone)))
        return res;
    };

    const setMoveTypeConfigs = () => {
        let clientConfigs = [];
        const clientId = isClient ? loggedInUserClientId : newLoad?.clientId;
        clientConfigs = clientsWithConfigs?.find(c => c.id == clientId);

        if (!clientConfigs && clientsWithConfigs.length === 1) {
            clientConfigs = clientsWithConfigs[0];
        }

        setClientConfigs(clientConfigs);
        setClientMoveTypes(clientConfigs?.loadMoveTypes);
        setLoadMoveType(clientConfigs?.loadMoveTypes?.find(m => m.id == newLoad?.configMoveType));
    };

    const daysToAddByOperation = (lotId, date, goBack = false) => {
        date = moment(date);
        if (date || lotId) {
            const lot = lots?.find(l => l.id == lotId);
            const groupedSchedule = _.groupBy(lot?.schedule, 'dayOfWeekId');

            if (goBack) {
                for (let i = 0; i < 6; i++) {
                    let localDay = date?.day() + 1 - i;
                    localDay = localDay > 7 ? ~~(localDay / 7) : localDay;
                    if (groupedSchedule?.[localDay])
                        return -i;
                }
            }
            else {
                for (let i = 0; i < 6; i++) {
                    let localDay = date?.day() + 1 + i;
                    localDay = localDay > 7 ? ~~(localDay / 7) : localDay;
                    if (groupedSchedule?.[localDay])
                        return i;
                }
            }
        }
        return 0; //open on selected day or 24/7, return selected day
    }


    const populatDecoded = () => {
        setOpenDecoded(false);
        if (decodedData) {
            setColorDecoded(true);
            /* if (decodedData?.vehicleType)
                 setColorDecodedType(true);*/

            setNewLoad({
                ...newLoad,
                'year': decodedData?.year,
                'make': decodedData?.make,
                'model': decodedData?.model,
            });
            setTimeout(() => {
                setColorDecoded(false);
            }, 5000);
        }
    }


    const setPickupMinDateForAllSchedulingLogics = (configs) => {
        let puMinDate = moment(newLoad?.createdDate) || moment();
        puMinDate.add(configs?.minPickup, 'days');
        puMinDate.add(daysToAddByOperation(newLoad?.pickupLocationId, puMinDate), 'days');
        puMinDate = moment(puMinDate).format("YYYY-MM-DD");
        setPickupMinDate(puMinDate);
    }

    const setDeliveryMinDateBySchedulingLogic = {
        [calcTypesById.NO_LOGIC]: (pickupStartDate) => {
            let dMinDate = moment(pickupStartDate);
            dMinDate.add(daysToAddByOperation(newLoad?.deliveryLocationId, dMinDate), 'days');
            dMinDate = moment(dMinDate).format("YYYY-MM-DD");
            setDeliveryMinDate(dMinDate);
        },
        [calcTypesById.PICKUP_FROM_DELIVERY]: (configs) => {
            let dMinDate = moment(newLoad?.createdDate) || moment();
            dMinDate.add(configs?.minPickup, 'days');
            dMinDate.add(daysToAddByOperation(newLoad?.pickupLocationId, dMinDate), 'days');
            dMinDate.add(destinationFormulaCalc(), 'days');
            dMinDate.add(daysToAddByOperation(newLoad?.deliveryLocationId, dMinDate), 'days');
            dMinDate = moment(dMinDate).format("YYYY-MM-DD");
            setDeliveryMinDate(dMinDate);
            const today = moment(newLoad?.createdDate) || moment().format("YYYY-MM-DD");
            const diff = moment(dMinDate).diff(today.format("YYYY-MM-DD"), 'days');
            setDDateMessage(calcTypeMessages[calcTypesById.PICKUP_FROM_DELIVERY].delivery(diff, configs?.maxDelivery));
        },
        [calcTypesById.MIN_TRANSIT_TIME]: (pickupStartDate) => {
            let dMinDate = moment(pickupStartDate, "YYYY-MM-DD");
            dMinDate.add(destinationFormulaCalc(), 'days');
            dMinDate.add(daysToAddByOperation(newLoad.deliveryLocationId, dMinDate), 'days');
            dMinDate = moment(dMinDate).format("YYYY-MM-DD");
            setDeliveryMinDate(dMinDate);
            const pickup = moment(pickupStartDate).format("YYYY-MM-DD");
            const diff = moment(dMinDate).diff(pickup, 'days');
            setDDateMessage(calcTypeMessages[calcTypesById.MIN_TRANSIT_TIME].delivery(diff, loadMoveType?.maxDelivery));
            return dMinDate;
        }
    }

    const setDeliveryMaxDateForAllSchedulingLogics = (pickupStartDate) => {
        if (loadMoveType?.maxDelivery > 0) {
            let dMaxDate = moment(pickupStartDate, "YYYY-MM-DD");
            dMaxDate.add(loadMoveType?.maxDelivery, 'days');
            dMaxDate.add(daysToAddByOperation(newLoad?.deliveryLocationId, dMaxDate, true), 'days');
            dMaxDate = moment(dMaxDate).format("YYYY-MM-DD");
            setDeliveryMaxDate(dMaxDate);
            return dMaxDate;
        }
        else {
            setDeliveryMaxDate(null);
            return null;
        }
    }

    const determineSchedulingLogic = {
        [calcTypesById.NO_LOGIC]: (configs) => {
            setPickupDateDisabled(false);
            setDeliveryDateDisabled(true);
            setPickupMinDateForAllSchedulingLogics(configs);
            setPuDateMessage(calcTypeMessages[calcTypesById.NO_LOGIC].pickup(configs.minPickup));
            setNewLoad({ ...newLoad, [`configMoveType`]: configs.id, [`pickupStartDate`]: null, [`deliveryStartDate`]: null });
            setDDateMessage("Please select pickup date");
        },
        [calcTypesById.PICKUP_FROM_DELIVERY]: (configs) => {
            setPickupDateDisabled(true);
            setDeliveryDateDisabled(true);
            setNewLoad({ ...newLoad, [`configMoveType`]: configs.id, [`pickupStartDate`]: null, [`deliveryStartDate`]: null });
            setDDateMessage("Please select locations");
            setPuDateMessage("Please select locations");
        },
        [calcTypesById.DELIVERY_FROM_PICKUP]: (configs) => {
            setPickupDateDisabled(true);
            setDeliveryDateDisabled(true);
            setPickupMinDateForAllSchedulingLogics(configs);
            setNewLoad({ ...newLoad, [`configMoveType`]: configs.id, [`pickupStartDate`]: null, [`deliveryStartDate`]: null });
            setPuDateMessage("Please select locations");
            setDDateMessage("Please select pickup date");
        },
        [calcTypesById.MIN_TRANSIT_TIME]: (configs) => {
            setPickupDateDisabled(false);
            setDeliveryDateDisabled(true);
            setPickupMinDateForAllSchedulingLogics(configs);
            setNewLoad({ ...newLoad, [`configMoveType`]: configs.id, [`pickupStartDate`]: null, [`deliveryStartDate`]: null })
            setDDateMessage("Please select pickup date");
            setPuDateMessage(calcTypeMessages[calcTypesById.MIN_TRANSIT_TIME].pickup(configs.minPickup));
        }
    }

    const onMoveTypeChange = (val) => {
        setDeliveryMinDate(null);
        setDeliveryMaxDate(null);
        setMoveTypeConfigs();
        const configs = clientMoveTypes?.find(m => m.id == val);
        if (configs) {
            if (determineSchedulingLogic[configs.calcType]) {
                clearErrorForFields(['pickupStartDate', 'deliveryStartDate']);
                determineSchedulingLogic[configs.calcType](configs);
            }
        }
    }

    useEffect(() => {
        if (startTimer) {
            setTimeout(() => {
                setShowWarning(false);
                setStartTimer(false);
                setWarnings({});
            }, 4000);
        }
    }, [startTimer]);

    useEffect(() => {
        setMoveTypeConfigs();
    }, [newLoad?.clientId, newLoad?.configMoveType, clientsWithConfigs])

    useEffect(() => {
        if (businessUnits?.length) {
            let clientId = isClient ? loggedInUserClientId : newLoad?.clientId;
            setClientBUs(businessUnits?.filter(b => b.clientId == clientId));
        }
    }, [newLoad?.clientId, businessUnits, clientsWithConfigs])


    const getLegalPickupStartDate = () => {
        let pickupStartDate = moment(newLoad?.pickupStartDate);
        //check pickup opening hours
        if (newLoad?.pickupLocationId && pickupStartDate._isValid) {
            let daysToPush = daysToAddByOperation(newLoad?.pickupLocationId, pickupStartDate, true)
            if (daysToPush != 0) {
                pickupStartDate.add(daysToPush, 'days');
                pickupStartDate = moment(pickupStartDate).format("YYYY-MM-DD");
                onChange('pickupStartDate', pickupStartDate);
            }
        }
        return moment(pickupStartDate);
    }

    const getLegalDeliveryStartDate = () => {
        let deliveryStartDate = moment(newLoad?.deliveryStartDate);

        //check delivery opening hours
        if (newLoad?.deliveryLocationId && deliveryStartDate._isValid) {
            const daysToPush = daysToAddByOperation(newLoad?.deliveryLocationId, deliveryStartDate);
            if (daysToPush != 0) {
                deliveryStartDate.add(daysToPush, 'days');
                deliveryStartDate = moment(deliveryStartDate).format("YYYY-MM-DD");
                onChange('deliveryStartDate', deliveryStartDate);
            }
        }
        return moment(deliveryStartDate);
    }

    useEffect(() => {

        getLegalPickupStartDate();
        getLegalDeliveryStartDate();

        if (loadMoveType?.calcType == calcTypesById.NO_LOGIC) {
            setPickupMinDateForAllSchedulingLogics(loadMoveType);
            setPuDateMessage(calcTypeMessages[calcTypesById.NO_LOGIC].pickup(loadMoveType?.minPickup));
            setPickupDateDisabled(false);
            //we need pickup date for claculating min and max delivery dates
            if (getLegalPickupStartDate()._isValid) {
                setDeliveryDateDisabled(false);
                setDeliveryMinDateBySchedulingLogic[calcTypesById.NO_LOGIC](getLegalPickupStartDate());
                setDeliveryMaxDateForAllSchedulingLogics(getLegalPickupStartDate());
                setDDateMessage(calcTypeMessages[calcTypesById.NO_LOGIC].delivery(loadMoveType?.maxDelivery));
            }
        }

        else if (loadMoveType?.calcType == calcTypesById.PICKUP_FROM_DELIVERY) {
            setDeliveryMinDateBySchedulingLogic[calcTypesById.PICKUP_FROM_DELIVERY](loadMoveType);
            setDeliveryDateDisabled(false);
            //we need miles for the dates caculation
            if (newLoad?.miles) {
                //once we have delivery date we could calculate pickup date, because it's based on the transit time
                if (getLegalDeliveryStartDate()._isValid) {
                    let pickupStartDate = moment(getLegalDeliveryStartDate());
                    pickupStartDate.subtract(destinationFormulaCalc(), 'days');
                    pickupStartDate.add(daysToAddByOperation(newLoad?.pickupLocationId, pickupStartDate, true), 'days');
                    pickupStartDate = moment(pickupStartDate).format("YYYY-MM-DD");
                    onChange('pickupStartDate', pickupStartDate);
                    const diff = moment(getLegalDeliveryStartDate()).diff(pickupStartDate, 'days');
                    setPuDateMessage(calcTypeMessages[calcTypesById.PICKUP_FROM_DELIVERY].pickup(diff));
                }
                else {
                    setPuDateMessage("Please select delivery date");
                }
            }
        }

        else if (loadMoveType?.calcType == calcTypesById.DELIVERY_FROM_PICKUP) {
            setPickupMinDateForAllSchedulingLogics(loadMoveType);
            setPuDateMessage(calcTypeMessages[calcTypesById.DELIVERY_FROM_PICKUP].pickup(loadMoveType?.minPickup));
            setPickupDateDisabled(false);
            //we need miles for the dates caculation
            if (newLoad?.miles) {
                //once we have pickup date we could calculate delivery date, because it's based on the transit time
                if (getLegalPickupStartDate()._isValid) {
                    let deliveryDate = moment(getLegalPickupStartDate());
                    deliveryDate.add(destinationFormulaCalc(), 'days');
                    deliveryDate.add(daysToAddByOperation(newLoad?.deliveryLocationId, deliveryDate), 'days');
                    deliveryDate = moment(deliveryDate).format("YYYY-MM-DD");
                    onChange('deliveryStartDate', deliveryDate);
                    const diff = moment(deliveryDate).diff(getLegalPickupStartDate().format("YYYY-MM-DD"), 'days');
                    setDDateMessage(calcTypeMessages[calcTypesById.DELIVERY_FROM_PICKUP].delivery(diff));
                }
            }
            else {
                setDDateMessage("Please select locations");
            }
        }

        else if (loadMoveType?.calcType == calcTypesById.MIN_TRANSIT_TIME) {
            setPickupDateDisabled(false);
            setPickupMinDateForAllSchedulingLogics(loadMoveType);
            setPuDateMessage(calcTypeMessages[calcTypesById.MIN_TRANSIT_TIME].pickup(loadMoveType?.minPickup));
            //we force the user to select pickup date first, so we could calculate delivery min and max dates
            if (getLegalPickupStartDate()._isValid) {
                //we need miles, cause calculation is based on the transit time
                if (newLoad?.miles) {
                    let dMinDate = setDeliveryMinDateBySchedulingLogic[calcTypesById.MIN_TRANSIT_TIME](getLegalPickupStartDate());
                    let dMaxDate = setDeliveryMaxDateForAllSchedulingLogics(getLegalPickupStartDate());

                    //conflict - minDelivery = (pickup + transit + weekend) > maxDelivery = (pickup + loadMoveType.maxDelivery)
                    if (dMinDate > dMaxDate) {
                        setErrors({ ...errors, [`pickupStartDate`]: "Based on the selected move type, the delivery date calculated by the transit time is later than the latest delivery allowed" })
                    }
                    setDeliveryDateDisabled(false);
                }
                else {
                    setDDateMessage("Please select locations");
                }
            }
        }

    }, [newLoad?.miles, newLoad?.pickupStartDate, newLoad?.deliveryStartDate, newLoad?.configMoveType, loadMoveType])

    const hasErrors = !!Object.values(errors)?.length;

    let filteredClients = clientsWithConfigs.filter(client => client.config.transportConfig.useTransport == true)

    let form = (
        <Grid direction='row' container >
            <Grid item xs={11}>
                <Grid container>

                    {!isClient && !loadToEdit &&
                        <Grid item xs={6}>
                            <div className={classes.input}>
                                <CustomInput
                                    id="clientId"
                                    label="Client"
                                    value={newLoad?.clientId}
                                    elementType="dropdown"
                                    onChange={val => onChange("clientId", +val)}
                                    values={filteredClients}
                                    showEmpty
                                    required
                                    touched
                                    valid={!(showMandatoryError && !newLoad?.clientId)}
                                    disabled={loadToEdit}
                                />
                            </div>
                        </Grid>}

                    {!!clientBUs?.length && <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="businessUnit"
                                label="Business Unit"
                                value={newLoad?.businessUnit}
                                elementType="dropdown"
                                onChange={val => onChange("businessUnit", +val)}
                                values={clientBUs}
                                valid={!(showMandatoryError && !newLoad?.businessUnit)}
                                showEmpty
                                required
                                touched
                            />
                        </div>
                    </Grid>}
                </Grid>
                {!loadToEdit && <Grid container>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item xs={9}>
                                    <CustomInput
                                        id="vin"
                                        label="VIN"
                                        value={newLoad?.vin ?? ''}
                                        elementType="input"
                                        onChange={val => onChange('vin', val)}
                                        required
                                        valid={!errors['vin'] && !(showMandatoryError && !newLoad?.vin)}
                                        touched
                                        disabled={loadToEdit}

                                    />
                                </Grid>
                                <Grid item >
                                    <VinDecode vin={newLoad?.vin} setDecodedData={setDecodedData} applyDecode={populatDecoded} openDecoded={openDecoded} setOpenDecoded={setOpenDecoded} />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="assetId"
                                label="Asset ID"
                                value={newLoad?.assetId ?? ''}
                                elementType="input"
                                onChange={val => onChange("assetId", val)}
                                required
                                valid={!(showMandatoryError && !newLoad?.assetId)}
                                touched
                                disabled={loadToEdit}

                            />
                        </div>
                    </Grid>

                </Grid>}
                {!loadToEdit && <Grid container>
                    <Grid item xs={4}>
                        <div className={classes.input}>
                            <CustomInput
                                id="make"
                                label="Vehicle Make"
                                value={newLoad?.make ?? ''}
                                elementType="input"
                                onChange={val => onChange("make", val)}
                                required
                                disabled={loadToEdit}
                                valid={!(showMandatoryError && !newLoad?.make)}
                                touched
                                colorInput={colorDecoded}
                            />
                            {decodedData && <VinMatching decodedData={decodedData.make} editedData={newLoad?.make} field='make' />}
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={classes.input}>
                            <CustomInput
                                id="model"
                                label="Vehicle Model"
                                value={newLoad?.model ?? ''}
                                elementType="input"
                                onChange={val => onChange("model", val)}
                                required
                                disabled={loadToEdit}
                                valid={!(showMandatoryError && !newLoad?.model)}
                                touched
                                colorInput={colorDecoded}
                            />
                            {decodedData && <VinMatching decodedData={decodedData.model} editedData={newLoad?.model} field='model' />}
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={classes.input}>
                            <CustomInput
                                id="year"
                                label="Vehicle Year"
                                value={newLoad?.year ?? ''}
                                elementType="input"
                                onChange={val => onChange("year", val)}
                                required
                                valid={!errors['year'] && !(showMandatoryError && !newLoad?.year)}
                                touched
                                disabled={loadToEdit}
                                numberOnly
                                colorInput={colorDecoded}
                            />
                            {decodedData && <VinMatching decodedData={decodedData.year} editedData={newLoad?.year} field='year' />}
                        </div>
                    </Grid>
                </Grid>}
                {!loadToEdit && <Grid container>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="assetTypeId"
                                label="Vehicle Type"
                                value={newLoad?.assetTypeId}
                                elementType="dropdown"
                                onChange={val => onChange("assetTypeId", +val)}
                                values={vehicleTypes}
                                showEmpty
                                required
                                valid={!(showMandatoryError && !newLoad?.assetTypeId)}
                                touched
                                disabled={loadToEdit}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="vehicleFuelTypeId"
                                label="Vehicle Fuel Type"
                                value={newLoad?.vehicleFuelTypeId}
                                elementType="dropdown"
                                onChange={val => onChange("vehicleFuelTypeId", +val)}
                                values={fuelTypes}
                                showEmpty
                                required
                                valid={!(showMandatoryError && !newLoad?.vehicleFuelTypeId)}
                                touched
                            />
                        </div>
                    </Grid>
                </Grid>}

                <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.input}>
                            <CustomInput
                                id="moveType"
                                label="Move Type"
                                value={newLoad?.configMoveType}
                                elementType="dropdown"
                                disabled={!clientMoveTypes?.length}
                                onChange={val => onMoveTypeChange(val)}
                                values={clientMoveTypes}
                                showEmpty
                                required
                                valid={!(showMandatoryError && !newLoad?.configMoveType)}
                                touched
                            />
                        </div>
                    </Grid>
                </Grid>

                <LocationField
                    clientId={isClient ? loggedInUserClientId : newLoad?.clientId}
                    name="pickup"
                    title="Origin"
                    onChange={onChange}
                    onChangeLocation={onChangeLocation}
                    newLoad={newLoad}
                    setNewLoad={setNewLoad}
                    errors={errors}
                    loadToEdit={loadToEdit}
                    newLot={originNewLot}
                    setNewLot={(val) => {
                        if (!val) {
                            clearErrorForFields(['pickupPocEmail', 'pickupPhone']);
                        }
                        setOriginNewLot(val);
                    }}
                    showMandatoryError={showMandatoryError}
                    allowNewLots={clientConfigs?.config?.transportConfig?.allowNewLots}
                />
                <LocationField
                    clientId={isClient ? loggedInUserClientId : newLoad?.clientId}
                    name="delivery"
                    title="Destination"
                    onChange={onChange}
                    onChangeLocation={onChangeLocation}
                    newLoad={newLoad}
                    setNewLoad={setNewLoad}
                    errors={errors}
                    loadToEdit={loadToEdit}
                    newLot={destinationNewLot}
                    setNewLot={(val) => {
                        if (!val) {
                            clearErrorForFields(['deliveryPocEmail', 'deliveryPhone']);
                        }
                        setDestinationNewLot(val);
                    }}
                    showMandatoryError={showMandatoryError}
                    allowNewLots={clientConfigs?.config?.transportConfig?.allowNewLots}
                />

                <LocationDistance onChange={onChange} newLoad={newLoad} originNewLot={originNewLot} destinationNewLot={destinationNewLot} showMandatoryError={showMandatoryError} />

                <Grid container>
                    <Grid item xs={6}>
                        <div className={classes.dateInput}>
                            <DatePicker
                                title="Earliest Pick Up Date"
                                value={(newLoad?.pickupStartDate) ? moment(newLoad?.pickupStartDate).format("YYYY-MM-DD") : null}
                                onSelect={val => onChangePickupDate(val)}
                                minDate={moment(pickupMinDate).format("YYYY-MM-DD")}
                                required
                                disabled={pickupDateDisabled}
                                valid={!errors['pickupStartDate'] && !(showMandatoryError && !newLoad?.pickupStartDate)}
                                touched
                            />
                            <Tooltip title={puDateMessage} arrow className={classes.tooltipIcon}>
                                <HelpOutlineIcon />
                            </Tooltip>
                        </div>
                    </Grid>
                    {
                        warnings['pickupStartDate'] && showWarning &&
                        <Grid item className={classes.warningAlert}>
                            <><Alert severity="info">{warnings['pickupStartDate']}</Alert><br /></>
                        </Grid>
                    }
                    <Grid item xs={6}>
                        <div className={classes.dateInput}>
                            <DatePicker
                                title="Latest Delivery Date"
                                value={(newLoad?.deliveryStartDate) ? moment(newLoad?.deliveryStartDate).format("YYYY-MM-DD") : null}
                                onSelect={val => onChange("deliveryStartDate", val)}
                                minDate={moment(deliveryMinDate).format("YYYY-MM-DD")}
                                maxDate={deliveryMaxDate ? moment(deliveryMaxDate).format("YYYY-MM-DD") : null}
                                required
                                disabled={deliveryDateDisabled}
                                valid={!errors['deliveryStartDate'] && !(showMandatoryError && !newLoad?.deliveryStartDate)}
                                touched
                            />
                            <Tooltip title={dDateMessage} arrow className={classes.tooltipIcon}>
                                <HelpOutlineIcon />
                            </Tooltip>
                        </div>
                    </Grid>
                    {
                        warnings['deliveryStartDate'] && showWarning &&
                        <Grid item className={classes.warningAlert}>
                            <><Alert severity="info">{warnings['deliveryStartDate']}</Alert><br /></>
                        </Grid>
                    }
                </Grid>

                {!loadToEdit && <Grid container>
                    {clientConfigs?.config?.transportConfig?.needByWeek &&
                        <Grid item xs={6}>
                            <div className={classes.input}>
                                <CustomInput
                                    id="neededByWeek"
                                    label="Need by week"
                                    value={newLoad?.neededByWeek ?? ''}
                                    onChange={val => onChange("neededByWeek", +val)}
                                    elementType="input"
                                    required
                                    numberOnly
                                    valid={!(showMandatoryError && !newLoad?.neededByWeek)}
                                    touched
                                    minValue={0}
                                />
                            </div>
                        </Grid>
                    }
                    {clientConfigs?.config?.transportConfig?.handOffWeek &&
                        <Grid item xs={6}>
                            <div className={classes.input}>
                                <CustomInput
                                    id="handoffWeek"
                                    label="Handoff week"
                                    value={newLoad?.handoffWeek ?? ''}
                                    onChange={val => onChange("handoffWeek", +val)}
                                    elementType="input"
                                    required
                                    numberOnly
                                    valid={!(showMandatoryError && !newLoad?.handoffWeek)}
                                    touched
                                    minValue={0}
                                />
                            </div>
                        </Grid>
                    }
                </Grid>}

                <div className={classes.input}>
                    <CustomInput
                        id="comments"
                        label="Comments"
                        value={newLoad?.comments}
                        elementType="textarea"
                        onChange={val => onChange("comments", val)}
                    />
                </div>
                <div className={classes.input}>
                    <CustomInput
                        id="shipperLoadId"
                        label="Shipper Load ID"
                        value={newLoad?.shipperLoadId ?? ''}
                        elementType="input"
                        onChange={val => onChange("shipperLoadId", val)}
                    />
                </div>
            </Grid>
            {
                hasErrors && <Grid item xs={2}>
                    <Paper className={classes.errorWrapper}>
                        <h4>Please correct the following errors</h4>
                        {Object.values(errors).map(error => <><Alert icon={false} severity="error">{error}</Alert><br /></>)}
                    </Paper>
                </Grid>
            }
        </Grid >
    );


    return (
        <Paper className={classes.formContainer}>
            {!loadToEdit && <h2 className={classes.title}>Create Load</h2>}
            <form id="createLoadForm" className={`${classes.form} ${isLoading() && classes.disabledForm}`}>
                {form}
            </form>
            <div className={`${classes.btnContainer}`}>
                <div>
                    {loadToEdit && <Button className={classes.button} variant="outlined" onClick={() => handleClose()}>Cancel</Button>}
                    <Tooltip title={Object.values(errors)?.length ? `${Object.values(errors)?.length} error(s) in the form` : ''}>
                        <div>
                            <Button
                                onClick={onSubmit}
                                disabled={isSaving}
                                className={classes.button}
                                variant="contained"

                            >
                                {isSaving ? "Saving..." : "Finish"}
                            </Button>
                        </div>
                    </Tooltip>
                </div>
            </div>
        </Paper>
    );
};

export default CreateLoadForm;
