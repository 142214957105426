import { Grid, Menu } from "@material-ui/core";
import { orderBy } from "lodash";
import { useState } from "react";
import { useGetLotsQuery } from "../../../../../lots/lotSlice";
import { LotsMarketsTreeView } from "./LotsMarketsTreeView"
import style from "./style";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DataSearchBar from "../../../../../../components/DataSearchBar";
import { useGetSubMarketsQuery } from "../../../../../lots/components/LotSubMarketsPage/lotSubMarketsSlice";

export const LotsMarketsMenu = ({
    selectedLot,
    selectedSubMarket,
    onSelectLot,
    onSelectSubMarket,
    filterLots = [],
    filterSubMarkets = []
}) => {

    const classes = style();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: submarkets, error: smError, isLoading: isLoadingSm } = useGetSubMarketsQuery();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const allLotsInSubMarket = submarkets?.flatMap(s => s.lotIds)?.reduce(function (map, obj) {
        map[obj] = obj;
        return map;
    }, {})

    const allLotsNotInSubmarket = lots?.filter(l => {
        return !allLotsInSubMarket[l.id] && !filterLots?.includes(l.id)
    });

    const lotMap = lots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {})

    const subMarketMap = submarkets?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {})

    const [search, setSearch] = useState();
    const [normalizedSearch, setNormalizedSearch] = useState();

    const data = orderBy([
        ...submarkets?.map(sub => (
            {
                ...sub,
                isSubMarket: true,
                blockSelection: filterSubMarkets?.includes(sub?.id),
                lots: sub?.lotIds?.filter(l => !filterLots?.includes(l)).map(lot => ({
                    ...lotMap[lot]
                }))?.filter(lot =>
                    (!normalizedSearch || lot?.name?.toLowerCase().includes(normalizedSearch))
                    && (!filterSubMarkets?.includes(sub?.id) || sub.lotIds?.some(l => !filterLots?.includes(l)))
                )
            })),
        ...allLotsNotInSubmarket], 'name').filter(item =>
        (
            (!normalizedSearch
                || item?.name?.toLowerCase().includes(normalizedSearch)
                || item?.lots?.some(l => l?.name?.toLowerCase().includes(normalizedSearch)))

        ));

    const onSelect = (item) => {
        if (item?.isSubMarket) {
            onSelectSubMarket(item.id)
        } else {
            onSelectLot(item.id)
        }
    }

    return (
        <>
            <Grid container className={classes.lotName} onClick={handleClick} alignItems='center' justifyContent="space-between">
                <Grid item xs={11}>
                    {selectedLot && lotMap[selectedLot]?.name}
                    {selectedSubMarket && subMarketMap[selectedSubMarket]?.name}
                </Grid>
                <Grid item xs={1}>
                    <ExpandMoreIcon onClick={handleClick} />
                </Grid>
            </Grid>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className={classes.menuWrapper}>
                    <div className={classes.searchWrapper}>
                        <DataSearchBar
                            onSearch={(val) => {
                                setSearch(val)
                                setNormalizedSearch(val.toLowerCase())
                            }}
                            searchVal={search}
                        />
                    </div>
                    <div className={classes.listWrapper} >
                        <LotsMarketsTreeView
                            selectedLot={selectedLot}
                            selectedSubMarket={selectedSubMarket}
                            data={data}
                            onSelect={(selected) => {
                                handleClose();
                                onSelect(selected);
                            }}
                        />
                    </div>
                </div>
            </Menu>
        </>
    )
}