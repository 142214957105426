import { useGetClaimsByUserIdQuery, useGetUserLotRelationsForUserQuery } from '../../usersSlice';
import usersPageStyle from '../UsersPageStyle';
import EditUserPermissions from './userPermissions/EditUserPermissions';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import { Card, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tabs, Tab, Box } from '@material-ui/core';
import Authorize from 'components/Authorize';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import EditUserLotRelations from './userLotRelations/EditUserLotRelations';
import { useSelector } from 'react-redux';
import { selectUserId } from 'features/user/userSlice';
import EditUserInformation from './userInformation/EditUserInformation';
import EditUserRestrictions from './userRestrictions/EditUserRestrictions';
import { permissions } from 'components/Authorize/permissions/permissions';
import { hasPermission } from 'components/Authorize/authorizeUtil';
import { TabsWrapper } from 'components/TabsWrapper/TabsWrapper';

const useStyles = usersPageStyle;

const EditUserDialog = ({ user, onCancel }) => {
    const classes = useStyles();

    const userId = useSelector(selectUserId);

    let { data: userLotRelations, error: relationsError, isLoading: isLoadingRelations } = useGetUserLotRelationsForUserQuery(userId, { skip: !userId });
    let { data: userClaims, error: userClaimsError, isLoading: isLoadingUserClaims } = useGetClaimsByUserIdQuery(user?.id, { skip: !user?.id });

    userLotRelations = userLotRelations || [];
    userClaims = userClaims?.data || [];

    const [relations, setRelations] = useState(userLotRelations);

    useEffect(() => {
        setRelations(userLotRelations);
    }, [isLoadingRelations]);


    const tabs = [
        {
            label: 'User Information',
            component: <EditUserInformation user={user} userClaims={userClaims} relations={userLotRelations}></EditUserInformation>
        },
        {
            label: 'Lot Relations',
            component: <Card className={classes.cardWrapper}>
                <EditUserLotRelations userId={user?.id} relations={relations} setRelations={setRelations} onCancel={onCancel} />
            </Card>
        },
        {
            label: 'Access',
            component: <EditUserRestrictions user={user} userClaims={userClaims} userLotRelations={userLotRelations}></EditUserRestrictions>
        }
    ]
    if (hasPermission(permissionProfiles.USER.MANAGE_PERMISSIONS.edit_perm[0].key)) {
        tabs.push({
            label: 'Permissions',
            component: <EditUserPermissions userId={user?.id} onCancel={onCancel} />
        })
    }
    return (
        <Dialog open={!!user?.id} onClose={() => onCancel()} aria-labelledby="form-dialog-title" fullWidth maxWidth='md'>
            <DialogTitle id="form-dialog-title">
                {'Edit User '}
                <div className={classes.subTitle}>{user?.email}</div>
            </DialogTitle>

            <DialogContent>
                <TabsWrapper tabs={tabs} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { onCancel() }} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default EditUserDialog;
