import React, { useState } from "react";
import { Badge, Drawer, Grid, IconButton } from "@material-ui/core";
import BuildCircleIcon from '@material-ui/icons/Build';
import { permissionProfiles } from "../../../../components/Authorize/permissionProfiles";
import Authorize from "../../../../components/Authorize";
import {Styles} from "./panelStyles";
import FilterListIcon from "@material-ui/icons/FilterList";
import FilterService, {fieldTypes} from "../../../../services/filterService";
import LotsDropdown from "../../../../components/SharedDropdowns/LotsDropDown";
import DropdownFilter from "../../../../components/DropdownFilter";
import DataSearchBar from "../../../../components/DataSearchBar";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import AutoSizer from "react-virtualized-auto-sizer";
import {FixedSizeList as List} from "react-window";
import EmptyPlaceholder from "../../../../components/EmptyPlaceholder/EmptyPlaceholder";
import {useDispatch, useSelector} from "react-redux";
import {useGetLotsQuery} from "../../../lots/lotSlice";
import {orderBy} from "lodash";
import {setTaskPanelOpen, useGetMechanicalTasksQuery,setFilters} from "../../slice";
import TaskItem from "../taskItem/index";
import {useGetAllActivityItemsQuery} from "../../../activity/activitySlice";

const MechanicalTasksPanel = () => {

    const classes = Styles();
    const dispatch = useDispatch();
    const { taskPanelOpen,filters } = useSelector(state => state.mechanicalTaskSlice);
    const [searchVal, setSearchVal] = useState();
    
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let { data: tasks, error: tasksError, isLoading: isLoadingTasks } = useGetMechanicalTasksQuery();
    let { data: lots, isLoading: isLoadingLots } = useGetLotsQuery();
        
    const filterConfig = [
        {
            type: fieldTypes.LOT,
            size: 3,
            label: !!filters?.lotId?.value?.length ? undefined : 'All lots',
            key: 'lotId',
            values: [...(lots ?? [])],
            multiple: true
        },
        {
            type: fieldTypes.TEXT,
            size: 3,
            key: 'activityStatus',
            values: [{ id: '', name: "All Status" }, { id: '1', name: "Requested By Client" }, { id: '2', name: "Complete" }
                , { id: '3', name: "Pending Invoice" }
                , { id: '4', name: "Cancelled" }
                , { id: '5', name: "Pending Invoice" }
                , { id: '6', name: "In Progress" }
            ],
        },  
        {
            type: fieldTypes.LIST,
            size: 3,
            label: !!filters?.activityItemId?.value?.length ? undefined : 'All activities',
            key: 'activityItemId',
            values: [...(activityItems ?? [])],
            multiple: true
        },
    ];
    
    const sortedTasks = orderBy(FilterService.filter(filters, searchVal, tasks?.data, filterConfig)
        , ['lotId'], ['asc']);


    const allTasks = tasks?.data ?? [];

    const openPanel = value => {
        dispatch(setTaskPanelOpen(value));
    }

    const Row = ({ index, style }) => (
        <div style={style}>
            <TaskItem task={sortedTasks?.[index]} key={index} activityItems={activityItems} />
        </div>
    );
    
    return (
        <>
            <Authorize profile={permissionProfiles.MECHANICALS_TASKS.TASK_LIST}>
                <IconButton title='Mechanical Tasks' onClick={() => openPanel(true)} >
                    <Badge badgeContent={allTasks?.length} color="primary">
                        <BuildCircleIcon className={classes.icon} />
                    </Badge>
                </IconButton>
            </Authorize>
            <Drawer
                anchor={'right'}
                open={taskPanelOpen}
                onClose={() => {
                    //dispatch(setFilters({ lotId: { value: [] }, clientId: { value: '' } }));
                    openPanel(false)
                }}
                BackdropProps={{ invisible: true }}
            >
                <Grid direction="column" container className={classes.panel}>
                    <Grid container className={classes.header}>
                        <Grid xs={10} item> Tasks ({sortedTasks?.length})</Grid>
                    </Grid>
                    <Grid container alignItems="center">
                        <Grid container direction='row' alignItems='center' className={classes.filters}>
                            <Grid item className={classes.iconContainer}><FilterListIcon /></Grid>
                            {filterConfig?.map(filter =>
                                <Authorize profile={filter.profile} key={filter.key}>
                                    <Grid xs={filter.size} item className={classes.filterWrapper}>
                                        <Grid container direction='row' alignItems='center' >
                                            <Grid xs={12} item >
                                                {filter?.type == fieldTypes.LOT ?
                                                    <LotsDropdown
                                                        clientId={filters['clientId']?.value}
                                                        title={filter.label}
                                                        values={filter.values}
                                                        value={filters?.[filter.key]?.value ?? (filter.multiple && [])}
                                                        onSelect={(val) => dispatch(setFilters({ ...filters, [filter.key]: { value: val } }))}
                                                        multiple
                                                    />
                                                    : <DropdownFilter
                                                        title={filter.label}
                                                        values={filter.values}
                                                        value={filters?.[filter.key]?.value ?? (filter.multiple && [])}
                                                        onSelect={(val) => dispatch(setFilters({ ...filters, [filter.key]: { value: val } }))}
                                                        multiple={filter.multiple}
                                                    />}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Authorize>
                            )}
                        </Grid>
                    </Grid>
                    <Grid alignItems='center' className={classes.search}>
                        <DataSearchBar onSearch={(val) => setSearchVal(val)} searchVal={searchVal} />
                    </Grid>

                    {isLoadingTasks ?
                        <LoadingSpinner loading={isLoadingTasks} />
                        : sortedTasks?.length ? <div style={{ flex: 1 }}>
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <List
                                            className="List"
                                            height={height}
                                            itemCount={sortedTasks?.length}
                                            itemSize={180}
                                            width={width}
                                        >
                                            {Row}
                                        </List>
                                    )}
                                </AutoSizer>

                            </div> :
                            <EmptyPlaceholder text="No Tasks" />}
                </Grid>
            </Drawer>
        </>
    )
}
export default MechanicalTasksPanel;