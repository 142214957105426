import {createSlice} from "@reduxjs/toolkit";

export const activityReportFiltersSlice = createSlice({
    name: 'activityReportFilters',
    initialState: {
        searchVal: '',
        filters: {
            clientId: {value: null},
            lotId: {value: null},
            date: {value: null},
            activityTypeId: {value: null},
            activityItemId: {value: null},
            activityId: {value: null},
        },
    },
    reducers: {
        setSearchVal: (state, action) => {
            state.searchVal = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        }
    },
});

export const { setSearchVal, setFilters } = activityReportFiltersSlice.actions;
export default activityReportFiltersSlice.reducer;
