import LoadingSpinner from "../../components/LoadingSpinner";
import React, { useState } from "react";
import { useStyles } from "./PricingPageStyles";
import PageComponent from "../../components/Page/PageComponent";
import DataListHeader from "components/DataListHeader";
import { Box, Grid, Paper, Tab, Tabs } from "@material-ui/core";
import PropTypes from 'prop-types';
import ActivityTypesList from "./ActivityTypesList/ActivityTypesList";
import PricingList from "./PricingList/PricingList";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { TabsWrapper } from "components/TabsWrapper/TabsWrapper";

const PricingPage = () => {
    const classes = useStyles();

    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const isLoading = () => false;


    if (isLoading()) {
        return <LoadingSpinner loading={true} />;
    }


    const tabs = [
        {
            label: 'Activity types',
            component: <ActivityTypesList />
        },
        {
            label: 'Pricing',
            component: <PricingList />
        }
    ]

    return (
        <Authorize profile={permissionProfiles.PRICING.MANAGE_PPRICING}>
            <Paper className={classes.paper}>
                <TabsWrapper tabs={tabs} />
            </Paper>
        </Authorize>
    );
};

export default PricingPage;
