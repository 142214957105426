import { modes } from "../modes";
import { roles } from "../roles";

export const inspectionPermissionProfile = {
    INSPECTION_CREATE: {
        [modes.EDIT]: [roles.ADMIN, roles.ACCOUNT_MANAGER],
    },
    INSPECTION_VIEW: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER],
    }
}