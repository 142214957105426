import { Button, Grid, IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popover from '@mui/material/Popover';
import { useGetMapUrlQuery } from "features/lots/lotSlice";
import {
    useGetAllIssuesTrackerQuery,
} from "features/reporting/IssuesTracker/issuesTrackerSlice";
import { IconLot } from 'icons';
import { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Authorize from "../../../../../components/Authorize";
import { permissionProfiles } from "../../../../../components/Authorize/permissionProfiles";
import ProgressBar from "../../../../../components/ProgressBar";
import { IconMarket, IconPmfLogo } from "../../../../../icons";
import { lotItemStyle } from "./LotsItemStyle";

const LotItem = ({ lot, onEdit, onViewImages, subMarketsByLot, onDelete, hideElements = false }) => {
    const classes = lotItemStyle();

    const [openToolTip, setOpenToolTip] = useState(false);
    const [marketsAnchorEl, setMarketsAnchorEl] = useState(null);
    const openMarkets = Boolean(marketsAnchorEl);

    let { data: issuesTracker, error: issuesTrackerError, isFetching: isLoadingIssuesTracker } = useGetAllIssuesTrackerQuery({ includeIssueTrackerHistory: false });
    issuesTracker = issuesTracker || [];

    const openMrketsPopover = (event) => {
        setMarketsAnchorEl(event.currentTarget);
    }

    const closeMarketsPopover = () => {
        setMarketsAnchorEl(null);
    }

    const handleOpen = () => {
        setOpenToolTip(true);
    }

    const handleClose = () => {
        setOpenToolTip(false);
    }

    const getSubMarketsNames = (lotId) => {
        if (!subMarketsByLot[lotId])
            return;
        let subsNames = "";
        let subs = subMarketsByLot[lotId];
        subsNames = subs?.map(s => s.name)?.join(', ');
        return subsNames;
    }

    const getLotIssueTrackerDescription = (lotId) => {
        let lotObj = issuesTracker.find(x => x.lotId === lotId);

        if (!lotObj) {
            return;
        }

        return lotObj.description;
    }

    return (
        <>
            <div className={`${classes.card}`} >
                <Grid container>
                    <Grid item xs={2} display="flex" className={classes.iconContainer}>
                        <IconLot height='27' width='27' />
                    </Grid>
                    <Grid item xs={9}>
                        <h2 className={classes.title}>
                            <div className={classes.marketIconWrapper}>
                                <div className={classes.marketIcon}>{lot.name}</div>
                                {!hideElements && <div className={classes.iconWrapper}>
                                    <Tooltip title="Park My Fleet Lot" placement="top" open={openToolTip} onClose={handleClose} onOpen={handleOpen} >
                                        <div>
                                            {lot.pmfLot && <IconButton size="medium" className={classes.icon} >
                                                <IconPmfLogo />
                                            </IconButton>}
                                        </div>
                                    </Tooltip>
                                    {getSubMarketsNames(lot.id) &&
                                        <IconButton onClick={openMrketsPopover} size="small" className={classes.icon}>
                                            <IconMarket />
                                        </IconButton>
                                    }
                                    {getLotIssueTrackerDescription(lot.id) &&
                                        <div>
                                            <NavLink exact
                                                to={`/issues/tracker/lot-${lot.id}`}
                                            >
                                                <Tooltip title={getLotIssueTrackerDescription(lot.id)} placement="top" >
                                                    <IconButton size="small" className={classes.icon}>
                                                        <ErrorOutlineIcon className={classes.errorIcon} title='View Lot Issue Tracker' fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </NavLink>
                                        </div>
                                    }
                                </div>}
                            </div>
                        </h2>
                        <h3 className={classes.subTitle}>
                            {lot.pmfAlias}
                            {lot.sku && <Authorize profile={permissionProfiles.LOT.LOT_VIEW_SKU}>
                                &nbsp;<>SKU: {lot.sku}&nbsp;</>
                            </Authorize>}
                        </h3>
                        <h3 className={classes.subTitle}>{<>

                            {`${lot.addressLine1}, ${lot.city}, ${lot.zipCode}`}
                        </>}</h3>

                    </Grid>
                    <Popover
                        open={openMarkets}
                        anchorEl={marketsAnchorEl}
                        onClose={closeMarketsPopover}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}>
                        <div className={classes.marketsList}>
                            <div><b>Sub Markets</b></div>
                            {getSubMarketsNames(lot.id) && <div >{getSubMarketsNames(lot.id)}</div>}
                        </div>
                    </Popover>
                    <Grid item xs={1} display="flex">
                        <LotItemAction lot={lot} onEdit={onEdit} onViewImages={onViewImages} onDelete={onDelete} hideElements={hideElements} />
                    </Grid>
                    <Grid item xs={2} />
                    {!hideElements && <Grid item xs={10} className={classes.actionContainer}>
                        <Authorize profile={permissionProfiles.LOT.LOT_CARD_OCCUPANCY_INTERNAL}>
                            <ProgressBar
                                progress={(lot.numberOfAssets / lot.numberOfSpots) * 100}
                                initLabel={lot.numberOfAssets + ' vehicles'}
                                endLabel={lot.numberOfSpots ? (lot.numberOfSpots - lot.numberOfAssets) + ' spots left' : ""}
                            />
                        </Authorize>
                        <Authorize profile={permissionProfiles.LOT.LOT_CARD_OCCUPANCY_CLIENT}>
                            <ProgressBar
                                progress={(lot.numberOfAssets / lot.clientNumberOfSpots) * 100}
                                initLabel={lot.numberOfAssets + ' vehicles'}
                                endLabel={lot.clientNumberOfSpots ? (lot.clientNumberOfSpots - lot.numberOfAssets) + ' spots left' : ""}
                            />
                        </Authorize>
                    </Grid>}
                </Grid>
            </div>
        </>
    )
}

const LotItemAction = ({ lot, onEdit, onViewImages, onDelete, hideElements = false }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div>
                <Button onClick={handleClick}>
                    <MoreVertIcon />
                </Button>
            </div>
            {!!anchorEl && <LotItemMenu
                {...{
                    lot,
                    onEdit,
                    onViewImages,
                    anchorEl,
                    handleClose,
                    onDelete,
                    hideElements
                }}

            />}
        </>
    )
}

const LotItemMenu = ({ lot, onEdit, onViewImages, anchorEl, handleClose, onDelete, hideElements = false }) => {
    let { data: map, error: attachmentsError, isFetching: isLoadingAttachments } = useGetMapUrlQuery(lot?.id, { skip: !lot.pmfLot });
    const open = Boolean(anchorEl);
    const history = useHistory();

    return (
        <Menu
            id="lot-actions"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'lot-menu',
            }}
        >
            {map && !hideElements && <MenuItem onClick={() => {
                history.push(`/lots/overview/${lot.id}`);
                handleClose();
            }} >
                View
            </MenuItem>}
            <Authorize profile={permissionProfiles.LOT.LOT_EDIT_CREATE}>
                <MenuItem onClick={() => {
                    onEdit(lot);
                    handleClose();
                }} >
                    Edit
                </MenuItem>
            </Authorize>
            <Authorize profile={permissionProfiles.LOT.LOT_DELETE}>
                <MenuItem onClick={() => {
                    onDelete(lot);
                    handleClose();
                }} >
                    Delete
                </MenuItem>
            </Authorize>
            <Authorize profile={permissionProfiles.LOT.LOT_EDIT_CREATE}>
                <MenuItem onClick={() => {
                    onViewImages(lot);
                    handleClose();
                }} >
                    Attachments
                </MenuItem>
            </Authorize>
        </Menu>
    )
}


export default LotItem;