import { Button, Grid, Paper } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import CustomInput from "components/CustomInput";
import LoadingSpinner from "components/LoadingSpinner";
import { useGetDriversQuery } from "features/drivers/driversSlice";
import { useGetVehiclesQuery } from "features/vehicles/vehicleSlice";
import { IconAccessCode, IconAdd } from "icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataListHeader from "../../components/DataListHeader";
import PageComponent from "../../components/Page/PageComponent";
import FilterService from "../../services/filterService";
import { useGetLotsQuery } from "../lots/lotSlice";
import AccessDialog from "./AccessDialog";
import AccessGroupList from "./AccessGroupList";
import { AccessStyle } from "./Style";
import { AccessGroupTypes, AccessGroupTypesList } from "./accessConsts";
import { useGetAccessesByGroupTypeQuery, useGetAllVendorsQuery } from "./accessSlice";
import { setFilters } from "./accessStateSlice";
import OpenGate from "./OpenGate/OpenGate";
import GenerateOneTimeAccess from "./GenerateOneTimeAccess/GenerateOneTimeAccess";
import AddIcon from '@material-ui/icons/Add';
import { selectIsClient } from "features/user/userSlice";

const AccessPage = () => {
    const classes = AccessStyle();

    const { searchVal, filters } = useSelector(state => state.accessState);
    const dispatch = useDispatch();
    const isClient = useSelector(selectIsClient);
    const [selectedGroupType, setSelectedGroupType] = useState(AccessGroupTypes.CLIENT);

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: vendors, error: vendorsError, isLoading: isLoadingVendors } = useGetAllVendorsQuery();
    let { data: accessData, error: accessError, isLoading: isLoadingAccess } = useGetAccessesByGroupTypeQuery(selectedGroupType);
    // let { data: drivers, error: driverError, isLoading: isLoadingDrivers } = useGetDriversQuery();
    // let { data: vehiclesData, error: vehicleError, isFetching: isFetchingVehicles, refetch: refetchVehicles } = useGetVehiclesQuery({ includeHistory: false });


    lots = lots || [];
    vendors = vendors || [];
    accessData = accessData || [];
    // drivers = drivers || [];
    // vehiclesData = vehiclesData || [];

    const isLoading = isLoadingLots || isLoadingVendors || isLoadingAccess;

    accessData = accessData.map(a => ({
        ...a,
        lots: lots?.filter(l => a?.lotIds?.includes(l?.id)),
        // drivers: drivers?.filter(l => a?.driverIds?.includes(l?.id)),
        // vehicles: vehiclesData?.filter(l => a?.vehicleIds?.includes(l?.id)),
    }));

    const [openModal, setOpenModal] = useState(false);

    const handleClose = () => {
        setOpenModal(false)
    }

    let filterConfig = [
        // { type: 'text', size: 2, title: 'Access Group Type', key: 'groupType', values: AccessGroupTypesList },
        // { type: fieldTypes.LOT, size: 2, title: 'Lot', key: 'lotId', values: lots },
        // { type: 'text', size: 2, title: 'Transporter', key: 'transporterId', values: AccessGroupTypesList },
        // { type: 'text', size: 2, title: 'Service Provider', key: 'serviceProviderId', values: AccessGroupTypesList },
    ];

    const onDropFilter = (filterValue, prop) => {
        setFilters({ ...filters, [prop]: { value: filterValue } });
    };

    let filteredData = FilterService.filter(filters, searchVal, accessData, filterConfig);

    const mapExportData = v => ({

    });


    return (
        <PageComponent
            header={<DataListHeader
                title={(
                    <Grid container alignItems="center">
                        <IconAccessCode style={{ marginRight: '0.5em' }} />
                        {'Authorized Access'}
                    </Grid>
                )}
                data={accessData}
                dropDownsConfig={filterConfig}
                filters={filters}
                onDropFilter={onDropFilter}
                onSearch={searchVal}
                actionButtons={(
                    <Grid container spacing={1}>
                        <Authorize profile={permissionProfiles.ACCESS.MANAGE_ACCESS}>
                            <Grid item>
                                <Button variant="outlined" //color="primary" 
                                    startIcon={<AddIcon className={classes.icon} />}
                                    onClick={() => setOpenModal(true)}>
                                    Create Access
                                </Button>
                            </Grid>
                        </Authorize>
                        <Grid item>
                            <OpenGate />
                        </Grid>
                        <Grid item>
                            <GenerateOneTimeAccess />
                        </Grid>
                    </Grid>
                )}
            />}
        >
            <Paper className={classes.typeWrapper}>
                <Grid xs={4}>
                    <CustomInput
                        label='Access Group Type'
                        elementType='dropdown'
                        value={selectedGroupType}
                        values={AccessGroupTypesList?.filter(a => !isClient || (isClient && a.showForClient))}
                        onChange={(val) => setSelectedGroupType(val)}
                    />
                </Grid>
            </Paper>
            <>
                <AccessDialog open={openModal} handleClose={handleClose} />

                <LoadingSpinner loading={isLoading} />
                <AccessGroupList accessGroups={filteredData} groupType={selectedGroupType} />
            </>
        </PageComponent>

    );
}

export default AccessPage;
