export const activityHeaderColumns = [
    {
        label: 'Lot',
        size: 4,
        key: 'lot',
        value: (invoice) => invoice?.lotInfo,
    },
    {
        label: 'Total Internal Cost',
        size: 2,
        key: 'totalInternalCost',
        value: (invoice) => `$${invoice?.totalInternalCost}`
    },
    {
        label: 'Total Charge',
        size: 2,
        key: 'totalCharge',
        value: (invoice) => `$${invoice?.totalCharge}`
    },
    {
        label: 'Total Revenue',
        size: 2,
        key: 'totalRevenue',
        value: (invoice) => `$${invoice?.totalRevenue}`
    }
]

export const activityDetailsColumns = [
    {
        label: 'Activity',
        size: 2,
        key: 'activityItemName',
    },
    {
        label: 'Total Internal Cost',
        size: 2,
        key: 'totalInternalCost',
        value: (v) => `$${v}`,
        editable: true
    },
    {
        label: 'Total Charge',
        size: 2,
        key: 'totalCharge',
        value: (v) => `$${v}`,
        editable: true
    },
    {
        label: 'Total Revenue',
        size: 2,
        key: 'totalRevenue',
        value: (v) => `$${v}`,
        // editable: true
    }
]

