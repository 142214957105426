import { Button, Grid, Paper } from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from "react";
import { useDispatch } from "react-redux";
import StorageInvoiceDetails from "./StorageInvoiceDetails";
import { storageInvoiceStyles } from "./style";
import { storageHeaderColumns } from "../../storageInvoiceConsts";


const StorageInvoice = ({ invoice }) => {
    const classes = storageInvoiceStyles();

    const [expanded, setExpanded] = useState(false);

    const columnStyling = {
        'lot': 'lotCol'
    }

    return (
        <Paper className={classes.wrapper}>

            <Grid container spacing={1} alignItems='center' justifyContent="flex-end" >
                {storageHeaderColumns?.map((col, index) =>
                    <Grid className={`${classes.column} ${classes[columnStyling[col.key]]}`} xs={col.size} item key={`${invoice.id}_col.key`}>
                        {col?.value ? col.value(invoice) : invoice?.[col.key]}
                    </Grid>
                )}

                <Grid className={classes.column} xs={2} item container key={`col_actions`} justifyContent='flex-end'>
                    <Button
                        color='primary'
                        onClick={() => setExpanded(!expanded)}
                        endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    >
                        {expanded ? 'Less' : 'More'}
                    </Button>
                </Grid>
            </Grid>
            {<StorageInvoiceDetails invoice={invoice} expanded={expanded} />}
        </Paper>
    )
}

export default StorageInvoice;