import { modes } from "../modes";
import { permissions } from "../permissions/permissions";
import { roles } from "../roles";

export const lotPermissionProfiles = {
    LOT_EDIT_CREATE: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.ACCOUNT_MANAGER]
    },
    LOT_DIALOG: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.ACCOUNT_MANAGER],
        [modes.READONLY]: [roles.CLIENT]
    },
    LOT_VIEW: {
        [modes.EDIT]: [roles.CLIENT]
    },
    LOT_VIEW_SKU: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.ACCOUNT_MANAGER]
    },
    SUB_MARKET_CREATE: {
        [modes.EDIT]: [roles.OWNER]
    },
    LOT_VIEW_OVERVIEW_OCCUPANCY: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.ACCOUNT_MANAGER]
    },
    LOT_VIEW_OVERVIEW_CLIENT: {
        [modes.EDIT]: [roles.CLIENT]
    },
    LOT_CARD_OCCUPANCY_INTERNAL: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.ACCOUNT_MANAGER]
    },
    LOT_CARD_OCCUPANCY_CLIENT: {
        [modes.EDIT]: [roles.CLIENT]
    },
    LOT_SIZING: {
        [modes.EDIT_PERMISSION]: [permissions.LOTS.SIZING.EDIT],
    },
    LOT_KPI_CONFIGURE: {
        [modes.EDIT]: [roles.OWNER],
    },
    LOT_DELETE: {
        [modes.EDIT_PERMISSION]: [permissions.LOTS.DELETE],
    },
    LOT_CONTACTS: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER],
    },
    LOT_OPEN_GATE: {
        [modes.EDIT_PERMISSION]: [permissions.LOTS.ACCESS.OPEN_GATE],
    }
}