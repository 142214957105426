import { Grid, Paper } from "@material-ui/core";
import DataSearchBar from "components/DataSearchBar";
import { useState } from "react";
import ContractHeader from "../ContractHeader/ContractHeader";
import LotMechanicalConfig from "./components/LotMechanicalConfig/LotMechanicalConfig";
import { mechanicalConfigStyles } from "./style";
import FilterService from "services/filterService";
import CustomInput from "components/CustomInput";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';

//const MechanicalWorkConfig = ({ contract, handleClose, clientConfig, open }) => {
const MechanicalWorkConfig = ({ clientToEdit, onConfigChange, configName = 'mechanicalsConfig' }) => {

    const classes = mechanicalConfigStyles();

    const [selectedLot, setSelectedLot] = useState();
    const [search, setSearch] = useState();
    //const [useMechanicals, setUseMechanicals] = useState(false);

    // const onClose = () => {
    //     setSelectedLot();
    //     handleClose();
    // }

    const mechanicalsConfigParams = clientToEdit?.config?.mechanicalsConfig;
    const lots = FilterService.filter({}, search, clientToEdit?.clientLots, {});

    // useEffect(() => {
    //     setUseMechanicals(clientToEdit?.mechanicalWorkConfig)
    // }, [clientConfig?.id]);

    //  <Dialog
    //     open={open}
    //     onClose={onClose}
    //     fullWidth
    //     maxWidth='md'
    // >
    //     <DialogTitle>
    //         <Grid container justifyContent='space-between'>
    //             <div>{`${contract?.label} SLA`}</div>
    //             {contract?.headerComponent}
    //         </Grid>
    //     </DialogTitle>
    //     <DialogContent>
    return <>
        {!selectedLot && <ContractHeader
            label={'Mechanicals'}
            flag={mechanicalsConfigParams?.useMechanicals}
            // onChange={() => {
            //     setUseMechanicals(!useMechanicals);
            // }}
            onChange={() => { onConfigChange(configName, "useMechanicals", !mechanicalsConfigParams?.useMechanicals) }}
        />}
        {
            <Paper className={classes.marginWrapper}>
                <div className={classes.inputField}>
                    <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.VIEW_CONFIG}>
                        <CustomInput
                            disabled={!mechanicalsConfigParams?.useMechanicals}
                            id="margin"
                            value={mechanicalsConfigParams?.margin}
                            onChange={(val) => onConfigChange(configName, "margin", +val)}
                            elementType="input"
                            numberOnly
                            step="0.1"
                            label={"Margin"}
                            icon={<MonetizationOnOutlinedIcon width={30} height={30} className={classes.inputIcon} />}
                        />
                    </Authorize>
                </div>
            </Paper>
        }
        <Grid container direction='column' spacing={2}>
            {!selectedLot &&
                <>
                    <Grid item><DataSearchBar onSearch={(val) => setSearch(val)} searchVal={search} /></Grid>
                    {lots?.map(l =>
                        <Grid key={l.id} item onClick={() => mechanicalsConfigParams?.useMechanicals && setSelectedLot(l)}>
                            <Paper className={classes.lotWrapper}>
                                <Grid container alignItems='center'>
                                    <Grid item xs={2} display="flex" className={classes.iconContainer}>
                                        <img src="/icons/icon-detailing.svg" alt="Transporter" />
                                    </Grid>
                                    <Grid item className={classes.lotName}>{l.name}</Grid>
                                </Grid>
                            </Paper>
                        </Grid>)
                    }</>
            }
            {selectedLot && <LotMechanicalConfig onClose={() => setSelectedLot()} lotConfig={selectedLot} clientId={clientToEdit?.id} />}
        </Grid>
    </>
    //     </DialogContent >
    // </Dialog >

}
export default MechanicalWorkConfig;