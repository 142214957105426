import { makeStyles } from "@material-ui/core"

export const loadCardStyle = makeStyles((theme) => ({
    loadBox: {
        padding: '0.5em',
        width: '100%'
    },
    cardItem: {
        display: 'flex',
        width: '22%'
    },
    btns: {
        display: 'flex',
        alignItems: 'flex-end',
        marginLeft: 'auto'
    },
    lastCol: {

    },
    statusCardItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '0.5em',
        marginLeft: '-2.5em'
    },
    displayAddressWrapper: {
        fontWeight: 'bold',
        fontSize: '16px',
    },
    locationWrapper: {
        marginLeft: '1em'
    },
    locationDateWrapper: {
        //marginBottom: '10%'
        marginTop: '1px'
    },
    confirmedDateTextWrapper: {
        fontWeight: 'bold',
        fontSize: '14px',
        marginTop: '8px'
    },
    upwardArrow: {
        background: '#FB8C00',
        padding: '1px',
        borderRadius: '50%',
        '& path': {
            fill: 'white'
        }
    },
    downwardArrow: {
        background: '#4CAF50',
        padding: '1px',
        borderRadius: '50%',
        '& path': {
            fill: 'white'
        }
    },
    status: {
        fontSize: '1rem',
        fontWeight: 400
    },
    statusDate: {
        opacity: 0.6
    },
    vin: {
        //fontWeight: 'bold',
        '& a': {
            textDecoration: 'none',
            color: theme.palette.primary.main,
            fontWeight: '400'
        }
    },
    externalID: {
        '& label': {
            fontWeight: 'bold',
        }

        //marginTop: '1em'
    },
    cardColumn: {
        width: '25%'
    },
    pencilIcon: {
        color: 'black',
        fontSize: '1rem'
    },
    dateWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    dateValue: {
        marginLeft: '0.5em'
    },
    editButton: {
        marginLeft: '0.5em'
    },
    editDatesButton: {
        background: 'none',
        border: 'none',
        padding: '0',
        margin: '0',
        marginLeft: '0.5em'
    },
    editLoadIcon: {
        // color: 'black',
        fontSize: '1.5rem'
    },
    contacts: {
        padding: '1em'
    }
}));
