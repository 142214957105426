import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../../app/baseQuery";
import { apiTags } from 'app/store/apiTags';
export const configurationApi = createApi({
    reducerPath: 'configurationApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getConfigurationByVehicle: builder.query({
            query: (groupType) => `configuration/${groupType}`,
            providesTags:[apiTags.CONFIGURATION]
        }),
    }),
});

export const {
    useGetConfigurationByVehicleQuery,
} = configurationApi;
