import { makeStyles } from "@material-ui/core"

export const loadDetailsStyle = makeStyles((theme) => ({
    container: {
        padding: '1em',
    },
    card: {
        margin: '1em 1em 1em 1em',
        padding: '1em',
    },
    longCard: {
        height: 'calc(100% - 2em)',
        margin: '1em 1em 1em 0',
        padding: '1em',
        display: 'flex',
        flexDirection: 'column',
    },
    boxTitle: {
        marginBottom: '1em'
    }
}));
