import { IconButton } from "@material-ui/core";
import ListIcon from '@material-ui/icons/List';
import { Dialog } from "@mui/material";
import { useState } from "react";
import ActivityListTable from "./ActivityListTable";
import { mechanicalDashboardStyles } from "../MechanicalDashboardStyles";

const ActivityListDialog = ({ status }) => {
    const classes = mechanicalDashboardStyles()

    const [showList, setShowList] = useState(false);

    return (
        <>
            <IconButton onClick={() => setShowList(true)}><ListIcon /></IconButton>
            <Dialog maxWidth='xl' fullWidth open={showList} onClose={() => setShowList(false)}>
                <ActivityListTable status={status} dialog={true} />
            </Dialog>
        </>
    )
}

export default ActivityListDialog;