import { Button, Dialog, DialogActions, DialogContent, Grid, IconButton } from "@material-ui/core";
import DataListHeader from "components/DataListHeader";
import DataTable from "components/DataTable";
import { useGetAllActivityItemsQuery } from "features/activity/activitySlice";
import useSortOptions from "hooks/useSortOptions";
import { orderBy } from "lodash";
import { useState } from "react";
import FilterService, { fieldTypes } from "services/filterService";
import { useDispatch, useSelector } from "react-redux";
import { PricingListStyle } from "./style";
import LoadingSpinner from "components/LoadingSpinner";
import PageComponent from "components/Page/PageComponent";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import usePrompt from "hooks/usePrompt";
import NotificationProvider from "components/NotificationProvider";
import { IconAdd } from "icons";
import { useDeletePriceMutation, useGetAllActivityPricingQuery, useUpdateActivityPriceMutation } from "../pricingSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { useGetVehicleTypesQuery } from "features/vehicles/vehicleSlice";
import { setFilters } from "./activityPricingFiltersSlice";
import { useGetServiceProvidersQuery } from "features/serviceProvider/serviceProviderSlice";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import Authorize from "components/Authorize";
import AddActivityPrice from "./AddActivityPrice/AddActivityPrice";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { activityPriceConfig, activityPriceFields } from "./pricingConsts";
import FormatService from "services/formatService";

const PricingList = ({ }) => {
    const classes = PricingListStyle()
    const { triggerPrompt } = usePrompt();

    const [openDialog, setOpenDialog] = useState(false);
    const handleClose = () => setOpenDialog(false);
    const [activityPriceToEdit, setActivityPriceToEdit] = useState(null);

    let { data: activityPricing, error: activityPricingError, isFetching: isLoadingActivityPricing } = useGetAllActivityPricingQuery();
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes } = useGetVehicleTypesQuery();
    let { data: serviceProviders, error: serviceProvidersError, isLoading: isLoadingServiceProviders } = useGetServiceProvidersQuery();

    const [deletePrice, { isLoading: isDeleting }] = useDeletePriceMutation();
    const [updatePrice, { isLoading: isUpdating }] = useUpdateActivityPriceMutation();

    const lotLookup = lots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    activityPricing = activityPricing?.map(activityPrice => {
        return {
            ...activityPrice,
            activityItem: activityItems?.find(item => item.id == activityPrice.activityItemId)?.name,
            lot: lotLookup?.[activityPrice?.lotId]?.name,
            vehicleType: vehicleTypes?.find(v => v.id == activityPrice.vehicleTypeId)?.name,
            vendor: serviceProviders?.find(s => s.id == activityPrice.vendorId)?.name,
            formattedPrice: FormatService.formatCurrency(activityPrice?.price),
            activityPriceObj: activityPrice
        }
    });


    const [sortOptions, sortData] = useSortOptions();
    const { filters } = useSelector(state => state.activityPricingFilters);
    const dispatch = useDispatch();

    const filterConfig = [
        { type: 'text', size: 2, title: 'Activity', key: 'activityItemId', values: activityItems },
        { type: 'text', size: 2, title: 'Vendor', key: 'vendorId', values: serviceProviders },
        { type: fieldTypes.LOT, size: 2, title: 'Lot', key: 'lotId', values: lots },
        { type: fieldTypes.TEXT, size: 2, title: 'Vehicle Type', key: 'vehicleTypeId', values: vehicleTypes },
    ];

    const onChange = (key, val) => {
        setActivityPriceToEdit({ ...activityPriceToEdit, [key]: val });
    };

    let activityPricingColumns = [
        {
            name: "Activity",
            sortKey: "activityItem",
            key: "id",
            component: (activityPriceId) => <>{
                activityPriceId === activityPriceToEdit?.id ?
                    <div className={classes.editField}>
                        {activityPriceConfig[activityPriceFields.ACTIVITY]?.component(activityPriceToEdit?.activityItemId, activityItems, (val) => onChange("activityItemId", parseInt(val)), false)}
                    </div> :
                    <div className={classes.field}>{activityPricing?.find(a => a.id === activityPriceId)?.activityItem}</div>}
            </>
        },
        {
            name: "Vendor",
            sortKey: "vendor",
            key: "id",
            component: (activityPriceId) => <>{
                activityPriceId === activityPriceToEdit?.id ?
                    <div className={classes.editField}>
                        {activityPriceConfig[activityPriceFields.VENDOR]?.component(activityPriceToEdit?.vendorId, serviceProviders, (val) => onChange("vendorId", parseInt(val)), false)}
                    </div> :
                    <div className={classes.field}>{activityPricing?.find(a => a.id === activityPriceId)?.vendor}</div>}
            </>
        },
        {
            name: "Lot",
            sortKey: "lot",
            key: "id",
            component: (activityPriceId) => <>{
                activityPriceId === activityPriceToEdit?.id ?
                    <div className={classes.editField}>
                        {activityPriceConfig[activityPriceFields.LOT]?.component(activityPriceToEdit?.lotId, lots, (val) => onChange("lotId", parseInt(val)), false)}
                    </div> :
                    <div className={classes.field}>{activityPricing?.find(a => a.id === activityPriceId)?.lot}</div>}
            </>
        },
        {
            name: "Vehicle Type",
            sortKey: "vehicleType",
            key: "id",
            component: (activityPriceId) => <>{
                activityPriceId === activityPriceToEdit?.id ?
                    <div className={classes.editField}>
                        {activityPriceConfig[activityPriceFields.VEHICLE_TYPE]?.component(activityPriceToEdit?.vehicleTypeId, vehicleTypes, (val) => onChange("vehicleTypeId", parseInt(val)), false)}
                    </div> :
                    <div className={classes.field}>{activityPricing?.find(a => a.id === activityPriceId)?.vehicleType}</div>}
            </>
        },
        {
            name: "Price",
            sortKey: "price",
            key: "id",
            component: (activityPriceId) => <>{
                activityPriceId === activityPriceToEdit?.id ?
                    <div className={classes.editField}>
                        {activityPriceConfig[activityPriceFields.PRICE]?.component(activityPriceToEdit?.price, null, (val) => onChange("price", parseInt(val)), false)}
                    </div> :
                    <div className={classes.field}>{activityPricing?.find(a => a.id === activityPriceId)?.formattedPrice}</div>}
            </>
        },
        {
            name: "Uses Inventory?",
            sortKey: 'useInventory',
            key: "id",
            component: (activityPriceId) => <>{
                activityPriceId === activityPriceToEdit?.id ?
                    <div className={classes.editField}>
                        {activityPriceConfig[activityPriceFields.USE_INVENTORY]?.component(activityPriceToEdit?.useInventory, (val) => onChange("useInventory", val.target.checked), "")}
                    </div> :
                    <div className={classes.field}>{activityPricing?.find(a => a.id === activityPriceId)?.useInventory ? 'Yes' : 'No'}</div>}
            </>
        }
    ];

    const rowActions = (rowId) => ([
        {
            component:
                <Authorize profile={permissionProfiles.PRICING.MANAGE_PPRICING}>
                    <>
                        {rowId === activityPriceToEdit?.id ?
                            <>
                                <IconButton disabled={isUpdating} size='small' onClick={onEdit} ><CheckIcon /></IconButton>
                                <IconButton disabled={isUpdating} size='small' onClick={onCancel} ><CloseIcon /></IconButton>
                            </> :
                            <IconButton onClick={() => onEditClick(rowId)} >
                                <EditIcon />
                            </IconButton>
                        }
                    </>
                </Authorize>,
        },
        {
            component:
                <Authorize profile={permissionProfiles.PRICING.MANAGE_PPRICING}>
                    <>
                        <IconButton disabled={isDeleting} onClick={() => onDelete(rowId)} >
                            <DeleteIcon />
                        </IconButton>
                    </>
                </Authorize>,
        }
    ])

    const onDropFilter = (value, prop) => {
        dispatch(setFilters({ ...filters, [prop]: { value } }));
    };

    let filteredData = FilterService.filter(filters, null, activityPricing, filterConfig);

    const isLoading = isLoadingActivityPricing || isLoadingActivityItems || isLoadingLots || isLoadingVehicleTypes || isLoadingServiceProviders || isDeleting || isUpdating;

    const [openModel, setOpenModel] = useState(false);

    const onAdd = () => {
        setActivityPriceToEdit(null);
        setOpenDialog(true);
    }

    const onEditClick = (id) => {
        let row = activityPricing.find(a => a.id === id);
        setActivityPriceToEdit(row);
    }

    const onEdit = async () => {
        let existing = activityPricing.find(a =>
            a?.id !== activityPriceToEdit?.id &&
            a?.activityItemId === activityPriceToEdit?.activityItemId &&
            a?.vendorId === activityPriceToEdit?.vendorId &&
            a?.lotId === activityPriceToEdit?.lotId &&
            a?.vehicleTypeId === activityPriceToEdit?.vehicleTypeId &&
            a?.useInventory === activityPriceToEdit?.useInventory)
        if (existing) {
            setOpenModel(true)
        }
        else {
            let res = await updatePrice(activityPriceToEdit);

            if (!res?.error) {
                NotificationProvider.success('Updated successfully');
                setActivityPriceToEdit(null);
            } else {
                NotificationProvider.error('Failed to update');
            }
        }
    }

    const onCancel = () => {
        setActivityPriceToEdit(null);
    }

    const onDelete = async (id) => {
        triggerPrompt({
            title: "Delete Activity Price",
            content: "Are you sure you want to delete this activity price?",
            onConfirm: async () => {
                let res = await deletePrice({ id });

                if (!res?.error) {
                    NotificationProvider.success('Deleted successfully');
                } else {
                    NotificationProvider.error('Failed to delete');
                }
            },
        });
    };

    return (
        <>
            <PageComponent className={classes.card} header={
                <DataListHeader
                    title={
                        <div className={classes.titleWrapper}>
                            Activity Pricing
                        </div>}
                    data={activityPricing}
                    dropDownsConfig={filterConfig}
                    filters={filters}
                    onDropFilter={onDropFilter}
                    actionButtons={(
                        <>
                            <Grid container spacing={1}>
                                <Authorize profile={permissionProfiles.PRICING.MANAGE_PPRICING}>
                                    <Grid item>
                                        <Button variant="contained" color="primary"
                                            onClick={onAdd}
                                            startIcon={<IconAdd />}
                                        >
                                            Add Activity Price
                                        </Button>
                                    </Grid>
                                </Authorize>
                            </Grid>
                        </>
                    )}
                />}>

                <DataTable
                    columns={activityPricingColumns}
                    rows={orderBy(
                        filteredData,
                        sortOptions.columnToSort,
                        sortOptions.sortDirection
                    )}
                    rowIdentifier='id'
                    onSort={sortData}
                    actions={
                        rowActions
                    }
                    maxActionCount={2}
                    sortDirection={sortOptions.sortDirection}
                    columnToSort={sortOptions.columnToSort}
                    noItemsMessage='There are no activity prices matching this criteria'
                    headerStyle={{
                        paddingLeft: '1rem',
                    }}
                />

                <AddActivityPrice open={openDialog} handleClose={handleClose} setOpenModel={setOpenModel} />

                <Dialog open={openModel} onClose={() => setOpenModel(false)}>
                    <DialogContent>The price database already contains a definition for this activity, lot, vendor and vehicle type</DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenModel(false)} color='primary'>OK</Button>
                    </DialogActions>
                </Dialog>
                <LoadingSpinner loading={isLoading} />

            </PageComponent>

        </>
    )
}

export default PricingList;
