import { Chip, FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import React, { useEffect } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Clear } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        width: '100%',
        '& fieldset': {
            border: 'none'
        }
    },
    select: {
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer'
    },
    inputMulti: {
        padding: '12px',
        minHeight: '2em',
        whiteSpace: 'normal'
    },
    input: {
        border: 0,
        color: theme.palette.common.darkText,
        backgroundColor: theme.palette.background.default,
    },
    colorInput: {
        backgroundColor: theme.palette.action.focus,
    },
    label: {
        color: theme.palette.text.secondary
    },
    errorLabel: {
        color: theme.palette.error.main
    },
    selectMenu: {
        maxHeight: '50vh',
        '& option': {
            cursor: 'pointer',

        },
        '& option:hover': {
            background: `${theme.palette.background.default} !important`
        }
    },
    option: {
        padding: '0.2em  0.5em',
    }
}));

const selectMenuStyle = {
    backgroundColor: '#f7f6fb',
    border: 'none',
    color: 'rgba(0, 0, 0, 0.38)',
}

const DropdownFilter = ({
    title,
    identifier,
    value,
    values,
    onSelect,
    valid,
    touched,
    disabled,
    showEmpty,
    required,
    classStyles,
    multiple,
    colorInput,
    renderOption
}) => {

    const classes = useStyles();

    const [filterValue, setValue] = React.useState(value)

    useEffect(() => {
        setValue(value)
    }, [value]);

    const handleChange = (event) => {
        let selectedVal = event.target.value;
        if (multiple && selectedVal?.includes('')) {
            selectedVal = [];
        }
        onSelect(selectedVal, identifier);
    };

    const handleDelete = (event, valueToRemove) => {
        let newValue = [...value];
        newValue?.splice(value.indexOf(valueToRemove), 1);
        onSelect(newValue, identifier);
    };

    const renderValue = (selected) => {
        return <div>
            {(selected)?.map((value) => (
                <Chip
                    key={value}
                    label={values?.find(v => v.id == value)?.name}
                    clickable
                    deleteIcon={
                        <Clear
                            onMouseDown={(event) => event.stopPropagation()}
                        />
                    }
                    className={classes.chip}
                    onDelete={(e) => {
                        handleDelete(e, value)
                    }
                    }
                    onClick={() => console.log("clicked chip")}
                />
            ))}
        </div>
    };

    const renderOptionInternal = (value, key) => {
        return (<MenuItem value={value.id}>
            {renderOption(value)}
        </MenuItem>)
    }

    return (
        <FormControl variant='outlined' className={classStyles === undefined ? classes.formControl : classStyles}>
            <InputLabel required={required} className={`${classes.label} ${!valid && touched && classes.errorLabel}`} htmlFor="outlined-age-native-simple">{title}</InputLabel>
            <Select
                native={multiple || renderOption ? false : true}
                multiple={multiple}
                className={classes.select}
                variant='outlined'
                value={filterValue}
                onChange={handleChange}
                label={title}
                inputProps={{
                    name: { title },
                    id: 'outlined-age-native-simple',
                    className: `${multiple ? classes.inputMulti : classes.input} ${colorInput ? classes.colorInput : ''}`
                }}
                MenuProps={{
                    className: classes.selectMenu
                }}
                error={!valid && touched}
                disabled={disabled}
                IconComponent={disabled ? empty : ExpandMoreIcon}
                renderValue={multiple ? renderValue : undefined}
            >
                {
                    showEmpty &&
                    <option className={classes.option} style={selectMenuStyle} aria-label="None" value="" />
                }
                {values?.map((value, key) => (
                    renderOption ? renderOptionInternal(value, key) :
                    <option id={key} key={key} style={selectMenuStyle} value={value.id} className={classes.option}>{value.name}</option>
                ))}
            </Select>
        </FormControl>
    )
}

export default DropdownFilter

const empty = () => (<></>);