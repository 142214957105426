// export const vehicleFuelTypes = [
//     { id: 1, name: "CNG" },
//     { id: 2, name: "Diesel" },
//     { id: 3, name: "Electric" },
//     { id: 4, name: "Gasoline" }
// ];

export const moveTypes = [
    { id: 1, name: "Network Transfer" },
    { id: 2, name: "Amazon Transfer" },
    { id: 3, name: "Direct to AFP" },
    { id: 4, name: "Expedited" }
];

export const moveTypesById = {
    NETWORK_TRANSFER: 1,
    AMAZON_TRANSFER: 2,
    DIRECT_TO_AFP: 3,
    EXPEDITED: 4,
};

export const moveTypeMessages = {
    [moveTypesById.NETWORK_TRANSFER]: {
        pickup: () => 'Based on the selected move type, the pickup date must be 72 hours after the request date',
        delivery: (days) => `Based on the selected move type and the distance to the delivery location, the delivery date must be at least ${days} days after the pickup and within three week of the pickup date`
    },
    [moveTypesById.AMAZON_TRANSFER]: {
        pickup: () => 'Based on the selected move type, the pickup date must be at least 72 hours after the request date',
        delivery: (days) => `Based on the selected move type and the distance to the delivery location, the delivery date must be at least ${days} days after the pickup`
    },
    [moveTypesById.DIRECT_TO_AFP]: {
        pickup: (days) => `Based on the selected move type and the distance to the delivery location, the pickup is ${days} days before the delivery`,
        delivery: (days) => `Based on the selected move type and the distance to the delivery location, the pickup is ${days} days before the delivery`
    },
    [moveTypesById.EXPEDITED]: {
        pickup: () => 'Based on the selected move type, there are no restrictions on the pickup date',
        delivery: () => 'Based on the selected move type, there are no restrictions on the delivery date'
    },
};

export const calcTypes = [
    { id: 1, name: "No scheduling logic" },
    { id: 2, name: "Calculate latest pickup from selected latest delivery" },
    { id: 3, name: "Calculate delivery from selected pickup" },
    { id: 4, name: "Calculate and enforce minimum transit time, but don't select pickup or delivery" }
];

export const calcTypesById = {
    NO_LOGIC: 1,
    PICKUP_FROM_DELIVERY: 2,
    DELIVERY_FROM_PICKUP: 3,
    MIN_TRANSIT_TIME: 4,
};

const getDaysText = (days) => days > 1 ? `${days} days` : `${days} day`

export const calcTypeMessages = {
    [calcTypesById.NO_LOGIC]: {
        pickup: (minPickup) => minPickup > 0 ? `Based on the selected move type, the pickup date must be at least ${getDaysText(minPickup)} after the request date` : 'There are no restrictions on the pickup date',
        delivery: (maxDelivery) => maxDelivery > 0 ? `Based on the selected move type, the delivery date must be within ${getDaysText(maxDelivery)} of the pickup date` : 'There are no restrictions on the delivery date',
    },
    [calcTypesById.PICKUP_FROM_DELIVERY]: {
        pickup: (days) => `Based on the selected move type and the distance to the delivery location, the pickup is ${getDaysText(days)} before the delivery`,
        delivery: (days, maxDelivery) => {
            let message = '';
            if (days > 0) {
                message = `Based on the selected move type and the distance to the delivery location, the delivery date must be at least ${getDaysText(days)} after the request date`
                if (maxDelivery > 0)
                    message = message + ` and within ${getDaysText(maxDelivery)} of the pickup date`
            }
            else message = 'There are no restrictions on the delivery date'
            return message;
        }
    },
    [calcTypesById.DELIVERY_FROM_PICKUP]: {
        pickup: (days) => days > 0 ? `Based on the selected move type, the pickup date must be at least ${getDaysText(days)} after the request date` : 'There are no restrictions on the pickup date',
        delivery: (days) => `Based on the selected move type, the delivery date is ${getDaysText(days)} after the pickup date`
    },
    [calcTypesById.MIN_TRANSIT_TIME]: {
        pickup: (minPickup) => minPickup > 0 ? `Based on the selected move type, the pickup date must be at least ${getDaysText(minPickup)} after the request date` : 'There are no restrictions on the pickup date',
        delivery: (transit, maxDelivery) => {
            let message = `Based on the selected move type and the distance to the delivery location, the delivery date must be at least ${getDaysText(transit)} after the pickup `;
            if (maxDelivery > 0)
                message = message + ` and within ${getDaysText(maxDelivery)} of the pickup date`
            return message
        }
    },
};

export const loadStatuses = {
    PENDING_ASSIGNMENT: 1,
    PENDING_ACCEPTANCE: 2,
    ACCEPTED: 3,
    TRANSIT: 4,
    DELIVERED: 5,
    COMPLETE: 6,
    CANCELLED: 7,
    CREATED: 8,
    AMERIT_CONFIRMED: 9,
    EXCEPTION: 10,
    CANCELLED_PARQ: 11
};