import {
    Button,
    Grid,
    Paper,
    Tooltip
} from '@material-ui/core';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import useMediaQuery from "@material-ui/core/useMediaQuery";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { EntityAuditTooltip } from 'components/EntityAudit/EntityAuditTooltip';
import { groupBy } from "lodash";
import { useSelector } from "react-redux";
import Authorize from '../../../components/Authorize';
import { permissionProfiles } from '../../../components/Authorize/permissionProfiles';
import DropZoneInDialog from '../../../components/DropZoneInDialog';
import LoadingSpinner from '../../../components/LoadingSpinner';
import NotificationProvider from '../../../components/NotificationProvider';
import {
    useGetAllIssuesTrackerQuery,
} from "../../../features/reporting/IssuesTracker/issuesTrackerSlice";
import { useGetReadinessQuery } from '../../../features/vehicles/readiness/readinessSlice';
import FormatService from "../../../services/formatService";
import mobileBreakpoint from "../../../utils/mobileBreakpoint";
import { useGetClientsQuery } from "../../clients/clientSlice";
import { useGetLotsQuery } from '../../lots/lotSlice';
import { selectIsAdmin } from "../../user/userSlice";
import {
    useDocumentConditionReportMutation
} from '../ConditionReportSlice';
import AssetReadinessStatuses from '../components/AssetReadinessStatuses';
import { useGetConfigurationByVehicleQuery } from "../configurationSlice";
import {
    useGetFuelTypesQuery,
    useGetVehicleStatusQuery,
    useGetVehicleTypesQuery,
    useUploadDocumentMutation
} from '../vehicleSlice';
import { vehicleDetailsPageStyle } from "./VehicleDetailsPageStyle";
import { useGetDriversQuery } from 'features/drivers/driversSlice';


const VehicleDetailsSummary = ({ vehicleDetails, minimalSummary = false, onEdit }) => {
    const classes = vehicleDetailsPageStyle();
    const isMobile = useMediaQuery(mobileBreakpoint);
    const isAdmin = useSelector(selectIsAdmin);

    let [uploaddocument, { isLoading: uploadingDoc, isError, isSuccess }] = useUploadDocumentMutation();
    let { data: issuesTracker, error: issuesTrackerError, isFetching: isLoadingIssuesTracker } = useGetAllIssuesTrackerQuery({ includeIssueTrackerHistory: false });
    let [documentConditionReport] = useDocumentConditionReportMutation();
    let { data: drivers, error: driverError, isLoading: isLoadingDrivers } = useGetDriversQuery();

    const driversLookup = drivers?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    let {
        data: lots,
        error: lotError,
        isLoading: isLoadingLots,
    } = useGetLotsQuery();
    let {
        data: vehicleTypes,
        error: vehicleTypesError,
        isLoading: isLoadingVehicleTypes
    } = useGetVehicleTypesQuery();
    let {
        data: statuses,
        error: statusError,
        isLoading: isLoadingStatuses
    } = useGetVehicleStatusQuery();
    let {
        data: clients,
        error: clientError,
        isLoading: isLoadingClients,
    } = useGetClientsQuery(null, { skip: !isAdmin });
    let {
        data: configuration, error: configurationError,
        isLoading: isLoadingConfiguration
    } = useGetConfigurationByVehicleQuery(vehicleDetails?.id);

    let { data: vehiclesReadiness, error: vehiclesReadinessError, isFetching: isLoadingVehiclesReadiness } = useGetReadinessQuery({});
    let { data: fuelTypes, error: fuelTypesError, isLoading: isLoadingFuelTypes, isSuccess: isSuccessFuelTypes } = useGetFuelTypesQuery();

    issuesTracker = issuesTracker || [];

    const [isDownloading, setIsDownloading] = useState(false);
    const [openToolTipLotIssueTracker, setOpenToolTipLotIssueTracker] = useState(false);

    clients = clients || [];
    statuses = statuses || [];
    vehicleTypes = vehicleTypes || [];

    const readinessByVehicleId = groupBy(vehiclesReadiness, r => r.vehicleId)

    let vehicleType = vehicleTypes.find(s => s.id === vehicleDetails?.vehicleTypeId)?.name;
    let vehicleStatus = statuses.find(s => s.id === vehicleDetails?.vehicleStatusId)?.name;

    if (!vehicleDetails) {
        return (
            <div className={classes.noAssetDiv}>
                <h2>This asset does not exist</h2>
            </div>
        )
    }

    const findLot = (lot_id) => {
        return lots?.find(l => l.id === lot_id) || {};
    }


    const uploadDocument = async (file) => {
        const d = new Date();
        let fileBase64 = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            fileBase64 = reader.result.toString().replace(/^data:(.*,)?/, '');
            const res = uploaddocument({
                "id": 0,
                "vehicleId": vehicleDetails?.id,
                "fileUrl": "",
                "filename": file.name,
                "file_type": file.type,
                "createdAt": d.toISOString(),
                "is_Deleted": 0,
                "filedata": fileBase64
            })
        };
    };

    const handleToolTipLotIssueTrackerClose = () => {
        setOpenToolTipLotIssueTracker(false);
    }

    const handleToolTipLotIssueTrackerOpen = () => {
        setOpenToolTipLotIssueTracker(true);
    }

    let client = clients?.find(c => c.clientAccounts.find(ca => ca.id === vehicleDetails?.customerId));
    let lot = findLot(vehicleDetails?.lotId);

    lot = lot || {};

    let conditionReportUrl = vehicleDetails?.conditionReportUrl;

    if (vehicleDetails?.conditionReportId) {
        conditionReportUrl = `/assets/condition/${vehicleDetails?.id}`;
    }

    let dateIn = FormatService.formatDate(vehicleDetails?.dateIn);
    let dateOut = vehicleDetails?.dateOut;
    if (dateOut) {
        dateOut = FormatService.formatDate(vehicleDetails?.dateOut);
    }

    let driverName = driversLookup?.[vehicleDetails?.driverId]?.firstName && driversLookup?.[vehicleDetails?.driverId]?.lastName ? driversLookup?.[vehicleDetails?.driverId]?.firstName?.trim() + ' ' + driversLookup?.[vehicleDetails?.driverId]?.lastName?.trim() : null

    const onDownloadCR = async () => {
        setIsDownloading(true);
        let downloadEntity = {
            VehicleId: vehicleDetails?.id,
            ClientAccountId: vehicleDetails?.customerId,
            RequestedFrom: "Web"
        };
        let result = await documentConditionReport(downloadEntity);

        if (result && !result.error) {
            downloadBase64File(result?.data?.type, result?.data?.content, result?.data?.name);
        } else {
            console.log("Error", result);
            let concatenateError = result?.error?.data?.messages[0];
            NotificationProvider.error(concatenateError);
        }

        setIsDownloading(false);
    };

    function downloadBase64File(contentType, base64Data, fileName) {
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }


    const getAssetIssueTrackerDescription = () => {

        let lotObj = issuesTracker.find(x => x.assetId === vehicleDetails?.assetId && x.clientId == client?.id);

        if (!lotObj) {
            return;
        }

        return lotObj.description;
    }

    let isConditionalReportEnable = configuration?.isConditionalReportEnabled;

    const fields = [
        {
            key: 'client',
            label: 'Client',
            value: client?.name,
            visible: !!client
        },
        {
            key: 'lot',
            label: 'Lot',
            value: lot.name,
        },
        {
            key: 'status',
            label: 'Status',
            value: vehicleStatus,
        },
        {
            key: 'make',
            label: 'Make',
            value: vehicleDetails?.make,
        },
        {
            key: 'model',
            label: 'Model',
            value: vehicleDetails?.model,
        },
        {
            key: 'year',
            label: 'Year',
            value: vehicleDetails?.year,
        },
        {
            key: 'type',
            label: 'Type',
            value: vehicleType,
        },
        {
            key: 'assetId',
            label: 'Asset Id',
            value: vehicleDetails?.assetId,
        },
        {
            key: 'mileage',
            label: 'Mileage',
            value: vehicleDetails?.mileage ? FormatService.formatNumberWithCommas(vehicleDetails?.mileage) : '',
        },
        {
            key: 'domicile',
            label: 'Domicile',
            value: <Tooltip title={vehicleDetails?.domicileLotAutoCalculated ? 'Automatically set' : 'Manually set'}><div>{lots?.find((lot) => lot.id == vehicleDetails.domicileLot)?.name}</div></Tooltip>
        },
        {
            key: 'driverName',
            label: 'Driver name',
            value: driverName,
            visible: !!driverName
        },
        {
            key: 'driverCarrier',
            label: 'Carrier',
            value: vehicleDetails?.driverCarrier,
            visible: !!vehicleDetails?.driverCarrier
        },
        {
            key: 'gasLevel',
            label: 'Gas Level',
            value: vehicleDetails?.gasLevel,
            visible: !!vehicleDetails?.gasLevel
        },
        {
            key: 'fuelType',
            label: 'Fuel type',
            value: fuelTypes?.find(f => f.id == vehicleDetails?.fuelType)?.name
        }
    ];

    return (
        <>
            <Paper className={`${classes.card} ${isMobile ? classes.cardMobile : ""}`} >
                <Grid container alignItems="center" justifyContent="space-between" spacing={2}
                    className={`${classes.titleRow} ${isMobile ? classes.titleRowMobile : ""}`}>
                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Grid container alignItems='center'>
                                    <img src="/icons/icon-wheel.svg" className={classes.icon} alt="Asset ID" />
                                    <h2 className={`${classes.title} ${isMobile ? classes.titleRowMobile : ""}`}>{vehicleDetails?.descriptor ?? vehicleDetails?.assetId}</h2>

                                    {readinessByVehicleId[vehicleDetails.id] &&
                                        <Grid item className={`${classes.detail} ${isMobile && classes.detailMobile}`}>
                                            <AssetReadinessStatuses loading={isLoadingVehiclesReadiness} readinessStatus={readinessByVehicleId[vehicleDetails.id][0]} editable />
                                        </Grid>}
                                </Grid>
                            </Grid>


                        </Grid>
                    </Grid>
                    {!minimalSummary && <Grid item>
                        <Grid container spacing={1} justifyContent="flex-end" alignItems='center' >
                            <Grid item>
                                <EntityAuditTooltip entity={vehicleDetails} />
                            </Grid>
                            <Grid item>
                                {getAssetIssueTrackerDescription() &&
                                    <NavLink exact
                                        className={classes.link}
                                        to={`/issues/tracker/asset-${vehicleDetails?.assetId}`}>
                                        <Tooltip style={{ width: '0.8em', marginTop: '5px' }} title={getAssetIssueTrackerDescription()} placement="top" open={openToolTipLotIssueTracker} onClose={handleToolTipLotIssueTrackerClose} onOpen={handleToolTipLotIssueTrackerOpen} >
                                            <ErrorOutlineIcon title='View Lot Issue Tracker' />
                                        </Tooltip>
                                    </NavLink>
                                }
                            </Grid>
                            {
                                isConditionalReportEnable &&
                                <Authorize profile={permissionProfiles.CONDITION_REPORT.ConditionReport}>
                                    <Grid item alignItems='flex-end'>
                                        <Grid item >
                                            {onDownloadCR &&
                                                <Button className={classes.editButton}
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={() => onDownloadCR()}
                                                    disabled={isDownloading}>
                                                    {isDownloading ? "Downloading..." : "Download Condition Report"}
                                                </Button>
                                            }
                                        </Grid>
                                    </Grid>
                                </Authorize>
                            }
                            <Authorize profile={permissionProfiles.ASSETS.ASSET_EDIT}>
                                <Grid item alignItems='flex-end'>
                                    <Grid item >
                                        <Button className={classes.editButton} color="primary" variant="contained" onClick={() => onEdit()}>
                                            Edit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Authorize>
                            <Authorize profile={permissionProfiles.ASSETS.ASSET_EDIT}>
                                <Grid item>
                                    <DropZoneInDialog
                                        labelDialog={'Upload Document'}
                                        buttonLabel={'Upload Document'}
                                        onSave={uploadDocument}
                                        formContent={
                                            <div className={classes.clientWrapper}>

                                            </div>
                                        }
                                        accept={{
                                            'application/pdf': ['.pdf'],
                                            'image/jpg': ['.jpg'],
                                            'image/png': ['.png'],
                                            'image/jpeg': ['.jpeg']
                                        }}
                                        actionButtonLabel="Upload"
                                    />
                                    <LoadingSpinner loading={uploadingDoc} />
                                </Grid>
                            </Authorize>
                            {
                                !!conditionReportUrl &&
                                <Grid item alignItems="flex-end">
                                    <Button color="primary" variant="contained" href={conditionReportUrl}
                                        target="_blank">Condition Report</Button>
                                </Grid>

                            }
                        </Grid>
                    </Grid>}
                </Grid>
                <Grid container spacing={2} className={classes.dateInfoWrapper}>
                    <Grid item>
                        <Grid container className={classes.dateInfo}>
                            <div className={`${classes.detailLabel} ${classes.dateIn}`}>{dateIn ? "Last In" : ""}</div>
                            <div>{dateIn}</div>
                        </Grid>
                    </Grid>
                    {
                        dateOut
                            ? <>
                                <Grid item>
                                    <Grid container className={classes.dateInfo}>
                                        <div className={`${classes.detailLabel} ${classes.dateOut}`}>Last Out:</div>
                                        <div>{dateOut}</div>
                                    </Grid>
                                </Grid>
                            </>
                            : null
                    }
                </Grid>
                {!minimalSummary && <Grid container spacing={2}>
                    {fields?.filter(field => field.visible == undefined || field.visible).map(field => <Grid item xs={3} className={`${classes.detail} ${isMobile && classes.detailMobile}`}>
                        <Grid container>
                            <div className={classes.detailLabel}>{field.label}: </div>
                            <div>{field.value ?? '-'}</div>
                        </Grid>
                    </Grid>)}
                </Grid>}
            </Paper>
        </>
    );
};

export default VehicleDetailsSummary;
