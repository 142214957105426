import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import PermissionList from 'components/Authorize/permissions/PermissionList/PermissionList';
import MobileActivityPage from 'features/MobileActivity/MobileActivityPage';
import ActivityInvoicePage from 'features/invoice/activity/components/ActivityInvoicePage';
import KpiInspection from 'features/landing/components/KpiDashboard/navigations/KpiInspection/KpiInspection';
import OneOnOnesPage from 'features/landing/components/KpiDashboard/navigations/KpiOneOnOne/OneOnOnesPage';
import { ActivityListPage } from 'features/landing/components/MechanicalDashboard/ActivityList/ActivityListPage';
import LotOverview from 'features/lots/components/LotOverview';
import PricingPage from 'features/pricing/PricingPage';
import UsersPage from 'features/users/components/UsersPage';
import AuditList from 'features/vehicles/VehicleLotAudit/components/AuditList';
import { Route, Switch } from 'react-router-dom';
import ExternalConfirmation from './components/ExternalConfirmation';
import AccessPage from "./features/access/AccessPage";
import ContractsPage from './features/clients/contractsPage/ContractsPage';
import InventoryPage from './features/inventory/InventoryPage';
import InvoicePage from './features/invoice/loads/components/InvoicePage/InvoicePage';
import StorageInvoicePage from './features/invoice/storage/components/StorageInvoicePage';
import DashboardPage from './features/landing/DashboardPage';
import LoadDashboard from './features/loads/components/LoadDashboard';
import LoadDetails from './features/loads/components/LoadDetails';
import LoadsPage from './features/loads/components/LoadsPage/LoadsPage';
import CallbackPage from './features/login/CallbackPage';
import LogoutPage from './features/login/LogoutPage';
import PrivateRoute from './features/login/PrivateRoute';
import NewLotInspection from "./features/lotInspection/lotInpecionNew";
import LotsTabs from './features/lots/components/LotsTabs/LotsTabs';
import MePassword from './features/me/password';
import MechanicalWorkTask from "./features/mechanicalWork/mechanicalWorkTask";
import IssuesTrackerReportPage from "./features/reporting/IssuesTracker/IssuesTrackerPage";
import ReportsPage from "./features/reporting/ReportsPage";
import SubscriptionPage from './features/subscriptions/SubscriptionPage';
import TransporterPage from "./features/transporter/TransporterPage";
import UserPage from './features/user/components/UserPage';
import ConditionReportPage from "./features/vehicles/ConditionReportPage";
import VehiclesFileUploadPage from "./features/vehicles/FileUploadPage";
import VehicleDetailsPage from './features/vehicles/VehicleDetailsPage';
import VehicleLotAudit from './features/vehicles/VehicleLotAudit';
import VehiclesPage from './features/vehicles/VehiclesPage';

const routes = () => (
    <Switch>
        <PrivateRoute path="/logout" component={LogoutPage} />
        <PrivateRoute path="/resetpassword" component={MePassword} />
        <PrivateRoute path="/workoder/:id" component={MechanicalWorkTask} />
        <PrivateRoute path="/permissions" component={PermissionList} />
        <Route path="/confirm" component={ExternalConfirmation} />
        <PrivateRoute path="/subscriptions" component={SubscriptionPage} />
        <PrivateRoute path="/user" component={UserPage} />
        <PrivateRoute path="/transporters" component={TransporterPage} />
        <PrivateRoute path="/slas" component={ContractsPage} />
        <PrivateRoute path="/lots/overview/:id" component={LotOverview} />
        <PrivateRoute path="/lots" component={LotsTabs} />
        <PrivateRoute path="/assets/fileUpload" component={VehiclesFileUploadPage} />
        <PrivateRoute path="/assets/detail/:id" component={VehicleDetailsPage} />
        <PrivateRoute path="/assets/condition/:id" component={ConditionReportPage} />
        <PrivateRoute path="/assets/audit/:id" component={VehicleLotAudit} />
        <PrivateRoute path="/assets/audit" component={AuditList} />
        <PrivateRoute path="/assets" component={VehiclesPage} />
        <PrivateRoute path="/loads/list" component={LoadsPage} />
        <PrivateRoute path="/loads" component={LoadDashboard} />
        <PrivateRoute path="/loads/:id" component={LoadDetails} />
        <PrivateRoute path="/reports" component={ReportsPage} />
        <Route path="/callback" component={CallbackPage} />
        <PrivateRoute path="/access" component={AccessPage} />
        {/* <PrivateRoute path="/access" component={AccessPage_old} /> */}
        <PrivateRoute path="/dashboard/:type" component={DashboardPage} />
        <PrivateRoute path="/dashboard" component={DashboardPage} />
        <PrivateRoute path="/mechanical/:status" component={ActivityListPage} />
        <PrivateRoute path="/invoices/loads/new/:loadId" component={InvoicePage} />
        <PrivateRoute path="/invoices/loads" component={InvoicePage} />
        <PrivateRoute path="/invoices/storage" component={StorageInvoicePage} />
        <PrivateRoute path="/invoices/activity" component={ActivityInvoicePage} />
        <PrivateRoute path="/users" component={UsersPage} />
        <PrivateRoute path="/oneonones" component={OneOnOnesPage} />
        <PrivateRoute path="/oneonones/new" component={OneOnOnesPage} />
        <PrivateRoute path="/issues/tracker" component={IssuesTrackerReportPage} />
        <PrivateRoute path="/issues/tracker/:param" component={IssuesTrackerReportPage} />
        <PrivateRoute path="/inspection/:id" component={NewLotInspection} />
        <PrivateRoute path="/inspection" component={KpiInspection} />
        <PrivateRoute path="/inspection/new" component={NewLotInspection} />
        <PrivateRoute path="/mobile/activity" component={MobileActivityPage} />
        <PrivateRoute path="/pricing" component={PricingPage} profile={permissionProfiles.PRICING.MANAGE_PPRICING} />
        <PrivateRoute path="/inventory" component={InventoryPage} />
        <PrivateRoute path="/" component={LotsTabs} />
    </Switch>
);

export default routes;
