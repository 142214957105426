import { CreatableDropdown } from 'components/CreatableDropdown/CreatableDropdown';
import LoadingSpinner from 'components/LoadingSpinner';
import NotificationProvider from 'components/NotificationProvider';
import { setAccessToEdit } from 'features/access/accessStateSlice';
import { useAddServiceProviderMutation, useGetServiceProvidersQuery } from 'features/serviceProvider/serviceProviderSlice';
import { useDispatch, useSelector } from 'react-redux';

const VendorFields = ({ }) => {
    let { data: serviceProviders, error: serviceProvidersError, isLoading: isLoadingServiceProviders } = useGetServiceProvidersQuery();
    const [addServiceProvider, { isLoading: isAdding }] = useAddServiceProviderMutation();

    const { accessToEdit } = useSelector(state => state.accessState);
    const dispatch = useDispatch();

    const onChangeValue = (key, val) => {
        dispatch(setAccessToEdit({...accessToEdit, [key]: val}))
    }

    const onAddServiceProvider = async (val) => {
        let result = await addServiceProvider(val);
        if (result && !result.error) {
            NotificationProvider.success("Vendor added successfully");
        } else {
            NotificationProvider.error("Failed to save the vendor");
        }
        return result;
    }

    return (
        <>
            <LoadingSpinner loading={isAdding} />
            <CreatableDropdown
                options={serviceProviders}
                value={accessToEdit?.serviceProviderId}
                required
                addValue={onAddServiceProvider}
                label='Vendor Name'
                onChange={(val) => onChangeValue("serviceProviderId", val)}
            />
        </>
    );
}

export default VendorFields;