import { baseApi } from '../../app/baseApi';
import { baseQuery } from "../../app/baseQuery";
import { apiTags } from '../../app/store/apiTags';

const apiPrefix = 'inventory';
export const inventoryApi = baseApi.injectEndpoints({
    reducerPath: 'inventoryApi',
    tagTypes: [apiTags.INVENTORY],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAllInventory: builder.query({
            query: () => `${apiPrefix}`,
            providesTags: [apiTags.INVENTORY],
        }),
        addInventory: builder.mutation({
            query: (inventory) => ({
                url: `${apiPrefix}`,
                method: "POST",
                body: inventory
            }),
            invalidatesTags: [apiTags.INVENTORY],
        }),
        updateInventory: builder.mutation({
            query: (inventory) => ({
                url: `${apiPrefix}`,
                method: "PUT",
                body: inventory
            }),
            invalidatesTags: [apiTags.INVENTORY]
        }),
        adjustmentInventory: builder.mutation({
            query: (inventory) => ({
                url: `${apiPrefix}/Adjusment`,
                method: "POST",
                body: inventory
            }),
            invalidatesTags: [apiTags.INVENTORY]
        }),
        restockInventory: builder.mutation({
            query: (inventory) => ({
                url: `${apiPrefix}/Restock`,
                method: "POST",
                body: inventory
            }),
            invalidatesTags: [apiTags.INVENTORY]
        })
    }),
});

export const {
    useGetAllInventoryQuery,
    useAddInventoryMutation,
    useUpdateInventoryMutation,
    useAdjustmentInventoryMutation,
    useRestockInventoryMutation
} = inventoryApi;