//import { billingDashboardStyle } from "../style";

import DataListHeader from "components/DataListHeader";
import DataTable from "components/DataTable";
import useSortOptions from "hooks/useSortOptions";
import { orderBy } from "lodash";
import GraphHeader from "../GraphHeader";
import { useSelector } from "react-redux";


const BillingDataTable = ({ data }) => {
    //const classes = billingDashboardStyle();

    const { showCost, showCharge } = useSelector(state => state.billingDashboardState);

    let columns = [
        { name: "Program", key: "programName" },
        { name: "Lot", key: "lotName" },
        { name: "Date", key: "formattedDate", sortKey: 'date' },

    ];
    if (showCost) {
        columns.push({ name: "Total Cost", key: "totalCost", component: (val) => `$${val.toFixed(2)}` });
    }

    if (showCharge) {
        columns.push({ name: "Total Charge", key: "totalChargeToClient", component: (val) => `$${val.toFixed(2)}` });
    }

    columns.push({ name: "Total Revenue", key: "totalRevenue", component: (val) => `$${val.toFixed(2)}` });

    const [sortOptions, sortData] = useSortOptions();


    const mapExportData = row => {
        const detailsInfo = {};
        columns?.map(col => {
            detailsInfo[col.name] = row?.[col.key]
        });
        return detailsInfo;
    };

    return (
        <div style={{ height: 500 }}>
            <GraphHeader title={'Billing Data'} mapExportData={mapExportData} data={data} />
            <DataTable
                columns={columns}
                rows={orderBy(
                    data,
                    sortOptions.columnToSort,
                    sortOptions.sortDirection
                )}
                rowIdentifier='id'
                noItemsMessage={'No data found'}
                onSort={sortData}
                sortDirection={sortOptions.sortDirection}
                columnToSort={sortOptions.columnToSort}
            />
        </div>
    )
}

export default BillingDataTable;
