import { Checkbox, FormControlLabel, Grid, Paper } from '@material-ui/core';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import LoadingSpinner from 'components/LoadingSpinner';
import { useGetClientVehicleTypesQuery, useGetVehicleTypesQuery } from 'features/vehicles/vehicleSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedVehicleTypes } from '../../workFlowSlice';
import ClientVehicleTypesStyle from './ClientVehicleTypesStyle';

const useStyles = ClientVehicleTypesStyle;

const ClientVehicleTypes = ({ clientId }) => {
    const classes = useStyles();
    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes, isSuccess: isVehicleTypesSuccess } = useGetVehicleTypesQuery();
    let { data: clientVehicleTypes, error: clientVehicleTypesError, isLoading: isLoadingclientVehicleTypes, isSuccess: isClientVehicleTypesSuccess } = useGetClientVehicleTypesQuery();

    const dispatch = useDispatch();
    const { selectedVehicleTypes } = useSelector(state => state.mobileWorkflowState);

    useEffect(() => {
        if (!isLoadingclientVehicleTypes) {
            updateSelectedTypes(clientVehicleTypes?.filter(c => c.clientId == clientId).map(c => c.vehicleTypeId));
        }
    }, [clientId, isLoadingclientVehicleTypes]);

    const updateSelectedTypes = (types) => {
        dispatch(setSelectedVehicleTypes(types))
    }
    const onChange = (value, selected) => {
        if (selected) {
            updateSelectedTypes([...selectedVehicleTypes, value])
        } else {
            updateSelectedTypes(selectedVehicleTypes?.filter(s => s != value));
        }

    }
    const isLoading = isLoadingclientVehicleTypes || isLoadingVehicleTypes;
    return (
        <>
            {isLoading ?
                <LoadingSpinner loading={isLoading} /> :
                <Paper>
                    <Grid className={classes.container}>
                        {vehicleTypes?.map(v =>
                            <Grid key={v.id}>
                                <Grid>
                                    <FormControlLabel
                                        control={<Authorize profile={permissionProfiles.CLIENT_CONFIG.WORKFLOW_SLA_EDIT}><Checkbox
                                            checked={selectedVehicleTypes?.includes(v.id)}
                                            onChange={(e, selected) => onChange(v.id, selected)}
                                        /></Authorize>}
                                        label={v.name} />
                                </Grid>

                            </Grid>
                        )}
                    </Grid>
                </Paper>
            }
        </>
    );
};

export default ClientVehicleTypes;


