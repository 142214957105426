import { Button, Grid, Paper } from "@material-ui/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../../../../components/CustomInput";
import usePrompt from "../../../../../hooks/usePrompt";
import { IconDollar, IconPercent, IconSlip } from "../../../../../icons";
import { resetSelected, setBulkEdit } from "../lotConfigSlice";
import LotsConfigStyle from "../LotsConfigStyle";

const useStyles = LotsConfigStyle

export const BulkEditLot = ({ onBulkSave, onSelectAll }) => {

    const classes = useStyles();

    const [lotToEdit, setLotToEdit] = useState({
        numberOfSpots: null,
        pricePerSpot: null,
        overage: null
    });

    const [isSaving, setIsSaving] = useState(false);

    const { bulkEdit, selectedLots, selectedSubmarkets } = useSelector(state => state.lotConfigState);

    const { triggerPrompt } = usePrompt();
    const dispatch = useDispatch();

    const onFieldValueChange = (fieldName, value) => {
        setLotToEdit({
            ...lotToEdit,
            [fieldName]: value,
        })
    }

    const editField = (fieldName, label, numberOnly, icon) => (
        <Grid item alignItems='center' container className={classes.input} >
            <Grid xs={6} className={classes.label}>{label} </Grid>
            <Grid xs={6} className={classes.inputField}>
                {<CustomInput
                    value={lotToEdit?.[fieldName] ?? ''}
                    onChange={(val) => {
                        onFieldValueChange(fieldName, val)
                    }}
                    icon={icon}
                    elementType="input"
                    numberOnly={numberOnly}
                />}
            </Grid>
        </Grid>
    )
    const onSave = async () => {
        setIsSaving(true);
        await onBulkSave(lotToEdit);
        setIsSaving(false);
    }

    return (
        <Paper className={`${classes.listItemWrapper} ${classes.listItemWrapperEdit} ${classes.listItemWrapperBulk}`}>
            <Grid container direction='column'>
                <Grid item container direction="column">
                    <Grid className={classes.bulkTitle}>
                        Bulk Update
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={1} justifyContent="flex-end">
                        {editField('numberOfSpots', 'Total spots', true, <IconSlip />)}
                        {editField('pricePerSpot', 'Price per spot', true, <IconDollar />)}
                        {editField('overage', 'Overage', true, <IconDollar />)}
                        <Grid item className={classes.btnWrapper}>
                            <Button
                                onClick={() => {
                                    dispatch(setBulkEdit(false));
                                    return;
                                }}
                                className={classes.button}
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={onSave}
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                disabled={!!(!selectedLots?.length || isSaving)}
                            >
                                {isSaving ? "Saving..." : "Save"}
                            </Button>
                        </Grid>
                    </Grid >
                    <Grid className={classes.bulkCountTitle} item container justifyContent="space-between" alignItems="center">
                        <div>
                            {selectedLots?.length} selected lot{selectedLots?.length > 1 ? 's' : ''}
                            <br />
                            {selectedSubmarkets?.length} selected sub market{selectedSubmarkets?.length > 1 ? 's' : ''}
                            <div>
                                <Button
                                    onClick={onSelectAll}
                                    className={classes.button}
                                    color="primary"
                                >
                                    Select All
                                </Button>
                                <Button
                                    onClick={() => {
                                        dispatch(resetSelected());
                                        return;
                                    }}
                                    className={classes.button}
                                    color="primary"
                                >
                                    Clear All
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Paper >
    )
}


