import { baseApi } from '../../app/baseApi';
import { apiTags } from '../../app/store/apiTags';

export const loadApi = baseApi.injectEndpoints({
    reducerPath: 'loadApi',
    endpoints: (builder) => ({
        getLoads: builder.query({
            query: (body) => ({
                url: body?.lmStatus ? `load?lmStatus=${body?.lmStatus}` : `load`
            }),
            providesTags: [apiTags.LOADS],
            transformResponse: (response) => {
                return response.map(load => ({
                    ...load,
                    calcPickupDate: load.confirmedPickupDate ? load.confirmedPickupDate : load.pickupStartDate,
                    calcDeliveryDate: load.confirmedDeliveryDate ? load.confirmedDeliveryDate : load.deliveryEndDate,
                    vinsForSearch: load.assets.map(a => a.vin).join('$$'),
                    latestStatusObject: load?.statuses[0] ?? {},
                    latestStatus: load?.statuses[0]?.status ?? {},
                    latestStatusDate: load?.statuses[0]?.statusDate ?? {},
                    actualPickupDate: load?.statuses?.find(s => s.status == 4)?.statusDate,
                    actualDeliveryDate: load?.statuses?.find(s => s.status == 5)?.statusDate,
                    carrierId: load.carrier?.transporterId
                }))
            }
        }),
        getLoad: builder.query({
            query: (latestLoadID) => `load/${latestLoadID}`,
        }),
        getLoadLocations: builder.query({
            query: (loadID) => `load/history/${loadID}`,
        }),
        getLoadAttachments: builder.query({
            query: (loadID) => `load/attachments/${loadID}`,
        }),
        getLoadPayments: builder.query({
            query: (loadID) => `load/payments/${loadID}`,
        }),
        importLoads: builder.mutation({
            query: form => ({
                url: "load/import",
                method: "POST",
                body: form
            }),
            invalidatesTags: [apiTags.LOADS]
        }),
        dispatchLoad: builder.mutation({
            query: (body) => ({
                url: `load/dispatch`,
                method: "POST",
                body: {
                    LoadId: body.loadId,
                    TransporterId: body.transporterId
                }
            }),
            invalidatesTags: [apiTags.LOADS]
        }),
        postLoad: builder.mutation({
            query: (body) => ({
                url: `load/post`,
                method: "POST",
                body
            }),
            invalidatesTags: [apiTags.LOADS]
        }),
        addStatus: builder.mutation({
            query: (body) => ({
                url: `load/status`,
                method: "POST",
                body: {
                    status: body.status,
                    statusDate: body.statusDate,
                    comment: body.comment,
                    loadId: body.loadId
                }
            }),
            invalidatesTags: [apiTags.LOADS, apiTags.VEHICLES]
        }),
        updateStatus: builder.mutation({
            query: (body) => ({
                url: `load/status/edit`,
                method: "POST",
                body: {
                    id: body.id,
                    status: body.status,
                    statusDate: body.statusDate,
                    comment: body.comment,
                    loadId: body.loadId
                }
            }),
            invalidatesTags: [apiTags.LOADS, apiTags.VEHICLES]
        }),
        deleteStatus: builder.mutation({
            query: (id) => ({
                url: `load/status/delete`,
                method: "POST",
                body: { id }
            }),
            invalidatesTags: [apiTags.LOADS, apiTags.VEHICLES]
        }),
        cancelLoad: builder.mutation({
            query: (body) => ({
                url: `load/cancel`,
                method: "POST",
                body
            }),
            invalidatesTags: [apiTags.LOADS, apiTags.VEHICLES]
        }),
        updateActualDate: builder.mutation({
            query: (body) => ({
                url: `load/updateschedule`,
                method: "POST",
                body: body
            }),
            invalidatesTags: [apiTags.LOADS, apiTags.VEHICLES]
        }),
        getLoadsDashboard: builder.query({
            query: (params) => {
                const queryString = Object.keys(params).map(param => params[param] ? `${param}=${params[param]}` : undefined).filter(k => k).join('&');
                return `load/dashboard?${queryString}`
            }
        }),
        addLoad: builder.mutation({
            query: load => ({
                url: "load/add",
                method: "POST",
                body: load
            }),
            invalidatesTags: [apiTags.LOADS, apiTags.VEHICLES, apiTags.LOTS, apiTags.CLIENT_CONFIG]
        }),
        updateLoad: builder.mutation({
            query: load => ({
                url: "load/update",
                method: "POST",
                body: load
            }),
            invalidatesTags: [apiTags.LOADS, apiTags.LOTS, apiTags.CLIENT_CONFIG]
        }),
        getBillingReport: builder.mutation({
            query: body => ({
                url: `load/getbillingreport`,
                method: "POST",
                body: body,
                responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
                cache: "no-cache",
            })
        }),
        getImportLoadTemplate: builder.mutation({
            query: clientId => ({
                url: `load/downloadtemplate`,
                method: "POST",
                body: { clientId },
                responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
                cache: "no-cache"
            })
        }),
    }),
});

export const { useGetLoadsQuery, useGetLoadQuery, useGetLoadLocationsQuery, useGetLoadAttachmentsQuery, useGetLoadPaymentsQuery,
    useImportLoadsMutation, useDispatchLoadMutation, useCancelLoadMutation, useGetLoadsDashboardQuery, useUpdateStatusMutation, useAddStatusMutation, useDeleteStatusMutation,
    useUpdateActualDateMutation, usePostLoadMutation, useAddLoadMutation, useUpdateLoadMutation, useGetBillingReportMutation, useGetImportLoadTemplateMutation
} = loadApi;
