import { Button, CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DateRange from '../../../../components/DateRange';
import { useGetBillingReportMutation } from '../../../loads/loadsSlice';
import { getBillingReportStyle } from './GetBillingReportStyle';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner'
import { useGetClientsQuery } from '../../../clients/clientSlice';
import NotificationProvider from '../../../../components/NotificationProvider';
import Authorize from '../../../../components/Authorize';
import { permissionProfiles } from '../../../../components/Authorize/permissionProfiles';


const GetBillingReport = ({ clientId }) => {
    const classes = getBillingReportStyle()
    let [getbillingreport, { data: billingreport, error: reportError, isLoading: reportLoading }] = useGetBillingReportMutation();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSave = async () => {
        setLoading(true);
        let body = {
            startDate: startDate,
            endDate: endDate,
            clientId: clientId
        };
        const result = await getbillingreport(body);
        if (result && !result.error) {
            handleClose();
            setLoading(false);
            NotificationProvider.success("Downloaded billing report successfully");
        } else {
            handleClose();
            setLoading(false);
            NotificationProvider.error("Something happened, try again or contact support");
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onDateSelect = (value) => {
        setStartDate(value?.startDate);
        setEndDate(value?.endDate);
    }

    return (
        <>
            <Authorize profile={permissionProfiles.CLIENT_CONFIG.CLIENT_CONFIG_BILLING_REPORT}>
                <Button
                    variant="contained"
                    color="primary"
                    //startIcon={<CloudUploadOutlined />}
                    onClick={handleClickOpen}
                >
                    Get Billing Report
                </Button>
            </Authorize>


            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Billing Report</DialogTitle>
                <DialogContent>
                    {
                        loading ? <LoadingSpinner loading={loading} /> :
                            <div>
                                <DateRange
                                    title={"Select Report Date"}
                                    identifier={"dt"}
                                    onSelect={onDateSelect}
                                    dateValue={null}
                                />
                            </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <label>
                        <Button variant="contained" color="primary"
                            component="span" onClick={onSave}
                            disabled={!startDate || !endDate}>
                            {"submit"}
                        </Button>
                    </label>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default GetBillingReport;