import { Grid } from "@material-ui/core";
import React from "react";
import FormatService from "../../../../../../services/formatService";
import { TaskItemStyles } from "../../TaskItemStyles";
import { useGetAllActivityItemsQuery } from "features/activity/activitySlice";

const MechanicalTask = ({ task }) => {
    const classes = TaskItemStyles();

    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();

    const field = (label, value) => {
        return <Grid container direction="row"><Grid xs={4}><label className={classes.label}>{label}</label></Grid><Grid xs={8}> {value}</Grid></Grid>;
    }

    return (
        <Grid container direction='column'>
            {task?.lot && field('Lot', task?.lot?.name)}
            {field('Activity', activityItems?.find(a => a.id == task.activityItemId)?.name)}
            {!!task?.vehicle?.descriptor && field('VIN', task?.vehicle?.descriptor)}
            {!!task?.vehicle?.assetId && field('Asset Id', task?.vehicle?.assetId)}
            {field('Date', FormatService.formatDate(task?.activityDate))}
        </Grid>
    )
}
export default MechanicalTask;