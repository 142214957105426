export const activityPermissions = {
    ADD_PRICE: {
        key: 'Activity.AddPrice',
        name: 'Add prices',
        description: 'Allows entering a price for pending approval activitites'
    },
    APPROVE_ABOVE_PRICE_CAP: {
        key: 'Activity.ApprovePrice',
        name: 'Approve prices',
        description: 'Allows approving activity prices which exceed the customer defined price cap'
    }, 
    MANAGE_PENDING_INVOICE: {
        key: 'Activity.PendingInvoice',
        name: 'Manage pending invoices',
        description: 'Allows managing activities in the pending invoice status and moving them to the completed status'
    }
}
