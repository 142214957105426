import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from "../../app/baseQuery";
import { apiTags } from 'app/store/apiTags';
import { baseApi } from 'app/baseApi';

const apiPrefix = 'serviceProvider';
export const serviceProviderApi = baseApi.injectEndpoints({
    reducerPath: 'serviceProviderApi',
    tagTypes: [apiTags.SERVICE_PROVIDER],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getServiceProviders: builder.query({
            query: () => `${apiPrefix}`,
            providesTags: [apiTags.SERVICE_PROVIDER],
        }),
        addServiceProvider: builder.mutation({
            query: (serviceProvider) => ({
                url: `${apiPrefix}`,
                method: "POST",
                body: serviceProvider
            }),
            invalidatesTags: [apiTags.SERVICE_PROVIDER],
        }),
    }),
});

export const { 
    useGetServiceProvidersQuery,
    useAddServiceProviderMutation
 } = serviceProviderApi;
