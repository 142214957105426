import React, {useState} from 'react';
import { Button, Checkbox, FormControlLabel, Grid, Paper } from "@material-ui/core";
import formStyles from "../../../features/vehicles/formStyles";
import DateRange from '../../../components/DateRange';
import CustomInput from "../../../components/CustomInput";
import { useGetLotsQuery } from "../../lots/lotSlice";
import DropdownFilter from "../../../components/DropdownFilter";
import {
    useAddIssueTrackerMutation,
    useUpdateIssueTrackerMutation,
    useGetStatusIssueTrackerQuery
} from "./issuesTrackerSlice";
import { useGetClientsQuery } from "../../clients/clientSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAdmin } from "../../user/userSlice";

const useStyles = formStyles;

const AddIssueTracker = ({handleClose, issueTracker, clientId}) => {
    const classes = useStyles();
    const isAdmin = useSelector(selectIsAdmin);

    const [addIssueTracker, { isLoading }] = useAddIssueTrackerMutation();
    const [updateIssueTracker, { isUpdateLoading }] = useUpdateIssueTrackerMutation();
    let [issueTrackerToAdd, setIssueTrackerToAdd] = useState(issueTracker);
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: statusIssuesTrackers, error: statusIssuesTrackerError, isFetching: isLoadingStatusIssuesTracker } = useGetStatusIssueTrackerQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null, { skip: !isAdmin });
    lots = lots || [];
    clients = clients || [];
    statusIssuesTrackers = statusIssuesTrackers || [];

    const onSubmit = async () => {
        issueTrackerToAdd.lotId = parseInt(issueTrackerToAdd.lotId);
        issueTrackerToAdd.clientId = parseInt(issueTrackerToAdd.clientId);
        issueTrackerToAdd.statusId = parseInt(issueTrackerToAdd.statusId);
        
        let result;
        if (issueTrackerToAdd.id) {
            result = await updateIssueTracker(issueTrackerToAdd);
        }
        else {
            result = await addIssueTracker(issueTrackerToAdd);
        }

        if (result && !result.error) {
            console.log("Issue Tracker added successfully");
            handleClose();
        } else {
            console.log("Something happened, try again or contact support");
        }

        handleClose();
    };

    const onChange = async (key, val) => {
        setIssueTrackerToAdd({ ...issueTrackerToAdd, [key]: val});
    };

    return (
        <>
            <Paper className={classes.formContainer}>
            <form >
                <div className={classes.input}>
                    <DropdownFilter
                        title='Client'
                        identifier='clientId'
                        values={clients || []}
                        onSelect={(event) => onChange("clientId", event)}
                        value={issueTrackerToAdd.clientId}/>
                </div>
                { issueTrackerToAdd.id && 
                    <div className={classes.input}>
                    <DropdownFilter
                        title='Status'
                        identifier='statusId'
                        showEmpty
                        values={statusIssuesTrackers || []}
                        onSelect={(event) => onChange("statusId", event)}
                        value={issueTrackerToAdd.statusId}/>
                    </div>
                }
                
                <div className={classes.input}>
                    <DropdownFilter
                        title='Lot'
                        identifier='lotId'
                        showEmpty
                        values={lots || []}
                        onSelect={(event) => onChange("lotId", event)}
                        value={issueTrackerToAdd.lotId}/>
                </div>
                <div className={classes.input}>
                    <CustomInput
                        id="assetId"
                        label="Asset Id"
                        value={issueTrackerToAdd.assetId}
                        elementType="input"
                        onChange={val => onChange("assetId", val)}
                    />
                </div>
                <div className={classes.input}>
                    <CustomInput
                        id="description"
                        label="Description"
                        value={issueTrackerToAdd.description}
                        elementType="input"
                        onChange={val => onChange("description", val)}
                        required={true}
                    />
                </div>
            </form>
            <div className={classes.btnContainer}>
                <div>
                    <Button className={classes.button} variant="outlined" onClick={() => handleClose()}>Cancel</Button>
                    <Button
                        onClick={onSubmit}
                        //disabled={isSaving || !isValid()}
                        className={classes.button}
                        variant="contained"
                    >
                        Submit
                    </Button>
                </div>
            </div>
            </Paper>
        </>
    );
};

export default AddIssueTracker;
