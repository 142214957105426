import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    formContainer: {
        backgroundColor: theme.palette.common.white,
        boxShadow: 'none',
    },
    form: {
        display: 'flex',
        overflow: "auto",
        flexDirection: 'column',
    },
    btnContainer: {
        display: "flex",
        justifyContent: "flex-end",
        backgroundColor: theme.palette.common.white,
        margin: "16px 20px"
    },
    btnWrapper: {
        marginLeft: '0.5rem',
    },
    configWrapper: {
        borderRadius: '5px',
        margin: 16,
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: '100'
    },
    slaTitle: {
        color: theme.palette.primary.main,
        fontSize: '1.2em',
        padding: '1em 1em 0.5em 0.5em',
    },
    listItem: {
        padding: '0.5em 0',
        margin: '1rem 0'
    },
    lableWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: '2rem'
    },
    requiredLable: {
        color: 'red',
        fontWeight: 'lighter',
        fontSize: '0.9em',
    },
    description: {
        padding: '0 0 0.5rem 4rem',
        fontSize: '0.8rem',
        fontWeight: 'lighter'
    },
    groupNames: {
        paddingLeft: '4rem',
        padding: '0 0 0.3rem 4rem'
    },
    dropdownStatuses: {
        marginTop: 16,
        position: 'relative',
        width: "30%"
    }
}));
