import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from "../../app/baseQuery";
import {apiTags} from "../../app/store/apiTags";

export const mechanicalWorkApi = createApi({
    reducerPath: 'mechanicalWorkApi',
    baseQuery: baseQuery,
    tagTypes: [apiTags.MECHANICAL_WORK,apiTags.MECHANICAL_TASK],
    endpoints: (builder) => ({
        getByActivityLogId: builder.query({
            query: (activityLogId) =>  `mechanicalTask/GetTaskInfoByActivityLogId/?activityLogId=${activityLogId}`,
            providesTags: [apiTags.MECHANICAL_TASK]
        }),
        saveMechanicalWork: builder.mutation({
            query: body => ({
                url: `mechanicalWork/`,
                method: "PUT",
                body: body
            }),
            invalidatesTags: [apiTags.MECHANICAL_WORK]
        }),
    }),
});

export const {
    useGetByActivityLogIdQuery,
    useSaveMechanicalWorkMutation
} = mechanicalWorkApi;

