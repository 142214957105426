import React, {useState} from 'react';
import {Button, Paper, Checkbox, FormControlLabel, makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    popupContainer: {
        boxShadow: 'none',
        margin: 20,
    },
    checkboxList: {
        display: 'flex',
        flexDirection: 'column',        
    },
    btnContainer: {
        margin: "12px",
        alignItems: 'center',
        display: "flex",
        flexDirection: 'column'
    },
    button: {
        margin: "12px 8px",
        width: '210px',
        '&.MuiButton-contained': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        }
    }
}));

const SelectFieldsPopup = ({handleClose, columns, selectedColumns, isChecked, onSubmit}) => {

    const classes = useStyles();

    const [checked, setChecked] = useState([...selectedColumns]);
    
    const onSelectFeild = (index, value) => {
        if (value) {
            setChecked(
                columns.map((column,i) => ( i==index || isChecked(checked,column.name) ) ? column : null)
                .filter(column=>column)
            );
        }
        else{
            setChecked(
                checked.filter( c => c ? c.name != columns[index].name : false )
            );
        }
    }

    return (
        <Paper className={classes.popupContainer}>
            <div className={classes.checkboxList}>
                {columns.map((column, index) => (
                <>
                    <FormControlLabel
                        control={<Checkbox defaultChecked={isChecked(checked, column.name)} onChange={(e, value) => onSelectFeild(index, value)}/>}
                        label={column.name}
                    />
                </>                                                   
                ))}
            </div>           
            <div className={classes.btnContainer}>               
                <Button className={classes.button} variant="outlined" onClick={() => handleClose()}>Cancel</Button>               
                <Button
                    onClick={() => onSubmit(checked)}
                    className={classes.button}
                    variant="contained"
                >Finish</Button>
            </div>
        </Paper>
    );
};

export default SelectFieldsPopup;
