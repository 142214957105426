import { Button, Grid, Paper } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
//import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from "moment";
import { useEffect, useState } from 'react';
import Authorize from "../../../components/Authorize";
import { permissionProfiles } from "../../../components/Authorize/permissionProfiles";
import CustomInput from "../../../components/CustomInput";
import NotificationProvider from "../../../components/NotificationProvider";
import { IconAdd } from "../../../icons";
import FormatService from '../../../services/formatService';
import { ActivityStyles } from '../ActivityStyles';
import DateTimeField from "components/DateTimePicker/DateTimePicker";
import RichTextEditor from "components/RichTextEditor/RichTextEditor";
import { PricingLookup } from "features/pricing/PricingLookup/PricingLookup";
import { useGetServiceProvidersQuery } from "features/serviceProvider/serviceProviderSlice";
import { orderBy } from "lodash";
import { activityStatusOrder, activityStatuses } from "../activityConsts";
import {
    useAddActivityMutation,
    useGetActivityTypePriceByItemIdQuery,
    useGetAllActivitiesItemByTypeIdQuery,
    useGetAllActivitiesQuery,
    useGetAllActivityTypesQuery
} from "../activitySlice";
import { ChargeCalculation } from "features/pricing/ChargeCalculation/ChargeCalculation";
import ActivityInventory from "features/mechanicalWork/components/Inventory/ActivityInventory";

const AddActivity = ({ vehicleId, lotId, clientId, onClose, vehicleTypeId, entityId }) => {
    const classes = ActivityStyles();

    const [addActivity, { isLoading }] = useAddActivityMutation();

    const [isSaving, setIsSaving] = useState(false);
    const [open, setOpen] = useState(false);
    const [activityTypeId, setActivityType] = useState(null);
    const [activityId, setActivity] = useState(null);
    const [activityItemId, setActivityItem] = useState(null);
    const [price, setPrice] = useState(0);
    const [revenue, setRevenue] = useState(0);
    const [note, setNote] = useState();
    const [serviceProviderId, setServiceProviderId] = useState(null);
    const [customActivity, setCustomActivity] = useState(null);
    const [dateTime, setDateTime] = useState(FormatService.formatDateTime(moment()));
    const [inventoryUsed, setInventoryUsed] = useState(null);
    const [useInventory, setUseInventory] = useState(false)

    let {
        data: activityTypes, error: activityTypesError,
        isLoading: isLoadingActivityTypes
    } = useGetAllActivityTypesQuery();

    let {
        data: activities, error: activityError,
        isLoading: isLoadingActivity
    } = useGetAllActivitiesQuery();

    let {
        data: activityItems, error: activityItemError,
        isLoading: isLoadingActivityItems
    } = useGetAllActivitiesItemByTypeIdQuery(activityTypeId);

    let {
        data: activityPrices, error: activityPricesError,
        isLoading: isLoadingActivityPrices
    } = useGetActivityTypePriceByItemIdQuery(activityItemId);

    let {
        data: serviceProviders, error: serviceProvidersError,
        isLoading: isLoadingServiceProviders
    } = useGetServiceProvidersQuery();

    activityTypes = activityTypes || [];
    activities = orderBy(activities?.map(a => ({ ...a, order: activityStatusOrder[a.id] })), 'order') || [];
    activityItems = activityItems || [];
    activityPrices = activityPrices || {};
    serviceProviders = serviceProviders || {};

    let activityPrice = activityPrices

    if (!activityPrice) {
        activityPrice = { price: 0, revenue: 0 };
    }

    const isCustomActivity = () => {
        if (!activityItemId) {
            return false;
        }

        let activityItem = activityItems?.find(a => a.id === activityItemId);

        return activityItem?.isCustom;
    };

    const onSelectDateTime = (e) => {
        setDateTime(e)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setActivityType(null);
        setActivity(null);
        setActivityItem(null);
        setCustomActivity(null);
        setDateTime();
        setPrice(0);
        setRevenue(0);
        setServiceProviderId(null)
        setIsSaving(false);
        onClose();
        setNote();
        setInventoryUsed(null);
        setUseInventory(false);
    };

    const onChangeValue = (key, val) => {
        switch (key) {
            case "activitytype":
                setActivityType(getIntValue(val));
                break
            case "activityitem":
                setActivityItem(getIntValue(val));
                updatePrice(0);
                break;
            case "activity":
                setActivity(getIntValue(val));
                break;
            case "price":
                updatePrice(val);
                break;
            case "revenue":
                updateRevenue(val);
                break;
            case "serviceProvider":
                updateServiceProvider(getIntValue(val));
                break;
            case "note":
                setNote(val);
                break;
        }
    };

    const getIntValue = (val) => {
        return parseInt(val);
    };

    const updatePrice = price => {
        setPrice(price);
    }

    const updateRevenue = revenue => {
        setRevenue(revenue);
    }

    const updateServiceProvider = serviceProvider => {
        setServiceProviderId(serviceProvider);
    }

    const onClickFinish = async () => {
        setIsSaving(true);
        let date = new Date(moment(dateTime).format("MMM DD, yyyy, hh:mm a") + " UTC");
        let activityDate = date.toISOString();
        let invToSend = inventoryUsed?.filter(i => !(i.inventoryId === null && i.unitNumber === null));
        
        let data = {
            activityTypeId,
            activityItemId,
            activityId,
            vehicleId,
            serviceProviderId,
            lotId,
            price,
            revenue,
            activityDate,
            note,
            inventoryUsed: invToSend
        };

        if (isCustomActivity()) {
            data.customActivity = customActivity;
        }

        let result = await addActivity(data);
        if (result && !result.error) {
            NotificationProvider.success("Activity added successfully");
        } else {
            NotificationProvider.error("Something happened, try again or contact support");
        }
        handleClose();
    };

    useEffect(() => {
        if (!activityPrice) {
            return;
        }

        updatePrice(activityPrice?.price);
        updateRevenue(activityPrice?.revenue);
    }, [activityItemId]);

    useEffect(() => {
        if (!activityPrice) {
            return;
        }

        updatePrice(activityPrice?.price ?? price);
        updateRevenue(activityPrice?.revenue ?? revenue);
    }, [activityPrice]);

    useEffect(() => {
        setInventoryUsed([]); 
    }, [useInventory])

    const isValid = activityTypeId && activityItemId && activityId;

    return (
        <>
            <Button variant="contained" color="primary"
                onClick={handleClickOpen} startIcon={<IconAdd />}>
                Add Activity
            </Button>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    Add Activity
                </DialogTitle>
                <DialogContent>
                    <Paper className={classes.paper}>
                    <Grid container spacing={2} direction='column'>
                        {!isLoadingActivityTypes &&

                            <Grid item>
                                <CustomInput
                                    id="ddlActivityTypeId"
                                    label="Activity Type"
                                    elementType="dropdown"
                                    values={activityTypes}
                                    required={true}
                                    showEmpty={true}
                                    onChange={val => onChangeValue("activitytype", val)}
                                />
                            </Grid>
                        }
                        <Grid item>
                            <CustomInput
                                id="ddlActivityItemId"
                                label="Activity Item"
                                elementType="dropdown"
                                values={activityItems}
                                required={true}
                                showEmpty={true}
                                onChange={val => onChangeValue("activityitem", val)}
                            />
                        </Grid>
                        {
                            isCustomActivity() &&
                            <Grid item>
                                <CustomInput
                                    id="ddlCustomActivity"
                                    label="Custom"
                                    elementType="text"
                                    value={customActivity}
                                    required={true}
                                    onChange={val => setCustomActivity(val)}
                                />
                            </Grid>
                        }
                        {!isLoadingActivity &&
                            <Grid item>
                                <CustomInput
                                    id="ddlActivityId"
                                    label="Activity Status"
                                    elementType="dropdown"
                                    values={activities.filter(a => a.id !== activityStatuses.ItemCompleted)}//For now completed is only allowed for edit of a group the add API needs to support this
                                    required={true}
                                    showEmpty={true}
                                    onChange={val => onChangeValue("activity", val)}
                                />
                            </Grid>
                        }
                        {!isLoadingServiceProviders &&
                            <Grid item>
                                <CustomInput
                                    id="ddlServiceProviderId"
                                    label="Vendor"
                                    elementType="dropdown"
                                    values={serviceProviders}
                                    value={serviceProviderId}
                                    showEmpty={true}
                                    onChange={val => onChangeValue("serviceProvider", val)}
                                />
                            </Grid>
                        }
                        <Grid item>
                            <DateTimeField
                                label='Activity Date'
                                value={dateTime}
                                onChange={onSelectDateTime}
                            />
                        </Grid>
                    </Grid>
                    <Authorize profile={permissionProfiles.ASSETS.EDIT_ACTIVITY}>
                        <Grid container spacing={2} className={classes.pricing} >
                            <Grid item xs={10}>
                                <CustomInput
                                    id="txtPrice"
                                    label="Internal Cost"
                                    elementType="input"
                                    value={price}
                                    required={true}
                                    onChange={val => onChangeValue("price", val)}
                                />
                            </Grid>
                            <Grid item >
                                <PricingLookup
                                    onChange={val => onChangeValue("price", val)}
                                    activityItemId={activityItemId}
                                    lotId={lotId}
                                    vehicleTypeId={vehicleTypeId}
                                    serviceProviderId={serviceProviderId}
                                    setServiceProviderId={setServiceProviderId}
                                    price={price}
                                    setUseInventory={setUseInventory}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} className={classes.pricing} alignItems="center" >
                            <Grid item xs={9}>
                                <CustomInput
                                    id="txtRevenue"
                                    label="Charge"
                                    elementType="input"
                                    value={revenue}
                                    required={true}
                                    onChange={val => onChangeValue("revenue", val)}
                                />
                            </Grid>
                            <Grid item >
                                <ChargeCalculation
                                    onChange={val => onChangeValue("revenue", val)}
                                    clientId={clientId}
                                    activityType={activityTypeId}
                                    lotId={lotId}
                                    price={price}
                                    inventoryUsed={!!inventoryUsed?.length ? inventoryUsed : null}
                                />
                            </Grid>
                        </Grid>
                    </Authorize>
                    <Grid item>
                        <div>Note</div>
                        <RichTextEditor onChange={(val) => onChangeValue("note", val)} content={note} />
                    </Grid>
                    </Paper>
                    <Grid item>
                        {useInventory &&
                            <ActivityInventory inventoryUsed={inventoryUsed} setInventoryUsed={setInventoryUsed} activityLog={{id: null, lotId: lotId}}></ActivityInventory> 
                        }
                    </Grid>                    
                </DialogContent>
                <DialogActions>
                    <div className={classes.btnContainer}>
                        <Button className={classes.button} variant="outlined"
                            onClick={() => handleClose()}>Cancel</Button>
                        <Button
                            onClick={onClickFinish}
                            disabled={!isValid || isSaving}
                            className={classes.button}
                            variant="contained" >
                            {isSaving ? "Saving..." : "Finish"}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddActivity;
