import { Grid, Paper } from "@material-ui/core";
import React, { useState } from "react";
import Authorize from "../../../../../../components/Authorize";
import { permissionProfiles } from "../../../../../../components/Authorize/permissionProfiles";
import FormatService from "../../../../../../services/formatService";
import { TaskSetup } from "../../../../taskConsts";
import { TaskItemStyles } from "../../TaskItemStyles";

const WashTask = ({ task }) => {
    const taskSetup = TaskSetup[task?.taskType];
    const classes = TaskItemStyles();

    const field = (label, value) => {
        return <Grid container direction="row"><Grid xs={4}><label className={classes.label}>{label}</label></Grid><Grid xs={8}> {value}</Grid></Grid>;
    }


    return (
        <Grid container direction='column'>
            {task?.client && <Authorize profile={permissionProfiles.TASKS.TASK_VIEW_CLIENT}>
                {field('Client', task?.client?.name)}
            </Authorize>}
            {field('VIN', task?.entity?.descriptor)}
            {task?.entity?.assetId && field('Asset ID', task?.entity?.assetId)}
            {task?.lot && field('Lot', task?.lot?.name)}
            {field('Requested', FormatService.formatDate(task?.activityDate))}
            {/* {task?.requestedCompletionDate && field('Requested Completion', FormatService.formatDate(task?.requestedCompletionDate))} */}
        </Grid>
    )
}
export default WashTask;