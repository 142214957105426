import { makeStyles } from "@material-ui/core"

export const loadAssetsStyle = makeStyles((theme) => ({
    listWrapper: {
        overflow: 'auto'
    },
    card: {
        margin: '-1em -1em -1em -1em',
        padding: '1em',
    },
    emptyPlaceHolder: {
        margin: '-1rem'
    },
    link: {
        //display: 'flex',
        textDecoration: 'none',
        color: theme.palette.primary.main,
        fontWeight: '400'
    }
}));