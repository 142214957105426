import { Button, ButtonGroup, Grid, Paper, Tooltip } from "@material-ui/core";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Checkbox from '@mui/material/Checkbox';
import LotsMapMarker from "features/maps/markers/LotsMapMarker/LotsMapMarker";
import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import Authorize from "../../../../../components/Authorize";
import { permissionProfiles } from "../../../../../components/Authorize/permissionProfiles";
import DropdownFilter from "../../../../../components/DropdownFilter";
import LotDialog from "../../LotDialog";
import LotPanel from "../LotPanel/LotPanel";
import ViewToggle from './../ViewToggle';
import LotsMap from './LotsMap';
import { lotsMapStyle } from "./LotsMapStyle";

const LotsMapWrapper = ({ lots, screenView, handleViewChange, onNewLot,
    filters, setFilters, searchLot, subMarketsByLot, onEditLot, onViewImages, onDelete,
    lotToEdit, onCloseImages, onSaveLot, onCancelEditLot, lotViewImages,
    updateLotLoading, deletePopup, setDeletePopup, subMarkets, isNarrowScreen }) => {

    const classes = lotsMapStyle();
    const dispatch = useDispatch();

    const [selectedLotId, setSelectedLotId] = useState(null);
    const [selectedLot, setSelectedLot] = useState(null);
    const [openPanel, setOpenPanel] = useState(false);

    useEffect(() => {
        searchLot('');
    }, [])

    const handleSelectLot = (lotId) => {
        if (lotId == selectedLotId) {
            setOpenPanel(false);
            setSelectedLotId(null);
            setSelectedLot(null);
        }
        else {
            setSelectedLotId(lotId);
            setSelectedLot(lots?.find(l => l.id == lotId));
            setOpenPanel(true);
        }
    }

    const handleClosePanel = () => {
        setSelectedLotId(null);
        setSelectedLot(null);
        setOpenPanel(false);
    }

    return (
        <Paper className={classes.mapWrapperCard}>
            <div className={classes.mapWrapper}>
                {!openPanel && <Grid container spacing={1} className={classes.mapHeaderContainer} >

                    {!isNarrowScreen ? <>
                        <Grid xs={2} item >
                            <Grid container direction='row' alignItems='center' >
                                <Grid xs={12} item >
                                    <DropdownFilter
                                        showEmpty={true}
                                        title={'Sub Market'}
                                        values={subMarkets}
                                        value={filters?.['subMarket']?.value}
                                        onSelect={(val) => dispatch(setFilters({ ...filters, ['subMarket']: { value: val } }))}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid xs={4} item >
                            <Grid container direction='row' alignItems='center' spacing={1} >
                                <Grid xs={6} item >
                                    <div className={classes.lotFilterContainer}  >
                                        <div className={classes.lotFilterCheck} >
                                            <Checkbox
                                                checked={filters?.['pmfLotFilter']?.value == '1'}
                                                onChange={(e, val) => {
                                                    dispatch(setFilters({ ...filters, ['pmfLotFilter']: { value: val ? '1' : '' } }))
                                                }}
                                            />
                                            <div className={classes.lotFilterLabel} > PMF sites </div>
                                        </div>
                                        <LotsMapMarker color={"#0090FE"} />
                                    </div>
                                </Grid>
                                <Grid xs={6} item >
                                    <div className={classes.lotFilterContainer}>
                                        <div className={classes.lotFilterCheck}>
                                            <Checkbox
                                                checked={filters?.['pmfLotFilter']?.value == '0'}
                                                onChange={(e, val) => {
                                                    dispatch(setFilters({ ...filters, ['pmfLotFilter']: { value: val ? '0' : '' } }))
                                                }}
                                            />
                                            <div className={classes.lotFilterLabel}> Client sites </div>
                                        </div>
                                        <LotsMapMarker color={"#E48900"} />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </> :
                        <Grid xs={3} item >
                            <Grid container direction='row' alignItems='center' >
                                <Grid xs={12} item >
                                    <DropdownFilter
                                        showEmpty={true}
                                        title={'PMF Lot'}
                                        values={[{ id: '1', name: 'Yes' }, { id: '0', name: 'No' }]}
                                        value={filters?.['pmfLotFilter']?.value}
                                        onSelect={(val) => dispatch(setFilters({ ...filters, ['pmfLotFilter']: { value: val } }))}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    <Grid item>
                        <ViewToggle
                            screenView={screenView}
                            handleViewChange={handleViewChange}
                        />
                    </Grid>

                    <Authorize profile={permissionProfiles.LOT.LOT_EDIT_CREATE}>
                        <Grid item xs={1.5}>
                            <ButtonGroup size="large" fullWidth>
                                <Button onClick={onNewLot} className={`${isNarrowScreen ? classes.mobileButton : classes.button}`}>
                                    <Tooltip title='Add Lot'><AddRoundedIcon className={classes.addIcon} /></Tooltip>
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Authorize>

                </Grid>}

                <Grid container spacing={2} className={classes.mapContainer} >
                    <Grid item style={{ width: openPanel ? (selectedLot?.pmfLot ? '40%' : '70%') : '100%', height: 'calc(100vh - 110px)' }}>
                        <LotsMap
                            lots={lots}
                            handleSelectLot={handleSelectLot}
                            isNarrowScreen={isNarrowScreen}
                        />
                    </Grid>
                    {openPanel && selectedLot &&
                        <Grid item style={{ width: selectedLot?.pmfLot ? '60%' : '30%' }}>
                            <LotPanel
                                lot={selectedLot}
                                subMarketsByLot={subMarketsByLot}
                                onEditLot={onEditLot}
                                onViewImages={onViewImages}
                                onDelete={(lot) => onDelete(lot)}
                                onCloseImages={onCloseImages}
                                onSaveLot={onSaveLot}
                                lotViewImages={lotViewImages}
                                updateLotLoading={updateLotLoading}
                                deletePopup={deletePopup}
                                setDeletePopup={setDeletePopup}
                                handleClosePanel={handleClosePanel}
                            />
                        </Grid>
                    }
                </Grid>
                <LotDialog lot={lotToEdit} onSave={onSaveLot} onCancel={onCancelEditLot} saving={updateLotLoading} />
            </div>
        </Paper>
    )
}

export default LotsMapWrapper;