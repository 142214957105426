import CustomInput from 'components/CustomInput';
import { setAccessToEdit } from 'features/access/accessStateSlice';
import { useGetClientsQuery } from 'features/clients/clientSlice';
import { useDispatch, useSelector } from 'react-redux';

const ClientFields = ({ }) => {
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery();

    const { accessToEdit } = useSelector(state => state.accessState);
    const dispatch = useDispatch();

    const onChangeValue = (key, val) => {
        dispatch(setAccessToEdit({...accessToEdit, [key]: val}))
    }

    return (
        <>
            <CustomInput
                label='Client'
                elementType='dropdown'
                value={accessToEdit?.clientId}
                values={clients}
                onChange={(val) => onChangeValue("clientId", val)}
                showEmpty
                required
            />
        </>
    );
}

export default ClientFields;