import * as React from "react";

function SvgIconKey(props, width, height) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2507 4.75154C16.5829 2.09274 12.2671 2.09403 9.61049 4.75064L9.6092 4.75193C7.75641 6.59573 7.19472 9.25238 7.91699 11.5997C7.99869 11.8653 7.92693 12.1542 7.73049 12.3506L3.0355 17.0456C2.96983 17.1147 2.88084 17.2575 2.81667 17.45C2.75139 17.6458 2.73906 17.8113 2.75231 17.9021L2.75318 17.9081L3.05225 20.0815C3.08035 20.2574 3.18557 20.4655 3.36296 20.6437C3.5417 20.8233 3.74497 20.9238 3.9124 20.9473L6.09839 21.2481C6.19768 21.2626 6.36675 21.2508 6.55644 21.1893C6.74745 21.1274 6.88103 21.0407 6.9422 20.9777L6.95033 20.9694L8.13073 19.7915L6.35981 18.0206C6.06691 17.7277 6.06691 17.2528 6.35981 16.9599C6.6527 16.667 7.12757 16.667 7.42047 16.9599L9.19253 18.732L11.6604 16.2694C11.8577 16.0725 12.148 16.0013 12.414 16.0845C14.7461 16.8138 17.4037 16.2465 19.2603 14.3995C21.9168 11.7425 21.9166 7.40842 19.2507 4.75154ZM8.55047 3.68934C11.7939 0.446595 17.0576 0.448098 20.3096 3.68909C23.5635 6.93203 23.5635 12.2176 20.3205 15.4606L20.3192 15.4619C18.1725 17.5982 15.1453 18.3312 12.4093 17.6412L8.0135 22.0276C7.7352 22.3121 7.36594 22.5038 7.01887 22.6163C6.66963 22.7295 6.26512 22.7876 5.88542 22.733L5.88193 22.7325L3.70791 22.4333C3.15552 22.3566 2.66328 22.067 2.29986 21.7019C1.93625 21.3366 1.65175 20.8468 1.56876 20.3036L1.56708 20.2926L1.26801 18.1185L1.26752 18.1152C1.21164 17.727 1.27925 17.3188 1.39365 16.9756C1.50855 16.6309 1.6979 16.27 1.96197 15.998L1.96977 15.9899L6.36059 11.5992C5.67939 8.85854 6.4 5.82983 8.55047 3.68934ZM13.7501 9.5C13.7501 9.08579 14.0859 8.75 14.5001 8.75C14.9143 8.75 15.2501 9.08579 15.2501 9.5C15.2501 9.91421 14.9143 10.25 14.5001 10.25C14.0859 10.25 13.7501 9.91421 13.7501 9.5ZM14.5001 7.25C13.2575 7.25 12.2501 8.25736 12.2501 9.5C12.2501 10.7426 13.2575 11.75 14.5001 11.75C15.7428 11.75 16.7501 10.7426 16.7501 9.5C16.7501 8.25736 15.7428 7.25 14.5001 7.25Z" fill="#0090FE" />
        </svg>
    );
}

export default SvgIconKey;
