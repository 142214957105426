import moment from "moment";

const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const dateTimeFormatOptions = {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
};

const FormatService = {
    fromUtcToLocal: date => moment(date),
    fromLocalToUtc: date => moment.utc(date),
    fromLocalDateToUtcNoConversion: date => new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString(),
    formatDate: date => date ? moment(date).format("MMM DD, yyyy") : "",
    formatDateTime: date => date ? new Date(moment(date).format("MMM DD, yyyy, hh:mm a") + " UTC").toLocaleString({}, dateTimeFormatOptions) : "",
    formatDateTimeNoConversion: date => date ? new Date(moment(date).format("MMM DD, yyyy, hh:mm a")).toLocaleString({}, dateTimeFormatOptions) : "",
    formatToDatePicker: date => date ? moment(date).format("yyyy-MM-DD") : "",
    formatNumberWithCommas: x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    getMonths: () => ([
        { id: 1, name: 'January' },
        { id: 2, name: 'February' },
        { id: 3, name: 'March' },
        { id: 4, name: 'April' },
        { id: 5, name: 'May' },
        { id: 6, name: 'June' },
        { id: 7, name: 'July' },
        { id: 8, name: 'Áugust' },
        { id: 9, name: 'September' },
        { id: 10, name: 'October' },
        { id: 11, name: 'November' },
        { id: 12, name: 'December' },
    ]),
    getYears: (numOfYears, past = true, future = true) => {
        const rangeOfYears = (start, end, decrement) => {
            return Array(decrement ? start - end + 1 : end - start + 1)
                .fill(start)
                .map((year, index) => decrement ? year - index : year + index)
        }
        const curYear = new Date().getFullYear();
        return [
            ...(past ? rangeOfYears(curYear, curYear - numOfYears, true) : []),
            ...(future ? rangeOfYears(past ? curYear + 1 : curYear, curYear + numOfYears) : []),//If displaying past and future current year will be added by past
        ].sort();
    },
    formatCurrency: val => val || val === 0 ? currencyFormatter.format(val) : '',
};

export default FormatService;
