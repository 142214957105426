import { Button, Grid, Paper, Select } from '@material-ui/core';
import { ArrowDownwardSharp, ArrowUpwardSharp } from '@material-ui/icons';
import Icon from '@mui/material/Icon';
import { groupBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import DataListHeader from '../../../../components/DataListHeader';
import ExportButton from "../../../../components/ExportButton";
import LoadingSpinner from '../../../../components/LoadingSpinner';
import PageComponent from '../../../../components/Page/PageComponent';
import FilterService, { fieldTypes } from '../../../../services/filterService';
import FormatService from '../../../../services/formatService';
import { useGetClientsWithConfigsQuery } from '../../../clients/clientConfigSlice';
import { useGetClientsQuery } from '../../../clients/clientSlice';
import { useGetLotsQuery } from '../../../lots/lotSlice';
import { useGetTransportersQuery } from '../../../transporter/transporterSlice';
import { selectIsAdmin } from "../../../user/userSlice";
import { useGetReadinessQuery } from '../../../vehicles/readiness/readinessSlice';
import { useGetFuelTypesQuery, useGetVehicleTypesQuery } from '../../../vehicles/vehicleSlice';
import { moveTypes } from '../../enums';
import { setFilters, setSearchVal } from '../../loadsFiltersSlice';
import { useGetLoadsQuery } from '../../loadsSlice';
import { useGetBusinessUnitsQuery } from '../../../clients/clientConfigSlice';
import statuses from '../../loadStatusesWithIcons';
import CreateLoadForm from '../CreateLoadForm';
import ImportLoads from '../ImportLoads';
import LoadsList from '../LoadsList';
import { loadsPageStyle } from './LoadsPageStyle';
import { useGetInvoicesQuery } from 'features/invoice/loads/loadInvoiceSlice';
import { ServiceItemTypes } from 'features/invoice/loads/loadInvoiceConsts';

function LoadsPage(props) {
    const classes = loadsPageStyle()
    let [createLoad, setCreateLoad] = useState(false);

    const { searchVal, filters } = useSelector(state => state.loadFilters);

    let { data: loads, error: loadListError, isFetching: isLoadingLoads } = useGetLoadsQuery({ lmStatus: filters?.lmStatus?.value });
    let {
        data: vehicleTypes,
        error: vehicleTypesError,
        isLoading: isLoadingVehicleTypes
    } = useGetVehicleTypesQuery();
    let { data: vehiclesReadiness, error: vehiclesReadinessError, isLoading: isLoadingVehiclesReadiness } = useGetReadinessQuery({});

    const selectMenuStyle = {
        backgroundColor: '#f7f6fb',
        border: 'none',
        color: 'rgba(0, 0, 0, 0.38)',

    };

    const dispatch = useDispatch();
    const isAdmin = useSelector(selectIsAdmin);

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: transporters, error: transportersError, isLoading: isLoadingTransporters } = useGetTransportersQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null, { skip: (!isAdmin) });
    let { data: clientsWithConfigs, error: clientsWithConfigsError, isFetching: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery(null);
    let { data: fuelTypes, error: fuelTypesError, isLoading: isLoadingFuelTypes, isSuccess: isSuccessFuelTypes } = useGetFuelTypesQuery();
    let { data: businessUnits, error: businessUnitsError, isLoading: isLoadingbusinessUnits, isSuccess: businessUnitsSuccess } = useGetBusinessUnitsQuery();
    let { data: invoices, error, isFetching } = useGetInvoicesQuery([2, 5]);

    clientsWithConfigs = clientsWithConfigs || [];
    clients = clients || [];
    lots = lots || [];
    loads = loads || [];
    invoices = invoices || [];

    let tempStatuses = []
    tempStatuses = Object.entries(statuses).map(([key, value]) => ({ name: value.label, id: key }))

    const readinessForLoadId = {};
    const contactsForLoad = {};

    const clientConfigsLookup = clientsWithConfigs?.reduce(function (map, obj) {
        map[obj.accountId] = obj;
        return map;
    }, {});

    const vehiclesReadinessLookup = vehiclesReadiness?.reduce(function (map, obj) {
        map[obj.vehicleId] = obj;
        return map;
    }, {});

    const lotsLookup = lots?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const invoicesLookup = invoices?.reduce(function (map, obj) {
        map[obj.externalId] = obj;
        return map;
    }, {});

    let loadsProcessed = loads.map(load => {
        let loadClientId = clientsWithConfigs.find(c => c.accountId == load.clientAccountId)?.id;
        readinessForLoadId[load.id] = vehiclesReadinessLookup ? vehiclesReadinessLookup[load?.assets?.[0]?.id] : null;
        contactsForLoad[load.id] = lotsLookup[load?.pickupLocation?.id]?.contacts?.filter(s => !s.clientId || s.clientId == loadClientId)
        const loadWithData = {
            ...load,
            clientId: loadClientId,
            isReady: readinessForLoadId[load.id]?.status == 1 ? 1 : 2,
            movementType: clientConfigsLookup[load?.clientAccountId]?.loadMoveTypes?.find(l => l.id == load.configMoveType),
            businessUnitName: businessUnits?.find(bu => bu.id == load.businessUnit)?.name
        };
        return loadWithData;
    });

    let lmStatuses = [
        { id: 107, name: "Needs attention" },
        { id: 108, name: "Picked today" },
        { id: 109, name: "Delivered today" },
        { id: 110, name: "Delayed pickups", filterOn: 'isDelayedPickup', filterOnValue: '1' },
        { id: 111, name: "Delayed deliveries", filterOn: 'isDelayedDelivery', filterOnValue: '1' },
        { id: 112, name: "Delayed", filterOn: 'isDelayed', filterOnValue: '1' },
        { id: 113, name: "At Risk", filterOn: 'isAtRisk', filterOnValue: '1' },
        { id: 114, name: "Not Ready", filterOn: 'isReady', filterOnValue: '2' },
    ];

    let allStatuses = [...tempStatuses, ...lmStatuses];

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        let dashboardFilters = {}
        let currentFilters = filters;
        setCreateLoad(false);
        for (let [key, value] of params) {
            if (key === "createload") {
                setCreateLoad(true);
                currentFilters = {};
            }
            else {
                if (value !== "undefined") {
                    if (key === "calcPickupDate") {
                        let dates = value.split(",");
                        let startDate = new Date(dates[0]);
                        let endDate = new Date(dates[1]);
                        let dateValue = `${startDate?.toLocaleDateString('en-GB')} - ${endDate?.toLocaleDateString('en-GB')}`
                        value = { startDate: startDate, endDate: endDate, dateValue: dateValue }
                    }
                    dashboardFilters[key] = value;
                }
                else {
                    dashboardFilters[key] = ''
                }
            }
        }
        for (let key in dashboardFilters) {
            currentFilters = updateFilters(key, dashboardFilters[key], currentFilters)
        }

    }, [window.location.search]);

    const IsLmStatus = (prop, value) => {
        if (prop === "latestStatus" && ["107", "108", "109"].includes(value)) {
            return true;
        }
        return false;
    }

    let filterConfig = [
        { type: 'text', size: 2, title: 'Client', key: 'clientId', values: clients, requireAdmin: true },
        { type: 'text', size: 2, title: 'Status', key: 'latestStatus', values: allStatuses, specialBehaviorHdl: IsLmStatus },
        { type: fieldTypes.LOT, size: 2, title: 'Pickup Location', key: 'pickupLocationId', values: lots },
        { type: fieldTypes.LOT, size: 2, title: 'Delivery Location', key: 'deliveryLocationId', values: lots },
        { type: 'dateRange', size: 1.5, title: 'Pickup Date', key: 'calcPickupDate', values: [] },
        { type: 'dateRange', size: 1.5, title: 'Delivery Date', key: 'calcDeliveryDate', values: [] },
        { type: 'text', size: 2, title: 'Carrier', key: 'carrierId', values: transporters },
    ];

    if (!isAdmin) {
        filterConfig = filterConfig.filter(f => !f.requireAdmin);
    }

    if (businessUnits?.length) {
        filterConfig.push({
            type: 'text', size: 2, title: 'Business Unit', key: 'businessUnit', values: businessUnits, getValues: () => businessUnits?.filter(b => !filters?.clientId?.value || b.clientId == filters?.clientId?.value)
        })
    }

    const sortValues = [
        { name: "Created Date", id: 2, key: "createdDate" },
        { name: "Pickup Date", id: 1, key: "calcPickupDate" },
        { name: "Last Status Update", id: 3, key: "latestStatusDate" },
    ];

    const [orderby, setOrderBy] = useState("DSC");
    const [selectedSort, setSelectedSort] = useState(sortValues[0].key);

    const sort = (key, data) => {
        const sorted = data.slice().sort((a, b) => {
            return new Date(a[key]).getTime() -
                new Date(b[key]).getTime()
        }
        );
        data = orderby === "ASC" ? sorted : sorted.reverse();
        return data;
    }

    const searchLoads = value => {
        dispatch(setSearchVal(value.trim()));
    }

    const updateFilters = (prop, value, currentFilters) => {
        let newFilter = {}
        newFilter[prop] = { value: value };
        if (IsLmStatus(prop, value)) {
            newFilter['lmStatus'] = { value: value };
        } else if (prop === "latestStatus") {
            newFilter['lmStatus'] = { value: null };
        }
        dispatch(setFilters({ ...currentFilters, ...newFilter }));
        currentFilters = { ...currentFilters, ...newFilter }
        return currentFilters;
    }

    const onDropFilter = (filterValue, prop) => {
        updateFilters(prop, filterValue, filters);
    };

    let filteredLoads = sort(selectedSort, FilterService.filter(filters, searchVal, loadsProcessed, filterConfig.concat({ type: "text", key: "lmStatus", specialBehaviorHdl: (prop, value) => { if (prop === "lmStatus") { return true; } else { return false; } } })));

    const getLocationColumns = (l, prefix) => {
        return {
            [`${prefix} Node Name`]: l.nodeName,
            [`${prefix} Domicile Site`]: l.name,
            [`${prefix} Address`]: l.addressLine1,
            [`${prefix} City`]: l.city,
            [`${prefix} State`]: l.state,
            [`${prefix} Zip`]: l.zipCode,
        }
    }

    const getStatusColumns = (l) => {
        const statusColumns = {};
        Object.keys(statuses).map(statusKey => {
            const status = l?.statuses?.find(s => s.status == statusKey)?.statusDate;
            statusColumns[`Date ${statuses[statusKey].exportLabel ?? statuses[statusKey].label}`] = status ? FormatService.formatDateTime(status) : '';
        })
        return statusColumns;
    }

    invoices = invoices.filter(invoice => loads.some(load => load.lmId === invoice.externalId))

    const allDescroptions = [];
    invoices?.map(invoice => {
        invoice?.services?.map(service => {
            if (service?.itemTypeId !== ServiceItemTypes.FUEL && service?.itemTypeId !== ServiceItemTypes.TRANSPORT) {
                const desc = service?.description?.replace(` - ${invoice?.externalId}`, '');
                if (!allDescroptions.includes(desc)) {
                    allDescroptions.push(desc);
                }
            }
        })
    })

    const mapExportData = v => {
        const loadsData = {
            ['Asset ID']: v.externalId,
            ['VRID']: v.id,
            ['VIN']: v.assets?.[0]?.vin,
            ['Make']: v.assets?.[0]?.make,
            ['Model']: v.assets?.[0]?.model,
            ['Year']: v.assets?.[0]?.year,
            ['Vehicle Type']: vehicleTypes.find(s => s.id === v.assets?.[0]?.assetTypeId)?.name,
            ['Fuel Type']: fuelTypes?.find(f => f.id == v.assets?.[0]?.fuelType)?.name,
            ...(businessUnits?.length ? { ['Business Unit']: v.businessUnitName } : {}),
            ['Request Date']: FormatService.formatDateTime(v.createdDate),
            ['Move Type']: v.moveType ? moveTypes.find(t => t.id == v.moveType)?.name : v.movementType?.name,
            ['Miles']: v.miles,
            ['Latest Status']: statuses[v.latestStatus]?.label,
            ...getStatusColumns(v),
            ...getLocationColumns(v.pickupLocation, 'Pickup'),
            ['Earliest Pickup Date']: FormatService.formatDate(v.pickupStartDate),
            ['Estimated Pickup Date']: FormatService.formatDate(v.confirmedPickupDate),
            ['Actual Pickup Date']: FormatService.formatDateTime(v.actualPickupDate),
            ...getLocationColumns(v.deliveryLocation, 'Delivery'),
            ['Latest Delivery Date']: FormatService.formatDate(v.deliveryEndDate),
            ['Estimated Delivery Date']: FormatService.formatDate(v.confirmedDeliveryDate),
            ['Actual Delivery Date']: FormatService.formatDateTime(v.actualDeliveryDate),
            ['Needed By Week']: v.neededByWeek,
            ['Handoff Week']: v.handoffWeek,
            ['Carrier']: v.carrier?.transporterName,
            ['LoadMate ID']: v.lmId,
            ['Comments']: v.comments,
            ['Readiness']: readinessForLoadId[v.id]?.status == 1 ? "Ready" : "Not Ready",
            ['Latest Wash']: readinessForLoadId[v.id]?.latestCompletedWash ? FormatService.formatDate(readinessForLoadId[v.id]?.latestCompletedWash) : 'Unknown',
            ['Latest Inspection']: readinessForLoadId[v.id]?.latestAmeritInspection ? FormatService.formatDate(readinessForLoadId[v.id]?.latestAmeritInspection) : 'Unknown',
            ['Used GPS']: v.usedGps ? "Yes" : "No"
        }

        if (invoices.length > 0) {

            let loadInvoice = invoicesLookup[v.lmId]

            //loadsData['Managemant Fee'] = `$${loadInvoice?.managementFee ?? 0}`
            loadsData['Rate'] = loadInvoice?.carrierCost ?? '$0'
            //loadsData['Fuel'] = loadInvoice?.fuelCost ?? '$0'

            allDescroptions.map(desc => {
                let amount = loadInvoice?.services?.filter(s => s?.description?.startsWith(desc))?.reduce(function (a, b) {
                    return a + b?.amount;
                }, 0);
                loadsData[desc] = `$${amount ?? 0}`
            })
        }

        return loadsData;
    };


    return (
        <PageComponent header={<>
            {!createLoad && <DataListHeader
                title={`Loads (${filteredLoads?.length ?? 0})`}
                data={loadsProcessed}
                dropDownsConfig={filterConfig}
                filters={filters}
                onDropFilter={onDropFilter}
                onSearch={searchLoads}
                searchVal={searchVal}
                actionButtons={
                    <Grid container spacing={1}>
                        <Grid item>
                            <ImportLoads />
                        </Grid>
                        <Grid item> <ExportButton fileLabel="loads" data={filteredLoads} mapExportData={mapExportData} /></Grid>
                    </Grid>}

            />}
            {createLoad &&
                <>
                    <Paper variant='outlined' className={classes.createLoad}>
                        <CreateLoadForm />
                    </Paper>
                    <Paper variant='outlined' className={classes.newLoadsTitle}>
                        <h1 className={classes.loadsTitle}>New Loads ({filteredLoads?.length ?? 0})</h1>
                    </Paper>
                </>
            }

            <Paper variant='outlined' className={classes.sortWrapper}>
                <Grid container direction='row' alignItems="center">
                    <h1 className={classes.title}>{"Sort by:"}</h1>
                    {<Select native
                        title='Sort by:'
                        className={classes.sortSelect}
                        variant='outlined'
                        onChange={(e) => { setSelectedSort(e.target.value) }}
                        defaultValue={sortValues[0]}
                        error={false}
                        disabled={false}
                    >
                        {sortValues.map((value, key) => (
                            <option id={key} key={key} style={selectMenuStyle} value={value.key}>{value.name}</option>
                        ))}
                    </Select>}
                    <Button variant={orderby == 'ASC' ? "contained" : ''} className={orderby == 'ASC' ? classes.selectedSort : ''} onClick={() => setOrderBy("ASC")}><Icon component={ArrowUpwardSharp} /></Button>
                    <Button variant={orderby == 'DSC' ? "contained" : ''} className={orderby == 'DSC' ? classes.selectedSort : ''} onClick={() => setOrderBy("DSC")}><Icon component={ArrowDownwardSharp} /></Button>
                </Grid>
            </Paper>
        </>}>

            <LoadsList loads={filteredLoads} readinessForLoads={readinessForLoadId} contactsForLoad={contactsForLoad} />
            <LoadingSpinner loading={isLoadingLoads} />
        </PageComponent>
    );
}

export default LoadsPage;