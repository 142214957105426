import { makeStyles } from "@material-ui/core";

export const mechanicalDashboardStyles = makeStyles((theme) => ({
    card: {
        padding: '2rem ',
        height: '100%'
    },
    graphWrapper: {
        height: '100%',
        minHeight: '20rem'
    },
    subTitle: {
        color: theme.palette.primary.dark,
        fontSize: 20,
        fontWeight: 500,
        marginTop: 0,
        marginBottom: 0
    },
    wrapper: {
        height: '100%',
        cursor: 'pointer'
    },
    count: {
        color: theme.palette.primary.main,
        fontSize: 72,
        fontWeight: 700
    },
    text: {
        color: theme.palette.primary.dark,
        fontSize: 20,
        fontWeight: 500,
        marginTop: 0
    },
    tooltip: {
        background: '#fff',
        padding: '1em',
        boxShadow: '1px 2px 7px #efefef'
    },
    cardWrapper: {
        position: 'relative'
    },
    exportBtn: {
        position: 'absolute',
        right: '-1em'
    },
    icon: {
        paddingRight: '0.5rem'
    },
    dialogTable: {
        height: '60vh'
    },
    table: {
        height: '75vh'
    },
    titleContainer: {
        display: 'flex',
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    paper: {
        minHeight: '50vh'
    }
}));