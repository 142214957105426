import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import { AutoSizer, Column, Table, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { Link } from 'react-router-dom';
import { Box, Grid, IconButton, makeStyles, Typography, withStyles, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CustomizedActions from "./DataTable/CustomizedActions";

const classes = {
    flexContainer: 'ReactVirtualizedDemo-flexContainer',
    tableRow: 'ReactVirtualizedDemo-tableRow',
    tableRowHover: 'ReactVirtualizedDemo-tableRowHover',
    tableCell: 'ReactVirtualizedDemo-tableCell',
    noClick: 'ReactVirtualizedDemo-noClick',
    columnHeader: 'ReactVirtualized__Table__headerColumn',
};


const styles = ({ theme }) => ({
    // temporary right-to-left patch, waiting for
    // https://github.com/bvaughn/react-virtualized/issues/454
    '& .ReactVirtualized__Table__headerRow': {
        ...(theme.direction === 'rtl' && {
            paddingLeft: '0 !important',
        }),
        ...(theme.direction !== 'rtl' && {
            paddingRight: undefined,
        }),
        borderBottom: '1px solid #0000000d',
    },
    [`& .${classes.flexContainer}`]: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    [`& .${classes.tableRow}`]: {
        cursor: 'pointer',
        borderBottom: '1px solid #0000000d',

    },
    [`& .${classes.tableRowHover}`]: {
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    [`& .${classes.tableCell}`]: {
        flex: 1,
        borderBottom: '1px solid #0000000d'
    },
    [`& .${classes.noClick}`]: {
        cursor: 'initial',
    },
    [`& .${classes.columnHeader}`]: {
        cursor: 'pointer',
        color: theme.palette.primary.light,
        fontSize: 16,
    },
});


class MuiVirtualizedTable extends React.PureComponent {
    static defaultProps = {
        headerHeight: 54,
        rowHeight: 60,
    };

    _cache = new CellMeasurerCache({
        fixedHeight: true,
        fixedWidth: false,
        minHeight: 60,
    });

    _lastRenderedWidth = this.props.width;

    getRowClassName = ({ index }) => {
        const { onRowClick } = this.props;

        const clsxs = clsx(classes.tableRow, classes.flexContainer,
            { [classes.tableRowHover]: index !== -1 && onRowClick != null },
            { [classes.columnHeader]: index == -1 && !this.props.headerStyle },
        );
        return clsxs;
    };

    cellRenderer = ({ cellData, columnIndex, dataKey, parent, rowIndex }) => {
        const { columns, rowHeight, onRowClick, actions,actionWithMenu} = this.props;
        const column = columns[columnIndex];
        let value = cellData;
        if (column.values) {
            let v = column.values.find(v => v.id === value) || {};
            value = v.name;
        }

        if (value && column.icon) {
            value = <Link href={value} target="_blank">{column.icon}</Link>;
        }

        if (column.component) {
            value = column.component(value);
        }

        if (actions && column.isAction 
            && (actionWithMenu === undefined || actionWithMenu === false)) {
            value = <Grid alignItems='center' container> {actions(cellData).map((action, index) =>
                <div key={index}>
                    {action.component ? action.component :
                        action.label ? <Button color='primary' className={classes.iconButton} onClick={() => action.onClick(cellData)}>{action.label}</Button>
                            : <IconButton className={classes.iconButton} onClick={() => action.onClick(cellData)}>{action.icon}</IconButton>}

                </div>
            )}
            </Grid>
        }
        
        if (actions && column.isAction && actionWithMenu === true) {
            value =<Grid alignItems='center' container> 
                <CustomizedActions actions={actions} cellData={cellData} />
            </Grid>
        }

        return (
            <CellMeasurer
                cache={this._cache}
                columnIndex={columnIndex}
                key={dataKey}
                parent={parent}
                rowIndex={rowIndex}
            >
                <div>{value}</div>
            </CellMeasurer>
        );
    };

    headerRenderer = ({ label, columnIndex, dataKey, rowIndex, parent }) => {
        const { headerHeight, columns, columnToSort, sortDirection, onSort, headerStyle } = this.props;
        const column = columns[columnIndex];

        return (
            <CellMeasurer
                cache={this._cache}
                columnIndex={columnIndex}
                key={dataKey}
                parent={parent}
                rowIndex={rowIndex}
            >
                <Box
                    className={!headerStyle && classes.columnHeader}
                    style={headerStyle}
                    display="flex"
                    flexDirection="row"
                    alignItems="flex-end"
                    onClick={() => onSort(column.sortKey || column.key)}>
                    <Typography><span>{column.label} </span></Typography>
                    {columnToSort && columnToSort === (column.sortKey || column.key) ? (
                        sortDirection === "asc" ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )
                    ) : <></>}
                </Box>
            </CellMeasurer>
        );
    };

    render() {
        const { columns, rowHeight, headerHeight, headerStyle, width, rowCount, setRef, refresh, ...tableProps } = this.props;

        if (this._lastRenderedWidth !== this.props.width) {
            this._lastRenderedWidth = this.props.width;
            this._cache.clearAll();
        }

        return (
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        ref={(ref) => setRef(ref)}
                        height={height}
                        width={width}
                        gridStyle={{
                            direction: 'inherit',
                        }}
                        headerHeight={headerHeight}
                        {...tableProps}
                        rowClassName={this.getRowClassName}
                        deferredMeasurementCache={this._cache}
                        overscanRowCount={2}
                        rowHeight={this._cache.rowHeight}
                        rowCount={rowCount}
                    >
                        {columns.map(({ dataKey, ...other }, index) => {
                            return (
                                <Column
                                    key={dataKey}
                                    headerRenderer={(headerProps) =>
                                        this.headerRenderer({
                                            ...headerProps,
                                            columnIndex: index,
                                        })
                                    }
                                    className={classes.flexContainer}
                                    cellRenderer={this.cellRenderer}
                                    dataKey={dataKey}
                                    width={other?.width ?? width / columns?.length}
                                    flexGrow={1}
                                    {...other}
                                />
                            );
                        })}
                    </Table>
                )}
            </AutoSizer>
        );
    }
}

MuiVirtualizedTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            dataKey: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            numeric: PropTypes.bool,
            width: PropTypes.number.isRequired,
        }),
    ).isRequired,
    headerHeight: PropTypes.number,
    onRowClick: PropTypes.func,
    rowHeight: PropTypes.number,
    onSort: PropTypes.func,
    sortDirection: PropTypes.string,
    columnToSort: PropTypes.string,
    onShow: PropTypes.func,
    actions: PropTypes.func,
    setRef: PropTypes.func,
};

const VirtualizedTable = styled(MuiVirtualizedTable)(styles);
export default VirtualizedTable;

