import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton } from "@material-ui/core";
import { useGetLoadsQuery } from "features/loads/loadsSlice";
import moment from "moment";
import React, { useEffect, useState } from "react";
import CustomInput from "../../../../../components/CustomInput";
import DatePicker from "../../../../../components/DatePicker";
import NotificationProvider from "../../../../../components/NotificationProvider";
import { useAddInvoiceMutation, useGetInvoiceCustomerQuery, useGetInvoiceVendorsQuery } from "../../loadInvoiceSlice";
import { AddInvoiceStyle } from "./AddInvoiceStyle";
import LoadsDropDown from "components/SharedDropdowns/LoadsDropDown";
import { EditService } from "../InvoiceDetails/EditService";
import DeleteIcon from '@material-ui/icons/Delete';
import { AddCircle } from "@material-ui/icons";
import DropZoneInDialog from "components/DropZoneInDialog";
import FileService from "services/fileService";
import LoadingSpinner from "components/LoadingSpinner";
import FadeInImage from "features/vehicles/components/FadeInImage";

const AddInvoice = ({ open, setOpen, onClose }) => {
    const classes = AddInvoiceStyle();

    let { data: vendors, error: vendorError, isLoading: isLoadingVendor } = useGetInvoiceVendorsQuery({});
    let { data: customers, error: customerError, isLoading: isLoadingCustomer } = useGetInvoiceCustomerQuery({});
    let { data: loads, error: loadListError, isFetching: isLoadingLoads } = useGetLoadsQuery({});

    vendors = vendors || [];
    customers = customers || [];
    loads = loads || [];

    const loadMateLoads = loads.filter(load => load.lmId !== "" && load.lmId !== "temp");

    const loadId = new URLSearchParams(window.location.search).get('loadId');

    const [isSaving, setIsSaving] = useState(false);

    const [newInvoice, setNewInvoice] = useState({});
    const [invoiceServices, setInvoiceServices] = useState([]);

    const terms = [
        { id: 0, name: "On reciept" },
        { id: 10, name: "In 10 days" },
        { id: 15, name: "In 15 days" },
        { id: 30, name: "In 30 days" },
        { id: 60, name: "In 60 days" },
    ]

    const onLoadChange = (load) => {
        setNewInvoice({
            ...newInvoice,
            ['loadId']: load?.id,
            ['externalId']: load?.lmId,
            ['carrier']: load?.carrier?.transporterName,
            ['clientAccountId']: load?.clientAccountId,
            // ['pickupLotName']: load?.pickupLocation?.name, 
            // ['deliveryLotName']: load?.deliveryLocation?.name, 
            ['deliveryDate']: load?.deliveryStartDate,
            ['businessUnit']: load?.businessUnit
        })
    };

    const [fileName, setFileName] = useState();

    const onUploadFile = async (file) => {
        let base64Data = await FileService.getBase64Data(file);
        onFieldChange('invoiceDocumentData', base64Data);
        setFileName(file.name)
    }


    useEffect(() => {
        if (loadId) {
            let load = loads?.find(load => load.id == loadId)
            onLoadChange(load);
            setOpen(true)
        }
    }, [])

    const textField = (field) => (
        <>
            <CustomInput
                id={field.key}
                label={field.name}
                value={newInvoice?.[field.key] ?? ''}
                elementType="input"
                onChange={val => onFieldChange(field.key, val)}
                required={field.required}
                numberOnly={field.numberOnly}
            />
        </>
    );

    const invoiceFields = [
        {
            name: "Load",
            key: "loadId",
            editable: true,
            required: true,
            component: <LoadsDropDown value={newInvoice?.loadId} onLoadChange={onLoadChange} loadsValues={loadMateLoads} />
        },
        {
            name: "Client",
            key: "quickBooksCustomerId",
            required: true,
            component: <CustomInput
                id="quickBooksCustomerId"
                label="Client"
                value={newInvoice?.quickBooksCustomerId}
                onChange={(val) => onFieldChange('quickBooksCustomerId', val)}
                elementType="dropdown"
                required={true}
                values={customers?.map(i => ({ ...i, name: i.fullyQualifiedName }))}
                showEmpty
            />
        },
        {
            name: "Vendor",
            key: "quickBooksVendorId",
            required: true,
            component: <CustomInput
                id="quickBooksVendorId"
                label="Vendor"
                value={newInvoice?.quickBooksVendorId}
                onChange={(val) => onFieldChange('quickBooksVendorId', val)}
                elementType="dropdown"
                required={true}
                values={vendors?.map(i => ({ ...i, name: i.displayName }))}
                showEmpty
            />
        },
        {
            name: "Invoice Number",
            key: "invoiceNumber",
            required: true,
            editable: true,
        },
        {
            name: "Invoice Date",
            key: "issueDate",
            editable: true,
            required: true,
            component: <DatePicker
                title="Invoice Date"
                value={newInvoice?.issueDate ? moment(newInvoice?.issueDate).format("YYYY-MM-DD") : null}
                required={true}
                onSelect={(val) => onFieldChange('issueDate', val)} />
        },
        {
            name: "Delivery Date",
            key: "deliveryDate",
            editable: true,
            required: true,
            component: <DatePicker
                title="Delivery Date"
                value={newInvoice?.deliveryDate ? moment(newInvoice?.deliveryDate).format("YYYY-MM-DD") : null}
                required={true}
                onSelect={(val) => onFieldChange('deliveryDate', val)} />
        },
        {
            name: "Carrier Name",
            key: "carrier",
            required: true,
        },
        // {
        //     name: "Payment Terms",
        //     key: "paymentTerms",
        //     required: true,
        // },
        {
            name: "Bill Address",
            key: "billAddress",
            required: true,
        },
        // {
        //     name: "QB Bill Document Number",
        //     key: "quickbooksBillDocumentNumber",
        //     required: true,
        // },
        {
            name: "Payment Terms",
            key: "paymentTerms",
            required: true,
            component: <CustomInput
                id="paymentTerms"
                label="Payment Terms"
                value={newInvoice?.quickbooksTermId}
                onChange={(val) => onFieldChange('paymentTerms', val)}
                elementType="dropdown"
                required={true}
                values={terms}
                showEmpty
            />
        },
        // {
        //     name: "QB Transporter Id",
        //     key: "quickbooksTransporterId",
        //     required: true,
        // },
        // {
        //     name: "Departure Location",
        //     key: "pickupLotName"
        // },
        // {
        //     name: "Arrival Location",
        //     key: "deliveryLotName"
        // },
        // {
        //     name: "Distance",
        //     key: "miles",
        //     numberOnly: true,
        // },
        {
            name: "Invoice Document",
            key: "invoiceDocumentData",
            required: true,
            component: <>
                <DropZoneInDialog
                    labelDialog={'Upload File'}
                    buttonLabel={'Upload Invoice Document'}
                    actionButtonLabel={'Save'}
                    onSave={onUploadFile}
                    accept={{ 'application/pdf': ['.pdf'] }}
                />
                {newInvoice?.invoiceDocumentData &&
                    <div className={classes.document}>
                        <FadeInImage
                            img={{ type: 'pdf', fileName: fileName }}
                            isDocument={true}
                            showFileName
                        />
                    </div>
                }
            </>
        },
    ];

    const allFields = [
        {
            fields: invoiceFields,
        },
    ]



    const onFieldChange = (field, val) => {
        setNewInvoice({ ...newInvoice, [field]: val })
    };


    let [addInvoice, { isLoading: isAddInvoiceSaving, isSuccess: addSuccess }] = useAddInvoiceMutation();

    const onInvoiceSave = async () => {

        setIsSaving(true)

        let invoiceToSave = newInvoice
        let services = newInvoice?.services?.filter(s => !s?.deleted)
        invoiceToSave = { ...invoiceToSave, services: services }

        const result = await addInvoice(invoiceToSave);
        if (result?.error) {
            NotificationProvider.error(`Failed to created invoice`)
        } else {
            NotificationProvider.success(`Successfully created invoice`);
        }

        setIsSaving(false);
        handleClose();

    }

    const handleClose = () => {
        setNewInvoice({});
        // if (loadId) {
        //     let load = loads?.find(load => load.id == loadId)
        //     onLoadChange(load);
        // }
        onClose();
    }

    const isValid = () => invoiceFields.every(field => !field.required || newInvoice?.[field.key]) && (!newInvoice?.services || newInvoice?.services?.every(service => service.deleted || (service.description && service.quickbooksItemId && service.quickbooksAccountId && service.amount)));
    const isLoading = isAddInvoiceSaving || isLoadingLoads || isLoadingCustomer || isLoadingVendor;

    return (
        <>
            <LoadingSpinner loading={isLoading} />
            <Dialog maxWidth='md' fullWidth open={open} onClose={handleClose} aria-labelledby='dialog-title' className={classes.invoiceDialog}>
                <DialogTitle id="form-dialog-title">{'Create Invoice'}</DialogTitle>
                <DialogContent>
                    <Grid container direction="column" spacing={2}>
                        {allFields?.map((group, groupIndex) => (
                            <Grid item className={classes.groupWrapper} key={groupIndex} >
                                <Grid container spacing={2} direction="column" >
                                    {group.name && <Grid item className={classes.groupLabel} >{group.name}</Grid>}
                                    {group.fields?.map((field, fieldIndex) =>
                                        <Grid item key={fieldIndex}>
                                            <div className={classes.inputWrapper}>
                                                {field.component ??
                                                    textField(field)
                                                }
                                            </div>
                                        </Grid>)
                                    } </Grid>
                            </Grid>
                        )
                        )}
                        <Grid item className={classes.services} >
                            <Services invoiceServices={invoiceServices} setInvoiceServices={setInvoiceServices} invoiceToEdit={newInvoice} setInvoiceToEdit={setNewInvoice} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.button} variant="outlined"
                        onClick={handleClose}>Cancel</Button>
                    <Button
                        onClick={onInvoiceSave}
                        disabled={isSaving || !isValid()}
                        className={classes.button}
                        variant="contained" >
                        {isSaving ? "Saving..." : "Finish"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default AddInvoice;


const Services = ({ invoiceServices, setInvoiceServices, invoiceToEdit, setInvoiceToEdit }) => {
    const classes = AddInvoiceStyle();

    useEffect(() => {
        setInvoiceServices([])
    }, []);

    const onAddService = () => {
        const newObj = [...invoiceServices, { description: null, quickbooksItemId: null, quickbooksAccountId: null, amount: null, deleted: false }];
        setInvoiceServices(newObj);
    }

    const onFieldChange = (fieldName, value, index) => {
        let servicesList = [...invoiceServices];
        servicesList[index] = { ...servicesList[index], [fieldName]: value };
        setInvoiceServices(servicesList);
        setInvoiceToEdit({ ...invoiceToEdit, services: servicesList });
    }

    const onDelete = (index) => {
        let servicesList = [...invoiceServices];
        servicesList[index] = { ...servicesList[index], deleted: true }
        setInvoiceServices(servicesList);
        setInvoiceToEdit({ ...invoiceToEdit, services: servicesList });
    }


    return (
        <Grid container direction='column' spacing='2'>
            <Grid  ><Divider /></Grid>
            <Grid item className={classes.groupLabel} >Services</Grid>

            <Grid item xs={12} container className={classes.headers}>
                <Grid item xs={3}>Description</Grid>
                <Grid item xs={3}>Product/Service (invoice)</Grid>
                <Grid item xs={3}>Category (bill)</Grid>
                <Grid item xs={3} >Amount</Grid>
                {/* <Grid item xs={2} >Pass Through</Grid> */}
            </Grid>
            {invoiceServices?.map((item, index) => {
                if (item.deleted === false) {
                    return (
                        <Grid container xs={12} className={classes.editServiceContainer}>
                            <Grid item xs={11}>
                                <EditService onFieldChange={onFieldChange} serviceToEdit={item} index={index} />
                            </Grid>
                            <Grid item xs={1} className={classes.dropDownLine}>
                                <IconButton edge="end" aria-label="delete"
                                    onClick={() => onDelete(index)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>)
                }
            })}
            <Grid item>
                <Button
                    onClick={() => onAddService(true)}
                    startIcon={<AddCircle />}>Add Service</Button>
            </Grid>
            <Grid  ><Divider /></Grid>
        </Grid>
    )
}