import { Button, FormControlLabel, Grid, Paper, Switch } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import { useGetAllActivityItemsQuery, useGetAllActivityTypesQuery } from "features/activity/activitySlice";
import { useGetVehicleTypesQuery } from "features/vehicles/vehicleSlice";
import { ruleFieldType, ruleFieldTypeConfig } from "../../consts";
import { useAddMechanicalRuleMutation, useDeleteMechanicalsConfigMutation, useGetMechanicalRuleTypesQuery, useUpdateMechanicalRuleMutation } from "../../mechanicalsSlice";
import { lotMechanicalConfigStyles } from "./style";
import { useState } from "react";
import { useEffect } from "react";
import NotificationProvider from "components/NotificationProvider";
import { IconDetailing, IconFuel, IconInspect, IconMechanic, IconOil, IconTire } from "icons";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import Authorize from "components/Authorize";
import usePrompt from "hooks/usePrompt";
import { useGetServiceProvidersQuery } from "features/serviceProvider/serviceProviderSlice";


const Rule = ({ rule, onCancel }) => {
    const classes = lotMechanicalConfigStyles();

    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes } = useGetVehicleTypesQuery();
    let { data: activityTypes, error: activityTypesError, isFetching: isLoadingActivityTypes } = useGetAllActivityTypesQuery();//TODO only mechanicals
    let { data: mechanicalRuleTypes, error: mechanicalRuleTypesError, isFetching: isLoadingMechanicalRuleTypes } = useGetMechanicalRuleTypesQuery();//TODO only mechanicals
    let { data: serviceProviders, error: serviceProvidersError, isLoading: isLoadingServiceProviders } = useGetServiceProvidersQuery();
    let [updateMechanicalRule, { isLoading: isSaving }] = useUpdateMechanicalRuleMutation();
    let [deleteRule, { isLoading: isDeleting }] = useDeleteMechanicalsConfigMutation();
    let [addRule, { isLoading: isAdding }] = useAddMechanicalRuleMutation();

    const { triggerPrompt } = usePrompt();

    const ruleOperands = [
        {
            id: 1,
            name: 'And',
        },
        {
            id: 2,
            name: 'Or',
        }
    ]

    const ruleIcons = {
        'oil': <IconOil />,
        'fuel': <IconFuel />,
        'tire': <IconTire />,
        'inspection': <IconInspect />,
        'detailing': <IconDetailing />,
        DEFAULT: <IconMechanic />
    }
    const getIcon = (activityId) => {
        const parsedName = activityItems?.find(a => a.id == activityId)?.name.split(' ')[0].toLowerCase();
        const icon = ruleIcons[parsedName];
        return icon ?? ruleIcons.DEFAULT
    }

    const [ruleToEdit, setRuleToEdit] = useState({ ...rule });

    useEffect(() => {
        setRuleToEdit(rule);
    }, [rule]);

    const onFieldChange = (field, val) => {
        setRuleToEdit({ ...ruleToEdit, [field]: val })
    }

    const onRuleParamChange = (val, type, subType) => {
        let params = [...ruleToEdit?.ruleParams]
        let index = params.findIndex(p => p.paramType === type && p?.paramSubType === subType)
        if (index === -1) {
            params = [...params, { ['paramType']: type, ['paramSubType']: subType, ['paramValue']: val, ['ruleId']: ruleToEdit?.id }]
        }
        else {
            params[index] = { ...params[index], ['paramValue']: val };
        }
        setRuleToEdit({ ...ruleToEdit, ['ruleParams']: params })
    }

    const onSave = async () => {
        let res;
        if (ruleToEdit?.isNew) {
            res = await addRule({
                clientId: ruleToEdit.clientId,
                lotId: ruleToEdit.lotId,
                activityId: ruleToEdit.activityId,
                vehicleType: ruleToEdit?.vehicleType,
                calcOnCheckin: ruleToEdit?.calcOnCheckin || false,
                ruleOperand: ruleToEdit?.ruleOperand || 1,
                daysToComplete: ruleToEdit?.daysToComplete,
                preferredVendorId: ruleToEdit?.preferredVendorId,
                ruleParams: ruleToEdit?.ruleParams?.filter(r => r?.paramValue) || []
            })
        }
        else {
            res = await updateMechanicalRule(ruleToEdit);
        }
        if (!res?.error) {
            NotificationProvider.success('Successfully saved rule');
        } else {
            NotificationProvider.error('Failed to save rule');
            setRuleToEdit({ ...rule });
        }
    }


    const onDelete = async () => {
        triggerPrompt({
            title: "Delete Rule",
            content: "Are you sure you want to delete the rule?",
            onConfirm: async () => {
                const res = await deleteRule({
                    id: ruleToEdit.id
                })

                if (!res?.error) {
                    NotificationProvider.success('Successfully deleted rule');
                } else {
                    NotificationProvider.error('Failed to delete rule');
                }
            },
        });
    }

    const cancel = () => {
        if (ruleToEdit?.isNew) {
            onCancel();
        }
        else {
            setRuleToEdit(rule)
        }
    }

    const allRuleConfigs = mechanicalRuleTypes?.filter(c => c.paramType != ruleFieldType.VEHICLE_TYPE);
    const vehicleTypeConfig = mechanicalRuleTypes?.filter(c => c.paramType === ruleFieldType.VEHICLE_TYPE);

    const allRuleConfigsLookup = allRuleConfigs?.reduce(function (map, obj) {
        if (map[obj.activityId]) {
            map[obj.activityId] = [...map[obj.activityId], obj]
        } else {
            map[obj.activityId] = [obj];
        }
        return map;
    }, {})

    const vehicleTypeConfigLookup = vehicleTypeConfig?.reduce(function (map, obj) {
        map[obj.activityId] = obj;
        return map;
    }, {})

    const onActivityChange = (val) => {
        setRuleToEdit({
            ...ruleToEdit,
            activityId: val ? parseInt(val) : val,
            vehicleType: null,
            calcOnCheckin: false,
            ruleOperand: 1,
            daysToComplete: null,
            preferredVendorId: null,
            ruleParams: allRuleConfigsLookup?.[parseInt(val)] || []
        })
    }

    const filteredActivityItems = activityItems?.filter(a => mechanicalRuleTypes?.find(m => m.activityId === a.id))

    return (
        <Paper className={`${classes.listItemWrapper} ${ruleToEdit?.isNew ? classes.listItemWrapperNew : ""}`}>
            <Grid className={classes.ruleWrapper} container direction='column' spacing={1}>
                <Grid item className={classes.titleWrapper}>
                    {!ruleToEdit?.isNew ?
                        <div className={classes.title}>
                            {activityItems?.find(a => a.id == ruleToEdit.activityId)?.name}
                        </div>
                        :
                        <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.VIEW_CONFIG}>
                            <CustomInput
                                label="Activity"
                                elementType="dropdown"
                                value={ruleToEdit?.activityId}
                                values={filteredActivityItems}
                                onChange={val => onActivityChange(val)}
                                showEmpty
                            />
                        </Authorize>
                    }
                    <div className={classes.iconsWrapper}>
                        <div className={classes.iconWrapper}>{getIcon(ruleToEdit.activityId)}</div>
                    </div>
                </Grid>
                <Grid item className={classes.titleWrapper}>
                    {ruleToEdit?.activityId && <>
                        <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.VIEW_CONFIG}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="primary"
                                        checked={ruleToEdit?.calcOnCheckin}
                                        onChange={(e) => onFieldChange('calcOnCheckin', e.target.checked)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label={
                                    <div className={classes.label}>Consider Check In</div>
                                }
                                className={classes.wrapper}
                            />
                        </Authorize>
                        <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.VIEW_CONFIG}>
                            {
                                <Grid xs={3}>
                                    <CustomInput
                                        id="referredVendorId"
                                        label={"Preferred Vendor"}
                                        value={ruleToEdit?.preferredVendorId}
                                        onChange={(val) => onFieldChange('preferredVendorId', parseInt(val))}
                                        elementType="dropdown"
                                        values={serviceProviders}
                                        showEmpty={true}
                                    />
                                </Grid>
                            }
                        </Authorize>
                        <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.VIEW_CONFIG}>
                            {
                                <CustomInput
                                    label={"Days to Complete By"}
                                    elementType="input"
                                    value={ruleToEdit?.daysToComplete}
                                    onChange={(e) => onFieldChange('daysToComplete', e)}
                                />
                            }
                        </Authorize>
                    </>}
                </Grid >
                {ruleToEdit?.activityId && vehicleTypeConfigLookup?.[ruleToEdit?.activityId] &&
                    <Grid item>
                        {!!vehicleTypes?.length &&
                            <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.VIEW_CONFIG}>
                                <CustomInput
                                    label="Vehicle Type"
                                    elementType="dropdown"
                                    values={vehicleTypes ?? []}
                                    showEmpty={true}
                                    touched
                                    value={ruleToEdit.vehicleType}
                                    onChange={val => onFieldChange('vehicleType', val)}
                                />
                            </Authorize>
                        }
                    </Grid>
                }
                <Grid item className={classes.params}>
                    <Grid container spacing={1}>
                        {allRuleConfigsLookup?.[ruleToEdit?.activityId]?.map((param, index) =>
                            <>
                                <Grid item key={index} >
                                    <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.VIEW_CONFIG}>
                                        {ruleFieldTypeConfig[param.paramType]?.component(
                                            ruleToEdit?.ruleParams?.find(p => p?.paramType === param?.paramType && p?.paramSubType === param?.paramSubType),
                                            (val) => onRuleParamChange(val, param?.paramType, param?.paramSubType),
                                            param?.paramSubType
                                        )}
                                    </Authorize>
                                </Grid>
                                {index !== (allRuleConfigsLookup?.[ruleToEdit?.activityId]?.length - 1) &&
                                    <Grid item key={index} className={classes.ruleOperand}>
                                        <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.VIEW_CONFIG}>
                                            <CustomInput
                                                // label={}
                                                elementType="dropdown"
                                                value={ruleToEdit?.ruleOperand}
                                                values={ruleOperands}
                                                onChange={val => onFieldChange('ruleOperand', parseInt(val))}
                                            />
                                        </Authorize>
                                    </Grid>
                                }
                            </>
                        )}
                    </Grid>
                </Grid>
                <Authorize profile={permissionProfiles.MECHANICALS_CONFIG.SAVE_CONFIG}>
                    <div className={classes.btnContainer}>
                        <Button
                            onClick={cancel}
                            className={classes.button}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        {!ruleToEdit?.isNew && <Button
                            onClick={onDelete}
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            disabled={isDeleting}
                        >
                            {(isDeleting) ? "Deleting..." : "Delete"}
                        </Button>}
                        <Button
                            onClick={onSave}
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            disabled={isAdding || isSaving || !ruleToEdit?.activityId}
                        >
                            {(isAdding || isSaving) ? "Saving..." : "Save"}
                        </Button>
                    </div>
                </Authorize>
            </Grid>
        </Paper>
    )
}

export default Rule;