import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {accessApi} from "features/access/accessSlice";
import activityReportFiltersReducer from 'features/activity/activityReportFiltersSlice';
import {activityApi} from "features/activity/activitySlice";
import addAssetReducer from 'features/addAsset/addAssetSlice';
import {lotConfigApi, lotConfigSlice} from 'features/clients/clientConfigs/lots/lotConfigSlice';
import {clientConfigApi} from 'features/clients/clientConfigSlice';
import {clientApi} from "features/clients/clientSlice";
import {
    mobileWorkflowSlice,
    workflowApi
} from 'features/clients/contracts/EditContractPopup/EditWorkFlowPopup/workFlowSlice';
import {loadInvoiceApi, loadInvoiceStateSlice} from 'features/invoice/loads/loadInvoiceSlice';
import {storageInvoiceApi, storageInvoiceStateSlice} from 'features/invoice/storage/storageInvoiceSlice.ts';
import {dashboardSlice} from 'features/landing/dashboardSlice';
import {oneOnOneApi} from 'features/landing/components/KpiDashboard/navigations/KpiOneOnOne/OneOnOneSlice';
import loadFiltersReducer from 'features/loads/loadsFiltersSlice';
import accessStateReducer from 'features/access/accessStateSlice';
import {loadApi} from 'features/loads/loadsSlice';
import {submarketApi} from 'features/lots/components/LotSubMarketsPage/lotSubMarketsSlice';
import {lotApi, subMarketsSlice} from 'features/lots/lotSlice';
import {meApi} from 'features/me/meSlice';
import {subscriptionApi} from 'features/subscriptions/subscriptionSlice';
import {taskApi, taskStateSlice} from 'features/Tasks/taskSlice';
import {transporterApi} from "features/transporter/transporterSlice";
import userReducer from 'features/user/userSlice';
import {usersApi} from 'features/users/usersSlice';
import {readinessApi} from 'features/vehicles/readiness/readinessSlice';
import vehicleFiltersReducer from 'features/vehicles/vehicleFiltersSlice';
import OneOnOneFiltersReducer
    from 'features/landing/components/KpiDashboard/navigations/KpiOneOnOne/OneOnOneFilterSlice';
import usersFiltersReducer from 'features/users/usersFiltersSlice';
import auditFiltersSlice from 'features/vehicles/VehicleLotAudit/auditFiltersSlice';
import {vehicleApi} from "features/vehicles/vehicleSlice";
import {configurationApi} from "features/vehicles/configurationSlice";
import {vendorApi} from "features/vendors/vendorSlice";
import {createBrowserHistory} from "history";
import {loadUser, reducer as oidcReducer} from 'redux-oidc';
import {confirmationApi} from '../../components/ExternalConfirmation/confirmationSlice';
import {rtkQueryErrorLogger} from '../errorHandling';
import userManager from "../userManager";
import auditListFiltersSlice from 'features/vehicles/VehicleLotAudit/components/AuditList/auditListFiltersSlice';
import issuesTrackerFiltersReducer from 'features/reporting/IssuesTracker/issuesTrackerFiltersSlice';
import {issuesTrackerApi} from "features/reporting/IssuesTracker/issuesTrackerSlice";
import {mechanicalsApi} from 'features/clients/contracts/MechanicalWorkConfig/mechanicalsSlice';
import {activityInvoiceStateSlice} from 'features/invoice/activity/activityInvoiceSlice';
import {inspectionSlice} from 'features/landing/components/KpiDashboard/navigations/KpiInspection/inspectionSlice';
import {billingApi, billingDashboardSlice} from 'features/landing/components/BillingDashboard/billingSlice';
import {mobileActivityApi, mobileActivitySlice} from 'features/MobileActivity/mobileActivitySlice';
import {notesApi} from 'features/notes/notesSlice';
import {washSlaSlice} from 'features/clients/clientConfigs/washes/WashSlaSlice';
import activityTypeFiltersSlice from 'features/pricing/ActivityTypesList/activityTypeFiltersSlice';
import {activityTypePriceApi} from 'features/pricing/pricingSlice';
import activityPricingFiltersSlice from 'features/pricing/PricingList/activityPricingFiltersSlice';
import {serviceProviderApi} from 'features/serviceProvider/serviceProviderSlice';
import inventoryFiltersReducer from 'features/inventory/inventoryFiltersSlice';
import {activityTypeInventoryApi} from 'features/activity/ActivityTypeInventorySlice'
import {activityTypeStepApi} from 'features/activity/ActivityTypeStepSlice'
import {mechanicalTaskSlice} from 'features/mechanicalTasks/slice';
import {driverApi} from 'features/drivers/driversSlice';
import {rolesApi} from 'components/Authorize/rolesSlice';
import {mechanicalWorkApi} from "../../features/mechanicalWork/mechanicalWorkSlice";

export const history = createBrowserHistory();

let store = configureStore({
    reducer: {
        oidc: oidcReducer,
        user: userReducer,
        usersFilters: usersFiltersReducer,
        vehicleFilters: vehicleFiltersReducer,
        oneOnOneFilters: OneOnOneFiltersReducer,
        loadFilters: loadFiltersReducer,
        accessState: accessStateReducer,
        auditFilters: auditFiltersSlice,
        auditListFilters: auditListFiltersSlice,
        activityReportFilters: activityReportFiltersReducer,
        activityTypeFilters: activityTypeFiltersSlice,
        activityPricingFilters: activityPricingFiltersSlice,
        addAsset: addAssetReducer,
        taskState: taskStateSlice.reducer,
        subMarketsState: subMarketsSlice.reducer,
        dashboardState: dashboardSlice.reducer,
        inspectionState: inspectionSlice.reducer,
        loadInvoiceState: loadInvoiceStateSlice.reducer,
        storageInvoiceState: storageInvoiceStateSlice.reducer,
        activityInvoiceState: activityInvoiceStateSlice.reducer,
        mobileWorkflowState: mobileWorkflowSlice.reducer,
        lotConfigState: lotConfigSlice.reducer,
        billingDashboardState: billingDashboardSlice.reducer,
        mobileActivityState: mobileActivitySlice.reducer,
        washConfigState: washSlaSlice.reducer,
        mechanicalTaskSlice: mechanicalTaskSlice.reducer,
        configurationApiSlice: configurationApi.reducer,
        inventoryFilters: inventoryFiltersReducer,
        [vehicleApi.reducerPath]: vehicleApi.reducer,
        [configurationApi.reducerPath]: configurationApi.reducer,
        [lotApi.reducerPath]: lotApi.reducer,
        [loadApi.reducerPath]: loadApi.reducer,
        [clientApi.reducerPath]: clientApi.reducer,
        [clientConfigApi.reducerPath]: clientConfigApi.reducer,
        [lotConfigApi.reducerPath]: lotConfigApi.reducer,
        [accessApi.reducerPath]: accessApi.reducer,
        [vendorApi.reducerPath]: vendorApi.reducer,
        [driverApi.reducerPath]: driverApi.reducer,
        [activityApi.reducerPath]: activityApi.reducer,
        [transporterApi.reducerPath]: transporterApi.reducer,
        [subscriptionApi.reducerPath]: subscriptionApi.reducer,
        [confirmationApi.reducerPath]: loadApi.reducer,
        [meApi.reducerPath]: loadApi.reducer,
        [readinessApi.reducerPath]: readinessApi.reducer,
        [taskApi.reducerPath]: taskApi.reducer,
        [workflowApi.reducerPath]: workflowApi.reducer,
        [loadInvoiceApi.reducerPath]: loadInvoiceApi.reducer,
        [storageInvoiceApi.reducerPath]: loadInvoiceApi.reducer,
        [submarketApi.reducerPath]: submarketApi.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
        [oneOnOneApi.reducerPath]: oneOnOneApi.reducer,
        issuesTrackerFilters: issuesTrackerFiltersReducer,
        [issuesTrackerApi.reducerPath]: issuesTrackerApi.reducer,
        [mechanicalsApi.reducerPath]: mechanicalsApi.reducer,
        [billingApi.reducerPath]: billingApi.reducer,
        [mobileActivityApi.reducerPath]: mobileActivityApi.reducer,
        [notesApi.reducerPath]: notesApi.reducer,
        [activityTypePriceApi.reducerPath]: activityTypePriceApi.reducer,
        [serviceProviderApi.reducerPath]: serviceProviderApi.reducer,
        [activityTypeInventoryApi.reducerPath]: activityTypeInventoryApi.reducer,
        [activityTypeStepApi.reducerPath]: activityTypeStepApi.reducer,
        [mechanicalWorkApi.reducerPath]: mechanicalWorkApi.reducer,
        router: connectRouter(history),
        inventoryFilters: inventoryFiltersReducer,
        [rolesApi.reducerPath]: rolesApi.reducer,
    },
    middleware: getDefaultMiddleware({ serializableCheck: false })
        .concat(vehicleApi.middleware)
        .concat(configurationApi.middleware)
        .concat(lotApi.middleware)
        .concat(submarketApi.middleware)
        .concat(usersApi.middleware)
        .concat(oneOnOneApi.middleware)
        .concat(clientApi.middleware)
        .concat(clientConfigApi.middleware)
        .concat(lotConfigApi.middleware)
        .concat(accessApi.middleware)
        .concat(vendorApi.middleware)
        .concat(driverApi.middleware)
        .concat(activityApi.middleware)
        .concat(transporterApi.middleware)
        .concat(loadApi.middleware)
        .concat(subscriptionApi.middleware)
        .concat(confirmationApi.middleware)
        .concat(readinessApi.middleware)
        .concat(taskApi.middleware)
        .concat(workflowApi.middleware)
        .concat(loadInvoiceApi.middleware)
        .concat(storageInvoiceApi.middleware)
        .concat(mechanicalsApi.middleware)
        .concat(billingApi.middleware)
        .concat(notesApi.middleware)
        .concat(activityTypeInventoryApi.middleware)
        .concat(activityTypeStepApi.middleware)
        .concat(serviceProviderApi.middleware)
        .concat(routerMiddleware(history))
        .concat(rtkQueryErrorLogger)
        .concat(rolesApi.middleware)
        .concat(mechanicalWorkApi.middleware)
});

loadUser(store, userManager)
    .then(r => console.log('user loaded', r));

export default store;
