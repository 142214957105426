import { useGetClientsWithConfigsQuery } from "../../../features/clients/clientConfigSlice";
import { useGetLotsQuery } from "../../../features/lots/lotSlice";
import DropdownFilter from "../../DropdownFilter";
import { getLotsWithPmfText } from "../../../features/lots/lotUtil";

export default function LotsDropdown({
    title,
    identifier,
    onSelect,
    showEmpty,
    clientId,
    value,
    multiple,
    pmfLot,
    required,
    classStyles
}) {
    let { data: clientsWithConfigs, error: error, isLoading: isLoading } = useGetClientsWithConfigsQuery(null);
    let { data: getLots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    const clientsConfigs = clientsWithConfigs?.find(c => c.id == clientId);
    let lots = !clientsConfigs?.clientLots ? getLots : getLots?.filter(lot => clientsConfigs?.clientLots?.find(l => l.lotId == lot.id));
    lots = pmfLot ? lots?.filter(lot => lot.pmfLot) : lots;
    
    return (
        <DropdownFilter
            title={title}
            identifier={identifier}
            values={getLotsWithPmfText(lots)}
            onSelect={onSelect}
            value={value}
            showEmpty={showEmpty}
            multiple={multiple}
            required={required}
            classStyles={classStyles} />
    )
}