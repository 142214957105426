import { modes } from "../modes";
import { permissions } from "../permissions/permissions";
import { roles } from "../roles";

export const inventoryPermissionProfile = {
    INVENTORY_CREATE_EDIT: {
        [modes.EDIT]: [roles.OWNER, roles.MACHANIC, roles.ACCOUNT_MANAGER]
    },
    INVENTORY_RESTOCK: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER],
    }
}