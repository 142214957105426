import { makeStyles } from "@material-ui/core";

export const mechanicalConfigStyles = makeStyles(theme => ({
    lotWrapper: {
        padding: '1em',
        cursor: 'pointer'
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        backgroundColor: "rgb(212, 236, 255)",
        borderRadius: "50%",
        maxWidth: "fit-content",
        padding: "5px",
        margin: "0 1rem"
    },
    lotName: {
        fontWeight: 'bold',
        fontSize: '1.1em',
        color: theme.palette.secondary.light
    },
    inputField: {
        width: 260,
        padding: '0.5em' ,
        paddingLeft: "0em"
    },
    marginWrapper: {
        margin: '.4em',
        padding: '.5em'
    }
}));