import { makeStyles } from "@material-ui/core"

export const AccessStyle = makeStyles((theme) => ({
    listWrapper: {
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        height: '100%',
        "& ul": {
            flex: '1'
        }
    },
    listItem: {
        borderBottom: '1px solid lightgrey',
        '&:last-of-type': {
            border: 'none'
        }
    },
    footer: {
        display: 'flex',
        margin: '1rem auto'
    },
    typeWrapper: {
        marginBottom: '1rem',
        padding: '1rem'
    }
}));