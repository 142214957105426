import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

export const pageStyles = makeStyles((theme) => ({
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 100px)'
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',

        minHeight: '50vh'
    },
    header: {
        marginBottom: '1em',
    }
}));
