import { loadPermissions } from "./loadPermissions";
import { vehiclePermissions } from './vehiclePermissions';
import { lotPermissions } from "./lotPermissions";
import { conditionReportPermissions } from "./ConditionReportPermissions";
import { activityPermissions } from "./activityPermissions";
import { pricingPermissions } from "./pricingPermissions";
import { permissionPermissions } from "./permissionPermissions";
import { userPermissions } from "./userPermissions";

export const permissions = {
    VEHICLE: vehiclePermissions,
    LOTS: lotPermissions,
    LOADS: loadPermissions,
    Condition_Report: conditionReportPermissions,
    ACTIVITIES: activityPermissions,
    PRICING: pricingPermissions,
    PERMISSION: permissionPermissions,
    USER: userPermissions
}