import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from "../../app/baseQuery";

export const transporterApi = createApi({
    reducerPath: 'transporterApi',
    baseQuery: baseQuery,
    tagTypes: ["Transporters", "Transporter"],
    endpoints: (builder) => ({
        getTransporters: builder.query({
            query: () => "transporter",
            providesTags: ["Transporters"]
        }),
        createTransporter: builder.mutation({
            query: transporter => ({
                url: "transporter",
                method: "POST",
                body: transporter
            }),
            invalidatesTags: ["Transporters"]
        }),
        saveTransporter: builder.mutation({
            query: transporter => ({
                url: "transporter",
                method: "PUT",
                body: transporter
            }),
            invalidatesTags: ["Transporters"]
        }),
        deleteTransporter: builder.mutation({
            query: id => ({
                url: `transporter?id=${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Transporters"]
        }),
        assignToClient: builder.mutation({
            query: transporter => ({
                url: 'transporter/assigntoclient',
                method: "POST",
                body: transporter
            })
        }),
        removeFromClient: builder.mutation({
            query: transporter => ({
                url: 'transporter/removefromclient',
                method: "DELETE",
                body: transporter
            })
        }),
    }),
});

export const {
    useGetTransportersQuery,
    useCreateTransporterMutation,
    useSaveTransporterMutation,
    useDeleteTransporterMutation,
    useAssignToClientMutation, 
    useRemoveFromClientMutation
} = transporterApi;

