import { Button, Grid } from '@material-ui/core';
import DataListHeader from "components/DataListHeader";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import PageComponent from "components/Page/PageComponent";
import { useGetLotsQuery } from "features/lots/lotSlice";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { InvoiceTypes } from '../../../invoiceConsts.ts';
import InvoiceScreenSelector from '../../../shared/InvoiceScreenSelector';
import { useGetActivityInvoicesQuery, useGetActivityReportsMutation } from '../../activityInvoiceSlice.js';
import ActivityInvoiceHeader from '../ActivityInvoiceHeader/ActivityInvoiceHeader.js';
import ActivityInvoice from '../ActivityInvoive/ActivityInvoice.js';
import { useGetAllActivityItemsQuery } from 'features/activity/activitySlice.js';
import ActivityInvoiceSummary from '../ActivityInvoiceSummary/ActivityInvoiceSummary.js';
import ExportButton from 'components/ExportButton.js';
import { activityDetailsColumns } from '../../activityInvoiceConsts.js';
import BasicModal from 'components/BasicModal.js';
import SelectFieldsPopup from 'features/vehicles/selectFieldsPopup.js';
import ExportReportsButton from '../ExportReportsButton/ExportReportsButton.js';

const ActivityInvoicePage = () => {

    const { filters, searchVal } = useSelector(state => state.activityInvoiceState);

    let { data: invoices, error, isFetching } = useGetActivityInvoicesQuery({
        clientId: filters?.['clientId'],
        month: filters?.['month'],
        year: filters?.['year'] || new Date().getFullYear()
    }, { skip: !filters?.['clientId'] || !filters?.['month'] });

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: activityItems, error: activityItemsError, isLoading: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    const [getActivityReports, { isLoading: gettingReports }] = useGetActivityReportsMutation()

    const invoicesData = invoices?.map(invoice => {
        const lot = lots?.find(l => l.id === invoice?.lotId);
        return ({
            ...invoice,
            lot: lot,
            lotInfo: [lot?.name, lot?.addressLine1, lot?.city, lot?.state, lot?.zipCode]?.join(', '),
            activityInvoiceDetails: invoice?.activityInvoiceDetails?.map(det => {
                const activityItem = activityItems?.find(a => a.id === det?.activityItemId);
                return ({ ...det, activityItemName: activityItem?.name, lotId: lot?.id })
            })
        })
    })

    const dataForExport = invoicesData?.reduce((result, obj) => {
        return result.concat(obj?.activityInvoiceDetails);
    }, []);

    const mapExportData = row => {
        const detailsInfo = {};
        activityDetailsColumns?.map(col => {
            detailsInfo[col.key] = row?.[col.key]
        });
        const invoiceData = {
            ['Client']: filters?.['clientId'],
            ['Lot ID']: row.lotId,
            ...detailsInfo
        };

        return invoiceData;
    };

    let reportColumns = [
        { name: "Invoice Report", key: "1", selectedByDefault: true, width: 200 },
        { name: "Activity VIN Report", key: "2", selectedByDefault: true, width: 200 }
    ];

    const [selectedColumns, setSelectedColumns] = useState([...reportColumns].filter(c => c.selectedByDefault));

    const [openSelectFieldsPopup, setOpenSelectFieldsPopup] = useState(false);
    let [isDownloadingReports, setIsDownloadingReports] = useState(false);

    const isChecked = (checked, columnName) => {
        return [...checked].filter(column => column ? column.name == columnName : false).length > 0;
    }

    const onSelectExistingClick = () => {
        setOpenSelectFieldsPopup(true);
        setIsDownloadingReports(true);
    }

    const onCloseModal = () => {
        setOpenSelectFieldsPopup(false);
        setIsDownloadingReports(false);
    }

    const onSubmitSelectedFields = async (checked) => {
        setSelectedColumns(
            reportColumns.map((column) => (isChecked(checked, column.name) ? column : null))
                .filter(column => column)
        );

        let reportsToSend = [];
        reportColumns.map((column) => (isChecked(checked, column.name) ? column : null)).forEach((id) => {
            if (id !== null) {
                reportsToSend.push(parseInt(id.key));
            }
        })

        let reportsToSendObj = {
            ReportTypes: reportsToSend,
            ClientId: parseInt(filters?.['clientId']),
            Month: parseInt(filters?.['month']),
            Year: new Date().getFullYear()
        }

        setOpenSelectFieldsPopup(false);

        setIsDownloadingReports(true);
        await getActivityReports(reportsToSendObj);
        setIsDownloadingReports(false);
    }

    const needsFiltering = !filters?.['clientId'] || !filters?.['month'];
    return (
        <PageComponent
            loading={isFetching}
            header={
                <DataListHeader
                    titleSize={6}
                    titleComponent={(
                        <InvoiceScreenSelector
                            selected={InvoiceTypes.ACTIVITY}
                        />
                    )}
                    actionButtons={<Grid container spacing={1}>
                        {/* <Grid item>
                            <FormControlLabel
                                control={<Checkbox checked={showComplete}
                                    onChange={(e, value) => setShowComplete(value)} />}
                                label="Include History" />
                        </Grid> */}
                        <Grid item>
                            {/* <ExportButton fileLabel="Export" data={dataForExport} mapExportData={mapExportData} /> */}
                            {!needsFiltering &&
                                <ExportReportsButton />
                            }
                        </Grid>
                    </Grid>}
                />}>
            <ActivityInvoiceHeader />
            <BasicModal
                open={openSelectFieldsPopup}
                header={`Select Reports`}
                handleClose={() => onCloseModal(false)}
                component={<SelectFieldsPopup columns={reportColumns}
                    selectedColumns={selectedColumns}
                    isChecked={isChecked}
                    onSubmit={onSubmitSelectedFields}
                    handleClose={() => onCloseModal(false)} />}
            />
            {needsFiltering ?
                <EmptyPlaceholder text={'Please select client and month'} /> :
                !!invoicesData ?
                    <>

                        <Grid direction='column' spacing={1}>
                            {invoicesData?.map(invoice => <Grid item ><ActivityInvoice key={invoice.id} invoice={invoice} /></Grid>)}
                        </Grid>
                        <ActivityInvoiceSummary invoices={invoicesData} />
                    </> :
                    <EmptyPlaceholder text={'No Invoices'} />}
        </PageComponent>
    )
}
export default ActivityInvoicePage;