import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, ListItem } from '@material-ui/core';
import { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconDriver } from 'icons';
import CustomInput from 'components/CustomInput';
import { EditListsStyle } from './EditListsStyle';
import BasicModal from 'components/BasicModal';
import AddDriver from 'features/access/AccessDialog/Steps/DriverList/AddDriver';
import { useDeleteDriverMutation } from 'features/drivers/driversSlice';
import AddIcon from '@material-ui/icons/Add';
import NotificationProvider from 'components/NotificationProvider';
import LoadingSpinner from 'components/LoadingSpinner';


const EditDriversList = ({ access, driversToEdit, setDriversToEdit }) => {

    const classes = EditListsStyle()
    const [openNewDriver, setOpenNewDriver] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [driverToDelete, setDriverToDelete] = useState(null);

    const [deleteDriver, { isLoading: isDeleting }] = useDeleteDriverMutation();


    // const [selectedDriver, setSelectedDriver] = useState(null);

    // let { data: drivers, error: driverError, isLoading: isLoadingDrivers } = useGetDriversQuery();

    // drivers = drivers?.map(d => ({
    //     ...d,
    //     name: `${d?.firstName} ${d?.lastName}`
    // }))

    // drivers = drivers?.filter(d => d.vendorId == access.carrierId);

    const onSelectDriver = (val, newDriver) => {
        let driversList = driversToEdit ? [...driversToEdit] : [];
        // let newItem = drivers?.find(d => d.id == val);
        // if (newItem) {
        //     driversList?.push(newItem)
        //     setDriversToEdit(driversList);
        // }
        if (newDriver) {
            driversList?.push({ ...newDriver, id: val })
            setDriversToEdit(driversList);
        }
    }

    const onDelete = (item) => {
        setDialogOpen(true)
        setDriverToDelete(item)
    }

    const onConfirmDelete = async () => {
        let result = await deleteDriver({ id: driverToDelete.id });
        if (result && !result.error) {
            NotificationProvider.success("Driver deleted successfully");
            setDialogOpen(false);
            setDriverToDelete(null);
            setDriversToEdit(driversToEdit.filter(d => d.id !== driverToDelete.id));
        } else {
            NotificationProvider.error("Failed to delete the Driver");
        }
    }


    return (
        <>
            {/* <Grid className={classes.select} container direction='row' alignItems='center'>
                    <Grid item xs={10} >
                        <CustomInput
                            id="driver"
                            label="Select Drivers"
                            elementType="dropdown"
                            values={drivers}
                            value={selectedDriver}
                            showEmpty
                            onChange={val => setSelectedDriver(val)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button className={classes.buttonAdd} variant='contained' color='primary' disabled={!selectedDriver}
                            onClick={() => { onSelectDriver(selectedDriver) }}>
                            Add
                        </Button>
                    </Grid>
                </Grid> */}
            <div className={classes.paper}>
                <div className={classes.listWrapper}>
                    {
                        driversToEdit?.map((item, index) => {
                            return <div key={item}>
                                <ListItem className={classes.listItem}>
                                    <Grid container spacing={1} alignItems='center'>
                                        <Grid item xs={1}>
                                            <IconDriver className={classes.listItemIcon} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label className={classes.label}>{item?.firstName} {item?.lastName}</label>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <label className={classes.label}>{item?.phone}</label>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <label className={classes.label}>{item?.email}</label>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton edge="end" aria-label="delete"
                                                onClick={() => onDelete(item)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            </div>
                        })
                    }
                </div>

                <Button
                    className={classes.addButton}
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setOpenNewDriver(true)}
                >
                    Add driver
                </Button>
            </div>
            <BasicModal
                open={openNewDriver}
                header={`New Driver`}
                handleClose={() => setOpenNewDriver(false)}
                component={<AddDriver accessToEdit={access} handleClose={() => setOpenNewDriver(false)} onSave={(id, newDriver) => { onSelectDriver(id, newDriver) }} />}
            />
            <Dialog
                open={dialogOpen}
                onClose={() => { }}>
                <DialogTitle>Delete Driver</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this Driver?
                    <LoadingSpinner loading={isDeleting} />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => setDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onConfirmDelete}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default EditDriversList



