import { Grid } from "@material-ui/core";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { useGetLotsQuery, useGetSummaryQuery } from "features/lots/lotSlice";
import { IconLot } from "icons";
import { useParams } from "react-router-dom";
import { lotSummaryStyle } from "./style";

const LotSummary = ({
    lotId, showLotTitle = true
}) => {

    const params = useParams();
    const correctLotId = params.id ?? lotId;

    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: summary, error: summaryError, isFetching: isLoadingSummary } = useGetSummaryQuery(correctLotId);

    const lot = lots?.find(l => l.id == correctLotId);

    const isLoading = () => isLoadingSummary || isLoadingLots;

    const classes = lotSummaryStyle();
    const counts = [
        {
            key: 'numberOfSpots',
            label: 'Number of spots',
        },
        {
            key: 'carSpotsCount',
            label: 'Cars',
        },
        {
            key: 'truckSpotsCount',
            label: 'Trucks',
        }
    ]

    const dynamicCounts = [
        {
            key: 'totalOnLot',
            label: 'Assets on lot',
            profile: permissionProfiles.LOT.LOT_VIEW_OVERVIEW_OCCUPANCY
        },
        {
            key: 'leasedSpots',
            label: 'Leased spots',
            className: classes.leasedCount,
            profile: permissionProfiles.LOT.LOT_VIEW_OVERVIEW_OCCUPANCY
        },
        {
            key: 'overageSpots',
            label: 'Overage',
            className: classes.overageCount,
            profile: permissionProfiles.LOT.LOT_VIEW_OVERVIEW_OCCUPANCY
        },
        {
            key: 'availableForSale',
            label: 'Available for sale',
            className: classes.emptyCount,
            profile: permissionProfiles.LOT.LOT_VIEW_OVERVIEW_OCCUPANCY
        },
        {
            key: 'onLotForClient',
            label: 'Assets on lot',
            profile: permissionProfiles.LOT.LOT_VIEW_OVERVIEW_CLIENT
        },
        {
            key: 'leasedForClient',
            label: 'Leased spots',
            profile: permissionProfiles.LOT.LOT_VIEW_OVERVIEW_CLIENT
        },
    ]

    return (
        <>
            <LoadingSpinner loading={isLoading()} />
            {showLotTitle && <>
                <div className={classes.title}><IconLot height='27' width='27' /><div>{lot?.name}</div></div>
                <div className={classes.address}>{lot?.addressLine1} {lot?.city} {lot?.state} {lot?.zipCode}</div>
            </>}

            <Grid direction='column' spacing={1}>
                {counts?.map(count =>
                    <Grid item spacing={2} alignItems='center' container key={count.key} className={classes.countWrapper}>
                        <Grid item className={classes.count}> {lot?.[count.key] ?? 0} </Grid>
                        <Grid item className={classes.label}>{count?.label}</Grid>
                    </Grid>)}
            </Grid>

            <Grid direction='column' spacing={1}>
                {dynamicCounts?.map(count =>
                    <Authorize profile={count.profile}>
                        <Grid item spacing={2} alignItems='center' container key={count.key} className={`${classes.countWrapper}`}>
                            <Grid item className={`${classes.count}  ${count.className}`}> {summary?.[count.key] ?? 0} </Grid>
                            <Grid item className={classes.label}>{count?.label}</Grid>
                        </Grid>
                    </Authorize>)}
            </Grid>
        </>
    )
}
export default LotSummary;
