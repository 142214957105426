import { CircularProgress, makeStyles } from "@material-ui/core";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import _, { first } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, NavLink } from "react-router-dom";
import LoadButton from "../../components/LoadButton/LoadButton";
import NotificationProvider from "../../components/NotificationProvider";
import useSortOptions from "../../hooks/useSortOptions";
import FormatService from "../../services/formatService";
import { useGetClientsWithConfigsQuery } from '../clients/clientConfigSlice';
import { useGetClientsQuery } from "../clients/clientSlice";
import { useGetSubMarketsQuery } from "../lots/components/LotSubMarketsPage/lotSubMarketsSlice";
import { useGetLotsQuery } from "../lots/lotSlice";
import { selectIsAccountManager, selectIsAdmin, selectIsClient, selectIsOwner, selectUserClientId } from "../user/userSlice";
import { readinessTypes } from "./components/AssetReadiness/readinessUtil";
import AssetReadinessStatuses from "./components/AssetReadinessStatuses/AssetReadinessStatuses";
import { useGetReadinessQuery } from './readiness/readinessSlice.js';
import { useDeleteAssetMutation, useGetVehiclesQuery, useGetVehicleStatusQuery, useGetVehicleTypesQuery } from "./vehicleSlice";
import VehicleTable from "./VehicleTable";
import {
    useGetAllIssuesTrackerQuery,
} from "../../features/reporting/IssuesTracker/issuesTrackerSlice";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme) => ({
    spinner: {
        position: 'absolute',
        top: '50%',
        left: 'calc(50% + 100px)',
        zIndex: '100'
    },
    icon: {
        fill: theme.palette.common.white,
    },
    addAssetButton: {
        marginRight: 16
    }
}));

const VehiclesPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const [openToolTipLotIssueTracker, setOpenToolTipLotIssueTracker] = useState(false);

    let isAdmin = useSelector(selectIsAdmin);
    let isOwner = useSelector(selectIsOwner);
    let isAccountManager = useSelector(selectIsAccountManager);
    let isClient = useSelector(selectIsClient);
    let userClientId = useSelector(selectUserClientId);
    const [includeHistory, setIncludeHistory] = useState(false);
    let user = useSelector(state => state.oidc.user);
    let isUserLoaded = !!user;

    let { data: vehiclesData, error: vehicleError, isFetching: isFetchingVehicles, refetch: refetchVehicles } = useGetVehiclesQuery({ includeHistory });
    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes } = useGetVehicleTypesQuery();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null, { skip: !isAdmin && !isAccountManager });
    let { data: statuses, error: statusError, isLoading: isLoadingStatuses } = useGetVehicleStatusQuery();
    let { data: vehiclesReadiness, error: vehiclesReadinessError, isLoading: isLoadingVehiclesReadiness } = useGetReadinessQuery({});
    let { data: clientsWithConfigs, error: clientsWithConfigsError, isFetching: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery(null, { skip: !isUserLoaded });
    let { data: subMarkets, error: smError, isLoading: isLoadingSm } = useGetSubMarketsQuery();
    const [includeIssueTrackerHistory, setIncludeIssueTrackerHistory] = useState(false);
    let { data: issuesTracker, error: issuesTrackerError, isFetching: isLoadingIssuesTracker } = useGetAllIssuesTrackerQuery({ includeIssueTrackerHistory });
    

    vehiclesData = vehiclesData || [];
    vehicleTypes = vehicleTypes || [];
    lots = lots || [];
    clients = clients || [];
    statuses = statuses || [];
    clientsWithConfigs = clientsWithConfigs || [];
    issuesTracker = issuesTracker || [];

    const clientConfigs = clientsWithConfigs?.find(c => c.id == userClientId);
    const isUsingTransports = clientConfigs?.config?.transportConfig?.useTransport;
    const isUsingWash = clientConfigs?.config?.washConfig?.useWashProgram;
    const isUsingManualReadiness = clientConfigs?.config?.readinessConfig?.readinessType == readinessTypes.MANUAL;

    const usingReadiness = isUsingTransports || isUsingWash || isUsingManualReadiness;

    const readinessByVehicleId = vehiclesReadiness?.reduce(function (map, obj) {
        map[obj.vehicleId] = obj;
        return map;
    }, {})

    const subMarketsForLot = {}
    subMarkets?.map(s => {
        s?.lotIds?.map(l => {
            subMarketsForLot[l] = [...(subMarketsForLot[l] ?? []), s.id + ""];
        })
    })

    const subMarketMap = subMarkets?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {})

    const isLoading = () => isFetchingVehicles || isLoadingLots || isLoadingVehicleTypes || isLoadingClients || isLoadingStatuses;

    const getLotName = id => {
        let lot = lots.find(l => l.id === id) || {};
        return lot.name;
    };

    const getClientName = customerId => {
        let client = clients.find(c => c.clientAccounts.find(cu => cu.id === customerId)) || {};
        return client.name;
    };

    const getStatusName = id => statuses.find(s => s.id === id)?.name;

    const handleToolTipLotIssueTrackerClose = () => {
        setOpenToolTipLotIssueTracker(false);
    }

    const handleToolTipLotIssueTrackerOpen = () => {
        setOpenToolTipLotIssueTracker(true);
    }

    const getAssetIssueTrackerDescription = (id) => {
        let vehicleEntity = [...vehiclesData].find(x => x.id == id);
        let lotObj = issuesTracker.find(x => x.assetId === vehicleEntity.assetId && x.clientId == vehicleEntity.clientId);

        if (!lotObj)
        {
            return;
        }
        
        return lotObj.description;
    }

    const getAssetId = (id) => {
        return [...vehiclesData].find(x => x.id == id).assetId;
    }

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))({
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: 80
        },
    });

    let vehicleColumns = [
        { width: 50, name: "", key: "id", selectedByDefault: true, component: (id) => <>{getAssetIssueTrackerDescription(id) && <div>
            <NavLink exact
                className={classes.link}
                to={`/issues/tracker/asset-${getAssetId(id)}`}
            >
                <CustomWidthTooltip title={getAssetIssueTrackerDescription(id)} placement="left" open={openToolTipLotIssueTracker} onClose={handleToolTipLotIssueTrackerClose} onOpen={handleToolTipLotIssueTrackerOpen} >
                    <div>
                        <ErrorOutlineIcon title='View Lot Issue Tracker' />
                    </div>
                </CustomWidthTooltip>
            </NavLink>
        </div>}</>, hideMode: true },
        { name: "VIN", key: "descriptor", selectedByDefault: true, width: 280 },
        { name: "Asset ID", key: "assetId", selectedByDefault: true, width: 200 },
        { name: "Client", key: "client", requireAdmin: true, selectedByDefault: true },
        { name: "Current Location", key: "lotId", values: lots, selectedByDefault: true, width: 200 },
        { name: "Vehicle Type", key: "vehicleTypeId", values: vehicleTypes, selectedByDefault: true, width: 200 },
        { name: "Last In", key: "formattedDateIn", sortKey: "dateIn", selectedByDefault: true },
        { name: "Last Out", key: "formattedDateOut", sortKey: "dateOut" },
        { name: "Status", key: "vehicleStatusId", values: statuses, selectedByDefault: true },
        { name: "Registration", key: "registration", selectedByDefault: false, width: 200 },
        { name: "License Plate", key: "licensePlate", selectedByDefault: false, width: 200 },
        { name: "Year", key: "year" },
        { name: "Make", key: "make" },
        { name: "Model", key: "model" },
        { name: "Mileage", key: "mileage" },
        { name: "Domicile Lot", key: "domicileLot", values: lots, selectedByDefault: false },
    ];

    const updateAssetProperties = (data) => {
        return data.map(v => ({
            ...v,
            domicileLot: readinessByVehicleId?.[v.id]?.domicileLot,
            latestLoadId: readinessByVehicleId?.[v.id]?.latestLoadId,
            subMarketIds: subMarketsForLot?.[v.lotId],
            client: getClientName(v.customerId),
            formattedDateIn: FormatService.formatDate(readinessByVehicleId?.[v.id]?.dateIn),
            dateIn: readinessByVehicleId?.[v.id]?.dateIn,
            formattedDateOut: FormatService.formatDate(readinessByVehicleId?.[v.id]?.dateOut),
            dateOut: readinessByVehicleId?.[v.id]?.dateOut,
            readinessStatus: readinessByVehicleId?.[v.id] ?? {},
            registration: (v.hasRegistration && v.registrationMonth && v.registrationYear) ? (("0" + v.registrationMonth).slice(-2) + ' ' + v.registrationYear + (v.registrationExpired ? ' (Expired)' : '')) : '',
            licensePlate: (v.hasLicensePlate && v.licensePlate && v.licensePlateState) ? (v.licensePlate + ' ' + v.licensePlateState) : ''
        }));
    };

    vehiclesData = updateAssetProperties(vehiclesData);

    const [sortOptions, sortData] = useSortOptions();

    const columnsToAdd = {}

    vehiclesData?.map(v => {
        v.extendedDetails?.map(d => {
            v['extended_' + d.vehicleDetailExtendedTypeId] = d.value;
            if (d.vehicleDetailExtendedTypeId && !columnsToAdd['extended_' + d.vehicleDetailExtendedTypeId]) {
                columnsToAdd['extended_' + d.vehicleDetailExtendedTypeId] = { name: d.label, key: 'extended_' + d.vehicleDetailExtendedTypeId }
            }
        })
        return v;
    })

    vehicleColumns = [...vehicleColumns, ...Object.values(columnsToAdd)]

    if (!isAdmin && !isAccountManager) {
        vehicleColumns = vehicleColumns.filter(c => !c.requireAdmin);
    }

    if (isAdmin || (isClient && isUsingTransports)) {
        vehicleColumns.push({width: 100, name: "Load", key: "latestLoadId", selectedByDefault: true, component: (latestLoadId) => <>{latestLoadId && <LoadButton latestLoadID={latestLoadId} />}</> },);
    }
    if (isAdmin || (isClient && (usingReadiness))) {
        vehicleColumns.push({
            name: "Readiness", width: 350, key: "readinessStatus", selectedByDefault: true, component: (readinessStatus) => {
                return <AssetReadinessStatuses readinessStatus={readinessStatus} showActivityList={true} />
            }
        });
    }

    useEffect(() => {
        if (usingReadiness) {
            setSelectedColumns([...vehicleColumns].filter(c => c.selectedByDefault));
        }
    }, [isUsingTransports, isUsingWash])

    const [assetToEdit, setAssetToEdit] = useState(null);
    const [assetToAdd, setAssetToAdd] = useState(null);
    const [selectedColumns, setSelectedColumns] = useState([...vehicleColumns].filter(c => c.selectedByDefault));
    let [deleteAsset, { isLoading: isDeleting }] = useDeleteAssetMutation();

    const viewVehicle = (id) => {
        history.push(`/assets/detail/${id}`);
    };

    const onEdit = id => {
        let vehicle = vehiclesData.find(v => v.id === id);
        setAssetToEdit(vehicle);
    };

    const onAdd = () => {
        const clientAccounts = _.flatten(clients.map(c => c.clientAccounts));
        let asset = {};
        asset.vehicleTypeId = first(vehicleTypes)?.id;
        asset.vehicleStatusId = first(statuses)?.id;
        asset.lotId = first(lots)?.id;
        asset.customerId = first(clientAccounts)?.id;
        setAssetToAdd(asset);
    };

    const onDelete = async asset => {
        let result = await deleteAsset({ id: asset.id })

        if (result?.error) {
            NotificationProvider.error(`Asset VIN: ${asset.descriptor} cannot be deleted.`) //result.error.data.messages
        } else {
            NotificationProvider.success(`Successfully deleted Asset: ${asset.descriptor}`)
        }
    }

    const reloadAssets = () => {
        console.log('reloadAssets');
        setAssetToEdit(null);
        setAssetToAdd(null);
        refetchVehicles();
    };

    const mapExportData = v => {
        const selectedColumnsMappedData = {};
        const allDataMap = ({
            VIN: v.descriptor,
            ["Asset ID"]: v.assetId,
            Client: v.client,
            //"Submarket": v.subMarketIds?.map(s => subMarketMap[s]?.name).join(' - '),
            "Current Location": getLotName(v.lotId),
            "Domicile Lot": getLotName(v.domicileLot),
            Year: v.year,
            Make: v.make,
            Model: v.model,
            Trim: v.trim,
            Mileage: v.mileage,
            "Last In": v.formattedDateIn,
            "Last Out": v.formattedDateOut,
            Status: getStatusName(v.vehicleStatusId),
            "Registration": v.registration,
            "License Plate": v.licensePlate,
            "Asset Readiness": v.readinessStatus,
            "Condition Report": v.conditionReportUrl,
            "Vehicle Type": vehicleTypes?.find(vt => vt.id == v.vehicleTypeId)?.name
        });

        const extendedDataMap = ({});
        for (const property in v) {
            if(property.startsWith('extended_')){
                extendedDataMap[property] = v[property];
            }            
        }

        for (const field of selectedColumns) {
            selectedColumnsMappedData[field.name] = allDataMap[field.name] || extendedDataMap[field.key]
        }
        return selectedColumnsMappedData;
    };

    return (
        <div className="Vehicles">
            {
                isLoading()
                    ? <CircularProgress className={classes.spinner} />
                    : <VehicleTable
                        vehiclesData={vehiclesData}
                        columns={vehicleColumns}
                        lots={lots}
                        clients={clients}
                        statuses={statuses}
                        vehicleTypes={vehicleTypes}
                        isAdmin={isAdmin}
                        isOwner={isOwner}
                        isLoading={isLoading()}
                        assetToEdit={assetToEdit}
                        setAssetToEdit={setAssetToEdit}
                        assetToAdd={assetToAdd}
                        setAssetToAdd={setAssetToAdd}
                        updateAsset={reloadAssets}
                        addAsset={reloadAssets}
                        includeHistory={includeHistory}
                        setIncludeHistory={setIncludeHistory}
                        onAdd={onAdd}
                        onEdit={onEdit}
                        sortOptions={sortOptions}
                        sortVehicles={sortData}
                        viewVehicle={viewVehicle}
                        mapExportData={mapExportData}
                        selectedColumns={selectedColumns}
                        setSelectedColumns={setSelectedColumns}
                        onClickDelete={onDelete}
                        isDeleting={isDeleting}
                    />
            }
        </div>
    );
};

export default VehiclesPage;
