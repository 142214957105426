import {createSlice} from "@reduxjs/toolkit";

export const accessStateSlice = createSlice({
    name: 'accessState',
    initialState: {
        searchVal: '',
        filters: {
            lotId: {value: null},
            clientId: {value: null},
            transporterId: {value: null},
            serviceProviderId: {value: null},
        },
        accessToEdit: {}
    },
    reducers: {
        setSearchVal: (state, action) => {
            state.searchVal = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setAccessToEdit: (state, action) => {
            state.accessToEdit = action.payload;
        }
    },
});

export const { setSearchVal, setFilters, setAccessToEdit } = accessStateSlice.actions;
export default accessStateSlice.reducer;
