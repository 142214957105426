import { IconLocationWrapper, IconLot } from 'icons';
import { lotsMapMarkerStyle } from "./LotsMapMarkerStyle";

const LotsMapMarker = ({ color, text, textColor }) => {

    const classes = lotsMapMarkerStyle();
    return (
        <div className={classes.markerWrapper}>

            <div className={classes.iconWrapper}>
                <IconLocationWrapper fill={color} height='40' width='40' />
                <IconLot fill='white' height='24' width='24' className={classes.lotIcon} />
            </div>

            {text && <div className={`${classes.textWrapper} ${textColor ? classes.coloredTextWrapper : ""}`} >
                {text}
            </div>}

        </div>
    )
}
export default LotsMapMarker;
