import { Collapse } from "@material-ui/core";
import { useState } from "react";
import { InspectionsGroupStyles } from "./InspectionsGroupStyles";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import InspectionReport from "../ReportDialog/InspectionReport";

const InspectionsGroup = ({ inspections, name }) => {
    const classes = InspectionsGroupStyles();

    const [collapsed, setCollapsed] = useState(false);
    console.log(inspections)
    return (
        <>
            {inspections &&
                <div className={classes.itemWrapper}>
                    {name &&
                        <div className={classes.itemHeader} onClick={() => setCollapsed(!collapsed)}>
                            <h3>{name}</h3>
                            <KeyboardArrowDownIcon />
                        </div>
                    }
                    <Collapse in={collapsed} timeout="auto" unmountOnExit className={classes.collapseWrapper}>
                        {
                            inspections?.map((inspection, key) => (
                                <InspectionReport inspection={inspection} />
                            ))
                        }
                    </Collapse>
                </div>
            }
        </>
    );
};

export default InspectionsGroup;
