import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from "@material-ui/core";
import { hasPermission } from "components/Authorize/authorizeUtil";
import { permissions } from "components/Authorize/permissions/permissions";
import DateTimeField from "components/DateTimePicker/DateTimePicker";
import NotificationProvider from "components/NotificationProvider";
import RichTextEditor from "components/RichTextEditor/RichTextEditor";
import { activityStatusOrder, activityStatuses } from "features/activity/activityConsts";
import { PricingLookup } from "features/pricing/PricingLookup/PricingLookup";
import { useGetServiceProvidersQuery } from "features/serviceProvider/serviceProviderSlice";
import { orderBy } from "lodash";
import moment from "moment";
import { useState } from "react";
import CustomInput from "../../../components/CustomInput";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { getLotsWithPmfText } from "../../../features/lots/lotUtil";
import { useGetAllActivitiesQuery, useGetAllActivityItemsQuery, useUpdateActivityLogMutation } from "../../activity/activitySlice";
import { useGetLotsQuery } from "../../lots/lotSlice";
import { vehicleDetailsPageStyle } from "./VehicleDetailsPageStyle";
import { ChargeCalculation } from "features/pricing/ChargeCalculation/ChargeCalculation";
import { useGetAllInventoryByActivityTypeIdQuery } from "features/activity/ActivityTypeInventorySlice";
import { useEffect } from "react";
import ActivityInventory from "features/mechanicalWork/components/Inventory/ActivityInventory";

const EditActivityLog = ({
    onCancel,
    activityLog,
    onConfirm,
    vehicleId,
    clientId,
    vehicleTypeId
}) => {
    //let { data: activityStatuses, error: error, isLoading: isLoadingStatuses } = useGetActivityStatusesQuery();
    let { data: activities, error: activityError, isLoading: isLoadingActivity } = useGetAllActivitiesQuery();
    let { data: lots, error: getLotsError, isLoading: loadingLots } = useGetLotsQuery();
    let { data: activityItems, error: activityItemError, isLoading: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    let { data: inventoryUsed, isFetching: isLoadingInventory, refetch: refetchInventory } = useGetAllInventoryByActivityTypeIdQuery({ entityId: activityLog?.id, entityType: 1 });

    const classes = vehicleDetailsPageStyle();
    const [dateTime, setDateTime] = useState(activityLog?.date);
    const [serviceProviderId, setServiceProviderId] = useState(activityLog?.serviceProviderId);
    const [cost, setCost] = useState(activityLog?.cost);
    const [revenue, setRevenue] = useState(activityLog?.revenue);
    const [withinSla, setWithinSla] = useState(activityLog.withinSla);
    const [selectedStatus, setSelectedStatus] = useState(activityLog?.activityId);
    const [selectedLot, setSelectedLot] = useState(activityLog?.lotId);
    const [updateActivityLog, { isLoading }] = useUpdateActivityLogMutation();
    const [note, setNote] = useState();
    const [inventoryUsedState, setInventoryUsedState] = useState(inventoryUsed);
    const [useInventory, setUseInventory] = useState()

    let { data: serviceProviders, error: serviceProvidersError, isLoading: isLoadingServiceProviders } = useGetServiceProvidersQuery();

    serviceProviders = serviceProviders || {};
    activities = orderBy(activities?.filter(a => activityStatusOrder[a.id] >= activityStatusOrder[selectedStatus]).map(a => ({ ...a, order: activityStatusOrder[a.id] })), 'order') ?? [];

    const onSelectDateTime = (e) => {
        setDateTime(e)
    }

    useEffect(() => {
        if (inventoryUsed) {
            setInventoryUsedState(inventoryUsed);
        }
    }, [inventoryUsed])

    const save = async () => {
        let date = new Date(moment(dateTime).format("MMM DD, yyyy, hh:mm a") + " UTC");
        let activityDate = date.toISOString();
        let invToSend = inventoryUsedState?.filter(i => !(i.inventoryId === null && i.unitNumber === null));

        const res = await updateActivityLog({
            id: activityLog.activityLogId,
            createdDate: moment(),
            activityDate: activityDate,
            serviceProviderId: serviceProviderId,
            price: cost,
            revenue: revenue,
            status: selectedStatus,
            withinSla: withinSla,
            vehicleId: vehicleId,
            clientId: clientId,
            lotId: selectedLot,
            groupId: activityLog.groupId,
            note: note,
            inventoryUsed: invToSend
        });  //daysCompletedIn: daysCompletedIn, daysToComplete: daysToComplete
        
        if (res.error) {
            NotificationProvider.error("Failed to update the activity");
        } else {
            NotificationProvider.success("Successfully updated the activity");
            onConfirm();
        }
    };

    if (isLoading) {
        return <LoadingSpinner />
    }
  
    return (
        <Dialog open={!!activityLog} onClose={() => onCancel(false)} aria-labelledby='dialog-title'>
            <DialogTitle id={'dialog-title'} >Edit Activity {activityItems?.find(a => a.id == activityLog?.activityItemId)?.name}</DialogTitle>
            <DialogContent>
                <Paper className={classes.paper}>
                <div className={classes.input}>
                    <CustomInput
                        id="lot"
                        label="Lot"
                        value={selectedLot}
                        elementType="dropdown"
                        showEmpty={true}
                        required={false}
                        onChange={val => setSelectedLot(val)}
                        values={getLotsWithPmfText(lots)}
                    />
                </div>
                <div className={classes.input}>
                    <CustomInput
                        id="cost"
                        label="Activity Status"
                        value={selectedStatus}
                        elementType="dropdown"
                        showEmpty={true}
                        required={false}
                        onChange={val => setSelectedStatus(val)}
                        //values={activityStatuses}
                        values={hasPermission(permissions.ACTIVITIES.MANAGE_PENDING_INVOICE.key) ? activities : activities.filter(a => a.id !== activityStatuses.ItemCompleted)}
                    />
                </div>
                <div className={classes.input}>
                    <DateTimeField
                        label='Activity Date'
                        value={dateTime}
                        onChange={onSelectDateTime}
                    />
                </div>
                <div className={classes.input}>
                    <CustomInput
                        id="ddlServiceProviderId"
                        label="Vendor"
                        elementType="dropdown"
                        values={serviceProviders}
                        value={serviceProviderId}
                        classStyles={classes.dropdownStyle}
                        showEmpty={true}
                        onChange={setServiceProviderId}
                    />
                </div>
                <div className={classes.input}>
                    <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                        <Grid item xs={10}>
                            <CustomInput
                                id="cost"
                                label="Internal Cost"
                                value={cost}
                                elementType="input"
                                required={true}
                                onChange={setCost}
                            />
                        </Grid>
                        <Grid item >
                            <PricingLookup
                                onChange={setCost}
                                activityItemId={activityLog?.activityItemId}
                                lotId={selectedLot}
                                vehicleTypeId={vehicleTypeId}
                                serviceProviderId={serviceProviderId}
                                setServiceProviderId={setServiceProviderId}
                                price={cost}
                                setUseInventory={setUseInventory}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.input}>
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item xs={9}>
                            <CustomInput
                                id="cost"
                                label="Charge"
                                value={revenue ?? 1.15 * cost}
                                elementType="input"
                                required={true}
                                onChange={setRevenue}
                            />
                        </Grid>
                        <ChargeCalculation
                            onChange={setRevenue}
                            clientId={clientId}
                            activityType={activityLog?.activityTypeId}
                            lotId={selectedLot}
                            price={cost}
                            inventoryUsed={!!inventoryUsedState?.length ? inventoryUsedState : null}
                        />
                    </Grid>
                </div>

                {/* <div className={classes.input}>
                    <CustomInput
                        id="daysCompletedIn"
                        label="Days Completed In"
                        value={daysCompletedIn}
                        elementType="input"
                        onChange={setDaysCompletedIn}
                    />
                </div>
                <div className={classes.input}>
                    <CustomInput
                        id="daysToComplete"
                        label="Days To Complete"
                        value={daysToComplete}
                        elementType="input"
                        onChange={setDaysToComplete}
                    />
                </div>                 */}
                <div className={classes.input}>
                    <div>Note</div>
                    <RichTextEditor onChange={(val) => setNote(val)} content={note} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', }}>
                    <p>Within SLA?</p>
                    <Checkbox
                        title="Within SLA?"
                        checked={withinSla}
                        onChange={(e, value) => (setWithinSla(value))}
                    />
                </div>
                </Paper>
                <div>
                    {(useInventory || !!inventoryUsed?.length) &&
                        <ActivityInventory inventoryUsed={inventoryUsedState} setInventoryUsed={setInventoryUsedState} activityLog={{id:activityLog.activityLogId, lotId: activityLog?.lotId}}></ActivityInventory>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color='primary'>
                    Cancel
                </Button>
                <Button onClick={save} color='primary' variant="contained">
                    {isLoading ? 'Saving...' : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditActivityLog;
