import React, { useState } from "react";
import { useGetLotsQuery } from "../lots/lotSlice";
import { fieldTypes } from "../../services/filterService";
import { useGetAllInventoryQuery } from "./inventorySlice";
import { useGetAllUnitMeasureQuery } from "../unitMeasure/unitMeasureSlice";
import { useDispatch, useSelector } from "react-redux";
import FormatService from "services/formatService";
import { setSearchVal, setFilters } from "./inventoryFiltersSlice";
import PageComponent from "../../components/Page/PageComponent";
import DataListHeader from "../../components/DataListHeader";
import ExportButton from "components/ExportButton";
import { Button, IconButton } from "@material-ui/core";
import DataTable from "../../components/DataTable";
import { orderBy, first, groupBy } from "lodash";
import FilterService from "../../services/filterService";
import useSortOptions from "../../hooks/useSortOptions";
import BasicModal from "../../components/BasicModal";
import AddInventory from "./AddInventory";
import RestockInventory from "./RestockInventory";
import AdjustInventory from "./AdjustInventory";
import EditIcon from '@material-ui/icons/Edit';
import AdjustIcon from '@material-ui/icons/Adjust';
import RestoreIcon from '@material-ui/icons/Restore';
import { permissionProfiles } from '../../components/Authorize/permissionProfiles';
import Authorize from '../../components/Authorize/Authorize';

const InventoryPage = () => {
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [openRestockModal, setOpenRestockModal] = useState(false);
    const [openAdjustModal, setOpenAdjustModal] = useState(false);
    const [inventoryObj, setInventoryObj] = useState({});
    const [inventoryId, setInventoryId] = useState(0);

    let { data: inventories} = useGetAllInventoryQuery();
    inventories = inventories || [];
    
    let { data: lots} = useGetLotsQuery();
    lots = lots || [];

    // let { data: unitMeasures} = useGetAllUnitMeasureQuery();
    // unitMeasures = unitMeasures || [];

    const { searchVal, filters } = useSelector(state => state.inventoryFilters);

    inventories = inventories.map(a => ({
        ...a,
        formattedDate: FormatService.formatDateTime(a.createdDate),
        lotName: lots?.find(l => l.id == a.lotId)?.name
    }));

    let filterConfig = [
        //{ type: 'text', size: 2, title: 'Client', key: 'clientId', values: clients, requireAdmin: true },
        { type: fieldTypes.LOT, size: 2, title: 'Lot', key: 'lotId', values: lots },
        //{ type: fieldTypes.TEXT, size: 2, title: 'UnitMeasure', key: 'statusId', values: statusIssuesTracker }
    ];

    let inventoryColumns = [
        { name: "Name", key: "name", selectedByDefault: true , width: 50 },
        { name: "Description", key: "description", selectedByDefault: true, width: 50  },
        { name: "Lot", key: "lotId", values: lots, selectedByDefault: true, width: 50  },
        { name: "Unit", key: "unitMeasure", selectedByDefault: true, width: 50 },
        { name: "Cost", key: "cost", selectedByDefault: true, width: 50 },
        { name: "Warring Level", key: "warringLevel", selectedByDefault: true, width: 50  },
        { name: "Quantity", key: "quantity", selectedByDefault: true, width: 50  },
        { name: "Created Date", key: "formattedDate", selectedByDefault: true, width: 50  }
    ];

    const onDropFilter = (value, prop) => {
        dispatch(setFilters({ ...filters, [prop]: { value } }));
    };

    const searchInventory = value => {
        dispatch(setSearchVal(value.trim()));
    };

    const filteredInventory = FilterService.filter(filters, searchVal, inventories, filterConfig);
    
    const [sortOptions, sortData] = useSortOptions();

    const onAddNewInventory = () => {
        setInventoryObj({});
        setOpenModal(true);
    }

    const onEdit = id => {
        setInventoryObj(inventories.find(x => x.id === id));
        setOpenModal(true);
    };

    const onRestock = id => {
        setInventoryId(id);
        setOpenRestockModal(true);
    };

    const onAdjustment = id => {
        setInventoryId(id);
        setOpenAdjustModal(true);
    };

    // const rowActions = (id) => ([
    //     {
    //         icon: <EditIcon />,
    //         label: "Edit",
    //         onClick: () => onEdit(id)
    //     },
    //     {
    //         icon: <RestoreIcon />,
    //         label: "Restock",
    //         onClick: () => onRestock(id)
    //     },
    //     // {
    //     //     icon: <AdjustIcon />,
    //     //     label: "Adjust",
    //     //     onClick: () => onAdjustment(id)
    //     // }
    // ]);

    const rowActions = (id) => {
        const actions = [];
        <>
            <Authorize profile={permissionProfiles.INVENTORY.INVENTORY_CREATE_EDIT}>
                {
                    actions.push(
                        {
                            icon: <EditIcon />,
                            label: "Edit",
                            onClick: () => onEdit(id)
                        }
                    )
                }
            </Authorize>
                
            <Authorize profile={permissionProfiles.INVENTORY.INVENTORY_RESTOCK}>
                {
                    actions.push(
                        {
                            icon: <RestoreIcon />,
                            label: "Restock",
                            onClick: () => onRestock(id)
                        }
                    )
                }
            </Authorize>
            <Authorize profile={permissionProfiles.INVENTORY.INVENTORY_RESTOCK}>
                {
                    actions.push(
                        {
                            icon: <AdjustIcon />,
                            label: "Define Quantity",
                            onClick: () => onAdjustment(id)
                        }
                    )
                }
            </Authorize>
        </>

        return actions;
    }

    const handleClose = () => setOpenModal(false);
    const handleRestockClose = () => setOpenRestockModal(false);
    const handleAdjustClose = () => setOpenAdjustModal(false);

    return (
        <PageComponent header={
            <DataListHeader
                title={'Inventory'}
                data={inventories}
                dropDownsConfig={filterConfig}
                filters={filters}
                onDropFilter={onDropFilter}
                onSearch={searchInventory}
                searchVal={searchVal}
                actionButtons={
                    <>
                        <Authorize profile={permissionProfiles.INVENTORY.INVENTORY_CREATE_EDIT}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onAddNewInventory}>
                                Add Inventory
                            </Button>
                        </Authorize>

                    </>
                }
            />}>
            <DataTable
                columns={inventoryColumns}
                rows={orderBy(
                    filteredInventory,
                    sortOptions.columnToSort,
                    sortOptions.sortDirection
                )}
                rowIdentifier='id'
                onSort={sortData}
                sortDirection={sortOptions.sortDirection}
                columnToSort={sortOptions.columnToSort}
                noItemsMessage='There are no activities matching this criteria'
                maxActionCount={3}
                actions={
                    rowActions
                }
            />
            <BasicModal
                open={openModal}
                wide={true}
                header={`Inventory`}
                handleClose={handleClose}
                component={<AddInventory handleClose={handleClose} inventory={inventoryObj}/>}
            />
            <BasicModal
                open={openRestockModal}
                wide={true}
                header={`Enter the amount of items being added to your stock.`}
                handleClose={handleRestockClose}
                component={<RestockInventory handleClose={handleRestockClose} inventoryId={inventoryId}/>}
            />
            <BasicModal
                open={openAdjustModal}
                wide={true}
                header={`How many items do you have in your stock?`}
                handleClose={handleAdjustClose}
                component={<AdjustInventory handleClose={handleAdjustClose} inventoryId={inventoryId}/>}
            />
        </PageComponent>
    )
}
export default InventoryPage;