import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Grid, Paper } from "@material-ui/core";
import formStyles from "../vehicles/formStyles";
import CustomInput from "../../components/CustomInput";
import { useAdjustmentInventoryMutation } from "./inventorySlice";
const useStyles = formStyles;

const AdjustInventory = ({handleClose, inventoryId}) => {
    const classes = useStyles();
    const [adjustmentInventory] = useAdjustmentInventoryMutation();
    let [adjustInventory, setAdjustInventory] = useState({});
    const [isSaving, setIsSaving] = useState(false);

    const onChange = async (key, val) => {
        setAdjustInventory({ ...adjustInventory, [key]: val});
    };

    const onSubmit = async () => {
        setIsSaving(true);
        adjustInventory.quantity = parseInt(adjustInventory.quantity);
        adjustInventory.inventoryId = parseInt(inventoryId);
        adjustInventory.notes = "Adjusting Inventory";
        
        let result = await adjustmentInventory(adjustInventory);

        if (result && !result.error) {
            console.log("Inventory adjusted successfully");
            handleClose();
        } else {
            console.log("Something happened, try again or contact support");
        }

        handleClose();
        setIsSaving(false);
    };

    return (
        <>
        <Paper className={classes.formContainer}>
            <form >
                <div className={classes.input}>
                    <CustomInput
                        id="quantity"
                        label="Quantity"
                        value={adjustInventory?.quantity}
                        elementType="input"
                        onChange={val => onChange("quantity", val)}
                        required={true}
                    />
                </div>
                {/* <div className={classes.input}>
                    <CustomInput
                        id="notes"
                        label="Notes"
                        value={adjustInventory?.notes}
                        elementType="textarea"
                        onChange={val => onChange("notes", val)}
                    />
                </div> */}
            </form>
            <div className={classes.btnContainer}>
                <div>
                    <Button className={classes.button} variant="outlined" onClick={() => handleClose()}>Cancel</Button>
                    <Button
                        onClick={onSubmit}
                        disabled={isSaving}
                        className={classes.button}
                        variant="contained"
                    >
                        Submit
                    </Button>
                </div>
            </div>
            </Paper>
        </>
    );
};

export default AdjustInventory;