import PageComponent from "components/Page/PageComponent";
import { useParams } from "react-router-dom";
import ActivityListTable from "./ActivityListTable";


export const ActivityListPage = ({ }) => {
    const routeParams = useParams();
    return (
        <PageComponent>
            <ActivityListTable status={routeParams.status} dialog={false} />
        </PageComponent>
    )
}