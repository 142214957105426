import { useGetLoadsQuery } from 'features/loads/loadsSlice';
import { LoadsDropDownStyle } from './LoadsDropDownStyle';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import { Box, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@mui/material';


export default function LoadsDropDown({ onLoadChange, value, loadsValues }) {
    const classes = LoadsDropDownStyle();

    let { data: loads, error: loadListError, isFetching: isLoadingLoads } = useGetLoadsQuery({});
    loads = loads || [];

    const optionLabel = () => {
        let load = loadsValues ? loadsValues.find(l => l.id == value) : loads.find(l => l.id == value)
        return load?.lmId
    }

    return (
        <Autocomplete
            id="loadId"
            value={value}
            onChange={(event, val) => onLoadChange(val)}
            options={loadsValues || loads}
            autoHighlight
            getOptionLabel={(option) => option?.lmId ?? optionLabel()}//}
            renderOption={(props, option) => (
                <Box  {...props}>
                    <Grid container direction='row'>

                        <Grid item xs={6} container direction='column'>
                            <Grid item >
                                <label className={classes.loadLabel}> VIN: </label>
                                {option?.vinsForSearch}
                            </Grid>
                            <Grid item >
                                <label className={classes.loadLabel}> LoadMate ID: </label>
                                {option?.lmId}
                            </Grid>
                        </Grid>
                        <Grid item xs={6} container direction='row'>
                            <Grid item xs={6}>
                                <ArrowUpwardSharpIcon className={classes.upwardArrow} />
                                {option?.pickupLocation?.name}
                            </Grid>
                            <Grid item xs={6}>
                                <ArrowDownwardSharpIcon className={classes.downwardArrow} />
                                {option?.deliveryLocation?.name}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    fullWidth
                    value={value}
                    variant='outlined'
                    label="Select Load"
                    InputLabelProps={{ className: classes.label }}
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        notchedOutline: classes.notchedOutline,
                        className: classes.input,
                        // autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
            className={classes.input}
        />
    )
}