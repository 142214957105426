import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

export const emptyPlaceholderStyle = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'lightgrey',
        minHeight: '100px',
        fontSize: '0.9em',
        width: '100%'
    },
    icon: {
        fill: 'lightgrey'
    }
}));
