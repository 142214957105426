import { modes } from "../modes";
import { roles } from "../roles";
import { permissions } from "../permissions/permissions";

export const assetsPermissionProfiles = {
    ASSET_AUDIT: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER],
    },
    ASSET_EDIT: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER,roles.MACHANIC],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.CREATE]
    },
    ASSET_IMPORT: {
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.IMPORT]
    },
    ASSET_CREATE: {
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.CREATE]
    },
    ASSET_IMPORT_INSPECTION: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER],
    },
    ADD_ACTIVITY: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER,roles.MACHANIC]
    },
    EDIT_ACTIVITY: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER,roles.MACHANIC]
    },
    EDIT_MECHANICAL: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER,roles.MACHANIC]
    },
    VIEW_ACTIVITY_PRICING: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.ADMIN]
    },
    DELETE_ACTIVITY: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER]
    },
    VEHICLE_ACTIVITY_VIEW_AUDIT: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.ADMIN]
    },
    ASSET_EDIT_READINESS: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER],
    },
    VIEW_ACTIVITY_DAYS_COMPLETED_IN: {
        [modes.READONLY]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.ADMIN]
    }
}