import * as React from "react";

function SvgIconImage(props, width, height) {
    return (
        <svg
            width={width}
            height={height}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v7.586l-3.293-3.293a1 1 0 0 0-1.414 0L4.649 19.937A1 1 0 0 1 4 19V5zm1.001 17H5zm0 0H19a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v14a3 3 0 0 0 2.999 3M20 15.414V19a1 1 0 0 1-1 1H7.414L16 11.414l4 4zM8.5 8a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1zM6 8.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0z"
                fill="#0090FE"
            />

        </svg>
    );
}

export default SvgIconImage;
