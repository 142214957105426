const matchDatesRange = (propValue, filterValue, specialBehavior) => {
    if (specialBehavior) {
        return true;
    }
    
    let propDate = new Date(propValue)
    propDate.setHours(0, 0, 0, 0);
    filterValue.startDate.setHours(0, 0, 0, 0);
    filterValue.endDate.setHours(0, 0, 0, 0);
    
    return propDate.getTime() >= filterValue.startDate?.getTime() 
        && propDate.getTime() <= filterValue.endDate?.getTime();
    
};


const isMatchArrayFilter = (propValue, filterValue, specialBehavior) => {
    return !filterValue?.length || filterValue.includes(propValue);
}

const isMatchArrayValue = (propValue, filterValue, specialBehavior) => {
    return !filterValue || (Array.isArray(propValue) && propValue?.includes(filterValue))
}

const isMatch = (propValue, filterValue, specialBehavior) => {
    if (specialBehavior) {
        return true;
    }

    if (!filterValue || !propValue === null || typeof propValue === "undefined") {
        return false;
    }

    if (Array.isArray(filterValue)) {
        return isMatchArrayFilter(propValue, filterValue, specialBehavior);
    }

    if (typeof propValue === "number") {
        return propValue === +filterValue;
    }

    if (typeof propValue === "boolean" || typeof propValue === "object") {
        return false;
    }

    let match = propValue?.toLowerCase().includes(filterValue?.toLowerCase());
    return match;
};

export const fieldTypes = {
    'TEXT': 'text',
    'FREETEXT': 'freetext',
    'DATE': 'date',
    'DATERANGE': 'dateRange',
    'LIST': 'list',//Multiple filter
    'VALUE_LIST': 'valueList',//Multiple property value
    'LOT': 'lot',
    'ASSOCIATED_LOT':'AssociatedLot',
    'COMPONENT':'component',
};

const typeToMatchFuntion = {
    [fieldTypes.TEXT]: isMatch,
    [fieldTypes.FREETEXT]: isMatch,
    [fieldTypes.DATE]: isMatch,
    [fieldTypes.DATERANGE]: matchDatesRange,
    [fieldTypes.LIST]: isMatchArrayFilter,
    [fieldTypes.VALUE_LIST]: isMatchArrayValue,
    [fieldTypes.LOT]: isMatch,
};

const filterData = (prop, value, data, type, specialBehaviorHdl) => {
    if (!value) {
        return data;
    }

    let specialBehavior = specialBehaviorHdl && specialBehaviorHdl(prop, value);

    if (specialBehavior) {
        return data;
    }

    data = data.filter(obj => type && typeToMatchFuntion[type] ? typeToMatchFuntion[type](obj[prop], value, specialBehavior) : isMatch(obj[prop], value, specialBehavior));

    return data;
};

const FilterService = {
    fieldTypes,
    filter: (filters, searchVal, data, filterConfigTypes) => {
        const filterProps = Object.keys(filters)
            .filter(prop => filters[prop].value);

        let result = [...(data ?? [])];

        if (searchVal) {
            result = result.filter(v => {
                for (let prop of Object.keys(v)) {
                    if (isMatch(v[prop], searchVal)) {
                        return true;
                    }
                }

                return false;
            });
        }

        for (let prop of filterProps) {
            let filterConf = filterConfigTypes?.find(t => t.key === prop);
            let type = filterConf?.type;
            let specialBehaviorHdl = filterConf?.specialBehaviorHdl;
            let valDefinition = filterConf?.values?.find(v => v.id == filters[prop].value);
            let fieldName = valDefinition?.filterOn ?? prop;
            let value = valDefinition?.filterOnValue ?? filters[prop].value;
            result = filterData(fieldName, value, result, type, specialBehaviorHdl);
        }
        return result;
    }
};

export default FilterService;
