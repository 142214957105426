import { modes } from "../modes";
import { roles } from "../roles";

export const dashboardPermissionProfiles = {
    WASH_SLA: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.ACCOUNT_MANAGER]
    },
    KPI_DASHBOARD_VIEW: {
        [modes.EDIT]: [roles.OWNER]
    },
    ONE_ON_ONE: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER]
    },
    CREATE_INSPECTION: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.OWNER, roles.ADMIN]
    },
    MECHANICAL_VIEW: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.ADMIN],
        [modes.READONLY]: [roles.CLIENT, roles.MACHANIC]
    }
}