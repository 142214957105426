import { Grid } from '@material-ui/core';
import { CreatableDropdown } from 'components/CreatableDropdown/CreatableDropdown';
import CustomInput from 'components/CustomInput';
import LoadingSpinner from 'components/LoadingSpinner';
import NotificationProvider from 'components/NotificationProvider';
import { useAddVendorMutation, useGetAllVendorsQuery } from 'features/access/accessSlice';
import { setAccessToEdit } from 'features/access/accessStateSlice';
import { useGetClientsQuery } from 'features/clients/clientSlice';
import { useGetDriversQuery } from 'features/drivers/driversSlice';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const CarrierFields = ({ }) => {

    const { accessToEdit } = useSelector(state => state.accessState);
    const dispatch = useDispatch();

    const onChangeValue = (key, val) => {
        dispatch(setAccessToEdit({ ...accessToEdit, [key]: val }))
    }

    let { data: vendors, error: vendorsError, isLoading: isLoadingVendors } = useGetAllVendorsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery();
    let { data: drivers, error: driverError, isLoading: isLoadingDrivers } = useGetDriversQuery();
    const [addVendor, { isLoading: isAdding }] = useAddVendorMutation();

    const vendorsLookup = vendors?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const [scac, setScac] = useState()


    useEffect(() => {
        setScac(vendorsLookup?.[accessToEdit?.carrierId]?.scac);
        // dispatch(setAccessToEdit({ ...accessToEdit, ["scac"]: vendorsLookup?.[accessToEdit?.carrierId]?.scac }))
    }, [])


    const onAddCarrier = async (val) => {
        let result = await addVendor(val);
        if (result && !result.error) {
            NotificationProvider.success("Carrier added successfully");
        } else {
            NotificationProvider.error("Failed to save the carrier");
        }
        setScac(null)
        return result
    }

    const onSelectCarrier = (val) => {
        let carrierScac = vendors?.find(t => t.id === val)?.scac;
        setScac(carrierScac);
        dispatch(setAccessToEdit({
            ...accessToEdit,
            ["carrierId"]: val,
            ["scac"]: carrierScac,
            ["drivers"]: drivers?.filter(d => d.vendorId == val),
        }));
    }

    const onChangeScac = (val) => {
        onChangeValue("scac", val);
        setScac(val)
    }


    return (
        <>
            <LoadingSpinner loading={isAdding} />
            <Grid container direction='row' spacing={2}>
                <Grid item xs={4}>
                    <CustomInput
                        label='Client'
                        elementType='dropdown'
                        value={accessToEdit?.clientId}
                        values={clients}
                        onChange={(val) => onChangeValue("clientId", val)}
                        showEmpty
                    />
                </Grid>
                <Grid item xs={4}>
                    <CreatableDropdown
                        options={vendors}
                        value={accessToEdit?.carrierId}
                        required
                        addValue={onAddCarrier}
                        label='Carrier'
                        onChange={(val) => onSelectCarrier(val)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CustomInput
                        id="scac"
                        label='SCAC'
                        elementType='input'
                        value={scac}
                        onChange={(val) => onChangeScac(val)}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default CarrierFields;