import { makeStyles } from "@material-ui/core"

export const loadsPageStyle = makeStyles((theme) => ({
    searchBarWrapper: {
        marginBottom: '2em'
    },
    sortWrapper: {
        marginTop: '1em',
        padding: "10px 20px",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.darkText,
        '& select': {
            padding: '5px'
        }
    },
    sortSelect: {
        border: 'none',
        padding: '5px',
        '& fieldset': {
            border: 'none'
        }
    },
    actionButtonsContainer: {
        padding: "17px 1px"
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.primary.dark,
        },
        color: theme.palette.common.white,
        margin: 20,
        border: 0
    },
    icon: {
        fill: theme.palette.common.white,
    },
    title: {
        display: "flex",
        alignItems: "center",
        fontWeight: 500,
        fontSize: 16,
        paddingLeft: 8,
        marginRight: 32
    },
    subtitle: {
        fontSize: 12,
        fontStyle: "italic",
        marginBottom: 6
    },
    titleContainer: {
        display: "flex",
        flexDirection: "row"
    },
    filter: {
        minWidth: "164px"
    },
    titleContainerMobile: {
        flexDirection: "column"
    },
    filterContainerMobile: {
        justifyContent: "space-between"
    },
    searchBarMobile: {
        maxWidth: "100%",
        width: "100%",
        flexBasis: "100%"
    },
    filterMobile: {
        maxWidth: "100%",
        width: "100%",
        flexBasis: "100%"
    },
    loadsTitle: {
        display: "flex",
        alignItems: "center",
        fontWeight: 500,
        fontSize: 20,
        paddingLeft: 8,
        marginRight: 32
    },
    createLoad: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.darkText,
    },
    newLoadsTitle: {
        marginTop: '1em',
        padding: '1em 2em'
    }
}));