import { groupBy } from "lodash";
import moment from "moment";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import GraphHeader from "../GraphHeader";
import { useSelector } from "react-redux";
import { billingDashboardStyle } from "../style";

const TrendingBillingGraph = ({ data }) => {
    const classes = billingDashboardStyle();

    const { showCost, showCharge } = useSelector(state => state.billingDashboardState);

    const dataGrouped = {};
    data?.forEach(req => {
        let displayDate = moment(req.billingDate).format('MM-DD-YYYY').toString();
        if (!dataGrouped[displayDate]) {
            dataGrouped[displayDate] = { displayDate: displayDate, cost: 0, charge: 0, name: displayDate };
        }
        dataGrouped[displayDate].cost += req.totalCost;
        dataGrouped[displayDate].charge += req.totalChargeToClient;
    });

    let sortedReqs = Object.values(dataGrouped)?.sort((a, b) => (a?.date - b?.date));

    const CustomTooltip = ({ payload }) => {
        return (
            <div className={classes.graphTootltip}>
                <p>
                    <div><b>{payload?.[0]?.payload?.name} </b></div>
                    {showCost && <div><b>Cost: </b>${payload?.[0]?.payload?.cost.toFixed(2)}</div>}
                    {showCharge && <div><b>Charge: </b>${payload?.[0]?.payload?.charge.toFixed(2)}</div>}
                </p>
            </div>
        );
    };

    return (
        <div style={{ height: 350 }}>
            <GraphHeader title={'Trending Overtime'} />
            <ResponsiveContainer width="100%" height="80%">
                <AreaChart
                    data={sortedReqs}
                >
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis dataKey="displayDate" />
                    <YAxis
                        tickFormatter={amount => {
                            return `$${amount}`
                        }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    {showCost && <Area type="monotone" dataKey="cost" stackId="1" stroke="#086788" fill="#086788" />}
                    {showCharge && <Area type="monotone" dataKey="charge" stackId="2" stroke="#82ca9d" fill="#82ca9d" />}
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}

export default TrendingBillingGraph;
