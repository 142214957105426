import { List, ListItem, ListItemText, Paper } from '@material-ui/core';
import { NavLink } from "react-router-dom";
import React from 'react';
import { loadAssetsStyle } from './LoadAssetsStyle';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder/EmptyPlaceholder';
import AssetReadinessStatuses from '../../../vehicles/components/AssetReadinessStatuses/AssetReadinessStatuses';

function LoadAssets({ assets, readiness }) {
    const classes = loadAssetsStyle()

    return (
        <>
            {!(assets?.length) ?
                <EmptyPlaceholder text='no assets' className={classes.emptyPlaceHolder} /> :
                <Paper className={classes.card} elevation={1}>
                    <div className={classes.listWrapper}>
                        <List>
                            <ListItemText>Assets</ListItemText>
                            {assets?.map(asset =>

                                <ListItem >
                                    <NavLink exact
                                        className={classes.link}
                                        to={`/assets/detail/${asset.id}`}>
                                        <ListItemText primary={asset.vin} className={classes.listWrapper} />

                                    </NavLink>
                                    {readiness &&
                                        <div style={{ marginLeft: 'auto' }}><AssetReadinessStatuses readinessStatus={readiness} /></div>
                                    }
                                </ListItem>

                            )}
                        </List>
                    </div>
                </Paper>
            }
        </>
    );
}

export default LoadAssets;