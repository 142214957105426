import { Button, IconButton } from "@material-ui/core";
import moment from "moment";
import Papa from "papaparse";
import { useState } from "react";
import { useStyles } from "../features/vehicles/VehicleTable";
import CsvDownload from "./CsvDownload";

const ExportButton = ({
    fileLabel,
    data,
    mapExportData,
    vin = null,
    label = 'Export CSV',
    btnStyle = 'contained',
    icon = <img src="/icons/icon-download.svg" />
}) => {
    const classes = useStyles();
    const [csvExport, setCsvExport] = useState('');

    const getFileName = () => {
        const timestamp = moment().format("YYYYDDMMhmmss");
        const fileNameMiddleStamp = vin ? '-' + vin : '';
        return `${fileLabel}${fileNameMiddleStamp}-${timestamp}.csv`;
    };


    const exportCsv = () => {
        let csvData = data.map(mapExportData);
        const csv = Papa.unparse(csvData);
        const file = new Blob([csv], { type: 'text/csv' });
        setCsvExport(URL.createObjectURL(file));
    };

    return (
        <>
            <CsvDownload href={csvExport} fileName={getFileName()} />
            {label == '' ?
                <IconButton variant={btnStyle} color="primary" className={classes.actionButton} onClick={() => exportCsv()} >{icon} </IconButton> :
                <Button variant={btnStyle} color="primary" className={classes.actionButton} startIcon={icon} onClick={() => exportCsv()}>
                    {label}
                </Button>
            }
        </>
    );
};

export default ExportButton;
