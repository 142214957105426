import { apiTags } from 'app/store/apiTags';
import { baseApi } from '../../app/baseApi';
import { baseQuery } from "../../app/baseQuery";

const basePath = 'vehicle';
export const vehicleApi = baseApi.injectEndpoints({
    reducerPath: 'vehicleApi',
    baseQuery: baseQuery,
    tagTypes: ["Vehicles", "Vehicle"],
    endpoints: (builder) => ({
        getVehicles: builder.query({
            query: ({ includeHistory, cacheKey }) => `vehicle?includeHistory=${includeHistory}&c=${cacheKey}`,
            providesTags: [apiTags.VEHICLES]
        }),
        getVehiclesCount: builder.query({
            query: ({ includeHistory, cacheKey }) => `${basePath}/count?includeHistory=${includeHistory}&c=${cacheKey}`,
            providesTags: [apiTags.VEHICLES]
        }),
        getVehicleTypes: builder.query({
            query: (onlyShowInApp) => `${basePath}/type?onlyShowInApp=${onlyShowInApp ?? false}`,
            providesTags: [apiTags.VEHICLES_TYPES]
        }),
        getClientVehicleTypes: builder.query({
            query: (onlyShowInApp) => `${basePath}/typeForClient?onlyShowInApp=${onlyShowInApp ?? false}`,
            providesTags: [apiTags.VEHICLES_TYPES]
        }),
        getVehicleDetails: builder.query({
            query: (id) => `${basePath}/${id}`,
            providesTags: [apiTags.VEHICLE]
        }),
        getVehicleDetailsExtended: builder.query({
            query: (id) => `${basePath}/vehicledetailextended/${id}`,
            providesTags: [apiTags.VEHICLE]
        }),
        getActivityLog: builder.query({
            query: (id) => `${basePath}/activity/${id}`,
            providesTags: [apiTags.VEHICLE]
        }),
        getVehicleStatus: builder.query({
            query: () => `${basePath}/status`,
        }),
        getImageTypes: builder.query({
            query: () => `imageType`,
        }),
        editAsset: builder.mutation({
            query: updatedAsset => ({
                url: `${basePath}/edit`,
                method: "POST",
                body: updatedAsset
            }),
            invalidatesTags: [apiTags.VEHICLES, apiTags.VEHICLE, apiTags.READINESS]
        }),
        addAsset: builder.mutation({
            query: updatedAsset => ({
                url: `${basePath}/add`,
                method: "POST",
                body: updatedAsset
            }),
            invalidatesTags: [apiTags.VEHICLES, apiTags.VEHICLE]
        }),
        uploadImage: builder.mutation({
            query: image => ({
                url: `${basePath}/image`,
                method: "POST",
                body: image
            }),
            invalidatesTags: [apiTags.VEHICLE]
        }),
        deleteImage: builder.mutation({
            query: image => ({
                url: `${basePath}/deleteImage`,
                method: "POST",
                body: image
            }),
            invalidatesTags: [apiTags.VEHICLE]
        }),
        deleteDocument: builder.mutation({
            query: document => ({
                url: `${basePath}/deleteDocument`,
                method: "POST",
                body: document
            }),
            invalidatesTags: [apiTags.VEHICLE]
        }),
        updateImageType: builder.mutation({
            query: (image) => ({
                url: `${basePath}/updateImageType`,
                method: "POST",
                body: image
            }),
            invalidatesTags: [apiTags.VEHICLE]
        }),
        updateImage: builder.mutation({
            query: (image) => ({
                url: `${basePath}/editDocument`,
                method: "POST",
                body: image
            }),
            invalidatesTags: [apiTags.VEHICLE]
        }),
        uploadDocument: builder.mutation({
            query: document => ({
                url: `${basePath}/uploaddocument`,
                method: "POST",
                body: document
            }),
            invalidatesTags: [apiTags.VEHICLE]
        }),
        updateVehicleDetail: builder.mutation({
            query: detail => ({
                url: `${basePath}/detail`,
                method: "PUT",
                body: detail
            })
        }),
        importActivityInspection: builder.mutation({
            query: form => ({
                url: `${basePath}/importsicompletion`,
                method: "POST",
                body: form
            }),
        }),
        deleteAsset: builder.mutation({
            query: id => ({
                url: `${basePath}/deleteAsset`,
                method: "POST",
                body: id
            }),
            invalidatesTags: [apiTags.VEHICLES]
        }),
        mergeAssets: builder.mutation({
            query: records => ({
                url: `${basePath}/merge`,
                method: "POST",
                body: {
                    records: records
                }
            }),
            invalidatesTags: [apiTags.VEHICLE]
        }),
        bulkUpdate: builder.mutation({
            query: body => ({
                url: `${basePath}/bulk`,
                method: "POST",
                body
            }),
            invalidatesTags: [apiTags.VEHICLE]
        }),
        deleteVehicleDetail: builder.mutation({
            query: id => ({
                url: `${basePath}/vehicledetail/delete`,
                method: "POST",
                body: { vehicleDetailId: id }
            }),
            invalidatesTags: [apiTags.VEHICLE]
        }),
        getFuelTypes: builder.query({
            query: () => `${basePath}/fuelType`,
        }),
        getExtendedFieldTypes: builder.query({
            query: () => `${basePath}/extendedFields`,
        }),
        findMatch: builder.query({
            query: (params) => `${basePath}/match?${Object.keys(params).filter(key => params[key]).map(key => `${key}=${params[key]}`).join('&')}`,
        }),
        getDecodedVin: builder.query({
            query: (vin) => `${basePath}/decode/${vin}`,
        }),
        importAssets: builder.mutation({
            query: assets => ({
                url: `${basePath}/import`,
                method: "POST",
                body: assets
            }),
        }),
        getImportAssetsTemplate: builder.mutation({
            query: body => ({
                url: `vehicle/downloadtemplate`,
                method: "POST",
                body: body,
                responseHandler: async (response) => {
                    const filename = 'Import assets template.csv';
                    const url = window.URL.createObjectURL(await response.blob());
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    link.click();
                },
                cache: "no-cache"
            })
        }),
        getVehicleLocationInfo: builder.query({
            query: (params) => {
                const queryString = Object.keys(params).map(param => params[param] ? `${param}=${params[param]}` : undefined).filter(k => k).join('&');
                return `${basePath}/location?${queryString}`
            },
        }),
    }),
});

export const {
    useGetVehiclesQuery, useGetVehiclesCountQuery, useGetVehicleTypesQuery, useGetVehicleDetailsQuery, useGetVehicleStatusQuery, useEditAssetMutation,
    useAddAssetMutation, useGetActivityLogQuery, useUploadImageMutation, useUploadDocumentMutation, useDeleteImageMutation, useDeleteDocumentMutation, useGetImageTypesQuery, useUpdateImageTypeMutation,
    useUpdateVehicleDetailMutation, useImportActivityInspectionMutation, useDeleteAssetMutation, useDeleteVehicleDetailMutation,
    useBulkUpdateMutation, useMergeAssetsMutation,
    useGetFuelTypesQuery, useGetExtendedFieldTypesQuery, useGetClientVehicleTypesQuery, useGetVehicleDetailsExtendedQuery,
    useFindMatchQuery, useUpdateImageMutation, useGetDecodedVinQuery, useImportAssetsMutation, useGetImportAssetsTemplateMutation,
    useGetVehicleLocationInfoQuery
} = vehicleApi;
