import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Grid, Paper } from "@material-ui/core";
import formStyles from "../../features/vehicles/formStyles";
import CustomInput from "../../components/CustomInput";
import DropdownFilter from "../../components/DropdownFilter";
import { useGetLotsQuery } from "../../features/lots/lotSlice";
import { useGetAllUnitMeasureQuery } from "../unitMeasure/unitMeasureSlice";
import AddPhoto from "../../components/AddPhoto";
import FileService from '../../services/fileService';
import { useAddInventoryMutation, useUpdateInventoryMutation } from "./inventorySlice";
const useStyles = formStyles;

const AddInventory = ({handleClose, inventory}) => {
    const classes = useStyles();
    const [newAttachments, setNewAttachments] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [addInventory] = useAddInventoryMutation();
    const [existingImages, setExistingImages] = useState([]);
    const [updateInventory] = useUpdateInventoryMutation();

    let { data: lots} = useGetLotsQuery();
    lots = lots || [];

    // let { data: unitMeasures} = useGetAllUnitMeasureQuery();
    // unitMeasures = unitMeasures || [];

    let [inventoryToAdd, setAddInventory] = useState(inventory);

    useEffect(() => {
        if (inventoryToAdd.id && inventoryToAdd.url) {
            setExistingImages([{...inventoryToAdd}]);
        }
    }, []);

    const onChange = async (key, val) => {
        setAddInventory({ ...inventoryToAdd, [key]: val});
    };

    const addAttachment = async image => {
        setNewAttachments([...newAttachments, image]);

        let base64Data = await FileService.getBase64Data(image.file);
        let imageConverted = { fileName: image.file.name, fileData: base64Data,  isDeleted: 0}
        let inventoryToChange = {...inventoryToAdd};
        inventoryToChange.attachment = imageConverted;
        setAddInventory(inventoryToChange);
    };

    const handleDelete = async (img) => {
        setNewAttachments([]);
        setExistingImages([]);

        let inventoryToChange = {...inventoryToAdd};
        inventoryToChange.attachment = null;
        inventoryToChange.url = null;
        setAddInventory(inventoryToChange);
    };

    const onSubmit = async () => {
        setIsSaving(true);
        inventoryToAdd.lotId = parseInt(inventoryToAdd.lotId);
        inventoryToAdd.cost = parseFloat(inventoryToAdd.cost);
        inventoryToAdd.quantity = parseFloat(inventoryToAdd.quantity);
        inventoryToAdd.warringLevel = parseFloat(inventoryToAdd.warringLevel);

        let result;
        if (inventoryToAdd.id) {
            result = await updateInventory(inventoryToAdd);
        }
        else {
            result = await addInventory(inventoryToAdd);
        }

        if (result && !result.error) {
            console.log("Inventory added successfully");
            handleClose();
        } else {
            console.log("Something happened, try again or contact support");
        }

        handleClose();
        setIsSaving(false);
    };

    const isValid = () => {
        return !!inventoryToAdd.name &&
            !!inventoryToAdd.unitMeasure &&
            !!inventoryToAdd.cost &&
            !!inventoryToAdd.warringLevel &&
            !!inventoryToAdd.quantity &&
            !!inventoryToAdd.lotId;
    };

    return (
        <>
        <Paper className={classes.formContainer}>
            <form >
                <div className={classes.input}>
                    <CustomInput
                        id="name"
                        label="Name"
                        value={inventoryToAdd?.name}
                        elementType="input"
                        onChange={val => onChange("name", val)}
                        required={true}
                    />
                </div>
                <div className={classes.input}>
                    <CustomInput
                        id="description"
                        label="Description"
                        value={inventoryToAdd?.description}
                        elementType="input"
                        onChange={val => onChange("description", val)}
                    />
                </div>
                <div className={classes.input}>
                    <DropdownFilter
                        title='Lot'
                        identifier='lotId'
                        showEmpty
                        values={lots || []}
                        onSelect={(event) => onChange("lotId", event)}
                        value={inventoryToAdd?.lotId}
                        required={true}
                    />
                </div>
                <div className={classes.input}>
                    <CustomInput
                        id="unitMeasure"
                        label="Unit"
                        value={inventoryToAdd?.unitMeasure}
                        elementType="input"
                        onChange={val => onChange("unitMeasure", val)}
                        required={true}
                    />
                </div>
                <div className={classes.input}>
                    <CustomInput
                        id="cost"
                        label="Cost"
                        value={inventoryToAdd?.cost}
                        elementType="input"
                        onChange={val => onChange("cost", val)}
                        required={true}
                    />
                </div>
                <div className={classes.input}>
                    <CustomInput
                        id="warringLevel"
                        label="Warring level"
                        value={inventoryToAdd?.warringLevel}
                        elementType="input"
                        onChange={val => onChange("warringLevel", val)}
                        required={true}
                    />
                </div>
                <div className={classes.input}>
                    <CustomInput
                        id="quantity"
                        label="Quantity"
                        value={inventoryToAdd?.quantity}
                        elementType="input"
                        onChange={val => onChange("quantity", val)}
                        required={true}
                    />
                </div>
                <AddPhoto
                    limit={2}
                    newImages={newAttachments || []}
                    existingImages={existingImages || []}
                    addImage={addAttachment}
                    label='Add Photo'
                    onDelete={handleDelete}
                />
            </form>
            <div className={classes.btnContainer}>
                <div>
                    <Button className={classes.button} variant="outlined" onClick={() => handleClose()}>Cancel</Button>
                    <Button
                        onClick={onSubmit}
                        disabled={isSaving || !isValid()}
                        className={classes.button}
                        variant="contained"
                    >
                        Submit
                    </Button>
                </div>
            </div>
            </Paper>
        </>
    );
};

export default AddInventory;