import { baseApi } from '../../app/baseApi';
import { createSlice } from "@reduxjs/toolkit";

export const taskApi = baseApi.injectEndpoints({
    reducerPath: 'loadApi',
    endpoints: (builder) => ({
        getTasks: builder.query({
            query: (body) => ({
                url: 'task'
            }),
            providesTags: ["Tasks"],
            transformResponse: (response) => {
                return response;
            }
        }),
    })

});


export const { useGetTasksQuery } = taskApi;

export const taskStateSlice = createSlice({
    name: 'taskStateSlice',
    initialState: {
        searchVal: '',
        taskPanelOpen: false,
        showDomiciledOnly: false,
        filters: {
            'clientId': { value: '' },
            'lotId': { value: [] }
        }
    },
    reducers: {
        setSearchVal: (state, action) => {
            state.searchVal = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setFilter: (state, action) => {
            state.filters = { ...state.filters, [action.payload.key]: { value: action.payload.value } }
        },
        setTaskPanelOpen: (state, action) => {
            state.taskPanelOpen = action.payload;
        },
        setShowDomiciledOnly: (state, action) => {
            state.showDomiciledOnly = action.payload;
        }
    },
});

export const { setSearchVal, setTaskPanelOpen, setShowDomiciledOnly, setFilters, setFilter } = taskStateSlice.actions;
