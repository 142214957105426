import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import CustomInput from "components/CustomInput";
import DataTable from "components/DataTable";
import NotificationProvider from "components/NotificationProvider";
import { useGetDefaultStatusByClientIdQuery } from 'features/clients/clientConfigSlice';
import { selectIsClient } from "features/user/userSlice";
import { useGetVehicleStatusQuery } from 'features/vehicles/vehicleSlice';
import useSortOptions from "hooks/useSortOptions";
import { orderBy } from "lodash";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import configStyles from "../configStyles";
import { useGetCheckInForClientQuery, useUpdateCheckInMutation } from "./CheckInConfigSlice";
import CheckInConfigStyle from "./CheckInConfigStyle";

const useStyles = CheckInConfigStyle;
const useConfigStyles = configStyles;

const CheckInGeneralData = ({ open, clientConfig, handleClose, checkinSla, setCheckinSla, defaultStatusId, setDefaultStatusId }) => {
    const configClasses = useConfigStyles();
    const classes = useStyles();

    let { data: statuses, error: statusError, isLoading: isLoadingStatuses } = useGetVehicleStatusQuery();
    let { data: statusId, error: statusIdError, isLoading: isLoadingStatusId, refetch } = useGetDefaultStatusByClientIdQuery({ clientId: clientConfig?.id });
    let { data: clientsCheckIn, error: clientsCheckInError, isLoading: isLoadingClientsCheckIn } = useGetCheckInForClientQuery(clientConfig?.id);
    clientsCheckIn = clientsCheckIn?.data || [];
    statuses = statuses || [];

    const [sortOptions, sortData] = useSortOptions();
    let isClient = useSelector(selectIsClient);
    const headerColors = ["#d4ecff", "#d4ffea", "#f6d4ff", "#d4dbff", "#ffd4d4", "#d4faff", "#efffd4", "#ffd4ee", "#d4ddff"];

    useEffect(() => {
        if (statusId != undefined)
            setDefaultStatusId(statusId);
    }, [statusId, isLoadingStatusId])

    useEffect(() => {
        if (clientsCheckIn?.length) {
            const clientsCheckInMapped = clientsCheckIn?.map(row => ({
                ...row,
                rowObject: row
            }))
            setCheckinSla(clientsCheckInMapped);
        }
    }, [clientsCheckIn, open])

    const onConfigChange = (row, key, val) => {
        setCheckinSla(checkinSla?.map(r => {
            if (r === row) {
                return {
                    ...r,
                    [key]: val,
                    changed: true
                };
            }
            return r;
        }));
    }

    const onChangeDefaultStatus = (p_statusId) => {
        setDefaultStatusId(p_statusId);
    }

    const HeaderComponent = (sectionTypeLabel, sectionTypeId) => {
        return <div
            style={{ backgroundColor: headerColors[sectionTypeId - 1] }}
            className={classes.header}
        >
            {sectionTypeLabel}
        </div>
    }

    const TextComponent = (rowObject, value) => {
        const row = checkinSla.find(row => row.rowObject == rowObject);
        return <div className={`${classes.text} ${!row['isActive'] ? classes.notActiveText : ''}`}>{value || '-'}</div>
    }

    const SwitchComponent = (label, key, rowObject) => {
        const row = checkinSla.find(row => row.rowObject == rowObject);
        return <div className={`${!row['isActive'] ? classes.notActive : ''}`} >
            <FormControlLabel
                control={
                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.CHECKIN_SLA_ACTIONS}>
                        <Switch
                            checked={row[key]}
                            onChange={(e) => onConfigChange(row, key, e.target.checked)}
                            color="primary"
                        />
                    </Authorize>
                }
                label={<div className={classes.label}>{label}</div>}
                labelPlacement="start"
            />
        </div>
    }

    const CheckboxComponent = (label, key, rowObject) => {
        const row = checkinSla.find(row => row.rowObject == rowObject);
        return <div className={`${classes.checkbox} ${!row[key] ? classes.notActiveDark : ''}`} >
            <FormControlLabel
                control={
                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.CHECKIN_SLA_ACTIONS}>
                        <Checkbox
                            checked={row[key]}
                            onChange={(e, value) => onConfigChange(row, key, value)}
                        />
                    </Authorize>
                }
                label={<div className={classes.label}>{label}</div>}
                labelPlacement="start" />
        </div >
    }

    let checkInColumns = [
        { name: "Section", key: "rowObject", component: (rowObject) => HeaderComponent(rowObject?.sectionTypeLabel, rowObject?.sectionTypeId), width: 150 },
        { name: "Data Point", key: "rowObject", component: (rowObject) => TextComponent(rowObject, rowObject?.dataPointTypeLabel), width: 400 },
        { name: "Value", key: "rowObject", requireAdmin: true, component: (rowObject) => TextComponent(rowObject, rowObject?.value), width: 400 },
        { name: "Damage", key: "rowObject", requireAdmin: true, component: (rowObject) => SwitchComponent("Damage", "needDamage", rowObject), width: 150 },
        { name: "Photo", key: "rowObject", component: (rowObject) => SwitchComponent("Photo", "needPhoto", rowObject), width: 150 },
        { name: "Notes", key: "rowObject", component: (rowObject) => SwitchComponent("Notes", "needNote", rowObject), width: 150 },
        { name: "Active", key: "rowObject", component: (rowObject) => CheckboxComponent("Active", "isActive", rowObject), width: 150 }
    ];

    if (isClient) {
        checkInColumns = checkInColumns.filter(c => !c.requireAdmin);
    }

    let isLoading = isLoadingClientsCheckIn || isLoadingStatuses || isLoadingStatusId;

    return (
        <>
            {isLoading ? <CircularProgress className={classes.spinner} />
                :<Grid> 
                        <div className={classes.dropdownStatuses}>
                            <Authorize profile={permissionProfiles.CLIENT_CONFIG.CHECKIN_SLA_ACTIONS}>
                                <CustomInput
                                    id="status"
                                    label="Default check-in status"
                                    value={defaultStatusId}
                                    elementType="dropdown"
                                    onChange={val => onChangeDefaultStatus(+val)}
                                    values={statuses}
                                    showEmpty={true}
                                />
                            </Authorize>
                        </div>
                        <div className={classes.table} >
                            <DataTable
                                columns={checkInColumns}
                                rows={orderBy(
                                    checkinSla,
                                    sortOptions.columnToSort,
                                    sortOptions.sortDirection
                                )}
                                rowIdentifier='id'
                                onSort={sortData}
                                sortDirection={sortOptions.sortDirection}
                                columnToSort={sortOptions.columnToSort}
                                noItemsMessage='There is no check-in SLA to display'
                                headerStyle={{
                                    color: 'white',
                                    backgroundColor: '#54b5ff',
                                    padding: '1rem 0 1rem 1rem',
                                }}
                            />
                        </div>
                </Grid>
            }
        </>
    )
}
export default CheckInGeneralData;