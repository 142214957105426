import { Grid } from "@material-ui/core";
import ExportButton from "components/ExportButton";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { useGetReadinessQuery } from "features/vehicles/readiness/readinessSlice";
import { IconDownload } from 'icons';
import { groupBy } from "lodash";
import { useSelector } from "react-redux";
import { totalAssetsStyle } from "../../DispatchDashboard/TotalAssets/style";

const { useGetVehiclesQuery } = require("features/vehicles/vehicleSlice");

const TotalAssetsDomicile = ({ outOfPmfResponsibilityId }) => {
    const classes = totalAssetsStyle();

    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: vehiclesReadiness, error: vehiclesReadinessError, isLoading: isLoadingVehiclesReadiness } = useGetReadinessQuery({});
    const pmfLots = lots?.filter(l => l.pmfLot)?.map(l => l.id);

    const { filters } = useSelector(state => state.dashboardState);

    let filteredData = vehiclesReadiness?.filter(v =>
        (!filters.clientId || filters.clientId == v.clientId)
    )

    filteredData = filteredData?.filter(d => d.vehicleStatus != outOfPmfResponsibilityId);

    filteredData = filteredData?.filter(a => a.domicileLot && pmfLots?.includes(a.domicileLot));

    const groupedToClient = groupBy(filteredData, 'clientId');
    const dataForExport = Object.keys(groupedToClient).map(key => ({ clientId: key, count: groupedToClient[key]?.length }))

    const mapExportData = row => {
        return ({
            client: clients?.find(c => c.id == row.clientId)?.name,
            count: row.count
        })
    };

    return (
        <>
            <Grid className={classes.wrapper} container direction="column">
                <div className={classes.exportBtn}>
                    <ExportButton btnStyle='text' label="" icon={<IconDownload />} fileLabel="Export" data={dataForExport} mapExportData={mapExportData} />
                </div>
                <Grid item className={`${classes.count} ${classes.countBlue}`}>{filteredData?.length || 0}</Grid>
                <Grid item><h3 className={classes.text}>{'Assets Domiciled on PMF Lots'}</h3></Grid>
            </Grid>
        </>
    )
}

export default TotalAssetsDomicile;