import { Grid } from "@material-ui/core";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import ExportButton from "components/ExportButton";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { useGetReadinessQuery } from "features/vehicles/readiness/readinessSlice";
import { IconDownload } from 'icons';
import { groupBy } from "lodash";
import { useSelector } from "react-redux";
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { theme } from "styles/theme";
import { AssetListDialog } from "../AllAssetCountOnLot/AssetListDialog";
import { dispatchDashboardStyle } from "../style";

const DomiciledCount = ({ outOfPmfResponsibilityId }) => {
    const classes = dispatchDashboardStyle()

    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: vehiclesReadiness, error: vehiclesReadinessError, isLoading: isLoadingVehiclesReadiness } = useGetReadinessQuery({});
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);

    const { filters } = useSelector(state => state.dashboardState);

    let filteredData = vehiclesReadiness?.filter(v =>
        (!filters.clientId || filters.clientId == v.clientId)
    )

    filteredData = filteredData?.filter(d => d.vehicleStatus != outOfPmfResponsibilityId);

    const pmfLots = lots?.filter(l => l.pmfLot)?.map(l => l.id);
    const onlyPmfLotData = filteredData?.filter(a => a.domicileLot && pmfLots?.includes(a.domicileLot));
    const dataGrouped = groupBy(onlyPmfLotData, 'domicileLot');

    const finalData = Object.keys(dataGrouped).map(key => ({ lotId: key, count: dataGrouped[key]?.length }));

    const mapExportData = row => {
        return ({
            'VIN': row.descriptor,
            'Asset Id': row.assetId,
            'Client': clients?.find(c => c.id == row.clientId)?.name,
            'Lot': lots?.find(c => c.id == row.lotId)?.name,
            'Domicile Lot': lots?.find(c => c.id == row.domicileLot)?.name,
        })
    };

    return (
        <div>

            <Grid container justifyContent="space-between" >
                <h4 className={classes.graphTitle}>Assets Domiciled on PMF Lots</h4>
                <div>
                    <AssetListDialog data={onlyPmfLotData} />
                    <ExportButton btnStyle='text' label="" icon={<IconDownload />} fileLabel="Export" data={onlyPmfLotData} mapExportData={mapExportData} />
                </div>
            </Grid>
            {!!finalData?.length ?
                <div style={{ width: "100%", height: finalData?.length * 40 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            layout="vertical"
                            data={finalData}
                            margin={{ top: 30, right: 30, left: 100, bottom: 5 }}
                        >
                            <XAxis
                                type="number"
                                dataKey="count"
                                axisLine={false}
                                tickLine={false}
                            />

                            <YAxis
                                type="category"
                                axisLine={false}
                                dataKey="lotId"
                                tickLine={false}
                                tick={{ color: theme.palette.primary.main, fontSize: '1em', fontWeight: '500' }}
                                tickFormatter={lotId => {
                                    return lots?.find(l => l.id == lotId)?.name;
                                }}
                            />

                            <Bar
                                dataKey="count"
                                fill={'#efc761'}
                            >
                                <LabelList dataKey="count" position="outside" style={{ fill: theme.palette.primary.contrastText }} />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                :
                <EmptyPlaceholder text='No Washes' />}

        </div>)
}

export default DomiciledCount;