import { createSlice } from '@reduxjs/toolkit';
import { baseApi } from 'app/baseApi';
import { baseQuery } from 'app/baseQuery';
import { apiTags } from 'app/store/apiTags';

const apiPrefix = 'inspection';
export const inspectionApi = baseApi.injectEndpoints({
    reducerPath: 'inspectionApi',
    baseQuery: baseQuery,
    tagTypes: [apiTags.INSPECTION],
    endpoints: (builder) => ({
        GetAllInspections: builder.query({
            query: () => ({
                url: `${apiPrefix}`,
            }),
            providesTags: [apiTags.INSPECTION],
        }),
        GetInspectionsById: builder.query({
            query: (id) => ({
                url: `${apiPrefix}/${id}`,
            }),
        }),
        GetInspectionsByLot: builder.query({
            query: (lotId) => ({
                url: `${apiPrefix}/byLot/${lotId}`,
            }),
            providesTags: [apiTags.INSPECTION],
        }), 
        GetInspectionsByTimeFrame: builder.query({
            query: (params) => {
                const queryString = Object.keys(params).map(param => params[param] ? `${param}=${params[param]}` : undefined).filter(k => k).join('&');
                return `${apiPrefix}/forTimeFrame?${queryString}`
            },
            providesTags: [apiTags.INSPECTION],
        }),
        addLotInspection: builder.mutation({
            query: inspection => ({
                url: `${apiPrefix}`,
                method: "POST",
                body: inspection
            }),
            invalidatesTags: [apiTags.INSPECTION]
        }),
        updateLotInspection: builder.mutation({
            query: inspection => ({
                url: `${apiPrefix}`,
                method: "PUT",
                body: inspection
            }),
            invalidatesTags: [apiTags.INSPECTION]
        }),
        GetInspectionDocumentsByLotId: builder.query({
            query: () => ({
                url: `${apiPrefix}/documents`,
            }),
            providesTags: [apiTags.INSPECTION],
        }),
        GetAllInspectionTask: builder.query({
            query: () => ({
                url: `${apiPrefix}/tasks`
            }),
            providesTags: [apiTags.INSPECTION],
        })
    }),
});

export const {
    useGetAllInspectionsQuery,
    useGetInspectionsByIdQuery,
    useGetInspectionsByLotQuery,
    useGetInspectionsByTimeFrameQuery,
    useAddLotInspectionMutation,
    useUpdateLotInspectionMutation,
    useGetInspectionDocumentsByLotIdQuery,
    useGetAllInspectionTaskQuery
} = inspectionApi;


export const inspectionSlice = createSlice({
    name: 'inspectionSlice',
    initialState: {
        filters: {
            lotId: [],
            needsAttentionFilter: { value: null },
            regionalManager: { value: '' }
        }
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
    },
});

export const { setFilters } = inspectionSlice.actions;

