import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Paper } from "@material-ui/core";
import { Image, List } from "@material-ui/icons";
import { getErrorsFromResponse } from "app/errorHandling";
import { diff } from 'deep-object-diff';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import AddPhoto from "../../components/AddPhoto";
import CustomInput from "../../components/CustomInput";
import NotificationProvider from '../../components/NotificationProvider';
import { getLotsWithPmfText } from "../../features/lots/lotUtil";
import FileService from '../../services/fileService';
import FormatService from '../../services/formatService';
import VehicleService from '../../services/vehicleService';
import { useGetClientsQuery } from "../clients/clientSlice";
import { useGetLotsQuery } from "../lots/lotSlice";
import { useGetVendorsQuery } from '../vendors/vendorSlice';
import VehicleMatches from "./components/VehicleMatches/VehicleMatches";
import VinMatching from "./components/VinMatching/VinMatching";
import formStyles from "./formStyles";
import {
    useAddAssetMutation,
    useEditAssetMutation,
    useGetDecodedVinQuery,
    useGetImageTypesQuery,
    useGetVehicleDetailsExtendedQuery,
    useGetVehicleDetailsQuery,
    useGetVehicleStatusQuery,
    useGetVehicleTypesQuery, useUpdateImageTypeMutation, useUploadImageMutation
} from "./vehicleSlice";
import VinDecode from "./VinDecode/VinDecodePopup";
import { useGetDriversQuery } from "features/drivers/driversSlice";

const useStyles = formStyles;

const EditAssetPopup = ({ handleClose, asset, updateAsset, openPhotos }) => {

    let [editedAsset, setEditedAsset] = useState(asset);

    let {
        data: vehicleDetailsExtended,
        error: VehicleDetailExtendedError,
        isLoading: isLoadingVehicleDetailExtended,
    } = useGetVehicleDetailsExtendedQuery(asset.id);

    let { data: vehicleStatuses, error: vehicleStatusError, isLoading: isLoadingVehicleStatus, isSuccess: isVehicleStatusSuccess } = useGetVehicleStatusQuery();
    let { data: vendors, error: vendorsError, isLoading: isLoadingVendors } = useGetVendorsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let { data: lots, error: lotError, isLoading: isLoadingLots, isSuccess: isLotsSuccess } = useGetLotsQuery();
    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes, isSuccess: isVehicleTypesSuccess } = useGetVehicleTypesQuery();
    let { data: vehicleDetails, error: vehicleDetailsError, isLoading: isLoadingVehicleDetails, isSuccess: isVehicleDetailsSuccess, refetch: refetchDetails } = useGetVehicleDetailsQuery(asset.id, { skip: !asset.id });
    let { data: imageTypes, error: imageTypesError, isLoading: isLoadingImageTypes } = useGetImageTypesQuery();
    let { data: drivers, error: driverError, isLoading: isLoadingDrivers } = useGetDriversQuery();

    drivers = drivers?.map(d => ({
        ...d,
        name: `${d?.firstName} ${d?.lastName}`
    }))

    drivers = drivers?.filter(d => d.vendorId == editedAsset.driverCarrierId);

    let [editAsset] = useEditAssetMutation();
    let [addAsset] = useAddAssetMutation();
    let [uploadImage] = useUploadImageMutation();
    let [updateImageType, { isLoadingUpdateImageType, isError, isSuccess }] = useUpdateImageTypeMutation();

    vehicleTypes = vehicleTypes || [];
    vehicleStatuses = vehicleStatuses || [];
    vendors = vendors || [];
    clients = clients || [];
    vehicleDetails = vehicleDetails || {};

    const classes = useStyles();
    let [isSaving, setIsSaving] = useState(false);
    let [showPhotos, setShowPhotos] = useState(false);
    let [hasVehicleDetailExtendedChanged, setHasVehicleDetailExtendedChanged] = useState(false);
    const [error, setError] = useState(null);
    const [mandatoryFields, setMandatoryFields] = useState({});
    let [existingImages, setExistingImages] = useState();
    let [newVehicleDetailsExtended, setNewVehicleDetailsExtended] = useState(vehicleDetailsExtended);
    const [decodedData, setDecodedData] = useState({});
    const [openDecoded, setOpenDecoded] = useState(false);
    const [colorDecoded, setColorDecoded] = useState(false);
    const [colorDecodedType, setColorDecodedType] = useState(false);
    const [newImages, setNewImages] = useState([]);

    useEffect(() => {
        if (!existingImages) {
            setExistingImages(vehicleDetails?.images);
        }
    }, [vehicleDetails])


    useEffect(() => {
        if (!existingImages) {
            setExistingImages(vehicleDetails?.images);
        }
    }, [vehicleDetails])

    useEffect(() => {
        if (openPhotos) {
            setShowPhotos(true);
        }
    }, [openPhotos])

    useEffect(() => {
        setNewVehicleDetailsExtended(vehicleDetailsExtended)
    }, [vehicleDetailsExtended])

    const clientAccounts = _.flatten(clients.map(c => c.clientAccounts));
    const clientAccount = clientAccounts?.find(c => c.id === editedAsset.customerId);

    const isLoading = () => isLoadingVehicleStatus || isLoadingClients || isLoadingVehicleTypes || isLoadingLots || isLoadingVehicleDetails || isLoadingImageTypes || isLoadingVendors;

    const isVehicleDetailLoading = typeof newVehicleDetailsExtended !== 'undefined' && newVehicleDetailsExtended.length > 0;

    const uploadImages = async (vehicleId) => {
        let result;
        for (let i = 0; i < newImages.length; i++) {
            let base64Data = await FileService.getBase64Data(newImages[i].file);
            let image = { vehicleId: vehicleId, fileName: newImages[i].file.name, imageData: base64Data, imageTypeId: newImages[i].imageTypeId }
            result = await uploadImage(image);
            if (result && result.error) {
                setError(result.error);
                return result;
            }
        }
        for (let i = 0; i < existingImages?.length; i++) {
            if (existingImages[i] !== vehicleDetails.images[i]) {
                result = await updateImageType(existingImages[i]);
                if (result && result.error) {
                    setError(result.error);
                    return result;
                }
            }
        }
        return result;
    };

    const convertToSentenceCase = (str) => {
        if (str)
            return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
    };

    const populatDecoded = () => {
        setOpenDecoded(false);
        if (decodedData) {

            setColorDecoded(true);
            if (decodedData?.vehicleType)
                setColorDecodedType(true);

            setEditedAsset({
                ...editedAsset,
                year: decodedData?.year,
                make: convertToSentenceCase(decodedData?.make),
                model: decodedData?.model,
                ...(decodedData?.vehicleType && { vehicleTypeId: decodedData.vehicleType }),
            });

            setTimeout(() => {
                setColorDecoded(false);
            }, 5000);
        }
    }

    const validateAsset = () => {
        let errors = {};
        if (editedAsset.hasLicensePlate && clientAccount?.extendedAssetData) {
            if (!editedAsset.licensePlate) {
                errors['licensePlate'] = true;
            }
            if (!editedAsset.licensePlateState) {
                errors['licensePlateState'] = true;

            }
        }
        if (editedAsset.hasRegistration && clientAccount?.extendedAssetData) {
            if (!editedAsset.registrationYear) {
                errors['registrationYear'] = true;
            }
        }
        setMandatoryFields(errors);
        if (Object.keys(errors)?.length) return false;
        return true;
    }
    const onSubmit = async () => {
        console.log('editedAsset', editedAsset);
        if (!validateAsset()) {
            return;
        }
        setIsSaving(true);
        let changes = diff(asset, editedAsset);
        let hasChanged = !!Object.entries(changes).length || hasVehicleDetailExtendedChanged;
        console.log('hasChanged', hasChanged);
        let vehicleId = asset.id;

        let assetUpdateResult;
        if (hasChanged) {
            changes.vehicleId = asset.id;
            console.log('changes', changes);

            if (changes.vehicleId) {
                changes.VehicleDetailExtendeds = newVehicleDetailsExtended;
                assetUpdateResult = await editAsset(changes);
            } else {
                assetUpdateResult = await addAsset(editedAsset);
                vehicleId = assetUpdateResult?.data?.id;
            }

            if (assetUpdateResult && assetUpdateResult.error) {
                NotificationProvider.error(`Failed to save the asset. ${getErrorsFromResponse(assetUpdateResult)}`);
                setError(assetUpdateResult.error);
                setIsSaving(false);
                return;
            }
        }

        let imageUpdateResult = await uploadImages(vehicleId);

        if (imageUpdateResult && imageUpdateResult.error) {
            NotificationProvider.error("Failed to save asset images");
            setError(imageUpdateResult.error);
            setIsSaving(false);
            return;
        }

        if ((assetUpdateResult && hasChanged) || imageUpdateResult) {
            NotificationProvider.success("The asset was saved successfully");
        }

        // refetchDetails();
        if (hasChanged) {
            updateAsset(editedAsset);
        } else {
            handleClose();
        }
    };

    const onChange = (key, val) => {
        setEditedAsset({ ...editedAsset, [key]: val });
        setMandatoryFields({ ...mandatoryFields, [key]: val ? false : true });
    };

    const isValid = () => {
        return !!editedAsset.descriptor &&
            (!!editedAsset.mileage || editedAsset.mileage === 0);
    };

    const addImage = image => {
        setNewImages([...newImages, image]);
    };

    const changeImageTypeId = (image) => {
        setExistingImages([...existingImages].map((img) => {
            if (img.id == image.id) {
                return { id: img.id, url: img.url, createdDate: img.createdDate, imageTypeId: image.imageTypeId };
            }
            else {
                return img;
            }
        }));
    };

    const onConfigChange = (key, val) => {
        let editVehicleDetail = newVehicleDetailsExtended.find(x => x.vehicleDetailExtendedTypeId === key);
        let allVehicleDetails = newVehicleDetailsExtended.filter(function (x) { return x.vehicleDetailExtendedTypeId !== key; });

        let test = { ...editVehicleDetail };
        test.value = val === true ? "Yes" : "No";
        allVehicleDetails.push(test);
        setNewVehicleDetailsExtended(allVehicleDetails);
        setHasVehicleDetailExtendedChanged(true);
    }

    const field = (fieldName, label, component, icon, profile) => (
        <Grid item className={classes.inputFieldExtended}>
            <div className={classes.labelFieldExtended}>{label} </div>
            <div className={classes.inputField}>
                {
                    component
                }
            </div>
        </Grid>
    )

    let form;
    if (error) {
        form = (
            <>
                <div className={classes.error}>There was an error saving the asset</div>
            </>
        )
    } else if (isLoading() || isSaving) {
        form = <CircularProgress className={classes.spinner} />
    } else if (showPhotos) {
        form = (
            <AddPhoto newImages={newImages} existingImages={existingImages || []} addImage={addImage} changeImageTypeId={changeImageTypeId} imageTypes={imageTypes} />
        )
    } else {
        form = (
            <>
                <div className={classes.input}>
                    <CustomInput
                        id="client"
                        label="Client"
                        value={editedAsset.customerId}
                        elementType="dropdown"
                        onChange={val => onChange("customerId", +val)}
                        values={clientAccounts}
                    />
                </div>
                <div className={classes.input}>
                    <div className={classes.vinWrappper}>
                        <Grid xs={9}>
                            <div className={classes.vinInput}>
                                <CustomInput
                                    id="vin"
                                    label="VIN"
                                    value={editedAsset.descriptor}
                                    elementType="input"
                                    required={true}
                                    onChange={val => onChange("descriptor", val)}
                                />
                                {!!editedAsset?.id && <VehicleMatches
                                    title={'This VIN already exists in ParQ, click for more details'}
                                    currentId={editedAsset?.id}
                                    vin={editedAsset.descriptor}
                                    clientAccountId={editedAsset.customerId}
                                />}
                            </div>
                        </Grid>
                        <Grid >
                            <VinDecode vin={editedAsset?.descriptor} setDecodedData={setDecodedData} applyDecode={populatDecoded} openDecoded={openDecoded} setOpenDecoded={setOpenDecoded} />
                        </Grid>
                    </div>
                </div>

                <Grid container>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <Grid>
                                <CustomInput
                                    id="assetId"
                                    label="Asset ID"
                                    value={editedAsset.assetId}
                                    elementType="input"
                                    onChange={val => onChange("assetId", val)}
                                />
                                {!!editedAsset?.id && <VehicleMatches
                                    title={'This asset id already exists in ParQ, click for more details'}
                                    currentId={editedAsset?.id}
                                    assetId={editedAsset.assetId}
                                    clientAccountId={editedAsset.customerId}
                                />}
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="mileage"
                                label="Mileage"
                                value={editedAsset.mileage}
                                elementType="input"
                                required={true}
                                onChange={val => onChange("mileage", +val)}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="year"
                                label="Year"
                                value={editedAsset.year ?? ''}
                                elementType="input"
                                onChange={val => onChange("year", val)}
                                colorInput={colorDecoded}
                            />
                            {decodedData && <VinMatching decodedData={decodedData.year} editedData={editedAsset.year} field='year' />}
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="make"
                                label="Make"
                                value={editedAsset?.make ?? ''}
                                elementType="input"
                                onChange={val => onChange("make", val)}
                                colorInput={colorDecoded}
                            />
                            {decodedData && <VinMatching decodedData={convertToSentenceCase(decodedData.make)} editedData={convertToSentenceCase(editedAsset.make)} field='make' />}
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="model"
                                label="Model"
                                value={editedAsset.model ?? ''}
                                elementType="input"
                                onChange={val => onChange("model", val)}
                                colorInput={colorDecoded}
                            />
                            {decodedData && <VinMatching decodedData={convertToSentenceCase(decodedData.model)} editedData={convertToSentenceCase(editedAsset.model)} field='model' />}
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="trim"
                                label="Trim"
                                value={editedAsset.trim}
                                elementType="input"
                                onChange={val => onChange("trim", val)}
                            />
                        </div>
                    </Grid>
                </Grid>
                <div className={classes.input}>
                    <CustomInput
                        id="lot"
                        label="Lot"
                        value={editedAsset.lotId}
                        elementType="dropdown"
                        onChange={val => onChange("lotId", +val)}
                        values={getLotsWithPmfText(lots)}
                    />
                </div>
                <div className={classes.input}>
                    <CustomInput
                        id="domicileLot"
                        label="Domicile Lot"
                        showEmpty
                        value={editedAsset.domicileLot}
                        elementType="dropdown"
                        onChange={val => onChange("domicileLot", +val)}
                        values={getLotsWithPmfText(lots)}
                    />
                </div>
                <div className={classes.input}>
                    <CustomInput
                        id="type"
                        label="Asset Type"
                        value={editedAsset.vehicleTypeId}
                        elementType="dropdown"
                        onChange={val => onChange("vehicleTypeId", +val)}
                        values={vehicleTypes}
                        colorInput={colorDecodedType}
                    />
                </div>
                <div className={classes.input}>
                    <CustomInput
                        id="status"
                        label="Status"
                        value={editedAsset.vehicleStatusId}
                        elementType="dropdown"
                        onChange={val => onChange("vehicleStatusId", +val)}
                        values={vehicleStatuses}
                    />
                </div>
                <Grid container>
                    <Grid item xs={6}>
                        <div className={classes.input}>
                            <CustomInput
                                id="carrier"
                                label="Carrier"
                                value={editedAsset.driverCarrierId}
                                elementType="dropdown"
                                onChange={val => onChange("driverCarrierId", +val)}
                                values={[{ id: null, name: '' }, ...vendors]}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6} >
                        <div className={classes.input}>
                            <CustomInput
                                id="driver"
                                label="Driver"
                                elementType="dropdown"
                                values={drivers}
                                value={editedAsset.driverId}
                                showEmpty
                                onChange={val => onChange("driverId", +val)}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4}>
                        <div className={classes.input}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={editedAsset.hasLicensePlate ?? false}
                                    onChange={(e, value) => onChange("hasLicensePlate", value)}
                                />}
                                label="Has license plate" />
                        </div>
                    </Grid>
                    {editedAsset.hasLicensePlate && clientAccount?.extendedAssetData && <><Grid item xs={4}>
                        <div className={classes.input}>
                            <CustomInput
                                id="license"
                                label="License Plate"
                                value={editedAsset.licensePlate ?? ''}
                                elementType="input"
                                onChange={val => onChange("licensePlate", val)}
                                valid={!mandatoryFields?.['licensePlate']}
                                touched={true}
                            />
                        </div>
                    </Grid>
                        <Grid item xs={4}>
                            <div className={classes.input}>
                                <CustomInput
                                    id="licensePlateState"
                                    label="License State"
                                    value={editedAsset.licensePlateState}
                                    elementType="dropdown"
                                    onChange={val => onChange("licensePlateState", val)}
                                    values={[{ name: '', id: null }, ...VehicleService.getUsStates()]}
                                    valid={!mandatoryFields?.['licensePlateState']}
                                    touched={true}
                                />
                            </div>
                        </Grid></>}
                </Grid>
                <Grid container>
                    <Grid item xs={4} container>
                        <Grid item xs={6}>
                            <div className={classes.input}>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={editedAsset.hasRegistration ?? false}
                                        onChange={(e, value) => {
                                            if (!value) {
                                                onChange("registrationExpired", false)
                                                onChange("registrationYear", null)
                                                onChange("registrationMonth", null)
                                            }
                                            onChange("hasRegistration", value);
                                        }
                                        }
                                    />}
                                    label="Has registration" />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.input}>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={editedAsset.registrationExpired ?? false}
                                        onChange={(e, value) => onChange("registrationExpired", value)}
                                    />}
                                    label="Expired" />
                            </div>
                        </Grid>
                    </Grid>
                    {editedAsset.hasRegistration && clientAccount?.extendedAssetData && <><Grid item xs={4}>
                        <div className={classes.input}>
                            <CustomInput
                                id="regDateYear"
                                label="Year"
                                value={editedAsset.registrationYear}
                                elementType="dropdown"
                                onChange={val => onChange("registrationYear", val)}
                                values={[{ name: '', id: null }, ...FormatService.getYears(30)?.map(y => ({ id: y, name: y }))]}
                                valid={!mandatoryFields?.['registrationYear']}
                                touched={true}
                            />
                        </div>
                    </Grid>
                        <Grid item xs={4}>
                            <div className={classes.input}>
                                <CustomInput
                                    id="regDateMonth"
                                    label="Month"
                                    value={editedAsset.registrationMonth}
                                    elementType="dropdown"
                                    onChange={val => onChange("registrationMonth", val)}
                                    values={[{ name: '', id: null }, ...FormatService.getMonths()]}
                                />
                            </div>
                        </Grid>
                    </>
                    }
                </Grid>
                {isVehicleDetailLoading &&
                    <Paper className={classes.fieldGroup}>
                        <Grid container xs={12}>
                            <Grid xs={12} className={classes.groupTitle}>
                                Vehicle Detail Extended
                            </Grid>
                            <Grid xs={12} className={classes.groupTitleForm}>
                                {newVehicleDetailsExtended && _.sortBy(newVehicleDetailsExtended, 'vehicleDetailExtendedTypeId').map(entity =>

                                    <Grid item>
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={entity.value === "Yes" ? true : false}
                                                onChange={(e, value) => onConfigChange(entity.vehicleDetailExtendedTypeId, value)}
                                            />}
                                            label={entity.label} />
                                    </Grid>
                                )}

                            </Grid>
                        </Grid>
                    </Paper>
                }
            </>
        );
    }

    return (
        <Paper className={classes.formContainer}>
            <form className={`${classes.form} ${isLoading() && classes.disabledForm}`}>
                {form}
            </form>
            <div className={`${classes.btnContainer} ${error ? classes.hiddenButtons : null}`}>
                <div>
                    <Button className={classes.button} variant="outlined" onClick={() => setShowPhotos(!showPhotos)} startIcon={showPhotos ? <List /> : <Image />}>{showPhotos ? "Details" : "Photos"}</Button>
                </div>
                <div>
                    <Button className={classes.button} variant="outlined" onClick={() => handleClose()}>Cancel</Button>
                    <Button
                        onClick={onSubmit}
                        disabled={isSaving || !isValid()}
                        className={classes.button}
                        variant="contained"
                    >
                        {isSaving ? "Saving..." : "Finish"}
                    </Button>
                </div>
            </div>
        </Paper>
    );
};

export default EditAssetPopup;
