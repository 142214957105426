import { Paper, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import LoadCard from '../LoadCard';
import LoadMap from '../LoadMap';
import LoadAssets from '../LoadAssets';
import { loadDetailsStyle } from './LoadDetailsStyle';
import LoadHistory from '../LoadHistory';
import { Switch } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LoadAttachments from '../LoadAttachments';
import { permissionProfiles } from '../../../../components/Authorize/permissionProfiles';
import Authorize from '../../../../components/Authorize';
import LoadPayments from '../LoadPayments';

function LoadDetails({ load, readiness, contacts }) {
    const classes = loadDetailsStyle()
    const [showLocations, setShowLocations] = useState(false);

    const setShowLocationsVal = (val) => {
        setShowLocations(val.target.checked);
    };

    return (
        <Grid container direction="column" className={classes.container}>
            <Paper className={classes.card}>
                <LoadCard load={load} contacts={contacts} />
            </Paper>
            {load.comments && <Paper className={classes.card}>
                {load.comments}
            </Paper>}
            <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                    <div className={classes.card}>
                        <LoadAssets assets={load.assets} readiness={readiness} />
                    </div>
                    <div className={classes.card}>
                        <LoadHistory load={load} />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.longCard} elevation={1}>
                        <div>Map</div>
                        <FormControlLabel
                            value={showLocations}
                            control={<Switch color="primary" />}
                            label="Show locations"
                            labelPlacement="start"
                            onChange={setShowLocationsVal}
                        />
                        <LoadMap load={load} showLocations={showLocations} />
                    </Paper>
                </Grid>
            </Grid>
            <Paper className={classes.card}>
                <div className={classes.boxTitle}>Attachments</div>
                <LoadAttachments load={load} />
            </Paper>
            <Authorize profile={permissionProfiles.LOAD.LOAD_PAYMENTS}>
                <Paper className={classes.card}>
                    <div className={classes.boxTitle}>Payments</div>
                    <LoadPayments load={load} />
                </Paper>
            </Authorize>
        </Grid>
    );
}

export default LoadDetails