import { Button } from "@material-ui/core";
import MyLocationRoundedIcon from '@material-ui/icons/MyLocationRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { TextField } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import parse from 'autosuggest-highlight/parse';
import LotsMapMarker from 'features/maps/markers/LotsMapMarker/LotsMapMarker';
import VehicleMapMarker from "features/maps/markers/VehicleMapMarker/VehicleMapMarker";
import { IconLot } from 'icons';
import { useEffect, useMemo, useState } from 'react';
import { googleMapService } from "../googleMapService";
import AssetSearch from './AssetSearch';
import SearchMode from './SearchMode';
import { searchStyle } from "./SearchStyle";

function SearchBox({ map, maps, defaultCenter, defaultZoom, createMarkerWithEvent, markers, isNarrowScreen }) {
    const classes = searchStyle();

    const getLotOptions = () => {
        return markers?.map(m => { return { lot: true, name: m.name, address: m.address, city: m.city, state: m.state, zip: m.zipCode, ...m } }) ?? [];
    }

    const [currentLocationMarker, setCurrentLocationMarker] = useState();
    const [openSearch, setOpenSearch] = useState(false);
    const [searchMode, setSearchMode] = useState('location');
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState(getLotOptions());
    const autocompleteService = { current: null };

    useEffect(() => {
        setOptions(getLotOptions());
    }, [markers])

    const handleOpenSearch = () => {
        setOpenSearch(true);
    }

    const reFocusMap = (map) => {
        if (map) {
            map.setCenter(defaultCenter);
            map.setZoom(defaultZoom);

            if (currentLocationMarker) {
                var mark = currentLocationMarker;
                mark.setMap(null);
                setCurrentLocationMarker(mark);
            }
        }
    }

    const createAssetMarker = (locationInfo) => {
        const assetMarkerIcon = <VehicleMapMarker
            color="#0090FE"
            text={locationInfo?.descriptor}
        />;
        const onClick = (vehicleId) => {
            window.open(`/assets/detail/${vehicleId}`);
        };
        const location = { lat: parseFloat(locationInfo?.latitude), lng: parseFloat(locationInfo?.longitude) }

        focusMap(maps, location, map, assetMarkerIcon, onClick, locationInfo.vehicleId);
    }

    const createLocationMarker = (maps, location, map, address, onClick, id) => {
        if (address) {
            const currentLocationIcon = <LotsMapMarker
                text={address}
                color={"#FF5A4F"}
                textColor
            />;
            focusMap(maps, location, map, currentLocationIcon, onClick, id);
        }
    }

    const focusMap = (maps, location, map, icon, onClick, id) => {
        const marker = createMarkerWithEvent(maps, location, map, icon, '', onClick, id);
        marker.zIndex = 10;
        setCurrentLocationMarker(marker);
        map.setCenter(new maps.LatLng(location));
        map.setZoom(6);
    }

    const fetch = useMemo(
        () =>
            debounce((request, callback) => {
                if (autocompleteService.current)
                    autocompleteService.current.getPlacePredictions(request, callback);
            }, 400),
        [],
    );

    useEffect(() => {
        reFocusMap(map);
        const fetchData = async () => {
            if (value) {
                const geoRes = await googleMapService.geoCode(maps, value.description ?? value.address);
                const lotLocation = { lat: parseFloat(value.latitude), lng: parseFloat(value.longitude) }
                const locationToSend = value.latitude && value.longitude ? lotLocation : geoRes;
                createLocationMarker(maps, locationToSend, map, value.description ?? value.name, value.onClick, value.id);
            }
        }
        fetchData();
    }, [value])

    useEffect(() => {
        let active = true;

        if (autocompleteService && !autocompleteService.current && maps.places) {
            autocompleteService.current = new maps.places.AutocompleteService()
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : getLotOptions());
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions([...getLotOptions(), ...newOptions]);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    const getOptionLabel = (option) => {
        if (option?.lot)
            return `${option.name} ${option.address} ${option.city} ${option.state} ${option.zip}`;
        else
            return typeof option === 'string' ? option : option?.description;
    }

    const handleChange = (event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
    }

    const renderOption = (props, option) => {
        const matches =
            option?.structured_formatting?.main_text_matched_substrings || [];

        const parts = parse(
            option?.structured_formatting?.main_text,
            matches?.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
            <li {...props}>
                <Grid container alignItems="center">
                    <Grid item sx={{ display: 'flex', width: 40 }}>
                        {option?.lot ? <IconLot height='27' width='27' /> :
                            <MyLocationRoundedIcon />}
                    </Grid>
                    <Grid item sx={{ width: 'calc(100% - 40px)', wordWrap: 'break-word' }}>
                        <>{option?.lot ?
                            <>
                                <Box
                                    component="span"
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    {option?.name}
                                </Box>
                                <Typography variant="body1" color="text.secondary">
                                    {option?.address}
                                </Typography>
                                <Typography variant="body1" fontSize={12} fontWeight={300} color="text.secondary">
                                    {option?.zip}
                                </Typography>
                            </> :
                            <>
                                <>{parts?.map((part, index) => (
                                    <Box
                                        key={index}
                                        component="span"
                                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                    >
                                        {part.text}
                                    </Box>
                                ))}</>
                                <Typography variant="body2" color="text.secondary">
                                    {option?.structured_formatting?.secondary_text}
                                </Typography>
                            </>}
                        </>
                    </Grid>
                </Grid>
            </li>
        );
    }

    return (
        <>
            {!openSearch && <Button
                variant="text"
                className={classes.searchButton}
                onClick={handleOpenSearch}
            >
                <SearchRoundedIcon />
            </Button>}

            {openSearch && <>
                <Grid container justifyContent='center'>
                    {searchMode == 'location' && <Grid item>
                        <Autocomplete
                            sx={{
                                width: 300,
                                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                    border: 'none'
                                }
                            }}
                            getOptionLabel={(option) => getOptionLabel(option)}
                            id="location-customized-search"
                            options={options}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            value={value}
                            isOptionEqualToValue={(option, value) => {
                                return option?.id == value?.id
                            }}
                            onChange={handleChange}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label={!inputValue ? "Search for lot name or location" : ''} fullWidth
                                    style={{ margin: '0.5rem 0 0 1rem' }}
                                    className={classes.searchBar}
                                />
                            )}
                            renderOption={(props, option) => renderOption(props, option)}
                        />
                    </Grid>}
                    {searchMode == 'asset' && <Grid item className={classes.searchVin}>
                        <AssetSearch createAssetMarker={createAssetMarker} reFocusMap={reFocusMap} map={map} />
                    </Grid>}
                    <Grid item xs={isNarrowScreen ? 12 : 0}>
                        <SearchMode searchMode={searchMode} setSearchMode={setSearchMode} />
                    </Grid>
                </Grid>
            </>}
        </>
    );
}

export default SearchBox;