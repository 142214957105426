import React, { useEffect, useState } from 'react';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder/EmptyPlaceholder';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import FadeInImage from '../../../vehicles/components/FadeInImage/FadeInImage';
import { useGetLoadPaymentsQuery } from '../../loadsSlice';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import { Grid } from '@material-ui/core';
import { loadPaymentsStyle } from './LoadPaymentsStyle';
import FormatService from '../../../../services/formatService';

function LoadPayments({ load, placeholder, showLoading = true, hasTitle }) {
    let { data: loadPayments, error, isLoading } = useGetLoadPaymentsQuery(load?.id);

    const classes = loadPaymentsStyle()

    return (
        <div className={classes.attachmentsContainer}>
            <LoadingSpinner loading={showLoading && isLoading} />
            {loadPayments?.length ?
                <div className={classes.paymentWrapper}>
                    {loadPayments?.map(payment => <><div>{payment.description}</div><div className={classes.paymentAmount}>{FormatService.formatCurrency(payment.amount)}</div></>)}
                </div> :
                placeholder ?? <EmptyPlaceholder text='no attachments' />
            }
        </div>
    );
}

export default LoadPayments