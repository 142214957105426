import { modes } from "../modes";
import { permissions } from "../permissions/permissions";
import { roles } from "../roles";

export const userPermissionProfiles = {
    VIEW_CLIENTS: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.ACCOUNT_MANAGER],
    },
    VIEW_ALL_CLIENTS_LOTS: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN],
    },
    VIEW_LIMITED_CLIENTS_LOTS: {
        [modes.EDIT]: [roles.ACCOUNT_MANAGER],
    },
    VIEW_USERS: {
        [modes.EDIT]: [roles.OWNER],
    },
    MANAGE_PERMISSIONS: {
        [modes.EDIT_PERMISSION]: [permissions.PERMISSION.MANAGE_PERMISSIONS],
    }
}