import { AccessGroupTypes } from "../accessConsts";



const AccessValidator = {
    validateGroupTypeStep: (access) => {
        return access?.groupType;
    },
    validateAccessInfoStep: (access) => {
        switch (access?.groupType) {
            case AccessGroupTypes.CLIENT:
                return access?.clientId;
            case AccessGroupTypes.CARRIER:
                return access?.carrierId;
            case AccessGroupTypes.TRANSPORTER:
                return access?.transporterId;
            case AccessGroupTypes.VENDOR:
                return access?.serviceProviderId;
            default:
                return true;
        }
    },
};

export default AccessValidator;