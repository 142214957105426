import { createSlice } from "@reduxjs/toolkit";
import { sum } from 'lodash';
import { baseApi } from "../../../app/baseApi";
import { apiTags } from "../../../app/store/apiTags";

const basePath = 'storageinvoice';

export const storageInvoiceApi = baseApi.injectEndpoints({
    reducerPath: 'loadInvoiceApi',
    endpoints: (builder: any) => ({
        getStorageInvoices: builder.query({
            query: (body: StorageInvoiceRequest) => ({
                url: `${basePath}?${Object.keys(body)?.filter(k => body[k]).map(k => `${k}=${body[k]}`)?.join('&')}`,

            }),
            providesTags: [apiTags.STORAGE_INVOICES],
            transformResponse: (response: Array<StorageInvoice>) => {
                return response.map(invoice => ({
                    ...invoice,
                }));
            }
        }),
        updateStorageInvoiceDetails: builder.mutation({
            query: (body: any) => ({
                url: `${basePath}/details`,
                method: "PUT",
                body
            }),
            invalidatesTags: [apiTags.STORAGE_INVOICES],
        }),
        updateStorageInvoiceTotals: builder.mutation({
            query: (body: any) => ({
                url: `${basePath}/totals`,
                method: "PUT",
                body
            }),
            invalidatesTags: [apiTags.STORAGE_INVOICES],
        }),

    })

});


export const {
    useGetStorageInvoicesQuery,
    useUpdateStorageInvoiceDetailsMutation,
    useUpdateStorageInvoiceTotalsMutation,
} = storageInvoiceApi;

export const storageInvoiceStateSlice = createSlice({
    name: 'storageInvoiceStateSlice',
    initialState: {
        searchVal: '',
        filters: {
            'clientId': '',
            'month': '',
            'year': ''
        }
    },
    reducers: {
        setSearchVal: (state, action) => {
            state.searchVal = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setFilter: (state, action) => {
            state.filters = { ...state.filters, [action.payload.key]: action.payload.value }
        },
    },
});

export const { setSearchVal, setFilters, setFilter } = storageInvoiceStateSlice.actions;
