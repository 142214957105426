import ExportButton from "components/ExportButton";
import { billingPrograms } from "../consts";
import { ServiceItemTypes, loadInvoiceColumns } from "features/invoice/loads/loadInvoiceConsts";

const TransportExportData = ({ data, transformData }) => {
    //TEMP move to API

    const mapExportData = row => {
        const detailsInfo = {};
        detailsInfo['Client'] = transformData['clientId']?.(row.clientId) ?? row.lotId;
        detailsInfo['Pickup'] = transformData['lotId']?.(row.pickupLocationId) ?? row.pickupLocationId;
        detailsInfo['Delivery'] = transformData['lotId']?.(row.deliveryLocationId) ?? row.deliveryLocationId;
        detailsInfo['Date'] = row.formattedDate;
        loadInvoiceColumns?.map(col => {
            detailsInfo[col.name] = col.value ? col.value(row?.[col.key]) : row?.[col.key]
        });

        return detailsInfo;
    };


    const programData = data?.filter(row => row.programType == billingPrograms.TRANSPORT).map(row => ({
        ...row.loadInvoiceEntity,
        status: row.loadInvoiceEntity?.statusId,
        pickupLocationId: row.loadInvoiceEntity?.vehicles?.[0]?.pickupLocationId,
        deliveryLocationId: row.loadInvoiceEntity?.vehicles?.[0]?.deliveryLocationId,
        carrierCost: `$${row.loadInvoiceEntity?.services?.find(service => service.itemTypeId === ServiceItemTypes.TRANSPORT)?.amount ?? 0}`,
        fuelCost: `$${row.loadInvoiceEntity?.services?.find(service => service.itemTypeId === ServiceItemTypes.FUEL)?.amount ?? 0}`,
    })) ?? [];

    return <>
        <ExportButton mapExportData={mapExportData} data={programData} label="Export Transport Program Data" />
    </>
}
export default TransportExportData;