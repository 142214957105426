import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { NavLink } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import mobileBreakpoint from "../utils/mobileBreakpoint";

const useStyles = makeStyles(theme => ({
    linkContainer: {
        padding: 0,
        maxWidth: "192px",
        margin: "0 24px 28px",
        borderRadius: "20px",
        //height: "40px"
        boxSizing: 'border-box',
        marginLeft: '24px'
    },
    link: {
        display: "flex",
        textDecoration: "none",
        color: theme.palette.common.darkText,
        alignItems: "center",
        width: "100%",
        height: "100%",
        padding: "8px 16px",
        borderRadius: "20px",
    },
    selected: {
        background: theme.palette.neutral.light,
    },
    icon: {
        minWidth: "40px",
        "& img": {
            width: "24px"
        }
    },
    text: {
        fontSize: "14px"
    },
    linkContainerMobile: {
        marginLeft: "-5px",
        maxWidth: "40px"
    },
    linkMobile: {
        paddingLeft: "9px"
    },
    subItem: {
        //marginLeft: '1em',
        marginTop: '-1.5em',
    }
}));

export default function SideNavLink({ text, icon, link, isSubItem, isNavOpen }) {
    const classes = useStyles();
    const isMobile = useMediaQuery(mobileBreakpoint);
    
    return (
        <ListItem button className={`${classes.linkContainer} ${isMobile ? classes.linkContainerMobile : ""} ${isSubItem ? classes.subItem : ''}`} key={text}>
            <NavLink
                className={isActive =>
                    classes.link + (isActive ? ` ${classes.selected}` : "") + (isMobile ? ` ${classes.linkMobile}` : "")
                } to={`/${link ?? text.toLowerCase()}`}>
                
                <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
                {
                    !isMobile?
                        <ListItemText 
                            className={classes.text} 
                            primary={text}
                            style={{ display: isNavOpen ? "block" : "none" }}
                        />
                        : null
                }
            </NavLink>
        </ListItem >
    );
}
