import { Grid, Paper } from "@material-ui/core";
import DropdownFilter from "components/DropdownFilter";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useDispatch, useSelector } from "react-redux";
import FormatService from "services/formatService";
import { setFilters } from "../../activityInvoiceSlice.js";
import { activityHeaderStyles } from "./style";
import { activityHeaderColumns } from "../../activityInvoiceConsts.js";

const ActivityInvoiceHeader = ({ }) => {
    const classes = activityHeaderStyles();

    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery();
    const { filters, searchVal } = useSelector(state => state.activityInvoiceState);
    const dispatch = useDispatch();

    const handleChange = (prop, filterValue) => {
        dispatch(setFilters({ ...filters, ...{ [prop]: filterValue } }));
    }

    const filterConfig = [
        {
            key: 'clientId',
            title: 'Client',
            values: clients
        },
        {
            key: 'month',
            title: 'Month',
            values: FormatService.getMonths()
        },
        // {
        //     key: 'year',
        //     values: FormatService.getYears(1, true, false).map(y => ({ id: y, name: y }))
        // }
    ]


    return (
        <Paper className={classes.wrapper}>
            <Grid container spacing={2}>
                {filterConfig.map(filter =>
                    <Grid item xs={6} key={filter.key}>
                        <DropdownFilter
                            title={filter?.title}
                            showEmpty
                            values={filter.values}
                            onSelect={(selected) => handleChange(filter.key, selected)}
                            value={filters?.[filter.key]}
                        />
                    </Grid>)}
            </Grid>
            <Grid container spacing={1} >
                {activityHeaderColumns?.map((col, index) =>
                    <Grid className={classes.column} xs={col.size} item key={`col_${index}`}>
                        {col?.label}
                    </Grid>)}
            </Grid>
        </Paper>
    )
}

export default ActivityInvoiceHeader;