import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { Box, Button, IconButton, Link, withStyles } from '@material-ui/core';
import VirtualizedTable from './NewTable';
import { useRef } from 'react';

const useStyles = makeStyles((theme) => ({
    table: {
        backgroundColor: theme.palette.common.white,
        minWidth: 650,
    },
    tableHead: {
        backgroundColor: theme.palette.common.white
    },
    tableRow: {
        height: 51,
        verticalAlign: "center"
    },
    container: {
        padding: "0 28px 25px",
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.15)",
        height: '100%'
    },
    columnHeader: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        fontWeight: 400,
        fontSize: 16
    },
    hiddenIcon: {
        visibility: 'hidden'
    },
    noItemsDiv: {
        backgroundColor: theme.palette.common.white,
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    iconButtonCell: {
        paddingTop: 11
    },
    iconButton: {
        width: 32,
        height: 32
    }
}));

const DataTable = ({
    rows,
    columns,
    rowIdentifier,
    onSort,
    sortDirection,
    columnToSort,
    noItemsMessage,
    actions,
    headerStyle,
    maxActionCount = 0,
    actionWithMenu 
}) => {

    const classes = useStyles();
    let tableRef = useRef(null);

    return (
        <>
            {
                rows.length > 0 ?
                    <TableContainer component={Paper} className={classes.container}>
                        <VirtualizedTable
                            setRef={(ref) => tableRef = ref}
                            rowCount={rows.length}
                            rowGetter={({ index }) => rows[index]}
                            columns={
                                [...columns.map(col => ({ ...col, dataKey: col.key, label: col.name })),
                                ...(actions ? [{ label: 'Actions', dataKey: rowIdentifier,
                                    isAction: true, width: (maxActionCount * 110) }] : [])]
                            }
                            onSort={onSort}
                            sortDirection={sortDirection}
                            columnToSort={columnToSort}
                            actions={actions}
                            actionWithMenu={actionWithMenu}
                            headerStyle={headerStyle}
                        />
                    </TableContainer>
                    : <div className={classes.noItemsDiv}>
                        <h3>{noItemsMessage}</h3>
                    </div>}
        </>
    )
}

export default DataTable
