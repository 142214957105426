export const relationTypeEnum = [
    { id: 1, name: "Attendant" },
    { id: 2, name: "Supervisor" },
    { id: 3, name: "Regional Manager" }
];

export const relationTypeEnumById = {
    ATTENDANT: 1,
    SUPERVISOR: 2,
    REGIONAL_MANAGER: 3
};