import React from "react";
import { Grid, Paper } from "@material-ui/core";
import LoadingSpinner from "components/LoadingSpinner";
import { KpiOverviewStyles } from "./KpiOverviewStyle";
import { useGetLotScoresQuery, useGetLotsQuery } from "features/lots/lotSlice";
import SiteSizing from "./SiteSizing/SiteSizing";
import Productivity from "./Productivity/Productivity";
import LotRankingList from "./LotRankingList/LotRankingList";
import { useState } from "react";


const KpiOverview = () => {

    const classes = KpiOverviewStyles();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    let { data: lotRanking, error: lotRankingError, isFetching: isLoadingLotsRanking, isSuccess } = useGetLotScoresQuery({ startDate, endDate });
    lotRanking = lotRanking || []

    const isLoadingDashboardData = () => isLoadingLotsRanking;

    return (
        <>
            {
                isLoadingDashboardData() ? <LoadingSpinner loading={isLoadingDashboardData()} /> :
                    <>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={5} >
                                <Paper elevation={1} className={classes.card}>
                                    <SiteSizing />
                                </Paper>
                            </Grid>
                            <Grid item xs={7} >
                                <Paper elevation={1} className={classes.card}>
                                    <Productivity lotRanking={lotRanking} />
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingSpinner loading={isLoadingLotsRanking} />
                                <LotRankingList setStartDate={setStartDate} setEndDate={setEndDate} lotRanking={lotRanking} />
                            </Grid>
                        </Grid>

                    </>
            }
        </>
    );
};

export default KpiOverview;
