import { Tooltip } from '@material-ui/core';
import { CardsStyle } from '../../CardsStyle';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

const PocError = ({ access }) => {

    const classes = CardsStyle()

    return (
        <>
            {(!access.pocName || !access.pocEmail || !access.pocPhone) &&
                <Tooltip title={'Some points of contact are missing'}>
                    <ErrorOutlineOutlinedIcon className={classes.errorIcon} />
                </Tooltip>}
        </>
    );
}

export default PocError



