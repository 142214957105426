import { IconButton, Tooltip } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { copyToClipboardStyle } from './style';
import {CopyToClipboard as Copy} from 'react-copy-to-clipboard';

const CopyToClipboard = ({ text }) => {
    const classes = copyToClipboardStyle();

    return (
        <Copy text={text}>
            <Tooltip title='Copy to clipboard'>
                <IconButton>{<FileCopyOutlinedIcon className={classes.icon} color='secondary' />} </IconButton>
            </Tooltip>
        </Copy>
    )
}
export default CopyToClipboard;