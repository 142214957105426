import { Grid, IconButton } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import Authorize from '../components/Authorize';
import { permissionProfiles } from '../components/Authorize/permissionProfiles';
import { useGetClientsWithConfigsQuery } from '../features/clients/clientConfigSlice';
import { selectIsAdmin, selectIsClient, selectIsOwner } from "../features/user/userSlice";
import { IconBox, IconCar, IconClipboard, IconGraph, IconKey, IconLogout, IconLot, IconSla, IconTruck, IconUser } from '../icons';
import mobileBreakpoint from "../utils/mobileBreakpoint";
import SideNavLink from "./SideNavLink";

const drawerWidth = 240;
const mobileDrawerWidth = 90;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.common.white,
        border: "none",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.15)"
    },
    drawerContainer: {
        overflow: 'hidden',
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    logo: {
        maxWidth: "152px",
        paddingLeft: "72px"
    },
    logoutButton: {
        textAlign: "left",
        margin: "1rem 0 2rem 2rem"
    },
    logoMobile: {
        maxWidth: 60,
        paddingLeft: 0,
        marginBottom: 32,
        marginTop: 4
    },
    drawerMobile: {
        width: mobileDrawerWidth
    },
    drawerPaperMobile: {
        width: mobileDrawerWidth,
        overflow: "hidden"
    },
    logoutButtonMobile: {
        marginLeft: "10px",
        "& .MuiButton-root": {
            minWidth: 0
        },
        "& .MuiButton-label": {
            paddingLeft: "8px"
        },
        "& .MuiButton-startIcon": {
            marginRight: 0
        }
    },
    addAssetButton: {
        margin: "1rem 0 2rem 2rem"
    },
    expand: {
        borderLeft: '1px solid #efefef'
    },
    menuItemWrapper: {
        overflow: 'hidden'
    },
    subMenu: {
        background: theme.palette.background.default
    }
}));


export default function SideNav({ isNavOpen }) {
    const classes = useStyles();
    const isMobile = useMediaQuery(mobileBreakpoint);
    const history = useHistory();
    let isAdmin = useSelector(selectIsAdmin);
    let isOwner = useSelector(selectIsOwner);
    let isClient = useSelector(selectIsClient);
    let user = useSelector(state => state.oidc.user);
    let isUserLoaded = !!user;

    let {
        data: clientsWithConfigs,
        error: clientsWithConfigsError,
        isFetching: isLoadingClientsWithConfigs
    } = useGetClientsWithConfigsQuery(null, { skip: isAdmin || !isUserLoaded });
    clientsWithConfigs = clientsWithConfigs || [];

    let isUsingTransports = false
    let showUsers = false

    function logout() {
        history.push("/logout");
    }

    const menuItems = [
        {
            text: 'Dashboard',
            icon: <IconGraph />
        },
          {
            text: 'Lots',
            icon: <IconLot />
        },
        {
            text: 'Users',
            icon: <IconUser />,
            checkVisibility: () => showUsers
        },
        {
            text: 'Access',
            icon: <IconKey />
        },
        {
            text: 'Assets',
            icon: <IconCar />
        },
        {
            text: 'Inventory',
            icon: <IconBox />,
            profile: permissionProfiles.INVENTORY.INVENTORY_CREATE_EDIT
        },
        {
            text: 'Reports',
            icon: <IconClipboard />,
            subMenu: {
                items: [
                    {
                        text: 'Activity report',
                        link: 'reports',
                        profile: permissionProfiles.DASHBOARD.ONE_ON_ONE
                    },
                    {
                        text: '1:1 Report',
                        link: 'oneonones',
                        profile: permissionProfiles.DASHBOARD.ONE_ON_ONE
                    },
                    {
                        text: 'New 1:1',
                        link: 'oneonones/new',
                        profile: permissionProfiles.DASHBOARD.ONE_ON_ONE
                    },
                    {
                        text: 'Issues Tracker',
                        link: 'issues/tracker/ '
                    },
                    {
                        text: 'Inspections',
                        link: 'inspection',
                        profile: permissionProfiles.INSPECTION.INSPECTION_VIEW
                    },
                    {
                        text: 'New Inspection',
                        link: 'inspection/new',
                        profile: permissionProfiles.DASHBOARD.CREATE_INSPECTION
                    }
                ]
            },
        },
        {
            text: 'Loads',
            icon: <IconTruck />,
            subMenu: {
                items: [
                    {
                        text: 'Create Load',
                        //icon: <OpenInBrowserOutlined />,
                        link: 'loads/list?createload=true&latestStatus=8'
                    }
                ]
            },
            checkVisibility: () => isUsingTransports
        },
        {
            text: 'SLAs',
            icon: <IconSla />,
            subMenu: {
                profile: permissionProfiles.INVOICE.INVOICES_VIEW,
                items: [
                    {
                        text: 'Invoices',
                        //icon: <ReceiptIcon />,
                        link: 'invoices/loads',
                        profile: permissionProfiles.INVOICE.INVOICES_VIEW
                    }
                ]
            }
        },
    ]

    if (!isUserLoaded) {
        return <></>;
    }

    if (isClient) {
        isUsingTransports = clientsWithConfigs?.length ? clientsWithConfigs[0]?.config.transportConfig.useTransport : false
    } else {
        isUsingTransports = true
    }

    if (isOwner) {
        showUsers = true;
    }

    return (
        <div className={classes.root}>
            <Drawer
                className={`${classes.drawer} ${isMobile || !isNavOpen ? classes.drawerMobile : ""}`}
                variant="permanent"
                classes={{
                    paper: `${classes.drawerPaper} ${isMobile || !isNavOpen ? classes.drawerPaperMobile : ""}`
                }}
            >
                <Toolbar disableGutters>
                    <img
                        src="/images/ParqAIlogo.png"
                        className={`${classes.logo} ${isMobile ? classes.logoMobile : ""}`}
                        alt="parq.ai"
                        style={{ display: isNavOpen ? "block" : "none" }}
                    />
                </Toolbar>
                <div className={classes.drawerContainer}>
                    <List style={{ overflowY: "auto" }}>
                        {menuItems?.filter(item => !item.checkVisibility || item.checkVisibility()).map((item, index) =>
                            <MenuItem menu={item} isNavOpen={isNavOpen} />
                        )}
                    </List>
                    <div className={`${classes.logoutButton} ${isMobile || !isNavOpen ? classes.logoutButtonMobile : ""}`}>
                        <Button variant="text" onClick={() => logout()} startIcon={<IconLogout />}>
                            {
                                isNavOpen ?
                                    "Logout"
                                    : ""
                            }
                        </Button>
                    </div>
                </div>
            </Drawer>
        </div>
    );
}

const MenuItem = ({ menu, isNavOpen }) => {

    const isMobile = useMediaQuery(mobileBreakpoint);
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const subMenus = <>{menu?.subMenu?.items?.map((subItem, sindex) =>
        <Authorize profile={subItem.profile}>
            <SideNavLink isSubItem={true}
                key={menu.text + sindex}
                text={subItem.text}
                icon={subItem.icon}
                link={subItem.link}
                isNavOpen={isNavOpen} />
        </Authorize>
    )}</>

    const subMenuLength = menu?.subMenu?.items?.length;
    return (
        <Authorize profile={menu.profile}>
            <div>
                <Grid container alignContent='space-between' alignItems='flex-start'>
                    <Grid className={classes.menuItemWrapper} xs={!!subMenuLength ? 9 : 12}>
                        <SideNavLink key={menu.text}
                            text={menu.text}
                            icon={menu.icon}
                            isNavOpen={isNavOpen}
                        />
                    </Grid>
                    {!!subMenuLength &&
                        <Authorize profile={menu?.subMenu?.profile}>
                            <Grid xs={3} className={classes.expand}
                                style={{ display: isNavOpen ? "block" : "none" }}>
                                <IconButton onClick={() => setOpen(!open)}>
                                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </Grid>
                        </Authorize>
                    }
                </Grid>
                {menu?.subMenu && (open || isMobile) &&
                    <div className={classes.subMenu}>{subMenus}</div>
                }
            </div>
        </Authorize>
    )
}
