import React from "react";
import { useEffect, useState } from 'react';
import formStyles from "./formStyles";
import EditAssetPopup from "./EditAssetPopup";
import ConditionReportPopup from "./ConditionReportPopup";
import { permissionProfiles } from '../../components/Authorize/permissionProfiles';
import Authorize from '../../../src/components/Authorize/Authorize';
import PropTypes from 'prop-types';
import { useGetConfigurationByVehicleQuery } from "./configurationSlice";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import { selectIsClient } from "../user/userSlice";
import { Box, Tab, Tabs } from "@material-ui/core";

const useStyles = formStyles;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function tabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ParentEditAssetModal = ({ handleClose, asset, updateAsset, openPhotos }) => {

    const [typeOfReport, setTypeOfReport] = useState(1);
    const [modalTypes, setModalTypes] = useState([]);
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    let isClient = useSelector(selectIsClient);

    let {
        data: configuration, error: configurationError,
        isLoading: isLoadingConfiguration
    } = useGetConfigurationByVehicleQuery(asset.id);

    useEffect(() => {
        const modalsTypes = [
            {
                id: 1,
                name: "Basic Asset Data"
            },
            {
                id: 2,
                name: "CR Data"
            }
        ];
        setModalTypes(modalsTypes);
        setTypeOfReport("1");
    }, [])

    const changeModalType = (id) => {
        setTypeOfReport(id);
    }

    const handleChange = (event, newValue) => {
        console.log("newValue", newValue);
        setValue(newValue);
    };

    if (isLoadingConfiguration) {
        return <CircularProgress className={classes.spinner} />;
    }


    let isConditionalReportEnable = configuration.isConditionalReportEnabled;

    return (
        <div>
            <div style={{
                margin: 16,
                marginRight: 90,
                marginTop: 12,
                position: 'absolute',
                width: "30%",
                top: -40,
                right: "0%"
            }}>
                {
                    isConditionalReportEnable &&
                    <Authorize profile={permissionProfiles.CONDITION_REPORT.ConditionReport}>
                        <Box sx={{ '& .MuiBox-root': { padding: '24px 0' } }}>
                            <Tabs value={value} onChange={handleChange} centered className={classes.tabs}>
                                <Tab label="Basic Data" {...tabProps(0)} />
                                <Tab label="CR Data" {...tabProps(1)} />
                            </Tabs>
                        </Box>

                    </Authorize>
                }
            </div>
            {
                <div>
                    <TabPanel value={value} index={0}>
                        <EditAssetPopup asset={asset} handleClose={() => handleClose(null)} updateAsset={updateAsset} openPhotos={openPhotos} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ConditionReportPopup asset={asset} handleClose={() => handleClose(null)}
                            updateAsset={updateAsset} />
                    </TabPanel>
                </div>
            }

        </div>

    );
};

export default ParentEditAssetModal;
