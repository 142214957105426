import { Grid } from '@material-ui/core';
import MapWrapper from "components/MapWrapper/MapWrapper";
import { googleMapService } from "components/MapWrapper/googleMapService";
import { useGetLotsQuery } from "features/lots/lotSlice";
import LotsMapMarker from "features/maps/markers/LotsMapMarker/LotsMapMarker";
import VehicleMapMarker from "features/maps/markers/VehicleMapMarker/VehicleMapMarker";
import { useGetVehicleLocationInfoQuery } from "features/vehicles/vehicleSlice";
import { VehicleMapStyle } from "./style";

export const VehicleMap = ({ vehicleDetails }) => {
    const classes = VehicleMapStyle();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();

    let { data: vehicleLocationInfo, error, isLoading } = useGetVehicleLocationInfoQuery({ id: vehicleDetails?.id }, { skip: !vehicleDetails });

    const lotMarkers = lots?.map(lot => {
        return {
            ...lot,
            address: googleMapService.convertAddressPartsToAddressString(lot),
            icon: <LotsMapMarker
                color={lot.id == vehicleDetails?.domicileLot ? '#115B43' : lot.pmfLot ? "#0090FE" : "#E48900"}
                text={lot.name}
            />
        }
    })

    const markers = vehicleLocationInfo ? [
        ...lotMarkers,
        {
            ...vehicleLocationInfo,
            /*latitude: 40.585637,
            longitude: -74.249521,*/
            address: vehicleLocationInfo?.latitude ? null : googleMapService.convertAddressPartsToAddressString(vehicleLocationInfo),
            icon: <VehicleMapMarker
                color="#0090FE"
                text={vehicleDetails?.vin ?? vehicleDetails?.descriptor ?? vehicleDetails?.assetId}
            />,
            center: true
        }

    ] : [];

    return (
        <div className={classes.mapContainer}>
            <Grid className={classes.lengendWrapper} container justifyContent="space-between">
                <Grid Item className={`${classes.legendItem} ${classes.legendItemPmf}`}>Closest PMF Sites</Grid>
                <Grid Item className={`${classes.legendItem} ${classes.legendItemClient}`}>Closest Client Sites</Grid>
                <Grid Item className={`${classes.legendItem} ${classes.legendItemDomicile}`}>Domicile Lot</Grid>
            </Grid>
            <div className={classes.mapWrapper}>
                {vehicleLocationInfo && <MapWrapper
                    limitMarkersByDistanceFromCenter={25}
                    defaultZoom={8}
                    markers={markers}
                />}

            </div>
        </div>
    )
}